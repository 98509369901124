import { ReferralType } from '@wix/da-bi/pkg/constants';
import { verifyLoggedOutAccess } from '@wix/da-shared-react/pkg/redux/loggedout/actionCreators';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { ModalType } from '../../types/modals';

export const pushPointsModal = verifyLoggedOutAccess(
  ReferralType.GIVE,
  (username: string, title: string) =>
    modalActions.pushModal(ModalType.GIVE_POINTS, {
      params: { username, title },
      wrapInModal: false,
    })
);
