import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDown from '@wix/da-ds/pkg/Icons/24x24/ArrowDown';
import ArrowUp from '@wix/da-ds/pkg/Icons/24x24/ArrowUp';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { useSelector } from 'react-redux';
import TextButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/TextButtonWithContextMenu';
import {
  DropdownOption,
  NullableDropdownItem,
} from '@wix/da-ds/pkg/Dropdown/types';
import { sortGroupActionLog } from '../../../../actions/backroomSection';
import { getActionLogParams } from '../../../../selectors/groupsBackroom';

interface Props {}

function useOrderOptions(): [NullableDropdownItem[], DropdownOption] {
  const { t } = useTranslation();
  const { sortValue = 'newest' } = useSelector(getActionLogParams);
  const dispatchSortAction = useAction(sortGroupActionLog);

  const orderOptions = useMemo(
    () => [
      {
        value: 'newest',
        label: t('widgets.group_actionlog.sort.newest'),
        selected: sortValue === 'newest',
        onClick: () => dispatchSortAction('newest'),
      },
      {
        value: 'oldest',
        label: t('widgets.group_actionlog.sort.oldest'),
        selected: sortValue === 'oldest',
        onClick: () => dispatchSortAction('oldest'),
      },
    ],
    [sortValue, dispatchSortAction, t]
  );

  return [orderOptions, orderOptions.find(option => option.selected)!];
}

const OrderDropdown: React.FC<Props> = () => {
  const [orderOptions, selectedOption] = useOrderOptions();

  return (
    <TextButtonWithContextMenu
      variant="main"
      size="medium"
      postfix={{
        opened: ArrowUp,
        closed: ArrowDown,
      }}
      items={orderOptions}
    >
      {selectedOption.label}
    </TextButtonWithContextMenu>
  );
};
OrderDropdown.displayName = 'OrderDropdown';

export default OrderDropdown;
