import React from 'react';
import { useTranslation } from 'react-i18next';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import SideNavBar from '../../../SideNavBar';

export interface Props {
  activeHash: string;
  updateActiveHash: (hash: string) => void;
  routes: any;
}

export const StatsSideNavBar: React.FC<Props> = ({
  activeHash,
  updateActiveHash,
  routes,
}) => {
  const { t } = useTranslation();
  return (
    <SideNavBar
      routes={routes}
      activeHash={activeHash}
      onActiveHashChange={updateActiveHash}
      biEvid={830}
      heading={
        <VisuallyHidden>
          <Heading>{t('pages.stats.sidenav.a11y.title')}</Heading>
        </VisuallyHidden>
      }
    />
  );
};
StatsSideNavBar.displayName = 'StatsSideNavBar';

export default StatsSideNavBar;
