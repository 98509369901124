import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { AppState } from '../../../../types/store';
import {
  isCurrentUserProfileOwner,
  getIsWatchingProfileOwner,
} from '../../../selectors/users';
import { ADOPTABOBBLES_DEVIATIONS_STREAM_ID } from '../../../streams/adoptabobbles';

import { Props, RouteParams } from './AdoptabobblesSection';
import { isSectionLoading } from '../../../selectors/sections';

type StateProps = Pick<
  Props,
  | 'isOwner'
  | 'isWatching'
  | 'isEmpty'
  | 'streamId'
  | 'isSectionLoading'
>;
type RouteProps = RouteComponentProps<RouteParams>;
type OwnProps = Omit<Props & RouteProps, keyof StateProps>;

export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps & RouteProps,
  AppState
> = (state, ownProps) => {
  const username = ownProps.match.params.username;
  const streamId = ADOPTABOBBLES_DEVIATIONS_STREAM_ID;
  const isInitialized = streamSelectors.isInitialized(state, streamId);
  const isFetching = streamSelectors.isFetching(state, streamId);
  const isEmpty =
    isInitialized &&
    !isFetching &&
    streamSelectors.getItems(state, streamId).length === 0;
  const isOwner = isCurrentUserProfileOwner(state);
  const isWatching = getIsWatchingProfileOwner(state);

  return {
    isEmpty,
    username,
    streamId,
    isOwner,
    isWatching,
    isSectionLoading: isSectionLoading(state),
  };
};

export default connect(mapStateToProps);

