import { ModuleConfig } from '../../../../../types/modules';
import { FormValues } from './types';

export const configAdapter = (data: ModuleConfig): FormValues => {
  const { options = {} } = data;
  return {
    roleid: -1,
    note: '',

    options,
    user: undefined,
  };
};

export const submitAdapter = (formValues: FormValues) => {
  const { roleid = -1, note = '', user } = formValues;
  return {
    roleid,
    invitee_username: user?.username || '',
    note,
  };
};
