import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { PapiGallection, PapiDeviation } from '@wix/da-papi-types';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { getPagingMode } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { Props } from './ShopGrid';

import { AppState } from '../../../../../types/store';
import { getShopItemsStreamId } from '../../../../streams/shop';
import { ShopItemType } from '../../../../../types/shop';
import { getFoldersByIds } from '../../../../selectors/folders';
import { GallectionType } from '../../../../../types/gallection';
import { isCurrentUserProfileOwner } from '../../../../selectors/users';

type StateProps = Pick<
  Props,
  | 'streamId'
  | 'items'
  | 'hasMore'
  | 'isFetching'
  | 'isOwner'
  | 'isInfiniteScroll'
>;
type DispatchProps = Pick<Props, 'fetchMore'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const streamId = getShopItemsStreamId(ownProps.itemType);
  const itemIds = streamSelectors.getItems(state, streamId);
  let items: PapiGallection[] | PapiDeviation[] = [];
  if (ownProps.itemType === ShopItemType.PREMIUM_FOLDERS) {
    items = getFoldersByIds(state, GallectionType.GALLERY, itemIds);
  } else {
    items = getDeviationsByIds(state, itemIds);
  }
  return {
    streamId,
    items,
    hasMore: streamSelectors.hasMore(state, streamId),
    isFetching: streamSelectors.isFetching(state, streamId),
    isOwner: isCurrentUserProfileOwner(state),
    isInfiniteScroll: getPagingMode(state) === 'scroll',
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  fetchMore: () =>
    dispatch(withOffset.actions.fetch(getShopItemsStreamId(ownProps.itemType))),
});

export default connect(mapStateToProps, mapDispatchToProps);
