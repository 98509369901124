import React from 'react';
import classnames from 'classnames';

import s from './Background.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  backgroundIndex: number;
}

const Background: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  backgroundIndex,
  ...rest
}) => {
  return (
    <div
      className={classnames(
        s['background'],
        s[`background_${backgroundIndex}`],
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

Background.displayName = 'Background';

export default Background;
