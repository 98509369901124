import { createAction } from 'redux-actions';

export const setCurrentPage = createAction(
  'PAGE_INFO.SET_CURRENT_PAGE',
  (payload: number) => payload
);

export const setTotalPages = createAction(
  'PAGE_INFO.SET_TOTAL_PAGES',
  (payload: number) => payload
);

export const setBiMetadata = createAction(
  '@@APP.SET_METADATA',
  (payload: { biMetadata: string }) => payload
);

export const setRequestParams = createAction(
  '@@APP.SET_REQUEST_PARAMS',
  (payload: Record<string, string>) => payload
);
