import React from 'react';
import { Url, USER_PROFILE_SECTION_PATHS } from '@wix/da-url';
import { useTranslation } from 'react-i18next';
import { PapiModuleFeed } from '@wix/da-papi-types';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import Feed from '../Feed';
import EmptyView from '../FeedEmpty';
import { useSelector } from 'react-redux';
import { getProfileOwner } from '../../../../selectors/users';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const FeedBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const profileOwner = useSelector(getProfileOwner);

  const { moduleData } = useModuleData<PapiModuleFeed>(moduleId);
  const modulePrivs = useModulePrivileges(moduleId);
  const items = moduleData.results || [];
  const isEmpty = !items.length;

  if (isEmpty && !modulePrivs?.edit) {
    return null;
  }

  return (
    <WidgetBox
      header={t('widgets.feed.header')}
      seeAllUrl={Url.userLink(
        profileOwner.user.username,
        `${USER_PROFILE_SECTION_PATHS.POSTS}`
      )}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      {!isEmpty ? <Feed moduleId={moduleId} /> : <EmptyView />}
    </WidgetBox>
  );
};
FeedBox.displayName = 'FeedBox';

export default FeedBox;
