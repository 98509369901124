import React from 'react';

export interface WithSectionContextProps {
  section: string;
}

export const SectionContext = React.createContext('');
export const SectionProvider = SectionContext.Provider;
export const SectionConsumer = SectionContext.Consumer;
export default SectionContext;

// HOC for convenience...
export const withSectionContext = <TProps extends WithSectionContextProps>(
  Component: React.ComponentType<TProps>
): React.ComponentType<Omit<TProps, keyof WithSectionContextProps>> => (
  props: Omit<TProps, keyof WithSectionContextProps>
) => {
  return (
    <SectionConsumer>
      {section => <Component {...props as TProps} section={section} />}
    </SectionConsumer>
  );
};
