import React, { Suspense, lazy } from 'react';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const MegaboxConfigPopover = lazy(
  () => import(/* webpackChunkName: "owner" */ '../../MegaboxConfigPopover')
);

type Props = any;

const MegaboxConfigPopoverLazy: React.FC<Props> = props => {
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      <MegaboxConfigPopover {...props} />
    </Suspense>
  );
};
MegaboxConfigPopoverLazy.displayName = 'MegaboxConfigPopoverLazy';

export default MegaboxConfigPopoverLazy;
