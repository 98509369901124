import { createSelector } from 'reselect';
import { PapiGallection } from '@wix/da-papi-types';
import {
  getDenormalizedDataEntityById,
  getDenormalizedDataEntitiesByIds,
} from '@wix/da-shared-react/pkg/redux/entities';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { GallectionType } from '@wix/da-shared-react/pkg/types/gallection';
import { ALL_FOLDER_ID } from '@wix/da-url';
import { AppState } from '../../types/store';
import {
  getCurrentlyViewedFolderId,
  getGallectionSectionType,
} from './gallectionSection';
import { getDefaultFolderFromFolders } from '../components/Sections/GallectionsSection/helpers';
import {
  getStreamIdForGallectionType,
  getSchemaForGallectionType,
  filterOutEmpty,
} from '../utils/gallection';
import { getFolderDeviationsStreamId } from '../streams/folderDeviations';

export const getFolderById = (
  state: AppState,
  gallectionType: GallectionType,
  id: number
): PapiGallection | undefined =>
  getDenormalizedDataEntityById(
    state,
    getSchemaForGallectionType(gallectionType),
    id
  );

export const getFoldersByIds = (
  state: AppState,
  gallectionType: GallectionType,
  ids: number[]
) => {
  return getDenormalizedDataEntitiesByIds(
    state,
    getSchemaForGallectionType(gallectionType),
    ids
  );
};

export function getFoldersForCurrentSection(state: AppState) {
  const folderType = getGallectionSectionType(state);
  return getFoldersByGallectionType(state, folderType);
}

export const getNonEmptyFolders = createSelector(
  [getFoldersForCurrentSection],
  folders => filterOutEmpty(folders)
);

export const getDefaultFolder = (state: AppState) =>
  getDefaultFolderFromFolders(getNonEmptyFolders(state));

function getFolderIdsByType(state: AppState, gallectionType: GallectionType) {
  return streamSelectors.getItems<number>(
    state,
    getStreamIdForGallectionType(gallectionType)
  );
}

function getFoldersByGallectionType(
  state: AppState,
  gallectionType: GallectionType
): PapiGallection[] {
  return getFoldersByIds(
    state,
    gallectionType,
    getFolderIdsByType(state, gallectionType)
  );
}

export const getFeaturedFolder = createSelector(
  [getFoldersForCurrentSection],
  folders => folders.find(({ name }) => name === 'Featured') || folders[0]
);

export const getScrapsFolder = createSelector(
  [getFoldersForCurrentSection],
  folders => folders.find(({ name }) => name === 'Scraps')
);

export const getAllDeviationsFolder = createSelector(
  [getNonEmptyFolders],
  folders => folders.find(({ folderId }) => folderId === ALL_FOLDER_ID)
);

export const hasAnyDeviations = (state: AppState): boolean => {
  const gallectionType = getGallectionSectionType(state);
  const currentlyViewedFolderId = getCurrentlyViewedFolderId(state);
  const streamId = getFolderDeviationsStreamId(
    gallectionType,
    currentlyViewedFolderId
  );
  const streamItems = streamSelectors.getItems(state, streamId) || [];

  if (streamItems.length > 0) {
    return true;
  }

  const folders = getNonEmptyFolders(state);

  return folders.length > 0;
};
