import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import EmptyState from '../../../../EmptyState';

export interface Props {
  clearSearch: () => void;
}

export class GallectionsEmptyState extends React.Component<
  Props & WithTranslation
> {
  render() {
    const { t } = this.props;

    return (
      <EmptyState
        title={t('pages.gallections.search.emptyState.title')}
        subtitle={t('pages.gallections.search.emptyState.subtitle')}
        withButton
        buttonLabel={t('pages.gallections.search.emptyState.buttonCTA')}
        onButtonClick={this.props.clearSearch}
      />
    );
  }
}

export default withTranslation()(GallectionsEmptyState);
