import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  confirmNo,
  confirmValue,
} from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { Props } from './GroupCloseModal';

export type StateProps = Pick<Props, never>;
export type DispatchProps = Pick<Props, 'requestConfirm' | 'requestCancel'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  {}
> = state => ({});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  requestConfirm: reason => dispatch(confirmValue(reason)),
  requestCancel: () => dispatch(confirmNo()),
});

export default connect(mapStateToProps, mapDispatchToProps);
