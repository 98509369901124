import { Route as RouteData } from '../../types/route';
import { USER_PROFILE_SECTION_PATHS } from '@wix/da-url';
import { GROUP_CREATION_PATH, DEFAULT_BACKROOM_SECTION } from '../../constants';
import GroupCreation from '../components/Pages/GroupCreation';
import { RootPage } from '../components/Pages/RootPage';
import AboutSection from '../components/Sections/AboutSection';
import HomeSection from '../components/Sections/HomeSection';
import GallectionsSection from '../components/Sections/GallectionsSection';
import ShopSection from '../components/Sections/ShopSection';
import PostsSection from '../components/Sections/PostsSection';
import StatsSection from '../components/Sections/StatsSection';
import SubscriptionsSection from '../components/Sections/SubscriptionsSection';
import BackroomSection from '../components/Sections/BackroomSection';
import AdoptabobblesSection from '../components/Sections/AdoptabobblesSection';

export const sectionRoutes = [
  {
    key: 'about',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.ABOUT}`,
    component: AboutSection,
    exact: true,
    loaderName: 'about',
  },
  {
    key: 'home',
    path: '/:username',
    component: HomeSection,
    loaderName: 'home',
    exact: true,
  },
  {
    key: 'gallery',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.GALLERY}/:folderId(\\d+)?/:folderName?`,
    component: GallectionsSection,
    loaderName: 'gallery',
    exact: false,
  },
  {
    key: 'adoptabobbles',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.EXCLUSIVES}`,
    component: AdoptabobblesSection,
    loaderName: 'adoptabobbles',
    exact: true,
  },
  {
    key: 'favourites',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.FAVOURITES}/:folderId(\\d+)?/:folderName?`,
    component: GallectionsSection,
    loaderName: 'favourites',
    exact: false,
  },
  {
    key: 'private_collections',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.PRIVATE_COLLECTIONS}/:folderId(\\d+)?/:folderName?`,
    component: GallectionsSection,
    loaderName: 'privateCollections',
    isForOwnerOnly: true,
    isPrivate: true,
    exact: false,
  },
  {
    key: 'posts',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.POSTS}/:postsSection?/:sortOrder?`,
    component: PostsSection,
    loaderName: 'posts',
    exact: false,
  },
  {
    key: 'shop',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.SHOP}/:shopSection?`,
    component: ShopSection,
    loaderName: 'shop',
    exact: false,
  },
  {
    key: 'stats',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.STATS}`,
    component: StatsSection,
    loaderName: 'stats',
    isForOwnerOnly: true,
    isPrivate: true,
    exact: true,
  },
  {
    key: 'subscriptions',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.SUBSCRIPTIONS}/:tierDeviationid?/:tierContentType?`,
    component: SubscriptionsSection,
    loaderName: 'subscriptions',
    exact: false,
  },
  {
    key: 'backroom',
    path: `/:username/${USER_PROFILE_SECTION_PATHS.BACKROOM}/:backroomSection?/:itemId?`,
    defaultPath: `/:username/${USER_PROFILE_SECTION_PATHS.BACKROOM}/${DEFAULT_BACKROOM_SECTION}`,
    component: BackroomSection,
    loaderName: 'backroom',
    isForAdminsOnly: true,
    isPrivate: true,
    exact: false,
  },
] as RouteData[];

export const routes = [
  {
    key: 'groupCreation',
    path: GROUP_CREATION_PATH,
    component: GroupCreation,
    loaderName: 'groupCreation',
  },
  {
    key: 'root',
    component: RootPage as any,
    routes: sectionRoutes,
  },
] as RouteData[];

export const DEFAULT_PAGE_LAYOUT = [
  'about',
  'home',
  'gallery',
  'favourites',
  'private_collections',
  'posts',
  'shop',
  'stats',
  'subscriptions',
];

export const getSectionKeys = () => sectionRoutes.map(v => v.key);
