import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import {
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { Props } from './AdoptablesEmptyState';

export type StateProps = Pick<Props, 'isOwner' | 'user' | 'hasCoreMembership'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  user: getProfileOwnerUser(state),
  hasCoreMembership: getCurrentUserHasCore(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
