import { createAction } from 'redux-actions';
import { PapiDeviation } from '@wix/da-papi-types';

export const setIsRepositioningProfileHero = createAction(
  'PROFILE_HERO_EDITOR.SET_IS_REPOSITIONING',
  (payload: boolean) => payload
);

export const setProfileHeroDeviation = createAction(
  'PROFILE_HERO_EDITOR.SET_DEVIATION',
  (payload: PapiDeviation) => payload
);

interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const updateProfileHeroCropArea = createAction(
  'PROFILE_HERO_EDITOR.UPDATE_CROP_AREA',
  (payload: Rectangle) => payload
);

export const saveProfileHero = createAction('PROFILE_HERO.SAVE', () => ({}));

export const deleteProfileHero = createAction(
  'PROFILE_HERO.DELETE',
  () => ({})
);
