import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { URLS } from '@wix/da-url';
import { BiData, BiEvent, BuyCoreButtonBiEvent } from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import CoreExclusiveFeature from '@wix/da-shared-react/pkg/indicators/CoreExclusiveFeature/CoreExclusiveFeature';
import EmptyState from '../../../../EmptyState';
import { ShopItemType } from '../../../../../../types/shop';
import AddItemCard from '../AddItemCard';

import s from './UploadMore.scss';

export interface Props {
  hasCoreMembership?: boolean;
  itemType: ShopItemType;
  height?: number;
  onCreate?: () => void;
  biData?: BiEvent;
}

export const UploadMore: React.FC<Props> = ({
  hasCoreMembership,
  itemType,
  height = 256,
  onCreate,
  biData,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  let requiresCoreMembership, referralInfo;

  switch (itemType) {
    case ShopItemType.ADOPTABLES:
      referralInfo = ReferralType.SHOP_ADOPTABLES;
      requiresCoreMembership = true;
      break;

    case ShopItemType.PREMIUM_CONTENT:
      referralInfo = ReferralType.SHOP_PREMIUM_DOWNLOADS;
      requiresCoreMembership = true;
      break;

    case ShopItemType.PREMIUM_FOLDERS:
      referralInfo = ReferralType.SHOP_PREMIUM_GALLERIES;
      requiresCoreMembership = true;
      break;

    default:
      requiresCoreMembership = false;
  }

  // this tells mobile users that they need to switch to desktop
  // to upload if it's a CORE feature like galleries or downloads
  if (requiresCoreMembership && isMobile) {
    return (
      <EmptyState
        className={classnames(s['root'], height > 400 && s['tall'])}
        title={t(`pages.shop.uploadMore.var.${itemType}.title`)}
        titleClassName={s['title']}
        subtitle={t(`pages.shop.uploadMore.var.${itemType}.subtitle.mobile`)}
        subtitleClassName={s['subtitle']}
        illustration={<CoreExclusiveFeature withoutBuyCTA />}
        illustrationClassName={s['exclusive']}
        view={'small'}
        height={height}
      />
    );
  }

  // add item that doesnt require CORE membership or user already has CORE
  if (!requiresCoreMembership || hasCoreMembership) {
    return (
      <AddItemCard
        title={t(`pages.shop.uploadMore.var.${itemType}.title`)}
        subtitle={t(`pages.shop.uploadMore.var.${itemType}.subtitle`)}
        onClick={onCreate}
        biData={biData}
        height={height}
        autoWidth={true}
      />
    );
  }

  // non-core
  return (
    <EmptyState
      className={classnames(s['root'], height > 400 && s['tall'])}
      title={t(`pages.shop.uploadMore.nonCore.var.${itemType}.title`)}
      titleClassName={s['title']}
      subtitle={t(`pages.shop.uploadMore.nonCore.var.${itemType}.subtitle`)}
      subtitleClassName={s['subtitle']}
      illustration={<CoreExclusiveFeature withoutBuyCTA />}
      illustrationClassName={s['exclusive']}
      withButton={true}
      customButton={
        <BuyCoreBrandedButton
          size="large"
          href={URLS.CORE_MEMBERSHIP}
          biData={BiData<BuyCoreButtonBiEvent>({
            evid: 375,
            referral_info: referralInfo,
          })}
          withSaleSparkles
        >
          {t('widgets.tierContent.empty.cta.getCore')}
        </BuyCoreBrandedButton>
      }
      view={'small'}
    />
  );
};

UploadMore.displayName = 'UploadMore';

export default UploadMore;
