import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { FEATURED_POSTS_IDS_STREAM } from '../streams/posts';
import {
  withOffset,
  selectors as baseSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import i18next from 'i18next';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { getModuleIdByModuleName as getModuleIdByModuleNameNewGrusers } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';
import { getDataEntityById } from '@wix/da-shared-react/pkg/redux/entities/selectors';
import { getActiveSection } from '../selectors/sections';
import { ModuleType } from '../../types/modules';

import {
  pinPostOnFeed,
  featurePost,
  unfeaturePost,
  pinPost,
  unpinPost,
  removePost,
} from '../actions/feed';
import { getProfileGruser, getProfileOwnerUser } from '../selectors/users';
import { getCanPin, getCanFeature, getCanRemovePost } from '../selectors/privs';
import { getModuleIdByModuleName } from '../selectors/modules';
import ddt from '@wix/da-ddt';
import { requestPuppy } from '@wix/da-http-client';
import { requestPuppyGruser } from './gruserHelpers';

function* postRequest(modulePath, data = {}) {
  return yield call(
    requestPuppyGruser,
    {
      method: 'post',
      url: `/module/${modulePath}`,
      data,
    },
    undefined,
    'gruser'
  );
}

export function* pinPostOnFeedSaga(action) {
  const {
    payload: { item },
  } = action;
  const canPin = yield select(getCanPin);
  if (!canPin) {
    yield putErrorNotification(i18next.t('widgets.common.error.unauthorized'));
    return;
  }
  const { username } = yield select(getProfileOwnerUser);

  const section = yield select(getActiveSection);
  // Both FEED and POSTS_FEED use the same pinning/unpinning logic
  let moduleType;
  switch (section) {
    case 'home':
      moduleType = ModuleType.FEED;
      break;
    case 'posts':
      moduleType = ModuleType.POSTS_FEED;
      break;
    default:
      return;
  }
  const moduleId = yield select(state =>
    getModuleIdByModuleNameNewGrusers(
      state,
      getProfileGruser(state),
      moduleType
    )
  );
  if (!moduleId) {
    return;
  }

  let response;
  if (item !== undefined) {
    response = yield call(postRequest, `${moduleType}/pin`, {
      itemid: item.deviationId,
      typeid: item.typeId,
      moduleid: moduleId,
      username,
    });
  } else {
    response = yield call(postRequest, `${moduleType}/unpin`, {
      moduleid: moduleId,
      username,
    });
  }
  if (response && response.success && response.module) {
    window.location.reload();
  } else {
    ddt.error('feed', 'Could not unpin item');
  }
}

export function* featurePostSaga(action) {
  const {
    payload: { item },
  } = action;
  const canFeature = yield select(getCanFeature);

  if (!canFeature) {
    yield putErrorNotification(i18next.t('widgets.common.error.unauthorized'));
    return;
  }
  const { username } = yield select(getProfileOwnerUser);
  const moduleId = yield select(state =>
    getModuleIdByModuleNameNewGrusers(
      state,
      getProfileGruser(state),
      ModuleType.FEATURED_POSTS
    )
  );

  const response = yield call(postRequest, 'featured_posts/feature', {
    moduleid: moduleId,
    itemid: item.deviationId,
    typeid: item.typeId,
    username,
  });

  if (response && response.success) {
    const { result, entities } = normalize(item, deviationSchema);
    yield put(
      withOffset.actions.insertItems({
        streamId: FEATURED_POSTS_IDS_STREAM,
        items: [result],
        entities,
        offset: 0,
      })
    );
  }
}

export function* unfeaturePostSaga(action) {
  const {
    payload: { item },
  } = action;
  const canFeature = yield select(getCanFeature);

  if (!canFeature) {
    yield putErrorNotification(i18next.t('widgets.common.error.unauthorized'));
    return;
  }

  const { username } = yield select(getProfileOwnerUser);

  const moduleId = yield select(state =>
    getModuleIdByModuleNameNewGrusers(
      state,
      getProfileGruser(state),
      ModuleType.FEATURED_POSTS
    )
  );

  const response = yield call(postRequest, 'featured_posts/unfeature', {
    moduleid: moduleId,
    itemid: item.deviationId,
    typeid: item.typeId,
    username,
  });

  if (response && response.success) {
    const { result } = normalize(item, deviationSchema);
    yield put(
      withOffset.actions.deleteItem({
        streamId: FEATURED_POSTS_IDS_STREAM,
        item: result,
      })
    );
  }
}

export function* removePostSaga(action) {
  const {
    payload: { item, streamId },
  } = action;
  const canRemove = yield select(getCanRemovePost);
  if (!canRemove) {
    yield putErrorNotification(i18next.t('widgets.common.error.unauthorized'));
    return;
  }
  const { userId: groupid } = yield select(getProfileOwnerUser);

  const response = yield call(requestPuppy, {
    method: 'post',
    url: 'group/post/remove',
    data: {
      itemid: item.deviationId,
      typeid: item.typeId,
      groupid,
    },
  });

  if (response && response.success && response.deviation) {
    const { result } = yield call(updateDeviationEntity, response.deviation);
    if (streamId) {
      yield put(
        withOffset.actions.deleteItem({
          item: result,
          streamId,
        })
      );
    }
  }
}

function* updateDeviationEntity(deviation) {
  const { result, entities } = normalize(deviation, deviationSchema);
  yield put(putEntities({ entities }));
  return { result, entities };
}

function* getFirstStreamDeviation(streamId) {
  const [firstItemId] = yield select(baseSelectors.getItems, streamId);
  const firstItem = yield select(
    getDataEntityById,
    deviationSchema,
    firstItemId
  );
  return firstItem;
}

export function* pinPostSaga(action) {
  const {
    payload: { streamId, item },
  } = action;
  const canPin = yield select(getCanPin);
  if (!canPin) {
    yield putErrorNotification(i18next.t('widgets.common.error.unauthorized'));
    return;
  }
  const { username } = yield select(getProfileOwnerUser);
  const moduleId = yield select(getModuleIdByModuleName, ModuleType.POSTS_FEED);

  const response = yield call(postRequest, 'posts_feed/pin', {
    moduleid: moduleId,
    itemid: item.deviationId,
    typeid: item.typeId,
    username,
  });

  if (response && response.success) {
    const firstItem = yield call(getFirstStreamDeviation, streamId);
    if (firstItem && firstItem.isPinned) {
      yield call(updateDeviationEntity, { ...firstItem, isPinned: false });
    }
    const { result } = yield call(updateDeviationEntity, {
      ...item,
      isPinned: true,
    });
    yield put(
      withOffset.actions.insertItems({
        streamId,
        items: [result],
        offset: 0,
      })
    );
  }
}

export function* unpinPostSaga(action) {
  const {
    payload: { streamId },
  } = action;
  const canPin = yield select(getCanPin);
  if (!canPin) {
    yield putErrorNotification(i18next.t('widgets.common.error.unauthorized'));
    return;
  }
  const { username } = yield select(getProfileOwnerUser);
  const moduleId = yield select(getModuleIdByModuleName, ModuleType.POSTS_FEED);

  const firstItem = yield call(getFirstStreamDeviation, streamId);
  if (!firstItem || !firstItem.isPinned) {
    return;
  }

  const response = yield call(postRequest, 'posts_feed/unpin', {
    moduleid: moduleId,
    username,
  });

  if (!response || !response.success) {
    yield putErrorNotification(
      response.errorDetails || i18next.t('widgets.common.error.requestFailed')
    );
    return;
  }
  const { result } = yield call(updateDeviationEntity, {
    ...firstItem,
    isPinned: false,
  });
  const [firstItemId, ...rest] = yield select(baseSelectors.getItems, streamId);
  const offset = rest.findIndex(id => id === firstItemId);
  yield put(
    withOffset.actions.deleteItem({
      streamId,
      item: result,
    })
  );
  yield put(
    withOffset.actions.insertItems({
      streamId,
      items: [result],
      offset,
    })
  );
}

export default function* rootFeedSaga() {
  yield all([
    takeLatest(pinPostOnFeed, pinPostOnFeedSaga),
    takeLatest(pinPost, pinPostSaga),
    takeLatest(unpinPost, unpinPostSaga),
    takeLatest(featurePost, featurePostSaga),
    takeLatest(unfeaturePost, unfeaturePostSaga),
    takeLatest(removePost, removePostSaga),
  ]);
}
