import React from 'react';
import cn from 'classnames';
import { PapiModuleBadgesSpotlight } from '@wix/da-papi-types';
import Flex from '@wix/da-ds/pkg/Flex';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import SpotlightBadge from '../../UserWidgets/_partials/SpotlightBadge';

import s from './BadgesSpotlight.scss';

export interface Props {
  moduleId: number;
}

const BadgesSpotlight: React.FC<Props> = ({ moduleId }) => {
  const {
    moduleData: { badges },
  } = useModuleData<PapiModuleBadgesSpotlight>(moduleId);
  return (
    <Flex
      className={cn('ds-surface-secondary', s['root'])}
      justifyContent="center"
      wrap="wrap"
    >
      {badges.map(b => (
        <SpotlightBadge key={b.id} badge={b} />
      ))}
    </Flex>
  );
};
BadgesSpotlight.displayName = 'BadgesSpotlight';

export default BadgesSpotlight;
