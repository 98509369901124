import { PapiCustomboxTemplate } from '@wix/da-papi-types';

export const reduxKey = '@@CustomboxTemplates';
export const streamId = 'custombox_templates';

export interface CustomBoxTemplatesState {
  /**
   * A cache of what template the user selected when they created a custombox. We only use this for
   * bi events.
   */
  chosenTemplate?: PapiCustomboxTemplate;
}
