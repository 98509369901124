import {
  setIsEditorOpen,
  SetIsEditorOpenPayload,
  setErrorMessage,
  SetErrorMessagePayload,
} from '../actions/avatar';

import { handleActions, Action } from 'redux-actions';
import { AppState } from '../../types/store';
import AvatarEditor from '../../types/avatarEditor';

export default handleActions<AppState['avatarEditor'], any>(
  {
    [setIsEditorOpen.toString()]: (
      state: AvatarEditor,
      action: Action<SetIsEditorOpenPayload>
    ) => {
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    },
    [setErrorMessage.toString()]: (
      state: AvatarEditor,
      action: Action<SetErrorMessagePayload>
    ) => {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
  {
    isOpen: false,
    errorMessage: undefined,
  }
);
