import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import SearchInput from '@wix/da-ds/pkg/formControls/dsPresets/SearchInput';
import { Props as SearchInputProps } from '@wix/da-ds/pkg/formControls/dsPresets/SearchInput/SearchInput';
import IconButton from './IconButton';
import { navigate } from '../../helpers';

export interface Props extends ShopSearchInputProps {
  mobileIconClassName?: string;
}

/** bit of a weird one:
 *  - on desktop, a plain search input wrapped in a form inside the 'top' of the ShopFilters
 *  - on mobile, the same but:
 *    - hidden at first
 *    - only shown when a button in that 'top' is pressed
 *    - shown just below the 'top' section instead of in it
 */
export const ShopSearch: React.FC<Props> = ({
  mobileIconClassName,
  ...searchInputProps
}) => {
  const isDesktop = !useContext(MobileContext);

  const searchInput = <ShopSearchInput {...searchInputProps} />;
  return isDesktop ? (
    searchInput
  ) : (
    <MobileShopSearchDisclosure mobileIconClassName={mobileIconClassName}>
      {searchInput}
    </MobileShopSearchDisclosure>
  );
};
export default ShopSearch;

// ----
export const MobileShopSearchDisclosure: React.FC<{
  mobileIconClassName?: string;
  children: React.ReactNode;
}> = ({ mobileIconClassName, children }) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton
        icon={<SearchIcon className={mobileIconClassName} />}
        a11yText={t('pages.shop.shopFilters.openSearchButton.a11y.description')}
        onClick={() => setOpen(v => !v)}
      />
      {isOpen && children}
    </>
  );
};

// ----
interface ShopSearchInputProps
  extends Omit<SearchInputProps, 'id' | 'label' | 'value' | 'onClearClick'> {
  formClassName?: string;
}

export const ShopSearchInput: React.FC<ShopSearchInputProps> = ({
  formClassName,
  defaultValue = '',
  ...searchInputProps
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>(`${defaultValue}`);

  const onSubmit = e => {
    e.preventDefault();
    navigate(
      updateParamInQuerystring('q', searchTerm.trim(), window.location.href)
    );
  };

  return (
    <form className={formClassName} onSubmit={onSubmit}>
      <SearchInput
        {...searchInputProps}
        id="profile-shop-search"
        label={t('pages.shop.shopFilters.search.a11y.label')}
        placeholder={t('pages.shop.shopFilters.search.placeholder')}
        noBottomSpacing
        isLabelHidden
        onChange={e => setSearchTerm(e.target.value)}
        onClearClick={e => setSearchTerm('')}
        value={searchTerm}
      />
    </form>
  );
};

// ----
function updateParamInQuerystring(key: string, param = '', url): string {
  const updated = new URL(url);
  if (param) {
    updated.searchParams.set(key, param);
  } else {
    updated.searchParams.delete(key);
  }
  return `${updated}`;
}
