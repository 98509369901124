import React, { useRef, useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { useController, useFormContext } from 'react-hook-form';
import FormElement from '../../../../_partials/Form/FormElement';
import useEditorNg from '@wix/da-shared-react/pkg/DaEditorNg/hooks/useEditorNg';
import EditorCommon from '@wix/da-shared-react/pkg/DaEditorNg/EditorCommon';
import AboutTiptapEditor from '@wix/da-editor-tiptap/pkg/components/Editor/presets/GroupAbout';
import { EditorApi } from '@wix/da-editor-tiptap/pkg/components/Editor/types';
import { useEditorContext } from '@wix/da-editor-tiptap/pkg/components/Editor/Context';

import s from './EditorField.scss';

interface Props {
  name: string;
  label: string;
  subLabel?: string;
  placeholder?: string;
  maxLength?: number;
  withFooter?: boolean;
  scrollRef?: any;
  onReady?: (api: EditorApi) => void;
}

const EditorField: React.FC<Props> = ({
  name,
  label,
  subLabel,
  placeholder,
  maxLength,
  withFooter,
  scrollRef,
  onReady,
}) => {
  const editor = useEditorContext();
  const { current: id } = useRef(uniqueId('textarea'));
  const { control, register, errors } = useFormContext();
  const initialContent = control.defaultValuesRef.current[name];
  const error = get(errors, name);

  const {
    field: { onChange },
  } = useController({
    name,
    control,
  });

  // Editor is not a real form field, we need to register it
  useEffect(() => {
    register(name);
    register('editorApi');
  }, [register, name]);

  const [charCount, setCharCount] = useState(0);

  const { editorProps, editorSharedProps, editorCommonProps } = useEditorNg({
    onEditorReady: (api: EditorApi) => {
      onReady && onReady(api);
    },
  });

  const handleOnChange = useCallback(() => {
    onChange && onChange({});
    setCharCount(editor?.storage.characterCount.characters());
  }, [editor?.storage.characterCount, onChange]);

  return (
    <FormElement
      id={id}
      isLabelHidden={false}
      noBottomSpacing={false}
      label={label}
      subLabel={subLabel}
      validationResult={error && 'error'}
      details={error}
    >
      <EditorCommon {...editorCommonProps} />
      <AboutTiptapEditor
        cookieType={undefined}
        placeholder={placeholder}
        {...editorSharedProps}
        {...editorProps}
        initialContent={initialContent}
        onChange={handleOnChange}
        scrollContainerRef={scrollRef}
        autoScrollWhenWithin={15}
        containerPadding={50}
      ></AboutTiptapEditor>
      {withFooter && (
        <>
          {maxLength && (
            <div className={s['counter']}>
              ({charCount} / {maxLength})
            </div>
          )}
        </>
      )}
    </FormElement>
  );
};
EditorField.displayName = 'EditorField';

export default EditorField;
