import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './GroupMemberButton';

import { AppState } from '../../../../../../../../types/store';
import { getProfileOwnerUser } from '../../../../../../../selectors/users';

export type StateProps = Pick<Props, 'profileOwnerUser'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  profileOwnerUser: getProfileOwnerUser(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
