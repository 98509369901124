import { all, call, select, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
  deleteDeviationSuccess,
  DeleteDeviationPayload,
} from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';
import { getShopItemsStreamId } from '../streams/shop';
import { POSTS_DEVIATIONS_STREAM_GROUP_ID } from '../streams/posts';
import { ShopItemType } from '../../types/shop';
import { deleteItems } from '@wix/da-shared-react/pkg/Stream/base/actions';
import { getStreamIdsByGroupId } from '@wix/da-shared-react/pkg/Stream/base/selectors';
import { getDeviationType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import { getDeviationEntityId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { getActiveSection } from '../selectors/sections';
import { Section } from '../../types/sections';
import { getSelectedOrFirstTier } from '../selectors/subscriptionsSection';
import { getTierContentDeviationsStreamId } from '../streams/tierContent';
import { updateModuleDataByModuleType } from './gruserHelpers';
import { PapiModuleFeed } from '@wix/da-papi-types';

function* deleteDeviationSuccessSaga(action: Action<DeleteDeviationPayload>) {
  const deviation = action.payload!.deviation;
  const deviationType = getDeviationType(deviation);
  switch (deviationType) {
    case DeviationTypes.COMMISSION:
      yield put(
        deleteItems({
          streamId: getShopItemsStreamId(ShopItemType.COMMISSIONS),
          items: [getDeviationEntityId(deviation)],
        })
      );
      break;
    case DeviationTypes.POLL:
    case DeviationTypes.STATUS:
    case DeviationTypes.JOURNAL:
      const postStreams = yield select(state =>
        getStreamIdsByGroupId(state, POSTS_DEVIATIONS_STREAM_GROUP_ID)
      );

      const activeSection: Section = yield select(getActiveSection);
      if (activeSection === 'subscriptions') {
        const tier = yield select(getSelectedOrFirstTier);
        if (tier) {
          const tierPostsStreamId = getTierContentDeviationsStreamId(
            tier.deviationId,
            'posts'
          );
          postStreams.push(tierPostsStreamId);
        }
      }

      // ^ done this way because we might have
      // several stream for each deviation type
      // due to extra flags (order, filter)
      yield all(
        postStreams.map(streamId =>
          put(
            deleteItems({
              streamId,
              items: [getDeviationEntityId(deviation)],
            })
          )
        )
      );

      // Remove deviation from the feed module's moduleData results
      yield call(
        updateModuleDataByModuleType<PapiModuleFeed>,
        'feed',
        moduleData => {
          if (moduleData) {
            const { results } = moduleData;
            const oldResults = results || [];
            const filteredResults = oldResults.filter(
              result => result.deviationId !== deviation.deviationId
            );
            if (oldResults.length !== filteredResults.length) {
              return { ...moduleData, results: filteredResults };
            }
          }
          return undefined;
        }
      );

      break;
    default:
    // noop
  }
}

export default [takeEvery(deleteDeviationSuccess, deleteDeviationSuccessSaga)];
