import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { PapiDeviation, PapiModulePostsFeed } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import {
  FEATURED_POSTS_IDS_STREAM,
  POSTS_DEVIATIONS_STREAM_GROUP_ID,
} from '../../../../streams/posts';
import { ModuleType } from '../../../../../types/modules';
import { getDeviationsCommentsInitActions } from '../../../../store/initActions/common';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModulePostsFeed,
  requestParams: any
) => {
  const { results, total, hasMore, nextOffset } = moduleData;
  const deviations: PapiDeviation[] = results ?? [];
  const normalized = normalize(deviations, [deviationSchema]);

  return [
    withOffset.actions.initialize({
      streamId: FEATURED_POSTS_IDS_STREAM,
      groupId: POSTS_DEVIATIONS_STREAM_GROUP_ID,
      itemsPerFetch: 5,
      streamParams: {
        moduleId,
        sortOrder: requestParams.order,
        moduleType: ModuleType.FEATURED_POSTS,
      },
      items: normalized.result,
      entities: normalized.entities,
      nextOffset,
      hasMore,
      total,
    }),
    ...getDeviationsCommentsInitActions(deviations),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
