import React from 'react';
import NotificationTypeDropdown from './NotificationTypeDropdown';
import RoleDropdown from './RoleDropdown';
import StatusDropdown from './StatusDropdown';
import s from './FilterBar.scss';

interface Props {
  moduleId: number;
}

const FilterBar: React.FC<Props> = ({ moduleId }) => {
  return (
    <div className={s['root']}>
      <NotificationTypeDropdown moduleId={moduleId} />
      <RoleDropdown moduleId={moduleId} />
      <StatusDropdown moduleId={moduleId} />
    </div>
  );
};

FilterBar.displayName = 'FilterBar';

export default FilterBar;
