import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import Supporters from '../Supporters';
import EmptyState from '../../../EmptyState';
import { getProfileOwner } from '../../../../selectors/users';
import { useSelector } from 'react-redux';
import { useSubmitUrl } from '@wix/da-shared-react/pkg/utils/hooks/useSubmitUrl';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';

import s from './SupportersBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const SupportersBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const profileOwner = useSelector(getProfileOwner);
  const SUBMIT_URL = useSubmitUrl(true);
  const top_supporters = useFlag('top_supporters');

  const { moduleData } = useModuleData<any>(moduleId);
  const modulePrivs = useModulePrivileges(moduleId);
  const results = top_supporters
    ? moduleData.timePeriods?.alltime?.results
    : moduleData?.results;
  const isEmpty = !(results || []).length;
  const isOwner = !modulePrivs?.edit;

  const navigateToSubmit = () => {
    window.location.replace(Url.to(SUBMIT_URL));
  };

  return (
    <WidgetBox
      header={t('widgets.supporters.header')}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      {!isEmpty ? (
        <Supporters moduleId={moduleId} />
      ) : isOwner ? (
        <EmptyState
          view="small"
          className={s['empty-state']}
          title={t('widgets.supporters.empty.title.owner')}
          subtitle={t('widgets.supporters.empty.subtitle.owner')}
          buttonLabel={t('widgets.supporters.empty.CTALabel.owner')}
          withButton={true}
          onButtonClick={navigateToSubmit}
        />
      ) : (
        <EmptyState
          view="small"
          className={s['empty-state']}
          title={t('widgets.supporters.empty.title.visitor')}
          subtitle={t('widgets.supporters.empty.subtitle.visitor', {
            username: profileOwner?.user?.username,
          })}
        />
      )}
    </WidgetBox>
  );
};
SupportersBox.displayName = 'SupportersBox';

export default SupportersBox;
