import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import DonationsBox from './DonationsBox';
import DonationsConfigModal from './DonationsConfigModal';

export const getDonationsWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: DonationsBox,
  configPopoverComponent: DonationsConfigModal,
  confirmRemove: () =>
    defaultRemoveConfirmation({ t, dispatch, willDestroyContent: true }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-5-donation-pool.svg',
    title: t('widgets.donations.addButtonHeader'),
    description: t('widgets.donations.description'),
  },
});
