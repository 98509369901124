import { PapiDeviation } from '@wix/da-papi-types';
import MediaService from '@wix/da-shared-react/pkg/utils/DeviationMediaService';
import { ThemeOptions } from '@wix/da-react-context/pkg/constants';
import { BackgroundImageDisplayMode } from '@wix/da-shared-react/pkg/Background/types';
import {
  ProfileSkin,
  customSkin,
  wrapPapiBackgroundUrl,
} from '../types/profileSkins';

type CustomSkinParams = {
  skinId?: string;
  imageUrl?: string;
  image?: PapiDeviation;
  imageDisplayMode?: BackgroundImageDisplayMode;
  theme?: ThemeOptions;
};
export const profileSkinFromProps = ({
  skinId,
  imageUrl,
  image,
  imageDisplayMode,
  theme,
}: CustomSkinParams = {}): ProfileSkin => {
  if (!theme || !(image || imageUrl)) {
    return {
      ...customSkin,
    };
  }

  if (image) {
    const imageFile = MediaService.getLargestFullview(image);
    const imageThumb = MediaService.getSmallestThumbThatFullyCovers(
      image,
      150,
      95
    );
    const background = imageFile ? `url(${imageFile.src})` : '';
    const staticBackground = MediaService.isGif(image)
      ? customSkin.backgroundPreview
      : '';
    const backgroundPreview = imageThumb ? `url(${imageThumb?.src})` : 'none';

    return {
      ...customSkin,
      backgroundPreview,
      skin: {
        background,
        staticBackground,
        image,
        imageDisplayMode,
        baseTheme: theme,
      },
    };
  }

  if (imageUrl) {
    const background = wrapPapiBackgroundUrl(imageUrl);
    const staticBackground = imageUrl.includes('.gif')
      ? customSkin.backgroundPreview
      : '';
    const backgroundPreview = 'none';

    return {
      ...customSkin,
      skinId: skinId ? skinId : customSkin.skinId,
      backgroundPreview,
      skin: {
        background,
        staticBackground,
        imageUrl,
        imageDisplayMode,
        baseTheme: theme,
      },
    };
  }

  return {
    ...customSkin,
    backgroundPreview: 'none',
    skin: {
      background: '',
      staticBackground: '',
      image,
      imageDisplayMode,
      baseTheme: theme,
    },
  };
};
