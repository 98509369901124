import { Action } from 'redux-actions';
import {
  all,
  takeLatest,
  select,
  call,
  put,
  debounce,
} from 'redux-saga/effects';
import { Url } from '@wix/da-url';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { getProfileOwnerUser } from '../selectors/users';
import {
  fetchProfileOwnerGiveState,
  fetchProfileOwnerGiveStateSuccess,
  fetchProfileOwnerGiveStateFailure,
  fetchProfileOwnerSuccess,
  FetchProfileOwnerSuccessPayload,
  blockProfileOwner,
  showShareLinkPopup,
} from '../actions/profileOwner';
import { setWatchingState } from '@wix/da-shared-react/pkg/WatchButton/redux/actionCreators';
import { requestPuppy, isApiError } from '@wix/da-http-client';

function* fetchFromApi(requestUrl, params) {
  return yield call(
    requestPuppy,
    {
      method: 'get',
      url: requestUrl,
      params,
    },
    undefined,
    'dauserprofile'
  );
}

export function* fetchProfileOwnerGiveStateSaga() {
  const profileOwnerUser = yield select(getProfileOwnerUser);

  const result = yield call(fetchFromApi, 'give_menu/status', {
    username: profileOwnerUser.username,
  });

  const data = result;

  if (!data || isApiError(data)) {
    yield put(fetchProfileOwnerGiveStateFailure());
    return;
  }

  yield put(
    fetchProfileOwnerGiveStateSuccess({
      canBeGivenLlama: data.canGiveLlama,
      canBeGivenCake: data.canGiveCake,
      canBeGivenCore: data.canGiveCore,
    })
  );
  return;
}
export function* setupWatchingStatusSaga(
  action: Action<FetchProfileOwnerSuccessPayload>
) {
  const { isWatching } = action.payload;
  const profileOwnerUser = yield select(getProfileOwnerUser);
  yield put(setWatchingState(profileOwnerUser.username, isWatching));

  return;
}

export function* blockProfileOwnerSaga() {
  const profileOwnerUser = yield select(getProfileOwnerUser);

  yield put(
    pushModal(ModalType.USER_BLOCK, {
      params: {
        user: profileOwnerUser,
        redirectLocation: Url.browseHomeLink(),
      },
    })
  );
}

export function* showShareLinkPopupSaga() {
  const profileOwnerUser = yield select(getProfileOwnerUser);

  yield put(
    pushModal(ModalType.SHARE_LINK, {
      wrapInModal: true,
      params: {
        title: profileOwnerUser.username,
        url: Url.userLink(profileOwnerUser.username),
      },
    })
  );
}

export default function* rootFeedSaga() {
  yield all([
    debounce(500, fetchProfileOwnerGiveState, fetchProfileOwnerGiveStateSaga),
    takeLatest(fetchProfileOwnerSuccess, setupWatchingStatusSaga),
    takeLatest(blockProfileOwner, blockProfileOwnerSaga),
    takeLatest(showShareLinkPopup, showShareLinkPopupSaga),
  ]);
}
