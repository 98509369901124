import { call, select } from 'redux-saga/effects';
import { PapiRequestGruserModuleGroupActionlog } from '@wix/da-papi-types';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { GroupActionLogResultSchema } from '../../schemas/modules';
import fetchModuleStream from './fetchModuleStream';

export default function* fetchGroupActionLogStream(action) {
  const { roleId, sortValue } = yield select(
    streamSelectors.getStreamParams,
    action.streamId
  );
  const extraParams: Partial<PapiRequestGruserModuleGroupActionlog> = {
    instigator_roleid: roleId,
    sort_by: sortValue,
  };

  return yield call(
    fetchModuleStream,
    action,
    GroupActionLogResultSchema,
    extraParams
  );
}
