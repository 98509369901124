import React, { useContext, useCallback, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { PapiModuleLlamaFarm } from '@wix/da-papi-types';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import ZoneContext from '@wix/da-gruser-shared/pkg/context/ZoneContext';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';

import Llama from './_partials/Llama';
import Background from './_partials/Background';
import GiveLlamaButton from './_partials/GiveLlamaButton';

import { LlamaInfoWithPosition, DefaultLlama } from './types';
import {
  DEFAULT_BACKGROUND,
  BACKGROUND_RATIO,
  MAX_BACKGROUND_HEIGHT,
  MIN_BACKGROUND_HEIGHT,
} from './constants';
import { getMeasuredCookieType } from './helpers';

import s from './LlamaFarm.scss';

export interface Props {
  moduleId: number;
}

const LlamaFarm: React.FC<Props> = ({ moduleId }) => {
  const zoneName = useContext(ZoneContext)?.name;
  const [giftedLlama, setGiftedLlama] = useState<LlamaInfoWithPosition | null>(
    null
  );
  const { ref: inViewRef, inView } = useInView();
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    getMeasuredCookieType(zoneName!),
    {
      cookieDim: 'width',
    }
  );

  const { moduleData } = useModuleData<PapiModuleLlamaFarm>(moduleId);
  const { llamas = [], backgroundIndex = DEFAULT_BACKGROUND } = moduleData;

  const giveLlama = useCallback(() => setGiftedLlama(DefaultLlama), []);
  // LlamaFarm has a fixed aspect ratio.
  // It is fluid for the heights between MIN_BACKGROUND_HEIGHT..MAX_BACKGROUND_HEIGHT.
  // The width is calculdated from the height to preserve the aspect ratio.
  const containerWidth = measuredContainer?.width ?? 300;
  const containerHeight = containerWidth / BACKGROUND_RATIO;
  const height = Math.min(
    MAX_BACKGROUND_HEIGHT,
    Math.max(containerHeight, MIN_BACKGROUND_HEIGHT)
  );
  const width = height * BACKGROUND_RATIO;
  const scale = height / MIN_BACKGROUND_HEIGHT;

  return (
    <>
      <div ref={ref} className={s['root']}>
        <div
          ref={inViewRef}
          className={s['wrapper']}
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          <Background backgroundIndex={backgroundIndex}>
            {inView &&
              llamas.map((llama, index) => (
                <Llama
                  key={`${index}-${scale}`}
                  llama={llama}
                  scale={scale}
                  animation="hopping"
                  animationDelay={(index / llamas.length) * 6000}
                />
              ))}
            {inView && giftedLlama && (
              <Llama llama={giftedLlama} scale={scale} animation="gifted" />
            )}
          </Background>
          <GiveLlamaButton
            className={s['give-button']}
            onLlamaGiven={giveLlama}
          />
        </div>
      </div>
    </>
  );
};

LlamaFarm.displayName = 'LlamaFarm';

export default LlamaFarm;
