import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { AppState } from '../../../../../types/store';
import { Props } from './ActionLogFilters';
import { filterGroupActionLogByRole } from '../../../../actions/backroomSection';
import { GROUP_ACTIONLOG_IDS_STREAM } from '../../../../streams';

type StateProps = Pick<Props, 'selectedRoleId'>;
type DispatchProps = Pick<Props, 'onSelectRole'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const { roleId } =
    streamSelectors.getStreamParams(state, GROUP_ACTIONLOG_IDS_STREAM) || {};
  return {
    selectedRoleId: roleId,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  onSelectRole: filterGroupActionLogByRole,
};

export default connect(mapStateToProps, mapDispatchToProps);
