import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  selectors,
  withOffset as streamWithOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { getPageSize } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { Props } from './GallectionSearchView';
import { AppState } from '../../../../../types/store';
import {
  DEVIATION_SEARCH_RESULTS_STREAM_ID,
  DEVIATIONS_PER_FETCH_COUNT,
  FAVOURITES_SEARCH_RESULTS_STREAM_ID,
  PRIVATE_COLLECTION_SEARCH_RESULTS_STREAM_ID,
} from '../../../../streams/folderDeviations';
import {
  getSearchQuery,
  getGallectionSectionType,
  getSortOrder,
  getShouldHideFolderSlider,
} from '../../../../selectors/gallectionSection';

import { getCurrentPage } from '../../../../selectors/pageInfo';
import { GallectionType } from '../../../../../types/gallection';
import { getIsPagedMode } from '../../../../selectors/users';

export type StateProps = Pick<
  Props,
  | 'streamId'
  | 'streamIsInitialized'
  | 'streamItems'
  | 'streamHasMore'
  | 'streamIsFetching'
  | 'streamTotalCount'
  | 'streamSearchQuery'
  | 'streamSortOrder'
  | 'searchQuery'
  | 'gallectionType'
  | 'sortOrder'
  | 'isPaginated'
  | 'currentPage'
  | 'hideFolderSlider'
>;
export type DispatchProps = Pick<Props, 'streamInit' | 'streamFetchMore'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;
export type MergeStateProps = StateProps & {
  pageSize: number;
};
export type MergeDispatchProps = {
  streamInit: (state: MergeStateProps) => DispatchProps['streamInit'];
};

function getStreamIdFromGallectionType(gallectionType) {
  switch (gallectionType) {
    case GallectionType.COLLECTION:
      return FAVOURITES_SEARCH_RESULTS_STREAM_ID;
    case GallectionType.PRIVATE_COLLECTION:
      return PRIVATE_COLLECTION_SEARCH_RESULTS_STREAM_ID;
    default:
      return DEVIATION_SEARCH_RESULTS_STREAM_ID;
  }
}

const mapStateToProps: MapStateToProps<
  MergeStateProps,
  OwnProps,
  AppState
> = state => {
  const gallectionType = getGallectionSectionType(state);
  const streamId = getStreamIdFromGallectionType(gallectionType);
  const streamTotalCount = selectors.getTotal(state, streamId);
  return {
    streamId,
    streamTotalCount,
    streamItems: getDeviationsByIds(
      state,
      selectors.getItems(state, streamId) || []
    ),
    streamHasMore: selectors.hasMore(state, streamId),
    streamIsFetching: selectors.isFetching(state, streamId),
    streamIsInitialized: selectors.isInitialized(state, streamId),
    streamSearchQuery: selectors.getStreamParam(state, streamId, 'searchQuery'),
    streamSortOrder: selectors.getStreamParam(state, streamId, 'sortOrder'),
    searchQuery: getSearchQuery(state),
    sortOrder: getSortOrder(state),
    gallectionType,
    currentPage: getCurrentPage(state),
    isPaginated: getIsPagedMode(state),
    pageSize: getPageSize(state),
    hideFolderSlider: getShouldHideFolderSlider(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<
  MergeDispatchProps,
  OwnProps
> = dispatch => ({
  streamInit:
    ({ pageSize, isPaginated }) =>
    (searchQuery, sortOrder, gallectionType, page = 1) => {
      const streamId = getStreamIdFromGallectionType(gallectionType);
      const currentOffset = (page - 1) * pageSize;
      // When using pagination, we want to favor the pageSize chosen by the user,
      // otherwise, for infinite scroll, we want to use the smallest number of
      // items per fetch.
      dispatch(
        streamWithOffset.actions.initialize({
          streamId,
          hasMore: true,
          itemsPerFetch: isPaginated ? pageSize : DEVIATIONS_PER_FETCH_COUNT,
          prevOffset: isPaginated ? currentOffset - pageSize : undefined,
          nextOffset: isPaginated ? currentOffset : undefined,
          streamParams: {
            searchQuery,
            sortOrder,
          },
        })
      );
    },
  streamFetchMore: gallectionType => {
    const streamId = getStreamIdFromGallectionType(gallectionType);
    dispatch(streamWithOffset.actions.fetch(streamId));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };
  return {
    ...props,
    streamInit: dispatchProps.streamInit(stateProps),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
