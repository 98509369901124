import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import StandardDropdown from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown';
import { GallectionSortOrder } from '../../../../../types/gallection';

export interface Props {
  sorderOrder: GallectionSortOrder;
  setSortOrder: (value: GallectionSortOrder) => void;
  className?: string;
}

class SortOrderSwitcher extends React.Component<Props & WithTranslation> {
  render() {
    const { sorderOrder, setSortOrder, className } = this.props;
    const items = this.getOptions();
    return (
      <StandardDropdown
        role="menu"
        className={className}
        placement="bottom-end"
        selectedValue={sorderOrder}
        onSelect={setSortOrder}
        items={items}
      />
    );
  }

  getOptions = () => {
    const { t } = this.props;
    return [
      {
        value: GallectionSortOrder.NEWEST,
        label: t!('common.sortOrder.newest'),
      },
      {
        value: GallectionSortOrder.POPULAR,
        label: t!('common.sortOrder.popular'),
      },
    ];
  };
}

export default withTranslation()(SortOrderSwitcher);
