import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  PapiDaUserProfileTemplatesFetch,
  PapiDaUserProfileTemplatesList,
} from '@wix/da-papi-types';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { FetchType } from '@wix/da-shared-react/pkg/Stream/base/types';
import { OffsetStream } from '@wix/da-shared-react/pkg/Stream/withOffset/types';
import { getStream } from '@wix/da-shared-react/pkg/Stream/base/selectors';
import {
  fetchMoreCustomboxTemplates,
  saveCustomboxWithTemplate,
} from '../actions/customSectionTemplates';
import { streamId } from '../../types/customBoxTemplates';
import { getProfileGruser, getProfileOwnerUser } from '../selectors/users';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import i18next from 'i18next';
import { requestPuppy, isApiError } from '@wix/da-http-client';
import { requestPuppyGruser } from './gruserHelpers';
import {
  fetchModuleEndpoint,
  fetchModuleEndpointPurgeData,
} from '@wix/da-gruser-shared/pkg/redux/actions/modules';

function* handleFetchMoreTemplates() {
  const stream: OffsetStream = yield select(getStream, streamId);
  const profileOwnerUser = yield select(getProfileOwnerUser);
  const gruserType =
    profileOwnerUser && isGroup(profileOwnerUser) ? 'group' : 'profile';
  const response: PapiDaUserProfileTemplatesList = yield yield call(
    requestPuppy,
    {
      method: 'get',
      url: '/templates/list',
      params: {
        offset: stream.nextOffset,
        limit: stream.itemsPerFetch,
        type: gruserType,
      },
    },
    undefined,
    'dauserprofile'
  );

  if (isApiError(response)) {
    yield put(
      withOffset.actions.fetchFailure({
        streamId,
        fetchType: FetchType.Next,
        errorMsg: response.error,
      })
    );
  } else {
    yield put(
      withOffset.actions.fetchSuccess({
        streamId,
        items: response.results,
        hasMore: !!response.hasMore,
        nextOffset: response.nextOffset,
      })
    );
  }
}

function* handleSaveCustomboxWithTemplate(
  action: ReturnType<typeof saveCustomboxWithTemplate>
) {
  const fetchResponse: PapiDaUserProfileTemplatesFetch = yield call(
    requestPuppyGruser,
    {
      url: '/templates/fetch',
      params: { templateid: action.payload.template.templateId },
    },
    undefined,
    'dauserprofile'
  );

  if (isApiError(fetchResponse) || !fetchResponse.template) {
    yield putErrorNotification(
      i18next.t('widgets.custombox.templatePicker.fetchFailed')
    );
    return;
  }

  const fullTemplate = fetchResponse.template;
  const moduleId = action.payload.moduleId;

  const gruser = yield select(getProfileGruser);
  yield all([
    put(
      fetchModuleEndpoint(gruser!, {
        path: 'papi_custombox/save',
        method: 'post',
        id: moduleId,
        title: fullTemplate.title,
        editorRaw: fullTemplate.customtext?.html.markup,
        background_type: fullTemplate.backgroundType,
        color: fullTemplate.color,
        gradient: fullTemplate.gradient,
        image_display_mode: fullTemplate.imageDisplayMode,
        image_tint: fullTemplate.imageTint,
        image_url: fullTemplate.backgroundImage,
        // pattern is stored as string URL, and we need to send its id to the server
        pattern: 0,
        // this only happens on module creation, the version should always be 0
        version: 0,
      })
    ),
    put(
      fetchModuleEndpointPurgeData(gruser!, {
        id: moduleId,
        path: 'papi_custombox/config',
      })
    ),
  ]);
}

export default function* customBoxTemplatesSaga() {
  yield all([
    takeLatest(fetchMoreCustomboxTemplates, handleFetchMoreTemplates),
    takeLatest(saveCustomboxWithTemplate, handleSaveCustomboxWithTemplate),
  ]);
}
