import React from 'react';
import classnames from 'classnames';
import { PapiGallection } from '@wix/da-papi-types';

import s from './FolderBreadcrumbs.scss';

export interface Props {
  folder?: PapiGallection | null;
  subfolder?: PapiGallection | null;
  unsetSelectedSubfolderId: () => void;
}

export const FolderBreadcrumbs: React.FC<Props> = ({
  folder,
  subfolder,
  unsetSelectedSubfolderId,
}) => {
  if (!folder || !subfolder) {
    return null;
  }

  return (
    <div className={s['root']}>
      <div
        className={classnames(s['crumb'], s['folder'])}
        onClick={unsetSelectedSubfolderId}
      >
        {folder.name}
      </div>
      <div className={s['crumb']}>
        {subfolder.name} {subfolder.size}
      </div>
    </div>
  );
};
FolderBreadcrumbs.displayName = 'FolderBreadcrumbs';

export default FolderBreadcrumbs;
