import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import { fetchProfileOwnerGiveState } from '../../../../../actions/profileOwner';
import { Props } from './MoreActionsDropdownMobile';

type StateProps = Pick<Props, never>;
type DispatchProps = Pick<Props, 'initGiveState'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  initGiveState: () => dispatch(fetchProfileOwnerGiveState()),
});

export default connect(mapStateToProps, mapDispatchToProps);
