import {
  getPageInfoInitActions,
  getCustomInitActionsForWidgets,
} from './common';
import {
  setSelectedTierEntityId,
  setSelectedTierContentType,
} from '../../actions/subscriptionsSection';
import { getCompositeId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { DeviationResourceType } from '@wix/da-shared-react/pkg/types/deviation';
import { getSubscriptionWidgetConfigs } from '../../components/Sections/SubscriptionsSection/subscriptionWidgetConfigs';

export const getCustomInitActionsForSubscriptionWidgets = (
  responseData,
  requestParams
) => {
  const requestTierDeviationId =
    parseInt(requestParams.tier_deviationid) || undefined;

  const selectedTierDeviationId = requestTierDeviationId;

  return [
    ...(selectedTierDeviationId
      ? [
          setSelectedTierEntityId(
            getCompositeId(DeviationResourceType.TIER, selectedTierDeviationId)
          ),
        ]
      : []),
    setSelectedTierContentType(requestParams.section),
    ...getCustomInitActionsForWidgets(
      responseData,
      requestParams,
      getSubscriptionWidgetConfigs({ t: () => null, dispatch: () => null })
    ),
    ...getPageInfoInitActions(responseData, requestParams),
  ];
};
