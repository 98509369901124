import React from 'react';
import { useTranslation } from 'react-i18next';
import BirthdayCake from '@wix/da-ds/pkg/Icons/BirthdayCake';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import BirthdayCakeAnimated from '@wix/da-ds/pkg/Icons/BirthdayCakeAnimated';
import LocationPin from '@wix/da-ds/pkg/Icons/24x24/LocationPin';
import DeviantArtLogo from '@wix/da-ds/pkg/Icons/DeviantArtLogo';
import { dateToDistanceTrans } from '@wix/da-ds/pkg/Time/functions';
import Link from '@wix/da-ds/pkg/Icons/24x24/Link';
import { BulletItem } from '@wix/da-shared-react/pkg/Bullets';
import { getPronounKeyFromGender, sanitizeExternalUrl } from '../utils';
import UserInfoBulletList from './UserInfoBulletList/UserInfoBulletList';
import s from './UserInfoBullets.scss';

function getJoinDateFromDeviantFor(deviantFor) {
  const joinDate = new Date();
  joinDate.setTime(joinDate.getTime() - deviantFor * 1000);
  return dateToDistanceTrans(joinDate.toISOString());
}

interface Props {
  dateOfBirth?: string;
  countryCode?: string;
  deviantFor?: number;
  gender?: string | null;
  website?: string | null;
  websiteLabel?: string | null;
  isBirthweek?: boolean;
  username?: string;
}

const UserInfoBullets: React.FC<Props> = ({
  dateOfBirth,
  countryCode,
  deviantFor,
  gender,
  website,
  websiteLabel,
  isBirthweek,
  username,
}) => {
  const { t } = useTranslation();
  const deviantForDisplay = getJoinDateFromDeviantFor(deviantFor);
  return (
    <div className={s['root']}>
      <UserInfoBulletList>
        {dateOfBirth &&
          (isBirthweek ? (
            <SimpleTooltip
              placement="bottom"
              text={t('common.birthdayCake', {
                username,
              })}
            >
              <BulletItem
                bullet={
                  <BirthdayCakeAnimated
                    disableDefaultColors
                    size={IconSize.LARGE}
                  />
                }
              >
                {dateOfBirth}
              </BulletItem>
            </SimpleTooltip>
          ) : (
            <BulletItem bullet={<BirthdayCake disableDefaultColors />}>
              {dateOfBirth}
            </BulletItem>
          ))}
        {!!countryCode && (
          <BulletItem bullet={<LocationPin disableDefaultColors />}>
            {t!(`widgets.aboutMe.country.${countryCode || 'unknown'}`)}
          </BulletItem>
        )}
        <BulletItem bullet={<DeviantArtLogo disableDefaultColors />}>
          {t!('widgets.aboutMe.deviantFor', {
            deviantFor: t(deviantForDisplay.key, {
              count: deviantForDisplay.value,
            }),
          })}
        </BulletItem>
        {website && (
          <BulletItem bullet={<Link disableDefaultColors />}>
            <a
              href={sanitizeExternalUrl(website)}
              rel="nofollow noopener noreferrer ugc"
              target="_blank"
              className={s['url']}
            >
              {websiteLabel || website}
            </a>
          </BulletItem>
        )}
        {gender && (
          <BulletItem>{t(getPronounKeyFromGender(gender))}</BulletItem>
        )}
      </UserInfoBulletList>
    </div>
  );
};
UserInfoBullets.displayName = 'UserInfoBullets';

export default UserInfoBullets;
