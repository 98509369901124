import { handleActions } from 'redux-actions';
import StatsSection, {
  StatsTimePeriod,
  MySubmissionsSortBy,
  BenchmarkSortBy,
} from '../../types/statsSection';
import {
  setStats,
  setTimePeriod,
  setMySubmissionsSortBy,
  setMySubmissionsIsLoading,
  setMySubmissionsStats,
  setBenchmarkSortBy,
  fetchRecentVisitors,
  fetchRecentVisitorsSuccess,
  removeBenchmarkUser,
  resultUserQuery,
  addBenchmarkUser,
  addBenchmarkUserSuccess,
} from '../actions/statsSection';

const defaultState: StatsSection = {
  timePeriod: StatsTimePeriod.Week,
  mySubmissionsSortBy: MySubmissionsSortBy.PublishDate,
  mySubmissionsIsLoading: false,
  benchmarkSortBy: BenchmarkSortBy.ProfileVisitors,
  benchmarkIsLoading: false,
  visitorsIsLoading: false,
  matchingUsers: [],
  stats: {},
};

export default handleActions<StatsSection, any>(
  {
    [setStats.toString()]: (state, action: ReturnType<typeof setStats>) => ({
      ...state,
      stats: action.payload || {},
    }),
    [setMySubmissionsStats.toString()]: (
      state,
      action: ReturnType<typeof setMySubmissionsStats>
    ) => ({
      ...state,
      stats: {
        ...state.stats,
        myContent: action.payload,
      },
    }),
    [setTimePeriod.toString()]: (
      state,
      action: ReturnType<typeof setTimePeriod>
    ) => ({
      ...state,
      timePeriod: action.payload,
    }),
    [setMySubmissionsSortBy.toString()]: (
      state,
      action: ReturnType<typeof setMySubmissionsSortBy>
    ) => ({
      ...state,
      mySubmissionsSortBy: action.payload,
    }),
    [setMySubmissionsIsLoading.toString()]: (
      state,
      action: ReturnType<typeof setMySubmissionsIsLoading>
    ) => ({
      ...state,
      mySubmissionsIsLoading: action.payload,
    }),
    [setBenchmarkSortBy.toString()]: (
      state,
      action: ReturnType<typeof setBenchmarkSortBy>
    ) => ({
      ...state,
      benchmarkSortBy: action.payload,
    }),
    [addBenchmarkUser.toString()]: state => ({
      ...state,
      matchingUsers: [],
      benchmarkIsLoading: true,
    }),
    [addBenchmarkUserSuccess.toString()]: (
      state,
      action: ReturnType<typeof addBenchmarkUserSuccess>
    ) => ({
      ...state,
      stats: {
        ...state.stats,
        compareYourself: {
          frameId: state.stats.compareYourself!.frameId,
          timePeriods: {
            P7D: [
              ...state.stats.compareYourself!.timePeriods.P7D!,
              ...action.payload.timePeriods.P7D!,
            ],
            P28D: [
              ...state.stats.compareYourself!.timePeriods.P28D!,
              ...action.payload.timePeriods.P28D!,
            ],
            P365D: [
              ...state.stats.compareYourself!.timePeriods.P365D!,
              ...action.payload.timePeriods.P365D!,
            ],
          },
        },
      },
      benchmarkIsLoading: false,
    }),
    [removeBenchmarkUser.toString()]: (
      state,
      action: ReturnType<typeof removeBenchmarkUser>
    ) => ({
      ...state,
      stats: {
        ...state.stats,
        compareYourself: {
          frameId: state.stats.compareYourself!.frameId,
          timePeriods: {
            P7D: state.stats.compareYourself!.timePeriods.P7D?.filter(
              entry => entry.user.userId !== action.payload
            ),
            P28D: state.stats.compareYourself!.timePeriods.P28D?.filter(
              entry => entry.user.userId !== action.payload
            ),
            P365D: state.stats.compareYourself!.timePeriods.P365D?.filter(
              entry => entry.user.userId !== action.payload
            ),
          },
        },
      },
    }),
    [resultUserQuery.toString()]: (
      state,
      action: ReturnType<typeof resultUserQuery>
    ) => ({
      ...state,
      matchingUsers: action.payload,
    }),
    [fetchRecentVisitors.toString()]: state => ({
      ...state,
      visitorsIsLoading: true,
    }),
    [fetchRecentVisitorsSuccess.toString()]: (
      state,
      action: ReturnType<typeof fetchRecentVisitorsSuccess>
    ) => {
      const oldPeriods = state.stats.recentVisitors!.timePeriods;
      const actionPeriods = action.payload.timePeriods;
      const newPeriods = {};

      for (const period in oldPeriods) {
        if (actionPeriods.hasOwnProperty(period)) {
          newPeriods[period] = {
            hasMore: actionPeriods[period].hasMore,
            nextOffset: actionPeriods[period].nextOffset,
            results: oldPeriods[period].results.concat(actionPeriods[period].results),
          };
        } else {
          newPeriods[period] = oldPeriods[period];
        }
      }
      return {
        ...state,
        stats: {
          ...state.stats,
          recentVisitors: {
            frameId: state.stats.recentVisitors!.frameId,
            timePeriods: newPeriods,
          }
        },
        visitorsIsLoading: false,
      };
    },
  },
  defaultState
);
