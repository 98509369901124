import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { selectors, withOffset } from '@wix/da-shared-react/pkg/Stream';
import { AppState } from '../../../../../../../types/store';
import { isCurrentUserProfileOwner } from '../../../../../../selectors/users';
import {
  TIER_CONTENT_DEVIATIONS_PER_FETCH,
  TIER_CONTENT_POSTS_PER_FETCH,
  TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID,
} from '../../../../../../streams/tierContent';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { Props } from './TierContentFeed';

type StateProps = Pick<
  Props,
  'isOwner' | 'isEmpty' | 'deviations' | 'isInitialized' | 'isFetching'
>;
type DispatchProps = Pick<Props, 'initFetch'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const normalizedDeviations =
    selectors.getItems(state, ownProps.streamId) || [];
  const deviations = getDeviationsByIds(state, normalizedDeviations);
  return {
    deviations,
    isOwner: isCurrentUserProfileOwner(state),
    isEmpty: deviations.length === 0,
    isInitialized: selectors.isInitialized(state, ownProps.streamId),
    isFetching: selectors.isFetching(state, ownProps.streamId),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => {
  return {
    initFetch: () => {
      if (!ownProps.tier) {
        return;
      }
      dispatch(
        withOffset.actions.initialize({
          streamId: ownProps.streamId,
          groupId: TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID,
          itemsPerFetch:
            ownProps.tierContentType === 'posts'
              ? TIER_CONTENT_POSTS_PER_FETCH
              : TIER_CONTENT_DEVIATIONS_PER_FETCH,
          streamParams: {
            tierDeviationId: ownProps.tier?.deviationId,
            section: ownProps.tierContentType,
          },
        })
      );
      dispatch(withOffset.actions.fetch(ownProps.streamId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
