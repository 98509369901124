import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './SubfolderCard';
import { AppState } from '../../../../../types/store';
import { getSelectedSubfolderId } from '../../../../selectors/gallectionSection';
import { setSelectedSubfolderId } from '../../../../actions/gallectionSection';

export type StateProps = Pick<Props, 'isActive'>;
export type DispatchProps = Pick<Props, 'onClick'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps: OwnProps
) => ({
  isActive: getSelectedSubfolderId(state) === ownProps.subfolder.folderId,
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps: OwnProps
) => ({
  onClick: () => dispatch(setSelectedSubfolderId(ownProps.subfolder.folderId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
