import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';

export interface Props {
  className?: string;
  value: string;
  onChange: (event) => void;
}

const GroupNotesSearch: React.FC<Props> = ({ className, value, onChange }) => {
  const id = useUniqueId();
  const { t } = useTranslation();

  return (
    <TextInput
      id={id}
      value={value}
      label={t('widgets.group_notes.search.label')}
      isLabelHidden={true}
      autoComplete="off"
      className={className}
      placeholder={t('widgets.group_notes.search.placeholder')}
      onChange={onChange}
      prefix={<SearchIcon size={IconSize.SMALLER} disableDefaultColors />}
    />
  );
};

GroupNotesSearch.displayName = 'GroupNotesSearch';

export default GroupNotesSearch;
