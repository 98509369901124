import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import FeaturedDeviationBox from './FeaturedDeviationBox';
import FeaturedDeviationConfigPopoverLazy from './FeaturedDeviationConfigPopover/FeaturedDeviationConfigPopoverLazy';

export const getFeaturedDeviationWidgetConfig = ({
  t,
  dispatch,
  isGroup = false,
}): WidgetConfig => ({
  viewComponent: FeaturedDeviationBox,
  configPopoverComponent: FeaturedDeviationConfigPopoverLazy,
  autoShowWidgetConfig: true,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-10-spotlight.svg',
    title: t('widgets.featured_deviation.addButtonHeader'),
    description: t(
      isGroup
        ? 'widgets.featured_deviation.group.description'
        : 'widgets.featured_deviation.description'
    ),
  },
});

export const getFeaturedPostWidgetConfig = ({
  t,
  dispatch,
  isGroup = false,
}): WidgetConfig => ({
  viewComponent: FeaturedDeviationBox,
  configPopoverComponent: FeaturedDeviationConfigPopoverLazy,
  autoShowWidgetConfig: true,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-7-pinned-post.svg',
    title: t('widgets.featured_post.addButtonHeader'),
    description: t(
      isGroup
        ? 'widgets.featured_post.group.description'
        : 'widgets.featured_post.description'
    ),
  },
});
