import React from 'react';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { PapiGallection } from '@wix/da-papi-types';
import FolderShopCard from '@wix/da-shared-react/pkg/ShopCard/FolderShopCard';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';
import { ShopItemType } from '../../../../../../../types/shop';
import UploadMore from '../../../_partials/UploadMore';

interface Props {
  showAddItemCard?: boolean;
  folders: PapiGallection[];
}

const PremiumFolderSliderMobile: React.FC<Props> = ({
  showAddItemCard,
  folders,
}) => {
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    undefined,
    {
      cookieDim: 'width',
    }
  );

  const DEFAULT_CARD_WIDTH = 320;
  const DEFAULT_CARD_HEIGHT = 292;

  const cardWidthMobile = measuredContainer?.width ?? DEFAULT_CARD_WIDTH;

  return (
    <div ref={ref}>
      <SwipeSlider startSlide={showAddItemCard ? 1 : 0}>
        {showAddItemCard && (
          <UploadMore
            itemType={ShopItemType.PREMIUM_FOLDERS}
            height={DEFAULT_CARD_HEIGHT}
            biData={BiData<DefaultBiEvent>({
              evid: 2,
              click_info: 'create_premium_gallery',
              sectionname: BiContextProvidedValue,
            })}
          />
        )}
        {folders.map(folder => (
          <FolderShopCard
            key={folder.folderId}
            width={cardWidthMobile}
            folder={folder}
            withAuthorDetails={false}
            withIndicators={false}
          />
        ))}
      </SwipeSlider>
    </div>
  );
};
PremiumFolderSliderMobile.displayName = 'PremiumFolderSliderMobile';

export default PremiumFolderSliderMobile;
