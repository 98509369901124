import * as yup from 'yup';

const MAX_NOTE_LENGTH = 300;

export const validationSchema = yup.object({
  roleid: yup.number().required().min(1, 'common.formError.required'),
  user: yup
    .mixed()
    .test(
      'user-required',
      'common.formError.required',
      value => value !== undefined && value?.username?.length > 0
    )
    .test(
      'no-groups',
      'groups.invite.popup.form.errors.noGroups',
      value => !value?.isGroup
    ),
  note: yup.string().max(MAX_NOTE_LENGTH),

  // config options
  options: yup.object(),
});
