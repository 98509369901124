import React from 'react';
import { PapiDeviation, PapiUser } from '@wix/da-papi-types';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../../../../EmptyState';
import { SubscriptionsTierContentType } from '../../../../../../../types/subscriptionsSection';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import { Url } from '@wix/da-url';
import {
  ModalOptions,
  ModalType,
} from '@wix/da-shared-react/pkg/Modals/redux/types';
import { BiData, TierAddContentBiEvent } from '@wix/da-bi/pkg/events';
import { useSubmitUrl } from '@wix/da-shared-react/pkg/utils/hooks/useSubmitUrl';

import s from './EmptyState.scss';

const ctaButtonNameByTierContentType = {
  [SubscriptionsTierContentType.DEVIATIONS]: 'submit_deviation',
  [SubscriptionsTierContentType.POSTS]: 'submit_post',
};

export type Props = {
  isOwner: boolean;
  profileOwnerUser: PapiUser;
  isDraft?: boolean;
  tier?: PapiDeviation;
  tierContentType: SubscriptionsTierContentType;
  showSubmitModal?: (type: string, options?: ModalOptions) => void;
  publishDraftTier?: (deviation: PapiDeviation) => void;
};

const TierContentEmptyState: React.FC<Props> = ({
  profileOwnerUser,
  isOwner,
  isDraft,
  tierContentType,
  tier,
  publishDraftTier,
  showSubmitModal,
}) => {
  const { t } = useTranslation();
  const { username } = profileOwnerUser;
  const canUserPublish = tier?.tier?.canUserPublish;
  const SUBMIT_URL = useSubmitUrl(true);

  const illustration = (
    <div className={s[isOwner ? 'owner-illustration' : 'illustration']} />
  );
  let title = t('widgets.tierContent.empty.title.visitor');
  let subtitle = t(`widgets.tierContent.empty.subtitle.visitor`, {
    username,
  });
  let ctaButton;

  if (isOwner && isDraft) {
    if (canUserPublish) {
      // Has Core
      title = t('widgets.tierContent.empty.title.owner.draft');
      subtitle = t('widgets.tierContent.empty.subtitle.owner.draft');
      ctaButton = (
        <SolidButton
          className={s['cta']}
          size="medium"
          variant="approval-green"
          priority="primary"
          onClick={() => publishDraftTier && publishDraftTier(tier!)}
        >
          {t('widgets.tierContent.empty.cta.publish')}
        </SolidButton>
      );
    } else {
      // No Core
      title = t('widgets.tierContent.empty.title.noCore');
      subtitle = t('widgets.tierContent.empty.subtitle.noCore');
      ctaButton = (
        <BuyCoreBrandedButton
          size="large"
          biData={
            {
              referral_info: ReferralType.SUBSCRIPTIONS_GO_LIVE,
            } as any
          }
          withSaleSparkles
        >
          {t('widgets.tierContent.empty.cta.getCore')}
        </BuyCoreBrandedButton>
      );
    }
  } else if (isOwner) {
    title = t('widgets.tierContent.empty.title.owner');
    subtitle = t('widgets.tierContent.empty.subtitle.owner');
    ctaButton = (
      <SolidButton
        className={s['cta']}
        size="medium"
        variant="approval-green"
        priority="primary"
        biData={BiData<TierAddContentBiEvent>({
          evid: 532,
          typeid: ResourceType.TIER,
          itemid: tier?.deviationId ?? 0,
          button_name: ctaButtonNameByTierContentType[tierContentType],
        })}
        onClick={() => {
          if (tierContentType === SubscriptionsTierContentType.DEVIATIONS) {
            window.location.assign(Url.to(SUBMIT_URL));
            return;
          }
          showSubmitModal &&
            showSubmitModal(ModalType.STATUS_UPDATE, {
              params: { inTiers: [tier] },
            });
        }}
      >
        {t(`widgets.tierContent.empty.cta.${tierContentType}`)}
      </SolidButton>
    );
  }

  return (
    <EmptyState
      className={s['empty-state']}
      illustration={illustration}
      title={title}
      subtitle={subtitle}
      customButton={ctaButton}
    />
  );
};

TierContentEmptyState.displayName = 'TierContentEmptyState';

export default TierContentEmptyState;
