import { connect, MapStateToProps } from 'react-redux';
import { Props } from '@wix/da-shared-react/pkg/Duperbrowse/LoadableDeviationDuperbrowseContext';
import { AppState } from '../../../types/store';
import { getBiTypeAndItemId } from '../../selectors/biLogger';

type Parent = NonNullable<Props['parent']>;
type ParentWithoutBI = Pick<Parent, Exclude<keyof Parent, 'typeId' | 'itemId'>>;

export type StateProps = Pick<Props, 'parent'>;
export type OwnProps = Omit<Props, keyof StateProps> & {
  parent: ParentWithoutBI;
  children: any;
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  { parent, isLoadMoreAllowed = true }
) => {
  const { typeid, itemid } = getBiTypeAndItemId(state);
  return {
    parent: {
      ...parent,
      typeId: typeid,
      itemId: itemid,
    },
    isLoadMoreAllowed,
  };
};

export default connect(mapStateToProps);
