import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { useNormalizedFeed } from '@wix/da-feedback-shared/pkg/Feed/hooks';
import { InitializationStatus } from '@wix/da-feedback-shared/pkg/types';
import { FeedInfoContextProvider } from '@wix/da-feedback-shared/pkg/Feed/FeedInfoContext';
import {
  default as NotificationList,
  getNotificationListDisplayMode,
} from '@wix/da-feedback-shared/pkg/Feed/NotificationList';
import { getInitializationStatus } from '@wix/da-feedback-shared/pkg/redux/selectors/initialization';
import FeedScrollableContainer from '@wix/da-feedback-shared/pkg/Feed/FeedScrollableContainer';
import { NotificationFeaturesContextProvider } from '@wix/da-feedback-shared/pkg/notifications/NotificationFeaturesContext';

import { GROUP_MESSAGELOG_IDS_STREAM } from '../../../streams';
import EmptyState from '../../EmptyState';

import s from './MessageLog.scss';

export interface Props {
  moduleId: number;
}

export const MessageLog: React.FC<Props> = () => {
  const { t } = useTranslation();
  const feedInfo = useNormalizedFeed(GROUP_MESSAGELOG_IDS_STREAM, () => null);
  const initializationStatus = useSelector(getInitializationStatus);
  const {
    isEmpty,
    items,
    hasScrolled,
    mightNotBeScrollable,
    onNotScrollable,
    hasMore,
    fetchNext,
    isFetching,
  } = feedInfo;

  const onLoadMoreClick = useMemo(
    () =>
      mightNotBeScrollable &&
      !hasScrolled &&
      hasMore &&
      !isFetching &&
      fetchNext,
    [mightNotBeScrollable, hasScrolled, hasMore, isFetching, fetchNext]
  );

  if (isEmpty || !items.length) {
    return (
      <EmptyState
        view="small"
        className={s['empty-state']}
        title={t(`widgets.group_messagelog.empty.title.owner`)}
        subtitle={t(`widgets.group_messagelog.empty.subtitle.owner`)}
        buttonLabel={t(`widgets.group_messagelog.empty.CTALabel.owner`)}
      />
    );
  }

  return initializationStatus === InitializationStatus.Success ? (
    <FeedInfoContextProvider streamId={GROUP_MESSAGELOG_IDS_STREAM}>
      <NotificationFeaturesContextProvider
        features={{
          // Move operations require a destination folder, which we normally get from the active identity. As of now,
          // we do not have an active identity in the group's backroom. If we end up supporting this,
          // we'd probably just need to fetch the group identity that we use in NC.
          moveMessages: false,
          // Selection isn't spec'd for group messages. No selection header like we have in NC.
          selection: false,
          // Our code for marking all messages of a type as seen requires an active bucket. Notifications in the
          // group message log aren't bucketed. We could probably alter the code to support this, but that's more time.
          markAllOfTypeAsSeen: false,
        }}
      >
        <FeedScrollableContainer onNotScrollable={onNotScrollable}>
          {isEmpty && (
            <EmptyState
              title={t(`widgets.group_messagelog.empty.title.owner`)}
              subtitle={t(`widgets.group_messagelog.empty.subtitle.owner`)}
              buttonLabel={t(`widgets.group_messagelog.empty.CTALabel.owner`)}
            />
          )}
          {!isEmpty && (
            <NotificationList
              className={c(s['content-width'], s['notifications'])}
              items={items}
              displayMode={getNotificationListDisplayMode(feedInfo)}
              onLoadMoreClick={onLoadMoreClick}
            />
          )}
        </FeedScrollableContainer>
      </NotificationFeaturesContextProvider>
    </FeedInfoContextProvider>
  ) : null;
};

export default MessageLog;
