import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../types/store';
import {
  uploadAvatar,
  setIsEditorOpen,
  setErrorMessage,
} from '../../../../actions/avatar';
import { getIsGroupProfile } from '../../../../selectors/users';
import { Props } from './AvatarEdit';

export type StateProps = Pick<Props, 'errorMessage' | 'isOpen' | 'isGroup'>;
type DispatchProps = Pick<
  Props,
  'uploadAvatar' | 'setIsEditorOpen' | 'setErrorMessage'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const { isOpen, errorMessage } = state.avatarEditor || {};
  return {
    isOpen,
    errorMessage,

    isGroup: getIsGroupProfile(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  uploadAvatar,
  setIsEditorOpen,
  setErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps);
