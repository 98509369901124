import React, { useContext, useMemo } from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import Slider from '@wix/da-ds/pkg/Slider';
import ProfileFolderCard from '../ProfileFolderCard';
import AddNewFolderCard from '../AddNewFolderCard';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks/useFlag';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { filterOutEmpty } from '../../../../utils/gallection';
import { useGallectionFolders } from '../hooks/useGallectionFolders';
import { getCanCreateGalleryFolder } from '../../../../selectors/privs';
import {
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../../selectors/users';

import s from './FolderSlider.scss';

export interface Props {
  className?: string;
  cardWidth?: number;
}

const FolderSlider: React.FC<Props> = ({
  className,
  cardWidth: cardWidthFromProps,
}) => {
  const disableLoaders = useFlag('yumeruby'); // for debugging...
  const canCreateFolder = useSelector(getCanCreateGalleryFolder);
  const isProfileOwner = useSelector(isCurrentUserProfileOwner);
  const profileOwnerUser = useSelector(getProfileOwnerUser);

  const {
    hasMore,
    hasLess,
    fetchNext,
    fetchPrevious,
    isFetching,
    folders,
    selectedFolderId,
  } = useGallectionFolders();
  const foldersToRender = useMemo(
    () => (isProfileOwner ? folders : filterOutEmpty(folders)),
    [isProfileOwner, folders, filterOutEmpty]
  );

  const isMobile = useContext(MobileContext);
  const cardWidth = useMemo(
    () => cardWidthFromProps || parseInt(s['folder-card-width']),
    [isMobile, cardWidthFromProps]
  );
  const scrollToIndex = foldersToRender.findIndex(
    ({ folderId }) => folderId === selectedFolderId
  );

  return (
    <Slider
      className={c(s['folders-slider'], className)}
      scrollToIndex={scrollToIndex}
      smoothInitialScroll={false}
    >
      {hasLess && !disableLoaders && (
        <Waypoint onEnter={fetchPrevious} horizontal topOffset="-20%" />
      )}
      {foldersToRender.map((folder, index) => {
        return (
          <ProfileFolderCard
            withPrice
            width={cardWidth}
            className={s['folder-card']}
            key={folder.folderId}
            folder={folder}
            indexInsideParent={index}
            withLink
            isProfileOwner={isProfileOwner}
            profileOwnerUser={profileOwnerUser}
          />
        );
      })}
      {isFetching && <LoadingIndicator className={s['folder-card']} />}
      {hasMore && !disableLoaders && (
        <Waypoint onEnter={fetchNext} horizontal bottomOffset="-20%" />
      )}
      {canCreateFolder && (
        <AddNewFolderCard className={s['folder-card']} width={cardWidth} />
      )}
    </Slider>
  );
};
FolderSlider.displayName = 'FolderSlider';

export default FolderSlider;
