import { awardBadgeSuccess } from '@wix/da-shared-react/pkg/coreBadge/redux/actionCreators';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { getProfileOwnerUser } from '../selectors/users';
import { getModuleData, getModuleIdByModuleName } from '../selectors/modules';
import { ModuleType } from '../../types/modules';
import { updateModule } from '../actions/modules';

function* handleAwardBadge(action: ReturnType<typeof awardBadgeSuccess>) {
  const { badge, recipient } = action.payload;
  const owner = yield select(getProfileOwnerUser);

  if (owner.userId !== recipient.user.userId) {
    return;
  }

  const moduleId = yield select(getModuleIdByModuleName, ModuleType.ABOUT);
  if (!moduleId) {
    return;
  }
  const data = yield select(getModuleData, moduleId);
  if (!data) {
    return;
  }

  const existingBadges = data.badges || [];
  const idx = existingBadges.findIndex(
    existingBadge => existingBadge.name === badge.name
  );
  if (idx >= 0) {
    const newBadges = [...existingBadges];
    newBadges.splice(idx, 1);
    newBadges.unshift(badge);
    yield put(
      updateModule({
        id: moduleId,
        moduleData: {
          badges: newBadges,
        },
      })
    );
  } else {
    const newBadges = [badge, ...existingBadges];
    yield put(
      updateModule({
        id: moduleId,
        moduleData: {
          badges: newBadges,
        },
      })
    );
  }
}

export default function* rootCoreBadgeSaga() {
  yield all([takeEvery(awardBadgeSuccess, handleAwardBadge)]);
}
