import { AppState } from '../../types/store';
import { getModuleData, getModuleIdByModuleName } from './modules';
import { ModuleType } from '../../types/modules';
import { PapiBadge } from '@wix/da-papi-types';

export const getAboutMeData = (state: AppState, moduleId: number) =>
  getModuleData(state, moduleId) || {};

export const getBadges = (state: AppState, moduleId: number) =>
  getAboutMeData(state, moduleId).badges;

export const hasBadgesInstalled = (state: AppState) => {
  return getModuleIdByModuleName(state, ModuleType.ABOUT) ? true : false;
};

// Filter out available routes depending on features and ownership
export const hasSpecificBadge = (
  state: AppState,
  badgeTypeId: PapiBadge['typeId']
) => {
  const aboutMeModuleId = getModuleIdByModuleName(state, ModuleType.ABOUT);
  if (aboutMeModuleId) {
    const badges = getBadges(state, aboutMeModuleId);
    return badges.find(badge => badge.typeId === badgeTypeId);
  }

  return false;
};

export const hasPublicBirthday = (state: AppState) => {
  const aboutMeModuleId = getModuleIdByModuleName(state, ModuleType.ABOUT);
  if (aboutMeModuleId) {
    const aboutMeData = getAboutMeData(state, aboutMeModuleId);
    return aboutMeData && aboutMeData.dobMonth;
  }

  return false;
};

export const getBirthdayCakeBadge = (state: AppState) => {
  if (!hasBadgesInstalled(state)) {
    return false;
  }

  return hasSpecificBadge(state, 17);
};
