import { Action } from 'redux-actions';
import { PapiModuleGroupMessagelog } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { PapiNcMessageObjectSchema } from '@wix/da-feedback-shared/pkg/redux/normalizr/schemas';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';

import {
  GROUP_MESSAGELOG_IDS_STREAM,
  MESSAGES_PER_FETCH,
} from '../../../../streams';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleGroupMessagelog
): Action<any>[] => {
  const { hasMore, nextOffset, results } = moduleData;
  const { result: items, entities } = normalize(results, [
    PapiNcMessageObjectSchema,
  ]);
  return [
    putEntities({ entities }),
    {
      ...withOffset.actions.initialize({
        streamId: GROUP_MESSAGELOG_IDS_STREAM,
        streamParams: {
          moduleId,
        },
        itemsPerFetch: MESSAGES_PER_FETCH,
        items,
        entities,
        nextOffset: nextOffset || undefined,
        hasMore,
      }),
      payload:
        null /* stream intialize actions are not compatible with Action<any> */,
    },
  ];
};
