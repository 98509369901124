import { all, select, call, put } from 'redux-saga/effects';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { getProfileOwner } from '../../selectors/users';
import { PapiWatchingRequestParams } from '../../../types/papi';
import { isApiError } from '@wix/da-http-client';
import { requestPuppyGruser } from '../gruserHelpers';

function* fetchFromApi(params) {
  return yield call(
    requestPuppyGruser,
    {
      method: 'get',
      url: `/module/watching`,
      params,
    },
    undefined,
    'gruser'
  );
}

export default function* fetchMoreWatching(action) {
  const { streamId } = action;

  const [offset, limit, moduleId, profileOwner] = yield all([
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state => streamSelectors.getItemsPerFetch(state, streamId)),
    select(state =>
      streamSelectors.getStreamParam(state, streamId, 'moduleId')
    ),
    select(getProfileOwner),
  ]);

  const queryParams: PapiWatchingRequestParams = {
    username: profileOwner.user.username,
    moduleid: moduleId,
    offset,
    limit,
  };

  const response = yield call(fetchFromApi, queryParams);

  if (response && !isApiError(response)) {
    const { hasMore, nextOffset, results } = response;
    const { result, entities } = normalize(results, [userSchema]);

    yield put(
      withOffset.actions.fetchSuccess({
        streamId,
        hasMore,
        nextOffset,
        items: result,
        entities,
      })
    );
  }

  // TODO error catching
}
