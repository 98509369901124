import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import viewComponent from './ActiveMembersBox';
import initActionsCreator from './redux/initModule';

export const getWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-0-group-admin.svg',
    title: t('widgets.active_members.addButtonHeader'),
    description: t('widgets.active_members.description'),
  },
  initActionsCreator,
});

export default getWidgetConfig;
