import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import PromoBadge from '@wix/da-ds/pkg/Badges/PromoBadge';

import s from './TierContentDivider.scss';

export interface Props {
  className?: string;
  tier: PapiDeviation;
}

const TierContentDivider: React.FC<Props> = ({
  className,
  tier: { author = {}, tier = {} },
}) => {
  const { t } = useTranslation();
  const {
    isUserSubscribed = false,
    settings: { accessSettings = 'future_only' } = {},
  } = tier;
  const subscriptionSettings = isUserSubscribed ? 'subscribed' : 'unsubscribed';
  return (
    <div className={classnames(s['root'], className)}>
      <PromoBadge
        badgeType="tier"
        className={s['icon']}
        isLocked={true}
        size={40}
      />
      <div className={s['title']}>
        {t(
          `widgets.tierContent.divider.title.${subscriptionSettings}.${accessSettings}`,
          {
            username: author?.username,
          }
        )}
      </div>
      <div className={s['subtitle']}>
        {t(
          `widgets.tierContent.divider.subtitle.${subscriptionSettings}.${accessSettings}`,
          {
            username: author?.username,
          }
        )}
      </div>
    </div>
  );
};
TierContentDivider.displayName = 'TierContentDivider';

export default TierContentDivider;
