import { getAdoptableSliderWidgetConfig } from './ShopAll/AdoptableSlider/widgetConfig';
import { getCommissionSliderWidgetConfig } from './ShopAll/CommissionSlider/widgetConfig';
import { getPremiumContentSliderWidgetConfig } from './ShopAll/PremiumContentSlider/widgetConfig';
import { getPremiumFolderSliderWidgetConfig } from './ShopAll/PremiumFolderSlider/widgetConfig';
import { getShopRecommendationsWidgetConfig } from './ShopAll/ShopRecommendations/widgetConfig';
import getCoverDeviationWidgetConfig from '../../UserWidgets/CoverDeviation/widgetConfig';

export const getShopWidgetConfigs = ({ t, dispatch }) => ({
  coverDeviation: getCoverDeviationWidgetConfig({ t, dispatch }),
  adoptables: getAdoptableSliderWidgetConfig({ t, dispatch }),
  commissions: getCommissionSliderWidgetConfig({ t, dispatch }),
  premiumContent: getPremiumContentSliderWidgetConfig({ t, dispatch }),
  premiumFolders: getPremiumFolderSliderWidgetConfig({ t, dispatch }),
  shopRecommendations: getShopRecommendationsWidgetConfig({ t, dispatch }),
});
