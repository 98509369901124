import { useCallback, useMemo, useState } from 'react';
import omit from 'lodash/omit';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { BiData, MarketplaceFiltersBiEvent } from '@wix/da-bi/pkg/events';
import { navigate } from '../../helpers';
import { SubnavFilterValueMap, SubnavMap } from '../types';
import { FILTER_QUERYSTRING_KEYS, SHOP_FILTER_DEFAULTS } from '../constants';

export interface ReturnType {
  /** keys are subnav `type`s and values are `currentValue` in state */
  filterSubnavValues: SubnavFilterValueMap;
  /** how many filters are set to a non-default value */
  activeFilterCount: number;
  /** given a partial object `newFilters`, will merge with existing `filterSubnavValues` */
  updateFilters: (newFilters?: SubnavFilterValueMap) => void;
  /** as updateFilters, but will convert current subnavs to new querystring and navigate to it */
  updateFiltersAndReload: (newFilters?: SubnavFilterValueMap) => void;
  /** resets all `filterSubnavValues` to default values */
  resetFilters: () => void;
  /** as updateFiltersAndReload, but will convert current subnavs to new querystring and navigate to it */
  resetFiltersAndReload: () => void;
}

export default function useShopFilterState(
  filterSubnavMap: SubnavMap
): ReturnType {
  const logBiEvent = useBiLogger();

  const initialValues = {};
  for (const [subnavType, subnav] of Object.entries(filterSubnavMap)) {
    initialValues[subnavType] = subnav.currentValue;
  }
  const [filterSubnavValues, setFilterSubnavValues] =
    useState<SubnavFilterValueMap>(initialValues);

  const updateFilters = (newFilters: SubnavFilterValueMap): void => {
    setFilterSubnavValues(prevFilters => ({ ...prevFilters, ...newFilters }));
  };

  const updateFiltersAndReload = useCallback(
    (newFilters: SubnavFilterValueMap = {}): void => {
      const updatedFilters = { ...filterSubnavValues, ...newFilters };
      setFilterSubnavValues(updatedFilters);

      // remove any filters set to defaults so we don't send them in the querystring
      const nonDefaultFilters: SubnavFilterValueMap = omitMatchingPairs(
        updatedFilters,
        SHOP_FILTER_DEFAULTS
      );

      // convert current subnav states into queryParams for new querystring
      const queryParams = {};
      for (const filterType in nonDefaultFilters) {
        const subnav = filterSubnavMap[filterType] ?? {};
        if (!Array.isArray(subnav?.options)) continue;

        const selectedFilter = nonDefaultFilters[filterType];
        const optionSelected = subnav.options.find(
          option => option.subnav === selectedFilter
        );
        if (!optionSelected) continue;

        // remove params with falsy values (so far, those don't need to be sent)
        for (const [key, value] of Object.entries(
          optionSelected?.queryParams ?? {}
        )) {
          if (value) queryParams[key] = value;
        }
      }

      logBiEvent(
        BiData<MarketplaceFiltersBiEvent>({
          evid: 377,
          ...queryParams,
        })
      );

      if (typeof window === 'undefined') {
        return;
      }

      // remove any existing params that we handle here, replacing them with the updated set
      const newQuerystring = `${new URLSearchParams({
        ...omit(getCurrentSearchParamsAsObject(), FILTER_QUERYSTRING_KEYS),
        ...queryParams,
      })}`;
      navigate(
        newQuerystring
          ? `${window.location.pathname}?${newQuerystring}`
          : window.location.pathname
      );
    },
    [filterSubnavValues, logBiEvent, filterSubnavMap]
  );

  const activeFilterCount: number = useMemo(() => {
    const nonDefaultingFilters = omitMatchingPairs(
      filterSubnavValues,
      SHOP_FILTER_DEFAULTS
    );
    return Object.keys(nonDefaultingFilters).length;
  }, [filterSubnavValues]);

  return {
    filterSubnavValues,
    activeFilterCount,
    updateFilters,
    updateFiltersAndReload,
    resetFilters: (): void => updateFilters(SHOP_FILTER_DEFAULTS),
    resetFiltersAndReload: (): void =>
      updateFiltersAndReload(SHOP_FILTER_DEFAULTS),
  };
}

/** if an exact key+val pair from B are found in A, remove it from what's returned */
function omitMatchingPairs(
  objA: Record<string, any>,
  objB: Record<string, any>
): Record<string, any> {
  const filtered = {};
  for (const [key, value] of Object.entries(objA)) {
    if (objB[key] !== value) {
      filtered[key] = value;
    }
  }
  return filtered;
}

/** return the window's current querystring params as a plain object */
function getCurrentSearchParamsAsObject(): Record<string, string> {
  return typeof window !== 'undefined'
    ? Object.fromEntries(new URLSearchParams(window.location.search))
    : {};
}
