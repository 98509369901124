import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { Url } from '@wix/da-url';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import EmptyStateBase from '../EmptyState';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import CoreExclusiveFeature from '@wix/da-shared-react/pkg/indicators/CoreExclusiveFeature/CoreExclusiveFeature';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { PapiUser } from '@wix/da-papi-types';
import EmptyState from '../../../../EmptyState';
import { ShopItemType } from '../../../../../../types/shop';

import s from './PremiumContentEmptyState.scss';

export interface Props {
  view: 'wide' | 'large' | 'small';
  user: PapiUser;
  isOwner: boolean;
  hasCoreMembership?: boolean;
}

export const PremiumContentEmptyState: React.FC<Props> = ({
  view = 'large',
  isOwner,
  hasCoreMembership,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  if (isOwner) {
    if (hasCoreMembership) {
      return (
        <EmptyState
          className={classnames(s['root'], s[view])}
          title={t('pages.shop.emptyState.core.premiumContent.title')}
          titleClassName={s['title']}
          subtitle={
            isMobile
              ? t('pages.shop.emptyState.core.premiumContent.subtitle.mobile')
              : t('pages.shop.emptyState.core.premiumContent.subtitle')
          }
          subtitleClassName={s['subtitle']}
          illustration={<CoreExclusiveFeature withoutBuyCTA />}
          illustrationClassName={s['exclusive']}
          withButton
          buttonLabel={t('common.submit')}
          onButtonClick={() => {
            if (window) {
              window.location.replace(Url.studio({ openSubmitForm: true }));
            }
          }}
          view={'small'}
          biData={BiData<DefaultBiEvent>({
            evid: 2,
            click_info: 'create_premium_download',
            sectionname: 'premium_download',
          })}
        />
      );
    } else {
      return (
        <EmptyState
          className={classnames(s['root'], s[view])}
          title={t('pages.shop.emptyState.nonCore.premiumContent.title')}
          titleClassName={s['title']}
          subtitle={t(
            isMobile
              ? 'pages.shop.emptyState.nonCore.premiumContent.subtitle.mobile'
              : 'pages.shop.emptyState.nonCore.premiumContent.subtitle'
          )}
          subtitleClassName={s['subtitle']}
          illustration={<CoreExclusiveFeature withoutBuyCTA />}
          illustrationClassName={s['exclusive']}
          customButton={
            isMobile ? undefined : (
              <BuyCoreBrandedButton
                size="large"
                biData={
                  {
                    referral_info: ReferralType.SHOP_PREMIUM_DOWNLOADS,
                  } as any
                }
                withSaleSparkles
              >
                {t('widgets.tierContent.empty.cta.getCore')}
              </BuyCoreBrandedButton>
            )
          }
          view={'small'}
        />
      );
    }
  }

  return (
    <EmptyStateBase shopItemType={ShopItemType.PREMIUM_CONTENT} view="wide" />
  );
};

PremiumContentEmptyState.displayName = 'PremiumContentEmptyState';

export default PremiumContentEmptyState;
