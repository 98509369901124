import { Url } from '@wix/da-url';

export default function generatePaginationUrl(
  pageParam: number,
  requestUrl = '',
  paramName = 'page'
) {
  const { path, query } = Url.parseUrl(
    typeof window === 'undefined' ? requestUrl : window.location.href
  );
  const params = new URLSearchParams(query);
  params.delete(paramName);
  if (pageParam > 1) {
    params.set(paramName, pageParam.toString(10));
  }
  const updatedQuery = params.toString();
  if (!updatedQuery) {
    return path;
  }
  return `${path}?${updatedQuery}`;
}
