import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import Loader from '@wix/da-ds/pkg/Loader';
import EmptyState from '../../EmptyState';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '../../UserWidgets/_partials/WidgetBox';
import { default as GroupNotes } from '../GroupNotes';
import { Props as WidgetProps } from './GroupNotes';

import s from './GroupNotes.scss';

const GroupNotesBox: React.FC<WidgetProps & WidgetBoxProps> = ({
  isEmpty,
  isOwner,
  widgetRemove,
  widgetMoveUp,
  widgetMoveDown,
  widgetMenuRenderer,
  section,
  zone,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <WidgetBox
      section={section}
      zone={zone}
      header=""
      moduleId={props.moduleId}
      widgetMoveUp={widgetMoveUp}
      widgetMoveDown={widgetMoveDown}
      widgetRemove={widgetRemove}
      isEmpty={isEmpty}
      emptyView={
        isOwner ? (
          <EmptyState
            view="small"
            className={s['empty-state']}
            title={t(`widgets.group_messagelog.empty.title.owner`)}
            subtitle={t(`widgets.group_messagelog.empty.subtitle.owner`)}
            buttonLabel={t(`widgets.group_messagelog.empty.CTALabel.owner`)}
          />
        ) : null
      }
      className={s['root']}
    >
      <ErrorBoundary debugComponent="GroupNotesBox">
        <React.Suspense
          fallback={
            <Loader state="eating_grass" size="big" className={s['loader']} />
          }
        >
          <GroupNotes {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </WidgetBox>
  );
};
GroupNotesBox.displayName = 'GroupNotesBox';

export default GroupNotesBox;
