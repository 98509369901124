import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getModuleIdByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { ModuleType } from '../../../../types/modules';
import { getProfileGruser } from '../../../selectors/users';
import { Props } from './InviteToGroupModal';

export type StateProps = Pick<Props, 'moduleId'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = state => ({
  moduleId: getModuleIdByModuleName(
    state,
    getProfileGruser(state),
    ModuleType.GROUP_RECRUITMENT
  )!,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = () => ({});

export default connect(mapStateToProps, mapDispatchToProps);
