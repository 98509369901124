import React, { HTMLAttributes } from 'react';
import c from 'classnames';
import s from './RightSideButtonWrapper.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isCollapsed?: boolean;
  innerRef?: React.Ref<HTMLDivElement>;
}

const RightSideButtonWrapper: React.FC<Props> = ({
  isCollapsed,
  innerRef,
  className,
  ...rest
}) => (
  <div
    ref={innerRef}
    className={c(s['root'], isCollapsed && s['collapsed'], className)}
    {...rest}
  />
);
RightSideButtonWrapper.displayName = 'RightSideButtonWrapper';

export default RightSideButtonWrapper;
