import React from 'react';
import classnames from 'classnames';

import s from './LlamaSprite.scss';

export interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  src?: string;
}

export const LlamaSprite: React.FC<Props> = ({ className, src, ...rest }) => (
  <img
    src={`${src}`}
    alt="Llama sprite"
    draggable="false"
    className={classnames(s['root'], className)}
    {...rest}
  />
);

LlamaSprite.displayName = 'LlamaSprite';

export default LlamaSprite;
