import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { Url } from '@wix/da-url';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import EmptyStateBase from '../EmptyState';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import CoreExclusiveFeature from '@wix/da-shared-react/pkg/indicators/CoreExclusiveFeature/CoreExclusiveFeature';
import { PapiUser } from '@wix/da-papi-types';
import EmptyState from '../../../../EmptyState';
import { ShopItemType } from '../../../../../../types/shop';

import s from './AdoptablesEmptyState.scss';

export interface Props {
  view: 'wide' | 'large' | 'small';
  user: PapiUser;
  isOwner: boolean;
  hasCoreMembership?: boolean;
}

export const AdoptablesEmptyState: React.FC<Props> = ({
  view = 'large',
  isOwner,
  hasCoreMembership,
}) => {
  const { t } = useTranslation();

  if (isOwner) {
    if (hasCoreMembership) {
      return (
        <EmptyState
          className={classnames(s['root'], s[view])}
          title={t('pages.shop.emptyState.core.adoptables.title')}
          titleClassName={s['title']}
          subtitle={t('pages.shop.emptyState.core.adoptables.subtitle')}
          subtitleClassName={s['subtitle']}
          illustration={<CoreExclusiveFeature withoutBuyCTA />}
          illustrationClassName={s['exclusive']}
          withButton
          buttonLabel={t('common.submit')}
          onButtonClick={() => {
            if (window) {
              window.location.replace(Url.studio({ openSubmitForm: true }));
            }
          }}
          view={'small'}
          biData={BiData<DefaultBiEvent>({
            evid: 2,
            click_info: 'create_adoptable',
            sectionname: 'adoptable',
          })}
        />
      );
    } else {
      return (
        <EmptyState
          className={classnames(s['root'], s[view])}
          title={t('pages.shop.emptyState.nonCore.adoptables.title')}
          titleClassName={s['title']}
          subtitle={t('pages.shop.emptyState.nonCore.adoptables.subtitle')}
          subtitleClassName={s['subtitle']}
          illustration={<CoreExclusiveFeature withoutBuyCTA />}
          illustrationClassName={s['exclusive']}
          customButton={
            <BuyCoreBrandedButton
              size="large"
              biData={
                {
                  referral_info: ReferralType.SHOP_ADOPTABLES,
                } as any
              }
              withSaleSparkles
            >
              {t('widgets.tierContent.empty.cta.getCore')}
            </BuyCoreBrandedButton>
          }
          view={'small'}
        />
      );
    }
  }

  return (
    <EmptyStateBase shopItemType={ShopItemType.PREMIUM_CONTENT} view="wide" />
  );
};

AdoptablesEmptyState.displayName = 'AdoptablesEmptyState';

export default AdoptablesEmptyState;
