import { ThemeSkin } from '@wix/da-react-context/pkg/ThemeContext';
import cssVars from '@wix/da-ds/src/styles/variables.scss';
import profilesCssVars from '../../styles/variables.scss';

export function getStickyContentHeight(isMobile = false): number {
  if (isMobile) {
    return (
      parseInt(profilesCssVars['mobile-navbar-height']) +
      parseInt(cssVars['site-header-height-mobile'])
    );
  }

  return parseInt(profilesCssVars['navbar-height']);
}

const colorMap = {
  '--D2': '#161a1f',
  '--D3': '#22272b',
  '--D4': '#292f34',
  '--D6': '#838592',
  '--D8': '#f2f2f2',
  '--G3': '#00e59b',
  '--G14': '#7579ff',
  '--G16': '#0289ff',
  '--G19': '#ff5dd3',
  '--L2': '#ffffff',
  '--L3': '#f9fafb',
  '--L4': '#eaeaea',
  '--L5': '#90909c',
  '--L8': '#0e0d20',
  '--L2-g': '#ffffff',
  '--L4-g': '#d1dcd0',
  '--L5-g': '#90909c',
  '--L8-g': '#0c0c0c',
};

/**
 * Returns color value from styles for given variable
 *
 * For now we need keep this array in sync with styles
 *
 * @param colorName variable name from styles
 * @returns color e.g. '#ffffff'
 */

export const getColorFromCssVariable = (
  colorName: string,
  themeSkin?: ThemeSkin
) =>
  colorMap[
    themeSkin && themeSkin === ThemeSkin.GREEN ? `${colorName}-g` : colorName
  ];
