import React from 'react';
import MediaSelector, {
  MediaSelectorProps,
} from '@wix/da-shared-react/pkg/MediaPopup/MediaSelector';
import { MediaSourceType } from '@wix/da-shared-react/pkg/MediaPopup/types';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import { useSelector } from 'react-redux';
import { getProfileOwnerUser } from '../../../../../selectors/users';
import { useTranslation } from 'react-i18next';

export interface Props extends MediaSelectorProps {
  type?: 'regular' | 'posts';
}

export const DeviationPicker: React.FC<Props> = ({
  type = 'regular',
  onSelect,
  onClose,
}) => {
  const { t } = useTranslation();
  const profileOwnerUser = useSelector(getProfileOwnerUser);

  const getMediaSourceTypes = () => {
    if (type === 'posts') {
      return [
        MediaSourceType.JOURNAL,
        MediaSourceType.STATUS,
        MediaSourceType.POLL,
      ];
    }
    // if it's a group profile page, don't show collections
    const dontShowCollections = profileOwnerUser?.isGroup;
    return dontShowCollections
      ? [MediaSourceType.GALLERY]
      : [MediaSourceType.GALLERY, MediaSourceType.COLLECTION];
  };

  const getAllowedDeviationTypes = () => {
    if (type === 'posts') {
      return [
        DeviationTypes.JOURNAL,
        DeviationTypes.STATUS,
        DeviationTypes.POLL,
      ];
    }
    return [
      DeviationTypes.IMAGE,
      DeviationTypes.FILM,
      DeviationTypes.LITERATURE,
    ];
  };

  return (
    <MediaSelector
      title={type === 'posts' ? t('mediapopup.selectPost') : undefined}
      initialMediaSource={
        type === 'posts' ? MediaSourceType.JOURNAL : MediaSourceType.GALLERY
      }
      mediaSourceTypes={getMediaSourceTypes()}
      allowedDeviationTypes={getAllowedDeviationTypes()}
      allowPremium={true}
      allowUploads={false}
      gruser={profileOwnerUser}
      onClose={onClose}
      onSelect={onSelect}
    />
  );
};
export default DeviationPicker;
