export default interface SubscriptionsSection {
  tierContentType?: SubscriptionsTierContentType;
  selectedTierEntityId?: string | number | null;
  stats?: SubscriptionsStatType[];
  chartData?: SubscriptionsChartPointType[];
}

export enum SubscriptionsTierContentType {
  DEVIATIONS = 'deviations',
  POSTS = 'posts',
}

export interface SubscriptionsStatType {
  income: number;
  subscribers: number;
  activeSubscribers: number;
}

export interface SubscriptionsChartPointType {
  startDate: string;
  income: number;
  subscribers: number;
}
