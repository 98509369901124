import { all, select, call, put } from 'redux-saga/effects';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { getProfileOwner } from '../../selectors/users';
import { PapiInitAdoptabobblesRequestParams } from '../../../types/papi';
import { ADOPTABOBBLES_PER_FETCH } from '../../streams/adoptabobbles';
import { requestPuppy, isApiError } from '@wix/da-http-client';

function* fetchFromApi(params) {
  return yield call(
    requestPuppy,
    {
      method: 'get',
      url: '/init/adoptabobbles',
      params,
    },
    undefined,
    'dauserprofile'
  );
}

export default function* fetchMoreBoughtAdoptabobbles(action) {
  const { streamId } = action;

  const [offset, limit, profileOwner] = yield all([
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state => streamSelectors.getItemsPerFetch(state, streamId)),
    select(getProfileOwner),
  ]);

  const queryParams: PapiInitAdoptabobblesRequestParams = {
    username: profileOwner.user && profileOwner.user.username,
    page: 1 + offset / ADOPTABOBBLES_PER_FETCH,
    limit,
  };

  const response = yield call(fetchFromApi, queryParams);

  if (response && !isApiError(response)) {
    const data = (response.gruser?.page?.modules || []).filter(
      mdl => mdl.hasOwnProperty('name') && mdl.name === 'adoptabobbles_bought'
    );
    if (data.length === 1) {
      const {
        moduleData: {
          adoptabobblesBought: { hasMore, nextOffset, deviations: results },
        },
      } = data[0];

      const { result, entities } = normalize(results, [deviationSchema]);

      yield put(
        withOffset.actions.fetchSuccess({
          streamId,
          hasMore,
          nextOffset,
          items: result,
          entities,
        })
      );
    }
  }
  // TODO error catching
}
