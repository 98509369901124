import React, { useCallback, useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import { Url, USER_PROFILE_SECTION_PATHS } from '@wix/da-url';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import ConditionalWrapper from '@wix/da-ds/pkg/ConditionalWrapper';
import {
  BiData,
  ViewAdoptableTabBiEvent,
  ViewCommissionTabBiEvent,
  ViewPremiumGalleriesTabBiEvent,
  ViewSubscriptionTabBiEvent,
  ViewShopPremiumDownloadsTabBiEvent,
} from '@wix/da-bi/pkg/events';

import s from './MonetizationPromoBanner.scss';

export interface Props {
  className?: string;
  monetizationPromo?: string;
  profileOwnerUser: PapiUser;
}

export const MonetizationPromoBanner: React.FC<Props> = ({
  monetizationPromo,
  profileOwnerUser,
  className,
}) => {
  const { t } = useTranslation();
  const { username, userId } = profileOwnerUser;
  const isMobile = useContext(MobileContext);

  const checkOut = useCallback(() => {
    let checkOutURL;
    if (monetizationPromo === 'tier') {
      checkOutURL = Url.userSubscriptionsPath({
        username,
      });
    } else {
      checkOutURL = Url.userLink(username, USER_PROFILE_SECTION_PATHS.SHOP);
    }
    window.location.href = checkOutURL;
  }, [username, monetizationPromo]);

  if (
    !monetizationPromo ||
    ![
      'tier',
      'commission',
      'premium_gallery',
      'adoptable',
      'pcp',
      'generic',
    ].includes(monetizationPromo)
  ) {
    return null;
  }

  let biData;
  const commonBiData = {
    typeid: ResourceType.USER,
    itemid: userId,
    sectionname: 'profile_hero',
    section_typeid: ResourceType.USER,
    referral_info: ReferralType.PROFILE_COVER_PROMOTION,
  };
  if (monetizationPromo === 'tier') {
    biData = BiData<ViewSubscriptionTabBiEvent>({
      evid: 547,
      ...commonBiData,
    });
  } else if (monetizationPromo === 'adoptable') {
    biData = BiData<ViewAdoptableTabBiEvent>({
      evid: 546,
      ...commonBiData,
    });
  } else if (monetizationPromo === 'commission') {
    biData = BiData<ViewCommissionTabBiEvent>({
      evid: 548,
      ...commonBiData,
    });
  } else if (monetizationPromo === 'premium_gallery') {
    biData = BiData<ViewPremiumGalleriesTabBiEvent>({
      evid: 549,
      ...commonBiData,
    });
  } else if (monetizationPromo === 'pcp') {
    biData = BiData<ViewShopPremiumDownloadsTabBiEvent>({
      evid: 557,
      ...commonBiData,
    });
  } else if (monetizationPromo === 'generic') {
    biData = BiData<ViewShopPremiumDownloadsTabBiEvent>({
      evid: 557,
      ...commonBiData,
    });
  }

  let messageKey, ctaKey;
  if (monetizationPromo === 'tier') {
    messageKey = 'common.profileHero.moneyBanner.var.tier.message';
    ctaKey = 'common.profileHero.moneyBanner.CTA.tier';
  } else {
    messageKey = 'common.profileHero.moneyBanner.var.generic.message';
    ctaKey = 'common.profileHero.moneyBanner.CTA.generic';
  }

  return (
    <div
      className={c(
        s['root'],
        s[`type-${monetizationPromo}`],
        s['ms-ads-mobile'],
        s['genericized'],
        className
      )}
    >
      <div className={s['container']}>
        <ConditionalWrapper
          condition={isMobile}
          wrap={child => (
            <BiLink onClick={checkOut} biData={biData}>
              {child}
            </BiLink>
          )}
        >
          <div className={s['body']}>
            {t(`${messageKey}${isMobile ? '.mobile' : ''}`)}
          </div>
          {!isMobile && (
            <BiLink className={s['link']} onClick={checkOut} biData={biData}>
              {t(ctaKey)}
            </BiLink>
          )}
        </ConditionalWrapper>
      </div>
    </div>
  );
};
MonetizationPromoBanner.displayName = 'MonetizationPromoBanner';

export default MonetizationPromoBanner;
