import { select, call } from 'redux-saga/effects';
import {
  selectors as streamSelectors,
  withOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { GallectionType } from '@wix/da-shared-react/pkg/types/gallection';
import {
  FOLDER_DEVIATIONS_STREAM_GROUP_ID,
  DEVIATION_SEARCH_RESULTS_STREAM_ID,
  FAVOURITES_SEARCH_RESULTS_STREAM_ID,
  PRIVATE_COLLECTION_SEARCH_RESULTS_STREAM_ID,
} from '../../streams/folderDeviations';
import { POSTS_DEVIATIONS_STREAM_GROUP_ID } from '../../streams/posts';
import { SHOP_ITEMS_STREAM_GROUP_ID } from '../../streams/shop';
import { TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID } from '../../streams/tierContent';
import { ADOPTABOBBLES_DEVIATIONS_STREAM_GROUP_ID } from '../../streams/adoptabobbles';
import {
  MY_COMMENTS_STREAM,
  BADGE_ACTIVITY_STREAM,
  WATCHER_IDS_STREAM,
  WATCHING_IDS_STREAM,
  GROUP_MEMBERS_IDS_STREAM,
  GROUP_ACTIVE_MEMBERS_IDS_STREAM,
  GROUPS_MEMBER_IDS_STREAM,
  GROUP_MESSAGELOG_IDS_STREAM,
  GROUP_ACTIONLOG_IDS_STREAM,
  COLLECTION_FOLDER_IDS_STREAM,
  GALLERY_FOLDER_IDS_STREAM,
  PRIVATE_COLLECTION_FOLDER_IDS_STREAM,
} from '../../streams';
import fetchMoreFolderDeviations from './fetchMoreFolderDeviations';
import fetchMoreGallectionSearchResults from './fetchMoreGallectionSearchResults';
import fetchMoreBadgeActivity from './fetchMoreBadgeActivity';
import fetchMoreMyComments from './fetchMoreMyComments';
import fetchMoreWatchers from './fetchMoreWatchers';
import fetchMoreWatching from './fetchMoreWatching';
import fetchMoreGroupMembers from './fetchMoreGroupMembers';
import fetchMoreActiveMembers from './fetchMoreActiveMembers';
import fetchMoreGroupsMember from './fetchMoreGroupsMember';
import fetchMorePosts from './fetchMorePosts';
import fetchMoreTierContent from './fetchMoreTierContent';
import fetchMoreShopItems from './fetchMoreShopItems';
import fetchMoreBoughtAdoptabobbles from './fetchMoreBoughtAdoptabobbles';
import fetchGroupActionLogStream from './fetchGroupActionLogStream';
import { fetchMoreFolders, fetchPreviousFolders } from './fetchMoreFolders';
import { getConfigKey } from '@wix/da-redux-config/pkg/selectors';
import fetchGroupMessageLogStream from './fetchGroupMessageLogStream';

export function* callStreamSpecificSaga(
  action: ReturnType<typeof withOffset.actions.fetch>
) {
  const { streamId } = action;

  const groupId = yield select(state =>
    streamSelectors.getGroupId(state, streamId)
  );

  if (groupId === FOLDER_DEVIATIONS_STREAM_GROUP_ID) {
    return yield call(fetchMoreFolderDeviations, action);
  }

  if (groupId === POSTS_DEVIATIONS_STREAM_GROUP_ID) {
    return yield call(fetchMorePosts, action);
  }

  if (groupId === SHOP_ITEMS_STREAM_GROUP_ID) {
    return yield call(fetchMoreShopItems, action);
  }

  if (groupId === TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID) {
    return yield call(fetchMoreTierContent, action);
  }

  if (groupId === ADOPTABOBBLES_DEVIATIONS_STREAM_GROUP_ID) {
    return yield call(fetchMoreBoughtAdoptabobbles, action);
  }

  const searchTypeByStreamId = {
    [DEVIATION_SEARCH_RESULTS_STREAM_ID]: GallectionType.GALLERY,
    [FAVOURITES_SEARCH_RESULTS_STREAM_ID]: GallectionType.COLLECTION,
    [PRIVATE_COLLECTION_SEARCH_RESULTS_STREAM_ID]:
      GallectionType.PRIVATE_COLLECTION,
  };

  switch (streamId) {
    case BADGE_ACTIVITY_STREAM:
      return yield call(fetchMoreBadgeActivity, action);
    case WATCHER_IDS_STREAM:
      return yield call(fetchMoreWatchers, action);
    case WATCHING_IDS_STREAM:
      return yield call(fetchMoreWatching, action);
    case GROUP_MEMBERS_IDS_STREAM:
      return yield call(fetchMoreGroupMembers, action);
    case GROUP_ACTIVE_MEMBERS_IDS_STREAM:
      return yield call(fetchMoreActiveMembers, action);
    case GROUP_ACTIONLOG_IDS_STREAM:
      return yield call(fetchGroupActionLogStream, action);
    case GROUP_MESSAGELOG_IDS_STREAM:
      return yield call(fetchGroupMessageLogStream, action);
    case GROUPS_MEMBER_IDS_STREAM:
      return yield call(fetchMoreGroupsMember, action);
    case MY_COMMENTS_STREAM:
      return yield call(fetchMoreMyComments, action);
    case DEVIATION_SEARCH_RESULTS_STREAM_ID:
    case FAVOURITES_SEARCH_RESULTS_STREAM_ID:
    case PRIVATE_COLLECTION_SEARCH_RESULTS_STREAM_ID:
      return yield call(
        fetchMoreGallectionSearchResults,
        action,
        searchTypeByStreamId[streamId]
      );
    case COLLECTION_FOLDER_IDS_STREAM:
    case PRIVATE_COLLECTION_FOLDER_IDS_STREAM:
    case GALLERY_FOLDER_IDS_STREAM:
      return yield call(fetchMoreFolders, action);
    default:
    // noop
  }
}

export function* callStreamSpecificSagaForPrevious(
  action: ReturnType<typeof withOffset.actions.fetchPrevious>
) {
  const {
    payload: { streamId },
  } = action;

  switch (streamId) {
    case COLLECTION_FOLDER_IDS_STREAM:
    case PRIVATE_COLLECTION_FOLDER_IDS_STREAM:
    case GALLERY_FOLDER_IDS_STREAM:
      return yield call(fetchPreviousFolders, action);
    default:
    // noop
  }
}

export function* setCsrfOnParams(params) {
  const csrf = yield select(getConfigKey, 'csrfToken');
  params.csrf_token = csrf;
  return params;
}
