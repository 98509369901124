import React from 'react';
import classnames from 'classnames';
import { PapiUser } from '@wix/da-papi-types';

import SocialLink from '../SocialLink';
import s from './SocialMediaList.scss';

interface Props {
  socialLinks: {
    id: number;
    value?: string;
  }[];
  user: PapiUser;
}

const SocialMediaList: React.FC<Props> = ({
  socialLinks,
  user: { username },
}) => (
  <div
    className={classnames(
      s['root'],
      socialLinks.length >= 6 && s['a-lot-of-social-media']
    )}
  >
    {socialLinks.map(
      (socialLink, index) =>
        socialLink.value &&
        // We removed google plus when it died, but some entries are still hanging around. Instead of doing a DB migration,
        // FED was instructed to just filter these out. https://phrac.deviantart.net/T58927
        !socialLink.value.startsWith('https://plus.google.com') && (
          <SocialLink
            key={index}
            username={username}
            networkId={socialLink.id}
            url={socialLink.value}
            className={s['social-media-link']}
          />
        )
    )}
  </div>
);
SocialMediaList.displayName = 'SocialMediaList';

export default SocialMediaList;
