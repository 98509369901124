import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../types/store';
import { createGroup } from '../../../actions/groupCreation';

import { Props } from './GroupCreation';

type StateProps = Pick<Props, 'isLoading' | 'errors'>;
export type DispatchProps = Pick<Props, 'createGroup'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => state.groupCreation;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  createGroup,
};

export default connect(mapStateToProps, mapDispatchToProps);
