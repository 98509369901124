import { Middleware } from 'redux';
import rootReducer from '../reducers/index';
import { AppState } from '../../types/store';
import rootSaga from '../sagas';
import logger from '../utils/logger';
import { rootModuleStoreFactory } from '@wix/da-shared-react/pkg/redux/rootModuleStore/factory';
import { IModuleStore } from 'redux-dynamic-modules-core';

export function configureStore(
  initialState: Partial<AppState>
): IModuleStore<AppState> {
  const middleware: Middleware[] = [];

  return rootModuleStoreFactory<AppState>(
    middleware,
    logger,
    initialState as any,
    rootReducer as any,
    rootSaga,
    false
  ) as IModuleStore<AppState>;
}
