import React from 'react';
import classnames from 'classnames';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';

import s from './WidgetHeader.scss';

export interface Props {
  header?: string | React.ReactNode;
  count?: number | string;
  className?: string;
  extras?: React.ReactNode | React.ReactNode[];
}

export const WidgetHeader: React.FC<Props> = ({
  header,
  count,
  className,
  extras,
}) => (
  <div className={classnames(s['root'], className)}>
    {header && (
      <Heading className={s['header']}>
        {header}
        {!!count && ' '}
        {!!count && <span className={s['count']}>{shortenNumber(count)}</span>}
      </Heading>
    )}
    {extras}
  </div>
);
WidgetHeader.displayName = 'WidgetHeader';

export default WidgetHeader;
