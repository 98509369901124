import React from 'react';
import ProfileStatItem from './ProfileStatItem';
import { getIsGroupProfile } from '../../../selectors/users';
import { useSelector } from 'react-redux';
import s from './ProfileStats.scss';

interface Props {
  [stat: string]: number;
}

const ProfileStats: React.FC<Props> = ({ ...stats }) => {
  const isGroupProfile = useSelector(getIsGroupProfile);

  const showStats = isGroupProfile
    ? ['founded', 'members', 'watchers']
    : ['watchers', 'deviations', 'pageviews'];

  return (
    <div className={s['root']}>
      {showStats.map(stat => (
        <ProfileStatItem
          key={stat}
          stat={stat}
          value={stats[stat]}
          className={s['stats-item']}
        />
      ))}
    </div>
  );
};

export default ProfileStats;
