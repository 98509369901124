import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import HiddenIcon from '@wix/da-ds/pkg/Icons/32x32/Hidden';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import { setSectionPrivate } from '../../../../../actions/sections';
import { isSectionPrivate } from '../../../../../selectors/sections';

import s from './PrivateStatus.scss';

export interface Props {}

const PrivateStatus: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPrivate = useSelector(isSectionPrivate);

  const togglePrivate = useCallback(() => {
    dispatch(setSectionPrivate(!isPrivate));
  }, [isPrivate, dispatch]);

  return (
    <div className={s['root']}>
      <span className={s['text']}>
        {isPrivate && (
          <HiddenIcon
            disableDefaultColors
            className={s['eye-icon']}
            size={IconSize.MEDIUM}
          />
        )}
        {t(
          isPrivate
            ? 'widgets.adoptabobbles_bought.header.hidden'
            : 'widgets.adoptabobbles_bought.header.visible'
        )}
      </span>
      <TextButton
        size="small"
        variant="main"
        className={s['cta']}
        onClick={togglePrivate}
        biData={BiData<DefaultBiEvent>({
          evid: 2,
          sectionname: BiContextProvidedValue,
          widgetname: BiContextProvidedValue,
          click_info: isPrivate ? 'show_this_tab' : 'hide_this_tab',
        })}
      >
        {t(
          isPrivate
            ? 'widgets.adoptabobbles_bought.header.cta.show'
            : 'widgets.adoptabobbles_bought.header.cta.hide'
        )}
      </TextButton>
    </div>
  );
};

PrivateStatus.displayName = 'PrivateStatus';
export default PrivateStatus;
