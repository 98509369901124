import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import DeviousnessBox from './DeviousnessBox';

export const getDeviousnessConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: DeviousnessBox,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-0-deviousness.svg',
    title: t('widgets.deviousness.addButtonHeader'),
    description: t('widgets.deviousness.description'),
  },
});
