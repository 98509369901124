import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { createOrOpenChatWithUser } from '@wix/da-shared-react/pkg/Chat/redux/actions';
import { AppState } from '../../../../../../types/store';

import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../../../selectors/users';

import { Props } from './EmptyState';
import { PapiUser } from '@wix/da-papi-types';

type StateProps = Pick<Props, 'isOwner' | 'user'>;
type DispatchProps = Pick<Props, 'onOpenChat'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  user: getProfileOwnerUser(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  onOpenChat: (user: PapiUser) => {
    dispatch(
      createOrOpenChatWithUser(`shop-empty-state-${user.useridUuid}`, user)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
