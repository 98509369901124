import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import SupportersBox from './SupportersBox';

export const getSupportersWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: SupportersBox,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/il8.26_top_exclusive_supporters.svg',
    title: t('widgets.supporters.addButtonHeader'),
    description: t('widgets.supporters.description'),
  },
});
