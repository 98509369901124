import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import initActionsCreator from './redux/subscriptionCenterInit';
import SubscriptionCenterBox from './SubscriptionCenterBox';

export const getSubscriptionCenterWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent: SubscriptionCenterBox,
  initActionsCreator,
});
