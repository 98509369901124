export const TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID = 'tierContent';

export const TIER_CONTENT_DEVIATIONS_PER_FETCH = 12;
export const TIER_CONTENT_POSTS_PER_FETCH = 5;

export const getTierContentDeviationsStreamId = (
  tierDeviationId,
  section: 'deviations' | 'posts'
): string =>
  `${TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID}_${tierDeviationId}_${section}_ids_stream`.toUpperCase();
