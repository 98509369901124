// DS: TA1.9
import React from 'react';
import cn from 'classnames';
import { PapiUser } from '@wix/da-papi-types';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar/UserAvatar';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';

import s from './TopSupporterCard.scss';

interface Props {
  user: PapiUser;
  type: 'supporter' | 'owner';
  typeName: string;
  width?: string | number;
}

const TopSupporterCard: React.FC<Props> = ({
  user,
  type,
  typeName,
  width,
}) => {
  return (
    <UserLink
      user={user}
      className={cn(s['root'], 'ds-card')}
      style={{ width }}
    >
      <div className={cn(s['type'], s[type])}>{typeName}</div>
      <UserAvatar size={40} user={user} />
      <div className={s['username']}>{user.username}</div>
    </UserLink>
  );
};
TopSupporterCard.displayName = 'TopSupporterCard';

export default TopSupporterCard;
