import { Action } from 'redux-actions';
import { PapiModuleAdoptabobblesBought } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';

import {
  ADOPTABOBBLES_DEVIATIONS_STREAM_ID,
  ADOPTABOBBLES_DEVIATIONS_STREAM_GROUP_ID,
  ADOPTABOBBLES_PER_FETCH,
} from '../../../../streams/adoptabobbles';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleAdoptabobblesBought
): Action<any>[] => {
  const { result, entities } = normalize(moduleData.deviations || [], [
    deviationSchema,
  ]);
  return [
    {
      ...withOffset.actions.initialize({
        groupId: ADOPTABOBBLES_DEVIATIONS_STREAM_GROUP_ID,
        streamId: ADOPTABOBBLES_DEVIATIONS_STREAM_ID,
        streamParams: {
          moduleId,
        },
        itemsPerFetch: ADOPTABOBBLES_PER_FETCH,
        items: result,
        entities,
        hasMore: moduleData.hasMore,
      }),
      payload:
        null /* stream intialize actions are not compatible with Action<any> */,
    },
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ];
};
