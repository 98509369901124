import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BiContextProvidedValue,
  BiData,
  ClickSocialLinkBiEvent,
} from '@wix/da-bi/pkg/events';
import FacebookIcon from '@wix/da-ds/pkg/Icons/Facebook';
import InstagramIcon from '@wix/da-ds/pkg/Icons/Instagram';
import TwitterIcon from '@wix/da-ds/pkg/Icons/Twitter';
import TumblrIcon from '@wix/da-ds/pkg/Icons/Tumblr';
import PatreonIcon from '@wix/da-ds/pkg/Icons/Patreon';
import EtsyIcon from '@wix/da-ds/pkg/Icons/Etsy';
import BehanceIcon from '@wix/da-ds/pkg/Icons/Behance';
import DribbbleIcon from '@wix/da-ds/pkg/Icons/Dribbble';
import YouTubeIcon from '@wix/da-ds/pkg/Icons/YouTube';
import VimeoIcon from '@wix/da-ds/pkg/Icons/Vimeo';
import TwitchIcon from '@wix/da-ds/pkg/Icons/Twitch';
import LinkedInIcon from '@wix/da-ds/pkg/Icons/LinkedIn';
import PinterestIcon from '@wix/da-ds/pkg/Icons/Pinterest';
import FlickrIcon from '@wix/da-ds/pkg/Icons/Flickr';
import LinkIcon from '@wix/da-ds/pkg/Icons/24x24/Link';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { SocialLinks } from './utils';
import { formatExternalUrl } from '../utils';
import { ModuleType } from '../../../../../types/modules';

interface Props {
  networkId?: number;
  username?: string;
  url?: string;
  className: string;
}

const socialNetworks = {
  [SocialLinks.BEHANCE]: {
    title: 'widgets.aboutMe.social.behance',
    icon: BehanceIcon,
    name: 'behance',
  },
  [SocialLinks.DRIBBLE]: {
    title: 'widgets.aboutMe.social.dribbble',
    icon: DribbbleIcon,
    name: 'dribbble',
  },
  [SocialLinks.ETSY]: {
    title: 'widgets.aboutMe.social.etsy',
    icon: EtsyIcon,
    name: 'etsy',
  },
  [SocialLinks.FACEBOOK]: {
    title: 'widgets.aboutMe.social.facebook',
    icon: FacebookIcon,
    name: 'facebook',
  },
  [SocialLinks.FLICKR]: {
    title: 'widgets.aboutMe.social.flickr',
    icon: FlickrIcon,
    name: 'flickr',
  },
  [SocialLinks.INSTAGRAM]: {
    title: 'widgets.aboutMe.social.instagram',
    icon: InstagramIcon,
    name: 'instagram',
  },
  [SocialLinks.LINKEDIN]: {
    title: 'widgets.aboutMe.social.linkedin',
    icon: LinkedInIcon,
    name: 'linkedin',
  },
  [SocialLinks.PATREON]: {
    title: 'widgets.aboutMe.social.patreon',
    icon: PatreonIcon,
    name: 'patreon',
  },
  [SocialLinks.PINTEREST]: {
    title: 'widgets.aboutMe.social.pinterest',
    icon: PinterestIcon,
    name: 'pinterest',
  },
  [SocialLinks.TWITCH]: {
    title: 'widgets.aboutMe.social.twitch',
    icon: TwitchIcon,
    name: 'twitch',
  },
  [SocialLinks.TWITTER]: {
    title: 'widgets.aboutMe.social.twitter',
    icon: TwitterIcon,
    name: 'twitter',
  },
  [SocialLinks.TUMBLR]: {
    title: 'widgets.aboutMe.social.tumblr',
    icon: TumblrIcon,
    name: 'tumblr',
  },
  [SocialLinks.YOUTUBE]: {
    title: 'widgets.aboutMe.social.youtube',
    icon: YouTubeIcon,
    name: 'youtube',
  },
  [SocialLinks.VIMEO]: {
    title: 'widgets.aboutMe.social.vimeo',
    icon: VimeoIcon,
    name: 'vimeo',
  },
};

export const SocialLink: React.FC<Props> = ({
  networkId = '',
  username,
  url,
  className,
}) => {
  const { t } = useTranslation();

  const {
    title,
    icon: Icon,
    name,
  } = socialNetworks[networkId] || {
    title: url,
    icon: LinkIcon,
    name: 'website',
  };

  return (
    <BiLink
      href={formatExternalUrl(url)}
      title={t(title, { username })}
      aria-label={t(title, { username })}
      className={className}
      rel="noopener noreferrer nofollow ugc"
      target="_blank"
      biData={BiData<ClickSocialLinkBiEvent>({
        evid: 297,
        itemid: BiContextProvidedValue,
        typeid: ResourceType.USER,
        widgetname: ModuleType.ABOUT,
        social_share: name,
      })}
    >
      <Icon />
    </BiLink>
  );
};

export default SocialLink;
