import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from '@wix/da-shared-react/pkg/FolderCard';
import { gallectionTypeToResourceType } from '@wix/da-shared-react/pkg/types/gallection';
import { AppState } from '../../../../../types/store';
import { setSelectedFolderId } from '../../../../actions/gallectionSection';
import { getSelectedFolderId } from '../../../../selectors/gallectionSection';

type StateProps = Pick<Props, 'isSelected' | 'contentClickBiData'>;
type DispatchProps = Pick<Props, 'onClick'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  { folder: { folderId, type }, folder, indexInsideParent }
) => {
  return {
    isSelected: folderId === getSelectedFolderId(state),
    contentClickBiData: {
      evid: 100,
      widgetname: 'folders',
      rank: indexInsideParent,
      typeid: gallectionTypeToResourceType(type),
      itemid: folderId,
    },
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  onClick: () => {
    dispatch(setSelectedFolderId(ownProps.folder.folderId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
