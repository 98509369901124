import { AppState } from '../../types/store';
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import {
  Layout,
  SectionLayout,
  SectionLayoutZones,
  SectionLayoutZone,
  InstallableModuleType,
} from '../../types/layout';
import { ModuleType } from '../../types/modules';
import { getAllModuleTypeByModuleId, getModules } from './modules';
import { isCurrentUserProfileOwner } from '../selectors/users';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';

const reduxKey = 'layout';
function getSlice(state: any): Layout {
  if (!state[reduxKey]) {
    return {} as Layout;
  }
  return state[reduxKey];
}

export const getParameterAtIndex =
  (index: number) =>
  (...params) =>
    params[index];

export const BlankSectionLayoutZone = {
  installableModuleTypes: [],
  installedModulesIds: [],
} as SectionLayoutZone;

export const BlankSectionLayout: SectionLayout = {
  version: 0,
  zones: {},
};

export const getActiveSection = (state: AppState): string =>
  state?.sections?.active;

export const getSectionLayout = (
  state: AppState,
  section: string
): SectionLayout => getSlice(state)[section] || BlankSectionLayout;

export const getSectionLayoutVersion = (
  state: AppState,
  section: string
): number => getSectionLayout(state, section).version;

export const getSectionLayoutZones = (
  state: AppState,
  section: string
): SectionLayoutZones => getSectionLayout(state, section).zones;

export const getSectionLayoutZone = (
  state: AppState,
  section: string,
  zone: string
): SectionLayoutZone =>
  getSectionLayoutZones(state, section)[zone] || BlankSectionLayoutZone;

export const getInstalledModuleIds: (
  state: AppState,
  section: string,
  zone: string
) => number[] = createCachedSelector(
  getSectionLayoutZone,
  zone => zone.installedModulesIds
)(getParameterAtIndex(2)); // zone

export const getAllInstalledModuleIds: (
  state: AppState,
  section: string
) => number[] = createCachedSelector(getSectionLayoutZones, zones =>
  Object.keys(zones).reduce(
    (acc, zone) => acc.concat(zones[zone].installedModulesIds),
    [] as number[]
  )
)(getParameterAtIndex(1)); // section

export const getInstalledModuleTypes: (
  state: AppState,
  section: string
) => ModuleType[] = createSelector(
  getAllInstalledModuleIds,
  getAllModuleTypeByModuleId,
  (moduleIds, moduleTypeByModuleId) =>
    moduleIds.map(id => moduleTypeByModuleId[id])
);

export const getInstalledModuleTypesForZone: (
  state: AppState,
  section: string,
  zone: string
) => ModuleType[] = createCachedSelector(
  getInstalledModuleIds,
  getAllModuleTypeByModuleId,
  (moduleIds, moduleTypeByModuleId) =>
    moduleIds.map(id => moduleTypeByModuleId[id])
)(getParameterAtIndex(2)); // zone

export const getInstalledModuleCountsForZone: (
  state: AppState,
  section: string,
  zone: string
) => Record<string, number> = createCachedSelector(
  [getSectionLayoutZone, getInstalledModuleTypesForZone],
  (zoneLayout, installedModuleTypes) => {
    const installedModuleCounts: Record<string, number> = {};
    if (zoneLayout) {
      installedModuleTypes.forEach(moduleType => {
        installedModuleCounts[moduleType] =
          installedModuleCounts[moduleType] || 0;
        installedModuleCounts[moduleType]++;
      });
    }
    return installedModuleCounts;
  }
)(getParameterAtIndex(2)); // zone

export const getInstallableModulesForZone: (
  state: AppState,
  section: string,
  zone: string
) => InstallableModuleType[] = createCachedSelector(
  [getSectionLayoutZone, getInstalledModuleCountsForZone],
  (zoneLayout, installedModuleCounts) => {
    if (!zoneLayout) {
      return [];
    }

    return (zoneLayout.installableModuleTypes || []).map(moduleInfo => ({
      ...moduleInfo,
      installedCount: installedModuleCounts[moduleInfo.moduleName] ?? 0,
    }));
  }
)(getParameterAtIndex(2)); // zone

export const getInstallableModulesInfoForZone: (
  state: AppState,
  section: string,
  zone: string
) => InstallableModuleType[] = createCachedSelector(
  [getInstallableModulesForZone, getCurrentUserHasCore],
  (installableModules, hasCore) =>
    installableModules.filter(({ installedCount = 0, limit, restrict }) => {
      const coreLimit =
        restrict && restrict.priv === 'core' ? restrict.limit : 0;
      return installedCount < (hasCore ? Math.max(limit, coreLimit) : limit);
    })
)(getParameterAtIndex(2)); // zone

export const getInstallableModuleTypes: (
  state: AppState,
  section: string,
  zone: string
) => ModuleType[] = createCachedSelector(
  [getInstallableModulesInfoForZone],
  installableModulesInfo =>
    installableModulesInfo.map(({ moduleName }) => moduleName)
)(getParameterAtIndex(2)); // zone

export const getCanCurrentUserEditModule: (
  state: AppState,
  section: string,
  zone: string,
  moduleId: number
) => boolean = createCachedSelector(
  [
    getModules,
    getInstallableModulesForZone,
    isCurrentUserProfileOwner,
    getCurrentUserHasCore,
    getParameterAtIndex(3), // moduleId
  ],
  (modules, installableModules, isOwner, hasCore, moduleId) => {
    if (!isOwner || !modules || !modules[moduleId]) {
      return false;
    }
    const { type: moduleName } = modules[moduleId];
    const installableModule = installableModules.find(
      module => moduleName === module.moduleName
    );
    if (!installableModule || !installableModule.restrict) {
      return true;
    }
    // trying to find the position of the given moduleId in an array of
    // modulesIds of the same type, sorted by installation order
    const installedModules = Object.keys(modules)
      .filter(id => modules[id].type === moduleName)
      .map(id => parseInt(id))
      .sort();
    const installedIndex = installedModules.findIndex(id => id === moduleId);
    return (
      installedIndex <
      Math.max(
        installableModule.limit,
        hasCore ? installableModule.restrict.limit : 0
      )
    );
  }
)(getParameterAtIndex(3)); // moduleId

export const getIsInstallingModule = createSelector(
  getSectionLayoutZone,
  zone => zone.isInstallingModule || false
);

export const getCurrentlyInstalledModulePostion = (
  state: AppState,
  section: string,
  zone: string
) => {
  const zoneLayout: SectionLayoutZone = getSectionLayoutZone(
    state,
    section,
    zone
  );
  return zoneLayout.currentlyInstalledModulePostion;
};

export const getSectionLayoutZonesInfo: (
  state: AppState,
  section: string
) => SectionLayoutZones = createSelector(
  getParameterAtIndex(0),
  getParameterAtIndex(1),
  getSectionLayoutZones,
  (state, section, zones) =>
    Object.keys(zones).reduce(
      (result, zoneType) => ({
        ...result,
        [zoneType]: {
          ...(zones[zoneType] || {}),
          installableModuleTypes: getInstallableModuleTypes(
            state,
            section,
            zoneType
          ),
        },
      }),
      {}
    )
);
