import { getPostsWidgetConfigs } from '../../components/Sections/PostsSection/postsWidgetConfigs';
import {
  getCustomInitActionsForWidgets,
  getPageInfoInitActions,
} from './common';

export const getCustomInitActionsForPostWidgets = (
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getPostsWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  ...getPageInfoInitActions(responseData, requestParams),
];
