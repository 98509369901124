import { createAction } from 'redux-actions';
import {
  SubscriptionsTierContentType,
  SubscriptionsStatType,
  SubscriptionsChartPointType,
} from '../../types/subscriptionsSection';

export const setSelectedTierEntityId = createAction(
  'SUBSCRIPTIONS_SECTION/SET_SELECTED_TIER_ENTITYID',
  (payload: string | number) => payload
);

export const setSelectedTierContentType = createAction(
  'SUBSCRIPTIONS_SECTION/SET_SUBSCRIPTIONS_SECTION_TYPE',
  (payload: SubscriptionsTierContentType) => payload
);

export const setSubscriptionsStats = createAction(
  'SUBSCRIPTIONS_SECTION/SET_SUBSCRIPTION_STATS',
  (payload: SubscriptionsStatType[]) => payload
);

export const setSubscriptionsChartData = createAction(
  'SUBSCRIPTIONS_SECTION/SET_SUBSCRIPTION_CHART_DATA',
  (payload: SubscriptionsChartPointType[]) => payload
);
