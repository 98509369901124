import React, { useContext } from 'react';
import classnames from 'classnames';
import CommentBody from '@wix/da-shared-react/pkg/CommentBodyOld';
import DeviationDumbView from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import { PapiComment, PapiDeviation } from '@wix/da-papi-types';
import { isImageDeviation } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import CommentLink from '@wix/da-shared-react/pkg/CommentLink';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';

import s from './MyComment.scss';

export interface Props {
  title: React.ReactNode;
  comment: PapiComment;
  thumb?: PapiDeviation;
  className?: string;
}
export const MyComment: React.FC<Props> = ({
  title,
  comment,
  thumb,
  className,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <div
      className={classnames(s['root'], className)}
      data-commentid={comment.commentId}
      data-typeid={comment.typeId}
      data-itemid={comment.itemId}
    >
      <div className={s['comment']}>
        {renderCommentHeader(title)}
        {renderCommentBody(comment)}
        {renderTimestamp(comment)}
      </div>
      {renderThumb(thumb, isMobile)}
    </div>
  );
};

const renderCommentHeader = (title: React.ReactNode) => {
  return <div className={s['header']}>{title}</div>;
};

const renderCommentBody = (comment: PapiComment) => {
  return (
    <div className={s['body']}>
      <CommentBody comment={comment} />
    </div>
  );
};

const renderTimestamp = (comment: PapiComment) => {
  return (
    <CommentLink comment={comment} className={s['timestamp']}>
      <Timestamp timestamp={comment.posted} />
    </CommentLink>
  );
};

const renderThumb = (thumb: PapiDeviation | undefined, isMobile: boolean) => {
  if (!thumb || !isImageDeviation(thumb)) {
    return null;
  }
  return (
    <div className={s['deviation']}>
      <DeviationDumbView
        deviation={thumb}
        width={isMobile ? 87 : 174}
        height={isMobile ? 80 : 128}
        className={s['thumb']}
      />
    </div>
  );
};

export default MyComment;
