import { select, call, put, takeLatest } from 'redux-saga/effects';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { getShopItemsStreamId } from '../streams/shop';
import { ShopItemType } from '../../types/shop';
import { CommissionStatusCodeMap } from '@wix/da-shared-react/pkg/types/commissions';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { getModuleIdByModuleName } from '../selectors/modules';
import { updateModule } from '../actions/modules';
import { ModuleType } from '../../types/modules';
import {
  changeCommissionsGlobalStatus as changeCommissionsGlobalStatusAction,
  changeAllCommissionsStatus as changeAllCommissionsStatusAction,
} from '../actions/commissions';
import {
  PapiRequestGruserModuleCommissionsChangeAllCommissionsStatus,
  PapiRequestGruserModuleCommissionsChangeGlobalStatus,
} from '@wix/da-papi-types';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';
import { requestPuppyGruser } from './gruserHelpers';

function* changeAllCommissionsStatus(data) {
  return yield call(
    requestPuppyGruser,
    {
      method: 'post',
      url: '/module/commissions/change_all_commissions_status',
      data,
    },
    undefined,
    'gruser'
  );
}

function* changeCommissionsGlobalStatus(data) {
  return yield call(
    requestPuppyGruser,
    {
      method: 'post',
      url: '/module/commissions/change_global_status',
      data,
    },
    undefined,
    'gruser'
  );
}

export function* changeCommissionsGlobalStatusSaga(action) {
  const { isOpen } = action.payload;
  const params: PapiRequestGruserModuleCommissionsChangeGlobalStatus = {
    is_open: isOpen,
  };
  const response = yield call(changeCommissionsGlobalStatus, params);

  if (response && response.success) {
    const moduleId = yield select(
      getModuleIdByModuleName,
      ModuleType.COMMISSIONS
    );

    yield put(
      updateModule({
        id: moduleId,
        moduleData: { isOpenForCommissions: isOpen },
      })
    );
  }
}

export function* changeAllCommissionsStatusSaga(action) {
  const { status } = action.payload;
  const params: PapiRequestGruserModuleCommissionsChangeAllCommissionsStatus = {
    status,
  };
  const response = yield call(changeAllCommissionsStatus, params);

  if (response && response.success) {
    const streamId = getShopItemsStreamId(ShopItemType.COMMISSIONS);
    const commissionsIds = yield select(streamSelectors.getItems, streamId);
    const commissions = yield select(getDeviationsByIds, commissionsIds);
    const updatedCommissions = commissions.map(deviation => {
      deviation.commission.status = CommissionStatusCodeMap[response.status];
      return deviation;
    });

    const { entities } = normalize(updatedCommissions, deviationSchema);
    yield put(putEntities({ entities }));
  }
}

export default [
  takeLatest(
    changeCommissionsGlobalStatusAction,
    changeCommissionsGlobalStatusSaga
  ),
  takeLatest(changeAllCommissionsStatusAction, changeAllCommissionsStatusSaga),
];
