import { useSelector } from 'react-redux';
import {
  UseStreamReturnValue,
  useStream,
} from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { ShopItemType } from '../../../../../../types/shop';
import { GallectionType } from '../../../../../../types/gallection';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { getFoldersByIds } from '../../../../../selectors/folders';
import { AppState } from '../../../../../../types/store';

export interface UseShopGridStreamReturn<T>
  extends Partial<UseStreamReturnValue<T>> {
  streamId: string;
  totalPages: number;
}

export default function useShopGridStream<T>(
  itemType: ShopItemType
): UseShopGridStreamReturn<T> {
  const streamId: string = getShopItemsStreamId(itemType);
  const {
    total,
    itemsPerFetch,
    isFetching,
    hasMore,
    items: itemIds,
  } = useStream(streamId);

  const items: T[] = useSelector((state: AppState) => {
    return itemType === ShopItemType.PREMIUM_FOLDERS
      ? getFoldersByIds(state, GallectionType.GALLERY, itemIds)
      : getDeviationsByIds(state, itemIds);
  });

  // remove with T63052
  const totalPages =
    total && itemsPerFetch ? Math.ceil(total / itemsPerFetch) : 0;

  return {
    streamId,
    isFetching,
    items,
    itemsPerFetch,
    total,
    hasMore,
    totalPages,
  };
}
