import { PapiBadge } from '@wix/da-papi-types';
import { Url } from '@wix/da-url';

export interface GivableBadgeItemsHashInterface {
  [propName: string]: PapiBadge;
}

export const givableBadgeItemsHash: GivableBadgeItemsHashInterface = {
  llama: {
    typeId: 1,
    name: 'llama',
    title: 'Llama',
    baseTitle: 'Llama',
    description: 'Llamas are awesome!',
    stackCount: 1,
    images: [
      {
        imageUrl: Url.staticCDNLink('badges/llama.gif'),
        className: '',
      },
    ],
  },
  cake: {
    typeId: 17,
    name: 'cake',
    title: 'Cake',
    baseTitle: 'Cake',
    description: "My, that's a delicious cake",
    stackCount: 1,
    images: [
      {
        imageUrl: Url.staticCDNLink('badges/cake/base06.gif'),
        className: 'cake-base',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/wax-blue01.gif'),
        className: 'cake-candle cake-candle-1',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/wax-blue01.gif'),
        className: 'cake-candle cake-candle-2',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/wax-blue01.gif'),
        className: 'cake-candle cake-candle-3',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/wax-blue01.gif'),
        className: 'cake-candle cake-candle-4',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/wax-blue01.gif'),
        className: 'cake-candle cake-candle-5',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/wax-blue01.gif'),
        className: 'cake-candle cake-candle-6',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/flame01.gif'),
        className: 'cake-flame cake-flame-1',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/flame02.gif'),
        className: 'cake-flame cake-flame-2',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/flame04.gif'),
        className: 'cake-flame cake-flame-3',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/flame03.gif'),
        className: 'cake-flame cake-flame-4',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/flame01.gif'),
        className: 'cake-flame cake-flame-5',
      },
      {
        imageUrl: Url.staticCDNLink('badges/cake/flame02.gif'),
        className: 'cake-flame cake-flame-6',
      },
    ],
  },
};

const givableBadgeItems: PapiBadge[] = Object.keys(givableBadgeItemsHash).map(
  badgeKey => {
    return givableBadgeItemsHash[badgeKey];
  }
);

export default givableBadgeItems;
