import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './MobileSearchField';
import { AppState } from '../../../../../types/store';
import { getSearchInputValue } from '../../../../selectors/gallectionSection';
import {
  setSearchInputValue,
  setSearchQuery,
  clearSearch,
} from '../../../../actions/gallectionSection';

export type StateProps = Pick<Props, 'searchInputValue'>;
export type DispatchProps = Pick<
  Props,
  'setSearchInputValue' | 'setSearchQuery' | 'clearSearch'
>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  searchInputValue: getSearchInputValue(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  setSearchInputValue,
  setSearchQuery,
  clearSearch,
};

export default connect(mapStateToProps, mapDispatchToProps);
