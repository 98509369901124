import React from 'react';
import c from 'classnames';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './StatisticHeading.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

/**
 * Our statistic heading. We wrap this in a `<Heading />` from the a11y package.
 *
 * e.g.
 * ```jsx
 *  <StatisticHeading>Total Income</StatisticHeading>
 * ```
 */
const StatisticHeading: React.FC<Props> = ({ className, children }) => {
  return <Heading className={c(s['root'], className)}>{children}</Heading>;
};
StatisticHeading.displayName = 'StatisticHeading';

export default StatisticHeading;
