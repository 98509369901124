export enum PostsSortOrder {
  NEWEST = '',
  OLDEST = 'oldest',
}

export enum PostsType {
  ALL = 'all',
  JOURNALS = 'journals',
  STATUSES = 'statuses',
  POLLS = 'polls',
  DRAFTS = 'drafts',
}

export enum JournalFilterType {
  FEATURED = 'featured',
  ALL = '',
}
