import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  BiContextProvidedValue,
  BiData,
  AddWidgetBiEvent,
  MoveWidgetClickBiEvent,
  RemoveWidgetClickBiEvent,
} from '@wix/da-bi/pkg/events';
import ArrowUpIcon from '@wix/da-ds/pkg/Icons/ArrowUp';
import ArrowDownIcon from '@wix/da-ds/pkg/Icons/ArrowDown';
import ArrowLeftIcon from '@wix/da-ds/pkg/Icons/ArrowLeft';
import ArrowRightIcon from '@wix/da-ds/pkg/Icons/ArrowRight';
import TrashcanIcon from '@wix/da-ds/pkg/Icons/24x24/Trashcan';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import SophisticatedTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SophisticatedTooltip';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import EditConfigButton from './EditConfigButton';
import AddColumnButton from './AddColumnButton';

import s from './WidgetEditControls.scss';

export interface Props {
  className?: string;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
  onAddColumn?: () => void;
  tooltipTitle?: string;
  tooltipContent?: React.ReactNode;
  direction?: 'row' | 'column';
}

const WidgetEditControls: React.FC<Props> = ({
  tooltipTitle,
  tooltipContent,
  onEdit,
  onMoveUp,
  onRemove,
  className,
  onMoveDown,
  onAddColumn,
  direction = 'column',
}) => {
  const { t } = useTranslation();
  if (!onEdit && !onMoveDown && !onMoveUp && !onRemove) {
    return null;
  }

  const renderEditConfigButton = () => {
    if (tooltipTitle && tooltipContent) {
      return (
        <SophisticatedTooltip
          size="medium"
          heading={tooltipTitle}
          description={tooltipContent}
          usePortal
        >
          <EditConfigButton className={s['edit-button']} onClick={onEdit} />
        </SophisticatedTooltip>
      );
    }

    return <EditConfigButton className={s['edit-button']} onClick={onEdit} />;
  };

  return (
    <div className={classnames(s['root'], className)}>
      {direction === 'column' && onMoveUp && (
        <SimpleTooltip usePortal text={t!('widgets.common.moveUp')}>
          <IconButton
            icon={ArrowUpIcon}
            variant="edit-purple"
            size="medium"
            onClick={onMoveUp}
            className={s['icon-button']}
            data-role="edit-control"
            biData={BiData<MoveWidgetClickBiEvent>({
              evid: 281,
              direction: 'up',
              widgetname: BiContextProvidedValue,
              widgetzone: BiContextProvidedValue,
            })}
            aria-label={t('widgets.common.moveUp')}
          />
        </SimpleTooltip>
      )}
      {direction === 'column' && onMoveDown && (
        <SimpleTooltip usePortal text={t!('widgets.common.moveDown')}>
          <IconButton
            icon={ArrowDownIcon}
            variant="edit-purple"
            size="medium"
            onClick={onMoveDown}
            className={s['icon-button']}
            data-role="edit-control"
            biData={BiData<MoveWidgetClickBiEvent>({
              evid: 281,
              direction: 'down',
              widgetname: BiContextProvidedValue,
              widgetzone: BiContextProvidedValue,
            })}
            aria-label={t('widgets.common.moveDown')}
          />
        </SimpleTooltip>
      )}
      {direction === 'row' && onMoveUp && (
        <SimpleTooltip usePortal text={t!('widgets.common.moveLeft')}>
          <IconButton
            icon={ArrowLeftIcon}
            variant="edit-purple"
            size="medium"
            onClick={onMoveUp}
            className={s['icon-button']}
            data-role="edit-control"
            biData={BiData<MoveWidgetClickBiEvent>({
              evid: 281,
              direction: 'left',
              widgetname: BiContextProvidedValue,
              widgetzone: BiContextProvidedValue,
            })}
            aria-label={t('widgets.common.moveLeft')}
          />
        </SimpleTooltip>
      )}
      {direction === 'row' && onMoveDown && (
        <SimpleTooltip usePortal text={t!('widgets.common.moveRight')}>
          <IconButton
            icon={ArrowRightIcon}
            variant="edit-purple"
            size="medium"
            onClick={onMoveDown}
            className={s['icon-button']}
            data-role="edit-control"
            biData={BiData<MoveWidgetClickBiEvent>({
              evid: 281,
              direction: 'right',
              widgetname: BiContextProvidedValue,
              widgetzone: BiContextProvidedValue,
            })}
            aria-label={t('widgets.common.moveRight')}
          />
        </SimpleTooltip>
      )}
      {onRemove && (
        <SimpleTooltip usePortal text={t!('widgets.common.delete')}>
          <IconButton
            icon={TrashcanIcon}
            variant="edit-purple"
            size="medium"
            onClick={onRemove}
            className={s['icon-button']}
            data-role="edit-control"
            biData={BiData<RemoveWidgetClickBiEvent>({
              evid: 280,
              widgetname: BiContextProvidedValue,
              widgetzone: BiContextProvidedValue,
            })}
            aria-label={t('widgets.common.delete')}
          />
        </SimpleTooltip>
      )}
      {onEdit && renderEditConfigButton()}
      {onAddColumn && (
        <AddColumnButton
          onClick={onAddColumn}
          biData={BiData<AddWidgetBiEvent>({
            evid: 283,
            widgetname: BiContextProvidedValue,
            widgetzone: BiContextProvidedValue,
          })}
        />
      )}
    </div>
  );
};

WidgetEditControls.displayName = 'WidgetEditControls';

export default WidgetEditControls;
