import React from 'react';
import { compose } from 'redux';
import c from 'classnames';
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from 'react-i18next';
import Zone from '@wix/da-gruser-shared/pkg/components/Zone';
import withSectionFetchSpinner from '../../../../decorators/withSectionFetchSpinner';
import { useDispatch } from 'react-redux';
import { getShopWidgetConfigs } from '../shopWidgetConfigs';
import EmptyState from '../_partials/EmptyState';
import { getShopCommissionWidgetConfig } from '../ShopCommissions/widgetConfig';
import getCoverDeviationWidgetConfig from '../../../UserWidgets/CoverDeviation/widgetConfig';
import { getShopPremiumFoldersWidgetConfig } from '../ShopPremiumFolders/widgetConfig';
import { getShopAdoptablesWidgetConfig } from '../ShopAdoptables/widgetConfig';
import { getShopPremiumContentWidgetConfig } from '../ShopPremiumContent/widgetConfig';

import s from './ShopAll.scss';

export interface Props {
  className?: string;
  isEmpty?: boolean;
  isOwner?: boolean;
  hasSingularContentType?: boolean;
  emptySections?: Object;
}

const enhance = compose(withSectionFetchSpinner, withTranslation());

const ShopAll: React.FC<Props & WithTranslation> = ({
  isEmpty,
  isOwner,
  className,
  hasSingularContentType,
  emptySections,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let shopWidgetConfigs: any = getShopWidgetConfigs({
    t,
    dispatch,
  });

  if (isEmpty && !isOwner) {
    return <EmptyState view="large" />;
  }

  // if there is only one section and it's not empty, we want to expand it
  // to visitors to show more thumbs immediately
  const expandOnlySection = !isOwner && hasSingularContentType && emptySections;

  if (expandOnlySection) {
    shopWidgetConfigs = {
      coverDeviation: getCoverDeviationWidgetConfig({ t, dispatch }),
    };
    if (!emptySections['commissions']) {
      shopWidgetConfigs['commissions'] = getShopCommissionWidgetConfig({
        t,
        dispatch,
      });
    }
    if (!emptySections['premium_folders']) {
      shopWidgetConfigs['premiumFolders'] = getShopPremiumFoldersWidgetConfig({
        t,
        dispatch,
      });
    }
    if (!emptySections['adoptables']) {
      shopWidgetConfigs['adoptables'] = getShopAdoptablesWidgetConfig({
        t,
        dispatch,
      });
    }
    if (!emptySections['premium_content']) {
      shopWidgetConfigs['premiumContent'] = getShopPremiumContentWidgetConfig({
        t,
        dispatch,
      });
    }
  }

  return (
    <div className={className}>
      <Zone
        name="topLeft"
        className={c(s['zone'], s['top-left-zone'])}
        widgetConfigs={shopWidgetConfigs}
      />
      <Zone
        name="topBar"
        className={s['zone']}
        widgetConfigs={shopWidgetConfigs}
      />
    </div>
  );
};

export default enhance(ShopAll);
