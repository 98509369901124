import i18next from 'i18next';
import React from 'react';
import {
  all,
  take,
  takeLatest,
  select,
  race,
  call,
  put,
  delay,
} from 'redux-saga/effects';
import ddt from '@wix/da-ddt';
import { Url, USER_PROFILE_BACKROOM_SECTIONS } from '@wix/da-url';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import ErrorIcon from '@wix/da-ds/pkg/Icons/CloseBold';
import AddToGroup from '@wix/da-ds/pkg/Icons/AddToGroup';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { addNotification } from '@wix/da-shared-react/pkg/Notifications/redux/actionCreators';
import { NotificationType } from '@wix/da-shared-react/pkg/Notifications';

import {
  confirmNo,
  confirmYes,
  confirmValue,
  pushGlobalConfirmationModal,
  pushModal,
  popModal,
} from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';

import {
  Death as CloseGroupIcon,
  Warning as WarningIcon,
} from '@wix/da-ds/pkg/Icons/Confirmations';

import {
  requestLeaveGroup,
  groupJoinSuccess,
} from '@wix/da-shared-react/pkg/redux/groups/actions';

import { ModuleType } from '../../types/modules';
import {
  getModuleVersion as getModuleVersionNewGrusers,
  getModuleIdByModuleName as getModuleIdByModuleNameNewGrusers,
} from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import {
  fetchModuleEndpoint as fetchModuleEndpointNewGrusers,
  fetchModuleEndpointSuccess as fetchModuleEndpointSuccessNewGrusers,
  fetchModuleEndpointError as fetchModuleEndpointErrorNewGrusers,
} from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { getIsGroupProfile, getProfileGruser } from '../selectors/users';
import { getIsGroupFounder, getCanLeave } from '../selectors/groups';
import { getCanInviteToGroup } from '../selectors/privs';
import { getActiveSection } from '../selectors/layout';
import {
  inviteModuleSaved,
  inviteModuleInitialized,
  recruitmentModuleSaved,
  recruitmentModuleInitialized,
} from '../actions/modules';
import {
  requestCloseGroup,
  requestChangeFounder,
  requestChangeFounderCancel,
  requestChangeFounderError,
  requestChangeFounderSuccess,
  requestFounderLeaveGroup,
  inviteToGroup,
  contributeDeviations,
} from '../actions/groups';
import {
  createGroup,
  groupCreationSuccess,
  groupCreationFailure,
} from '../actions/groupCreation';
import { ModalType } from '../../types/modals';

const logger = ddt.createLogger('groupCreation');

function* handleGroupCloseFlow(action: ReturnType<typeof requestCloseGroup>) {
  const { group } = action.payload;

  // Groups can only be closed from the backroom section, since it relies on
  // GROUP_MANAGE, which is a backroom only module
  const section = yield select(getActiveSection);
  if (section !== 'backroom') {
    window.location.href = Url.groupBackroomLink(
      group.username,
      USER_PROFILE_BACKROOM_SECTIONS.ROOT,
      '',
      {
        flow: 'close',
      }
    );
    return;
  }

  // Step 1 - Show the modal to pick the reason for closing a group
  yield put(
    pushModal(ModalType.GROUP_CLOSE, {
      wrapInModal: false,
      params: {
        group,
      },
    })
  );

  const { confirm, cancel } = yield race({
    confirm: take(confirmValue),
    cancel: take(confirmNo),
  });
  yield put(popModal());

  if (cancel) {
    return;
  }
  const { payload: reason } = confirm;

  // Step 2 - Show a confirmation modal
  yield put(
    pushGlobalConfirmationModal({
      variant: 'vertical',
      cancelBtnLabelTransKey: 'groups.close.confirm.button.changeFounder',
      confirmBtnLabelTransKey: 'groups.close.confirm.button.close',
      textTransKey: 'groups.close.confirm.body',
      titleTransKey: 'groups.close.confirm.heading',
      confirmBtnType: 'danger',
      icon: <CloseGroupIcon />,
    })
  );

  const { changeFounder } = yield race({
    yes: take(confirmYes),
    changeFounder: take(confirmNo),
  });

  if (changeFounder) {
    // Stay on the groups page, close the modal
    yield put(popModal());

    // Request to change the founder
    yield put(requestChangeFounder(group));

    // Wait for success/failure
    const { success } = yield race({
      success: take(requestChangeFounderSuccess),
      cancel: take(requestChangeFounderCancel),
      error: take(requestChangeFounderError),
    });

    // Nothing to do
    if (!success) {
      return;
    }
  }

  // Step 3 - Actually closing the group - Send a PAPI request
  const { username } = group;

  const gruser = yield select(getProfileGruser);
  const id = yield select(
    getModuleIdByModuleNameNewGrusers,
    gruser,
    ModuleType.GROUP_MANAGE
  );
  const version = yield select(getModuleVersionNewGrusers, gruser, id);
  yield put(
    fetchModuleEndpointNewGrusers(gruser, {
      path: '/group_manage/close',
      method: 'post',

      id,
      version,
      username,
      reason,
    })
  );

  const { error } = yield race({
    success: take(fetchModuleEndpointSuccessNewGrusers),
    error: take(fetchModuleEndpointErrorNewGrusers),
  });

  if (error) {
    return;
  }

  // Step 4 - Show a success message
  yield put(
    pushGlobalConfirmationModal({
      variant: 'vertical',
      titleTransKey: 'groups.close.success.heading',
      textTransKey: 'groups.close.success.body',
      confirmBtnLabelTransKey: 'groups.close.success.button',
      icon: <CloseGroupIcon />,
    })
  );

  yield take(confirmYes);

  yield put(popModal());
  // Ignoring the GET params
  window.location.replace(window.location.pathname);
}

function* handleGroupCreation(action: ReturnType<typeof createGroup>) {
  const response = yield call(requestPuppy, {
    url: '/group/create',
    method: 'post',
    data: action.payload,
  });
  logger.log('handleGroupCreation, response:', response);

  if (response && response.success) {
    const { group: newGroup } = response;
    yield put(groupCreationSuccess(newGroup));

    // todo: remove groupCreationSuccess and newGroup in GroupCreation modal?
    // since the above action ends up being future proofing
    if (typeof window !== 'undefined') {
      window.location.assign(Url.userLink(newGroup.username));
    }
    return;
  }

  if (response?.errors) {
    yield put(groupCreationFailure(response.errors));
    return;
  }

  yield putErrorNotification(
    i18next.t('groupCreation.cantCreate.backendError', {
      error: response?.details?.error?.human ?? 'General error creating group',
    })
  );
}

function* handleInviteToGroupFlow(action: ReturnType<typeof inviteToGroup>) {
  const { group } = action.payload;
  const canInvite = yield select(getCanInviteToGroup);
  if (!canInvite) {
    return;
  }
  // Groups can only be closed from the backroom section, since it relies on
  // GROUP_RECRUITMENT, which is a backroom only module
  const section = yield select(getActiveSection);
  if (section !== 'backroom') {
    window.location.href = Url.groupBackroomLink(
      group.username,
      USER_PROFILE_BACKROOM_SECTIONS.ROOT,
      '',
      {
        flow: 'invite',
      }
    );
    return;
  }

  // Step 1 - Show the invite modal
  yield put(
    pushModal(ModalType.INVITE_TO_GROUP, {
      params: {
        group,
      },
    })
  );
  // Wait for the config to load
  const { initialized } = yield race({
    initialized: take(inviteModuleInitialized),
    timeout: delay(30 * 1000),
  });
  if (!initialized) {
    logger.error('Could not initialize ModalType.INVITE_TO_GROUP');
    return;
  }

  // Wait for the form to close or successfully save module data
  const { close } = yield race({
    success: take(inviteModuleSaved),
    close: take(popModal),
  });
  if (close) {
    // User closed the modal, exit the flow
    return;
  }

  // Close the invite modal
  yield put(popModal());

  // Step 2 - Show a success modal
  yield put(
    pushGlobalConfirmationModal({
      variant: 'vertical',
      cancelBtnLabelTransKey: 'groups.invite.success.button.dismiss',
      confirmBtnLabelTransKey: 'groups.invite.success.button.inviteMore',
      titleTransKey: 'groups.invite.success.heading',
      icon: <WarningIcon />,
    })
  );

  const { no } = yield race({
    yes: take(confirmYes),
    no: take(confirmNo),
  });

  // if secondary button (no) was clicked, redirect to group
  if (no) {
    yield put(popModal());
    // redirect to group
    // window.location.href = Url.userLink(group.username);
    // Makes more sense to reload the current page
    // Ignoring the GET params
    window.location.replace(window.location.pathname);

    return;
  }

  // Otherwise, if the primary button (yes) was clicked, restart the flow
  yield put(inviteToGroup(group));
}

function* handleGroupChangeFounderFlow(
  action: ReturnType<typeof requestChangeFounder>
) {
  const { group } = action.payload;
  const isGroup = yield select(getIsGroupProfile);
  if (!isGroup) {
    yield put(requestChangeFounderError(group, 'Not a group'));
    return;
  }

  const isFounder = yield select(getIsGroupFounder);
  if (!isFounder) {
    yield put(requestChangeFounderError(group, 'Not a founder'));
    return;
  }

  // Groups can only be closed from the backroom section, since it relies on
  // GROUP_RECRUITMENT, which is a backroom only module
  const section = yield select(getActiveSection);
  if (section !== 'backroom') {
    window.location.href = Url.groupBackroomLink(
      group.username,
      USER_PROFILE_BACKROOM_SECTIONS.ROOT,
      '',
      {
        flow: 'founder-leave',
      }
    );
    return;
  }

  // Open the modal
  yield put(pushModal(ModalType.GROUP_CHANGE_FOUNDER, { wrapInModal: false }));
  // wait for config to load
  const { initialized } = yield race({
    initialized: take(recruitmentModuleInitialized),
    timeout: delay(10 * 1000),
  });
  if (!initialized) {
    yield put(requestChangeFounderError(group, 'Initialization error'));
    return;
  }

  // wait for the module form to save
  const { close } = yield race({
    success: take(recruitmentModuleSaved),
    close: take(popModal),
  });

  // Nothing to do
  if (close) {
    yield put(requestChangeFounderCancel(group));
    return;
  }

  // Success! close the modal
  yield put(popModal());

  // Tell everyone that we are done
  yield put(requestChangeFounderSuccess(group));
}

function* handleGroupFounderLeaveFlow(
  action: ReturnType<typeof requestFounderLeaveGroup>
) {
  const { group } = action.payload;

  const isFounder = yield select(getIsGroupFounder);
  const canLeave = yield select(getCanLeave);
  if (!isFounder || canLeave) {
    // Go to the da-shared-react group flow
    yield put(requestLeaveGroup(group));
    return;
  }

  // Request to change the founder
  yield put(requestChangeFounder(group));

  // Wait for success/failure
  const { success } = yield race({
    success: take(requestChangeFounderSuccess),
    cancel: take(requestChangeFounderCancel),
    error: take(requestChangeFounderError),
  });

  // We did not successfully changed the founder
  if (!success) {
    return;
  }
  // Otherwise, changing the founder was successful, so we can continue the shared requestLeaveGroup flow.

  // Continue the regular leave flow:
  yield put(requestLeaveGroup(success.payload.group));
}

function* handleGroupJoinSuccess(action: ReturnType<typeof groupJoinSuccess>) {
  // This is a shared action, and we do not generally want to reload the page on success, but on user-profiles we do
  yield delay(1000); // wait 1s
  window.location.reload();
}

function* handleContributeDeviations(
  action: ReturnType<typeof contributeDeviations>
) {
  const { group, deviations, type, folderId } = action.payload;

  const responses = [] as any;

  for (const deviation of deviations) {
    const response = yield call(
      requestPuppy,
      {
        method: 'post',
        url: '/group_add',
        data: {
          deviationid: deviation.deviationId,
          groupid: group.userId,
          folderid: folderId,
          type,
        },
      },
      undefined,
      'dadeviation'
    );
    responses.push(response);
  }

  if (deviations.length > 1) {
    const successes = responses.filter(response => response.success).length;
    // at least one of the deviations needs a vote
    const needsVote = responses.some(response => response.needsVote);

    yield put(
      addNotification(NotificationType.Generic, {
        message: needsVote
          ? i18next.t('groups.submit.notification.needsVote.multi', {
              successes,
              total: deviations.length,
            })
          : i18next.t('groups.submit.notification.success.multi', {
              successes,
              total: deviations.length,
            }) +
            (successes > 0
              ? i18next.t('groups.submit.notification.success.multi.checkNC')
              : ''),
        cardProps: {
          multiLine: true,
          duration: 5000,
          iconComponent: AddToGroup,
        },
      })
    );
    return;
  }

  const response = responses[0];

  if (isApiError(response)) {
    yield put(
      addNotification(NotificationType.Generic, {
        message: response?.errorDetails || response?.errorDescription,
        isError: true,
        cardProps: {
          multiLine: true,
          duration: 5000,
          iconComponent: ErrorIcon,
        },
      })
    );
    return;
  }

  if (response.needsVote) {
    yield put(
      addNotification(NotificationType.Generic, {
        message: i18next.t('groups.submit.notification.success.needsVote'),
        cardProps: {
          multiLine: true,
          duration: 5000,
          iconComponent: AddToGroup,
        },
      })
    );
    return;
  }

  yield put(
    addNotification(NotificationType.Generic, {
      message: i18next.t('groups.submit.notification.success'),
      cardProps: {
        multiLine: true,
        duration: 5000,
        iconComponent: AddToGroup,
      },
    })
  );
}

export default function* saga() {
  yield all([
    takeLatest(createGroup, handleGroupCreation),
    takeLatest(requestCloseGroup, handleGroupCloseFlow),
    takeLatest(requestChangeFounder, handleGroupChangeFounderFlow),
    takeLatest(inviteToGroup, handleInviteToGroupFlow),
    takeLatest(contributeDeviations, handleContributeDeviations),
    takeLatest(requestFounderLeaveGroup, handleGroupFounderLeaveFlow),
    takeLatest(groupJoinSuccess, handleGroupJoinSuccess),
  ]);
}
