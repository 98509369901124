import { useSelector } from 'react-redux';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { getProfileGruser } from '../../../../../selectors/users';
import { PapiDeviation } from '@wix/da-papi-types';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';

export const useTiersModuleCounts = () => {
  // SUBSCRIPTION_TIPS module does not contain all the neccessary data
  // so we have to fetch it from the TIERS module
  const gruser = useSelector(getProfileGruser);
  const moduleData = useSelector<any>(
    state => getModuleDataByModuleName(state, gruser, 'tiers').tiers
  ) as number[];
  const tiers = (useSelector<any>(state =>
    getDeviationsByIds(state, moduleData || [])
  ) || []) as PapiDeviation[];

  const publishedCount = tiers.filter(
    deviation => deviation.tier?.state === 'active'
  ).length;
  const subscriberCount = tiers.reduce(
    (total, deviation) => total + (deviation.tier?.stats?.subscribers || 0),
    0
  );
  const draftCount = tiers.filter(
    deviation => deviation.tier?.state === 'draft'
  ).length;

  return {
    draftCount,
    subscriberCount,
    publishedCount,
  };
};

export default useTiersModuleCounts;
