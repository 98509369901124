import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { AppState } from '../../../../types/store';
import { PostsSortOrder, PostsType } from '../../../../types/posts';
import {
  isCurrentUserProfileOwner,
  getIsWatchingProfileOwner,
  getIsGroupProfile,
  getProfileGruser,
} from '../../../selectors/users';
import { getPostsDeviationsStreamId } from '../../../streams/posts';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';

import { Props, RouteParams } from './PostsSection';
import { isSectionLoading } from '../../../selectors/sections';
import { PapiGruserModulePostsFeed } from '@wix/da-papi-types';

type StateProps = Pick<
  Props,
  | 'isGroup'
  | 'isOwner'
  | 'isWatching'
  | 'isEmpty'
  | 'streamId'
  | 'counts'
  | 'isSectionLoading'
>;
type RouteProps = RouteComponentProps<RouteParams>;
type OwnProps = Omit<Props & RouteProps, keyof StateProps>;

export const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps & RouteProps,
  AppState
> = (state, ownProps) => {
  const username = ownProps.match.params.username;
  const sortOrder = (ownProps.match.params.sortOrder ||
    PostsSortOrder.NEWEST) as PostsSortOrder;
  const postsSection = (ownProps.match.params.postsSection ||
    PostsType.ALL) as PostsType;
  const streamId = getPostsDeviationsStreamId({
    type: postsSection!,
    sortOrder,
  });

  const moduleData = getModuleDataByModuleName(
    state as any,
    getProfileGruser(state),
    'posts_feed'
  ) as PapiGruserModulePostsFeed;
  const { counters: counts } = moduleData || {};

  const isInitialized = streamSelectors.isInitialized(state, streamId);
  const isFetching = streamSelectors.isFetching(state, streamId);
  const isEmpty =
    isInitialized &&
    !isFetching &&
    streamSelectors.getItems(state, streamId).length === 0;
  const isGroup = getIsGroupProfile(state);
  const isOwner = isCurrentUserProfileOwner(state);
  const isWatching = getIsWatchingProfileOwner(state);

  return {
    isEmpty,
    username,
    sortOrder,
    streamId,
    postsSection,
    isGroup,
    isOwner,
    isWatching,
    counts,
    isSectionLoading: isSectionLoading(state),
  };
};

export default connect(mapStateToProps);
