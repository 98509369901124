import { Action } from 'redux-actions';
import { PapiModuleGroupNotes } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { actions as threadActions } from '@wix/da-shared-react/pkg/CommentThread';
import { CommentableTypes } from '@wix/da-shared-react/pkg/Comment/redux/types';

import {
  setSelectedNoteId,
  setNotesOffset,
} from '../../../../actions/backroomSection';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleGroupNotes,
  requestParams: any
): Action<any>[] => {
  const { results, selectedNote } = moduleData;
  const selectedOrFirstNote = selectedNote || results?.[0];

  return [
    setNotesOffset(requestParams.offset),
    setSelectedNoteId(selectedOrFirstNote?.noteId),
    ...(selectedOrFirstNote
      ? [
          threadActions.initializeThread({
            thread: {
              commentableTypeId: CommentableTypes.COMMENT_THREADNOTE,
              commentableItemId: selectedOrFirstNote.noteId,
              order: 'oldest',
              canPostComment: true,
              items: [],
              total: 0,
              hasMore: true,
            },
            maxDepth: requestParams.commentMaxDepth,
          }),
        ]
      : []),
  ];
};
