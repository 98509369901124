// DS: CO1.3
import React from 'react';
import c from 'classnames';
import { PapiBadge } from '@wix/da-papi-types';
import Badge from '@wix/da-ds/pkg/Badge';
import { showBadgeInfoModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { getBiDataForBadge } from '@wix/da-shared-react/pkg/utils/badges';

import s from './SpotlightBadge.scss';

export interface Props {
  className?: string;
  badge: PapiBadge;
  username: string;
  showModal: typeof showBadgeInfoModal;
}

const SpotlightBadge: React.FC<Props> = ({
  className,
  badge,
  username,
  showModal,
}) => {
  const logBiEvent = useBiLogger();
  const handleBadgeClick = () => {
    showModal(username, badge.id!);
    logBiEvent(getBiDataForBadge(badge));
  };
  return (
    <div className={c(s['root'], className)} onClick={handleBadgeClick}>
      <Badge className={s['badge']} badge={badge} size={68} type="huge" />
      <div className={s['blur']} />
      <div className={s['title']}>{badge.baseTitle}</div>
    </div>
  );
};
SpotlightBadge.displayName = 'SpotlightBadge';

export default SpotlightBadge;
