import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';

const getStatLine = (t: Function, entity: string, count?: number) => {
  const formattedCount = count && shortenNumber(count);
  return count
    ? t(`common.stats.${entity}`, {
        count,
        formattedCount,
      })
    : '';
};

export default getStatLine;
