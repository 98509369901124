import { AppState } from '../../types/store';
import { createSelector } from 'reselect';
import { isCurrentUserProfileOwner, getIsCurrentGroupAdmin } from './users';
import { flagEnabled } from '@wix/da-react-context/pkg/flags/redux/selectors';
import { sectionRoutes, DEFAULT_PAGE_LAYOUT } from '../routes';
import { Route as RouteDefinition } from '../../types/route';

// Filter out available routes depending on features and ownership
export const getSectionRoutes = (state: AppState) => {
  const isOwner = isCurrentUserProfileOwner(state);
  const isGroupAdmin = getIsCurrentGroupAdmin(state);
  return sectionRoutes.filter(route => {
    if (route.featureProtected && !flagEnabled(state, route.featureProtected)) {
      return false;
    }
    if (route.isForAdminsOnly && !isGroupAdmin) {
      return false;
    }
    return isOwner || !route.isForOwnerOnly;
  });
};
export const getPageLayout = (state: AppState) =>
  state?.pageLayout || DEFAULT_PAGE_LAYOUT;

export const hasSubscriptions = (state: AppState) =>
  getPageLayout(state).includes('subscriptions');

// Filter and order the section routes depending on what the BE told us that we should show
export const getPageRoutes = createSelector(
  [getSectionRoutes, getPageLayout],
  (routes, routeKeys) =>
    (routeKeys
      .map(routeKey => routes.find(route => route.key === routeKey))
      .filter(x => x) || []) as RouteDefinition[]
);
