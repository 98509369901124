import React, { useContext } from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import DAProtectIconWithTooltip from '@wix/da-shared-react/pkg/DAProtectIconWithTooltip';
import WidgetHeader from '../../../UserWidgets/_partials/WidgetHeader/WidgetHeader';
import WidgetHeaderMobile from '../../../UserWidgets/_partials/WidgetHeaderMobile/WidgetHeaderMobile';
import EditGallectionButton from '../EditGallectionButton';
import SearchField from '../SearchField';
import { GallectionType } from '../../../../../types/gallection';
import { isCurrentUserProfileOwner } from '../../../../selectors/users';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import s from './FoldersHeader.scss';

export interface Props {
  gallectionType: GallectionType;
  isFolderSliderHidden?: boolean;
  className?: string;
}

export const FoldersHeader: React.FC<Props> = ({
  gallectionType,
  isFolderSliderHidden,
  className,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isGallery = gallectionType === GallectionType.GALLERY;
  const hasCore = useSelector(getCurrentUserHasCore);
  const isOwner = useSelector(isCurrentUserProfileOwner);

  const header = isFolderSliderHidden
    ? ''
    : t(`pages.gallections.foldersHeader.var.${gallectionType}`);

  if (isMobile) {
    return <WidgetHeaderMobile header={header} />;
  }

  return (
    <WidgetHeader
      header={header}
      className={c(
        s['header'],
        isFolderSliderHidden && s['folder-slider-hidden'],
        className
      )}
      extras={
        <div className={s['header-extras']}>
          {isGallery && isOwner && hasCore && (
            <DAProtectIconWithTooltip
              variant="active"
              className={s['da-protect-icon']}
            />
          )}
          <EditGallectionButton className={s['edit-button']} />
          <SearchField className={s['flex-grow-right']} />
        </div>
      }
    />
  );
};
FoldersHeader.displayName = 'FoldersHeader';

export default FoldersHeader;
