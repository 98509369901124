import React from 'react';
import { PapiDeviation } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import AdoptableShopCard from '@wix/da-shared-react/pkg/ShopCard/AdoptableShopCard';
import { ShopItemType } from '../../../../../types/shop';
import { RenderCardProps } from '../_partials/ShopGridWithFilters/types';
import ShopGridWithFilters from '../_partials/ShopGridWithFilters';
import AdoptablesEmptyState from '../_partials/AdoptablesEmptyState';

const BILOGGER_CONTEXT = {
  sectionname: 'Adoptables',
  widgetname: 'adoptables',
};

const renderCard = (props: RenderCardProps): React.ReactNode => {
  const { subject, index, width, style } = props;
  return (
    <div style={{ ...style, height: 'auto' }}>
      <AdoptableShopCard
        key={index}
        width={width}
        withAuthorDetails={false}
        withIndicators={false}
        withOwnerDetails
        deviation={subject as PapiDeviation}
      />
    </div>
  );
};

export interface Props {
  className?: string;
}

export const ShopAdoptables: React.FC<Props> = ({ className }) => {
  return (
    <BiLoggerContextProvider value={BILOGGER_CONTEXT}>
      <ShopGridWithFilters
        className={className}
        itemType={ShopItemType.ADOPTABLES}
        moduleName="adoptables"
        uploadMoreBiClickInfo="create_adoptable"
        invitationToUpload={AdoptablesEmptyState}
        renderCard={renderCard}
      />
    </BiLoggerContextProvider>
  );
};

ShopAdoptables.displayName = 'ShopAdoptables';
export default ShopAdoptables;
