import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButtonWithBackground from '@wix/da-ds/pkg/Buttons/IconButton/IconButtonWithBackground';
import ChangeSkin from '@wix/da-ds/pkg/Icons/24x24/ChangeSkin';
import {
  BiContextProvidedValue,
  BiData,
  ChangeBackgroundClickBiEvent,
} from '@wix/da-bi/pkg/events';
import {
  closeSkinSelector,
  openSkinSelector,
} from '../../../../actions/profileSkins';
import {
  getProfileSkin,
  isSkinSelectorShown as isSkinSelectorShownSelector,
} from '../../../../selectors/profileSkins';

interface Props {
  className?: string;
}
export const SkinButton: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const profileSkin = useSelector(getProfileSkin);
  const isSkinSelectorShown = useSelector(isSkinSelectorShownSelector);

  const handleClick = useCallback(() => {
    if (isSkinSelectorShown) {
      dispatch(closeSkinSelector());
    } else {
      dispatch(openSkinSelector());
    }
  }, [dispatch, isSkinSelectorShown]);

  return (
    <IconButtonWithBackground
      className={className}
      size="large"
      variant="edit-purple"
      icon={ChangeSkin}
      biData={BiData<ChangeBackgroundClickBiEvent>({
        evid: 370,
        background_name: profileSkin?.skinId ?? 'original',
        typeid: BiContextProvidedValue,
        itemid: BiContextProvidedValue,
        core_only: profileSkin?.isCore ? 1 : 0,
      })}
      onClick={handleClick}
    />
  );
};

export default SkinButton;
