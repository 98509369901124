import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import { toggleCommissionStatus } from '@wix/da-shared-react/pkg/redux/commissions/actionCreators';
import {
  getIsDeleted,
  getIsActive,
} from '@wix/da-shared-react/pkg/redux/commissions/selectors';
import {
  editDeviation,
  deleteDeviation,
} from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';

import { isCurrentUserProfileOwner } from '../../../../../selectors/users';
import { Props } from './CommissionThumbWithMenu';

type StateProps = Pick<Props, 'isOwner' | 'isDeleted' | 'isActive'>;
type DispatchProps = Pick<
  Props,
  'toggleCommissionStatus' | 'editDeviation' | 'deleteDeviation'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => ({
  isOwner: isCurrentUserProfileOwner(state),
  isDeleted: getIsDeleted(state, ownProps.deviation.deviationId),
  isActive: getIsActive(state, ownProps.deviation.deviationId),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  toggleCommissionStatus,
  editDeviation,
  deleteDeviation,
};

export default connect(mapStateToProps, mapDispatchToProps);
