import { connect, MapStateToProps } from 'react-redux';
import { getModuleData } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';

import { AppState } from '../../../../types/store';
import {
  getProfileOwnerUser,
  getProfileGruser,
} from '../../../selectors/users';

import { Props } from './GroupNotes';

type StateProps = Pick<Props, 'profileOwnerUser' | 'results'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const moduleData = getModuleData(
    state as any,
    getProfileGruser(state),
    ownProps.moduleId
  );
  const { results } = moduleData || {};

  return {
    results,
    profileOwnerUser: getProfileOwnerUser(state),
  };
};

export default connect(mapStateToProps);
