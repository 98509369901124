// DS: TA0.1
import React from 'react';
import { useSelector } from 'react-redux';
import Sticky from '@wix/da-ds/pkg/Sticky';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { getIsGroupProfile } from '../../../selectors/users';
import UserProfileSubNavDesktop from './variants/UserProfileSubNavDesktopInner';
import GroupProfileSubNavDesktop from './variants/GroupProfileSubNavDesktopInner';
import s from './SubNavBarDesktop.scss';

export interface Props {}

const SubNavBarDesktop: React.FC<Props> = props => {
  const isGroup = useSelector(getIsGroupProfile);
  return (
    <div className={s['root']}>
      <ErrorBoundary debugComponent="SubNavBarDesktop">
        <Sticky zIndex={parseInt(s['z-index'])} usePortal>
          {isSticky =>
            isGroup ? (
              <GroupProfileSubNavDesktop isSticky={isSticky} />
            ) : (
              <UserProfileSubNavDesktop isSticky={isSticky} />
            )
          }
        </Sticky>
      </ErrorBoundary>
    </div>
  );
};
SubNavBarDesktop.displayName = 'SubNavBarDesktop';

export default SubNavBarDesktop;
