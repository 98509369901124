import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { formatDate, DATE_FORMATS } from '@wix/da-ds/pkg/Time/functions';

export interface Props {
  className?: string;
  countClassName?: string;
  value: string;
  stat: string;
}

export const ProfileStatItemDate: React.FC<Props> = ({
  className,
  countClassName,
  value = '',
  stat,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const now = new Date();
  const date = new Date(Date.parse(`${value}`));

  const formattedDate = formatDate(date.toISOString(), DATE_FORMATS.Date.Short);
  const yearsAgo = now.getFullYear() - date.getFullYear();
  if (yearsAgo > 0) {
    return (
      <span className={className}>
        {t(`profileStats.${stat}YearsAgo`, {
          postProcess: ['reactPostprocessor', 'featureBrancher'],
          count: yearsAgo,
          counter: !isMobile ? (
            <SimpleTooltip key={stat} placement="top" text={formattedDate}>
              {yearsAgo}
            </SimpleTooltip>
          ) : (
            <span className={countClassName}>{yearsAgo}</span>
          ),
          stat,
        })}
      </span>
    );
  }

  return (
    <span className={className}>
      {t(`profileStats.${stat}`, {
        postProcess: ['reactPostprocessor', 'featureBrancher'],
        formattedDate: <span className={countClassName}>{formattedDate}</span>,
        stat,
      })}
    </span>
  );
};

ProfileStatItemDate.displayName = 'ProfileStatItemDate';

export default ProfileStatItemDate;
