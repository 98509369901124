import React from 'react';
import TextButton, { TextButtonProps } from '@wix/da-ds/pkg/Buttons/TextButton';
import ArrowRight from '@wix/da-ds/pkg/Icons/16x16/ArrowRight';

interface Props
  extends Omit<TextButtonProps, 'variant' | 'size' | 'postfix' | 'icon'> {
  withArrow?: boolean;
  tipType: 'top' | 'bottom';
}

/**
 * A `<TextButton />` with a preset `size`, `variant`, and `postfix`.
 *
 * We use this at the end of a tip.
 */
const TipButton: React.FC<Props> = ({
  tipType,
  withArrow = true,
  ...props
}) => (
  <TextButton
    size="small"
    variant="main"
    postfix={withArrow ? ArrowRight : undefined}
    {...props}
  />
);
TipButton.displayName = 'TipButton';

export default TipButton;
