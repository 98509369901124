import { createAction } from 'redux-actions';
import { PapiUser, PapiDeviation } from '@wix/da-papi-types';
import { GroupGallectionType } from '@wix/da-shared-react/pkg/GroupGallectionSelector/redux/types';

export const requestCloseGroup = createAction(
  `GROUPS.REQUEST_CLOSE`,
  (group: PapiUser) => ({ group })
);

export const inviteToGroup = createAction(
  `GROUPS.INVITE_TO_GROUP`,
  (group: PapiUser) => ({ group })
);

export const requestChangeFounder = createAction(
  `GROUPS.REQUEST_CHANGE_FOUNDER`,
  (group: PapiUser) => ({ group })
);

export const requestChangeFounderSuccess = createAction(
  `GROUPS.REQUEST_CHANGE_FOUNDER_SUCCESS`,
  (group: PapiUser) => ({ group })
);

export const requestChangeFounderCancel = createAction(
  `GROUPS.REQUEST_CHANGE_FOUNDER_CANCEL`,
  (group: PapiUser) => ({ group })
);

export const requestChangeFounderError = createAction(
  `GROUPS.REQUEST_CHANGE_FOUNDER_ERROR`,
  (group: PapiUser, errorDescription: string) => ({ group, errorDescription })
);

export const requestFounderLeaveGroup = createAction(
  `GROUPS.REQUEST_FOUNDER_LEAVE_GROUP`,
  (group: PapiUser) => ({ group })
);

export const contributeDeviations = createAction(
  `GROUPS.CONTRIBUTE_DEVIATIONS`,
  (
    deviations: PapiDeviation[],
    group: PapiUser,
    type: GroupGallectionType,
    folderId: number
  ) => ({
    deviations,
    folderId,
    group,
    type,
  })
);
