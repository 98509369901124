import React from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import TipHeading from '../TipHeading';
import TipText from '../TipText';
import s from './Tip.scss';

interface Props {
  heading: React.ReactNode;
  text: string;
  button: React.ReactNode;

  className?: string;
}

/**
 * A tip that includes a heading, some text, and a read more link
 *
 * e.g.
 * ```jsx
 *  <Tip
 *    heading="How to Set and Hit Your Creative Goals"
 *    text="Views, likes, watch time, patrons, income... DeviantArt’s Matt Buc..."
 *    readMoreHref="https://www.google.com"
 *  />
 * ```
 */
const Tip: React.FC<Props> = ({ heading, text, button, className }) => (
  <Section className={c(s['root'], className)}>
    <TipHeading className={s['heading']}>{heading}</TipHeading>
    <TipText className={s['text']}>{text}</TipText>
    {button}
  </Section>
);

Tip.displayName = 'Tip';

export default Tip;
