import React from 'react';
import classnames from 'classnames';
import { PapiGallection } from '@wix/da-papi-types';
import Flex from '@wix/da-ds/pkg/Flex';
import DeviationDumbView from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import {
  BiContextProvidedValue,
  BiData,
  ItemClickBiEvent,
} from '@wix/da-bi/pkg/events';
import BiDiv from '@wix/da-bi/pkg/Components/BiDiv';
import { gallectionTypeToResourceType } from '@wix/da-shared-react/pkg/types/gallection';

import s from './SubfolderCard.scss';

export interface Props {
  subfolder: PapiGallection;
  isActive: boolean;
  onClick: () => void;
  className?: string;
}

const COVER_IMAGE_WIDTH = 120;
const COVER_IMAGE_HEIGHT = 60;

export const SubfolderCard: React.FC<Props> = ({
  subfolder,
  isActive,
  onClick,
  className,
}) => {
  const { name, size, thumb, type: folderType, folderId } = subfolder;
  return (
    <BiDiv
      onClick={onClick}
      biData={BiData<ItemClickBiEvent>({
        evid: 100,
        typeid: gallectionTypeToResourceType(folderType),
        itemid: folderId,
        widgetname: 'subfolder',
        sectionname: BiContextProvidedValue,
      })}
    >
      <Flex
        direction="column"
        className={classnames(isActive && s['active'], s['root'], className)}
      >
        <div className={s['cover-image-wrapper']}>
          {thumb ? (
            <DeviationDumbView
              width={COVER_IMAGE_WIDTH}
              height={COVER_IMAGE_HEIGHT}
              deviation={thumb}
              className={s['cover-image']}
            />
          ) : (
            <div
              style={{ width: COVER_IMAGE_WIDTH, height: COVER_IMAGE_HEIGHT }}
              className={classnames(s['cover-image'], s['no-cover-image'])}
            />
          )}
          <div className={s['cover-image-frame']} />
        </div>
        <Flex>
          <div className={s['name']}>{name}</div>
          <div className={s['size']}>{size}</div>
        </Flex>
      </Flex>
    </BiDiv>
  );
};
SubfolderCard.displayName = 'SubfolderCard';

export default SubfolderCard;
