import React, { lazy, Suspense } from 'react';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import retry from '@wix/da-shared-react/pkg/utils/webpack/chunkRetry';
import { Props } from './LightningOverlay';

const LightningOverlay = lazy(() =>
  retry(() => import(/* webpackChunkName: "bolt" */ './LightningOverlay'))
);

const LoadableLightningOverlay: React.FC<Props> = props => {
  const isMounted = useIsMounted();
  if (!isMounted) {
    return null;
  }

  return (
    <ErrorBoundary debugComponent="LightningOverlay">
      <Suspense fallback={null}>
        <LightningOverlay {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};
LoadableLightningOverlay.displayName = 'LoadableLightningOverlay';

export default LoadableLightningOverlay;
