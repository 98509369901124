import React, { useRef, useState, useContext, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PapiDeviation, PapiModuleTiers } from '@wix/da-papi-types';
import { useThrottle } from '@wix/da-hooks/pkg/useThrottle';
import { useResizeObserver } from '@wix/da-hooks/pkg/useResizeObserver';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import Slider from '@wix/da-ds/pkg/Slider';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import ArrowDownIcon from '@wix/da-ds/pkg/Icons/16x16/ArrowDown';
import ArrowUpIcon from '@wix/da-ds/pkg/Icons/ArrowUp';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import TiersSliderCard from './TiersSliderCard';
import AddTierCard from './AddTierCard';
import { isCurrentUserProfileOwner } from '../../../../../selectors/users';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';

import s from './Tiers.scss';

export interface Props {
  moduleId: number;
  seeAllUrl?: string;
  view?: 'promotion' | 'product';
  selectedTierId?: number;
  onClick?: (tier: PapiDeviation) => void;
  onSelect?: (tier: PapiDeviation) => void;
  withExpandButton?: boolean;
}

const CENTERED_SLIDER_MIN_WIDTH = 1300;
const RESIZE_THROTTLE_DELAY = 300;
const RESIZE_THROTTLE_OPTIONS = { trailing: true };

const Tiers: React.FC<Props> = ({
  moduleId,
  view = 'promotion',
  withExpandButton,
  selectedTierId,
  onClick,
  onSelect,
}) => {
  const {
    moduleData: { tiers: tierDeviationids = [] },
  } = useModuleData<PapiModuleTiers & { tiers: number[] }>(moduleId);
  const tiers = useSelector<any, PapiDeviation[]>(state =>
    getDeviationsByIds(state, tierDeviationids)
  );
  const isOwner = useSelector(isCurrentUserProfileOwner);

  const findTierIndexByDeviationId = useCallback(
    id => tiers.findIndex(({ deviationId }) => deviationId === id),
    [tiers]
  );
  const isSubscribedToOne = tiers.some(
    deviation => deviation.tier?.isUserSubscribed
  );
  const selectedIndex = Math.max(0, findTierIndexByDeviationId(selectedTierId));
  const isMobile = useContext(MobileContext);
  const [isCollapsed, setIsCollapsed] = useState(isMobile || isSubscribedToOne);

  const { t } = useTranslation();
  const count = tiers.length;
  const showAddTierCard = isOwner;
  const showExpandAllButton = withExpandButton && !isMobile;

  const sliderRef = useRef<HTMLDivElement>(null);
  const [sliderWidth, setSliderWidth] = useState(
    sliderRef.current?.getBoundingClientRect().width
  );
  const [sliderHeight, setSliderHeight] = useState(
    sliderRef.current?.getBoundingClientRect().height
  );
  const handleResize = useThrottle(
    () => {
      const newWidth = sliderRef.current!.getBoundingClientRect().width;
      if (newWidth !== sliderWidth) {
        setSliderWidth(newWidth);
      }
      const newHeight = sliderRef.current!.getBoundingClientRect().height;
      if (newHeight !== sliderHeight) {
        setSliderHeight(newHeight);
      }
    },
    [sliderWidth, sliderHeight],
    RESIZE_THROTTLE_DELAY,
    RESIZE_THROTTLE_OPTIONS
  );
  useResizeObserver(sliderRef, handleResize);

  return (
    <div className={s['root']} ref={sliderRef}>
      <Slider
        className={classnames(s['slider'], s[view])}
        contentClassName={classnames(
          s['slider-content'],
          !isMobile &&
            count < 4 &&
            sliderWidth &&
            sliderWidth >= CENTERED_SLIDER_MIN_WIDTH &&
            s['centered-slider-content']
        )}
        /* Owners have one extra slide at the beginning of the slider so offset index by one for owners */
        scrollToIndex={selectedIndex + (showAddTierCard ? 1 : 0)}
        scrollSnap={isMobile}
      >
        {showAddTierCard && (
          <AddTierCard
            count={count}
            className={s['add-tier-card']}
            view={view}
          />
        )}
        {tiers.map((tier, index) => (
          <TiersSliderCard
            key={tier.deviationId}
            tier={tier}
            onClick={onClick}
            onSelect={onSelect}
            view={view}
            withExpandButton={!showExpandAllButton}
            isCollapsed={isCollapsed}
            onToggleCollapsed={newIsCollapsed => setIsCollapsed(newIsCollapsed)}
            isSelected={view !== 'promotion' && selectedIndex === index}
          />
        ))}
      </Slider>

      {isMobile && tiers.length > 1 && (
        <div className={s['indicators']}>
          {tiers.map((_, index) => (
            <div
              key={index}
              className={classnames(
                s['indicator'],
                index === selectedIndex && s['active']
              )}
              onClick={() => onSelect && onSelect(tiers[index])}
            />
          ))}
        </div>
      )}

      {showExpandAllButton && (
        <div className={s['toggle-button-wrapper']}>
          <TextButton
            variant="main"
            size="large"
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={s['toggle-button']}
            postfix={isCollapsed ? ArrowDownIcon : ArrowUpIcon}
          >
            {isCollapsed
              ? t('widgets.tiers.showAllBenefits')
              : t('widgets.tiers.showLessBenefits')}
          </TextButton>
        </div>
      )}
    </div>
  );
};
Tiers.displayName = 'Tiers';

export default Tiers;
