import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Url, URLS, USER_PROFILE_SECTION_PATHS } from '@wix/da-url';
import { PapiGruserModuleGroupsMembers } from '@wix/da-papi-types';
import snakeCase from 'lodash/snakeCase';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import WidgetBoxWide from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import { useModuleName } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import GroupList from '../GroupList';
import EmptyState from '../../../EmptyState';
import {
  getProfileOwner,
  getProfileOwnerStat,
} from '../../../../selectors/users';
import { useSelector } from 'react-redux';
import { ModuleType } from '../../../../../types/modules';

import s from './GroupListBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
  isWide?: boolean;
}

const GroupListBox: React.FC<Props> = ({
  moduleId,
  widgetBoxProps,
  isWide,
}) => {
  const { t } = useTranslation();
  const profileOwner = useSelector(getProfileOwner);
  const moduleName = useModuleName(moduleId);
  const moduleNameSnakeCase = snakeCase(moduleName);

  const statName =
    moduleName === 'groupListAdmins' ? 'groupsAdmin' : 'groupsMember';
  const totalCount = useSelector<any, number>(state =>
    getProfileOwnerStat(state, statName)
  );

  const { moduleData } = useModuleData<PapiGruserModuleGroupsMembers>(moduleId);
  const modulePrivs = useModulePrivileges(moduleId);
  const isOwner = modulePrivs?.edit;
  const isEmpty = !moduleData?.results.length;

  if (isEmpty && !isOwner) {
    return null;
  }

  const renderEmpty = () =>
    isOwner ? (
      <EmptyState
        view="small"
        className={c(s['empty-state'], s['is-owner'], s[moduleNameSnakeCase])}
        title={t(`widgets.${moduleNameSnakeCase}.empty.title.owner`)}
        subtitle={t(`widgets.${moduleNameSnakeCase}.empty.subtitle.owner`)}
        buttonLabel={t(`widgets.${moduleNameSnakeCase}.empty.CTALabel.owner`)}
        withButton={true}
        onButtonClick={() => window.location.replace(URLS.GROUPS)}
      />
    ) : (
      <EmptyState
        view="small"
        className={s['empty-state']}
        titleClassName={'empty-state-title'}
        title={t(`widgets.${moduleNameSnakeCase}.empty.title.visitor`, {
          username: profileOwner?.user?.username,
        })}
        subtitle={t(`widgets.${moduleNameSnakeCase}.empty.subtitle.visitor`, {
          username: profileOwner?.user?.username,
        })}
      />
    );

  return isWide ? (
    <WidgetBoxWide
      header={t(`widgets.${moduleNameSnakeCase}.header`)}
      moduleId={moduleId}
      count={totalCount}
      id={
        moduleName === 'groupListAdmins'
          ? ModuleType.GROUPS_ADMIN
          : ModuleType.GROUPS_MEMBER
      }
      {...widgetBoxProps}
    >
      {!isEmpty ? (
        <GroupList moduleId={moduleId} isWide={isWide} />
      ) : (
        renderEmpty()
      )}
    </WidgetBoxWide>
  ) : (
    <WidgetBox
      header={t(`widgets.${moduleNameSnakeCase}.header`)}
      moduleId={moduleId}
      count={totalCount}
      seeAllUrl={Url.userLink(
        profileOwner.user.username,
        `${USER_PROFILE_SECTION_PATHS.ABOUT}#${moduleName}`
      )}
      {...widgetBoxProps}
    >
      {!isEmpty ? (
        <GroupList moduleId={moduleId} isWide={isWide} />
      ) : (
        renderEmpty()
      )}
    </WidgetBox>
  );
};
GroupListBox.displayName = 'GroupListBox';

export default GroupListBox;
