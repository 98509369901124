import { all, call, select, put, takeEvery } from 'redux-saga/effects';
import {
  PapiBadge,
  PapiDaUserProfileBadgesList,
  PapiRequestDaUserProfileBadgesList,
  PapiRequestDaUserProfileBadgesSetFeaturedOrder,
} from '@wix/da-papi-types';
import {
  takeStreamFetch,
  fetchStreamContents,
} from '@wix/da-shared-react/pkg/Stream/helpers';
import { selectors, actions } from '@wix/da-shared-react/pkg/Stream';
import { requestPuppy } from '@wix/da-http-client';
import {
  fetchMoreBadges,
  moveBadgeCommit,
  moveBadgeInStream,
  refreshBadgeModules,
} from '../actions/badges';
import {
  fetchFallbackBadgeCheck,
  fetchFallbackBadgeCheckSuccess,
} from '../actions/profileOwner';
import { BADGES_STREAM } from '../streams';
import { getModuleByModuleName } from '../selectors/modules';
import { ModuleType } from '../../types/modules';
import { updateModule } from '../actions/modules';
import { BADGE_COUNT } from '../components/UserWidgets/BadgesSpotlight/constants';

function* handleFetchBadges(action: ReturnType<typeof fetchMoreBadges>) {
  const { streamId } = action.payload;
  yield fetchStreamContents<
    PapiRequestDaUserProfileBadgesList,
    PapiDaUserProfileBadgesList
  >({
    streamId,
    papiProject: 'dauserprofile',
    url: '/badges/list',
    params: {},
    getStreamData: result => ({
      hasMore: false,
      items: result.results,
    }),
  });
}

function* handleMoveBadgeInStream(
  action: ReturnType<typeof moveBadgeInStream>
) {
  const { badgeId, position } = action.payload;
  const items: PapiBadge[] = yield select(selectors.getItems, BADGES_STREAM);
  const item = items.find(i => i.id === badgeId);
  if (!item) {
    return;
  }
  yield put(actions.moveItem({ streamId: BADGES_STREAM, item, position }));
}

function* handleMoveBadgeCommit(action: ReturnType<typeof moveBadgeCommit>) {
  const { badgeId, initialPosition } = action.payload;
  const items: PapiBadge[] = yield select(selectors.getItems, BADGES_STREAM);
  const featuredIds = items.slice(0, 5).map(b => b.id);
  const newPosition = items.findIndex(i => i.id === badgeId);
  if (
    newPosition < 0 ||
    newPosition === initialPosition ||
    featuredIds.length === 0
  ) {
    return;
  }
  const data: PapiRequestDaUserProfileBadgesSetFeaturedOrder = {
    badgeids: featuredIds,
  };
  yield call(
    requestPuppy,
    {
      method: 'post',
      url: 'badges/set_featured_order',
      data,
    },
    undefined,
    'dauserprofile'
  );
}

function* handleRefreshBadgeModules(action) {
  const items: PapiBadge[] = yield select(selectors.getItems, BADGES_STREAM);
  if (items.length === 0) {
    return;
  }

  const badgeSpotlightModuleData = yield select(
    getModuleByModuleName,
    ModuleType.BADGES_SPOTLIGHT
  );

  if (badgeSpotlightModuleData) {
    yield put(
      updateModule({
        id: badgeSpotlightModuleData.id,
        moduleData: {
          ...badgeSpotlightModuleData.moduleData,
          badges: items.slice(0, BADGE_COUNT),
        },
      })
    );
  }

  const aboutModuleData = yield select(getModuleByModuleName, ModuleType.ABOUT);

  if (aboutModuleData) {
    yield put(
      updateModule({
        id: aboutModuleData.id,
        moduleData: {
          ...aboutModuleData.moduleData,
          badges: items,
        },
      })
    );
  }
}

function* handleFetchFallbackBadgeCheck() {
  const response = yield call(
    requestPuppy,
    {
      method: 'get',
      url: 'badges/list',
      params: {},
    },
    undefined,
    'dauserprofile'
  );
  if (
    response.results.length > 0 &&
    response.results.find(
      badge => badge.typeId === 133 /* TOTALLLY NORMAL BADGE */
    )
  ) {
    yield put(fetchFallbackBadgeCheckSuccess());
  }
}

export default function* rootBadgesSaga() {
  yield all([
    takeStreamFetch(fetchMoreBadges, handleFetchBadges),
    takeEvery(moveBadgeInStream, handleMoveBadgeInStream),
    takeEvery(moveBadgeCommit, handleMoveBadgeCommit),
    takeEvery(refreshBadgeModules, handleRefreshBadgeModules),
    takeEvery(fetchFallbackBadgeCheck, handleFetchFallbackBadgeCheck),
  ]);
}
