import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './GallectionsEmptyState';
import { AppState } from '../../../../../types/store';
import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../../selectors/users';
import { getGallectionSectionType } from '../../../../selectors/gallectionSection';
import { contributeDeviations } from '../../../../actions/groups';
import { getCanSubmitToGallery } from '../../../../selectors/privs';

export type StateProps = Pick<
  Props,
  'isOwner' | 'gallectionType' | 'owner' | 'canSubmitDeviations'
>;
export type DispatchProps = Pick<Props, 'contributeDeviations'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  gallectionType: getGallectionSectionType(state),
  owner: getProfileOwnerUser(state),
  canSubmitDeviations: getCanSubmitToGallery(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  contributeDeviations,
};

export default connect(mapStateToProps, mapDispatchToProps);
