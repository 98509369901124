import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import WatchedDeviantsBox from './WatchedDeviantsBox';
import WatchedDeviantsBoxWide from './WatchedDeviantsBox/WatchedDeviantsBoxWide';
import WatchedDeviantsConfigPopoverLazy from './WatchedDeviantsConfigPopover/WatchedDeviantsConfigPopoverLazy';
import watchingDeviantsInit from './redux/watchingDeviantsInit';
import watchersDeviantsInit from './redux/watchersDeviantsInit';

export const getWatchersWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: WatchedDeviantsBox,
  configPopoverComponent: WatchedDeviantsConfigPopoverLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-12-watchers.svg',
    title: t('widgets.watchers.addButtonHeader'),
    description: t('widgets.watchers.description'),
  },
});

export const getWatchingWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: WatchedDeviantsBox,
  configPopoverComponent: WatchedDeviantsConfigPopoverLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-13-watching.svg',
    title: t('widgets.watching.addButtonHeader'),
    description: t('widgets.watching.description'),
  },
});

export const getWideWatchingWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: WatchedDeviantsBoxWide,
  configPopoverComponent: WatchedDeviantsConfigPopoverLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-13-watching.svg',
    title: t('widgets.watching.addButtonHeader'),
    description: t('widgets.watching.description'),
  },
  initActionsCreator: watchingDeviantsInit,
});

export const getWideWatchersWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: WatchedDeviantsBoxWide,
  configPopoverComponent: WatchedDeviantsConfigPopoverLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-12-watchers.svg',
    title: t('widgets.watchers.addButtonHeader'),
    description: t('widgets.watchers.description'),
  },
  initActionsCreator: watchersDeviantsInit,
});
