import { handleActions } from 'redux-actions';
import {
  setSelectedTierEntityId,
  setSelectedTierContentType,
  setSubscriptionsStats,
  setSubscriptionsChartData,
} from '../actions/subscriptionsSection';
import { default as SubscriptionsSection } from '../../types/subscriptionsSection';

const defaultState: SubscriptionsSection = {
  selectedTierEntityId: null,
  tierContentType: undefined,
  stats: undefined,
  chartData: undefined,
};

export default handleActions<SubscriptionsSection, any>(
  {
    [setSelectedTierContentType.toString()]: (
      state: SubscriptionsSection,
      action: ReturnType<typeof setSelectedTierContentType>
    ) => ({
      ...state,
      tierContentType: action.payload,
    }),
    [setSelectedTierEntityId.toString()]: (
      state: SubscriptionsSection,
      action: ReturnType<typeof setSelectedTierEntityId>
    ) => ({
      ...state,
      selectedTierEntityId: action.payload,
      tierContentType: undefined,
    }),
    [setSubscriptionsStats.toString()]: (
      state: SubscriptionsSection,
      action: ReturnType<typeof setSubscriptionsStats>
    ) => ({
      ...state,
      stats: action.payload,
    }),
    [setSubscriptionsChartData.toString()]: (
      state: SubscriptionsSection,
      action: ReturnType<typeof setSubscriptionsChartData>
    ) => ({
      ...state,
      chartData: action.payload,
    }),
  },
  defaultState
);
