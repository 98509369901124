import React from 'react';
import UserGrid from '../UserGrid';

import { useSelector } from 'react-redux';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { useNormalizedUserStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';

export interface Props {
  streamId: string;
}

export const UserStream: React.FC<Props> = ({ streamId }) => {
  const {
    items: users,
    hasMore,
    isFetching,
    fetchNext: fetchMore,
    total: totalCount,
  } = useNormalizedUserStream(streamId);
  const isEmpty = !users?.length;
  const canSeeAll = useSelector(state =>
    streamSelectors.getStreamParam(state, streamId, 'canSeeAll')
  );
  return (
    <UserGrid
      {...{
        users,
        hasMore,
        isFetching,
        fetchMore,
        totalCount,
        canSeeAll,
        isEmpty,
      }}
    />
  );
};

export default UserStream;
