import React from 'react';
import FolderBar, { Props } from '../';
import StickyBar from '../../StickyBar';

export const StickyFolderBar: React.FC<Props> = props => (
  <StickyBar>{() => <FolderBar {...props} />}</StickyBar>
);
StickyFolderBar.displayName = 'StickyFolderBar';

export default StickyFolderBar;
