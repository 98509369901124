/* eslint-disable @wix/da/import-scss-last */
import 'react-hot-loader/patch';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import i18n from '@wix/da-i18next/pkg/presetI18nClientConfig';
// project-base needs to go before app styles
import '@wix/da-ds/src/styles/project-base.scss';
import App from './components/App';
import { AppContainer } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import { configureStore } from './store/configureStore';
import {
  routerSafeHistory as history,
  configureHistoryListener,
} from '@wix/da-shared-react/pkg/redux/routing/history';
import { getClientInstance } from '@wix/da-ddt';
import Modal from 'react-modal';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import AppDocumentWrapper from '@wix/da-react-app/pkg/client/AppDocumentWrapper';
import { getProfileTheme, getProfileThemeSkin } from './selectors/profileSkins';

const ddt = getClientInstance();
ddt.replayServerSideLogs(window['__DDT__']);

const initialI18n = window['__INITIAL_I18N__'];

const initialState = window['__INITIAL_STATE__'];
delete window['__INITIAL_STATE__'];
const store = configureStore(initialState);
configureHistoryListener(store);
i18n(
  initialI18n,
  getEnv(store.getState()).isDebug,
  getEnv(store.getState()).flags
);

Modal.setAppElement('body');
const root = hydrateRoot(
  document,
  <AppDocumentWrapper
    store={store}
    themeSelector={getProfileTheme}
    themeSkinSelector={getProfileThemeSkin}
  >
    <Router history={history}>
      <App
        language={initialI18n.locale}
        userAgent={
          (window && window.navigator && window.navigator.userAgent) || ''
        }
      />
    </Router>
  </AppDocumentWrapper>
);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    // eslint-disable-next-line global-require
    const AppLatest = require('./components/App').default;
    root.render(
      <AppContainer>
        <AppDocumentWrapper store={store}>
          <Router history={history}>
            <AppLatest />
          </Router>
        </AppDocumentWrapper>
      </AppContainer>
    );
  });
}
