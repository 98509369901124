import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import { useController, useFormContext } from 'react-hook-form';
import SharedSelectField from '@wix/da-ds/pkg/Dropdown/select/SelectField';

function SelectField({ name, label, defaultValue = '', items = [], ...props }) {
  const id = useUniqueId();
  const { t } = useTranslation();
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <SharedSelectField
      id={id}
      label={label}
      items={items}
      onSelect={(value: string, event: any) => onChange(event)}
      error={error && t(error?.message)}
      {...props}
      {...inputProps}
    />
  );
}

export default SelectField;
