import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import StandardDropdown from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown';
import { PostsSortOrder } from '../../../../../../types/posts';

import s from './SortOrderSwitcher.scss';
import { BiData, FeedSortOrderSelectBiEvent } from '@wix/da-bi/pkg/events';

interface Props {
  value: PostsSortOrder;
  onChange: (value: PostsSortOrder) => void;
  className?: string;
}

enum sortOrderBiValue {
  NEWEST = 'newest',
  OLDEST = 'oldest',
}

const sortOrderBi = {
  [PostsSortOrder.NEWEST]: sortOrderBiValue.NEWEST,
  [PostsSortOrder.OLDEST]: sortOrderBiValue.OLDEST,
};

const SortOrderSwitcher: React.FC<Props> = ({ value, onChange, className }) => {
  const { t } = useTranslation();
  const items = useMemo(
    () => [
      {
        value: PostsSortOrder.NEWEST,
        label: t('common.sortOrder.newest'),
        biData: BiData<FeedSortOrderSelectBiEvent>({
          evid: 403,
          sort_order: sortOrderBi[PostsSortOrder.NEWEST],
        } as any),
      },
      {
        value: PostsSortOrder.OLDEST,
        label: t('common.sortOrder.oldest'),
        biData: BiData<FeedSortOrderSelectBiEvent>({
          evid: 403,
          sort_order: sortOrderBi[PostsSortOrder.OLDEST],
        } as any),
      },
    ],
    [t]
  );

  return (
    <StandardDropdown
      role="menu"
      className={className}
      toggleClassName={s['toggle']}
      arrowClassName={s['arrow']}
      placement="bottom-start"
      selectedValue={value}
      onSelect={onChange}
      items={items}
    />
  );
};
SortOrderSwitcher.displayName = 'SortOrderSwitcher';
export default SortOrderSwitcher;
