import React from 'react';
import { useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import EmptyGraphic from './_partials/EmptyGraphic';
import StatisticBlock from './_partials/StatisticBlock';
import StatisticButton from './_partials/StatisticButton';
import s from './EmptyState.scss';

interface Props {}

/** The empty state for a subscription stats panel with no data */
const EmptyState: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <div className={s['root']}>
      <div className={s['top-row']}>
        <StatisticBlock
          heading={t('widgets.subscriptionsSection.center.total.income')}
          alignment="left"
          statistic={null}
          postfix={
            <StatisticButton
              biData={BiData<DefaultBiEvent>({
                evid: 2,
                click_info: 'tier_manage_earnings',
                sectionname: BiContextProvidedValue,
                widgetname: BiContextProvidedValue,
                typeid: BiContextProvidedValue,
                itemid: BiContextProvidedValue,
              })}
              onClick={() => window.location.assign(URLS.EARNINGS)}
            >
              {t('widgets.subscriptionsSection.center.button.income')}
            </StatisticButton>
          }
        />

        <StatisticBlock
          heading={t('widgets.subscriptionsSection.center.total.subscribers')}
          alignment="right"
          statistic={null}
          postfix={
            <StatisticButton
              biData={BiData<DefaultBiEvent>({
                evid: 2,
                click_info: 'tier_manage_customers',
                sectionname: BiContextProvidedValue,
                widgetname: BiContextProvidedValue,
                typeid: BiContextProvidedValue,
                itemid: BiContextProvidedValue,
              })}
              onClick={() => window.location.assign(URLS.CUSTOMER_DASHBOARD)}
            >
              {t('widgets.subscriptionsSection.center.button.subscribers')}
            </StatisticButton>
          }
        />
      </div>
      <EmptyGraphic />
    </div>
  );
};
EmptyState.displayName = 'EmptyState';

export default EmptyState;
