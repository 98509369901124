import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { BiData, BuyCoreButtonBiEvent } from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import MediaManager from '@wix/da-shared-react/pkg/MediaPopup/MediaManager';
import { MediaSourceType } from '@wix/da-shared-react/pkg/MediaPopup/types';
import { useRestrictToLoggedIn } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictToLoggedIn';
import DisclosureBanner from '@wix/da-ds/pkg/DisclosureBanner';
import GallectionSimpleView from './GallectionSimpleView';
import GallectionSearchView from './GallectionSearchView';
import GallectionEmptyState from './GallectionsEmptyState';
import { GallectionType } from '../../../../types/gallection';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { t } from 'i18next';
import s from './GallectionsSection.scss';

export interface Props {
  isSectionLoading: boolean;
  hasAnyDeviations: boolean;
  searchQuery: string;
  gallectionType: GallectionType;
  isOwner?: boolean;
  isLoggedIn?: boolean;
  isVerified?: boolean;
}

const GallectionsSection: React.FC<Props> = ({
  isSectionLoading,
  hasAnyDeviations,
  searchQuery,
  gallectionType,
  isOwner,
  isLoggedIn,
  isVerified,
}) => {
  const isPrivateCollections =
    gallectionType === GallectionType.PRIVATE_COLLECTION;
  const { sendUserToJoinPage } = useRestrictToLoggedIn();
  const isMobile = useContext(MobileContext);
  const hasCoreMembership = useSelector(getCurrentUserHasCore);
  const [isMMShown, setIsMMShown] = useState(false);
  const closeMM = () => setIsMMShown(false);
  // we don't do route parsing for gallery using react-router anymore
  // so make the back button simply reload the URL it returns to
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const reloadWindow = () => {
      window.location.reload();
    };
    window.addEventListener('popstate', reloadWindow);
    return () => {
      window.removeEventListener('popstate', reloadWindow);
    };
  }, []);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (window.location.search.includes('new_gallery')) {
      if (!isLoggedIn) {
        sendUserToJoinPage(ReferralType.CREATE_GALLERY);
      }
      if (isVerified && isOwner) {
        setIsMMShown(true);
      }
    }
  }, [isLoggedIn, isVerified, isOwner, sendUserToJoinPage, setIsMMShown]);

  const renderMMConditionally = () =>
    isMMShown && (
      <MediaManager
        initialMediaSource={MediaSourceType.GALLERY}
        withMediaSourceSelector={false}
        withNewGallectionPopupOpen
        onDone={closeMM}
        onClose={closeMM}
      />
    );

  if (isSectionLoading) {
    return <LoadingIndicator />;
  }

  if (!hasAnyDeviations) {
    return (
      <>
        <GallectionEmptyState />
        {renderMMConditionally()}
      </>
    );
  }

  const view = searchQuery ? (
    <GallectionSearchView />
  ) : (
    <BiLoggerContextProvider value={{ sectionname: gallectionType }}>
      <GallectionSimpleView />
      {renderMMConditionally()}
    </BiLoggerContextProvider>
  );

  return (
    <OverlayContextProvider
      value={{ showAuthorWatchButton: false, showThumbStatusIndicators: false }}
    >
      {isPrivateCollections && !hasCoreMembership && (
        <DisclosureBanner
          variant="horizontal"
          className={s['non-core-banner']}
          message={t(
            `pages.gallections.privateCollection.nonCore.message${
              isMobile ? '.mobile' : ''
            }`
          )}
          ctaLabel={t('pages.gallections.privateCollection.nonCore.CTA')}
          ctaBiData={BiData<BuyCoreButtonBiEvent>({
            evid: 375,
            referral_info: ReferralType.PRIVATE_COLLECTIONS_SECTION,
          })}
        />
      )}
      {view}
    </OverlayContextProvider>
  );
};
GallectionsSection.displayName = 'GallectionsSection';

export default GallectionsSection;
