import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import StatsBoxWide from './StatsBoxWide';

export const getStatsWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: StatsBoxWide,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-11-statistics.svg',
    title: t('widgets.statistics.addButtonHeader'),
    description: t('widgets.statistics.description'),
  },
});
