import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '@wix/da-ds/pkg/Flex';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';
import { useNormalizedStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import LegacyTextRenderer from '@wix/da-shared-react/pkg/TextRenderer/LegacyTextRenderer';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { GROUP_ACTIONLOG_IDS_STREAM } from '../../../streams';
import { GroupActionLogResultsSchema } from '../../../schemas/modules';
import EmptyState from '../../EmptyState';

import s from './ActionLog.scss';

export interface Props {
  moduleId: number;
}

export const ActionLog: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { items, isFetching, hasMore, fetchNext } = useNormalizedStream(
    GROUP_ACTIONLOG_IDS_STREAM,
    GroupActionLogResultsSchema
  );

  if (!isFetching && items.length < 1) {
    return (
      <>
        <EmptyState
          title={t(`widgets.group_actionlog.empty.title.owner`)}
          subtitle={t(`widgets.group_actionlog.empty.subtitle.owner`)}
          buttonLabel={t(`widgets.group_actionlog.empty.CTALabel.owner`)}
        />
      </>
    );
  }

  return (
    <>
      <table className={s['action-log']} cellSpacing={24}>
        <thead>
          <tr>
            <th>{t('widgets.group_actionlog.table.member')}</th>
            <th>{t('widgets.group_actionlog.table.set')}</th>
            <th>{t('widgets.group_actionlog.table.loggedAction')}</th>
            <th>{t('widgets.group_actionlog.table.on')}</th>
            <th>{t('widgets.group_actionlog.table.date')}</th>
          </tr>
        </thead>
        <tbody>
          {items.map(result => (
            <tr key={result.eventId}>
              <td>
                <UserLink className={s['username']} user={result.who} />
              </td>
              <td>{result.rolelabel}</td>
              <td>
                <LegacyTextRenderer
                  className={s['action']}
                  html={result.action}
                  theme="default"
                />
              </td>
              <td>{result.on}</td>
              <td>
                <Timestamp timestamp={result.ts} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isFetching && <LoadingIndicator />}

      {hasMore && (
        <Flex
          direction="row"
          justifyContent="center"
          className={s['load-more-btn']}
        >
          <GhostButton
            size={'medium'}
            variant={'main'}
            disabled={isFetching}
            onClick={fetchNext}
          >
            {t!('common.loadMore')}
          </GhostButton>
        </Flex>
      )}
    </>
  );
};

export default ActionLog;
