import getMessageLogWidgetConfig from '../../GroupWidgets/MessageLog/widgetConfig';
import getActionLogWidgetConfig from '../../GroupWidgets/ActionLog/widgetConfig';
import getGroupNotesWidgetConfig from '../../GroupWidgets/GroupNotes/widgetConfig';

export const getBackroomWidgetConfigs = ({ t, dispatch }) =>
  ({
    groupMessagelog: getMessageLogWidgetConfig({ t, dispatch }),
    groupActionlog: getActionLogWidgetConfig({ t, dispatch }),
    groupNotes: getGroupNotesWidgetConfig({ t, dispatch }),
  } as any);

export default getBackroomWidgetConfigs;
