import React from 'react';
import InputErrorMessage from '@wix/da-ds/pkg/formControls/lego/InputErrorMessage';
import InputHint from '@wix/da-ds/pkg/formControls/lego/InputHint';
import InputLabelSubText from '@wix/da-ds/pkg/formControls/lego/InputLabelSubText';
import InputLabelText from '@wix/da-ds/pkg/formControls/lego/InputLabelText';
import InputSuccessMessage from '@wix/da-ds/pkg/formControls/lego/InputSuccessMessage';
import { default as DSFormElement } from '@wix/da-ds/pkg/formControls/layouts/FormElement';

export interface Props {
  /** Required for a11y reasons. We need to be able to link autocomplete menus and labels to the input field. */
  id: string;

  /**
   * Displays above the input field.
   *
   * Required for a11y reasons. If design won't work a label into the spec, consider passing through `isLabelHidden`.
   */
  label: string;

  /** Displays below the `label` in smaller font */
  subLabel?: string;

  /**
   * Displays below the input field.
   *
   * Depending on the value of `validationResult`, it'll be wrapped in...
   *
   * `error`: `<InputErrorMessage />`
   *
   * `success`: `<InputSuccessMessage />`
   *
   * `undefined`: `<InputHint />`
   *
   */
  details?: React.ReactNode;
  validationResult?: 'success' | 'error';

  isRequired?: boolean;
  isLabelHidden?: boolean;
  noBottomSpacing?: boolean;

  children: React.ReactNode;
}

const FormElement: React.FC<Props> = ({
  id,
  label,
  subLabel,
  isRequired,
  isLabelHidden,
  details,
  validationResult,
  noBottomSpacing,
  children,
}) => {
  let wrappedDetails: React.ReactNode = null;
  const errorMessageId = `${id}-error`;
  const hintId = `${id}-hint`;

  switch (validationResult) {
    case 'success':
      wrappedDetails = <InputSuccessMessage>{details}</InputSuccessMessage>;
      break;
    case 'error':
      wrappedDetails = (
        <InputErrorMessage id={errorMessageId}>{details}</InputErrorMessage>
      );
      break;
    default:
      wrappedDetails = details ? (
        <InputHint id={hintId}>{details}</InputHint>
      ) : undefined;
  }

  return (
    <DSFormElement
      inputId={id}
      isLabelHidden={isLabelHidden}
      noBottomSpacing={noBottomSpacing}
      label={<InputLabelText isRequired={isRequired}>{label}</InputLabelText>}
      subLabel={subLabel && <InputLabelSubText>{subLabel}</InputLabelSubText>}
      details={wrappedDetails}
    >
      {children}
    </DSFormElement>
  );
};
FormElement.displayName = 'FormElement';

export default FormElement;
