import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './PremiumFoldersEmptyState';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { AppState } from '../../../../../../types/store';
import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../../../selectors/users';
import { PapiUser } from '@wix/da-papi-types';
import { createOrOpenChatWithUser } from '@wix/da-shared-react/pkg/Chat/redux/actions';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';

export type StateProps = Pick<Props, 'isOwner' | 'user' | 'hasCoreMembership'>;
export type DispatchProps = Pick<Props, 'onOpenChat' | 'onCreateGallery'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  user: getProfileOwnerUser(state),
  hasCoreMembership: getCurrentUserHasCore(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  onOpenChat: (user: PapiUser) =>
    createOrOpenChatWithUser(
      `shop-premium-folders-empty-state-${user.useridUuid}`,
      user
    ),
  onCreateGallery: () =>
    pushModal(ModalType.CREATE_PREMIUM_FOLDER, {
      wrapInModal: false,
    }),
};

export default connect(mapStateToProps, mapDispatchToProps);
