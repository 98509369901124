import React, { lazy, Suspense, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { formatDollars } from '@wix/da-shared-react/pkg/utils/formatting';
import { URLS } from '@wix/da-url';
import { useThrottle } from '@wix/da-hooks/pkg/useThrottle';
import { useResizeObserver } from '@wix/da-hooks/pkg/useResizeObserver';
import ChartPanelSkeleton from '../../layout/ChartPanelSkeleton';
import { formatMonth } from '../../../../utils';
import { theme, margin } from '../../constants';
import {
  SubscriptionsChartPointType,
  SubscriptionsStatType,
} from '../../../../../../../../../../types/subscriptionsSection';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';

const MAX_VISIBLE_DATAPOINTS = 12;
const MAX_VISIBLE_DATAPOINTS_MOBILE = 6;
const RESIZE_THROTTLE_DELAY = 300;
const RESIZE_THROTTLE_OPTIONS = { trailing: true };
const WIDTH_FOR_ROTATED_TICKS = 360;

const ResponsiveBar = lazy(() =>
  import(/* webpackChunkName: "stats-light" */ '@nivo/bar').then(m => ({
    default: m.ResponsiveBar,
  }))
);

const BasicTooltip = lazy(() =>
  import(/* webpackChunkName: "stats-light" */ '@nivo/tooltip').then(m => ({
    default: m.BasicTooltip,
  }))
);

interface Props {
  className?: string;
  chart: SubscriptionsChartPointType[];
  stats?: SubscriptionsStatType;
}

const USD_VALUES_FORMATTING: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
};

const IncomeChartPanel: React.FC<Props> = ({ className, chart, stats }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isSSR = typeof window === 'undefined';
  const visibleDataPoints = chart.slice(
    isMobile ? -MAX_VISIBLE_DATAPOINTS_MOBILE : -MAX_VISIBLE_DATAPOINTS
  );

  const rootRef = useRef<HTMLDivElement>(null);
  const [rootWidth, setRootWidth] = useState(0);
  const handleResize = useThrottle(
    () => {
      if (isMobile) {
        return;
      }
      const newWidth = rootRef.current!.clientWidth;
      if (newWidth !== rootWidth) {
        setRootWidth(newWidth);
      }
    },
    [rootWidth, isMobile],
    RESIZE_THROTTLE_DELAY,
    RESIZE_THROTTLE_OPTIONS
  );
  useResizeObserver(rootRef, handleResize);
  const newData = visibleDataPoints.map(({ startDate, ...rest }) => ({
    startDate,
    month: formatMonth(startDate),
    ...rest,
  }));

  return (
    <ChartPanelSkeleton
      className={className}
      rootRef={rootRef}
      totalTitle={t('widgets.subscriptionsSection.center.total.income')}
      total={stats ? formatDollars(stats.income, USD_VALUES_FORMATTING) : ''}
      manageTitle={t('widgets.subscriptionsSection.center.button.income')}
      manageHref={URLS.EARNINGS}
    >
      {!isSSR && (
        <ErrorBoundary debugComponent="IncomeChartPanel">
          <Suspense fallback={<LoadingIndicator view="llama" />}>
            <ResponsiveBar
              data={newData}
              indexBy={`month`}
              keys={[`income`]}
              axisBottom={{
                tickRotation:
                  !isMobile && rootWidth <= WIDTH_FOR_ROTATED_TICKS ? 45 : 0,
              }}
              axisLeft={{ tickValues: 5 }}
              gridYValues={5}
              padding={0.2}
              margin={margin}
              defs={[
                {
                  id: 'barGradient',
                  type: 'linearGradient',
                  colors: [
                    { offset: 0, color: '#7579ff' },
                    { offset: 100, color: 'rgba(200, 109, 215, 0.41)' },
                  ],
                },
              ]}
              fill={[{ match: '*', id: 'barGradient' }]}
              valueFormat={(val: number) =>
                val > 0 ? formatDollars(val, USD_VALUES_FORMATTING) : ''
              }
              tooltip={({ indexValue, value }) => (
                <BasicTooltip id={`${indexValue} - ${value}`} />
              )}
              theme={theme}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </ChartPanelSkeleton>
  );
};
IncomeChartPanel.displayName = 'IncomeChartPanel';

export default IncomeChartPanel;
