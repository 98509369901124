import React from 'react';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import classnames from 'classnames';
import ConditionalWrapper from '@wix/da-ds/pkg/ConditionalWrapper';
import WidgetPaywall from '@wix/da-shared-react/pkg/widgets/lego/WidgetPaywall';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { useShowCoreUpsell } from '@wix/da-shared-react/pkg/widgets/hooks/useShowCoreUpsell';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { PapiModuleMegabox } from '@wix/da-papi-types';
import Custom from '../Custom';

import s from './MegaboxBox.scss';

export type Props = {
  moduleId: number;
  widgetBoxProps: Partial<WidgetBoxProps>;
};

const MegaboxBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { moduleData } = useModuleData<PapiModuleMegabox>(moduleId);
  const { title, width } = moduleData;

  const showCoreUpsell = useShowCoreUpsell(moduleId);

  return (
    <WidgetBox
      header={title}
      moduleId={moduleId}
      {...widgetBoxProps}
      className={classnames(widgetBoxProps.className, s[width])}
    >
      <BiLoggerContextProvider
        value={{ widgetname: 'megabox', sectionname: 'megabox' }}
      >
        <ConditionalWrapper
          condition={showCoreUpsell}
          wrap={child => <WidgetPaywall>{child}</WidgetPaywall>}
        >
          <Custom moduleId={moduleId} noPadding />
        </ConditionalWrapper>
      </BiLoggerContextProvider>
    </WidgetBox>
  );
};
MegaboxBox.displayName = 'MegaboxBox';

export default MegaboxBox;
