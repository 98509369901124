import React, { useContext } from 'react';
import { PapiDeviation, PapiModuleFeed } from '@wix/da-papi-types';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import FeedThumb from '../../UserWidgets/_partials/FeedThumb';

import s from './Feed.scss';

export interface Props {
  moduleId: number;
}

const FeedWidget: React.FC<Props> = ({ moduleId }) => {
  const { moduleData } = useModuleData<PapiModuleFeed>(moduleId);
  const items = moduleData.results || [];
  const isMobile = useContext(MobileContext);
  const RootComponent: any = isMobile ? SwipeSlider : 'div';

  return (
    <RootComponent className={s['root']}>
      {items.map((item: PapiDeviation, i: number) => (
        <BiLoggerContextProvider
          key={`${item.typeId}-${item.deviationId}`}
          value={{ rank: i, sectionname: 'postsfeed_widget' }}
        >
          <FeedThumb deviation={item} className={s['item']} withComments />
        </BiLoggerContextProvider>
      ))}
    </RootComponent>
  );
};

export default FeedWidget;
