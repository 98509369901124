import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import PremiumFolderSlider from './PremiumFolderSlider';
import premiumFolderInit from './redux/premiumFolderInit';

export const getPremiumFolderSliderWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent: PremiumFolderSlider,
  initActionsCreator: premiumFolderInit,
});
