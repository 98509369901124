import { PapiExtendedGroup, PapiUser } from '@wix/da-papi-types';
import { createSelector } from 'reselect';
import {
  getCurrentUser,
  getCurrentUserHasCore,
  getPagingMode,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { getUserById } from '@wix/da-shared-react/pkg/redux/users/selectors';
import { GruserType } from '@wix/da-gruser-shared/pkg/types/';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi/';
import { AppState } from '../../types/store';

import { PagePriv } from '../../types/pagePrivileges';
import { getCanPerformPageAction } from './privs';
import { getPageType } from './pageType';

export const getProfileOwner = createSelector(
  (state: AppState) => state,
  (
    state: AppState
  ): Exclude<AppState['profileOwner'], 'user'> & { user: PapiUser } => ({
    ...state.profileOwner,
    user: {
      ...getUserById(state, state.profileOwner.user.id),
      extendedGroup: getProfileOwnerExtendedGroup(state),
    },
  })
);

export const getIsGroupProfile = (state: AppState) =>
  state.profileOwner.isGroup;

export const getIsWatchingProfileOwner = (state: AppState) =>
  state.profileOwner.isWatching;

export const getProfileOwnerIsWatcher = (state: AppState) =>
  state.profileOwner.isWatcher;

export const getProfileOwnerMonetizationPromo = (state: AppState) =>
  state.profileOwner.showMonetizationPromo;

export const getProfileOwnerStats = (state: AppState) =>
  state.profileOwner.stats;

export const getProfileOwnerStat = (state: AppState, entity) =>
  getProfileOwnerStats(state)[entity];

export const getProfileOwnerTagline = (state: AppState) =>
  state.profileOwner.tagline;

export const getProfileOwnerUserId = (state: AppState) =>
  state.profileOwner.user.id;
export const getProfileOwnerUser = (state: AppState): PapiUser =>
  getUserById(state, state.profileOwner.user.id);

export const getProfileOwnerExtendedGroup = (
  state: AppState
): PapiExtendedGroup | undefined => state.profileOwner.extendedGroup;

export const getIsCurrentGroupAdmin = (state: AppState): boolean =>
  !!getProfileOwnerExtendedGroup(state)?.isAdmin;

export const isLoggedIn = state => getCurrentUser(state) !== undefined;

export const isCurrentUserProfileOwner = (state: AppState): boolean => {
  if (getPageType(state) !== 'profile') {
    return false;
  }
  const currentUser = getCurrentUser(state);
  const profileOwner = getProfileOwnerUser(state);
  // We expected to use PagePriv.EDIT_PAGE here, but edit page privilege is
  // unused on green site, so we will use the ADD_MODULE priv for now to detect
  // wether the user has ownership over the page
  const canEditPage = getCanPerformPageAction(state, PagePriv.ADD_MODULE);
  return (
    !!currentUser && (profileOwner.userId === currentUser.userId || canEditPage)
  );
};

export const getProfileGruser = createSelector(
  [getProfileOwnerUserId],
  (gruserId): Gruser => ({
    gruserId,
    gruserTypeid: GruserType.Profile,
  })
);

export const getShowCoreUpsell = createSelector(
  [isCurrentUserProfileOwner, getCurrentUserHasCore, getIsGroupProfile],
  (isOwner, hasCore, isGroup) => isOwner && !hasCore && !isGroup
);

export const getIsPagedMode = (state: AppState) =>
  getPagingMode(state) === 'page';
