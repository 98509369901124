import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import initActionsCreator from './redux/initModule';
import GroupMembersBox from './GroupMembersBox';
import GroupMembersBoxWide from './GroupMembersBoxWide';

export const getGroupMembersWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: GroupMembersBox,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-17-groups.svg',
    title: t('widgets.group_members.addButtonHeader'),
    description: t('widgets.group_members.description'),
  },
  initActionsCreator,
});

export const getWideGroupMembersWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  ...getGroupMembersWidgetConfig({ t, dispatch }),
  viewComponent: GroupMembersBoxWide,
});
