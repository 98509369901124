import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { getIsLoggedIn } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { restrictLoggedOut } from '@wix/da-shared-react/pkg/redux/loggedout/actionCreators';
import {
  getProfileOwner,
  getProfileOwnerUser,
} from '../../../../../../../selectors/users';
import { Props } from './GiveButton';
import { AppState } from '../../../../../../../../types/store';
import {
  fetchProfileOwnerGiveState,
  fetchProfileOwnerGiveStateSuccess,
} from '../../../../../../../actions/profileOwner';
import { hasPublicBirthday } from '../../../../../../../selectors/aboutMe';
import { pushPointsModal } from '../../../../../../../actions/gifts';

export type StateProps = Pick<
  Props,
  'profileOwnerUser' | 'giveState' | 'isLoggedIn' | 'hasPublicBirthday'
>;
export type DispatchProps = Pick<
  Props,
  | 'restrictLoggedOut'
  | 'pushBadgeModal'
  | 'pushPointsModal'
  | 'onLlamaBadgeSent'
  | 'initGiveState'
>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, {}> = (
  state: AppState
) => ({
  giveState: getProfileOwner(state).giveState,
  profileOwnerUser: getProfileOwnerUser(state),
  isLoggedIn: getIsLoggedIn(state),
  hasPublicBirthday: hasPublicBirthday(state),
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  restrictLoggedOut: payload => dispatch(restrictLoggedOut(payload)),
  pushBadgeModal: (username: string, onBadgeSent?: () => void) =>
    dispatch(modalActions.pushBadgeModal(username, onBadgeSent)),
  pushPointsModal: (username, title) =>
    dispatch(pushPointsModal(username, title)),
  onLlamaBadgeSent: () =>
    dispatch(fetchProfileOwnerGiveStateSuccess({ canBeGivenLlama: false })),
  initGiveState: () => dispatch(fetchProfileOwnerGiveState()),
});

export default connect(mapStateToProps, mapDispatchToProps);
