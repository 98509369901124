import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import UnwatchIcon from '@wix/da-ds/pkg/Icons/Unwatch';
import StandardDropdown from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown';
import { PostsType } from '../../../../../../types/posts';

import s from './PostsTypeSwitcher.scss';
import { BiData, ProfilePostFeedDropdownBiEvent } from '@wix/da-bi/pkg/events';

interface Props {
  isGroup: boolean;
  isOwner: boolean;
  value: PostsType;
  onChange: (value: PostsType) => void;
  className?: string;
  counts: Partial<{
    [key in PostsType]: number | undefined;
  }>;
}

const BI_MENU_ITEM_VALUES = {
  [PostsType.ALL]: 'all_posts',
  [PostsType.JOURNALS]: 'journals',
  [PostsType.POLLS]: 'polls',
  [PostsType.STATUSES]: 'status',
  [PostsType.DRAFTS]: 'drafts',
};

const PostsTypeSwitcher: React.FC<Props> = ({
  isGroup,
  isOwner,
  value,
  onChange,
  className,
  counts = {},
}) => {
  const { t } = useTranslation();
  const makePostTypeLabel = useCallback(
    (postType: PostsType) => (
      <>
        {t(`pages.posts.types.${postType}`)}
        {counts[postType] ? (
          <span className={s['count']}>{counts[postType]}</span>
        ) : null}
      </>
    ),
    [counts, t]
  );

  const items = useMemo(() => {
    const postTypes = [
      PostsType.ALL,
      PostsType.JOURNALS,
      PostsType.POLLS,
      PostsType.STATUSES,
    ];
    return postTypes
      .map(postType => ({
        value: postType,
        /* i18n grep
            'pages.posts.types.all'
            'pages.posts.types.journals'
            'pages.posts.types.polls'
            'pages.posts.types.statuses'
          */
        label: makePostTypeLabel(postType),
        icon: undefined,
        biData: BiData<ProfilePostFeedDropdownBiEvent>({
          evid: 266,
          menu_item: BI_MENU_ITEM_VALUES[postType],
        }),
      }))
      .concat(
        isOwner
          ? [
              {
                value: PostsType.DRAFTS,
                /* i18n grep
                    'pages.posts.types.drafts'
                  */
                label: makePostTypeLabel(PostsType.DRAFTS),
                icon: <UnwatchIcon size={IconSize.SMALL} />,
                biData: BiData<ProfilePostFeedDropdownBiEvent>({
                  evid: 266,
                  menu_item: BI_MENU_ITEM_VALUES[PostsType.DRAFTS],
                }),
              },
            ]
          : ([] as any)
      );
  }, [isOwner, makePostTypeLabel]);

  if (isGroup) {
    return null;
  }

  return (
    <StandardDropdown
      role="menu"
      className={className}
      toggleClassName={s['toggle']}
      arrowClassName={s['arrow']}
      menuClassName={s['menu']}
      popperClassName={s['popper']}
      placement="bottom-start"
      selectedValue={value}
      onSelect={onChange}
      items={items}
    />
  );
};

PostsTypeSwitcher.displayName = 'PostsTypeSwitcher';

export default PostsTypeSwitcher;
