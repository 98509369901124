import { createAction } from 'redux-actions';
import { PapiDeviation } from '@wix/da-papi-types';

export type ItemPayload = {
  item: PapiDeviation | undefined;
};
export type StreamPayload = {
  streamId: string | undefined;
};

export const pinPostOnFeed = createAction(
  'POSTS_PIN_POST_ON_FEED',
  (payload: ItemPayload) => payload
);

export const featurePost = createAction(
  'POSTS_FEATURE_ITEM',
  (payload: ItemPayload) => payload
);

export const unfeaturePost = createAction(
  'POSTS_UNFEATURE_ITEM',
  (payload: ItemPayload) => payload
);

export const pinPost = createAction(
  'POSTS_PIN_ITEM',
  (payload: ItemPayload & StreamPayload) => payload
);

export const unpinPost = createAction(
  'POSTS_UNPIN_ITEM',
  (payload: StreamPayload) => payload
);

export const removePost = createAction(
  'POSTS_REMOVE_ITEM',
  (payload: ItemPayload & StreamPayload) => payload
);
