import { createSelector } from 'reselect';
import { ModuleType } from '../../types/modules';
import {
  ProfileSkin,
  officialSkin,
  customSkin,
  ProfileSkinsSlice,
} from '../../types/profileSkins';
import { getModuleDataByModuleName } from './modules';
import { getModuleDataByModuleName as getModuleDataByModuleNameNewGrusers } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { getProfileGruser } from './users';
import { profileSkinFromProps } from '../../utils/profileSkins';
import { getIsDuperbrowseActive } from '@wix/da-shared-react/pkg/Duperbrowse/redux/selectors';
import {
  getTheme,
  getThemeSkin,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { ThemeSkin } from '@wix/da-react-context/pkg/constants';

const getState = (state): ProfileSkinsSlice => state.profileSkins;

export const getActiveSkin = createSelector([getState], state => {
  return state.activeSkin;
});

export const getCustomSkin = createSelector([getState], state => {
  return state.customSkin || customSkin;
});

export const isSkinSelectorShown = state => getState(state).skinSelectorShown;

export const isSkinSaving = state => getState(state).isSkinSaving;

export const getProfileSkin = createSelector(
  [state => state],
  (state): ProfileSkin | undefined => {
    // active skin comes first when the selector is open
    const activeSkin = getActiveSkin(state);
    if (activeSkin && isSkinSelectorShown(state)) {
      return activeSkin;
    }

    const newGrusersModuleData = getModuleDataByModuleNameNewGrusers(
      state as any,
      getProfileGruser(state as any),
      ModuleType.COVER_DEVIATION
    );

    // get the skin from the module config
    const moduleData = newGrusersModuleData?.profileSkin
      ? newGrusersModuleData
      : getModuleDataByModuleName(state, ModuleType.COVER_DEVIATION);
    if (!moduleData) {
      return undefined;
    }
    const skinId = moduleData?.profileSkin?.templateName;

    if (skinId) {
      if (skinId === 'official') {
        return officialSkin;
      }
      if (skinId === 'custom') {
        return getCustomSkin(state);
      }

      const profileSkin = moduleData.profileSkin;

      if (profileSkin && profileSkin.imageUrl) {
        return profileSkinFromProps({
          skinId,
          imageUrl: profileSkin.imageUrl,
          imageDisplayMode: profileSkin.imageDisplayMode,
          theme: profileSkin.theme,
        });
      }
    }
    return undefined;
  }
);

/**
 * Used to setup profile skins where themes are not in the session
 * see web.tsx and client.tsx
 */
export const getProfileTheme = createSelector(
  state => state,
  state => {
    const isDuper = getIsDuperbrowseActive(state as any);
    const profileSkin = getProfileSkin(state);
    const profileSkinActive =
      profileSkin && profileSkin.skinId !== 'official' && !isDuper;
    const theme = getTheme(state);

    return profileSkinActive ? profileSkin?.skin?.baseTheme : theme;
  }
);

/**
 * Used to setup profile skins where themes are not in the session
 * see web.tsx and client.tsx
 */
export const getProfileThemeSkin = createSelector(
  state => state,
  state => {
    const isDuper = getIsDuperbrowseActive(state as any);
    const profileSkin = getProfileSkin(state);
    const profileSkinActive =
      profileSkin && profileSkin.skinId !== 'official' && !isDuper;
    const themeSkin = getThemeSkin(state);
    /* We force the themeSkin off when the skin is active, if they add green based skins pass the skin here instead of NONE */
    return profileSkinActive ? ThemeSkin.NONE : themeSkin;
  }
);
