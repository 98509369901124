import React from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import IconTrashcan from '@wix/da-ds/pkg/Icons/24x24/Trashcan';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import {
  BiData,
  BiEvent,
  SeeAllClickBiEvent,
  WidgetRemoveConfirmBiEvent,
} from '@wix/da-bi/pkg/events';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { withMobileContext } from '@wix/da-react-context/pkg/MobileContext';

import WidgetHeader from '../WidgetHeader/WidgetHeader';
import WidgetEditControls from '../WidgetEditControls';
import WidgetHeaderMobile from '../WidgetHeaderMobile';
import withConfirmationModal, {
  WithConfirmationModalProps,
} from '../../_hoc/withConfirmationModal';
import { GallectionType } from '../../../../../types/gallection';
import { ModuleType } from '../../../../../types/modules';
import { ZoneType } from '../../../../../types/layout';
import { UpdateModulePayload } from '../../../../actions/modules';
import WidgetBoxWrapper from '../WidgetBoxWrapper';

import s from './WidgetBox.scss';

export interface Props {
  moduleId: number;
  moduleType: ModuleType;
  moduleVersion: number;
  position?: number;
  zone: ZoneType;
  section: string;
  children: React.ReactNode;
  className?: string;
  direction?: 'row' | 'column';
  widgetBoxClassName?: string;
  header: string | React.ReactNode;
  headerClassName?: string;
  headerExtras?: React.ReactNode | ((props: any) => React.ReactNode);
  count?: number | string;
  allowEditOnMobile?: boolean;
  autoShowWidgetConfig?: boolean;
  isAuthorizedToEditWidget?: boolean;
  isAuthorizedToRemoveWidget?: boolean;
  isMobile?: boolean;
  isNewlyAddedModule?: boolean;
  isOwner: boolean;
  showCoreUpsell?: boolean;
  isAuthorizedToMoveModule: boolean;
  isEmpty?: boolean;
  emptyView?: React.ReactNode;
  hasMore?: boolean;
  profileOwnerUser: PapiUser;
  seeAllUrl?: string;
  seeAllText?: string;
  widgetMenuRenderer?: (closeMenu) => React.ReactNode;
  widgetMoveUp?: () => void;
  widgetMoveDown?: () => void;
  widgetRemove?: () => void;
  widgetAddColumn?: () => void;
  editControlsTooltipTitle?: string;
  editControlsTooltipContent?: React.ReactNode;
  updateModule?: (payload: UpdateModulePayload) => void;
  sourceType?: GallectionType;
  dataHook?: string;
  biData?: Partial<BiEvent>;
}

export interface State {
  isWidgetConfigShown: boolean;
  disableAutoShowWidgetConfigFor: number[];
}

const enhance = compose(
  withMobileContext,
  withConfirmationModal,
  withTranslation()
);
export class WidgetBox extends React.Component<
  Props & WithTranslation & WithConfirmationModalProps,
  State
> {
  static displayName = 'WidgetBox';
  state = {
    isWidgetConfigShown: false,
    disableAutoShowWidgetConfigFor: [] as number[],
  };

  render() {
    const {
      moduleId,
      isMobile,
      className,
      count,
      children,
      isEmpty,
      emptyView,
      moduleType,
      zone,
      dataHook,
      biData,
      profileOwnerUser,
    } = this.props;

    if (isEmpty && !emptyView) {
      return null;
    }

    const headerContents = this.renderHeaderContents();
    return (
      <BiLoggerContextProvider
        value={{
          widgetname: moduleType,
          sectionname: moduleType,
          widgetzone: zone,
          ...biData,
        }}
      >
        <WidgetBoxWrapper
          data-role="widget"
          className={classnames(s['root'], className)}
          data-hook={dataHook}
          data-moduleid={moduleId}
          data-userid={profileOwnerUser.userId}
          id={`module-${moduleId}`}
        >
          <ErrorBoundary debugComponent="WidgetBox">
            {isMobile ? (
              <WidgetHeaderMobile
                rootClassName={this.props.headerClassName}
                header={headerContents}
                count={count}
                isSticky={false}
                extras={this.renderHeaderExtraElements()}
              />
            ) : (
              <WidgetHeader
                className={this.props.headerClassName}
                header={headerContents}
                count={count}
                extras={this.renderHeaderExtraElements()}
              />
            )}
            <div className={s['widget-content']}>
              {isEmpty ? emptyView : children}
            </div>
          </ErrorBoundary>
        </WidgetBoxWrapper>
      </BiLoggerContextProvider>
    );
  }

  renderHeaderContents() {
    const { profileOwnerUser, header, seeAllUrl } = this.props;

    if (!seeAllUrl) {
      return header;
    }

    return (
      <BiLink
        biData={BiData<SeeAllClickBiEvent>({
          evid: 106,
          creatorid: profileOwnerUser.useridUuid,
          typeid: undefined,
          itemid: undefined,
        })}
        href={seeAllUrl}
      >
        {header}
      </BiLink>
    );
  }

  renderSeeAllLink(contents, role?, className?) {
    const { seeAllUrl, profileOwnerUser } = this.props;
    if (seeAllUrl && contents) {
      return (
        <TextButton
          size="medium"
          biData={BiData<SeeAllClickBiEvent>({
            evid: 106,
            typeid: undefined,
            itemid: undefined,
            creatorid: profileOwnerUser?.useridUuid,
          })}
          href={seeAllUrl}
          {...(className && { className })}
          {...(role && { 'data-role': role })}
        >
          {contents}
        </TextButton>
      );
    }
  }

  renderHeaderExtraElements() {
    const { headerExtras } = this.props;
    return (
      <div className={s['header-extras']}>
        {typeof headerExtras === 'function'
          ? headerExtras(this.props)
          : headerExtras}
        {this.renderEditControls()}
        {this.renderWidgetConfig()}
        {this.renderRightSideSeeAllLink()}
      </div>
    );
  }

  renderRightSideSeeAllLink() {
    const { t, hasMore, seeAllText } = this.props;

    const shouldRenderSeeAllLink = hasMore === undefined || hasMore;
    const contents = seeAllText || t!('common.see_all');
    const role = 'see-all-link';
    const linkClass = s['see-all'];
    return shouldRenderSeeAllLink
      ? this.renderSeeAllLink(contents, role, linkClass)
      : null;
  }

  renderEditControls() {
    const {
      isMobile,
      isOwner,
      isAuthorizedToEditWidget,
      isAuthorizedToRemoveWidget,
      allowEditOnMobile,
      widgetMoveUp,
      widgetMoveDown,
      widgetRemove,
      widgetMenuRenderer,
      widgetAddColumn,
      editControlsTooltipTitle,
      editControlsTooltipContent,
      isAuthorizedToMoveModule,
      direction,
    } = this.props;

    const canUserMoveWidget = isOwner || isAuthorizedToMoveModule;
    const isAuthorized =
      canUserMoveWidget ||
      isAuthorizedToRemoveWidget ||
      isAuthorizedToEditWidget;
    const isDisabledOnMobile = isMobile && !allowEditOnMobile;

    if (!isAuthorized || isDisabledOnMobile) {
      return null;
    }

    return (
      <WidgetEditControls
        className={s['edit-widget-controls']}
        onMoveUp={widgetMoveUp}
        onMoveDown={widgetMoveDown}
        onRemove={
          isAuthorizedToRemoveWidget && widgetRemove
            ? this.handleWidgetRemove
            : undefined
        }
        onEdit={
          isAuthorizedToEditWidget && widgetMenuRenderer
            ? this.showWidgetConfig
            : undefined
        }
        onAddColumn={widgetAddColumn}
        tooltipTitle={editControlsTooltipTitle}
        tooltipContent={editControlsTooltipContent}
        direction={direction}
      />
    );
  }

  renderWidgetConfig() {
    const {
      moduleId,
      widgetMenuRenderer,
      autoShowWidgetConfig,
      isNewlyAddedModule,
    } = this.props;
    const { isWidgetConfigShown, disableAutoShowWidgetConfigFor } = this.state;

    if (!widgetMenuRenderer) {
      return null;
    }

    // This is why mixing redux and local state is an anti-pattern.
    // Due to race condition between updating isNewlyAddedModule in the redux
    // store and the isWidgetConfigShown in the local state, this was
    // auto-showing the config even after you close it.
    // To fix it, we introduce another layer of complexity: disableAutoShowWidgetConfigFor
    // Which we use to disable the autoShowWidgetConfig for certain moduleIds,
    // after you close the config for the first time, so that it does not trigger again
    if (
      !isWidgetConfigShown &&
      isNewlyAddedModule &&
      !disableAutoShowWidgetConfigFor.includes(moduleId) &&
      autoShowWidgetConfig
    ) {
      this.setState({ isWidgetConfigShown: true });
    }

    if (isWidgetConfigShown) {
      return widgetMenuRenderer && widgetMenuRenderer(this.hideWidgetConfig);
    }
  }

  showWidgetConfig = () => {
    this.setState({ isWidgetConfigShown: true });
  };

  hideWidgetConfig = () => {
    const { isNewlyAddedModule, updateModule, moduleId } = this.props;
    const { disableAutoShowWidgetConfigFor } = this.state;
    isNewlyAddedModule &&
      updateModule &&
      updateModule({
        id: this.props.moduleId,
        moduleMeta: {
          isNewlyAdded: false,
        },
      });
    this.setState({
      isWidgetConfigShown: false,
      disableAutoShowWidgetConfigFor: [
        ...disableAutoShowWidgetConfigFor,
        moduleId,
      ],
    });
  };

  doWidgetRemove = () => {
    const { moduleId, widgetRemove } = this.props;
    const { disableAutoShowWidgetConfigFor } = this.state;
    this.setState({
      disableAutoShowWidgetConfigFor: disableAutoShowWidgetConfigFor.filter(
        id => id !== moduleId
      ),
    });
    widgetRemove && widgetRemove();
  };
  handleWidgetRemove = () => {
    const { moduleType, zone, pushConfirmationModal, t } = this.props;

    switch (moduleType) {
      case ModuleType.CUSTOM:
      case ModuleType.MEGABOX:
      case ModuleType.DONATIONS:
        // These will destroy content if removed, show warning
        pushConfirmationModal({
          title: t('widgets.common.removeWidget.header'),
          text: t('widgets.common.removeWidget.body.danger'),
          confirmBtnLabel: t('widgets.common.removeWidget.confirmBtnLabel'),
          confirmBtnBiData: BiData<WidgetRemoveConfirmBiEvent>({
            evid: 270,
            widgetname: moduleType,
            widgetzone: zone,
          }),
          cancelBtnLabel: t('widgets.common.removeWidget.cancelBtnLabel'),
          icon: <IconTrashcan size={IconSize.MEGA} />,
          onConfirm: this.doWidgetRemove,
        });
        break;
      case ModuleType.ABOUT:
      default:
        pushConfirmationModal({
          title: t('widgets.common.removeWidget.header'),
          text: t('widgets.common.removeWidget.body'),
          confirmBtnLabel: t('widgets.common.removeWidget.confirmBtnLabel'),
          confirmBtnBiData: BiData<WidgetRemoveConfirmBiEvent>({
            evid: 270,
            widgetname: moduleType,
            widgetzone: zone,
          }),
          cancelBtnLabel: t('widgets.common.removeWidget.cancelBtnLabel'),
          icon: <IconTrashcan size={IconSize.MEGA} />,
          onConfirm: this.doWidgetRemove,
        });
        break;
    }
  };
}

export default enhance(WidgetBox) as React.ComponentType<Props>;
