import { all, call, put, select } from 'redux-saga/effects';
import {
  selectors as streamSelectors,
  withOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { FetchType } from '@wix/da-shared-react/pkg/Stream/base/types';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import { PapiTierContentRequestParams } from '../../../types/papi';
import { initializeThread } from '@wix/da-shared-react/pkg/CommentThread/actionCreators';
import { getCommentableTypeForDeviationType } from '@wix/da-shared-react/pkg/DeviationPage/redux/helpers';
import {
  FEED_COMMENTS_ITEMS_PER_FETCH,
  FEED_COMMENTS_MAX_DEPTH,
} from '../../streams/posts';

export function fakeResponse({ params }): any {
  if (process.env.NODE_ENV !== 'production') {
    const { deviationsByType } = require('@wix/da-sample-data'); // eslint-disable-line global-require
    const { Chance } = require('chance'); // eslint-disable-line global-require
    const chance = new Chance();
    const { section, offset, limit } = params;

    let deviations;
    if (section === 'deviations') {
      deviations = chance.pickset(deviationsByType.pixel, limit);
    } else {
      deviations = chance.pickset(
        deviationsByType.statuses
          .concat(deviationsByType.journal)
          .concat(deviationsByType.polls),
        limit
      );
    }

    return {
      hasMore: offset + limit < deviationsByType.image.length,
      nextOffset: offset + limit,
      deviations: deviations.slice(0, limit),
    };
  }
}

function* fetch(url, params) {
  const response = yield call(
    requestPuppy,
    {
      method: 'get',
      url,
      params,
    },
    fakeResponse
  );
  return response;
}

export default function* fetchMorePosts(action) {
  const { streamId } = action;

  const [offset, limit, streamParams] = yield all([
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state => streamSelectors.getItemsPerFetch(state, streamId)),
    select(state => streamSelectors.getStreamParamsSafe(state, streamId)),
  ]);

  const params: PapiTierContentRequestParams = {
    tier_deviationid: streamParams.tierDeviationId,
    section: streamParams.section,
    offset,
    limit,
  };

  const url = 'tier/content';
  const response = yield call(fetch, url, {
    ...params,
    expand: 'deviation.fulltext',
  });

  if (!response || isApiError(response)) {
    yield put(
      withOffset.actions.fetchFailure({
        streamId,
        fetchType: FetchType.Next,
        errorMsg: 'Failed to fetch',
      })
    );
    return;
  }

  const { hasMore, total, nextOffset, deviations } = response;
  const { result, entities } = normalize(deviations, [deviationSchema]);

  yield put(
    withOffset.actions.fetchSuccess({
      streamId,
      hasMore,
      nextOffset,
      items: result,
      total,
      entities,
    })
  );

  // Initialize comments
  const commentThreads = deviations.map(deviation =>
    put(
      initializeThread({
        thread: {
          commentableItemId: deviation.deviationId,
          commentableTypeId: getCommentableTypeForDeviationType(deviation),
          items: [],
          hasMore: !!deviation.stats?.comments,
          total: deviation.stats?.comments ?? 0,
          canPostComment: !!deviation.isCommentable,
        },
        maxDepth: FEED_COMMENTS_MAX_DEPTH,
        itemsPerFetch: FEED_COMMENTS_ITEMS_PER_FETCH,
      })
    )
  );
  yield all(commentThreads);
}
