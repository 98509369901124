import { Action } from 'redux-actions';
import { PapiCommentThread } from '@wix/da-papi-types';
import { initializeThread } from '@wix/da-shared-react/pkg/CommentThread/actionCreators';
import { ThreadOrder } from '@wix/da-shared-react/pkg/CommentThread/types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiCommentThread
): Action<any>[] => {
  return [
    initializeThread({
      thread: {
        commentableTypeId: moduleData.commentableTypeid,
        commentableItemId: moduleData.commentableItemid,
        items: moduleData.thread,
        order: 'newest' as ThreadOrder,
        total: moduleData.total,
        hasMore: moduleData.hasMore,
        canPostComment: moduleData.canPostComment,
        cursor: moduleData.cursor || undefined,
      },
    }),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ];
};
