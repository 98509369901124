import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  selectors as streamSelectors,
  withOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { AppState } from '../../../../../../types/store';
import { Props } from './FeaturedList';

type StateProps = Pick<Props, 'items' | 'hasMore' | 'isFetching' | 'isEmpty'>;
type DispatchProps = Pick<Props, 'fetchMore'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  { streamId }: OwnProps
) => {
  const items = getDeviationsByIds(
    state,
    streamSelectors.getItems(state, streamId)
  );
  const hasMore = streamSelectors.hasMore(state, streamId);
  const isFetching = streamSelectors.isFetching(state, streamId);
  const isInitialized = streamSelectors.isInitialized(state, streamId);
  const isEmpty = isInitialized && !isFetching && items.length === 0;
  return {
    items,
    hasMore,
    isFetching,
    isEmpty,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { streamId }
) => ({
  fetchMore: () => dispatch(withOffset.actions.fetch(streamId)),
});

export default connect(mapStateToProps, mapDispatchToProps);
