import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { PapiDeviation } from '@wix/da-papi-types';
import { BiData, SubmitButtonClickBiEvent } from '@wix/da-bi/pkg/events';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import PromoBadge from '@wix/da-ds/pkg/Badges/PromoBadge';
import EmptyState from '../../../../../EmptyState';

import s from './TierContentSubmit.scss';

export interface Props {
  tier?: PapiDeviation;
  isOwner: boolean;
}

const TierContentSubmit: React.FC<Props> = ({ tier, isOwner }) => {
  const { t } = useTranslation();
  const onClick = useCallback(() => {
    window.location.href = Url.studio({
      openSubmitForm: true,
      tierId: tier?.deviationId,
    });
  }, [tier]);

  if (!isOwner) {
    return null;
  }

  const title = t('widgets.tierContent.submitContent.title.owner');
  const subtitle = t('widgets.tierContent.submitContent.subtitle.owner');
  const ctaButton = (
    <SolidButton
      className={s['cta']}
      size="medium"
      variant="approval-green"
      priority="primary"
      onClick={onClick}
      biData={BiData<SubmitButtonClickBiEvent>({
        evid: 704,
        sectionname: 'tier_benefits',
      })}
    >
      {t('common.submit')}
    </SolidButton>
  );
  const illustration = <PromoBadge badgeType="tier" size={40} />;

  return (
    <EmptyState
      className={s['root']}
      title={title}
      subtitle={subtitle}
      customButton={ctaButton}
      illustration={illustration}
    />
  );
};
TierContentSubmit.displayName = 'TierContentSubmit';

export default TierContentSubmit;
