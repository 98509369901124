import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { ShopItemType } from '../../../../../../types/shop';
import { changeCommissionsGlobalStatus } from '../../../../../actions/commissions';
import { Props } from './DisabledOverlay';

type StateProps = Pick<Props, 'total'>;
type DispatchProps = Pick<Props, 'enableAllCommissions'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const streamId = getShopItemsStreamId(ShopItemType.COMMISSIONS);

  return {
    total: streamSelectors.getTotal(state, streamId),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    enableAllCommissions: () =>
      dispatch(changeCommissionsGlobalStatus({ isOpen: true })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
