import Url from 'url-parse';

export function getTranslatedDropdownItems(
  optionItems,
  translation,
  t,
  translationKey = 'id'
) {
  return optionItems.map(option => ({
    value: option.id,
    label: t(`${translation}.${option[translationKey] || 'unknown'}`),
  }));
}

function translateMonth(t, month) {
  switch (month) {
    case 1:
      return t('date.month.1.short');
    case 2:
      return t('date.month.2.short');
    case 3:
      return t('date.month.3.short');
    case 4:
      return t('date.month.4.short');
    case 5:
      return t('date.month.5.short');
    case 6:
      return t('date.month.6.short');
    case 7:
      return t('date.month.7.short');
    case 8:
      return t('date.month.8.short');
    case 9:
      return t('date.month.9.short');
    case 10:
      return t('date.month.10.short');
    case 11:
      return t('date.month.11.short');
    case 12:
      return t('date.month.12.short');
    default:
      return t('date.month.1.short');
  }
}

/**
 * Provides standard formatting for displaying a user's date of birth.
 * This assumes that we've been given an accurate day/month/year and don't need to put it into a Date object
 *
 * @param day 1 - 31
 * @param month 1 - 12
 * @param year
 * @param t An i18n translation function, normally provided by useTranslation
 */
export function formatDateOfBirth(day, month, year, t) {
  // We're not using Date objects because of their tendency to take wild guesses and shift the date around
  // Albeit probably for good reasons, but can't November 5th just be November 5th?
  if (day > 0 && month > 0 && year > 0) {
    return t('aboutMe.dayMonthYear', {
      day,
      month: translateMonth(t, month),
      year,
    });
  }
  if (day > 0 && month > 0) {
    return t('aboutMe.dayMonth', { day, month: translateMonth(t, month) });
  }

  return '';
}

export const WEBSITE_URL_REGEX =
  /^(https?:\/\/)?(www\.)?([\da-z-]+)\.([a-z]+\.)*([a-z0-9._\-/]+)(\??[/\w .:%_+~#?&=@-]*\/?)?$/i;
const EXTERNAL_URL_REGEX = /^(https?):\/\//i;
const URL_PREFIX = 'http://';

export const INTEREST_MAX_LENGTH = 255;

export enum FormTab {
  ABOUT,
  SOCIAL_ACCOUNTS,
  INTERESTS,
  BIO,
  BADGES,
}

export function formatExternalUrl(url) {
  if (url && !EXTERNAL_URL_REGEX.test(url)) {
    return URL_PREFIX + url;
  }
  return url;
}

// For whitelisting user provided links
export function sanitizeExternalUrl(url) {
  const compiledUrl = Url(url, {});
  // Protect against javascript: protocol attacks
  if (
    compiledUrl.protocol === 'http:' ||
    compiledUrl.protocol === 'https:' ||
    compiledUrl.protocol === ''
  ) {
    // Avoid XSS in query and hash url portions by just tossing it
    // Should make a more elegant solution later.
    compiledUrl.set('query', '');
    compiledUrl.set('hash', '');
    return formatExternalUrl(compiledUrl.set().toString());
  }
  return '';
}

export function getPronounKeyFromGender(gender: string): string {
  switch (gender) {
    case 'male':
      return 'widgets.aboutMe.gender.complete.he.him';
    case 'female':
      return 'widgets.aboutMe.gender.complete.she.her';
    case 'other':
      return 'widgets.aboutMe.gender.complete.they.them';
    default:
      return '';
  }
}
