import React, { useCallback, useRef } from 'react';
import DonationsTiptapEditor from '@wix/da-editor-tiptap/pkg/components/Editor/presets/UserDonations';
import { EditorApi } from '@wix/da-editor-tiptap/pkg/components/Editor/types';
import useEditorNg from '@wix/da-shared-react/pkg/DaEditorNg/hooks/useEditorNg';
import EditorCommon from '@wix/da-shared-react/pkg/DaEditorNg/EditorCommon';

interface Props {
  initialContent?: any;
  className?: string;
  onEditorChange?: () => void;
  onEditorReady?: (editorApi: EditorApi) => void;
  scrollRef?: any;
}

const DonationEditor: React.FC<Props> = ({
  initialContent,
  className,
  onEditorChange,
  onEditorReady,
  scrollRef,
}) => {
  const editorApi = useRef<any>();
  const { editorProps, editorSharedProps, editorCommonProps } = useEditorNg({
    onEditorChange: onEditorChange,
    onEditorReady: (api: EditorApi) => {
      onEditorReady && onEditorReady(api);
      editorApi.current = api;
    },
  });

  const handleOnChange = useCallback(() => {
    onEditorChange && onEditorChange();
  }, [onEditorChange]);

  return (
    <>
      <EditorCommon {...editorCommonProps} />
      <DonationsTiptapEditor
        cookieType={undefined}
        {...editorSharedProps}
        {...editorProps}
        initialContent={initialContent}
        onChange={handleOnChange}
        className={className}
        scrollContainerRef={scrollRef}
        autoScrollWhenWithin={15}
      />
    </>
  );
};
DonationEditor.displayName = 'DonationEditor';

export default DonationEditor;
