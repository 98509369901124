import camelCase from 'lodash/camelCase';
import { ModuleType } from '../types/modules';

// TODO: rename all i18n widgets.* keys to use the back-end names for widgets not arbitrary names that we use now
// That will make this mapping obsolete.

const moduleTypeToTranslationKeyMap = {
  [ModuleType.ABOUT]: 'widgets.aboutMe.header',
  [ModuleType.BADGES_ACTIVITY]: 'widgets.badges_activity.header',
  [ModuleType.COMMENTS]: 'widgets.profileComments.header',
  [ModuleType.CUSTOM]: 'widgets.custombox.header',
  [ModuleType.DEVIOUSNESS]: 'widgets.deviousness.header',
  [ModuleType.DONATIONS]: 'widgets.donations.header',
  [ModuleType.EXHIBITIONIST]: 'widgets.exhibitionist.header',
  [ModuleType.FEATURED_DEVIATIONS]: 'widgets.featured.header',
  [ModuleType.FEATURED_DEVIATION]: 'widgets.featured_deviation.header',
  [ModuleType.FEATURED_JOURNALS]: 'widgets.featured_journals.header',
  [ModuleType.FEATURED_POSTS]: 'widgets.feed.header',
  [ModuleType.FEATURED_POST]: 'widgets.featured_post.header',
  [ModuleType.FEED]: 'widgets.feed.header',
  [ModuleType.GROUPS_ADMIN]: 'widgets.groups_admin.header',
  [ModuleType.GROUPS_MEMBER]: 'widgets.groups_member.header',
  [ModuleType.JOURNALS]: 'widgets.journal.header',
  [ModuleType.LLAMA_FARM]: 'widgets.llamafarm.header',
  [ModuleType.LATEST_DEVIATIONS]: 'widgets.latest_artworks.header',
  [ModuleType.MY_COMMENTS]: 'widgets.myComments.header',
  [ModuleType.STATS]: 'widgets.statistics.header',
  [ModuleType.WATCHERS]: 'widgets.watchers.header',
  [ModuleType.WATCHING]: 'widgets.watching.header',

  /* hidden modules */
  [ModuleType.COMMISSIONS]: '',
  [ModuleType.COVER_DEVIATION]: '',
  [ModuleType.ADOPTABOBBLES_BOUGHT]: '',
  [ModuleType.DRAFTS]: '',
  [ModuleType.FEED_PINNED_DEVIATION]: '',
  [ModuleType.FOLDERS]: '',
  [ModuleType.FOLDER_DEVIATIONS]: '',
  [ModuleType.NEWEST_DEVIATIONS]: '',
  [ModuleType.POLLS]: '',
  [ModuleType.POSTS_FEED]: '',
  [ModuleType.PREMIUM_CONTENT]: '',
  [ModuleType.PREMIUM_FOLDERS]: '',
  [ModuleType.STATUSES]: '',
};
export const moduleTypeToTranslationKey = (moduleType: ModuleType) =>
  moduleTypeToTranslationKeyMap[moduleType] || 'widgets.unknown.header';

export const getRawModuleDataFromResponse = (module: any) => {
  const { dataKey } = module.moduleData || {};
  if (dataKey) {
    return module.moduleData[camelCase(dataKey)];
  }
};
