import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { CommentableTypes } from '@wix/da-shared-react/pkg/Comment/redux/types';
import { getThreadState } from '@wix/da-shared-react/pkg/CommentThread/selectors';
import {
  fetchNextInThread,
  initializeThread,
} from '@wix/da-shared-react/pkg/CommentThread/actionCreators';

export default function useInitThread(noteId) {
  const [fetchedNext, setFetchedNext] = useState(false);
  const dispatch = useDispatch();
  const { streamId, isFetching, isInitialized, items } = useSelector(state =>
    getThreadState(state, CommentableTypes.COMMENT_THREADNOTE, noteId)
  );
  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (!isInitialized) {
      dispatch(
        initializeThread({
          thread: {
            commentableTypeId: CommentableTypes.COMMENT_THREADNOTE,
            commentableItemId: noteId,
            order: 'oldest',
            canPostComment: true,
            items: [],
            total: 0,
            hasMore: true,
          },
        })
      );
    }
  }, [isInitialized, isFetching, dispatch, noteId]);

  useEffect(() => {
    if (isFetching || fetchedNext) {
      return;
    }
    dispatch(fetchNextInThread(streamId));
    setFetchedNext(true);
  }, [streamId, isFetching, fetchedNext, isInitialized, dispatch, items]);

  return { streamId, isFetching };
}
