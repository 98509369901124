import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';

import { Admins } from './Admins';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}
const AdminsBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  return (
    <WidgetBox
      header={t(`widgets.group_admins.header`)}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      <Admins moduleId={moduleId} />
    </WidgetBox>
  );
};
AdminsBox.displayName = 'AdminsBox';

export default AdminsBox;
