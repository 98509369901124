export enum PagePriv {
  ADD_MODULE = 'addModule',
  MOVE_MODULE = 'moveModule',
  VIEW_PAGE = 'viewPage',
  CAN_CHANGE_AVATAR = 'changeAvatar',
  CAN_RESPOND_TO_GROUP_NOTIFICATIONS = 'canRespond',
  CAN_CONTRIBUTE_DEVIATIONS = 'contributeDeviations',
  CAN_RECOMMEND_DEVIATIONS = 'recommendDeviations',
  CAN_SUBMIT_JOURNAL = 'canSubmitJournal',
}
export type PagePrivileges = {
  [priv in keyof typeof PagePriv]?: boolean;
};
