import { Action } from 'redux-actions';
import { PapiModuleTiers } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleTiers
): Action<any>[] => {
  const { result, entities } = normalize(moduleData.tiers || [], [
    deviationSchema,
  ]);
  return [
    putEntities({ entities }),
    updateModule(gruser, {
      id: moduleId,
      moduleData: {
        tiers: result,
      },
    }),
  ];
};
