import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../types/store';
import {
  getProfileOwner,
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../selectors/users';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { getIsLoggedIn } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { restrictLoggedOut } from '@wix/da-shared-react/pkg/redux/loggedout/actionCreators';
import {
  fetchProfileOwnerGiveState,
  fetchProfileOwnerGiveStateSuccess,
} from '../../actions/profileOwner';
import { getPageRoutes } from '../../selectors/pageLayout';
import { biClickProfileNavBar } from '../../actions/bi';
import { SubNavBarProps } from './SubNavBar';
import { pushPointsModal } from '../../actions/gifts';

type StateProps = Pick<
  SubNavBarProps,
  'isOwner' | 'isLoggedIn' | 'giveState' | 'profileOwnerUser' | 'routes'
>;
type DispatchProps = Pick<
  SubNavBarProps,
  | 'pushBadgeModal'
  | 'pushPointsModal'
  | 'initGiveState'
  | 'onLlamaBadgeSent'
  | 'restrictLoggedOut'
  | 'biClickProfileNavBar'
>;
type OwnProps = Omit<SubNavBarProps, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  giveState: getProfileOwner(state).giveState,
  profileOwnerUser: getProfileOwnerUser(state),
  isLoggedIn: getIsLoggedIn(state),
  routes: getPageRoutes(state),
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  pushBadgeModal: (username: string, onBadgeSent?: () => void) =>
    dispatch(modalActions.pushBadgeModal(username, onBadgeSent)),
  pushPointsModal: (username, title) =>
    dispatch(pushPointsModal(username, title)),
  initGiveState: () => dispatch(fetchProfileOwnerGiveState()),
  onLlamaBadgeSent: () =>
    dispatch(fetchProfileOwnerGiveStateSuccess({ canBeGivenLlama: false })),
  restrictLoggedOut: payload => dispatch(restrictLoggedOut(payload)),
  biClickProfileNavBar: linkName => dispatch(biClickProfileNavBar(linkName)),
});

export default connect(mapStateToProps, mapDispatchToProps);
