import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PapiDeviation } from '@wix/da-papi-types';
import {
  isDeviationTextEditable,
  isDeviationAuthor,
} from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { getCurrentUser } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { getCanFeature, getCanPin } from '../../client/selectors/privs';

// Helper hook which tells us which permissions the currentUser has over a given deviation
export const useDeviationPermissions = (deviation: PapiDeviation) => {
  const currentUser = useSelector(getCurrentUser);
  const canPin = useSelector(getCanPin);
  const canFeature = useSelector(getCanFeature);

  return useMemo(() => {
    const isEditable = isDeviationTextEditable(deviation);
    const isAuthor = isDeviationAuthor(deviation, currentUser);
    const canEdit = isAuthor;
    const canDelete = isAuthor;

    return {
      canPin,
      canEdit,
      canDelete,
      canFeature,
      isAuthor,
      isEditable,
    };
  }, [canPin, canFeature, deviation, currentUser]);
};

export default useDeviationPermissions;
