import React from 'react';
import classnames from 'classnames';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import Sticky from '@wix/da-ds/pkg/Sticky';
import { getStickyContentHeight } from '../../helpers';

import s from './StickyBar.scss';

export interface Props {
  children(isStuck: boolean): React.ReactNode;
}

export const StickyBar: React.FC<Props> = props => {
  return (
    <ErrorBoundary debugComponent="StickyBar">
      <div className={s['wrapper-placeholder']}>
        <Sticky className={s['sticky']} marginTop={getStickyContentHeight()}>
          {isStuck => (
            <div className={classnames(s['content'], isStuck && s['stuck'])}>
              {props.children(isStuck)}
            </div>
          )}
        </Sticky>
      </div>
    </ErrorBoundary>
  );
};
StickyBar.displayName = 'StickyBar';

export default StickyBar;
