import React, { useEffect, useContext } from 'react';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { PapiDeviation } from '@wix/da-papi-types';
import TierPromotionCard from '@wix/da-shared-react/pkg/DeviationViews/TierPromotionCard';
import TierProductCard from '@wix/da-shared-react/pkg/DeviationViews/TierProductCard';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';

import s from './Tiers.scss';

export interface Props {
  tier: PapiDeviation;
  view?: 'promotion' | 'product';
  onClick?: (tier: PapiDeviation) => void;
  onSelect?: (tier: PapiDeviation) => void;
  withExpandButton?: boolean;
  isCollapsed?: boolean;
  onToggleCollapsed?: (isCollapsed: boolean) => void;
  isSelected?: boolean;
}

const TiersSliderCard: React.FC<Props> = ({
  tier,
  view = 'promotion',
  withExpandButton,
  onToggleCollapsed,
  isCollapsed,
  isSelected,
  onClick,
  onSelect,
}) => {
  const isMobile = useContext(MobileContext);

  // eslint-disable-next-line
  const [ref, _, entry] = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: 1,
    rootMargin: '50% 0%',
  });
  const intersectionRatio = entry?.intersectionRatio || 0;

  useEffect(() => {
    if (isMobile && !isSelected && intersectionRatio === 1) {
      onSelect && onSelect(tier);
    }
  }, [isMobile, isSelected, tier, onClick, onSelect, intersectionRatio]);

  return (
    <React.Fragment>
      {view === 'promotion' && (
        <TierPromotionCard
          fakeCTAButton
          showAuthor={false}
          className={classnames(isSelected && s['selected'])}
          wrapperClassName={s['tier-card']}
          deviation={tier}
          height={200}
          showEmptyPlaceholders={!tier.isPublished}
          onClick={onClick ? () => onClick(tier) : undefined}
          style={isMobile ? { width: '100%' } : undefined}
          innerRef={ref}
        />
      )}
      {view === 'product' && (
        <TierProductCard
          className={classnames(s['tier-card'], isSelected && s['selected'])}
          deviation={tier}
          onClick={onClick ? () => onClick(tier) : undefined}
          showEmptyPlaceholders={!tier.isPublished}
          isCollapsed={isCollapsed}
          onToggleCollapsed={onToggleCollapsed}
          withExpandButton={withExpandButton}
          innerRef={ref}
        />
      )}
    </React.Fragment>
  );
};
TiersSliderCard.displayName = 'TiersSliderCard';

export default TiersSliderCard;
