import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { PapiUser, PapiModuleGroupList } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { GROUPS_ADMIN_IDS_STREAM, GROUPS_PER_FETCH } from '../../../../streams';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleGroupList
) => {
  const { results, total, nextOffset, canSeeAll } = moduleData;

  const streamId = GROUPS_ADMIN_IDS_STREAM;
  const groups: PapiUser[] = results ?? [];
  const normalized = normalize(groups, [userSchema]);

  return [
    withOffset.actions.initialize({
      streamId,
      itemsPerFetch: GROUPS_PER_FETCH,
      streamParams: {
        moduleId,
        moduleType: 'posts_feed',
        canSeeAll,
      },
      items: normalized.result,
      entities: normalized.entities,
      nextOffset: nextOffset ?? undefined,
      hasMore: false /* disabled, since you can only administer 10 groups */,
      total,
    }),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
