import { AppState } from '../../types/store';
import { Section } from '../../types/sections';
import { GROUP_CREATION_PATH } from '../../constants';
import { getProfileOwnerUser } from './users';
import { getSelectedFolder } from './gallectionSection';
import { getPageType } from './pageType';

export const getActiveSection = (state: AppState): Section =>
  state.sections.active;

export const isSectionLoading = (state: AppState): boolean =>
  state.sections.isLoading;

export const getIsSectionLoading = isSectionLoading;

export const isSectionPrivate = (state: AppState): boolean =>
  state.sections.isPrivate;

export const getSectionStreamId = (state: AppState): string | undefined =>
  state.sections.streamId;

export const getActiveSectionAppUrl = (state: AppState) => {
  const pageType = getPageType(state);
  if (pageType === 'groupCreation') {
    return GROUP_CREATION_PATH;
  }

  const profileOwner = getProfileOwnerUser(state);
  const activeSection = getActiveSection(state);
  const folderUuid = getSelectedFolder(state)?.gallectionUuid;
  switch (activeSection) {
    case 'gallery':
      if (folderUuid) {
        return `gallery/${profileOwner.username}/${folderUuid}`;
      }
      break;
    case 'favourites':
      if (folderUuid) {
        return `collection/${profileOwner.username}/${folderUuid}`;
      }
      break;
    default:
  }

  return `profile/${profileOwner.username}`;
};
