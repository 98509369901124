import React from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import StatisticHeading from '../StatisticHeading';
import s from './StatisticBlock.scss';

interface Props {
  /** Whether we align content to the left or right of the content box */
  alignment: 'left' | 'right';

  /** The bold text at the top of the block. This is wrapped in a `<StatisticHeading />` */
  heading: React.ReactNode;

  /** The actual statistic value. Consider passing in a `<StatisticText />` */
  statistic: React.ReactNode;

  /** Shown at the end of the block. We tend to put a `<TextButton />` here */
  postfix?: React.ReactNode;
}

/**
 * A block that contains a statistic and some context.
 *
 * e.g.
 * ```jsx
 *  <StatisticBlock
 *    alignment="left"
 *    heading="Total Income"
 *    statistic={<StatisticText>$100</StatisticText>}
 *  />
 * ```
 */
const StatisticBlock: React.FC<Props> = ({
  alignment,
  heading,
  postfix,
  statistic,
}) => {
  return (
    <Section
      className={c(
        s['root'],
        alignment === 'left' && s['left-aligned'],
        alignment === 'right' && s['right-aligned']
      )}
    >
      <StatisticHeading className={s['heading']}>{heading}</StatisticHeading>
      {statistic}
      {postfix && <div className={s['postfix']}>{postfix}</div>}
    </Section>
  );
};
StatisticBlock.displayName = 'StatisticBlock';

export default StatisticBlock;
