import { connect, MapDispatchToProps } from 'react-redux';
import { openCommissionEditModal } from '@wix/da-shared-react/pkg/redux/commissions/actionCreators';
import { Props } from './CommissionCreateButton';

export type DispatchProps = Pick<Props, 'openModal'>;
export type OwnProps = Omit<Props, keyof DispatchProps>;

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    openModal: () =>
      dispatch(openCommissionEditModal({ deviationId: undefined })),
  };
};

export default connect(
  null,
  mapDispatchToProps
);
