import { connect, MapStateToProps } from 'react-redux';
import { Props } from './FoldersHeader';
import { AppState } from '../../../../../types/store';
import {
  getGallectionSectionType,
  getShouldHideFolderSlider,
} from '../../../../selectors/gallectionSection';

export type StateProps = Pick<Props, 'gallectionType' | 'isFolderSliderHidden'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  gallectionType: getGallectionSectionType(state),
  isFolderSliderHidden: getShouldHideFolderSlider(state),
});

export default connect(mapStateToProps);
