import React, {
  Suspense,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import IframePopup from '@wix/da-shared-react/pkg/IframePopup';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import Zone from '@wix/da-gruser-shared/pkg/components/Zone';

import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import Modal from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import { PapiUser } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { Url, USER_PROFILE_BACKROOM_SECTIONS } from '@wix/da-url';

import { getBackroomWidgetConfigs } from './backroomWidgetConfigs';
import EmptyState from '../../EmptyState';

import SubSubNavBar from '../../SubSubNavBar';

import s from './BackroomSection.scss';

export interface Props {
  profileOwnerUser: PapiUser;
  canManageRoles: boolean;
  canRespondToGroupNotifications: boolean;
}

const BackroomSection: React.FC<Props> = ({
  profileOwnerUser,
  canManageRoles,
  canRespondToGroupNotifications,
}) => {
  const [showRoster, setShowRoster] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useContext(MobileContext);
  const isMounted = useIsMounted();

  const component = useCallback(
    (...props) => (
      <Zone
        {...props}
        name="topLeft"
        widgetConfigs={getBackroomWidgetConfigs({ t, dispatch })}
      />
    ),
    [t, dispatch]
  );

  const defaultRoute = useMemo(
    () => ({
      label: t('common.notes'),
      component,
    }),
    [t, component]
  );
  const tabRoutes = useMemo(() => {
    const { username: ownerUsername } = profileOwnerUser;
    const username = ownerUsername.toLowerCase();
    return [
      {
        label: t('common.messagelog'),
        path: Url.groupBackroomPath(
          username,
          USER_PROFILE_BACKROOM_SECTIONS.MESSAGE_LOG
        ),
        component,
      },
      canRespondToGroupNotifications && {
        label: t('common.notes'),
        path: Url.groupBackroomPath(
          username,
          USER_PROFILE_BACKROOM_SECTIONS.NOTES
        ),
        component,
      },
      {
        label: t('common.actionlog'),
        path: Url.groupBackroomPath(
          username,
          USER_PROFILE_BACKROOM_SECTIONS.ACTION_LOG
        ),
        component,
      },
    ].filter(x => x);
  }, [t, component, profileOwnerUser, canRespondToGroupNotifications]);

  const routes = useMemo(
    () => [...tabRoutes, defaultRoute],
    [defaultRoute, tabRoutes]
  );

  if (isMobile) {
    return (
      <div className={s['root']}>
        <EmptyState
          view="small"
          className={s['empty-state']}
          title={t('widgets.backroomSection.empty.title.owner')}
          subtitle={t('widgets.backroomSection.empty.subtitle.owner')}
        />
      </div>
    );
  }
  return (
    <div className={s['root']}>
      <div className={s['navbar']}>
        <SubSubNavBar className={s['section-nav']} items={tabRoutes} />
        {canManageRoles && (
          <GhostButton
            size={'large'}
            variant={'main'}
            onClick={() => setShowRoster(true)}
            className={s['btn-roster']}
          >
            {t('common.manageMembers')}
          </GhostButton>
        )}
      </div>
      {!isMounted && <LoadingIndicator view="llama" forSSR />}
      {isMounted && (
        <ErrorBoundary debugComponent="backroomViews">
          <Suspense fallback={<LoadingIndicator view="llama" />}>
            {renderRoutes(routes)}
          </Suspense>
        </ErrorBoundary>
      )}

      {showRoster && (
        <Modal
          isOpen
          showFooterBorder
          showHeaderBorder
          heading={t('common.manageMembers')}
          onRequestClose={() => setShowRoster(false)}
          isFullscreen={true}
        >
          <IframePopup
            style={{
              position: 'relative',
              height: '100%',
              width: '100%',
              boxSizing: 'border-box',
              padding: '8px',
            }}
            src={Url.groupRosterLink(profileOwnerUser.username)}
          />
        </Modal>
      )}
    </div>
  );
};
BackroomSection.displayName = 'BackroomSection';
export default BackroomSection;
