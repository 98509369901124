import React from 'react';
import { BiData, GalleryPurchaseBiEvent } from '@wix/da-bi/pkg/events';
import { PapiDeviation } from '@wix/da-papi-types';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import StandardThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Standard';
import ThumbInfo from '@wix/da-shared-react/pkg/ThumbInfo';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import {
  getDeviationType,
  getDeviationResourceType,
} from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import DeviationPaywall from '../../../UserWidgets/_partials/paywalls/DeviationPaywall';

import s from './FeaturedDeviationThumb.scss';

export interface Props {
  deviation: PapiDeviation;
  width?: number;
  height?: number;
}

export const FeaturedDeviationThumb: React.FC<Props> = ({
  deviation: { title, author, deviationId },
  deviation,
  width,
  height,
}) => {
  const { pointPrice, folderId } = normalizePremiumFolderData(
    deviation.premiumFolderData
  );

  return (
    <div className={s['root']}>
      <ErrorBoundary debugComponent="FeaturedDeviationThumb">
        <DeviationPaywall
          isLiterature={
            getDeviationType(deviation) === DeviationTypes.LITERATURE
          }
          premiumFolderData={deviation.premiumFolderData}
          contentOwner={author!}
          biData={BiData<GalleryPurchaseBiEvent>({
            evid: 361,
            points: pointPrice,
            typeid: getDeviationResourceType(deviation),
            itemid: deviationId,
            galleryid: folderId ?? 0,
            authorid: author && author.useridUuid, // This is NOT the gallery owner
          })}
        >
          <OverlayContextProvider
            value={{
              showAuthor: false,
              showTitle: false,
              showThumbStatusIndicators: false,
            }}
          >
            <StandardThumb
              deviation={deviation}
              width={width}
              height={height}
            />
          </OverlayContextProvider>
        </DeviationPaywall>
        <ThumbInfo urlDeviation={deviation} user={author!} title={title} />
      </ErrorBoundary>
    </div>
  );
};
FeaturedDeviationThumb.displayName = 'FeaturedDeviationThumb';

export default FeaturedDeviationThumb;
