import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { PrimaryTierContextProvider } from '@wix/da-shared-react/pkg/Tier/PrimaryTierContext';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import Torpedo from '@wix/da-shared-react/pkg/Torpedo/DeviationTorpedo';
import LoadableDuperbrowseContext from '../../../../../../contexts/LoadableDuperbrowseContext';
import PostsFeed from '../../../../../Sections/PostsSection/_partials/PostsFeed';
import StreamPagination from '../../../../../Sections/_partials/StreamPagination';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types'; // TODO: add new cookie type
import { SubscriptionsTierContentType } from '../../../../../../../types/subscriptionsSection';
import { getTierContentDeviationsStreamId } from '../../../../../../streams/tierContent';
import TierContentEmptyState from '../EmptyState';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import Grid from '@wix/da-shared-react/pkg/Grid/DeviationGrid';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import TierContentDivider from '../TierContentDivider';
import TierContentSubmit from '../TierContentSubmit';

const TORPEDO_DEFAULT_SIZE = {
  desktop: { width: 1024, height: 0 },
  mobile: { width: 320, height: 0 },
};
const TORPEDO_CONTAINER_SIZE = { width: 1024 };

import s from './TierContentFeed.scss';

export interface Props {
  tierContentType: SubscriptionsTierContentType;
  isOwner: boolean;
  streamId: string;
  deviations?: PapiDeviation[];
  tier?: PapiDeviation;
  isInitialized: boolean;
  isFetching: boolean;
  isEmpty?: boolean;
  initFetch: () => void;
}

const TierContentFeed: React.FC<Props> = ({
  isOwner,
  tier,
  streamId,
  deviations = [],
  isInitialized,
  isFetching,
  tierContentType,
  initFetch,
}) => {
  useEffect(() => {
    if (!isInitialized && !isFetching) {
      initFetch();
    }
  }, [initFetch, isInitialized, isFetching]);

  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const deviationsStreamId = getTierContentDeviationsStreamId(
    tier?.deviationId,
    'deviations'
  );
  const postsStreamId = getTierContentDeviationsStreamId(
    tier?.deviationId,
    'posts'
  );

  const noUnlockedDeviations = !deviations.some(
    deviation => deviation?.tierAccess === 'unlocked'
  );

  const showSubmitDeviationCTA =
    isOwner && tierContentType === SubscriptionsTierContentType.DEVIATIONS;
  const showEmptyState =
    !showSubmitDeviationCTA &&
    isInitialized &&
    !isFetching &&
    !deviations.length;

  return (
    <div className={s['root']}>
      <a id="content">
        <VisuallyHidden>Tier Content</VisuallyHidden>
      </a>
      {showSubmitDeviationCTA && <TierContentSubmit tier={tier} />}
      {showEmptyState && (
        <TierContentEmptyState tier={tier} tierContentType={tierContentType} />
      )}
      {isInitialized && deviations.length > 0 && (
        <LoadableDuperbrowseContext
          streamId={streamId}
          parent={{
            title: t('common.back'),
          }}
        >
          <PrimaryTierContextProvider primaryTier={tier}>
            <BiLoggerContextProvider value={{ sectionname: tierContentType }}>
              {streamId === postsStreamId && (
                <>
                  {!!tier && noUnlockedDeviations && (
                    <TierContentDivider tier={tier} />
                  )}
                  <PostsFeed
                    streamId={streamId}
                    className={s['posts']}
                    disablePinningAndFeaturing
                  />
                </>
              )}
              {streamId === deviationsStreamId && (
                <OverlayContextProvider
                  value={{
                    showAuthorWatchButton: !isOwner,
                    showThumbStatusIndicators: false,
                  }}
                >
                  {!!tier && noUnlockedDeviations && (
                    <TierContentDivider tier={tier} />
                  )}
                  {isMobile ? (
                    <Grid
                      items={deviations}
                      cookieType={
                        MeasuredCookieType.USER_PROFILE_GALLECTION_CONTENT
                      }
                      spacing={8}
                      enableScrollOptim
                      allowMobileCard
                    />
                  ) : (
                    <Torpedo
                      cookieType={
                        MeasuredCookieType.USER_PROFILE_GALLECTION_CONTENT
                      }
                      cookieDim={'width'}
                      defaultSize={TORPEDO_DEFAULT_SIZE}
                      container={TORPEDO_CONTAINER_SIZE}
                      items={deviations}
                      enableScrollVisibilityOptimisation
                    />
                  )}
                  <StreamPagination streamId={streamId} />
                </OverlayContextProvider>
              )}
            </BiLoggerContextProvider>
          </PrimaryTierContextProvider>
        </LoadableDuperbrowseContext>
      )}
      {isFetching && <LoadingIndicator view={'spinner'} />}
    </div>
  );
};
TierContentFeed.displayName = 'TierContentFeed';

export default TierContentFeed;
