import { createAction } from 'redux-actions';
import {
  PapiDaUserProfileStatsMyContent,
  PapiUser,
  PapiDataPackageCompareYourself,
  PapiDataPackageRecentVisitors,
} from '@wix/da-papi-types';
import {
  StatsTimePeriod,
  MySubmissionsSortBy,
  BenchmarkSortBy,
} from '../../types/statsSection';

export const setStats = createAction(
  'STATS_SECTION/SET_STATS',
  payload => payload
);

export type SetMySubmissionsStatsPayload =
  PapiDaUserProfileStatsMyContent['myContent'];
export const setMySubmissionsStats = createAction(
  'STATS_SECTION/SET_MY_SUBMISSIONS_STATS',
  (payload: SetMySubmissionsStatsPayload) => payload
);

export const setTimePeriod = createAction(
  'STATS_SECTION/SET_TIME_PERIOD',
  (payload: StatsTimePeriod) => payload
);

export const setMySubmissionsSortBy = createAction(
  'STATS_SECTION/SET_MY_SUBMISSIONS_SORT_BY',
  (payload: MySubmissionsSortBy) => payload
);

export const setMySubmissionsIsLoading = createAction(
  'STATS_SECTION/SET_MY_SUBMISSIONS_IS_LOADING',
  (payload: boolean) => payload
);

export const setBenchmarkSortBy = createAction(
  'STATS_SECTION/SET_BENCHMARK_SORT_BY',
  (payload: BenchmarkSortBy) => payload
);

export const removeBenchmarkUser = createAction(
  'STATS_SECTION/DELETE_BENCHMARK_USER',
  (payload: number) => payload
);

export const addBenchmarkUser = createAction(
  'STATS_SECTION/ADD_BENCHMARK_USER',
  (payload: number) => payload
);

export type AddBenchmarkUserSuccessPayload = {
  timePeriods: PapiDataPackageCompareYourself['timePeriods'];
};
export const addBenchmarkUserSuccess = createAction(
  'STATS_SECTION/ADD_BENCHMARK_USER_SUCCESS',
  (payload: AddBenchmarkUserSuccessPayload) => payload
);

export const sendQuery = createAction(
  'STATS_SECTION/SEND_QUERY',
  (payload: string) => payload
);

export const resultUserQuery = createAction(
  'STATS_SECTION/SEND_USER_QUERY_SUCCESSFUL',
  (payload: PapiUser[]) => payload
);

export const fetchRecentVisitors = createAction(
  'STATS_SECTION/FETCH_RECENT_VISITORS',
  (payload: number) => payload
);

export type FetchRecentVisitorsSuccessPayload = {
  timePeriods: PapiDataPackageRecentVisitors['timePeriods'];
};
export const fetchRecentVisitorsSuccess = createAction(
  'STATS_SECTION/FETCH_RECENT_VISITORS_SUCCESS',
  (payload: FetchRecentVisitorsSuccessPayload) => payload
);
