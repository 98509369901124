import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { URLS } from '@wix/da-url';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import FavouriteOutfilled from '@wix/da-ds/pkg/Icons/FavouriteOutfilled/FavouriteOutfilled';
import { BiData, SubmitButtonClickBiEvent } from '@wix/da-bi/pkg/events';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton/GhostButton';
import MediaSelectorWithGroupGallection from '@wix/da-shared-react/pkg/MediaPopup/Groups/MediaSelectorWithGroupGallection';
import { MediaSourceType } from '@wix/da-shared-react/pkg/MediaPopup/types';
import { GroupGallectionType } from '@wix/da-shared-react/pkg/GroupGallectionSelector/redux/types';

import EmptyState from '../../../EmptyState';
import { GallectionType } from '../../../../../types/gallection';
import { PapiDeviation, PapiUser } from '@wix/da-papi-types';
import { getSubmitUrlForProfileOwner } from '../../../../utils/urls';
import withFeatureFlags, {
  WithFeatureFlagsProps,
} from '@wix/da-react-context/pkg/flags/withFeatureFlags';
import s from './GallectionsEmptyState.scss';

const TRANSLATION_KEYS = {
  [GallectionType.GALLERY]: {
    titleOwner: 'pages.gallections.gallery.emptyState.title.owner',
    titleVisitor: 'pages.gallections.gallery.emptyState.title.visitor',
    subtitleOwner: 'pages.gallections.gallery.emptyState.subtitle.owner',
    subtitleVisitor: 'pages.gallections.gallery.emptyState.subtitle.visitor',
    buttonCTA: 'pages.gallections.gallery.emptyState.buttonCTA.owner',
  },
  [GallectionType.COLLECTION]: {
    titleOwner: 'pages.gallections.collection.emptyState.title.owner',
    titleVisitor: 'pages.gallections.collection.emptyState.title.visitor',
    subtitleOwner: 'pages.gallections.collection.emptyState.subtitle.owner',
    subtitleVisitor: 'pages.gallections.collection.emptyState.subtitle.visitor',
    buttonCTA: 'pages.gallections.collection.emptyState.buttonCTA.owner',
  },
  [GallectionType.PRIVATE_COLLECTION]: {
    titleOwner: 'pages.gallections.privateCollection.emptyState.title.owner',
    subtitleOwner:
      'pages.gallections.privateCollection.emptyState.subtitle.owner',
    buttonCTA: 'pages.gallections.privateCollection.emptyState.buttonCTA.owner',
  },
};

export interface Props {
  gallectionType: GallectionType;
  owner: PapiUser;
  isOwner: boolean;
  canSubmitDeviations: boolean;
  contributeDeviations: (
    deviations: PapiDeviation[],
    group: PapiUser,
    type: GroupGallectionType,
    folderId?: number
  ) => void;
}

export class GallectionsEmptyState extends React.Component<
  Props & WithTranslation & WithFeatureFlagsProps
> {
  state = {
    isMediaSelectorOpen: false,
  };

  render() {
    const { isOwner, canSubmitDeviations, t, gallectionType, owner } =
      this.props;

    if (gallectionType === GallectionType.PRIVATE_COLLECTION && !isOwner) {
      return null;
    }

    return (
      <EmptyState
        view="large"
        className={s['root']}
        title={
          canSubmitDeviations
            ? t(TRANSLATION_KEYS[gallectionType].titleOwner)
            : t(TRANSLATION_KEYS[gallectionType].titleVisitor, {
                username: owner.username,
              })
        }
        subtitle={this.getSubtitle()}
        buttonVariant="approval-green"
        buttonLabel={t(TRANSLATION_KEYS[gallectionType].buttonCTA)}
        biData={BiData<SubmitButtonClickBiEvent>({
          evid: 704,
          sectionname: gallectionType,
        })}
        onButtonClick={this.onButtonClick}
        withButton={canSubmitDeviations}
        customSecondaryButton={this.getSecondaryButton()}
        buttonAlignment="vertical"
      />
    );
  }

  onButtonClick = () => {
    if (this.props.gallectionType === GallectionType.GALLERY) {
      this.navigateToSubmit();
    } else {
      this.navigateToBrowse();
    }
  };

  navigateToSubmit() {
    const { owner } = this.props;
    window.location.replace(getSubmitUrlForProfileOwner(owner));
  }

  navigateToBrowse() {
    window.location.replace(URLS.ROOT);
  }

  openMediaSelector = () => {
    this.setState({ isMediaSelectorOpen: true });
  };

  closeMediaSelector = () => {
    this.setState({ isMediaSelectorOpen: false });
  };

  getSecondaryButton() {
    const {
      gallectionType,
      owner,
      t,
      canSubmitDeviations,
      contributeDeviations,
    } = this.props;
    const { isMediaSelectorOpen } = this.state;
    const isGroupGallery =
      gallectionType === GallectionType.GALLERY && isGroup(owner);

    if (!canSubmitDeviations || !isGroupGallery) {
      return null;
    }

    return (
      <>
        <GhostButton
          variant="main"
          size="medium"
          onClick={this.openMediaSelector}
        >
          {t(
            canSubmitDeviations
              ? 'groups.submit.contributeExistingDeviation'
              : 'groups.submit.recommendExistingDeviation'
          )}
        </GhostButton>
        {isMediaSelectorOpen && (
          <MediaSelectorWithGroupGallection
            group={owner}
            allowUploads={false}
            mediaSourceTypes={[
              MediaSourceType.GALLERY,
              MediaSourceType.COLLECTION,
              MediaSourceType.JOURNAL,
              MediaSourceType.STATUS,
              MediaSourceType.BROWSE,
            ]}
            onClose={this.closeMediaSelector}
            onSelect={(...args) => {
              contributeDeviations(...args);
              this.closeMediaSelector();
              // reload to show newly added deviations on the page
              window.location.reload();
            }}
          />
        )}
      </>
    );
  }

  getSubtitle() {
    const { gallectionType, t, owner, canSubmitDeviations } = this.props;
    if (canSubmitDeviations) {
      return gallectionType === GallectionType.COLLECTION ? (
        <>
          {/* prettier-ignore */}
          <Trans i18nKey={TRANSLATION_KEYS[gallectionType].subtitleOwner}>
            Simply star{' '}
            <FavouriteOutfilled
              size={IconSize.SMALL}
              className={s['star']}
            />{' '}
            your favourite deviations as you browse, to show other deviants what you love.
          </Trans>
        </>
      ) : (
        t(TRANSLATION_KEYS[gallectionType].subtitleOwner)
      );
    }

    return t(TRANSLATION_KEYS[gallectionType].subtitleVisitor, {
      username: owner.username,
    });
  }
}

export default withFeatureFlags(withTranslation()(GallectionsEmptyState));
