import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeOptions } from '@wix/da-react-context/pkg/constants';
import SimpleTooltipContent from '@wix/da-ds/pkg/tooltips/SimpleTooltipContent';
import PopperPositionedPopover from '@wix/da-ds/pkg/popper/PopperPositionedPopover';
import PopoverWrapper from '@wix/da-ds/pkg/popper/PopoverWrapper';
import DiscordIcon from '@wix/da-ds/pkg/Icons/16x16/Discord';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';

import s from './FtueTooltip.scss';

export interface Props {
  onClose: () => void;
}

const FtueTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onClose,
}) => {
  const { t } = useTranslation();
  const renderPopover = useCallback(
    ({
      effectivePlacement,
      effectiveSecondaryPlacement,
      rootRef,
      arrowRef,
      contentStyle,
      arrowStyle,
      contentAttributes,
      arrowAttributes,
      updatePositioning,
    }) => (
      <PopoverWrapper
        contentClassName={s['popover-wrapper']}
        className={s['popover-root']}
        width={150}
        effectivePlacement={effectivePlacement}
        effectiveSecondaryPlacement={effectiveSecondaryPlacement}
        rootRef={rootRef}
        arrowRef={arrowRef}
        contentStyle={contentStyle}
        arrowStyle={arrowStyle}
        contentAttributes={contentAttributes}
        arrowAttributes={arrowAttributes}
        arrowClassName={s[`arrow-${effectivePlacement}`]}
        updatePositioning={updatePositioning}
        forceTheme={ThemeOptions.DARK}
      >
        <SimpleTooltipContent
          className={s['content']}
          onClick={onClose}
          prefix={
            <DiscordIcon
              size={IconSize.SMALLER}
              disableDefaultColors
              className={s['icon']}
            />
          }
        >
          {t('widgets.tierContent.tierActions.discord.ftue.tooltip')}
        </SimpleTooltipContent>
      </PopoverWrapper>
    ),
    [onClose, t]
  );

  return (
    <PopperPositionedPopover
      isVisible
      renderPopover={renderPopover}
      placement="bottom"
    >
      {children}
    </PopperPositionedPopover>
  );
};
FtueTooltip.displayName = 'FtueTooltip';

export default FtueTooltip;
