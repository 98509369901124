import { XhrRequestOptions, requestPuppy } from '@wix/da-http-client';
import { AxiosRequestConfig } from 'axios';
import { call, select, put } from 'redux-saga/effects';
import { getProfileGruser } from '../selectors/users';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule as updateModuleNewGruser } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { ModuleName } from '@wix/da-gruser-shared/pkg/types/modules';
import {
  getModuleDataByModuleName as getModuleDataByModuleNameNewGruser,
  getModuleIdByModuleName as getModuleIdByModuleNameNewGruser,
} from '@wix/da-gruser-shared/pkg/redux/selectors/modules';

export function* requestPuppyGruser(
  options: XhrRequestOptions,
  fakeResponse?: (requestOptions: AxiosRequestConfig) => any,
  papiBase: string = 'dashared'
) {
  const gruser: Gruser = yield select(getProfileGruser);

  if (options.data) {
    options.data = {
      gruserid: gruser.gruserId,
      gruser_typeid: gruser.gruserTypeid,
      ...options.data,
    };
  }
  if (options.params) {
    options.params = {
      gruserid: gruser.gruserId,
      gruser_typeid: gruser.gruserTypeid,
      ...options.params,
    };
  }
  return yield call(requestPuppy, options, fakeResponse, papiBase);
}

export function* updateModuleDataByModuleType<T extends object>(
  moduleName: ModuleName,
  updateCallback: (moduleData: T) => T | undefined
) {
  const gruser = yield select(getProfileGruser);
  const moduleData = yield select(
    getModuleDataByModuleNameNewGruser,
    gruser,
    moduleName
  );

  if (moduleData) {
    const newModuleData = updateCallback(moduleData);
    const id = yield select(
      getModuleIdByModuleNameNewGruser,
      gruser,
      moduleName
    );
    if (newModuleData) {
      yield put(
        updateModuleNewGruser(gruser, { id, moduleData: newModuleData })
      );
    }
  }
}
