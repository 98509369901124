import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';

import { Url } from '@wix/da-url';
import { PapiModuleGroupAdmins } from '@wix/da-papi-types';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';

import { isCurrentUserProfileOwner } from '../../../selectors/users';
import EmptyState from '../../EmptyState';
import UserGrid from '../../_partials/UserGrid';

import s from './Admins.scss';

export interface Props {
  moduleId: number;
}

export const Admins: React.FC<Props> = ({ moduleId }) => {
  const { t } = useTranslation();
  const { moduleData } = useModuleData<PapiModuleGroupAdmins>(moduleId);
  const { results = [], roles = [] } = moduleData;
  const isEmpty = !results?.length;
  const isOwner = useSelector(isCurrentUserProfileOwner);

  if (isEmpty && isOwner) {
    const navigateToDyW = () => {
      window.location.replace(Url.browseDeviantsYouWatchLink());
    };

    return (
      <EmptyState
        view="small"
        className={s['empty-state']}
        title={t(`widgets.group_admins.empty.title.owner`)}
        subtitle={t(`widgets.group_admins.empty.subtitle.owner`)}
        buttonLabel={t(`widgets.group_admins.empty.CTALabel.owner`)}
        withButton={true}
        onButtonClick={navigateToDyW}
      />
    );
  }

  return (
    <>
      {roles.map(({ typeId: roleId, name }) => {
        const users = results
          .filter(({ typeId }) => typeId === roleId)
          .map(({ user }) => user);
        if (users.length === 0) {
          return null;
        }
        return (
          <div key={roleId} className={s['role-group']}>
            <h2 className={s['role-title']}>{capitalize(name)}</h2>
            <UserGrid users={users} />
          </div>
        );
      })}
    </>
  );
};

export default Admins;
