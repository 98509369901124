import React, { useContext } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { BiEvent } from '@wix/da-bi/pkg/events';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import PromoBadge from '@wix/da-ds/pkg/Badges/PromoBadge';
import {
  BannerLayout,
  BannerMessage,
  BannerButton,
} from '@wix/da-ds/pkg/Banner/lego';
import s from './SubscriptionBanner.scss';

interface Props {
  onCtaClick: () => void;
  ctaBiData: BiEvent;
  className?: string;
}

const SubscriptionBanner: React.FC<Props> = ({
  onCtaClick,
  ctaBiData,
  className,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  return (
    <BannerLayout
      size={isMobile ? 'multiline' : 'big'}
      className={c(s['root'], className)}
      body={[
        isMobile ? null : <PromoBadge key="badge" badgeType="tier" size={24} />,
        <BannerMessage key="message" weight={'bld'}>
          {t('page.subscriptions.promoStrip.tipJar.message')}
        </BannerMessage>,
      ]}
      postfix={
        <BannerButton variant={'ghost'} onClick={onCtaClick} biData={ctaBiData}>
          {t('page.subscriptions.promoStrip.tipJar.CTAbutton')}
        </BannerButton>
      }
    />
  );
};

SubscriptionBanner.displayName = 'SubscriptionBanner';

export default SubscriptionBanner;
