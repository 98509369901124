import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { BiData, TierAddNewBiEvent } from '@wix/da-bi/pkg/events';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../../EmptyState';
import {
  getProfileOwner,
  isCurrentUserProfileOwner,
} from '../../../../selectors/users';

import s from './TiersEmptyState.scss';

export type Props = {
  view: 'promotion' | 'product';
};

const TiersEmptyState: React.FC<Props> = ({ view }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useContext(MobileContext);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const profileOwner = useSelector(getProfileOwner);
  const createTier = useCallback(
    () =>
      dispatch(
        pushModal(ModalType.TIER_EDIT, {
          wrapInModal: false,
        })
      ),
    [dispatch]
  );

  return isOwner ? (
    <EmptyState
      view="small"
      className={classnames(s['empty-state'], s['is-owner'])}
      title={t(`widgets.tiers.empty.title.owner`)}
      subtitle={t(`widgets.tiers.empty.subtitle.owner`)}
      buttonLabel={t(`widgets.tiers.empty.CTALabel.owner`)}
      withButton={!isMobile}
      onButtonClick={createTier}
      biData={BiData<TierAddNewBiEvent>({
        evid: 530,
        is_empty_state_view: 1,
        widgetname: view === 'promotion' ? 'tiers' : undefined,
      })}
    />
  ) : (
    <EmptyState
      view="small"
      className={s['empty-state']}
      titleClassName={'empty-state-title'}
      title={t(`widgets.tiers.empty.title.visitor`, {
        username: profileOwner?.user?.username,
      })}
      subtitle={t(`widgets.tiers.empty.subtitle.visitor`, {
        username: profileOwner?.user?.username,
      })}
    />
  );
};

TiersEmptyState.displayName = 'TiersEmptyState';

export default TiersEmptyState;
