import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { DeviationResourceType } from '@wix/da-shared-react/pkg/types/deviation';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { PapiModuleTiers } from '@wix/da-papi-types';
import Tiers from '../../Sections/SubscriptionsSection/widgets/Tiers';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { Url } from '@wix/da-url';
import TiersEmptyState from './TiersEmptyState';
import { BiData, ViewSubscriptionTabBiEvent } from '@wix/da-bi/pkg/events';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const TiersPromoBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const logBiEvent = useBiLogger();
  const {
    moduleData: { tiers = [] },
  } = useModuleData<PapiModuleTiers>(moduleId);
  const count = tiers.length;
  const isEmpty = count === 0;
  const [selectedTierId, setSelectedTierId] = useState(
    isEmpty ? undefined : tiers[0].deviationId
  );

  return (
    <BiLoggerContextProvider
      value={{
        referral_info: ReferralType.HOME_WIDGET,
        widgetname: 'tiers_promotion',
      }}
    >
      <WidgetBox
        moduleId={moduleId}
        header={t('widgets.tiers.header', { count })}
        count={count > 1 ? count : undefined}
        {...widgetBoxProps}
      >
        {isEmpty ? (
          <TiersEmptyState view="promotion" />
        ) : (
          <Tiers
            moduleId={moduleId}
            view="promotion"
            onClick={tier => {
              logBiEvent(
                BiData<ViewSubscriptionTabBiEvent>({
                  evid: 547,
                  // seems redundant, but logBiEvent doesn't pick up the context above
                  referral_info: ReferralType.HOME_WIDGET,
                  typeid: DeviationResourceType.TIER,
                  itemid: tier.deviationId,
                })
              );
              window.location.href = Url.userSubscriptionsPath({
                username: tier.author!.username,
                tierDeviationId: tier.deviationId,
              });
            }}
            onSelect={tier => setSelectedTierId(tier.deviationId)}
            selectedTierId={selectedTierId}
          />
        )}
      </WidgetBox>
    </BiLoggerContextProvider>
  );
};
TiersPromoBox.displayName = 'TiersPromoBox';

export default TiersPromoBox;
