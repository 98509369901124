import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import MyBadgesBoxWide from './MyBadgesBoxWide';
import initActionsCreator from './redux/initModule';

export const getMyBadgesWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: MyBadgesBoxWide,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-2-badges.svg',
    title: t('widgets.badges_activity.addButtonHeader'),
    description: t('widgets.badges_activity.description'),
  },
  initActionsCreator,
});
