import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import PremiumContentSlider from './PremiumContentSlider';
import premiumContentInit from './redux/premiumContentInit';

export const getPremiumContentSliderWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent: PremiumContentSlider,
  initActionsCreator: premiumContentInit,
});
