export const MAXIMUM_GIF_INPUT_FILESIZE = Math.pow(2, 20); // ~1Mb
export const MAXIMUM_GIF_AVATAR_FILESIZE = 102400;
export const MAXIMUM_AVATAR_FILESIZE = 20480;

export const AVATAR_WIDTH = 100;
export const AVATAR_HEIGHT = 100;
export const GROUP_AVATAR_WIDTH = 100;
export const GROUP_AVATAR_HEIGHT = 50;
export const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
export const ACCEPTED_FILE_TYPES_STRING = ACCEPTED_FILE_TYPES.join(', ');
export const IMAGE_QUALITY = 0.92;
