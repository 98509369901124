import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import GetPointsLink from './GetPointsLink';
import WhatArePointsLink from './WhatArePointsLink';
import s from './FooterExtras.scss';

export type Props = {
  className?: string;
};

export const FooterExtras: React.FC<Props> = ({ className }) => {
  const isMobile = useContext(MobileContext);
  if (isMobile) {
    return (
      <div className={s['root']}>
        <GetPointsLink />
        <WhatArePointsLink />
      </div>
    );
  }
  return <GetPointsLink />;
};
FooterExtras.displayName = 'FooterExtras';

export default FooterExtras;
