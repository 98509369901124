import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import AboutMe from '../AboutMe';
import { AboutMeMode } from '../types';
import { USER_PROFILE_SECTION_PATHS, Url } from '@wix/da-url';
import { getProfileOwnerUser } from '../../../../selectors/users';
import { useSelector } from 'react-redux';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const AboutMeBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();

  const { username } = useSelector(getProfileOwnerUser);

  return (
    <WidgetBox
      moduleId={moduleId}
      header={t('widgets.aboutMe.headerWithUsername', {
        username: username,
      })}
      seeAllUrl={Url.userLink(username, `${USER_PROFILE_SECTION_PATHS.ABOUT}`)}
      {...widgetBoxProps}
    >
      <AboutMe moduleId={moduleId} mode={AboutMeMode.CARD} />
    </WidgetBox>
  );
};
AboutMeBox.displayName = 'AboutMeBox';

export default AboutMeBox;
