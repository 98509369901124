import { all, select, put } from 'redux-saga/effects';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { getProfileGruser, getProfileOwner } from '../../selectors/users';
import { getModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { PapiModuleStreamParams } from '../../../types/papi';
import { isApiError } from '@wix/da-http-client';
import { requestPuppyGruser } from '../gruserHelpers';
import { getRawModuleDataFromResponse } from '../../../utils/modules';

export default function* fetchModuleStream(
  action,
  resultsSchema,
  extraParams = {},
  endpoint = ''
) {
  const { streamId } = action;

  const [offset, limit, moduleId, profileOwner, profileGruser] = yield all([
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state => streamSelectors.getItemsPerFetch(state, streamId)),
    select(state =>
      streamSelectors.getStreamParam(state, streamId, 'moduleId')
    ),
    select(getProfileOwner),
    select(getProfileGruser),
  ]);
  const moduleType = yield select(getModuleName, profileGruser, moduleId);

  const params: PapiModuleStreamParams = {
    username: profileOwner.user.username,
    moduleid: moduleId,
    offset,
    limit,
    ...extraParams,
  };

  const url = endpoint || `/module/${moduleType}`;
  const response = yield requestPuppyGruser(
    {
      method: 'get',
      params,
      url,
    },
    undefined,
    'gruser'
  );

  if (response && !isApiError(response)) {
    // Some endpoints return papi_module_data object, and some specific
    // papi_module_(module_name) objects
    const normalizedResponse =
      getRawModuleDataFromResponse(response) || response;
    const { hasMore, nextOffset, results } = normalizedResponse;
    const { result, entities } = normalize(results, [resultsSchema]);

    yield put(
      withOffset.actions.fetchSuccess({
        streamId,
        hasMore,
        nextOffset,
        items: result,
        entities,
      })
    );
  }

  // TODO error catching
}
