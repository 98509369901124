import React, { useContext } from 'react';
import classnames from 'classnames';
import { PapiDeviation, PapiModuleFeaturedDeviation } from '@wix/da-papi-types';
import { getDeviationType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { useModuleName } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import Thumb from './Thumb';
import MediaService from '@wix/da-shared-react/pkg/utils/DeviationMediaService';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';

import s from './FeaturedDeviation.scss';

const MIN_CARD_WIDTH = 300;
const MIN_CARD_HEIGHT = 360;
const MAX_CARD_HEIGHT = 800;
const MOBILE_CARD_HEIGHT = 337;

export interface Props {
  moduleId: number;
  className?: string;
}

const FeaturedDeviation: React.FC<Props> = ({ moduleId, className }) => {
  const moduleName = useModuleName(moduleId);
  const { moduleData } = useModuleData<PapiModuleFeaturedDeviation>(moduleId);
  const { deviation } = moduleData;

  const isMobile = useContext(MobileContext);
  const { ref, dimensions } = useMeasureElementCookie(
    moduleName === 'featuredDeviation'
      ? MeasuredCookieType.FEATURED_DEVIATION
      : MeasuredCookieType.FEATURED_POST,
    { cookieDim: 'width' }
  );

  const { width, height } = calculateBounds(
    dimensions.width || 1000,
    deviation!,
    isMobile
  );

  return (
    <div className={classnames(s['root'], className)} ref={ref}>
      {deviation && (
        <Thumb deviation={deviation} width={width} height={height} />
      )}
    </div>
  );
};

function calculateBounds(
  containerWidth: number,
  deviation: PapiDeviation,
  isMobile: boolean
) {
  const type = getDeviationType(deviation);
  const preview = MediaService.getDeviationFile(deviation, 'preview');
  if (
    !preview ||
    type === DeviationTypes.JOURNAL ||
    type === DeviationTypes.LITERATURE
  ) {
    return {
      width: Math.max(containerWidth, MIN_CARD_WIDTH),
      height: MIN_CARD_HEIGHT,
    };
  }

  if (isMobile) {
    return {
      width: Math.max(containerWidth, MIN_CARD_WIDTH),
      height: MOBILE_CARD_HEIGHT,
    };
  }

  const { width: preWidth, height: preHeight } = preview;

  // Fit to width
  const widthRatio = containerWidth / (preWidth || 0);
  let height = Math.floor((preHeight || 0) * widthRatio);
  height = Math.min(height, MAX_CARD_HEIGHT);

  return {
    width: Math.max(containerWidth, MIN_CARD_WIDTH),
    height: Math.min(preHeight || height, Math.max(height, MIN_CARD_HEIGHT)),
  };
}

FeaturedDeviation.displayName = 'FeaturedDeviation';

export default FeaturedDeviation;
