import React from 'react';
import AddItem, { Props as AddItemProps } from '@wix/da-ds/pkg/AddItem';
import c from 'classnames';
import s from './AddItemCard.scss';

export interface Props extends AddItemProps {
  hasCoreMembership?: boolean;
  autoWidth?: boolean;
  height?: number;
  nonCoreMembershipView?: React.ReactNode;
  view?: string;
}

export const AddItemCard: React.FC<Props> = ({
  hasCoreMembership,
  height,
  autoWidth,
  nonCoreMembershipView,
  view = 'large',
  ...props
}) => {
  if (nonCoreMembershipView && !hasCoreMembership) {
    return <div className={s['non-core-card']}>{nonCoreMembershipView}</div>;
  }

  return (
    <div
      className={c(s['root'], autoWidth && s['auto-width'], s[view])}
      style={{ height }}
    >
      <AddItem
        className={s['add-item-card']}
        titleClassName={s['title']}
        {...props}
      />
    </div>
  );
};
AddItemCard.displayName = 'AddItemCard';
export default AddItemCard;
