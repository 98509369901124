import React from 'react';

import s from './LlamaAnimation.scss';

export interface Props {
  animation?: string;
}

const LlamaAnimation: React.FC<React.PropsWithChildren<Props>> = ({
  animation,
  children,
}) => {
  switch (animation) {
    case 'hopping':
      return (
        <div className={s['duration-6000']}>
          <div className={s['walk']}>
            <div className={s['hop']}>
              <div className={s['flip']}>{children}</div>
            </div>
          </div>
        </div>
      );
    case 'gifted':
      return (
        <div className={s['duration-1000']}>
          <div className={s['gifted']}>
            <div className={s['duration-6000']}>
              <div className={s['walk']}>
                <div className={s['hop']}>
                  <div className={s['flip']}>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 'bounce':
      return (
        <div className={s['duration-3000']}>
          <div className={s['bounce']}>{children}</div>
        </div>
      );
    case 'wiggle':
      return (
        <div className={s['duration-300']}>
          <div className={s['wiggle']}>{children}</div>
        </div>
      );
    case 'remove':
      return (
        <div className={s['duration-1000']}>
          <div className={s['remove']}>{children}</div>
        </div>
      );
    default:
      return <div>{children}</div>;
  }
};

LlamaAnimation.displayName = 'LlamaAnimation';

export default LlamaAnimation;
