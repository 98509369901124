import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../../types/store';
import { getProfileOwnerIsWatcher } from '../../../selectors/users';
import { SubNavBarProps as Props } from '../SubNavBar';

export type StateProps = Pick<Props, 'isProfileOwnerWatchingCurrentUser'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps = (state: AppState, props: OwnProps): StateProps => ({
  isProfileOwnerWatchingCurrentUser: getProfileOwnerIsWatcher(state),
});
const mapDispatchToProps: DispatchProps = {};

export default connect(mapStateToProps, mapDispatchToProps) as (
  component: ComponentType<Props>
) => ComponentType<OwnProps>;
