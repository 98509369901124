import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './SearchEmptyState';
import { AppState } from '../../../../../../types/store';
import { clearSearch } from '../../../../../actions/gallectionSection';

export type DispatchProps = Pick<Props, 'clearSearch'>;
export type OwnProps = Omit<Props, keyof (DispatchProps)>;

const mapStateToProps: MapStateToProps<{}, OwnProps, AppState> = state => ({});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  clearSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
