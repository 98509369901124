import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import SubscriptionCenter from './SubscriptionCenter';

import s from './SubscriptionCenterBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const SubscriptionCenterBox: React.FC<Props> = ({
  moduleId,
  widgetBoxProps,
}) => {
  const { t } = useTranslation();
  return (
    <WidgetBox
      moduleId={moduleId}
      header={t('widgets.subscription_center.header')}
      className={s['root']}
    >
      <SubscriptionCenter
        moduleId={moduleId}
        className={s['subscription-center']}
      />
    </WidgetBox>
  );
};

SubscriptionCenterBox.displayName = 'SubscriptionCenterBox';
export default SubscriptionCenterBox;
