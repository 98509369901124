import { Action } from 'redux-actions';
import { PapiModuleFeed } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { actions as threadActions } from '@wix/da-shared-react/pkg/CommentThread';
import { getCommentableTypeForDeviationType } from '@wix/da-shared-react/pkg/DeviationPage/redux/helpers';
import {
  FEED_COMMENTS_MAX_DEPTH,
  FEED_COMMENTS_ITEMS_PER_FETCH,
} from '../../../../streams/posts';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleFeed,
  requestParams: any
): Action<any>[] => {
  const { results } = moduleData;

  return [
    ...(results || []).map(deviation =>
      threadActions.initializeThread({
        thread: {
          commentableItemId: deviation.deviationId,
          commentableTypeId: getCommentableTypeForDeviationType(deviation),
          items: [],
          hasMore: !!deviation.stats?.comments,
          total: deviation.stats?.comments ?? 0,
          canPostComment: !!deviation.isCommentable,
        },
        maxDepth: FEED_COMMENTS_MAX_DEPTH,
        itemsPerFetch: FEED_COMMENTS_ITEMS_PER_FETCH,
      })
    ),
  ];
};
