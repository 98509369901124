import { SagaIterator } from 'redux-saga';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  setMySubmissionsSortBy,
  setMySubmissionsIsLoading,
  setMySubmissionsStats,
  removeBenchmarkUser,
  addBenchmarkUser,
  sendQuery,
  resultUserQuery,
  addBenchmarkUserSuccess,
  fetchRecentVisitors,
  fetchRecentVisitorsSuccess,
} from '../actions/statsSection';
import { getProfileOwnerUser } from '../selectors/users';
import { MySubmissionsSortBy } from '../../types/statsSection';
import { requestPuppyGruser } from './gruserHelpers';

function* fetchFromApi(requestUrl, params) {
  return yield call(
    requestPuppy,
    {
      method: 'get',
      url: requestUrl,
      params,
    },
    undefined,
    'dauserprofile'
  );
}

function* updateBenchmarkUsers(data, type: 'add' | 'remove') {
  return yield call(
    requestPuppy,
    {
      method: 'post',
      url: `/stats/comparelist/${type}`,
      data,
    },
    undefined,
    'dauserprofile'
  );
}

export function* removeBenchmarkUserSaga(
  action: ReturnType<typeof removeBenchmarkUser>
) {
  const userId = action.payload;
  yield call(
    updateBenchmarkUsers,
    {
      userid: userId,
    },
    'remove'
  );
}

export function* addBenchmarkUserSaga(
  action: ReturnType<typeof addBenchmarkUser>
) {
  const userId = action.payload;
  const result = yield call(
    updateBenchmarkUsers,
    {
      userid: userId,
    },
    'add'
  );

  if (result.success) {
    yield put(
      addBenchmarkUserSuccess({
        timePeriods: result.compareYourself.timePeriods,
      })
    );
  }
}

function* fetchFriendsForLetter(search) {
  const result = yield call(requestPuppy, {
    method: 'get',
    url: `/user/friendsforletter`,
    params: {
      search,
      letter: search[0],
      page: 0,
    },
  });
  return result;
}

export function* setMySubmissionsSortBySaga(
  action: ReturnType<typeof setMySubmissionsSortBy>
): SagaIterator {
  const { payload: sortBy } = action;
  const profileOwnerUser = yield select(getProfileOwnerUser);

  yield put(setMySubmissionsIsLoading(true));

  const sortByToSnakeCaseMap = {
    [MySubmissionsSortBy.PublishDate]: 'publish_date',
    [MySubmissionsSortBy.PageViews]: 'page_views',
    [MySubmissionsSortBy.Faves]: 'faves',
    [MySubmissionsSortBy.Comments]: 'comments',
  };

  const params = {
    username: profileOwnerUser.username,
    sort_by: sortByToSnakeCaseMap[sortBy],
  };

  const result = yield call(fetchFromApi, '/stats/my_content', params);

  if (result && !isApiError(result)) {
    yield all([
      put(setMySubmissionsStats(result.myContent)),
      put(setMySubmissionsIsLoading(false)),
    ]);
  }
}

export function* fetchRecentVisitorsSaga(
  action: ReturnType<typeof fetchRecentVisitors>
) {
  const offset = action.payload;
  const result = yield call(
    requestPuppyGruser,
    {
      method: 'get',
      url: '/module/visitors',
      params: { offset },
    },
    undefined,
    'gruser'
  );
  if (result && !isApiError(result)) {
    yield put(fetchRecentVisitorsSuccess(result));
  }
}

export function* sendUserQuery(action: ReturnType<typeof sendQuery>) {
  const query = action.payload;

  if (query.length < 3) {
    yield put(resultUserQuery([]));
    return;
  }

  const result = yield call(fetchFriendsForLetter, query);

  if (result.error || !result.users || result.users.length === 0) {
    return;
  }

  const regexp = new RegExp(`^${query}`, 'i');
  const foundUsers = result.users.filter(user => user.username.match(regexp));

  yield put(
    resultUserQuery(
      // unique
      foundUsers.filter(
        (user, index) =>
          foundUsers.findIndex(u => u.userId === user.userId) === index
      )
    )
  );
}

export default function* statsSectionSaga() {
  yield all([
    takeEvery(setMySubmissionsSortBy, setMySubmissionsSortBySaga),
    takeEvery(removeBenchmarkUser, removeBenchmarkUserSaga),
    takeEvery(addBenchmarkUser, addBenchmarkUserSaga),
    takeEvery(fetchRecentVisitors, fetchRecentVisitorsSaga),
    takeLatest(sendQuery, sendUserQuery),
  ]);
}
