import React, { useEffect, useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import Pagination from '@wix/da-shared-react/pkg/Pagination';
import { PapiDeviation } from '@wix/da-papi-types';
import OnScrollBottom, {
  DISTANCE_UNIT,
} from '@wix/da-shared-react/pkg/OnScrollBottom';
import FoldersHeader from '../FoldersHeader';
import FolderSlider from '../FolderSlider';
import GallectionDeviationsTorpedo from '../GallectionDeviationsTorpedo';
import SearchResultsBar from './SearchResultsBar';
import SearchEmptyState from './SearchEmptyState';
import {
  GallectionSortOrder,
  GallectionType,
} from '../../../../../types/gallection';
import generatePaginationUrl from '../../../../utils/generatePaginationUrl';
import MobileGallectionHeader from '../MobileGallectionHeader';

import s from './GallectionSearchView.scss';

export type Props = {
  streamId: string;
  streamIsInitialized: boolean;
  streamItems: PapiDeviation[];
  streamHasMore: boolean;
  streamIsFetching: boolean;
  streamTotalCount: number;
  streamSearchQuery: string;
  streamSortOrder: GallectionSortOrder;
  streamInit: (
    searchQuery: string,
    sortOrder: GallectionSortOrder,
    gallectionType: GallectionType,
    page?: number
  ) => void;
  streamFetchMore: (gallectionType: GallectionType) => void;
  searchQuery: string;
  gallectionType: GallectionType;
  sortOrder: GallectionSortOrder;
  isPaginated: boolean;
  currentPage: number;
  hideFolderSlider?: boolean;
};

export type GallectionSearchViewProps = Props;

export const GallectionSearchView: React.FC<GallectionSearchViewProps> = ({
  searchQuery,
  streamSearchQuery,
  sortOrder,
  streamSortOrder,
  streamInit,
  gallectionType,
  streamFetchMore,
  streamItems,
  streamHasMore,
  streamIsFetching,
  streamIsInitialized,
  streamTotalCount,
  streamId,
  isPaginated,
  currentPage,
  hideFolderSlider,
}) => {
  // re-init stream if search query changed
  useEffect(() => {
    if (searchQuery !== streamSearchQuery || sortOrder !== streamSortOrder) {
      streamInit(searchQuery, sortOrder, gallectionType, currentPage);
      streamFetchMore(gallectionType);
    }
  }, [
    searchQuery,
    streamSearchQuery,
    sortOrder,
    streamSortOrder,
    streamInit,
    gallectionType,
    streamFetchMore,
    currentPage,
  ]);
  const isMobile = useContext(MobileContext);

  const isLoadingFirstResults = !streamItems.length && streamHasMore;
  const isLoadingMore = streamItems.length > 0 && streamIsFetching;
  const isLoading =
    isLoadingFirstResults || isLoadingMore || !streamIsInitialized;

  const isEmpty = streamIsInitialized && !streamItems.length && !streamHasMore;

  const onScrollBottom = () => {
    streamHasMore && !streamIsFetching && streamFetchMore(gallectionType);
  };

  return (
    <ErrorBoundary debugComponent="GallectionSearchView">
      {!isMobile && <FoldersHeader />}
      {!isMobile && !hideFolderSlider && <FolderSlider />}
      {isMobile ? (
        <MobileGallectionHeader
          searchQuery={searchQuery}
          resultsTotal={streamTotalCount}
        />
      ) : (
        <SearchResultsBar
          searchQuery={searchQuery}
          resultsTotal={streamTotalCount}
          isSearching={isLoadingFirstResults}
        />
      )}
      {!isPaginated && streamItems.length > 0 && (
        <OnScrollBottom
          distanceToBottom={1}
          distanceUnit={DISTANCE_UNIT.WINDOW_HEIGHT}
          onScrolledToBottom={onScrollBottom}
        >
          <div /* needed for OnScrollBottom */>
            <GallectionDeviationsTorpedo
              streamId={streamId}
              gallectionType={gallectionType}
              deviations={streamItems}
            />
          </div>
        </OnScrollBottom>
      )}
      {isPaginated && streamItems.length > 0 && (
        <div>
          <GallectionDeviationsTorpedo
            streamId={streamId}
            gallectionType={gallectionType}
            deviations={streamItems}
          />
          <div className={s['pagination-wrapper']}>
            <Pagination
              showPageNumbers={false}
              currentPage={currentPage}
              totalPages={streamHasMore ? currentPage + 1 : currentPage}
              getPageUrl={page => generatePaginationUrl(page)}
            />
          </div>
        </div>
      )}
      {isEmpty && <SearchEmptyState />}
      {isLoading && <LoadingIndicator />}
    </ErrorBoundary>
  );
};
GallectionSearchView.displayName = 'GallectionSearchView';

export default GallectionSearchView;
