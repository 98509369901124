import { PageTypeState } from '../../types/pageType';
import { setPageType } from '../actions/pageType';

const defaultState = {
  pageType: 'profile',
} as PageTypeState;

export default function sections(state = defaultState, action): PageTypeState {
  const { type, payload } = action;
  switch (type) {
    case setPageType.toString():
      return {
        ...state,
        pageType: payload.pageType,
      };
    default:
      return state;
  }
}
