import { getExhibitionistGridWidgetConfig } from '@wix/da-shared-react/pkg/widgets/Exhibitionist/widgetConfig';
import {
  getFeaturedDeviationWidgetConfig,
  getFeaturedPostWidgetConfig,
} from '../../UserWidgets/FeaturedDeviation/widgetConfig';
import {
  getWatchersWidgetConfig,
  getWatchingWidgetConfig,
} from '../../UserWidgets/WatchedDeviants/widgetConfig';
import { getSupportersWidgetConfig } from '../../UserWidgets/Supporters/widgetConfig';
import {
  getGroupListAdminsWidgetConfig,
  getGroupListMembersWidgetConfig,
} from '../../UserWidgets/GroupList/widgetConfig';
import { getFeedWidgetConfig } from '../../UserWidgets/Feed/widgetConfig';
import { getProfileCommentsWidgetConfig } from '../../UserWidgets/ProfileComments/widgetConfig';
import { getLlamaFarmWidgetConfig } from '../../UserWidgets/LlamaFarm/widgetConfig';
import { getDonationsWidgetConfig } from '../../UserWidgets/Donations/widgetConfig';
import { getGroupAdminsWidgetConfig } from '../../GroupWidgets/Admins/widgetConfig';
import getActiveMembersWidgetConfig from '../../GroupWidgets/ActiveMembers/widgetConfig';
import { getGroupMembersWidgetConfig } from '../../GroupWidgets/GroupMembers/widgetConfig';
import getNewestMembersWidgetConfig from '../../GroupWidgets/NewestMembers/widgetConfig';
import getGroupAboutWidgetConfig from '../../GroupWidgets/GroupAbout/widgetConfig';
import { getTiersPromoWidgetConfig } from '../../UserWidgets/TiersPromoBox/widgetConfig';
import { getCustomWidgetConfig } from '../../UserWidgets/Custom/widgetConfig';
import { getMegaboxWidgetConfig } from '../../UserWidgets/Megabox/widgetConfig';
import getCoverDeviationWidgetConfig from '../../UserWidgets/CoverDeviation/widgetConfig';
import { getAboutMeWidgetConfig } from '../../UserWidgets/AboutMe/widgetConfig';
import { getBadgesSpotlightConfig } from '../../UserWidgets/BadgesSpotlight/widgetConfig';
import { getDeviousnessConfig } from '../../UserWidgets/Deviousness/widgetConfig';

export const getHomeWidgetConfigs = ({ t, dispatch, isGroup = false }) => ({
  deviousness: getDeviousnessConfig({ t, dispatch }),
  coverDeviation: getCoverDeviationWidgetConfig({ t, dispatch }),
  megabox: getMegaboxWidgetConfig({ t, dispatch }),
  tiers: getTiersPromoWidgetConfig({ t, dispatch }),
  groupAbout: getGroupAboutWidgetConfig({ t, dispatch }),
  about: getAboutMeWidgetConfig({ t, dispatch }),
  exhibitionist: getExhibitionistGridWidgetConfig({ t, dispatch, isGroup }),
  featuredDeviation: getFeaturedDeviationWidgetConfig({ t, dispatch, isGroup }),
  watchers: getWatchersWidgetConfig({ t, dispatch }),
  watching: getWatchingWidgetConfig({ t, dispatch }),
  supporters: getSupportersWidgetConfig({ t, dispatch }),
  groupListMembers: getGroupListMembersWidgetConfig({ t, dispatch }),
  groupListAdmins: getGroupListAdminsWidgetConfig({ t, dispatch }),
  llamaFarm: getLlamaFarmWidgetConfig({ t, dispatch }),
  groupAdmins: getGroupAdminsWidgetConfig({ t, dispatch }),
  activeMembers: getActiveMembersWidgetConfig({ t, dispatch }),
  groupMembers: getGroupMembersWidgetConfig({ t, dispatch }),
  newestMembers: getNewestMembersWidgetConfig({ t, dispatch }),
  papiCustombox: getCustomWidgetConfig({ t, dispatch }),
  featuredPost: getFeaturedPostWidgetConfig({ t, dispatch, isGroup }),
  feed: getFeedWidgetConfig({ t, dispatch }),
  donations: getDonationsWidgetConfig({ t, dispatch }),
  comments: getProfileCommentsWidgetConfig({ t, dispatch, isGroup }),
  badgesSpotlight: getBadgesSpotlightConfig({ t, dispatch }),
});
