import React from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  className?: string;
  deviationCount: number;
  numSubscribers?: number;
}

export const FolderInfo: React.FC<Props> = ({
  className,
  deviationCount,
  numSubscribers,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {deviationCount} {t('common.deviations', { count: deviationCount })}
      {numSubscribers !== undefined && (
        <>
          {' '}
          | {numSubscribers} {t('common.supporters', { count: numSubscribers })}
        </>
      )}
    </div>
  );
};
FolderInfo.displayName = 'FolderInfo';

export default FolderInfo;
