import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import {
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { Props } from './AvatarBirthdayBadge';

export type StateProps = Pick<Props, 'isOwner' | 'profileOwnerUser'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    isOwner: isCurrentUserProfileOwner(state),
    profileOwnerUser: getProfileOwnerUser(state),
  };
};

export default connect(mapStateToProps);
