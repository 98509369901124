import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import MegaboxBox from './MegaboxBox';
import MegaboxConfigPopoverLazy from './MegaboxConfigPopover/MegaboxConfigPopoverLazy';

export const getMegaboxWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: MegaboxBox,
  configPopoverComponent: MegaboxConfigPopoverLazy,
  autoShowWidgetConfig: true,
  confirmRemove: () =>
    defaultRemoveConfirmation({ t, dispatch, willDestroyContent: true }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-21-megabox-custom.svg',
    title: t('widgets.megabox.addButtonHeader'),
    description: t('widgets.megabox.description'),
  },
});
