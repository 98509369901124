// CR49
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { PapiModuleDeviousness } from '@wix/da-papi-types';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import InfoIcon from '@wix/da-ds/pkg/Icons/24x24/Info';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { formatDate, DATE_FORMATS } from '@wix/da-ds/pkg/Time/functions';

import LoadableLightningOverlay from './LightningOverlay';
import s from './Deviousness.scss';

export interface Props {
  className?: string;
  moduleId: number;
}

const Deviousness: React.FC<Props> = ({ className, moduleId }) => {
  const { t } = useTranslation();
  const {
    moduleData: { dateAwarded, description, awardType },
  } = useModuleData<PapiModuleDeviousness>(moduleId);

  return (
    <div
      className={classnames(
        s['root'],
        awardType === 'bolt_award' && s['bolt-award'],
        className
      )}
    >
      <div className={s['box']}>
        <a
          className={s['info-link']}
          href="https://www.deviantartsupport.com/en/article/what-is-the-deviousness-award"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <InfoIcon
            className={s['info-icon']}
            size={IconSize.MEDIUM}
            condensed
          />
        </a>
        {awardType === 'bolt_award' && (
          <LoadableLightningOverlay
            className={s['overlay']}
            width={180}
            height={180}
          />
        )}
        <div className={s['crown']} />
        <div className={s['description']}>
          <div
            className={s['dd-text']}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
        <div className={s['award-date']}>
          {t('widgets.deviousness.awarded', {
            timestamp: formatDate(dateAwarded, DATE_FORMATS.Date.MonthYear),
          })}
        </div>
      </div>
    </div>
  );
};
Deviousness.displayName = 'Deviousness';

export default Deviousness;
