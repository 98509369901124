import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { PapiModuleSubscriptionCenter } from '@wix/da-papi-types';
import {
  setSubscriptionsChartData,
  setSubscriptionsStats,
} from '../../../../../../actions/subscriptionsSection';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleSubscriptionCenter,
  requestParams: any
) => {
  const { overviewSubscriptions, overviewSubscriptionsMonthly } = moduleData;

  return [
    setSubscriptionsStats(overviewSubscriptions?.timePeriods?.P365D as any),
    setSubscriptionsChartData(
      overviewSubscriptionsMonthly?.timePeriods?.P28D as any
    ),
  ];
};
