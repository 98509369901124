import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './PremiumContentEmptyState';
import { AppState } from '../../../../../../types/store';
import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../../../selectors/users';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';

export type StateProps = Pick<Props, 'isOwner' | 'user' | 'hasCoreMembership'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  user: getProfileOwnerUser(state),
  hasCoreMembership: getCurrentUserHasCore(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
