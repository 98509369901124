import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { PapiUser } from '@wix/da-papi-types';
import {
  BiData,
  BiContextProvidedValue,
  LlamaFarmGiftClickBiEvent,
} from '@wix/da-bi/pkg/events';
import BuyButton from '@wix/da-ds/pkg/Buttons/BuyButton';
import LlamaIcon from '@wix/da-ds/pkg/Icons/Llama';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';

import s from './GiveLlamaButton.scss';

export interface Props {
  className?: string;
  isOwner?: boolean;
  canBeGivenLlama?: boolean;
  onLlamaBadgeSent: () => void;
  onLlamaGiven?: () => void;
  initGiveState: () => void;
  pushBadgeModal: typeof modalActions.pushBadgeModal;
  profileOwnerUser: PapiUser;
}

const GiveLlamaButton: React.FC<Props> = ({
  className,
  isOwner,
  canBeGivenLlama,
  onLlamaBadgeSent,
  onLlamaGiven,
  pushBadgeModal,
  profileOwnerUser: { username },
  initGiveState,
}) => {
  const [isInitialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!isOwner && !isInitialized) {
      initGiveState();
      setInitialized(true);
    }
  }, [isOwner, isInitialized, initGiveState]);
  const onClick = useCallback(() => {
    pushBadgeModal(username, onLlamaBadgeSent, onLlamaGiven);
  }, [onLlamaBadgeSent, onLlamaGiven, pushBadgeModal, username]);

  if (isOwner || !canBeGivenLlama) {
    return null;
  }

  return (
    <BuyButton
      className={classnames(s['root'], className)}
      rounded
      size="large"
      onClick={onClick}
      icon={LlamaIcon}
      biData={BiData<LlamaFarmGiftClickBiEvent>({
        evid: 275,
        menu_item_name: 'Give Llama badge',
        widgetname: BiContextProvidedValue,
      })}
    ></BuyButton>
  );
};

GiveLlamaButton.displayName = 'GiveLlamaButton';

export default GiveLlamaButton;
