import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { ZoneName } from '@wix/da-gruser-shared/pkg/types/zones';

export const getMeasuredCookieType = (zone?: ZoneName, position?: number) => {
  // CustomBox Widgets can appear in both the left and the right column of the
  // Home section on UP, as well as in the MEGA_BAR zone, all of which have
  // different widths and should use different MeasuredCookieType
  let measuredCookieType: MeasuredCookieType;
  switch (zone) {
    case 'megaBar':
      switch (position) {
        case 2:
          measuredCookieType = MeasuredCookieType.USER_PROFILE_CUSTOM_TOP_3;
          break;
        case 1:
          measuredCookieType = MeasuredCookieType.USER_PROFILE_CUSTOM_TOP_2;
          break;
        case 0:
        default:
          measuredCookieType = MeasuredCookieType.USER_PROFILE_CUSTOM_TOP_1;
      }
      break;
    case 'topLeft':
    case 'bottomLeft':
      measuredCookieType = MeasuredCookieType.USER_PROFILE_CUSTOM_LEFT;
      break;
    case 'midRight':
    default:
      measuredCookieType = MeasuredCookieType.USER_PROFILE_CUSTOM;
  }
  return measuredCookieType;
};
