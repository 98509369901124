import React, { useContext } from 'react';
import c from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import InfoBlock, { Props as InfoBlockProps } from '@wix/da-ds/pkg/InfoBlock';
import s from './EmptyState.scss';

export interface Props extends InfoBlockProps {
  view?: 'large' | 'small' | 'wide';
}

/** A slightly augmented version of the DS empty state component to keep it
 * backwards compatible with old profiles empty states */
const EmptyState: React.FC<Props> = ({
  view = 'large',
  className,
  ...props
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <InfoBlock {...props} className={c(!isMobile && s[view], className)} />
  );
};
EmptyState.displayName = 'EmptyState';

export default EmptyState;
