import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';

import { PapiUser } from '@wix/da-papi-types';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import { AuthorWatchButton } from '@wix/da-shared-react/pkg/Button';

import s from './MutualConnections.scss';

interface Props {
  user: PapiUser;
  mutual: PapiUser[];
  isWatchingYou: boolean;
  mutualWatchedUsersCount: number;
}

const MutualConnections: React.FC<Props> = ({
  user,
  mutual,
  isWatchingYou,
  mutualWatchedUsersCount,
}) => {
  const { t } = useTranslation();

  if (!isWatchingYou && mutual.length === 0) {
    return null;
  }

  return (
    <div className={classnames(s['root'], 'ds-outline')}>
      {isWatchingYou && (
        <div className={s['watch-wrapper']}>
          <span>
            <UserLink
              className={s['user-link']}
              user={user}
              showSymbol={false}
              linkTo={'about'}
              withTooltip
            />{' '}
            {t('widgets.aboutMe.mutualConnections.isWatchingYou')}
          </span>
          <AuthorWatchButton author={user} isWatchingBack />
        </div>
      )}
      {mutual.length !== 0 && (
        <div className={s['connections-wrapper']}>
          <span className={s['connections']}>
            {/* prettier-ignore */}
            {mutualWatchedUsersCount === 1 && (
              <Trans i18nKey={'widgets.aboutMe.mutualConnections.onlyOne'}>
                You are both watching{' '}
                <UserLink
                  className={s['user-link']}
                  user={mutual[0]}
                  showSymbol={false}
                  withTooltip
                />
              </Trans>
            )}
            {/* prettier-ignore */}
            {mutualWatchedUsersCount >= 2 && (
              <Trans
                i18nKey={
                  mutualWatchedUsersCount > 2
                    ? 'widgets.aboutMe.mutualConnections.twoAndMore'
                    : 'widgets.aboutMe.mutualConnections.onlyTwo'
                }
              >
                You are both watching{' '}
                <UserLink
                  className={s['user-link']}
                  user={mutual[0]}
                  showSymbol={false}
                  withTooltip
                />
                {mutualWatchedUsersCount === 2 ? ' & ' : ', '}
                <UserLink
                  className={s['user-link']}
                  user={mutual[1]}
                  showSymbol={false}
                  withTooltip
                />
                {mutualWatchedUsersCount > 2 && (
                  <>
                    {' & '}
                    {{
                      moreConnections: mutualWatchedUsersCount - 2,
                    }}{' '}
                    more.
                  </>
                )}
              </Trans>
            )}
          </span>
          <div className={s['avatars-list']}>
            {mutual.map((u, index) => (
              <UserAvatar
                size={24}
                key={u.userId}
                className={classnames(
                  s['user-avatar'],
                  index === 2 && s['last-avatar']
                )}
                user={u}
                withTooltip
              />
            ))}
            {mutualWatchedUsersCount > 3 && (
              <div
                className={classnames(s['count-more'], s['count-more-large'])}
              >
                +{mutualWatchedUsersCount - 3}
              </div>
            )}
            {mutualWatchedUsersCount > 2 && (
              <div
                className={classnames(s['count-more'], s['count-more-small'])}
              >
                +{mutualWatchedUsersCount - 2}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

MutualConnections.displayName = 'MutualConnections';

export default MutualConnections;
