import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiGallection } from '@wix/da-papi-types';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import IconButton from '@wix/da-shared-react/pkg/Button/IconButton';
import FolderBarFolderDropdown from '../FolderBar/_partials/FolderBarFolderDropdown';
import WidgetHeaderMobile from '../../../UserWidgets/_partials/WidgetHeaderMobile';
import MobileSearchField from '../MobileSearchField';

import profilesCssVars from '../../../../styles/variables.scss';
import s from './MobileGallectionHeader.scss';

interface Props {
  currentFolder?: PapiGallection;
  searchQuery?: string;
  resultsTotal?: number;
  disableSearch?: boolean;
}

const MobileGallectionHeader: React.FC<Props> = ({
  currentFolder,
  searchQuery,
  resultsTotal,
  disableSearch = false,
}) => {
  const { t } = useTranslation();
  const [showSearchBar, setShowSearchBar] = useState(!!searchQuery);

  return (
    <div className={s['root']}>
      {!disableSearch && showSearchBar && (
        <div className={s['above-header']}>
          <MobileSearchField />
        </div>
      )}
      <WidgetHeaderMobile
        isSticky={false}
        rootClassName={s['widget-header-mobile']}
        header={
          searchQuery ? (
            t('pages.gallections.search.mobile.resultsCount', {
              count: resultsTotal,
            })
          ) : (
            <FolderBarFolderDropdown
              offsetTop={parseInt(profilesCssVars['mobile-navbar-height']) - 2}
              currentFolder={currentFolder}
            />
          )
        }
        extras={
          !disableSearch && (
            <IconButton
              className={s['search-button']}
              onClick={() => setShowSearchBar(!showSearchBar)}
            >
              <SearchIcon size={IconSize.MEDIUM} />
            </IconButton>
          )
        }
      />
    </div>
  );
};
MobileGallectionHeader.displayName = 'MobileGallectionHeader';

export default MobileGallectionHeader;
