import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBoxWide, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';

import { Admins } from './Admins';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const AdminsBoxWide: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  return (
    <WidgetBoxWide
      id="group_admins"
      moduleId={moduleId}
      header={t('widgets.group_admins.header')}
      {...widgetBoxProps}
    >
      <Admins moduleId={moduleId} />
    </WidgetBoxWide>
  );
};
AdminsBoxWide.displayName = 'AdminsBoxWide';

export default AdminsBoxWide;
