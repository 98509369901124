import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DAProtectIconWithTooltip from '@wix/da-shared-react/pkg/DAProtectIconWithTooltip';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import SharedFolderDropdown, {
  Props as FolderDropdownProps,
} from '@wix/da-shared-react/pkg/GallectionFolderDropdown';
import { isCurrentUserProfileOwner } from '../../../../../../selectors/users';
import { useNonEmptyGallectionFolders } from '../../../hooks/useGallectionFolders';
import { setSelectedFolderId } from '../../../../../../actions/gallectionSection';

import s from './FolderBarFolderDropdown.scss';

export interface Props
  extends Omit<
    FolderDropdownProps,
    | 'id'
    | 'folders'
    | 'hasMore'
    | 'fetchMore'
    | 'isLoading'
    | 'setSelectedFolderId'
  > {}

const FolderBarFolderDropdown: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { folders, hasMore, isFetching, fetchAll } =
    useNonEmptyGallectionFolders();
  const handleSetSelectedFolderId = useCallback(
    number => {
      if (window) {
        window.scrollTo(0, 0);
      }
      dispatch(setSelectedFolderId(number));
    },
    [dispatch]
  );

  const hasCore = useSelector(getCurrentUserHasCore);
  const isOwner = useSelector(isCurrentUserProfileOwner);

  return (
    <SharedFolderDropdown
      {...props}
      id={'folders-dropdown'}
      folders={folders}
      hasMore={hasMore}
      isLoading={isFetching}
      fetchMore={fetchAll}
      setSelectedFolderId={handleSetSelectedFolderId}
      showCurrentFolderTotal
      folderNamePostfixIcon={
        isOwner &&
        hasCore && (
          <DAProtectIconWithTooltip
            variant="active"
            className={s['da-protect-icon']}
          />
        )
      }
    />
  );
};
FolderBarFolderDropdown.displayName = 'FolderBarFolderDropdown';

export default FolderBarFolderDropdown;
