import {
  getCanSubmitToGroupGallery,
  getCanSubmitToGroupPosts,
  getCanCreateGroupGalleryFolder,
} from '@wix/da-shared-react/pkg/utils/groupPrivs';
import { canEditModuleType } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { AppState } from '../../types/store';
import { PagePriv } from '../../types/pagePrivileges';
import { ModuleType } from '../../types/modules';
import {
  getIsGroupProfile,
  getProfileGruser,
  getProfileOwnerExtendedGroup,
  isCurrentUserProfileOwner,
} from './users';
import { getIsGroupFounder, getGroupApps } from './groups';
import { canEditModule } from './modules';

export const getPagePrivileges = state => state.pagePrivileges || {};
export const getCanPerformPageAction = (state, action: string) => {
  const pagePrivs = getPagePrivileges(state);
  return action in pagePrivs && pagePrivs?.[action] === true;
};

export const isUserProfileOwner = (state: AppState) =>
  !getIsGroupProfile(state) && isCurrentUserProfileOwner(state);

export const getCanSubmitToGallery = (state: AppState) =>
  isUserProfileOwner(state) ||
  (getIsGroupProfile(state) &&
    getCanSubmitToGroupGallery(getProfileOwnerExtendedGroup(state)));

export const getCanSubmitToPosts = (state: AppState) =>
  isUserProfileOwner(state) ||
  (getIsGroupProfile(state) &&
    getCanSubmitToGroupPosts(getProfileOwnerExtendedGroup(state)));

export const getCanCreateGalleryFolder = (state: AppState) =>
  isUserProfileOwner(state) ||
  (getIsGroupProfile(state) &&
    getCanCreateGroupGalleryFolder(getProfileOwnerExtendedGroup(state)));

export const getCanJoinGroup = state => !!getGroupApps(state)?.join?.canJoin;

export const getCanLeaveGroup = state => !!getGroupApps(state)?.join?.canLeave;

export const getCanChangeFounder = state => !!getIsGroupFounder(state);

export const getCanManageRoles = state =>
  !!getGroupApps(state)?.roster?.canManage;

export const getCanInviteToGroup = state =>
  !!getGroupApps(state)?.join?.canInvite;

export const getCanCloseGroup = state =>
  !!getGroupApps(state)?.roster?.canClose;

export const getCanRespondToNotifications = state =>
  getCanPerformPageAction(state, PagePriv.CAN_RESPOND_TO_GROUP_NOTIFICATIONS);

export const getCanPin = state => {
  const profileGruser = getProfileGruser(state);
  return (
    canEditModuleType(state, profileGruser, 'posts_feed') ||
    canEditModuleType(state, profileGruser, 'feed')
  );
};

// uses privs as pinning
export const getCanFeature = state => getCanPin(state);

export const getCanMessageAdmins = state => true;

export const getCanCustomizePage = state => {
  return canEditModuleType(state, getProfileGruser(state), 'coverDeviation');
};

export const getCanChangeAvatar = (state: AppState) =>
  getCanPerformPageAction(state, PagePriv.CAN_CHANGE_AVATAR);

export const getCanRemovePost = state =>
  getIsGroupProfile(state) && canEditModule(state, ModuleType.POSTS_FEED);
