import React from 'react';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import {
  BiData,
  BiContextProvidedValue,
  UserNameClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import UserGrid from '../../_partials/UserGrid/UserGrid';
import { PapiUser } from '@wix/da-papi-types';

interface Props {
  moduleId: number;
  users: PapiUser[];
}

const WatchedDeviants: React.FC<Props> = ({ moduleId, users }) => {
  const logEvent = useBiLogger();

  return (
    <UserGrid
      users={users}
      onUserClick={user => {
        logEvent(
          BiData<UserNameClickBiEvent>({
            evid: 101,
            sectionname: BiContextProvidedValue,
            widgetname: BiContextProvidedValue,
            typeid: ResourceType.USER,
            itemid: user.userId,
          })
        );
      }}
    />
  );
};
WatchedDeviants.displayName = 'WatchedDeviants';

export default WatchedDeviants;
