import React from 'react';
import c from 'classnames';
import { Trans } from 'react-i18next';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import s from './EmptyGraphic.scss';

interface Props {
  className?: string;
}

/** The graphic + text that will display in the center of the graph when there is not enough data */
const EmptyGraphic: React.FC<Props> = ({ className }) => {
  return (
    <Section className={c(s['root'], className)}>
      <div className={s['graphic']} />
      <Heading className={s['text']}>
        <Trans
          i18nKey={'widgets.subscriptionsSection.graph.empty.graphic.heading'}
        >
          Stay tuned for an awesome graph!
          <br />
          Our llamas are still gathering data about your subscriptions.
        </Trans>
      </Heading>
    </Section>
  );
};
EmptyGraphic.displayName = 'EmptyGraphic';

export default EmptyGraphic;
