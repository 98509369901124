import React, { useCallback, useRef } from 'react';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { useController, useFormContext } from 'react-hook-form';
import TextInput, {
  type TextInputProps,
} from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';

interface Props extends Omit<TextInputProps, 'id' | 'value'> {
  name: string;
  label: string;
  maskingCallback?: (value) => string;
}

function InputField({
  name,
  label = '',
  maskingCallback,
  details,
  ...props
}: Props) {
  const { control, errors, setValue } = useFormContext();
  const error = get(errors, name);
  const { current: id } = useRef(uniqueId('input'));

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  const onChange = useCallback(
    e => {
      if (maskingCallback) {
        setValue(name, maskingCallback(e.target.value));
      }
    },
    [maskingCallback, name, setValue]
  );

  if (maskingCallback) {
    inputProps.onChange = onChange;
  }

  return (
    <TextInput
      id={id}
      details={error?.message || details}
      validationResult={error ? 'error' : undefined}
      label={label}
      {...props}
      {...inputProps}
      ref={ref}
    />
  );
}

export default InputField;
