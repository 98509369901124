// DS1.15.2
import React, { useContext } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { PapiUser } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import ConditionalWrapper from '@wix/da-ds/pkg/ConditionalWrapper';
import ProfileIdentityMobile from '@wix/da-shared-react/pkg/ProfileIdentityMobile';
import { getCanCustomizePage } from '../../../selectors/privs';
import AvatarBirthdayBadge from './_partials/AvatarBirthdayBadge';
import ProfileStats from './_partials/ProfileStats';
import AvatarEdit from './_partials/AvatarEdit';

import s from './ProfileIdentity.scss';

export interface Props {
  profileOwnerUser: PapiUser;
  stats: object;
  tagline: string;
  url: string;
  className?: string;
  isOwner: boolean;
  isMobile?: boolean;
  canChangeAvatar?: boolean;
  aboutModuleId?: any;
}

const ProfileIdentity: React.FC<Props> = ({
  profileOwnerUser,
  stats,
  tagline,
  className,
  isOwner,
  canChangeAvatar,
}) => {
  const isMobile = useContext(MobileContext);
  const isGroupProfile = isGroup(profileOwnerUser);
  const isAuthorizedToCustomize = useSelector(getCanCustomizePage);
  const isDefaultAvatar =
    profileOwnerUser?.usericonDefault || !profileOwnerUser?.usericon;
  if (isMobile) {
    return (
      <ProfileIdentityMobile
        user={profileOwnerUser}
        tagline={tagline}
        className={classnames(className, isGroupProfile && s['group'])}
        statsComponent={<ProfileStats {...stats} />}
      />
    );
  }
  return (
    <ConditionalWrapper
      condition={isGroupProfile}
      wrap={child => <div className={s['group-container']}>{child}</div>}
    >
      <div
        className={classnames(s['root'], className, !tagline && s['notagline'])}
      >
        {canChangeAvatar && <AvatarEdit />}
        {profileOwnerUser.isBirthweek && <AvatarBirthdayBadge />}
        <span>
          <UserAvatar
            size={profileOwnerUser.isGroup ? 50 : 100}
            user={profileOwnerUser}
            className={classnames(
              s['user-avatar'],
              isAuthorizedToCustomize && isDefaultAvatar && s['outline']
            )}
            withTooltip={false}
            withLink={!isOwner}
          />
        </span>
        <div className={s['user-details']}>
          <h1 className={s['user-link']}>
            <UserLink
              className={s['user-link-text']}
              symbolClassName={s['user-link-icon']}
              user={profileOwnerUser}
              withTooltip={false}
              useCoreRibbon={false}
              coreRibbonClassname={s['core-ribbon']}
            />
          </h1>
          <div className={s['tagline']}>{tagline}</div>
          <ProfileStats {...stats} />
        </div>
      </div>
    </ConditionalWrapper>
  );
};
ProfileIdentity.displayName = 'ProfileIdentity';

export default ProfileIdentity;
