import React from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import { useFormContext } from 'react-hook-form';
import InputErrorMessage from '@wix/da-ds/pkg/formControls/lego/InputErrorMessage';

interface Props {
  name: string;
}

const ErrorMessage: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation();
  const { errors } = useFormContext();
  const error = get(errors, name);
  const id = useUniqueId();

  if (!error || !error.message) {
    return null;
  }
  return <InputErrorMessage id={id}>{t(error.message)}</InputErrorMessage>;
};

export default ErrorMessage;
