import { all, select, call, put } from 'redux-saga/effects';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { getProfileOwner } from '../../selectors/users';
import { PapiActivityBadgesRequestParams } from '../../../types/papi';
import { isApiError } from '@wix/da-http-client';
import { requestPuppyGruser } from '../gruserHelpers';

function* fetchFromApi(params) {
  return yield call(
    requestPuppyGruser,
    {
      method: 'get',
      url: `/module/badge_activity`,
      params,
    },
    undefined,
    'gruser'
  );
}

export default function* fetchMoreBadgeActivity(action) {
  const { streamId } = action;

  const [offset, limit, moduleId, profileOwner] = yield all([
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state => streamSelectors.getItemsPerFetch(state, streamId)),
    select(state =>
      streamSelectors.getStreamParam(state, streamId, 'moduleId')
    ),
    select(getProfileOwner),
  ]);

  const queryParams: PapiActivityBadgesRequestParams = {
    username: profileOwner.user.username,
    moduleid: moduleId,
    offset,
    limit,
  };

  const response = yield call(fetchFromApi, queryParams);

  if (response && !isApiError(response)) {
    const { hasMore, nextOffset, results } = response;

    yield put(
      withOffset.actions.fetchSuccess({
        streamId,
        hasMore,
        nextOffset,
        items: results,
      })
    );
  }

  // TODO error catching
}
