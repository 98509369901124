import { useSelector } from 'react-redux';
import { useNormalizedFolderStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { useMemo } from 'react';
import {
  getGallectionSectionType,
  getSelectedFolderId,
} from '../../../../selectors/gallectionSection';
import {
  getStreamIdForGallectionType,
  filterOutEmpty,
} from '../../../../utils/gallection';
import { AppState } from '../../../../../types/store';
import { SCRAPS_FOLDER_ID } from '@wix/da-url';

export function useGallectionFolders() {
  const { streamId, gallectionType, selectedFolderId } = useSelector(
    (state: AppState) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const gallectionType = getGallectionSectionType(state);
      return {
        streamId: getStreamIdForGallectionType(gallectionType),
        gallectionType,
        selectedFolderId: getSelectedFolderId(state),
      };
    }
  );
  const { items, ...stream } = useNormalizedFolderStream(
    streamId,
    gallectionType
  );
  let folders = items;

  // Scraps can be sent multiple times because of the always last item
  // logic, so we find it in the stack, filter out all dupes, and then
  // push it to the end.
  const scraps = folders.find(f => f.folderId === SCRAPS_FOLDER_ID);
  if (scraps) {
    folders = folders.filter(f => f.folderId !== SCRAPS_FOLDER_ID);
    folders.push(scraps);
  }

  return {
    ...stream,
    gallectionType,
    folders,
    selectedFolderId,
  };
}

export function useNonEmptyGallectionFolders() {
  const { folders, ...rest } = useGallectionFolders();
  const filteredFolders = useMemo(() => filterOutEmpty(folders), [folders]);
  return {
    ...rest,
    folders: filteredFolders,
  };
}
