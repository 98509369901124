import React, { useContext, useState } from 'react';
import { PapiDeviation } from '@wix/da-papi-types';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import ListCard from '@wix/da-ds/pkg/List/ListCard';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import TierConsumerReduxModule from '@wix/da-shared-react/pkg/Tier/redux/consumer/ModuleLoadable';
import { useSubscriptionTier } from '@wix/da-shared-react/pkg/utils/hooks/useSubscriptionTier';
import ManageTier from '@wix/da-shared-react/pkg/Tier/ActionItems/ManageTier';
import ShareOnSocial from '@wix/da-shared-react/pkg/Tier/ActionItems/ShareOnSocial';
import ConnectDiscord from '@wix/da-shared-react/pkg/Tier/ActionItems/ConnectDiscord';
import {
  PopperDropdownManager,
  PopperDropdownContent,
} from '@wix/da-ds/pkg/Dropdown/PopperDropdownLayout';
import { DropdownStateContext } from '@wix/da-ds/pkg/Dropdown/DropdownStateManager';
import FtueTooltip from './FtueTooltip';

export interface Props {
  deviation: PapiDeviation;
}

function landedFromCheckout() {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    return params.get('checkout') === '1';
  }
  return false;
}

const TierActions: React.FC<Props> = ({ deviation }) => {
  const { isUserSubscribed } = useSubscriptionTier(deviation);
  const { setToggleRef } = useContext(DropdownStateContext);
  const [showTooltip, setShowTooltip] = useState(() => {
    if (!deviation.tier?.discord?.connected) {
      return false;
    }
    if (!isUserSubscribed) {
      return false;
    }
    return landedFromCheckout();
  });

  const hideTooltip = () => setShowTooltip(false);

  const button = (
    <IconButton
      icon={EllipsisIcon}
      size={IconSize.MEDIUM}
      innerRef={setToggleRef}
      onClick={hideTooltip}
    />
  );

  return (
    <>
      <TierConsumerReduxModule />
      {showTooltip ? (
        <FtueTooltip onClose={hideTooltip}>{button}</FtueTooltip>
      ) : (
        button
      )}
      <PopperDropdownContent>
        <ListCard>
          <ShareOnSocial deviation={deviation} />
          {isUserSubscribed && (
            <>
              <ConnectDiscord deviation={deviation} />
              <ManageTier deviation={deviation} />
            </>
          )}
        </ListCard>
      </PopperDropdownContent>
    </>
  );
};
TierActions.displayName = 'TierActions';

const TierActionsWithDropdownState: React.FC<Props> = props => (
  <PopperDropdownManager>
    <TierActions {...props} />
  </PopperDropdownManager>
);

export default TierActionsWithDropdownState;
