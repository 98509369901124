import React from 'react';
import classnames from 'classnames';
import ProfileStatItemNumeric from './ProfileStatItemNumeric';
import ProfileStatItemDate from './ProfileStatItemDate';

import s from './ProfileStatItem.scss';

export interface Props {
  className?: string;
  countClassName?: string;
  value: number | string;
  stat: string;
}

export const ProfileStatItem: React.FC<Props> = ({
  className,
  countClassName,
  value = 0,
  stat,
}) => {
  if (typeof value === 'string') {
    const isDate = !isNaN(Date.parse(`${value}`));
    if (isDate) {
      return (
        <ProfileStatItemDate
          stat={stat}
          value={`${value}`}
          className={classnames(s['item'], className)}
          countClassName={countClassName}
        />
      );
    }
  }
  if (typeof value === 'number') {
    return (
      <ProfileStatItemNumeric
        stat={stat}
        value={value}
        className={classnames(s['item'], className)}
        countClassName={countClassName}
      />
    );
  }
  return null;
};

ProfileStatItem.displayName = 'ProfileStatItem';

export default ProfileStatItem;
