import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import AddCoverImageIcon from '@wix/da-ds/pkg/Icons/24x24/AddCoverImage';
import TrashcanIcon from '@wix/da-ds/pkg/Icons/24x24/Trashcan';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import { BiUpdateType } from '@wix/da-bi/pkg/constants';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
  ProfileCoverClickBiEvent,
  RemoveWidgetClickBiEvent,
} from '@wix/da-bi/pkg/events';
import BiButton from '@wix/da-bi/pkg/Components/BiButton';
import Button from '@wix/da-shared-react/pkg/Button';
import MediaSelector from '@wix/da-shared-react/pkg/MediaPopup/MediaSelector';
import { MediaSourceType } from '@wix/da-shared-react/pkg/MediaPopup/types';
import { DeviationTypes } from '@wix/da-shared-react/pkg/types/deviation';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import { ModuleType } from '../../../../../types/modules';

import s from './ProfileHeroEditControls.scss';

export interface Props {
  coverDeviation?: PapiDeviation;
  isRepositioning: boolean;
  isGroupProfile: boolean;
  className: string;
  setIsRepositioningProfileHero: (payload: boolean) => void;
  setProfileHeroDeviation: (payload: PapiDeviation) => void;
  showProfileHeroAid?: boolean;
  saveProfileHero: () => void;
  deleteProfileHero: () => void;
}

export interface State {
  showMediaManager: boolean;
}

export const ProfileHeroEditControls: React.FC<Props> = props => {
  const {
    showProfileHeroAid,
    coverDeviation,
    isRepositioning,
    isGroupProfile,
    className,
    saveProfileHero,
    setProfileHeroDeviation,
    setIsRepositioningProfileHero,
  } = props;
  const { t } = useTranslation();
  const [showMediaManager, setShowMediaManager] = useState(false);

  const renderButtons = () => {
    if (isRepositioning) {
      return renderButtonsWhenRepositioning(props, { t });
    }
    if (coverDeviation) {
      return (
        <>
          {renderDeleteImageButton(props, { t })}
          {renderSetImageButton(props, { t, setShowMediaManager })}
        </>
      );
    }
    return renderSetImageButton(props, { t, setShowMediaManager });
  };

  return (
    <div
      className={classnames(
        s['root-wrapper'],
        showProfileHeroAid && s['is-ftue'],
        className
      )}
    >
      {isRepositioning && renderDraggableImageHint({ t })}
      <div className={s['buttons']}>
        {renderButtons()}
        {showMediaManager && (
          <MediaSelector
            mediaSourceTypes={[MediaSourceType.GALLERY, MediaSourceType.STASH]}
            allowedDeviationTypes={[DeviationTypes.IMAGE]}
            allowGifs={false}
            onClose={() => setShowMediaManager(false)}
            onSelect={selectedDeviations => {
              setProfileHeroDeviation(selectedDeviations[0]);
              setShowMediaManager(false);
              if (isGroupProfile) {
                saveProfileHero();
              } else {
                setIsRepositioningProfileHero(true);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
ProfileHeroEditControls.displayName = 'ProfileHeroEditControls';

const renderDraggableImageHint = ({ t }) => (
  <div className={s['draggable-hint']}>
    <div className={s['triple-stripe']}>
      <div className={s['stripe']} />
      <div className={s['stripe']} />
      <div className={s['stripe']} />
    </div>
    {t('common.profileHero.dragToMove')}
  </div>
);

const renderSetImageButton = (
  { showProfileHeroAid, coverDeviation }: Props,
  { t, setShowMediaManager }
) => {
  return (
    <SimpleTooltip
      usePortal
      text={t('common.profileHero.addCoverImage.tooltip')}
    >
      <Button
        key="change-btn"
        className={s['icon-btn']}
        onClick={() => setShowMediaManager(true)}
        data-role={showProfileHeroAid ? 'ftue-control' : 'edit-control'}
        view="b8"
        biData={BiData<ProfileCoverClickBiEvent>({
          evid: 276,
          is_update: BiUpdateType.CHANGE,
        })}
        aria-label={t('common.profileHero.editCoverImage')}
      >
        <span className={s['icon-btn-content']}>
          <AddCoverImageIcon
            className={s['icon']}
            size={IconSize.MEDIUM}
            condensed
          />
          <span className={s['btn-title']}>
            {t(
              coverDeviation
                ? 'common.profileHero.editCoverImage'
                : 'common.profileHero.addCoverImage'
            )}
          </span>
        </span>
      </Button>
    </SimpleTooltip>
  );
};

const renderButtonsWhenRepositioning = (
  { setIsRepositioningProfileHero, saveProfileHero }: Props,
  { t }
) => (
  <>
    <GhostButton
      size="large"
      variant="edit-purple"
      biData={BiData<DefaultBiEvent>({
        evid: 2,
        click_info: 'profile.hero.repositioning.cancel',
        sectionname: BiContextProvidedValue,
        widgetname: BiContextProvidedValue,
        typeid: BiContextProvidedValue,
        itemid: BiContextProvidedValue,
      })}
      onClick={() => setIsRepositioningProfileHero(false)}
    >
      {t('common.cancel')}
    </GhostButton>
    <SolidButton
      size="large"
      variant="edit-purple"
      className={s['done-btn']}
      biData={BiData<DefaultBiEvent>({
        evid: 2,
        click_info: 'profile.hero.repositioning.done',
        sectionname: BiContextProvidedValue,
        widgetname: BiContextProvidedValue,
        typeid: BiContextProvidedValue,
        itemid: BiContextProvidedValue,
      })}
      onClick={saveProfileHero}
    >
      {t('common.done')}
    </SolidButton>
  </>
);

const renderDeleteImageButton = (
  { deleteProfileHero, showProfileHeroAid }: Props,
  { t }
) => (
  <span className={s['delete-btn-wrapper']}>
    <SimpleTooltip text={t('common.profileHero.delete')}>
      <BiButton
        key="delete-btn"
        className={classnames(s['icon-btn'], s['delete-btn'])}
        onClick={deleteProfileHero}
        data-role={showProfileHeroAid ? 'ftue-control' : 'edit-control'}
        biData={BiData<RemoveWidgetClickBiEvent>({
          evid: 280,
          widgetname: ModuleType.COVER_DEVIATION,
          widgetzone: BiContextProvidedValue,
        })}
        aria-label={t('common.profileHero.a11y.removeCoverImage')}
      >
        <TrashcanIcon className={s['icon']} size={IconSize.MEDIUM} condensed />
      </BiButton>
    </SimpleTooltip>
  </span>
);

export default ProfileHeroEditControls;
