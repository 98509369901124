import React from 'react';
import {
  ButtonProps,
  ProfileOwnerWatchButton,
} from '@wix/da-shared-react/pkg/Button';
import { PapiUser } from '@wix/da-papi-types';

interface Props extends ButtonProps {
  user: PapiUser;
  isProfileOwnerWatchingCurrentUser: boolean;
}
export const WatchButton: React.FC<Props> = ({
  className,
  user,
  isProfileOwnerWatchingCurrentUser,
  ...rest
}) => {
  return (
    <ProfileOwnerWatchButton
      user={user}
      className={className}
      isWatchingBack={isProfileOwnerWatchingCurrentUser}
      {...rest}
    />
  );
};

export default WatchButton;
