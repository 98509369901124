import {
  getPageInfoInitActions,
  getCustomInitActionsForWidgets,
} from './common';

import getBackroomWidgetConfigs from '../../components/Sections/BackroomSection/backroomWidgetConfigs';

export const getCustomInitActionsForBackroomWidgets = (
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getBackroomWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  ...getPageInfoInitActions(responseData, requestParams),
];
