export const MIN_BACKGROUND_HEIGHT = 288;
export const MAX_BACKGROUND_HEIGHT = 614;
export const BACKGROUND_HEIGHT = 420;

export const BACKGROUND_WIDTH = 560;
export const BACKGROUND_RATIO = BACKGROUND_WIDTH / BACKGROUND_HEIGHT;
// A list of filenames for backgroudns available at https://admin.deviantart.com/tools/utils/filemanager/dav/eclipse/widgets/llamafarm/
// 1.jpg is vacant, overwrite it on the file server when the product decides to add a new background
export const BACKGROUNDS = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23,
];
export const DEFAULT_BACKGROUND = 12;

export const SIDEBAR_WIDTH = 156;
export const STAGE_HEIGHT = BACKGROUND_HEIGHT;
export const STAGE_WIDTH = BACKGROUND_WIDTH + SIDEBAR_WIDTH;

export const MIN_LLAMA_HEIGHT = 27;
export const MAX_LLAMA_HEIGHT = 108;
export const LLAMA_IMAGE_WIDTH = 20;
export const LLAMA_IMAGE_HEIGHT = 27;
export const LLAMA_IMAGE_SCALE = MIN_LLAMA_HEIGHT / LLAMA_IMAGE_HEIGHT;
export const LLAMA_SCALE_RANGE =
  (MAX_LLAMA_HEIGHT - MIN_LLAMA_HEIGHT) / MIN_LLAMA_HEIGHT;

export const MAX_ALLOWED_LLAMAS = 20;
