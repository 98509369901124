import React from 'react';
import { PapiBadge, PapiUser } from '@wix/da-papi-types';
import Badges from './Badges';
import BadgeGifts from './Badges/BadgeGifts';
import givableBadgeItems from './Badges/BadgeGifts/givableBadgeItems';
import BadgeActivity from './BadgeActivity';
import { BadgeActivityItem } from '../../../../types/badge';
import s from './MyBadges.scss';

export interface Props {
  className?: string;
  badges: PapiBadge[];
  badgeActivity: BadgeActivityItem[];
  totalSent: number;
  totalReceived: number;
  isOwner: boolean;
  profileOwnerUser: PapiUser;
}

const MyBadges: React.FC<Props> = ({
  badges,
  badgeActivity,
  totalSent,
  totalReceived,
  isOwner,
  profileOwnerUser,
}) => {
  const widthBreakpoints = [640, 800];
  return (
    <>
      <Badges
        className={s['section']}
        items={badges}
        widthBreakpoints={widthBreakpoints}
        profileOwnerUser={profileOwnerUser}
      />
      {!isOwner && (
        <BadgeGifts
          className={s['section']}
          items={givableBadgeItems}
          widthBreakpoints={widthBreakpoints}
        />
      )}
      <BadgeActivity
        className={s['activities']}
        totalSent={totalSent}
        totalReceived={totalReceived}
        items={badgeActivity}
        isOwner={isOwner}
        ownerUsername={profileOwnerUser.username}
      />
    </>
  );
};
MyBadges.displayName = 'MyBadges';

export default MyBadges;
