// DS: CR03A, CR03B
import React, { useContext, useRef } from 'react';
import classnames from 'classnames';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserLinkPopover from '@wix/da-shared-react/pkg/User/Link/UserLinkPopover';
import { PapiUser } from '@wix/da-papi-types';
import WatchButton from '@wix/da-shared-react/pkg/WatchButton';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { Url } from '@wix/da-url';
import s from './MiniProfileIdentity.scss';

export interface Props {
  user: PapiUser;
  className?: string;
  usernameClassName?: string;
  withTooltip?: boolean;
  withWatchButton?: boolean;
  asCard?: boolean;
  onClick?: (user: PapiUser) => void;
}

export const MiniProfileIdentity: React.FC<Props> = ({
  user,
  withWatchButton,
  withTooltip: withTooltipProp,
  asCard = true,
  className,
  usernameClassName,
  onClick,
}) => {
  const isMobile = useContext(MobileContext);
  const withTooltip = isMobile ? false : withTooltipProp;
  const watchButtonWrapperRef = useRef<HTMLDivElement | null>(null);

  const onRootClick = e => {
    onClick && onClick(user);

    // go to profile using JS because it's a button inside a link situation
    if (watchButtonWrapperRef.current?.contains(e.target)) {
      return;
    }
    const userLinkUrl = Url.userLink(user.username);
    if (e.metaKey && e.ctrlKey) {
      window.open(userLinkUrl, '_blank');
      return;
    }
    typeof window !== undefined && (window.location.href = userLinkUrl);
  };
  const renderContent = () => (
    <div
      className={classnames(
        s['root'],
        !withTooltip && className,
        asCard && s['card']
      )}
      onClick={onRootClick}
    >
      <div className={s['content-wrapper']}>
        <UserAvatar
          className={s['avatar']}
          user={user}
          size={40}
          withLink={false}
        />
        <div className={s['right-side']}>
          <span className={classnames(s['user-name'], usernameClassName)}>
            {user.username}
          </span>
          {withWatchButton && (
            <div ref={watchButtonWrapperRef}>
              <WatchButton user={user}>
                {({ onClick: onButtonClick, disabled, text }) => (
                  <TextButton
                    variant="approval-green"
                    size="small"
                    onClick={onButtonClick}
                    disabled={disabled}
                    noHorizontalPaddings
                  >
                    {text}
                  </TextButton>
                )}
              </WatchButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return withTooltip ? (
    <UserLinkPopover user={user} className={className}>
      {renderContent()}
    </UserLinkPopover>
  ) : (
    renderContent()
  );
};
MiniProfileIdentity.displayName = 'MiniProfileIdentity';

export default MiniProfileIdentity;
