import React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import Button from '@wix/da-shared-react/pkg/Button';
import s from './DisabledOverlay.scss';

export interface Props {
  className?: string;
  total: number;
  enableAllCommissions(): void;
}

export class DisabledOverlay extends React.Component<
  Props & WithTranslation
> {
  render() {
    const { className, total, enableAllCommissions, t } = this.props;

    return (
      <div className={s['root']}>
        <div className={classnames(s['content'], className)}>
          <span className={s['title']}>
            {t!('pages.shop.disabledState.commissions.titleOwner')}
          </span>
          <span className={s['subtitle']}>
            {t!('pages.shop.disabledState.commissions.subtitleOwner', {
              count: total,
            })}
          </span>
          <Button view="b9" onClick={enableAllCommissions}>
            {t!('pages.shop.disabledState.commissions.enableAllCommissions')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DisabledOverlay) as React.ComponentType<Props>;
