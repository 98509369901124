import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDown from '@wix/da-ds/pkg/Icons/24x24/ArrowDown';
import ArrowUp from '@wix/da-ds/pkg/Icons/24x24/ArrowUp';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import TextButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/TextButtonWithContextMenu';
import {
  DropdownOption,
  NullableDropdownItem,
} from '@wix/da-ds/pkg/Dropdown/types';
import { sortGroupMessageLog } from '../../../../../actions/backroomSection';
import { useMessageLogParams } from '../hooks';

interface Props {}

function useOrderOptions(): [NullableDropdownItem[], DropdownOption] {
  const { t } = useTranslation();
  const { sortBy = 'newest' } = useMessageLogParams();
  const dispatchSortAction = useAction(sortGroupMessageLog);

  const orderOptions = useMemo(
    () => [
      {
        label: t(
          'widgets.group_messagelog.filterBar.orderDropdown.option.newest.label'
        ),
        value: 'newest',
        selected: sortBy === 'newest',
        onClick: () => dispatchSortAction('newest'),
      },
      {
        label: t(
          'widgets.group_messagelog.filterBar.orderDropdown.option.oldest.label'
        ),
        value: 'oldest',
        selected: sortBy === 'oldest',
        onClick: () => dispatchSortAction('oldest'),
      },
    ],
    [sortBy, dispatchSortAction, t]
  );

  return [orderOptions, orderOptions.find(option => option.selected)!];
}

const OrderDropdown: React.FC<Props> = () => {
  const [orderOptions, selectedOption] = useOrderOptions();

  return (
    <TextButtonWithContextMenu
      variant="main"
      size="medium"
      postfix={{
        opened: ArrowUp,
        closed: ArrowDown,
      }}
      items={orderOptions}
    >
      {selectedOption.label}
    </TextButtonWithContextMenu>
  );
};
OrderDropdown.displayName = 'OrderDropdown';

export default OrderDropdown;
