import React, { useState } from 'react';
import cn from 'classnames';
import { useSwipeable } from 'react-swipeable';
import Dots from '@wix/da-ds/pkg/pagination/Dots';
import IncomeChartPanel from '../ChartPanel/presets/IncomeChartPanel';
import SubscribersChartPanel from '../ChartPanel/presets/SubscribersChartPanel';
import SubscriptionTips from '../../../SubscriptionTips/SubscriptionTips';
import useTiersModuleCounts from '../../../SubscriptionTips/useTiersModuleCounts';
import {
  SubscriptionsChartPointType,
  SubscriptionsStatType,
} from '../../../../../../../../types/subscriptionsSection';
import EmptyState from '../EmptyState';

import s from './MobileContent.scss';

export interface Props {
  moduleId: number;
  className?: string;
  stats?: SubscriptionsStatType[];
  chartData?: SubscriptionsChartPointType[];
}

const MobileContent: React.FC<Props> = ({
  moduleId,
  className,
  stats,
  chartData = [],
}) => {
  const [currentPanel, setCurrentPanel] = useState(0);
  const { draftCount, publishedCount, subscriberCount } =
    useTiersModuleCounts();
  const { ref: swipeRef, onMouseDown } = useSwipeable({
    onSwipedLeft: () =>
      setCurrentPanel(Math.min(panels.length - 1, currentPanel + 1)),
    onSwipedRight: () => setCurrentPanel(Math.max(0, currentPanel - 1)),
  });

  const viewPortTranslateX =
    currentPanel > 0 ? `-${currentPanel * 100}%` : `0%`;

  const panels: React.ReactNode[] = [];

  if (!stats || stats.length < 1 || chartData.length < 1) {
    panels.push(
      <div className={s['panel']} key="empty-state">
        <EmptyState />
      </div>
    );
  } else {
    panels.push(
      <IncomeChartPanel
        className={s['panel']}
        chart={chartData}
        stats={stats && stats[0]}
        key="income"
      />
    );
    panels.push(
      <SubscribersChartPanel
        className={s['panel']}
        chart={chartData}
        stats={stats && stats[0]}
        key="subscribers"
      />
    );
  }
  panels.push(
    <SubscriptionTips
      moduleId={moduleId}
      className={s['panel']}
      draftCount={draftCount}
      publishedCount={publishedCount}
      subscriberCount={subscriberCount}
      key="tips"
    />
  );

  return (
    <div className={cn(s['root'], className)}>
      <div className={s['viewport']}>
        <div
          className={s['swipeable']}
          ref={swipeRef}
          onMouseDown={onMouseDown}
          style={{ transform: `translateX(${viewPortTranslateX})` }}
        >
          {panels}
        </div>
      </div>
      <Dots
        className={s['pagination']}
        total={panels.length}
        current={currentPanel}
        onClick={n => setCurrentPanel(n)}
      />
    </div>
  );
};
MobileContent.displayName = 'MobileContent';

export default MobileContent;
