export const APP_LOADED = 'APP_LOADED';
export const PATH_CHANGE = 'PATH_CHANGE';
export const FETCH_APP_SESSION_SUCCESS = 'FETCH_APP_SESSION_SUCCESS';

export const appLoaded = () => ({
  type: APP_LOADED,
});

export const pathChange = (location: object) => ({
  type: PATH_CHANGE,
  location,
});
