import { GroupsBackroomState } from '../../types/backroomSection';
import {
  setSelectedFolder,
  setSelectedNoteId,
  setNotesOffset,
  setNotesSearch,
} from '../actions/backroomSection';

const defaultState = {
  folder: undefined,
  search: undefined,
  selectedNoteId: undefined,
  offset: 0,
} as GroupsBackroomState;

export default function backroomSectionReducer(
  state = defaultState,
  action
): GroupsBackroomState {
  const { type, payload } = action;
  switch (type) {
    case setSelectedFolder.toString():
      return {
        ...state,
        folder: payload.folder,
      };
    case setSelectedNoteId.toString():
      return {
        ...state,
        selectedNoteId: payload.noteId,
      };
    case setNotesOffset.toString():
      return {
        ...state,
        offset: payload.offset,
      };
    case setNotesSearch.toString():
      return {
        ...state,
        search: payload.search,
      };
    default:
      return state;
  }
}
