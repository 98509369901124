import React from 'react';
import c from 'classnames';
import { useSelector } from 'react-redux';
import { useNormalizedDeviationStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { isCurrentUserProfileOwner } from '../../../selectors/users';
import { ADOPTABOBBLES_DEVIATIONS_STREAM_ID } from '../../../streams/adoptabobbles';
import PrivateStatus from './_partials/PrivateStatus';
import AdoptabobblesBought from './AdoptabobblesBought';

import s from './AdoptabobblesBoughtBox.scss';

export type Props = {
  moduleId: number;
  widgetBoxProps: Partial<WidgetBoxProps>;
};

const streamId = ADOPTABOBBLES_DEVIATIONS_STREAM_ID;

const AdoptabobblesBoughtBox: React.FC<Props> = ({
  moduleId,
  widgetBoxProps,
}) => {
  const { items: deviations, isInitialized } =
    useNormalizedDeviationStream(streamId);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const isEmpty = isInitialized && !deviations.length;

  return (
    <WidgetBox
      moduleId={moduleId}
      header={null}
      headerExtras={isOwner && !isEmpty && <PrivateStatus />}
      headerClassName={c(s['header'], !isOwner && s['no-header'])}
      {...widgetBoxProps}
    >
      <AdoptabobblesBought deviations={deviations} />
    </WidgetBox>
  );
};
AdoptabobblesBoughtBox.displayName = 'AdoptabobblesBoughtBox';

export default AdoptabobblesBoughtBox;
