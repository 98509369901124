/** Allows a user to drag and drop or choose image files, crop, and then use them for their avatar */
import React, {
  Suspense,
  lazy,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import ModalFormDialog from '@wix/da-shared-react/pkg/Popup/ModalFormDialog';
import IconButton from '@wix/da-shared-react/pkg/Button/IconButton';
import IconClose from '@wix/da-ds/pkg/Icons/CloseBold';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { AvatarUploadPayload } from '../../../../../actions/avatar';
import { MAXIMUM_GIF_INPUT_FILESIZE, ACCEPTED_FILE_TYPES } from '../constants';
import s from './AvatarEditPopup.scss';

const ImageCrop = lazy(
  () => import(/* webpackChunkName: "avatarEdit" */ '../ImageCrop')
);

const TRANSLATED_ERRORS = [
  'widgets.avatarEdit.error.maximumFileSizeExceeded',
  'widgets.avatarEdit.error.maximumGifFileSizeExceeded',
  'widgets.avatarEdit.error.cannotResizeAnimatedGifs',
  'widgets.avatarEdit.error.cannotProcessFile',
  'widgets.avatarEdit.error.imageTooSmall',
  'widgets.avatarEdit.error.trySmallerAnimatedGif',
  'widgets.avatarEdit.error.unexpectedErrorOccurred',
];

export interface Props {
  className?: string;
  /** a locale key of an error set when something went wrong during upload or processing */
  errorMessage?: string;
  setIsEditorOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage?: string) => void;
  uploadAvatar?: (payload: AvatarUploadPayload) => void;
  avatarWidth: number;
  avatarHeight: number;
}

const AvatarEditPopup: React.FC<Props> = ({
  className,
  errorMessage,
  setIsEditorOpen,
  setErrorMessage,
  uploadAvatar = noop,
  avatarWidth,
  avatarHeight,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | undefined>();
  const [image, setImage] = useState<HTMLImageElement | undefined>();
  const [pixelCrop, setPixelCrop] = useState<ReactCrop.PixelCrop | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMobile = useContext(MobileContext);

  const clearState = useCallback(() => {
    setErrorMessage(undefined);
    setFile(undefined);
    setImage(undefined);
    setPixelCrop(undefined);
    setIsSubmitting(false);
  }, [setErrorMessage]);

  const handleRequestClose = useCallback(() => {
    setIsEditorOpen(false);
  }, [setIsEditorOpen]);

  const processAndUploadAvatar = useCallback(
    e => {
      setIsSubmitting(true);
      uploadAvatar({ file, image, pixelCrop });
    },
    [uploadAvatar, file, image, pixelCrop]
  );

  const handleFileDrop = useCallback(
    (newFile, newImage) => {
      try {
        setErrorMessage();
        setIsSubmitting(false);
        setImage(undefined);

        validateDroppedFile(newFile);
        validateImage(newImage, avatarWidth, avatarHeight);

        setFile(newFile);
        setImage(newImage);
        return newImage;
      } catch (err) {
        setErrorMessage(err.message);
      }
    },
    [setErrorMessage, avatarHeight, avatarWidth]
  );

  const handleCrop = useCallback((newCrop, newPixelCrop) => {
    setPixelCrop(newPixelCrop);
  }, []);

  const isSubmitDisabled =
    isSubmitting ||
    !!errorMessage ||
    !file ||
    // don't allow submitting when there's no crop selection
    !pixelCrop?.width ||
    !pixelCrop?.height;

  const translationContext = useMemo(
    () => ({
      postProcess: ['reactPostprocessor'],
      dims: `${avatarWidth}x${avatarHeight}px`,
      br: <br />,
    }),
    [avatarWidth, avatarHeight]
  );

  return (
    <ModalFormDialog
      isOpen
      showHeaderBorder={isMobile}
      showFooterBorder={isMobile}
      className={classnames(s['popup'], !!image && s['has-image'], className)}
      title={t<string>('widgets.avatarEdit.popupTitle')}
      onSubmit={processAndUploadAvatar}
      onCancel={handleRequestClose}
      onClose={handleRequestClose}
      disabledSubmit={isSubmitDisabled}
      footerExtras={
        errorMessage && (
          <div className={classnames(s['alert'])}>
            <div className={s['alert-message']}>
              {t(getErrorLocaleKey(errorMessage), translationContext)}
            </div>
            <IconButton onClick={clearState} noHoverEffect>
              <IconClose className={s['alert-close']} />
            </IconButton>
          </div>
        )
      }
    >
      <div className={s['popup-content']}>
        <p className={s['help']}>
          {!!image && t('widgets.avatarEdit.selectionHelp')}
        </p>

        <Suspense fallback={<LoadingIndicator />}>
          <ImageCrop
            className={s['editor']}
            file={file}
            image={image}
            pixelCrop={pixelCrop}
            onFileDrop={handleFileDrop}
            onCrop={handleCrop}
            targetWidth={avatarWidth}
            targetHeight={avatarHeight}
          />
        </Suspense>
      </div>
    </ModalFormDialog>
  );
};
AvatarEditPopup.displayName = 'AvatarEditPopup';
export default AvatarEditPopup;

function validateDroppedFile(file: File): void {
  if (!file || ACCEPTED_FILE_TYPES.indexOf(file.type) < 0) {
    throw new Error('widgets.avatarEdit.error.unexpectedErrorOccurred');
  }
  if (file.type === 'image/gif' && file.size > MAXIMUM_GIF_INPUT_FILESIZE) {
    throw new Error('widgets.avatarEdit.error.trySmallerAnimatedGif');
  }
}

function validateImage(image: HTMLImageElement, width, height): void {
  if (!image) {
    throw new Error('widgets.avatarEdit.error.unexpectedErrorOccurred');
  }
  if (image.width < width || image.height < height) {
    throw new Error('widgets.avatarEdit.error.imageTooSmall');
  }
}

function getErrorLocaleKey(errorMessage) {
  return TRANSLATED_ERRORS.indexOf(errorMessage) >= 0
    ? errorMessage
    : 'widgets.avatarEdit.error.unexpectedErrorOccurred';
}
