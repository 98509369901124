import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import EmptyState from '../../../../EmptyState';

import s from '../EmptyState/EmptyState.scss';

export interface Props {
  view?: 'wide' | 'large';
  total: number;
  user: PapiUser;
  isOwner: boolean;
  enableAllCommissions(): void;
  onOpenChat: (user: PapiUser) => void;
}

export const DisabledEmptyState: React.FC<Props> = ({
  view = 'large',
  total,
  isOwner,
  user,
  user: { username },
  enableAllCommissions,
  onOpenChat,
}) => {
  const { t } = useTranslation();

  return (
    <EmptyState
      view={view}
      className={c(s['root'], view === 'large' && s['large'])}
      titleClassName={s['title']}
      subtitleClassName={s['subtitle']}
      title={
        isOwner
          ? t!('pages.shop.disabledState.commissions.titleOwner')
          : t!('pages.shop.disabledState.commissions.title', { username })
      }
      subtitle={
        isOwner
          ? t!('pages.shop.disabledState.commissions.subtitleOwner', {
              count: total,
            })
          : t!('pages.shop.disabledState.commissions.subtitle')
      }
      buttonLabel={
        isOwner
          ? t!('pages.shop.disabledState.commissions.enableAllCommissions')
          : t!('common.openChat')
      }
      onButtonClick={isOwner ? enableAllCommissions : () => onOpenChat(user)}
      withButton={true}
    />
  );
};

export default DisabledEmptyState;
