import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TierManageContentButton from '@wix/da-shared-react/pkg/Tier/TierManageContentButton';
import UnderlinedTabs from '@wix/da-ds/pkg/Tabs/dsPresets/UnderlinedTabs';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import TierContentFeed from './TierContentFeed';
import TiersDropdown from './TiersDropdown';
import EmptyState from './EmptyState';
import TierActions from './TierActions';
import {
  getProfileOwner,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import {
  getSelectedOrFirstTier,
  getSelectedTierContentType,
} from '../../../../../selectors/subscriptionsSection';
import { getTierContentDeviationsStreamId } from '../../../../../streams/tierContent';
import { SubscriptionsTierContentType } from '../../../../../../types/subscriptionsSection';
import { setSelectedTierContentType } from '../../../../../actions/subscriptionsSection';
import s from './TierContent.scss';

export interface Props {
  moduleId: number;
}

const TierContent: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useContext(MobileContext);
  const profileOwner = useSelector(getProfileOwner);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const tier = useSelector(getSelectedOrFirstTier);
  const selectedTierContentType =
    useSelector(getSelectedTierContentType) ||
    SubscriptionsTierContentType.DEVIATIONS;

  const streamId = getTierContentDeviationsStreamId(
    tier ? tier.deviationId : 0,
    selectedTierContentType
  );

  const tabs = useMemo(
    () => [
      {
        index: SubscriptionsTierContentType.DEVIATIONS,
        label: t('widgets.tierContent.tabs.deviations'),
      },
      {
        index: SubscriptionsTierContentType.POSTS,
        label: t('widgets.tierContent.tabs.posts'),
      },
    ],
    [t]
  );

  if (!tier) {
    return null;
  }

  const renderManageContentButtons = () => (
    <>
      <TierManageContentButton
        type={'deviations'}
        tierDeviation={tier}
        profileOwner={profileOwner.user}
        className={s['manage-content-button']}
      />
      <TierManageContentButton
        type={'posts'}
        tierDeviation={tier}
        profileOwner={profileOwner.user}
        className={s['manage-content-button']}
      />
    </>
  );

  const renderRightSideControls = () => {
    if (!isMobile && isOwner && tier.isPublished) {
      return renderManageContentButtons();
    }
    if (!isOwner) {
      return <TierActions deviation={tier} />;
    }
  };

  const rightSide = renderRightSideControls();

  return (
    <div className={s['root']}>
      <div className={s['toolbar']}>
        {!isMobile && <TiersDropdown className={s['title']} />}
        <UnderlinedTabs
          size="big"
          className={s['tabs']}
          items={tabs}
          activeIndex={selectedTierContentType}
          onChange={tab =>
            dispatch(
              setSelectedTierContentType(tab as SubscriptionsTierContentType)
            )
          }
        />
        <div className={s['right-side-area']}>{rightSide}</div>
      </div>
      <div className={s['content']}>
        {!tier.isPublished && (
          <EmptyState
            isDraft
            tier={tier}
            tierContentType={selectedTierContentType}
          />
        )}
        {tier.isPublished && (
          <TierContentFeed
            tier={tier}
            streamId={streamId}
            tierContentType={selectedTierContentType}
          />
        )}
      </div>
    </div>
  );
};
TierContent.displayName = 'TierContent';

export default TierContent;
