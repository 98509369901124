import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import AddIcon from '@wix/da-ds/pkg/Icons/24x24/Add';
import BiButton from '@wix/da-bi/pkg/Components/BiButton';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import type { BiEvent } from '@wix/da-bi/pkg/events';

import s from './AddColumnButton.scss';

export interface Props {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  biData?: BiEvent;
}

const AddColumnButton: React.FC<Props> = ({
  className,
  onClick,
  disabled,
  biData,
}) => {
  const { t } = useTranslation();
  return (
    <BiButton
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        s['edit-button'],
        disabled && s['disabled'],
        className
      )}
      data-role="edit-control"
      aria-label={t('widgets.common.addColumn')}
      biData={biData}
    >
      <AddIcon size={IconSize.MEDIUM} className={s['icon']} condensed />
      <span className={s['edit-button-text']}>
        {t!('widgets.common.addColumn')}
      </span>
    </BiButton>
  );
};

AddColumnButton.displayName = 'AddColumnButton';

export default AddColumnButton;
