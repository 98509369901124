import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import initActionsCreator from './redux/initModule';

export const getCoverDeviationWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  initActionsCreator,
});

export default getCoverDeviationWidgetConfig;
