import React from 'react';
import { PapiBadge } from '@wix/da-papi-types';
import BadgeGiftBox from './BadgeGiftBox';
import ElementQueries from '@wix/da-shared-react/pkg/ElementQueries';
import classnames from 'classnames';

import s from '../Badges.scss';

export interface Props {
  items: PapiBadge[];
  className?: string;
  widthBreakpoints?: number[];
}

const BadgesBox: React.FC<Props> = ({ items, className, widthBreakpoints }) => (
  <ElementQueries
    className={classnames(s['grid'], className)}
    widthBreakpoints={widthBreakpoints || [560]}
  >
    {items.map((item, i) => (
      <BadgeGiftBox key={i} item={item} className={s['grid-item']} />
    ))}
  </ElementQueries>
);

BadgesBox.displayName = 'BadgesBox';
export default BadgesBox;
