import React from 'react';
import { PapiModuleCustombox } from '@wix/da-papi-types';
import ConditionalWrapper from '@wix/da-ds/pkg/ConditionalWrapper';
import WidgetPaywall from '@wix/da-shared-react/pkg/widgets/lego/WidgetPaywall';
import { useShowCoreUpsell } from '@wix/da-shared-react/pkg/widgets/hooks/useShowCoreUpsell';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import Custom from '../Custom';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const CustomBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { moduleData } = useModuleData<PapiModuleCustombox>(moduleId);
  const { title } = moduleData;

  const showCoreUpsell = useShowCoreUpsell(moduleId);

  return (
    <WidgetBox header={title} moduleId={moduleId} {...widgetBoxProps}>
      <ConditionalWrapper
        condition={showCoreUpsell}
        wrap={child => <WidgetPaywall>{child}</WidgetPaywall>}
      >
        <Custom moduleId={moduleId} />
      </ConditionalWrapper>
    </WidgetBox>
  );
};
CustomBox.displayName = 'CustomBox';

export default CustomBox;
