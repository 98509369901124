import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import MessageLogBox from './MessageLogBox';
import initActionsCreator from './redux/initModule';

export const getMessageLogWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: MessageLogBox,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-17-groups.svg',
    title: t('widgets.group_messagelog.addButtonHeader'),
    description: t('widgets.group_messagelog.description'),
  },
  initActionsCreator,
});
export default getMessageLogWidgetConfig;
