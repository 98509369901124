import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import {
  OverlayContextProvider,
  OverlayContextProps,
} from '@wix/da-react-context/pkg/OverlayContext';
import FeedLayout, {
  FeedContent,
  FeedSidebar,
} from '@wix/da-shared-react/pkg/FeedLayout';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { PostsSortOrder, PostsType } from '../../../../types/posts';
import EmptyState from './_partials/EmptyState';
import SortOrderSwitcher from './_partials/SortOrderSwitcher';
import PostsTypeSwitcher from './_partials/PostsTypeSwitcher';
import Postbox from './_partials/Postbox';
import PostsFeed from './_partials/PostsFeed';
import FeaturedList from './_partials/FeaturedList';
import { FEATURED_POSTS_IDS_STREAM } from '../../../streams/posts';

import s from './PostsSection.scss';

export interface Props {
  isSectionLoading: boolean;
  isGroup: boolean;
  isOwner: boolean;
  isWatching: boolean;
  isEmpty: boolean;
  streamId: string;
  counts: Partial<{
    [key in PostsType]: number | undefined;
  }>;
}

export interface RouteParams {
  username: string;
  postsSection?: PostsType;
  sortOrder?: PostsSortOrder;
}

const PostsSection: React.FC<Props & RouteParams> = ({
  isSectionLoading,
  isGroup,
  isOwner,
  isWatching,
  isEmpty,
  username,
  streamId,
  sortOrder = PostsSortOrder.NEWEST,
  postsSection = PostsType.ALL,
  counts,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useContext(MobileContext);
  const overlayContextProps: OverlayContextProps = {
    showAuthorTooltip: true,
    showExtraActions: false,
    showThumbStatusIndicators: false,
  };

  if (isSectionLoading) {
    return <LoadingIndicator />;
  }

  return (
    <OverlayContextProvider value={overlayContextProps}>
      <FeedLayout className={s['root']}>
        <FeedContent>
          <div className={s['header']}>
            <PostsTypeSwitcher
              isGroup={isGroup}
              isOwner={isOwner}
              className={s['switcher']}
              value={postsSection}
              counts={counts}
              onChange={subPath =>
                history.push(
                  Url.userPostsPath({
                    username,
                    subPath: subPath as any,
                    sortOrder,
                  })
                )
              }
            />
            <SortOrderSwitcher
              className={s['switcher']}
              value={sortOrder}
              onChange={order =>
                history.push(
                  Url.userPostsPath({
                    username,
                    subPath: postsSection as any,
                    sortOrder: order,
                  })
                )
              }
            />
          </div>
          {!(isMobile && isGroup) && <Postbox className={s['post-box']} />}
          {isEmpty && <EmptyState postType={postsSection as any} />}
          {!isEmpty && (
            <BiLoggerContextProvider value={{ sectionname: postsSection }}>
              <PostsFeed
                streamId={streamId}
                withWatchOrSubscribeButtons={!isWatching}
              />
            </BiLoggerContextProvider>
          )}
        </FeedContent>

        {!isMobile && !isEmpty && (
          <FeedSidebar>
            <div className={s['header']}>{t('widgets.feed.featured')}</div>
            <FeaturedList streamId={FEATURED_POSTS_IDS_STREAM} />
          </FeedSidebar>
        )}
      </FeedLayout>
    </OverlayContextProvider>
  );
};
PostsSection.displayName = 'PostsSection';

export default PostsSection;
