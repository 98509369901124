import { call, select } from 'redux-saga/effects';
import { PapiRequestGruserModuleGroupMessagelog } from '@wix/da-papi-types';
import { PapiNcMessageObjectSchema } from '@wix/da-feedback-shared/pkg/redux/normalizr/schemas';
import { getMessageLogParams } from '../../selectors/groupsBackroom';
import { MessageLogStreamParams } from '../../../types/groupsBackroom';
import fetchModuleStream from './fetchModuleStream';

export default function* fetchGroupMessageLogStream(action) {
  const {
    instigatorRoleid,
    instigatorModuleType,
    sortBy,
    status,
  }: MessageLogStreamParams = yield select(getMessageLogParams);

  return yield call(fetchModuleStream, action, PapiNcMessageObjectSchema, {
    instigator_roleid: instigatorRoleid,
    instigator_module_type: instigatorModuleType,
    status,
    sort_by: sortBy,
  } as PapiRequestGruserModuleGroupMessagelog);
}
