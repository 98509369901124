import React, { useCallback } from 'react';
import AddItem from '@wix/da-ds/pkg/AddItem';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { useVerification } from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import { MAX_TIERS_COUNT } from '../constants';
import { BiData, TierAddNewBiEvent } from '@wix/da-bi/pkg/events';

export type Props = {
  className?: string;
  count: number;
  view?: 'promotion' | 'product';
};
export const AddTierCard: React.FC<Props> = ({ className, count, view }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createTier = useCallback(
    () =>
      dispatch(
        pushModal(ModalType.TIER_EDIT, {
          wrapInModal: false,
        })
      ),
    [dispatch]
  );
  // users who are gifted core can see these buttons even before they're verified
  const { restrictToVerified } = useVerification();
  const restrictedCreateTier = restrictToVerified(
    'createsubscription',
    createTier
  );

  const canCreateTier = count < MAX_TIERS_COUNT;

  return canCreateTier ? (
    <AddItem
      className={className}
      title={t('widgets.tiers.addItem.title')}
      subtitle={t('widgets.tiers.addItem.subtitle', {
        count,
        total: MAX_TIERS_COUNT,
      })}
      onClick={restrictedCreateTier}
      biData={BiData<TierAddNewBiEvent>({
        evid: 530,
        is_empty_state_view: count === 0 ? 1 : 0,
        widgetname: view === 'promotion' ? 'tiers' : undefined,
      })}
    />
  ) : (
    <AddItem
      className={className}
      title={t('widgets.tiers.addItem.title.disabled')}
      subtitle={t('widgets.tiers.addItem.subtitle.disabled')}
      disabled={true}
    />
  );
};

AddTierCard.displayName = 'AddTierCard';
export default AddTierCard;
