import {
  GallectionType,
  GallectionResourceType,
} from '@wix/da-shared-react/pkg/types/gallection';

export { GallectionType, GallectionResourceType };

export enum GallectionSourceType {
  FEATURED_GALLERY = 'featured_gallery',
  NEWEST = 'newest',
  GALLERY = 'gallery',
  COLLECTION = 'collection',
  PRIVATE_COLLECTION = 'private_collection',
  ADOPTABLE_BOUGHT = 'adoptable_bought',
  ADOPTABLE_FOR_SALE = 'adoptable_for_sale',
}

export enum GallectionSortOrder {
  NEWEST = 'newest',
  POPULAR = 'popular',
}
