import React, { useContext } from 'react';
import WidgetHeader from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetHeader';
import WidgetHeaderMobile from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetHeaderMobile';
import useModuleDataByName from '@wix/da-gruser-shared/pkg/hooks/useModuleDataByName';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import Slider from '@wix/da-ds/pkg/Slider';
import SupportersIcon from '@wix/da-ds/pkg/Icons/24x24/Supporters';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';
import TopSupporterCard from '../ShopAdoptables/TopSupporterCard';
import { useTranslation } from 'react-i18next';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import Flex from '@wix/da-ds/pkg/Flex';

import s from './TopSupporters.scss';

interface Props {}

const DEFAULT_CARD_WIDTH_MOBILE = 120;
const MOBILE_CARDS_GAP = 16;
const MOBILE_CARDS_PER_SLIDE = 2;
const DEFAULT_CARD_WIDTH_DESKTOP = 138;

export const MIN_SUPPORTERS_COUNT_TO_RENDER = 3;

export enum TimePeriod {
  RECENT = 'recent',
  ALL_TIME = 'alltime',
}

const TopSupporters: React.FC<Props> = () => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();
  const timePeriod = TimePeriod.RECENT;
  const moduleData = useModuleDataByName('supporters') || {};
  const supporters = moduleData.timePeriods?.[timePeriod]?.results || [];

  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    undefined,
    {
      cookieDim: 'width',
    }
  );

  if (supporters.length < MIN_SUPPORTERS_COUNT_TO_RENDER) {
    return null;
  }

  const header = (
    <Heading className={s['heading']}>
      {t('pages.shop.widgets.topSupporters.header')}
      <SupportersIcon withGradient disableDefaultColors />
    </Heading>
  );
  const subHeader = t('pages.shop.widgets.topSupporters.subheader');

  const renderCards = () => {
    if (isMobile) {
      const chunkedSupporters: any = [];
      for (let i = 0; i < supporters.length; i += 2) {
        chunkedSupporters.push(supporters.slice(i, i + 2));
      }
      return chunkedSupporters.map((supporterPair, index) => (
        <Flex gap={MOBILE_CARDS_GAP} key={index}>
          {supporterPair.map(({ user, type }, userIndex) => (
            <TopSupporterCard
              key={userIndex}
              user={user}
              type={type}
              typeName={t(`pages.shop.widgets.supporterType.${type}`)}
              width={
                measuredContainer?.width
                  ? (measuredContainer?.width - MOBILE_CARDS_GAP) /
                    MOBILE_CARDS_PER_SLIDE
                  : DEFAULT_CARD_WIDTH_MOBILE
              }
            />
          ))}
        </Flex>
      ));
    } else {
      return supporters.map(({ user, type }, index) => (
        <TopSupporterCard
          key={index}
          user={user}
          type={type}
          typeName={t(`pages.shop.widgets.supporterType.${type}`)}
          width={DEFAULT_CARD_WIDTH_DESKTOP}
        />
      ));
    }
  };

  return (
    <div className={s['root']}>
      {isMobile ? (
        <WidgetHeaderMobile
          header={header}
          subHeader={subHeader}
          isSticky={false}
          rootClassName={s['widget-header']}
        />
      ) : (
        <WidgetHeader
          header={header}
          subHeader={subHeader}
          className={s['widget-header']}
        />
      )}
      <div className={s['widget-content']}>
        {isMobile ? (
          <div className={s['swipe-slider-container']} ref={ref}>
            <SwipeSlider>{renderCards()}</SwipeSlider>
          </div>
        ) : (
          <Slider className={s['slider']}>{renderCards()}</Slider>
        )}
      </div>
    </div>
  );
};
TopSupporters.displayName = 'TopSupporters';

export default TopSupporters;
