import {
  getCustomInitActionsForWidgets,
  getPageInfoInitActions,
} from './common';
import { getAboutSectionWidgetConfigs } from '../../components/Sections/AboutSection/aboutSectionWidgetConfigs';

export const getCustomInitActionsForAboutWidgets = (
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getAboutSectionWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  ...getPageInfoInitActions(responseData, requestParams),
];
