import i18next from 'i18next';
import {
  all,
  take,
  takeEvery,
  takeLatest,
  put,
  select,
  call,
  race,
  delay,
} from 'redux-saga/effects';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { PapiGroupnote } from '../../types/papi';
import { getCurrentUser } from '@wix/da-shared-react/pkg/publicSession/selectors';
import ReceiptIcon from '@wix/da-ds/pkg/Icons/24x24/Receipt';
import {
  NotificationType,
  actions as notificationActions,
} from '@wix/da-shared-react/pkg/Notifications';
import {
  GROUP_ACTIONLOG_IDS_STREAM,
  GROUP_MESSAGELOG_IDS_STREAM,
  MESSAGES_PER_FETCH,
} from '../streams';
import {
  filterGroupActionLogByRole,
  sortGroupActionLog,
  filterGroupMessageLogByNotificationType,
  filterGroupMessageLogByRole,
  filterGroupMessageLogByStatus,
  sortGroupMessageLog,
  starNotes,
  unstarNotes,
  archiveNotes,
  unarchiveNotes,
} from '../actions/backroomSection';

import {
  updateModule,
  fetchModuleEndpoint,
  fetchModuleEndpointError,
  fetchModuleEndpointSuccess,
} from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import {
  getModuleIdByModuleName,
  getModuleData,
} from '@wix/da-gruser-shared/pkg/redux/selectors/modules';

import { getProfileOwnerUser, getProfileGruser } from '../selectors/users';
import { ModuleType } from '../../types/modules';
import { getSelectedFolder } from '../selectors/backroomSection';

import { GroupNotesFolderType } from '../../types/backroomSection';

function* filterGroupActionLog(newStreamParams) {
  const streamParams = yield select(
    streamSelectors.getStreamParams,
    GROUP_ACTIONLOG_IDS_STREAM
  );
  yield put(
    withOffset.actions.initialize({
      streamId: GROUP_ACTIONLOG_IDS_STREAM,
      streamParams: {
        ...streamParams,
        ...newStreamParams,
      },
      itemsPerFetch: MESSAGES_PER_FETCH,
    })
  );
  yield put(withOffset.actions.fetch(GROUP_ACTIONLOG_IDS_STREAM));
}

function* handleFilterGroupActionLogByRole(
  action: ReturnType<typeof filterGroupActionLogByRole>
) {
  yield call(filterGroupActionLog, { roleId: action.payload.roleId });
}

function* handleSortGroupActionLog(
  action: ReturnType<typeof sortGroupActionLog>
) {
  yield call(filterGroupActionLog, { sortValue: action.payload.sortValue });
}

function* filterGroupMessageLog(newStreamParams) {
  const streamParams = yield select(
    streamSelectors.getStreamParams,
    GROUP_MESSAGELOG_IDS_STREAM
  );
  yield put(
    withOffset.actions.initialize({
      streamId: GROUP_MESSAGELOG_IDS_STREAM,
      streamParams: {
        ...streamParams,
        ...newStreamParams,
      },
      itemsPerFetch: MESSAGES_PER_FETCH,
    })
  );
  yield put(withOffset.actions.fetch(GROUP_MESSAGELOG_IDS_STREAM));
}

function* handleFilterMessageLogByNotificationType(
  action: ReturnType<typeof filterGroupMessageLogByNotificationType>
) {
  yield call(filterGroupMessageLog, {
    instigatorModuleType: action.payload.notificationType,
  });
}

function* handleFilterMessageLogByRole(
  action: ReturnType<typeof filterGroupMessageLogByRole>
) {
  yield call(filterGroupMessageLog, {
    instigatorRoleid: action.payload.roleId,
  });
}

function* handleFilterMessageLogByStatus(
  action: ReturnType<typeof filterGroupMessageLogByStatus>
) {
  yield call(filterGroupMessageLog, { status: action.payload.status });
}

function* handleSortMessageLog(action: ReturnType<typeof sortGroupMessageLog>) {
  yield call(filterGroupMessageLog, { sortBy: action.payload.sortBy });
}

function* tagNotes(
  noteids: number[],
  tag: 'star' | 'unstar' | 'archive' | 'unarchive'
) {
  const { username } = yield select(getProfileOwnerUser);

  const gruser = yield select(getProfileGruser);
  const id = yield select(
    getModuleIdByModuleName,
    gruser,
    ModuleType.GROUP_NOTES
  );
  yield put(
    fetchModuleEndpoint(gruser, {
      id,
      username,
      path: '/group_notes/tag',
      method: 'post',

      noteids,
      action: tag,
    })
  );
  const { error } = yield race({
    success: take(fetchModuleEndpointSuccess),
    error: take(fetchModuleEndpointError),
  });

  if (error) {
    return false;
  }
  return true;
}

function* getNotes(noteids: number[]) {
  const gruser = yield select(getProfileGruser);
  const id = yield select(
    getModuleIdByModuleName,
    gruser,
    ModuleType.GROUP_NOTES
  );
  const moduleData = yield select(getModuleData, gruser, id);
  return moduleData.results;
}

// update normalized entities in the store
function* updateGroupnotes(
  noteids: number[],
  overrides: Partial<PapiGroupnote> = {}
) {
  const gruser = yield select(getProfileGruser);
  const id = yield select(
    getModuleIdByModuleName,
    gruser,
    ModuleType.GROUP_NOTES
  );
  const moduleData = yield select(getModuleData, gruser, id);
  let { results = [] } = moduleData;
  results = results.map(note => {
    if (noteids.includes(note.noteId)) {
      note = { ...note, ...overrides };
    }
    return note;
  });
  yield put(
    updateModule(gruser, {
      id,
      moduleData: {
        ...moduleData,
        results,
      },
    })
  );
  return;
}

function* handleStarNotes(action: ReturnType<typeof starNotes>) {
  const noteIds = action.payload.noteIds;
  const success = yield call(tagNotes, noteIds, 'star');
  if (success) {
    yield call(updateGroupnotes, noteIds, { isStarred: true });
  }
}

function* removeGroupNotesFromTheFolder(
  noteIds: number[],
  folder: GroupNotesFolderType
) {
  const currentFolder = yield select(getSelectedFolder);
  if (folder !== currentFolder || !noteIds?.length) {
    return;
  }

  const gruser = yield select(getProfileGruser);
  const id = yield select(
    getModuleIdByModuleName,
    gruser,
    ModuleType.GROUP_NOTES
  );
  const moduleData = yield select(getModuleData, gruser, id);
  yield put(
    updateModule(gruser, {
      id,
      moduleData: {
        ...moduleData,
        results: moduleData.results.filter(
          note => !noteIds.includes(note.noteId)
        ),
      },
    })
  );
}

function* addGroupNotesToTheFolder(
  noteIds: number[],
  folder: GroupNotesFolderType
) {
  const currentFolder = yield select(getSelectedFolder);
  if (folder !== currentFolder || !noteIds?.length) {
    return;
  }

  const gruser = yield select(getProfileGruser);
  const id = yield select(
    getModuleIdByModuleName,
    gruser,
    ModuleType.GROUP_NOTES
  );
  const moduleData = yield select(getModuleData, gruser, id);
  yield put(
    updateModule(gruser, {
      id,
      moduleData: {
        ...moduleData,
        results: [...moduleData.results, ...noteIds]
          // unique
          .filter(
            (u, idx, self) =>
              self.findIndex(u2 => u.userId === u2.userId) === idx
          ),
      },
    })
  );
}

function* handleUnstarNotes(action: ReturnType<typeof unstarNotes>) {
  const noteIds = action.payload.noteIds;
  const success = yield call(tagNotes, noteIds, 'unstar');
  if (success) {
    yield call(updateGroupnotes, noteIds, { isStarred: false });
    // Since back-end does not return us new list of notes, we manually have to remove these notes from the GROUP_NOTES moduleData
    yield call(
      removeGroupNotesFromTheFolder,
      noteIds,
      GroupNotesFolderType.STARRED
    );
  }
}

function* handleArchiveNotes(action: ReturnType<typeof archiveNotes>) {
  const noteIds = action.payload.noteIds;
  const success = yield call(tagNotes, noteIds, 'archive');
  if (!success) {
    return;
  }
  // Update notes in the store, mark them as archived
  yield call(updateGroupnotes, noteIds, { isArchived: true });

  // Archived notes can show up in inbox if you are the sender (but only the
  // sender, other users should not see them in inbox). If the user archived
  // notes that they were not the sender of, we remove them from the stream.
  const notes = yield call(getNotes, noteIds);
  const currentUser = yield select(getCurrentUser);
  const nonSenderNoteIds = notes
    .filter(note => note.sender.userId !== currentUser.userId)
    .map(note => note.noteId);

  // Remove these items from the moduleData if we're in the inbox folder
  yield call(
    removeGroupNotesFromTheFolder,
    nonSenderNoteIds,
    GroupNotesFolderType.INBOX
  );

  const duration = 5000;

  const notification = {
    message: i18next.t('widgets.group_notes.notification.archive'),
    isError: true,
    cardProps: {
      multiLine: true,
      duration,
      iconComponent: ReceiptIcon,
    },
  };
  yield put(
    notificationActions.addNotification(
      NotificationType.GenericUndo,
      notification
    )
  );

  // Wait for an optional undo action
  const { undo } = yield race({
    undo: take(notificationActions.genericUndoAction),
    dismiss: take(notificationActions.removeNotificationFromQueue),
    wait: delay(duration),
  });

  if (undo) {
    yield put(unarchiveNotes(noteIds));
    const undoNotification = {
      ...notification,
      message: i18next.t('widgets.group_notes.notification.undone'),
    };
    yield put(
      notificationActions.addNotification(
        NotificationType.Generic,
        undoNotification
      )
    );
    return;
  }
}

function* handleUnarchiveNotes(action: ReturnType<typeof unarchiveNotes>) {
  const noteIds = action.payload.noteIds;
  const success = yield call(tagNotes, noteIds, 'unarchive');
  if (success) {
    yield call(updateGroupnotes, noteIds, { isArchived: false });

    // Remove these items from the moduleData if we're in the archived folder
    yield call(
      removeGroupNotesFromTheFolder,
      noteIds,
      GroupNotesFolderType.ARCHIVED
    );

    // put them back into inbox
    yield call(addGroupNotesToTheFolder, noteIds, GroupNotesFolderType.INBOX);
  }
}

export default function* rootGroupsBackroomSaga() {
  yield all([
    takeEvery(filterGroupActionLogByRole, handleFilterGroupActionLogByRole),
    takeEvery(sortGroupActionLog, handleSortGroupActionLog),
    takeEvery(
      filterGroupMessageLogByNotificationType,
      handleFilterMessageLogByNotificationType
    ),
    takeEvery(filterGroupMessageLogByRole, handleFilterMessageLogByRole),
    takeEvery(filterGroupMessageLogByStatus, handleFilterMessageLogByStatus),
    takeEvery(sortGroupMessageLog, handleSortMessageLog),
    takeLatest(starNotes, handleStarNotes),
    takeLatest(unstarNotes, handleUnstarNotes),
    takeLatest(archiveNotes, handleArchiveNotes),
    takeLatest(unarchiveNotes, handleUnarchiveNotes),
  ]);
}
