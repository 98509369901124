import { handleActions } from 'redux-actions';
import {
  closeSkinSelector,
  finishSaving,
  openSkinSelector,
  saveActiveSkin,
  setActiveSkin,
  setCustomSkin,
} from '../actions/profileSkins';
import { AppState } from '../../types/store';
import { ProfileSkinsSlice } from '../../types/profileSkins';

export default handleActions<AppState['profileSkins'], any>(
  {
    [setActiveSkin.toString()]: (
      state: ProfileSkinsSlice,
      action: ReturnType<typeof setActiveSkin>
    ) => {
      return {
        ...state,
        activeSkin: action.payload.skin,
      };
    },
    [setCustomSkin.toString()]: (
      state: ProfileSkinsSlice,
      action: ReturnType<typeof setCustomSkin>
    ) => {
      return {
        ...state,
        customSkin: action.payload.skin,
      };
    },
    [saveActiveSkin.toString()]: (
      state: ProfileSkinsSlice,
      action: ReturnType<typeof saveActiveSkin>
    ) => {
      return {
        ...state,
        isSkinSaving: true,
      };
    },
    [openSkinSelector.toString()]: (state: ProfileSkinsSlice) => {
      return {
        ...state,
        skinSelectorShown: true,
        isSkinSaving: false,
      };
    },
    [closeSkinSelector.toString()]: (state: ProfileSkinsSlice) => {
      return {
        ...state,
        skinSelectorShown: false,
        isSkinSaving: false,
      };
    },
    [finishSaving.toString()]: (
      state: ProfileSkinsSlice,
      action: ReturnType<typeof finishSaving>
    ) => {
      return {
        ...state,
        isSkinSaving: false,
      };
    },
  },
  {
    skinSelectorShown: false,
    isSkinSaving: false,
  }
);
