import React from 'react';
import c from 'classnames';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import s from './WidgetBoxWrapper.scss';

export interface Props {
  id?: string;
  className?: string;
  children: React.ReactNode;
}

export const WidgetBoxWrapper = React.forwardRef<HTMLDivElement, Props>(
  ({ className, id, children, ...props }, ref) => {
    return (
      <Section id={id} className={c(s['root'], className)} ref={ref} {...props}>
        {children}
      </Section>
    );
  }
);
WidgetBoxWrapper.displayName = 'WidgetBoxWrapper';

export default WidgetBoxWrapper;
