import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './DraggableProfileHero';
import { AppState } from '../../../../../types/store';
import { getProfileHeroDeviation } from '../../../../selectors/profileHeroEditor';
import { updateProfileHeroCropArea } from '../../../../actions/profileHeroEditor';

export type StateProps = Pick<Props, 'coverDeviation'>;
export type DispatchProps = Pick<Props, 'updateProfileHeroCropArea'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const coverDeviation = getProfileHeroDeviation(state)!;
  return {
    coverDeviation,
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  updateProfileHeroCropArea,
};

export default connect(mapStateToProps, mapDispatchToProps);
