import { createAction } from 'redux-actions';

export const fetchModuleDataSuccess = createAction(
  'MODULE.FETCH.SUCCESS',
  (payload: Record<string, any>) => payload
);

export interface UpdateModulePayload {
  id: number;
  moduleMeta?: any;
  moduleData?: any;
}

export interface ModuleEndpointPayload {
  id: number;
  path: string;
  method: 'post' | 'get';
  [key: string]: any;
}

export const updateModule = createAction(
  'MODULE.UPDATE',
  (payload: UpdateModulePayload) => payload
);

export type ModuleConfigPayload = {
  moduleId: number;
};

export const fetchModuleConfig = createAction(
  'MODULE.CONFIG.FETCH',
  (payload: ModuleConfigPayload) => payload
);

export const fetchModuleConfigSuccess = createAction(
  'MODULE.CONFIG.FETCH.SUCCESS',
  (payload: { id: number; config: any }) => payload
);

export const fetchModuleConfigError = createAction(
  'MODULE.CONFIG.FETCH.ERROR',
  (payload: { id: number; errorDescription: string }) => payload
);

export const saveModuleConfig = createAction(
  'MODULE.CONFIG.SAVE',
  (payload: Record<string, any>) => payload
);

export const saveModuleConfigSuccess = createAction(
  'MODULE.CONFIG.SAVE.SUCCESS',
  (payload: { id: number }) => payload
);

export const saveModuleConfigError = createAction(
  'MODULE.CONFIG.SAVE.ERROR',
  (payload: { id: number; errorDescription: string }) => payload
);

export const fetchModuleEndpoint = createAction(
  'MODULE.ENDPOINT.FETCH',
  (payload: ModuleEndpointPayload) => payload
);

export const fetchModuleEndpointSuccess = createAction(
  'MODULE.ENDPOINT.FETCH.SUCCESS',
  (payload: { id: number; path: string; response: any }) => payload
);

export const fetchModuleEndpointError = createAction(
  'MODULE.ENDPOINT.FETCH.ERROR',
  (payload: { id: number; path: string; errorDescription: string }) => payload
);

export const fetchModuleEndpointPurgeData = createAction(
  'MODULE.ENDPOINT.PURGE_DATA',
  (payload: { id: number; path: string }) => payload
);

export const purgeAllModules = createAction('MODULE.PURGE.ALL', () => ({}));

export const inviteModuleInitialized = createAction(
  `MODULE.INVITE.INITIALIZED`,
  () => null
);

export const inviteModuleSaved = createAction(
  `MODULE.INVITE.SAVED`,
  () => null
);

export const recruitmentModuleInitialized = createAction(
  `MODULE.RECRUITMENT.INITIALIZED`,
  () => null
);

export const recruitmentModuleSaved = createAction(
  `MODULE.RECRUITMENT.SAVED`,
  () => null
);
