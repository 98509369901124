import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './FolderBreadcrumbs';
import { AppState } from '../../../../../../types/store';
import {
  getSelectedSubfolder,
  getSelectedFolder,
} from '../../../../../selectors/gallectionSection';
import { unsetSelectedSubfolderId } from '../../../../../actions/gallectionSection';

export type StateProps = Pick<Props, 'folder' | 'subfolder'>;
export type DispatchProps = Pick<Props, 'unsetSelectedSubfolderId'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  folder: getSelectedFolder(state),
  subfolder: getSelectedSubfolder(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  unsetSelectedSubfolderId,
};

export default connect(mapStateToProps, mapDispatchToProps);
