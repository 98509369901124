import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBoxWide, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import AboutMe from '../AboutMe';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useSelector } from 'react-redux';
import { getProfileOwnerUser } from '../../../../selectors/users';

import s from './AboutMeBoxWide.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const AboutMeBoxWide: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const user = useSelector(getProfileOwnerUser);

  const header = isMobile ? (
    t('widgets.aboutMe.header')
  ) : (
    <UserLink
      user={user}
      symbolClassName={s['user-symbol']}
      withTooltip={false}
    />
  );

  return (
    <WidgetBoxWide moduleId={moduleId} header={header} {...widgetBoxProps}>
      <AboutMe moduleId={moduleId} />
    </WidgetBoxWide>
  );
};
AboutMeBoxWide.displayName = 'AboutMeBoxWide';

export default AboutMeBoxWide;
