import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../../../types/store';
import { getProfileOwnerUser } from '../../../../../../../selectors/users';
import { getCanPerformPageAction } from '../../../../../../../selectors/privs';
import { getSelectedFolder } from '../../../../../../../selectors/gallectionSection';
import { getActiveSection } from '../../../../../../../selectors/sections';
import { contributeDeviations } from '../../../../../../../actions/groups';
import { PagePriv } from '../../../../../../../../types/pagePrivileges';
import { Props } from './SubmitButton';

export type StateProps = Pick<
  Props,
  | 'profileOwnerUser'
  | 'canContributeDeviations'
  | 'canRecommendDeviations'
  | 'canSubmitJournal'
  | 'selectedFolder'
>;
export type DispatchProps = Pick<Props, 'contributeDeviations'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  profileOwnerUser: getProfileOwnerUser(state),
  canContributeDeviations: getCanPerformPageAction(
    state,
    PagePriv.CAN_CONTRIBUTE_DEVIATIONS
  ),
  canRecommendDeviations: getCanPerformPageAction(
    state,
    PagePriv.CAN_RECOMMEND_DEVIATIONS
  ),
  canSubmitJournal: getCanPerformPageAction(state, PagePriv.CAN_SUBMIT_JOURNAL),
  selectedFolder:
    getActiveSection(state) === 'gallery'
      ? getSelectedFolder(state)
      : undefined,
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  contributeDeviations,
};

export default connect(mapStateToProps, mapDispatchToProps);
