import React from 'react';
import Loader from '@wix/da-ds/pkg/Loader';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';

import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';

import ActionLog from './ActionLog';
import ActionLogFilters from './ActionLogFilters';
import OrderDropdown from './OrderDropdown';

import s from './ActionLog.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const ActionLogBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  return (
    <WidgetBox
      moduleId={moduleId}
      header={<ActionLogFilters moduleId={moduleId} />}
      headerExtras={<OrderDropdown />}
      {...widgetBoxProps}
    >
      <ErrorBoundary debugComponent="ActionLogBox">
        <React.Suspense
          fallback={
            <Loader state="eating_grass" size="big" className={s['loader']} />
          }
        >
          <ActionLog moduleId={moduleId} />
        </React.Suspense>
      </ErrorBoundary>
    </WidgetBox>
  );
};
ActionLogBox.displayName = 'ActionLogBox';

export default ActionLogBox;
