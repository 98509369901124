import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { openCommissionEditModal } from '@wix/da-shared-react/pkg/redux/commissions/actionCreators';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import {
  getProfileGruser,
  isCurrentUserProfileOwner,
} from '../../../../selectors/users';
import { AppState } from '../../../../../types/store';
import { Props } from './ShopCommissions';

export type DispatchProps = Pick<Props, 'onCreate'>;
type StateProps = Pick<Props, 'isOpenForCommissions' | 'isOwner'>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const gruser = getProfileGruser(state);
  const moduleData = getModuleDataByModuleName(
    state as any,
    gruser,
    'commissions'
  );
  const { isOpenForCommissions } = moduleData;
  return {
    isOpenForCommissions,
    isOwner: isCurrentUserProfileOwner(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    onCreate: () =>
      dispatch(openCommissionEditModal({ deviationId: undefined })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
