import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import InputLabelText from '@wix/da-ds/pkg/formControls/lego/InputLabelText';
import FormElement from '@wix/da-ds/pkg/formControls/layouts/FormElement';
import UserInput from '@wix/da-shared-react/pkg/Chat/UserInput';
import { useController, useFormContext } from 'react-hook-form';

import ErrorMessage from '../../../../_partials/Form/ErrorMessage';
import s from './UserInputField.scss';

interface Props {
  name: string;
  isRequired?: boolean;
  label: string;
  placeholder?: string;
}
const UserInputField: React.FC<Props> = ({
  name,
  isRequired,
  label,
  placeholder,
  ...props
}) => {
  const { control, setError, clearErrors } = useFormContext();
  const { t } = useTranslation();
  const id = useUniqueId();

  const {
    field: { ref, value, onChange, ...inputProps },
    meta: { invalid },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  const handleNoMatchingUsers = useCallback(
    inputValue => {
      if (inputValue.length) {
        setError(name, {
          type: 'custom',
          message: t('groups.invite.popup.form.errors.userMustExist'),
        });
      }
    },
    [setError, name, t]
  );

  const handleKeyDown = useCallback(
    e => {
      if (value) {
        e.preventDefault();
        return;
      }
      if (e.target.value.length === 0) {
        clearErrors(name);
      }
    },
    [clearErrors, name, value]
  );

  const handleAddUser = useCallback(
    user => {
      clearErrors();
      onChange(user);
    },
    [clearErrors, onChange]
  );

  const resetField = useCallback(() => onChange(undefined), [onChange]);
  const users = useMemo(() => (value ? [value] : []), [value]);
  const placeholderMemo = useMemo(
    () => (!value ? placeholder : undefined),
    [value, placeholder]
  );

  return (
    <FormElement
      inputId={id}
      label={<InputLabelText isRequired={isRequired}>{label}</InputLabelText>}
      details={<ErrorMessage name={name} />}
    >
      <UserInput
        className={invalid ? s['error'] : s['root']}
        prefix={<SearchIcon size={IconSize.SMALL} />}
        users={users}
        onAddUser={handleAddUser}
        onRemoveUser={resetField}
        onRemoveAll={resetField}
        onKeyDown={handleKeyDown}
        onNoMatchingUsers={handleNoMatchingUsers}
        disabled={!!value}
        placeholder={placeholderMemo}
        {...props}
        {...inputProps}
      />
    </FormElement>
  );
};
UserInputField.displayName = 'UserInputField';

export default UserInputField;
