import React from 'react';
import { withRouter } from 'react-router-dom';
import escapeRegExp from 'lodash/escapeRegExp';
import classnames from 'classnames';
import { SubSubNavBarProps } from '../types';
import ArrowDownIcon from '@wix/da-ds/pkg/Icons/ArrowDown';
import WidgetHeaderMobile from '../../UserWidgets/_partials/WidgetHeaderMobile';
import CommissionCreateButton from '../../Sections/ShopSection/_partials/CommissionCreateButton';

import s from '../SubSubNavBar.scss';

class SubSubNavBarMobile extends React.Component<SubSubNavBarProps> {
  dropdownRef: React.RefObject<any> = React.createRef();

  render() {
    const { location, items, className, iconClassName, rootClassName } =
      this.props;
    const activePath = `${location.pathname || items[0].path}/`
      .replace(/\/{2,}/g, '/')
      .toLowerCase();
    const activePathReg = new RegExp(
      `${escapeRegExp(activePath)}?`.replace(/\//g, '\\/'),
      'i'
    );
    const activeItem = items.find(item => activePathReg.test(item.path)) || {
      label: '',
      count: '',
      path: '',
    };

    return (
      <WidgetHeaderMobile
        className={className}
        rootClassName={rootClassName}
        header={activeItem.label}
        count={activeItem.count}
        isSticky
        extras={
          <>
            <span className={classnames(s['open-menu-button'], iconClassName)}>
              <ArrowDownIcon />
            </span>
            <select
              className={s['dropdown']}
              ref={this.dropdownRef}
              value={activeItem.path}
              onChange={this.navigateToSubsection}
            >
              {items.map((item, index) => (
                <option key={index} value={item.path}>
                  {`${item.label} (${item.count})`}
                </option>
              ))}
            </select>
            {activeItem.label === 'Commissions' && (
              <CommissionCreateButton
                className={s['commission-create-button']}
              />
            )}
          </>
        }
      />
    );
  }

  navigateToSubsection = () => {
    const newPath = this.dropdownRef.current && this.dropdownRef.current.value;
    newPath && this.props.history.push(newPath);
  };
}

export default withRouter(SubSubNavBarMobile);
