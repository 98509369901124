import React from 'react';
import classnames from 'classnames';

import { PapiGroupnote } from '../../../../../../types/papi';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';

import GroupNotesListItem from '../GroupNotesListItem';
import s from './GroupNotesList.scss';

export interface Props {
  className?: string;
  items?: PapiGroupnote[];
  onItemClick: (noteId: number) => void;
  onItemCheckboxChange?: (noteId: number, isChecked: boolean) => void;
  currentNoteId?: number;
  selectedNoteIds?: number[];
}

const GroupNotesList: React.FC<Props> = ({
  className,
  items = [],
  onItemClick,
  onItemCheckboxChange,
  currentNoteId,
  selectedNoteIds,
}) => {
  return (
    <ErrorBoundary debugComponent="GroupNotesList">
      <div className={classnames(s['root'], className)}>
        {!items.length && <div>Such empty</div>}
        {items.map(item => (
          <div key={item.noteId} className={s['item']}>
            <GroupNotesListItem
              item={item}
              active={currentNoteId === item.noteId}
              selected={selectedNoteIds?.includes(item.noteId)}
              onClick={() => onItemClick(item.noteId)}
              onChange={isChecked =>
                onItemCheckboxChange?.(item.noteId, isChecked)
              }
            />
          </div>
        ))}
      </div>
    </ErrorBoundary>
  );
};
GroupNotesList.displayName = 'GroupNotesList';

export default GroupNotesList;
