import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiModuleMyComments } from '@wix/da-papi-types';
import { Props as WidgetBoxProps } from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import WidgetBoxWide from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { StreamType, withOffset } from '@wix/da-shared-react/pkg/Stream';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import { ModuleType } from '../../../../types/modules';
import {
  MY_COMMENTS_STREAM,
  MY_COMMENTS_STREAM_ITEMS_PER_FETCH,
} from '../../../streams';
import MyComment from './MyComment';
import s from './MyComments.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const MyCommentsBoxWide: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const modulePrivs = useModulePrivileges(moduleId);
  const isOwner = Boolean(modulePrivs?.edit);
  const dispatch = useDispatch();

  const { items, hasMore } = useStream<PapiModuleMyComments['results'][0]>(
    MY_COMMENTS_STREAM,
    500,
    {
      autoFetch: false,
      itemsPerFetch: MY_COMMENTS_STREAM_ITEMS_PER_FETCH,
      streamType: StreamType.WITH_OFFSET,
    }
  );

  const onClickLoadMore = () =>
    dispatch(withOffset.actions.fetch(MY_COMMENTS_STREAM));

  return (
    <WidgetBoxWide
      moduleId={moduleId}
      id={ModuleType.MY_COMMENTS}
      header={t('widgets.myComments.header')}
      {...widgetBoxProps}
    >
      <div className={s['root']}>
        <div className={classnames(s['comments'], !hasMore && s['last-set'])}>
          {items &&
            items
              .filter(item => !!item.comment)
              .map(({ subject, comment }, idx) => (
                <MyComment
                  className={s['item']}
                  subject={subject}
                  comment={comment}
                  isOwner={isOwner}
                  key={idx}
                />
              ))}
        </div>
        {items && hasMore && (
          <GhostButton
            variant="main"
            size="large"
            onClick={onClickLoadMore}
            fillContainerWidth
          >
            {t!('common.loadMore')}
          </GhostButton>
        )}
      </div>
    </WidgetBoxWide>
  );
};

MyCommentsBoxWide.displayName = 'MyCommentsBoxWide';

export default MyCommentsBoxWide;
