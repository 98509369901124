import {
  getCustomInitActionsForWidgets,
  getPageInfoInitActions,
} from './common';
import { setGallectionSectionType } from '../../actions/gallectionSection';
import { getGallectionTypeFromSection } from '../../utils/gallection';
import { getGallectionWidgetConfigs } from '../../components/Sections/GallectionsSection/gallectionWidgetConfigs';

export const getCustomInitActionsForGallectionWidgets = (
  section: 'gallery' | 'favourites' | 'private_collections',
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getGallectionWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  setGallectionSectionType(getGallectionTypeFromSection(section)),
  ...getPageInfoInitActions(responseData, requestParams),
];
