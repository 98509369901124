import React from 'react';
import { useTranslation } from 'react-i18next';
import { Props as WidgetBoxProps } from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import ElementQueries from '@wix/da-shared-react/pkg/ElementQueries';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import WidgetBoxWide from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import { useSelector } from 'react-redux';
import { getProfileOwnerStats } from '../../../selectors/users';
import { ModuleType } from '../../../../types/modules';
import s from './StatsBoxWide.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const StatsBoxWide: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const stats = useSelector(getProfileOwnerStats);

  const renderStatItems = () => {
    const statsToDisplay = [
      'pageviews',
      'deviations',
      'watchers',
      'watching',
      'favourites',
      'commentsMade',
      'commentsReceivedProfile',
    ];

    return statsToDisplay.map(entity => {
      return (
        <div key={entity} className={s['stat-item']}>
          <span className={s['stat-name']}>
            {t(`common.${entity}`, { count: stats[entity] })}
          </span>
          {shortenNumber(stats[entity])}
        </div>
      );
    });
  };

  return (
    <WidgetBoxWide
      moduleId={moduleId}
      id={ModuleType.STATS}
      header={t('widgets.statistics.header')}
      {...widgetBoxProps}
    >
      <ElementQueries
        className={s['stats-widget']}
        widthBreakpoints={[640, 800]}
      >
        {renderStatItems()}
      </ElementQueries>
    </WidgetBoxWide>
  );
};

StatsBoxWide.displayName = 'StatsBoxWide';

export default StatsBoxWide;
