import React, { CSSProperties, HTMLProps } from 'react';
import { useReducedMotion } from '@wix/da-hooks/pkg/useReducedMotion';
import { useDeviationMatureFilter } from '@wix/da-shared-react/pkg/utils/hooks/useDeviationMatureFilter';
import { ProfileSkin } from '../../../../types/profileSkins';

interface Props extends HTMLProps<HTMLDivElement> {
  profileSkin?: ProfileSkin;
  activeSection: string;
}

const BackgroundContainer: React.FC<Props> = ({
  profileSkin,
  activeSection,
  ...props
}) => {
  const reduceMotion = useReducedMotion();
  const { showBlocked } = useDeviationMatureFilter(profileSkin?.skin?.image);

  const noBackgroundSections = ['about', 'stats', 'shop', 'backroom'];
  const disableSkin =
    !profileSkin || noBackgroundSections.includes(activeSection);

  if (disableSkin) {
    return <div {...props} />;
  }

  let backgroundColor: any;
  let backgroundImage: any;
  if (profileSkin.skin?.background.startsWith('#')) {
    backgroundColor = profileSkin.skin?.background;
  } else {
    if (reduceMotion && profileSkin.skin?.staticBackground) {
      backgroundImage = profileSkin.skin?.staticBackground;
    } else {
      backgroundImage = profileSkin.skin?.background;
    }
  }
  const imageDisplayMode = profileSkin.skin?.imageDisplayMode;
  const backgroundSize = ['contain', 'cover'].includes(imageDisplayMode || '')
    ? imageDisplayMode
    : undefined;
  const backgroundRepeat =
    !imageDisplayMode || imageDisplayMode === 'tile' ? 'repeat' : 'no-repeat';

  if (showBlocked) {
    backgroundImage = undefined;
  }
  const style: CSSProperties = {
    backgroundColor,
    backgroundImage,
    backgroundSize,
    backgroundRepeat,
    backgroundAttachment: 'fixed',
  };

  return <div {...props} style={style} />;
};
BackgroundContainer.displayName = 'BackgroundContainer';

export default BackgroundContainer;
