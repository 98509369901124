import { BiData, StatsChangeLevelClickBiEvent } from '@wix/da-bi/pkg/events';
import { StatsTimePeriod } from '../../../../types/statsSection';

export const routes = [
  { hash: 'alltime', transKey: 'pages.stats.widgets.alltime.title' },
  {
    hash: 'overview',
    transKey: 'pages.stats.widgets.overview.title',
  },
  {
    hash: 'recent_visitors',
    transKey: 'pages.stats.widgets.recentVisitors.title',
  },
  {
    hash: 'my_submissions',
    transKey: 'pages.stats.widgets.my_submissions.title',
  },
  { hash: 'traffic', transKey: 'pages.stats.widgets.traffic.title' },
  { hash: 'general', transKey: 'pages.stats.widgets.general.title' },
  { hash: 'benchmark', transKey: 'pages.stats.widgets.benchmark.title' },
];

export const timePeriods = [
  {
    index: StatsTimePeriod.Week,
    labelTransKey: 'pages.stats.week',
    biData: BiData<StatsChangeLevelClickBiEvent>({
      evid: 831,
      time_period: StatsTimePeriod.Week,
    }),
  },
  {
    index: StatsTimePeriod.Month,
    labelTransKey: 'pages.stats.month',
    biData: BiData<StatsChangeLevelClickBiEvent>({
      evid: 831,
      time_period: StatsTimePeriod.Month,
    }),
  },
  {
    index: StatsTimePeriod.Year,
    labelTransKey: 'pages.stats.year',
    biData: BiData<StatsChangeLevelClickBiEvent>({
      evid: 831,
      time_period: StatsTimePeriod.Year,
    }),
  },
];
