import { connect, MapStateToProps } from 'react-redux';
import ddt from '@wix/da-ddt';
import { getEnv } from '@wix/da-react-app/pkg/client/Environment/redux/selectors';
import { getIsDuperbrowseActive } from '@wix/da-shared-react/pkg/Duperbrowse/redux/selectors';
import { getPagingMode } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { AppState } from '../../../../../types/store';
import { getCurrentPage, getTotalPages } from '../../../../selectors/pageInfo';

import { Props } from './StreamPagination';

// TODO: remove with profile_shop_pagination_2
const logger = ddt.createLogger('streampagination');

export type StateProps = Pick<
  Props,
  | 'isDuperbrowseActive'
  | 'isInfiniteScroll'
  | 'requestUrl'
  | 'currentPage'
  | 'totalPages'
  | 'error'
>;
export type DispatchProps = Pick<Props, never>;
// allow passing totalPages but -default- to the value from the container state
// TODO: consolidate when moving to backend pagination data w/ profile_shop_pagination_2
export type NonPassableProps = Omit<StateProps, 'totalPages'>;
export type OwnProps = Omit<Props, keyof (NonPassableProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  { totalPages }
) => {
  const totalPagesFromState = getTotalPages(state);
  logger.log(JSON.stringify({ totalPages, totalPagesFromState }));
  const currentPage = getCurrentPage(state);
  const isInfiniteScroll = getPagingMode(state) === 'scroll';
  logger.log(JSON.stringify({ currentPage, isInfiniteScroll }));

  return {
    requestUrl: getEnv(state).requestUrl,
    isDuperbrowseActive: getIsDuperbrowseActive(state as any),
    isInfiniteScroll: getPagingMode(state) === 'scroll',
    currentPage,
    totalPages: totalPages ?? totalPagesFromState,
  };
};

export default connect(mapStateToProps);
