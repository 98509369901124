import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { PapiModuleFolderDeviations } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import {
  DEVIATIONS_PER_FETCH_COUNT,
  FOLDER_DEVIATIONS_STREAM_GROUP_ID,
  getFolderDeviationsStreamId,
  getFolderDeviationsStreamParams,
} from '../../../../../../streams/folderDeviations';
import { getGallectionTypeFromSection } from '../../../../../../utils/gallection';
import { setTotalPages } from '../../../../../../actions/pageInfo';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleFolderDeviations,
  requestParams: any
) => {
  const gallectionType = getGallectionTypeFromSection(requestParams.section);

  if (!gallectionType || !moduleData) {
    return [];
  }

  const { result, entities } = normalize(moduleData.deviations, [
    deviationSchema,
  ]);

  return [
    withOffset.actions.initialize({
      streamId: getFolderDeviationsStreamId(
        gallectionType,
        moduleData.folderId
      ),
      streamParams: getFolderDeviationsStreamParams(
        gallectionType,
        moduleData.folderId
      ),
      groupId: FOLDER_DEVIATIONS_STREAM_GROUP_ID,
      itemsPerFetch: DEVIATIONS_PER_FETCH_COUNT,
      nextOffset: moduleData.nextOffset!,
      items: result,
      hasMore: !result || !result.length ? false : moduleData.hasMore,
      entities,
    }),
    setTotalPages(moduleData.totalPageCount || 1),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
