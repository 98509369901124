import { createAction } from 'redux-actions';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';
import { ProfileSkin } from '../../types/profileSkins';

export const fetchMoreProfileSkins = createCallbackActionCreator(
  `PROFILE_SKINS.FETCH_MORE_SKINS`
);

export const setActiveSkin = createAction(
  'PROFILE_SKINS.SET_ACTIVE',
  (skin: ProfileSkin) => ({ skin })
);

export const setCustomSkin = createAction(
  'PROFILE_SKINS.SET_CUSTOM',
  (skin: ProfileSkin) => ({ skin })
);

export const saveSkin = createAction(
  'PROFILE_SKINS.SAVE',
  (skin: ProfileSkin) => ({ skin })
);

export const saveActiveSkin = createAction(
  'PROFILE_SKINS.SAVE_ACTIVE',
  () => ({})
);

export const openSkinSelector = createAction(
  'PROFILE_SKINS.OPEN_SKIN_SELECTOR',
  () => ({})
);

export const closeSkinSelector = createAction(
  'PROFILE_SKINS.CLOSE_SKIN_SELECTOR',
  () => ({})
);

export const finishSaving = createAction(
  'PROFILE_SKINS.FINISH_SAVING',
  () => ({})
);
