import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import viewComponent from './AdoptabobblesBoughtBox';
import initActionsCreator from './redux/initModule';

export const getAdoptabobblesBoughtWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/il8.26_top_exclusive_supporters.svg',
    title: t('widgets.group_members.addButtonHeader'),
    description: t('widgets.group_members.description'),
  },
  initActionsCreator,
});
