import { handleActions } from 'redux-actions';
import { fetchPageLayoutSuccess } from '../actions/pageLayout';
import { DEFAULT_PAGE_LAYOUT } from '../routes';

const defaultState = [];

export default handleActions<any, any>(
  {
    [fetchPageLayoutSuccess.toString()]: (
      state,
      { payload = DEFAULT_PAGE_LAYOUT }
    ) => [...payload],
  },
  defaultState
);
