import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PapiUser, PapiBadge } from '@wix/da-papi-types';
import Badge from '@wix/da-ds/pkg/Badge';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import { showBadgeInfoModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { getBiDataForBadge } from '@wix/da-shared-react/pkg/utils/badges';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { BadgeActivityItem } from '../../../../../types/badge';

import s from './BadgeActivity.scss';

export interface Props {
  className?: string;
  isOwner?: boolean;
  ownerUsername: string;
  items: BadgeActivityItem[];
  totalSent: number;
  totalReceived: number;
}

interface BadgeRenderOptions {
  giver: PapiUser;
  receiver: PapiUser;
  badgeName: string;
  badge: PapiBadge;
}

const BadgeActivity: React.FC<Props> = ({
  className,
  items,
  isOwner,
  ownerUsername,
  totalReceived,
  totalSent,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showModal = useCallback(
    (username, badgeId) => dispatch(showBadgeInfoModal(username, badgeId)),
    [dispatch]
  );

  const renderTotalsRow = () => {
    return renderNotificationRow({
      label: renderTotalsLabel(),
      key: 'totals',
      timestamp: null,
      icon: null,
      receiverUsername: null,
      badge: null,
    });
  };

  const renderBadgesRows = () => {
    if (!items) {
      return null;
    }

    return items.map(item =>
      renderNotificationRow({
        label: renderBadgeLabel({
          giver: item.giver,
          receiver: item.receiver,
          badgeName: item.badge.title,
          badge: item.badge,
        }),
        icon: <Badge badge={item.badge} />,
        timestamp: item.timestamp,
        key: `badge-${item.timestamp}`,
        receiverUsername: item.receiver.username,
        badge: item.badge,
      })
    );
  };

  const renderUserLabel = (user: PapiUser, ownerClassname = '') => {
    const isUserAnOwner = user.username === ownerUsername;

    return isOwner && isUserAnOwner ? (
      <span className={ownerClassname}>{t('common.you')}</span>
    ) : (
      // add span here so it matches the element count and i18n indeces of the 'you' above
      <span>
        <UserLink
          className={s['userlink']}
          showSymbol={false}
          user={user}
          withTooltip={true}
        />
      </span>
    );
  };

  const renderBadgeLabel = ({
    giver,
    receiver,
    badgeName,
    badge,
  }: BadgeRenderOptions) => {
    return (
      <Trans i18nKey="widgets.notifications.badges.badge">
        {renderUserLabel(giver, s['giver-is-owner-label'])}
        {' gave '}
        {renderUserLabel(receiver)}
        {' a '}
        <PlainButtonOrLink
          className={s['badge-name']}
          onClick={() => showModal(receiver.username, badge.id!)}
          biData={getBiDataForBadge(badge)}
        >
          {badgeName}
        </PlainButtonOrLink>
        Badge
      </Trans>
    );
  };

  const renderTotalsLabel = () => {
    return (
      <div>
        <strong title={String(totalSent)}>{shortenNumber(totalSent)}</strong>{' '}
        <Trans
          i18nKey="widgets.notifications.badges.sent"
          count={totalSent}
          parent="span"
        >
          Badges sent
        </Trans>
        {', '}
        <strong title={String(totalReceived)}>
          {shortenNumber(totalReceived)}
        </strong>{' '}
        <Trans
          i18nKey="widgets.notifications.badges.received"
          count={totalReceived}
          parent="span"
        >
          Badges received
        </Trans>
        :
      </div>
    );
  };

  const renderNotificationRow = ({
    label,
    timestamp,
    icon,
    key,
    receiverUsername,
    badge,
  }) => {
    return (
      <div className={s['row']} key={key}>
        <div className={s['notification']}>
          <div className={s['notification-label']}>{label}</div>
          {timestamp && (
            <div className={s['notification-ts']}>
              <Timestamp timestamp={timestamp} />
            </div>
          )}
        </div>
        {icon && badge && (
          <PlainButtonOrLink
            onClick={() => showModal(receiverUsername, badge.id)}
            className={s['icon-wrapper']}
            biData={badge && getBiDataForBadge(badge)}
          >
            {icon}
          </PlainButtonOrLink>
        )}
      </div>
    );
  };

  return (
    <div className={classnames(s['root'], className)}>
      {renderTotalsRow()}
      {renderBadgesRows()}
    </div>
  );
};

BadgeActivity.displayName = 'BadgeActivity';

export default BadgeActivity;
