import React from 'react';
import { useTranslation } from 'react-i18next';

import WidgetBoxWide, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import { ModuleType } from '../../../../../types/modules';
import GroupAbout from '../';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const GroupAboutBoxWide: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  return (
    <WidgetBoxWide
      moduleId={moduleId}
      id={ModuleType.STATS}
      header={t('widgets.statistics.header')}
      {...widgetBoxProps}
    >
      <GroupAbout moduleId={moduleId} />
    </WidgetBoxWide>
  );
};

export default GroupAboutBoxWide;
