import {
  PapiGruserModuleExhibitionistConfig,
  PapiPremiumFolderData,
} from '@wix/da-papi-types';

export interface Modules {
  [moduleId: number]: any;
}

export enum ModulePriv {
  EDIT = 'edit',
  DELETE = 'delete',
}

// Please keep these sorted by enum const
export enum ModuleType {
  ABOUT = 'about',
  ACTIVE_MEMBERS = 'active_members',
  ADOPTABLES = 'adoptables',
  ADOPTABOBBLES_BOUGHT = 'adoptabobbles_bought',
  NEWEST_MEMBERS = 'newest_members',
  ADMINS = 'members',
  BADGES_ACTIVITY = 'badges_activity',
  BADGES_SPOTLIGHT = 'badges_spotlight',
  COMMENTS = 'comments',
  COMMISSIONS = 'commissions',
  COVER_DEVIATION = 'cover_deviation',
  CUSTOM = 'papi_custombox',
  DEVIOUSNESS = 'deviousness',
  DONATIONS = 'donations',
  DRAFTS = 'drafts',
  EXHIBITIONIST = 'exhibitionist',
  FEATURED_DEVIATION = 'featured_deviation',
  FEATURED_DEVIATIONS = 'featured',
  FEATURED_JOURNALS = 'featured_journals',
  FEATURED_POST = 'featured_post',
  FEATURED_POSTS = 'featured_posts',
  FEED = 'feed',
  FEED_PINNED_DEVIATION = 'feed_pinned_deviation',
  FOLDERS = 'folders',
  FOLDER_DEVIATIONS = 'folder_deviations',
  GROUPS_ADMIN = 'group_list_admins', // TODO: rename to GROUPS_ADMIN_OF
  GROUPS_MEMBER = 'group_list_members', // TODO: rename to GROUPS_MEMBER_OF
  GROUP_ADMINS = 'group_admins',
  GROUP_MEMBERS = 'group_members',
  GROUP_MESSAGELOG = 'group_messagelog',
  GROUP_ACTIONLOG = 'group_actionlog',
  GROUP_NOTES = 'group_notes',
  GROUP_ABOUT = 'group_about',
  GROUP_RECRUITMENT = 'recruitment',
  GROUP_MANAGE = 'group_manage',
  JOURNALS = 'journals',
  LATEST_DEVIATIONS = 'latest_deviation',
  LLAMA_FARM = 'llama_farm',
  MEGABOX = 'megabox',
  MY_COMMENTS = 'my_comments',
  NEWEST_DEVIATIONS = 'newest',
  POLLS = 'polls',
  POSTS_FEED = 'posts_feed',
  PREMIUM_CONTENT = 'premium_content',
  PREMIUM_FOLDERS = 'premium_folders',
  STATS = 'userstats',
  STATUSES = 'statuses',
  SUBSCRIPTION_CENTER = 'subscription_center',
  SUBSCRIPTION_TIPS = 'subscription_tips',
  TIERS = 'tiers',
  TIER_CONTENT = 'tier_content',
  WATCHERS = 'watchers',
  WATCHING = 'watching',
  SUPPORTERS = 'supporters',
}

export type ModuleConfig = {
  config: any;
  options?: any;
};
export type ExhibitionistModuleConfig = PapiGruserModuleExhibitionistConfig;
export type ExhibitionistModuleOptions = ExhibitionistModuleConfig['options'];
export type ExhibitionistModuleGalleryListItem = Unpacked<
  ExhibitionistModuleOptions['galleryList']
>;
export type ExhibitionistModuleCollectionListItem = Unpacked<
  ExhibitionistModuleOptions['collectionList']
>;
export type ExhibitionistModuleSourceTypeOptions =
  ExhibitionistModuleOptions['sourceTypes'];
export type ExhibitionistModuleFolderList = Pick<
  ExhibitionistModuleOptions,
  'galleryList' | 'collectionList'
>;
export type ExhibitionistPremiumFolderData = NonNullable<PapiPremiumFolderData>;

export enum ExhibitionistViewMode {
  TORPEDO = 'torpedo',
  SLIDESHOW = 'slideshow',
}

export enum ExhibitionistSlideFitMode {
  FIT = 'fit',
  COVER = 'cover',
}
