import React, { useContext, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import CoreExclusiveFeature from '@wix/da-shared-react/pkg/indicators/CoreExclusiveFeature/CoreExclusiveFeature';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { PapiUser } from '@wix/da-papi-types';
import EmptyState from '../../../../EmptyState';

import s from './PremiumFoldersEmptyState.scss';

export interface Props {
  view: 'wide' | 'large' | 'small';
  user: PapiUser;
  isOwner: boolean;
  onOpenChat: (user: PapiUser) => void;
  onCreateGallery: () => void;
  hasCoreMembership?: boolean;
}

export const PremiumFoldersEmptyState: React.FC<Props> = ({
  view,
  user,
  user: { username },
  isOwner,
  onOpenChat,
  onCreateGallery,
  hasCoreMembership,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    if (isOwner && window.location.search.includes('new_premium_folder')) {
      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
      onCreateGallery();
    }
  }, [isOwner, onCreateGallery]);

  if (isOwner) {
    return hasCoreMembership ? (
      <EmptyState
        className={classnames(s['root'], s[view])}
        title={t('pages.shop.emptyState.core.premiumFolder.title')}
        titleClassName={s['title']}
        subtitle={
          isMobile
            ? t('pages.shop.emptyState.core.premiumFolder.subtitle.mobile')
            : t('pages.shop.emptyState.core.premiumFolder.subtitle')
        }
        subtitleClassName={s['subtitle']}
        illustration={<CoreExclusiveFeature withoutBuyCTA />}
        illustrationClassName={s['exclusive']}
        withButton={!isMobile}
        buttonLabel={t('pages.shop.emptyState.core.premiumFolder.cta')}
        onButtonClick={onCreateGallery}
        biData={BiData<DefaultBiEvent>({
          evid: 2,
          click_info: 'create_premium_gallery',
          sectionname: BiContextProvidedValue,
        })}
        view={'small'}
      />
    ) : (
      <EmptyState
        className={classnames(s['root'], s[view])}
        title={t('pages.shop.emptyState.nonCore.premiumFolder.title')}
        titleClassName={s['title']}
        subtitle={t(
          isMobile
            ? 'pages.shop.emptyState.nonCore.premiumFolder.subtitle.mobile'
            : 'pages.shop.emptyState.nonCore.premiumFolder.subtitle'
        )}
        subtitleClassName={s['subtitle']}
        illustration={<CoreExclusiveFeature withoutBuyCTA />}
        illustrationClassName={s['exclusive']}
        customButton={
          isMobile ? undefined : (
            <BuyCoreBrandedButton
              size="large"
              biData={
                {
                  referral_info: ReferralType.SHOP_PREMIUM_GALLERIES,
                } as any
              }
              withSaleSparkles
            >
              {t('widgets.tierContent.empty.cta.getCore')}
            </BuyCoreBrandedButton>
          )
        }
        view={'small'}
      />
    );
  }

  const handleOpenChat = () => onOpenChat(user);

  // empty state for visitor
  return (
    <EmptyState
      className={classnames(s['root'], s[view])}
      title={t('pages.shop.emptyState.premiumFolders.title.visitor', {
        username,
      })}
      subtitle={t('pages.shop.emptyState.premiumFolders.subtitle.visitor')}
      subtitleClassName={s['subtitle']}
      onButtonClick={handleOpenChat}
      buttonLabel={t('pages.shop.emptyState.openChatWithArtist')}
      withButton
    />
  );
};
PremiumFoldersEmptyState.displayName = 'PremiumFoldersEmptyState';

export default PremiumFoldersEmptyState;
