import React, { useState, useEffect, Suspense } from 'react';
import c from 'classnames';
import { PapiModuleCustombox } from '@wix/da-papi-types';
import useModule, {
  useModuleVersion,
} from '@wix/da-gruser-shared/pkg/hooks/useModule';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { useCurrentZoneName } from '@wix/da-gruser-shared/pkg/hooks/useZone';
import TextRenderer from '@wix/da-shared-react/pkg/TextRenderer';
import Background from '@wix/da-shared-react/pkg/Background';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import {
  BackgroundImageDisplayMode,
  BackgroundType,
} from '@wix/da-shared-react/pkg/Background/types';
import { getMeasuredCookieType } from './helpers';
import CustomBoxViewerTiptap from '@wix/da-editor-tiptap/pkg/components/Viewer/presets/CustomBox';

import s from './Custom.scss';

export interface Props {
  moduleId: number;
  viewerKeySuffix?: string;
  noPadding?: boolean;
}

const Custom: React.FC<Props> = ({ moduleId, viewerKeySuffix, noPadding }) => {
  const zoneName = useCurrentZoneName();
  const module = useModule(moduleId);
  const { moduleData } = useModuleData<PapiModuleCustombox>(moduleId);
  const moduleVersion = useModuleVersion(moduleId);
  const {
    backgroundType,
    customtext,
    color,
    gradient,
    pattern,
    image: imageDeviation,
    imageDisplayMode,
    imageTint,
    imageUrl,
  } = moduleData;

  const backgroundProps = {
    imageDeviation,
    imageUrl,
    backgroundType: backgroundType as BackgroundType, // I <3 TS
    imageDisplayMode: imageDisplayMode as BackgroundImageDisplayMode,
    imageTint,
    pattern,
    gradient,
    color,
  };

  // Force measured cache bust any time viewerKeySuffix updates
  const [measuredVersion, setMeasuredVersion] = useState(0);
  const [viewerKey, setViewerKey] = useState(viewerKeySuffix);
  useEffect(() => {
    if (viewerKeySuffix !== viewerKey) {
      setMeasuredVersion(measuredVersion + 1);
      setViewerKey(viewerKeySuffix);
    }
  }, [viewerKeySuffix, viewerKey, measuredVersion]);

  const measuredCookieType = getMeasuredCookieType(zoneName, module?.position);
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    measuredCookieType,
    {
      cookieDim: 'width',
      version: measuredVersion,
    }
  );

  const width = measuredContainer?.width || 600;

  return (
    <>
      <Background
        variant="custombox"
        className={c('da-editor-custombox', s['root'])}
        contentClassName={s['content']}
        {...backgroundProps}
        noPadding={noPadding}
      >
        <div ref={ref} style={{ width: '100%' }}>
          <Suspense fallback={null}>
            <TextRenderer
              textContent={customtext}
              legacyTheme={'default'}
              viewerKey={`custombox-${moduleId}-${moduleVersion}-${width}`}
              tipTapViewer={CustomBoxViewerTiptap}
              cookieType={measuredCookieType}
              defaultSize={{
                desktop: { width: 780, height: 800 },
                mobile: { width: 380, height: 400 },
              }}
            />
          </Suspense>
        </div>
      </Background>
    </>
  );
};
Custom.displayName = 'Custom';

export default Custom;
