import React from 'react';
import classnames from 'classnames';
import LlamaAnimation from '../LlamaAnimation';
import LlamaSprite from '../LlamaSprite';
import { LlamaInfoWithPosition, LlamaInfo } from '../../types';
import { LLAMA_SCALE_RANGE, LLAMA_IMAGE_SCALE } from '../../constants';

import s from './Llama.scss';

export interface Props {
  className?: string;
  llama: LlamaInfoWithPosition;
  scale?: number;
  animation?: string;
  animationDelay?: number;
  onLlamaPress?: (llama: LlamaInfo) => void;
}

const Llama: React.FC<Props> = ({
  className,
  llama,
  animation,
  animationDelay = 0,
  scale: backgroundScale = 1,
  onLlamaPress,
}) => {
  const { top, left, imgSrc: src } = llama;
  const distanceScale = 1 + top * LLAMA_SCALE_RANGE;
  const scale = backgroundScale * distanceScale * LLAMA_IMAGE_SCALE;
  const style: React.CSSProperties = {
    top: `${top * 100}%`,
    left: `${left * 100}%`,
    transform: `scale(${scale},${scale})`,
    ['--animation-delay' as any]: `${animationDelay}ms`,
  };
  return (
    <div className={classnames(s['root'], className)} style={style}>
      <LlamaAnimation animation={animation}>
        <LlamaSprite
          src={src}
          onMouseDown={onLlamaPress ? _ => onLlamaPress(llama) : undefined}
        />
      </LlamaAnimation>
    </div>
  );
};

Llama.displayName = 'Llama';

export default Llama;
