import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../types/store';
import {
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../../selectors/users';
import {
  getModuleType,
  getModuleMeta,
  getModuleVersion,
  canPerformModuleAction,
} from '../../../../selectors/modules';
import { updateModule } from '../../../../actions/modules';
import { PagePriv } from '../../../../../types/pagePrivileges';
import { getCanPerformPageAction } from '../../../../selectors/privs';
import { Props } from './WidgetBox';
import { ComponentType } from 'react';
import { ModulePriv } from '../../../../../types/modules';

export type ConnectedKeys =
  | 'profileOwnerUser'
  | 'isOwner'
  | 'moduleType'
  | 'moduleVersion'
  | 'isAuthorizedToEditWidget'
  | 'isAuthorizedToRemoveWidget'
  | 'isAuthorizedToMoveModule'
  | 'isNewlyAddedModule';
export type StateProps = Pick<Props, ConnectedKeys>;
export type DispatchProps = Pick<Props, 'updateModule'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => ({
  profileOwnerUser: getProfileOwnerUser(state),
  isOwner: isCurrentUserProfileOwner(state),
  moduleType: getModuleType(state, ownProps.moduleId),
  moduleVersion: getModuleVersion(state, ownProps.moduleId),
  isNewlyAddedModule: getModuleMeta(state, ownProps.moduleId).isNewlyAdded,
  isAuthorizedToMoveModule: getCanPerformPageAction(
    state,
    PagePriv.MOVE_MODULE
  ),
  isAuthorizedToRemoveWidget: canPerformModuleAction(
    state,
    ownProps.moduleId,
    ModulePriv.DELETE
  ),
  isAuthorizedToEditWidget: canPerformModuleAction(
    state,
    ownProps.moduleId,
    ModulePriv.EDIT
  ),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  updateModule,
};

export default connect(mapStateToProps, mapDispatchToProps) as (
  component: ComponentType<Props>
) => ComponentType<OwnProps>;
