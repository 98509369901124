import React, { useContext } from 'react';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';

import { SubSubNavBarProps } from './types';
import SubSubNavBarMobile from './Mobile';
import SubSubNavBarDesktop from './Desktop';

const SubSubNavBar: React.FC<SubSubNavBarProps> = props => {
  const isMobile = useContext(MobileContext);

  return (
    <ErrorBoundary hideOnFail debugComponent="SubSubNavBar">
      {isMobile ? (
        <SubSubNavBarMobile {...props} />
      ) : (
        <SubSubNavBarDesktop {...props} />
      )}
    </ErrorBoundary>
  );
};
SubSubNavBar.displayName = 'SubSubNavBar';

export default SubSubNavBar;
