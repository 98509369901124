import type { Theme, Margin } from '@nivo/core';

export const theme: Theme = {
  fontFamily: 'var(--devious-sans-medium)',
  fontSize: 12,
  textColor: 'var(--g-typography-primary)',
  axis: {
    ticks: {
      text: {
        fontFamily: 'var(--devious-sans-regular)',
        fill: 'var(--g-typography-tertiary)',
      },
      line: {
        stroke: 'var(--gray6)',
      },
    },
  },
  grid: {
    line: {
      stroke: 'var(--gray6)',
    },
  },
  tooltip: {
    container: {
      background: 'var(--g-bg-primary)',
      color: 'var(--g-typography-primary)',
    },
  },
};

export const margin: Margin = { top: 40, right: 25, bottom: 40, left: 40 };
