import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Props } from './ProfileHeroMobile';
import { AppState } from '../../../../types/store';
import { getCoverDeviation } from '../../../selectors/deviations';
import {
  getProfileOwnerUser,
  getProfileOwnerStats,
  getProfileOwnerTagline,
  getIsGroupProfile,
  isCurrentUserProfileOwner,
} from '../../../selectors/users';
import {
  getCanCustomizePage,
  getCanChangeAvatar,
} from '../../../selectors/privs';
import { hasSubscriptions } from '../../../selectors/pageLayout';
import { setIsEditorOpen } from '../../../actions/avatar';

export type StateProps = Pick<
  Props,
  | 'coverDeviation'
  | 'profileOwnerUser'
  | 'canChangeAvatar'
  | 'isAuthorizedToCustomize'
  | 'isOwner'
  | 'isGroup'
  | 'tagline'
  | 'stats'
  | 'hasSubscriptions'
>;
type DispatchProps = Pick<Props, 'openAvatarEditor'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const coverDeviation = getCoverDeviation(state);
  const profileOwnerUser = getProfileOwnerUser(state);
  const tagline = getProfileOwnerTagline(state);
  const stats = getProfileOwnerStats(state);
  const isOwner = isCurrentUserProfileOwner(state);
  const isGroup = getIsGroupProfile(state);
  const isAuthorizedToCustomize = getCanCustomizePage(state);
  const canChangeAvatar = getCanChangeAvatar(state);
  return {
    stats,
    isOwner,
    isGroup,
    tagline,
    hasSubscriptions: hasSubscriptions(state),
    coverDeviation,
    profileOwnerUser,
    isAuthorizedToCustomize,
    canChangeAvatar,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  openAvatarEditor: setIsEditorOpen,
};

export default connect(mapStateToProps, mapDispatchToProps);
