import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PapiUser, PapiDeviation, PapiGallection } from '@wix/da-papi-types';
import {
  BiData,
  BiContextProvidedValue,
  GroupProfileSubmitMenuClickBiEvent,
  GroupProfileSubmitOptionClickBiEvent,
} from '@wix/da-bi/pkg/events';
import AddIcon from '@wix/da-ds/pkg/Icons/24x24/Add';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import BrandedButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/BrandedButtonWithContextMenu';
import MediaSelectorWithGroupGallection from '@wix/da-shared-react/pkg/MediaPopup/Groups/MediaSelectorWithGroupGallection';
import { MediaSourceType } from '@wix/da-shared-react/pkg/MediaPopup/types';
import { GroupGallectionType } from '@wix/da-shared-react/pkg/GroupGallectionSelector/redux/types';
import { showSubmitModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { getSubmitUrlForProfileOwner } from '../../../../../../../utils/urls';

const SELECTION_LIMIT = 24;

export interface Props {
  className?: string;
  profileOwnerUser: PapiUser;
  contributeDeviations: (
    deviations: PapiDeviation[],
    group: PapiUser,
    type: GroupGallectionType,
    folderId?: number
  ) => void;
  canContributeDeviations: boolean;
  canRecommendDeviations: boolean;
  canSubmitJournal: boolean;
  selectedFolder?: PapiGallection;
}

const SubmitButton: React.FC<Props> = ({
  className,
  profileOwnerUser,
  contributeDeviations,
  canContributeDeviations,
  canRecommendDeviations,
  canSubmitJournal,
  selectedFolder,
}) => {
  const { t } = useTranslation();
  const [isMediaSelectorOpen, setIsMediaSelectorOpen] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useContext(MobileContext);

  if (!canContributeDeviations && !canRecommendDeviations) {
    return null;
  }

  const openMediaSelector = () => {
    setIsMediaSelectorOpen(true);
  };

  const closeMediaSelector = () => {
    setIsMediaSelectorOpen(false);
  };

  const redirectToSubmitPage = () => {
    window.location.replace(getSubmitUrlForProfileOwner(profileOwnerUser));
  };

  const submitJournal = () => {
    dispatch(
      showSubmitModal(ModalType.JOURNAL, {
        params: {
          refreshAfterSubmit: true,
          groupDestination: profileOwnerUser,
        },
      })
    );
  };
  const submitStatusUpdate = () => {
    dispatch(
      showSubmitModal(ModalType.STATUS_UPDATE, {
        params: { refreshAfterSubmit: true },
      })
    );
  };

  let items = [] as NullableDropdownItem[];

  if (canContributeDeviations) {
    items.push(
      {
        label: t('groups.submit.contributeNewDeviation'),
        onClick: redirectToSubmitPage,
        biData: BiData<GroupProfileSubmitOptionClickBiEvent>({
          evid: 851,
          submit_type: 'submit_new',
          typeid: BiContextProvidedValue,
          itemid: BiContextProvidedValue,
        }),
      },
      {
        label: t('groups.submit.contributeExistingDeviation'),
        onClick: openMediaSelector,
        biData: BiData<GroupProfileSubmitOptionClickBiEvent>({
          evid: 851,
          submit_type: 'add_existing',
          typeid: BiContextProvidedValue,
          itemid: BiContextProvidedValue,
        }),
      }
    );
  } else if (canRecommendDeviations) {
    items.push({
      label: t('groups.submit.recommendExistingDeviation'),
      onClick: openMediaSelector,
      biData: BiData<GroupProfileSubmitOptionClickBiEvent>({
        evid: 851,
        submit_type: 'recommend_existing',
        typeid: BiContextProvidedValue,
        itemid: BiContextProvidedValue,
      }),
    });
  }

  if (!isMobile && canSubmitJournal) {
    items.push({
      label: t('groups.submit.writePost'),
      onClick: profileOwnerUser.isGroup ? submitJournal : submitStatusUpdate,
      biData: BiData<GroupProfileSubmitOptionClickBiEvent>({
        evid: 851,
        submit_type: 'journal',
        typeid: BiContextProvidedValue,
        itemid: BiContextProvidedValue,
      }),
    });
  }

  items = items.filter(x => x);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <BrandedButtonWithContextMenu
        icon={AddIcon}
        items={items}
        size="large"
        variant="brand-green"
        className={className}
        biData={BiData<GroupProfileSubmitMenuClickBiEvent>({
          evid: 850,
          typeid: BiContextProvidedValue,
          itemid: BiContextProvidedValue,
        })}
      >
        {t('common.submit')}
      </BrandedButtonWithContextMenu>
      {isMediaSelectorOpen && (
        <MediaSelectorWithGroupGallection
          selectionLimit={SELECTION_LIMIT}
          group={profileOwnerUser}
          onClose={closeMediaSelector}
          initialGroupGallection={selectedFolder}
          allowUploads={false}
          mediaSourceTypes={[
            MediaSourceType.GALLERY,
            MediaSourceType.COLLECTION,
            MediaSourceType.JOURNAL,
            MediaSourceType.STATUS,
            MediaSourceType.BROWSE,
          ]}
          onSelect={(...args) => {
            contributeDeviations(...args);
            setIsMediaSelectorOpen(false);
          }}
        />
      )}
    </>
  );
};
SubmitButton.displayName = 'SubmitButton';

export default SubmitButton;
