import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import MailIcon from '@wix/da-ds/pkg/Icons/24x24/Mail';
import ReceiptIcon from '@wix/da-ds/pkg/Icons/24x24/Receipt';
import FavStarFilledIcon from '@wix/da-ds/pkg/Icons/16x16/FavStarFill';
import Loader from '@wix/da-ds/pkg/Loader';

import { Nav } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';

import NetworkBarItem from '@wix/da-shared-react/pkg/NetworkBar/_partials/NetworkBarItem';

import { GroupNotesFolderType } from '../../../../../../types/backroomSection';
import s from './GroupNotesSidebar.scss';

export interface Props {
  className?: string;
  selectedFolderName?: GroupNotesFolderType;
  isLoading?: boolean;
  onClick?: (key: GroupNotesFolderType) => void;
}

const menuItems = [
  {
    key: GroupNotesFolderType.INBOX,
    icon: MailIcon,
  },
  {
    key: GroupNotesFolderType.STARRED,
    icon: FavStarFilledIcon,
  },
  {
    key: GroupNotesFolderType.ARCHIVED,
    icon: ReceiptIcon,
  },
];

const GroupNotesSidebar: React.FC<Props> = ({
  className,
  selectedFolderName,
  isLoading,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Nav className={classnames(s['root'], className)}>
      <VisuallyHidden>
        <Heading>{t('widgets.group_notes.sidebar.heading')}</Heading>
      </VisuallyHidden>
      <ul>
        {menuItems.map(({ key, icon: Icon }) => {
          const isSelected = key === selectedFolderName;
          const prefixIcon =
            isSelected && isLoading ? (
              <Loader size="small" />
            ) : (
              <Icon size={IconSize.MEDIUM} />
            );
          return (
            <li key={key}>
              <NetworkBarItem
                isSelected={isSelected}
                icon={prefixIcon}
                className={s['item']}
                tooltipContent={t(
                  `widgets.group_notes.sidebar.items.var.${key}.tooltip`
                )}
                onClick={() => onClick?.(key)}
              >
                {t(`widgets.group_notes.sidebar.items.var.${key}`)}
              </NetworkBarItem>
            </li>
          );
        })}
      </ul>
    </Nav>
  );
};
GroupNotesSidebar.displayName = 'GroupNotesSidebar';

export default GroupNotesSidebar;
