import React from 'react';
import c from 'classnames';
import ddt from '@wix/da-ddt';
import { useTranslation } from 'react-i18next';
import {
  BiContextProvidedValue,
  BiData,
  SubscriptionHelpCenterBiEvent,
} from '@wix/da-bi/pkg/events';
import DiscordTipLoadable from './DiscordTip/DiscordTipLoadable';
import Tip from './Tip';
import TipDivider from './TipDivider';
import TipButton from './TipButton';
import s from './SubscriptionTips.scss';

export interface Props {
  moduleId: number;
  className?: string;
  draftCount?: number;
  publishedCount?: number;
  subscriberCount?: number;
}

const getVariant = ({
  draftCount = 0,
  publishedCount = 0,
  subscriberCount = 0,
}: Props) => {
  if (publishedCount >= 1 && subscriberCount >= 1) {
    return 'publishedAndOneOrMoreSubscribers';
  }
  if (publishedCount >= 1 && subscriberCount === 0) {
    return 'publishedButNoSubscribers';
  }
  // If we got to here, user will always have at least one draft
  // If they don't - a section empty state would have been shown instead
  if (draftCount < 1) {
    ddt.error('subscriptions', 'EmptyState should have been shown');
  }
  return 'onlyDrafts';
};

const getTranslationKeys = (
  position: 'top' | 'bottom',
  variant:
    | 'onlyDrafts'
    | 'publishedButNoSubscribers'
    | 'publishedAndOneOrMoreSubscribers'
) => {
  // ./scripts/unused-translations.sh grep help
  // t(`widgets.subscriptionsSection.tips.var.${variant}.${position}.heading`);
  // t(`widgets.subscriptionsSection.tips.var.${variant}.${position}.text`);
  // t(`widgets.subscriptionsSection.tips.var.${variant}.${position}.url`);
  // t(`widgets.subscriptionsSection.tips.var.${variant}.${position}.buttonText`);
  const prefix = `widgets.subscriptionsSection.tips.var.${variant}.${position}`;
  const heading = `${prefix}.heading`;
  const text = `${prefix}.text`;
  const url = `${prefix}.url`;
  const buttonText = `${prefix}.buttonText`;
  return { heading, text, url, buttonText };
};

const SubscriptionTips: React.FC<Props> = props => {
  const { t } = useTranslation();
  const variant = getVariant(props);
  const top = getTranslationKeys('top', variant);
  const bottom = getTranslationKeys('bottom', variant);
  return (
    <div className={c(s['root'], props.className)}>
      <DiscordTipLoadable />
      <Tip
        heading={t(top.heading)}
        text={t(top.text)}
        button={
          <TipButton
            tipType="top"
            href={t(top.url)}
            biData={BiData<SubscriptionHelpCenterBiEvent>({
              evid: 554,
              variant_type: `${variant}.top`,
              tip_heading: t(top.heading),
              sectionname: BiContextProvidedValue,
              widgetname: BiContextProvidedValue,
              itemid: BiContextProvidedValue,
              typeid: 82,
            })}
          >
            {t(top.buttonText)}
          </TipButton>
        }
      />
      <TipDivider className={s['divider']} />
      <Tip
        heading={t(bottom.heading)}
        text={t(bottom.text)}
        button={
          <TipButton
            tipType="bottom"
            href={t(bottom.url)}
            biData={BiData<SubscriptionHelpCenterBiEvent>({
              evid: 554,
              variant_type: `${variant}.bottom`,
              tip_heading: t(bottom.heading),
              sectionname: BiContextProvidedValue,
              widgetname: BiContextProvidedValue,
              itemid: BiContextProvidedValue,
              typeid: 82,
            })}
          >
            {t(bottom.buttonText)}
          </TipButton>
        }
      />
    </div>
  );
};
SubscriptionTips.displayName = 'SubscriptionTips';

export default SubscriptionTips;
