import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './GroupProfileSubNavDesktopInner';

export type StateProps = Pick<Props, never>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  {}
> = state => ({});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
