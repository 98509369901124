import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { AppState } from '../../../../../../../types/store';
import { getModuleDataByModuleName } from '../../../../../../selectors/modules';
import { ModuleType } from '../../../../../../../types/modules';
import { getSelectedTier } from '../../../../../../selectors/subscriptionsSection';
import { setSelectedTierEntityId } from '../../../../../../actions/subscriptionsSection';
import { Props } from './TiersDropdown';

type StateProps = Pick<Props, 'tiers' | 'selectedTier'>;
type DispatchProps = Pick<Props, 'setSelectedTierEntityId'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const selectedTier = getSelectedTier(state);
  const { tiers: tiersIds = [] } = getModuleDataByModuleName(
    state,
    ModuleType.TIERS
  );
  const tiers = getDeviationsByIds(state, tiersIds);
  return {
    tiers,
    selectedTier,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  setSelectedTierEntityId,
};

export default connect(mapStateToProps, mapDispatchToProps);
