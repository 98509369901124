import { reducers } from '@wix/da-shared-react/pkg/redux/shared';
import pageType from './pageType';
import profileHeroEditor from './profileHeroEditor';
import pagePrivileges from './pagePrivileges';
import profileOwner from './profileOwner';
import sections from './sections';
import gallectionSection from './gallectionSection';
import subscriptionsSection from './subscriptionsSection';
import layout from './layout';
import pageLayout from './pageLayout';
import pageInfo from './pageInfo';
import modules from './modules';
import statsSection from './statsSection';
import avatarEditor from './avatar';
import profileSkins from './profileSkins';
import groupCreation from './groupCreation';
import backroomSection from './backroomSection';
import customBoxTemplates from './customBoxTemplates';
import { default as headReducers } from '@wix/da-react-app/pkg/client/Head/redux/reducers';

const buildFakeReducer =
  defaultValue =>
  (state = defaultValue, _action) =>
    state;

const rootReducers = {
  ...headReducers,
  baseDaUrl: buildFakeReducer(''),
  ...reducers,
  pageType,
  profileHeroEditor,
  pagePrivileges,
  profileOwner,
  sections,
  gallectionSection,
  subscriptionsSection,
  layout,
  pageLayout,
  pageInfo,
  modules,
  statsSection,
  avatarEditor,
  profileSkins,
  groupCreation,
  backroomSection,
  customBoxTemplates,
};

export default rootReducers;
