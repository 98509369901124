import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import { PapiUser } from '@wix/da-papi-types';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { createOrOpenChatWithUser } from '@wix/da-shared-react/pkg/Chat/redux/actions';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { ShopItemType } from '../../../../../../types/shop';
import { changeCommissionsGlobalStatus } from '../../../../../actions/commissions';
import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../../../selectors/users';

import { Props } from './DisabledEmptyState';

type StateProps = Pick<Props, 'total' | 'isOwner' | 'user'>;
type DispatchProps = Pick<Props, 'enableAllCommissions' | 'onOpenChat'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => {
    const streamId = getShopItemsStreamId(ShopItemType.COMMISSIONS);

    return {
      total: streamSelectors.getTotal(state, streamId),
      isOwner: isCurrentUserProfileOwner(state),
      user: getProfileOwnerUser(state),
    };
  };

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> =
  dispatch => {
    return {
      enableAllCommissions: () =>
        dispatch(changeCommissionsGlobalStatus({ isOpen: true })),
      onOpenChat: (user: PapiUser) =>
        dispatch(
          createOrOpenChatWithUser(
            `disabled-empty-state-${user.useridUuid}`,
            user
          )
        ),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps);
