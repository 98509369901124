import { AppState } from '../../types/store';
import { PapiDeviation } from '@wix/da-papi-types';

export const getIsRepositioningProfileHero = (state: AppState): boolean =>
  state.profileHeroEditor.isRepositioning;

export const getProfileHeroDeviation = (
  state: AppState
): PapiDeviation | null => state.profileHeroEditor.deviation;

export const getProfileHeroCropArea = (state: AppState) =>
  state.profileHeroEditor.cropArea;
