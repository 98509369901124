import { PapiGallection } from '@wix/da-papi-types';
import { ALL_FOLDER_ID } from '@wix/da-url';
import { normalize, schema } from '@wix/da-shared-react/pkg/redux/normalizr';
import { GallectionType } from '../../types/gallection';
import {
  galleryFolderSchema,
  collectionFolderSchema,
  stashFolderSchema,
} from '@wix/da-shared-react/pkg/redux/normalizr/schemas/folder';
import {
  GALLERY_FOLDER_IDS_STREAM,
  COLLECTION_FOLDER_IDS_STREAM,
  PRIVATE_COLLECTION_FOLDER_IDS_STREAM,
} from '../streams';

const schemas: { [k in GallectionType]: schema.Entity } = {
  [GallectionType.GALLERY]: galleryFolderSchema,
  [GallectionType.COLLECTION]: collectionFolderSchema,
  [GallectionType.PRIVATE_COLLECTION]: collectionFolderSchema,
  [GallectionType.STASH]: stashFolderSchema,
};
const DEFAULT_FOLDERS = [ALL_FOLDER_ID];

export function getSchemaForGallectionType(gallectionType: GallectionType) {
  return schemas[gallectionType] as schema.Entity;
}

export function normalizeGallectionList(
  folders: PapiGallection[] | undefined,
  type?: GallectionType
) {
  const gallectionType =
    type ?? (folders?.[0]?.type as GallectionType | undefined);
  if (!gallectionType) {
    return { result: folders, entities: {} };
  }

  return normalize(folders, [getSchemaForGallectionType(gallectionType)]);
}

export const getGallectionTypeFromSection = (
  gallectionSection: 'gallery' | 'favourites' | 'private_collections'
) =>
  ({
    gallery: GallectionType.GALLERY,
    favourites: GallectionType.COLLECTION,
    private_collections: GallectionType.PRIVATE_COLLECTION,
  }[gallectionSection]);

export function getStreamIdForGallectionType(gallectionType: GallectionType) {
  return {
    [GallectionType.GALLERY]: GALLERY_FOLDER_IDS_STREAM,
    [GallectionType.COLLECTION]: COLLECTION_FOLDER_IDS_STREAM,
    [GallectionType.PRIVATE_COLLECTION]: PRIVATE_COLLECTION_FOLDER_IDS_STREAM,
  }[gallectionType];
}

function isFolderNotEmpty({
  size,
  totalItemCount = size,
  folderId,
}: PapiGallection) {
  return (
    // has deviations, self or subfolders
    totalItemCount > 0 ||
    // always needs to be there and/or shown
    DEFAULT_FOLDERS.includes(folderId)
  );
}

/** Return only folders have deviations, subfolders, or are a default (like 'all') */
export function filterOutEmpty(folders: PapiGallection[]) {
  return (
    folders
      // filter out empty root folders
      .filter(folder => isFolderNotEmpty(folder))
      // filter out empty subfolders
      .map(folder => ({
        ...folder,
        subfolders: folder.subfolders?.filter(isFolderNotEmpty),
      }))
  );
}

export function filterOutEmptyAndSubfolders(folders: PapiGallection[]) {
  return folders.filter(folder => !folder.parentId && isFolderNotEmpty(folder));
}
