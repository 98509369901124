import React, { Suspense, lazy } from 'react';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const GroupListConfigPopover = lazy(
  () => import(/* webpackChunkName: "owner" */ '../../GroupListConfigPopover')
);

type Props = any;

const GroupListConfigPopoverLazy: React.FC<Props> = props => {
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      <GroupListConfigPopover {...props} />
    </Suspense>
  );
};
GroupListConfigPopoverLazy.displayName = 'GroupListConfigPopoverLazy';

export default GroupListConfigPopoverLazy;
