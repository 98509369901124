import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { PapiDeviation, PapiModulePostsFeed } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import {
  POSTS_DEVIATIONS_STREAM_GROUP_ID,
  POSTS_PER_FETCH,
  getPostsDeviationsStreamId,
} from '../../../../streams/posts';
import { PostsType } from '../../../../../types/posts';
import { biLogPostsFeedItems } from '../../../../actions/bi';
import { setSectionStreamId } from '../../../../actions/sections';
import { setTotalPages } from '../../../../actions/pageInfo';
import { getDeviationsCommentsInitActions } from '../../../../store/initActions/common';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModulePostsFeed,
  requestParams: any
) => {
  const {
    results,
    total,
    hasMore,
    nextOffset,
    totalPageCount: totalPages = 0,
  } = moduleData;

  const filter = requestParams['posts_section'] || PostsType.ALL;
  const sortOrder = requestParams['order'];

  const streamId = getPostsDeviationsStreamId({
    type: filter,
    sortOrder,
  });

  const deviations: PapiDeviation[] = results ?? [];
  const normalized = normalize(deviations, [deviationSchema]);

  return [
    withOffset.actions.initialize({
      streamId,
      groupId: POSTS_DEVIATIONS_STREAM_GROUP_ID,
      itemsPerFetch: POSTS_PER_FETCH,
      streamParams: {
        moduleId,
        moduleType: 'posts_feed',
        sortOrder,
        filter,
      },
      items: normalized.result,
      entities: normalized.entities,
      nextOffset,
      hasMore,
      total,
    }),
    ...getDeviationsCommentsInitActions(deviations),
    setTotalPages(totalPages),
    setSectionStreamId(streamId),
    biLogPostsFeedItems(deviations),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
