// DS1.15.2
import React from 'react';
import { compose } from 'redux';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import { PapiUser } from '@wix/da-papi-types';
import ProfileIdentityMobile from '@wix/da-shared-react/pkg/ProfileIdentityMobile';
import { withMobileContext } from '@wix/da-react-context/pkg/MobileContext';
import AvatarEdit from './AvatarEdit';
import AvatarBirthdayBadge from './AvatarBirthdayBadge';
import ProfileStats from './ProfileStats';

import s from './ProfileIdentity.scss';

export interface Props {
  profileOwnerUser: PapiUser;
  stats: object;
  tagline: string;
  url: string;
  className?: string;
  isOwner: boolean;
  isMobile?: boolean;
  canChangeAvatar?: boolean;
  aboutModuleId?: any;
}

export interface State {}

class ProfileIdentity extends React.Component<Props & WithTranslation, State> {
  state = {};

  renderDesktop() {
    const {
      profileOwnerUser,
      stats,
      tagline,
      className,
      isOwner,
      canChangeAvatar,
    } = this.props;
    return (
      <div
        className={classnames(s['root'], className, !tagline && s['notagline'])}
      >
        {canChangeAvatar && <AvatarEdit />}
        {profileOwnerUser.isBirthweek && <AvatarBirthdayBadge />}
        <span>
          <UserAvatar
            size={profileOwnerUser.isGroup ? 50 : 100}
            user={profileOwnerUser}
            className={s['user-avatar']}
            withTooltip={false}
            withLink={!isOwner}
          />
        </span>
        <div className={s['user-details']}>
          <h1 className={s['user-link']}>
            <UserLink
              className={s['user-link-text']}
              symbolClassName={s['user-link-icon']}
              user={profileOwnerUser}
              withTooltip={false}
              useCoreRibbon={false}
              coreRibbonClassname={s['core-ribbon']}
            />
          </h1>
          {tagline}
          <ProfileStats {...stats} />
        </div>
      </div>
    );
  }

  renderMobile() {
    const { profileOwnerUser, stats, tagline, className } = this.props;
    return (
      <ProfileIdentityMobile
        user={profileOwnerUser}
        tagline={tagline}
        className={className}
        statsComponent={<ProfileStats {...stats} />}
      />
    );
  }

  render() {
    return this.props.isMobile ? this.renderMobile() : this.renderDesktop();
  }
}

const connect = compose(withMobileContext, withTranslation());

export default connect(ProfileIdentity);
