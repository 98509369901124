import { Action } from 'redux-actions';
import { PapiGruserModulePremiumFolders } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { GallectionType } from '../../../../../../../types/gallection';
import {
  SHOP_ITEMS_PER_FETCH,
  SHOP_ITEMS_STREAM_GROUP_ID,
  getShopItemsStreamId,
} from '../../../../../../streams/shop';
import { ShopItemType } from '../../../../../../../types/shop';
import { normalizeGallectionList } from '../../../../../../utils/gallection';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiGruserModulePremiumFolders
): Action<any>[] => {
  const { results } = moduleData;
  const normalized = normalizeGallectionList(
    results || [],
    GallectionType.GALLERY
  );

  return [
    withOffset.actions.initialize({
      streamId: getShopItemsStreamId(ShopItemType.PREMIUM_FOLDERS),
      groupId: SHOP_ITEMS_STREAM_GROUP_ID,
      streamParams: {
        moduleId,
        shopItemType: ShopItemType.PREMIUM_FOLDERS,
      },
      itemsPerFetch: SHOP_ITEMS_PER_FETCH,
      items: normalized.result,
      entities: normalized.entities,
      hasMore: moduleData.hasMore,
      total: moduleData.total,
    }),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
