import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDown from '@wix/da-ds/pkg/Icons/24x24/ArrowDown';
import ArrowUp from '@wix/da-ds/pkg/Icons/24x24/ArrowUp';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import TextButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/TextButtonWithContextMenu';
import { DropdownOption } from '@wix/da-ds/pkg/Dropdown/types';
import { filterGroupMessageLogByRole } from '../../../../../actions/backroomSection';
import { MessageLogModuleData } from '../../types';
import { useMessageLogParams } from '../hooks';

interface Props {
  moduleId: number;
}

function useRoleOptions(moduleId): [DropdownOption[], DropdownOption] {
  const { t } = useTranslation();
  const dispatchFilterAction = useAction(filterGroupMessageLogByRole);
  const {
    moduleData: { instigatorRoles },
  } = useModuleData<MessageLogModuleData>(moduleId);
  const { instigatorRoleid: currentInstigatorRoleid } = useMessageLogParams();

  const defaultRoleFilter: DropdownOption = {
    label: t(
      'widgets.group_messagelog.filterBar.roleDropdown.defaultOptionLabel.any'
    ),
    value: undefined,
    selected: currentInstigatorRoleid === undefined,
    onClick: () => dispatchFilterAction(undefined),
  };

  const roleOptions = [
    defaultRoleFilter,
    ...instigatorRoles.map(role => ({
      label: role.label,
      value: role.roleId,
      selected: role.roleId === currentInstigatorRoleid,
      onClick: () => dispatchFilterAction(role.roleId),
    })),
  ];

  return [roleOptions, roleOptions.find(option => option.selected)!];
}

const RoleDropdown: React.FC<Props> = ({ moduleId }) => {
  const [roleOptions, selectedOption] = useRoleOptions(moduleId);

  return (
    <TextButtonWithContextMenu
      variant="main"
      size="medium"
      postfix={{
        opened: ArrowUp,
        closed: ArrowDown,
      }}
      items={roleOptions}
    >
      {selectedOption.label}
    </TextButtonWithContextMenu>
  );
};

RoleDropdown.displayName = 'RoleDropdown';

export default RoleDropdown;
