import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './StatsSection';
import { AppState } from '../../../../types/store';
import { getHasCalculatedStats } from '../../../selectors/statsSection';

export type StateProps = Pick<Props, 'hasCalculatedStats'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state
) => ({
  hasCalculatedStats: getHasCalculatedStats(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
