import React, { Suspense, lazy } from 'react';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const WatchedDeviantsConfigPopover = lazy(
  () =>
    import(/* webpackChunkName: "owner" */ '../../WatchedDeviantsConfigPopover')
);

type Props = any;

const WatchedDeviantsConfigPopoverLazy: React.FC<Props> = props => {
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      <WatchedDeviantsConfigPopover {...props} />
    </Suspense>
  );
};
WatchedDeviantsConfigPopoverLazy.displayName =
  'WatchedDeviantsConfigPopoverLazy';

export default WatchedDeviantsConfigPopoverLazy;
