import { PapiGallection } from '@wix/da-papi-types';
import { ALL_FOLDER_ID } from '@wix/da-url';
import { isSpecialDefaultGallection } from '@wix/da-shared-react/pkg/helpers/gallections';
import { GallectionType } from '../../../../types/gallection';
import stickyBarStyles from './StickyBar/StickyBar.scss';

export function getGallectionsStickyBarHeight(isMobile): number {
  return parseInt(
    isMobile
      ? stickyBarStyles['folder-bar-mobile-height']
      : stickyBarStyles['folder-bar-height']
  );
}

export function getCommentsBlockAnchor(folderId) {
  return `folder-comments-${folderId}`;
}

export const getAllFolderFromFolders = (folders: PapiGallection[]) =>
  folders.find(({ folderId }) => folderId === ALL_FOLDER_ID);

export const getSpecialDefaultFolderFromFolders = (folders: PapiGallection[]) =>
  folders.find(folder => isSpecialDefaultGallection(folder));

export function getDefaultFolderFromFolders(
  folders: PapiGallection[]
): PapiGallection | undefined {
  const featuredFolder = getSpecialDefaultFolderFromFolders(folders);
  if (featuredFolder && featuredFolder.size) {
    return featuredFolder;
  }
  if (folders[0] && folders[0].type === GallectionType.PRIVATE_COLLECTION) {
    // private collections don't have All folder
    // so if Special Default Folder is not found, we return the first non-empty folder
    return folders.find(folder => folder.size > 0);
  }

  return getAllFolderFromFolders(folders);
}
