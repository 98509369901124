import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Url, USER_PROFILE_SHOP_SUB_PATHS } from '@wix/da-url';
import { MeasuredSlider as Slider } from '@wix/da-shared-react/pkg/Slider';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import PCPShopCard from '@wix/da-shared-react/pkg/ShopCard/PCPShopCard';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import DuperbrowseContext from '../../../../../contexts/DuperbrowseContext';
import AddItemCard from '../../_partials/AddItemCard';
import PremiumContentEmptyState from '../../_partials/PremiumContentEmptyState';
import PremiumContentSliderMobile from './PremiumContentSliderMobile';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { useSelector } from 'react-redux';
import {
  getProfileOwner,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { PapiGruserModulePremiumContent } from '@wix/da-papi-types';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { ShopItemType } from '../../../../../../types/shop';

export interface Props {
  moduleId: number;
  widgetBoxProps: Partial<WidgetBoxProps>;
}

const PremiumContentSlider: React.FC<Props> = ({
  moduleId,
  widgetBoxProps,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const profileOwner = useSelector(getProfileOwner);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const { moduleData } =
    useModuleData<PapiGruserModulePremiumContent>(moduleId);

  const streamId = getShopItemsStreamId(ShopItemType.PREMIUM_CONTENT);
  const premiumContentDeviations = useSelector(state =>
    getDeviationsByIds(state, streamSelectors.getItems(state, streamId))
  );
  const isEmpty = !premiumContentDeviations.length;
  const showAddItemCard = isOwner;

  const renderWidgetContent = () =>
    isEmpty ? (
      <PremiumContentEmptyState view={'wide'} />
    ) : (
      <DuperbrowseContext
        streamId={streamId}
        deviations={premiumContentDeviations}
        parent={{ title: t('duperbrowse.backButton.profile.shop') }}
      >
        {isMobile ? (
          <PremiumContentSliderMobile
            deviations={premiumContentDeviations}
            showAddItemCard={showAddItemCard}
          />
        ) : (
          <Slider
            renderSliderItems={elementSize => (
              <>
                {showAddItemCard && (
                  <AddItemCard
                    title={t('pages.shop.premiumContent.addItem.title')}
                    subtitle={t('pages.shop.premiumContent.addItem.subtitle')}
                    onClick={() =>
                      window.location.replace(
                        Url.studio({ openSubmitForm: true })
                      )
                    }
                    nonCoreMembershipView={
                      <PremiumContentEmptyState view={'small'} />
                    }
                    biData={BiData<DefaultBiEvent>({
                      evid: 2,
                      click_info: 'create_premium_download',
                      sectionname: 'premium_download',
                    })}
                    view={'small'}
                  />
                )}
                {premiumContentDeviations.map(deviation => (
                  <PCPShopCard
                    key={deviation.deviationId}
                    deviation={deviation}
                    width={elementSize.width}
                    withAuthorDetails={false}
                    withIndicators={false}
                    withDreamupLabel={false}
                  />
                ))}
              </>
            )}
          />
        )}
      </DuperbrowseContext>
    );

  if (isEmpty && !isOwner) {
    return null;
  }

  return (
    <WidgetBox
      header={t('common.premiumContent')}
      count={moduleData?.total}
      moduleId={moduleId}
      seeAllUrl={Url.userShopPath(
        profileOwner.user.username,
        USER_PROFILE_SHOP_SUB_PATHS.PREMIUM_CONTENT
      )}
      {...widgetBoxProps}
    >
      {renderWidgetContent()}
    </WidgetBox>
  );
};
PremiumContentSlider.displayName = 'PremiumContentSlider';

export default PremiumContentSlider;
