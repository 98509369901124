import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import s from './AvatarBirthdayBadge.scss';

export interface Props {
  isOwner: boolean;
  profileOwnerUser: PapiUser;
}

const AvatarBirthdayBadge: React.FC<Props> = ({
  isOwner,
  profileOwnerUser,
}) => {
  const { t } = useTranslation();

  const assetUrl =
    'https://st.deviantart.net/badges/cake/huge/19-sparklerific-cake.gif';

  return (
    <div className={s['root']}>
      <SimpleTooltip
        placement="bottom"
        usePortal
        text={
          isOwner
            ? t('widgets.profile.birthdayWeek.owner')
            : t('widgets.profile.birthdayWeek.visitor', {
                username: profileOwnerUser.username,
              })
        }
      >
        <img
          className={s['cake']}
          alt={t('widgets.profile.birthdayWeek.visitor', {
            username: profileOwnerUser.username,
          })}
          src={assetUrl}
        />
      </SimpleTooltip>
    </div>
  );
};
AvatarBirthdayBadge.displayName = 'AvatarBirthdayBadge';
export default AvatarBirthdayBadge;
