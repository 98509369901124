import React from 'react';
import c from 'classnames';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';

import s from './WideWidgetLayout.scss';

export interface Props {
  header?: string;
  subSubheader?: string;
  navigation: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  widgetsRootClassName?: string;
}

export const WideWidgetLayout: React.FC<Props> = ({
  header,
  subSubheader,
  navigation,
  children,
  className,
  widgetsRootClassName,
}) => {
  // If header is supplied, we want to make sure all of this is wrapped in a section
  const ContentColumnComponent = header ? Section : 'div';

  return (
    <div className={c(s['root'], className)}>
      <div className={s['navigation']}>{navigation}</div>
      <ContentColumnComponent className={s['content-column']}>
        {header && (
          <Heading className={s['header']}>
            <div>{header}</div>
          </Heading>
        )}
        {subSubheader && <div className={s['subheader']}>{subSubheader}</div>}
        <div className={c(s['widgets'], widgetsRootClassName)}>{children}</div>
      </ContentColumnComponent>
    </div>
  );
};
WideWidgetLayout.displayName = 'WideWidgetLayout';

export default WideWidgetLayout;
