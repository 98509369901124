import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { AppState } from '../../types/store';
import { getPagingMode } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  getFolderDeviationsStreamId,
  STREAM_PARAM_TYPE_FETCHING_PAUSED,
} from '../streams/folderDeviations';
import { getFolderById, getNonEmptyFolders } from './folders';
import { getCanCreateGalleryFolder } from './privs';
import { createSelector } from 'reselect';

export const getGallectionSectionType = (state: AppState) =>
  state.gallectionSection.type;

export const getSelectedFolderId = (state: AppState) =>
  state.gallectionSection.selectedFolderId;

export const getSelectedFolder = (state: AppState) => {
  const selectedFolderId = getSelectedFolderId(state);
  const gallectionType = getGallectionSectionType(state);
  return typeof selectedFolderId === 'number'
    ? getFolderById(state, gallectionType, selectedFolderId)
    : undefined;
};

export const getIsPagedMode = (state: AppState) =>
  getPagingMode(state) === 'page';

export const getSelectedSubfolderId = (state: AppState) =>
  state.gallectionSection.selectedSubfolderId;

export const getSelectedSubfolder = (state: AppState) => {
  const selectedSubfolderId = getSelectedSubfolderId(state);
  const gallectionType = getGallectionSectionType(state);
  return typeof selectedSubfolderId === 'number'
    ? getFolderById(state, gallectionType, selectedSubfolderId)
    : undefined;
};

export const getCurrentlyViewedFolderId = (state: AppState) =>
  state.gallectionSection.currentlyViewedFolderId;

export const getCurrentlyViewedFolder = (state: AppState) => {
  const currentlyViewedFolderId = getCurrentlyViewedFolderId(state);
  const gallectionType = getGallectionSectionType(state);
  return typeof currentlyViewedFolderId === 'number'
    ? getFolderById(state, gallectionType, currentlyViewedFolderId)
    : undefined;
};

export const getHasOnlyDefaultFolders = (state: AppState) =>
  getNonEmptyFolders(state).length === 2; // All and Featured (these folders can't be removed)

export const getShouldHideFolderSlider = (state: AppState) => {
  const canCreateFolder = getCanCreateGalleryFolder(state);
  const hasOnlyDefaultFolders = getHasOnlyDefaultFolders(state);
  return !canCreateFolder && hasOnlyDefaultFolders;
};

export const getSortOrder = (state: AppState) =>
  state.gallectionSection.sortOrder;

export const getSearchInputValue = (state: AppState) =>
  state.gallectionSection.searchInputValue;

export const getSearchQuery = (state: AppState) =>
  state.gallectionSection.searchQuery;

export const getFullViewFolderIds = (state: AppState) =>
  state.gallectionSection.fullViewFolderIds;

export const getLastFullViewFolderStreamId = (state: AppState) => {
  const fullViewFolderIds = getFullViewFolderIds(state);
  const lastFullViewFolderId = fullViewFolderIds[fullViewFolderIds.length - 1];
  const gallectionType = getGallectionSectionType(state);
  const lastFullViewFolderStreamId = getFolderDeviationsStreamId(
    gallectionType,
    lastFullViewFolderId
  );
  return lastFullViewFolderStreamId;
};

export const getHasMoreDeviationsInLastFullViewFolder = (state: AppState) => {
  return streamSelectors.hasMore(state, getLastFullViewFolderStreamId(state));
};

export const getIsFetchingPausedInLastFullViewFolder = (state: AppState) => {
  return !!streamSelectors.getStreamParam(
    state,
    getLastFullViewFolderStreamId(state),
    STREAM_PARAM_TYPE_FETCHING_PAUSED
  );
};

export function getNextFullViewFolderId(state: AppState) {
  const hasMoreDeviationsInLastFullViewFolder =
    getHasMoreDeviationsInLastFullViewFolder(state);
  const isFetchingPausedInLastFullViewFolder =
    getIsFetchingPausedInLastFullViewFolder(state);

  if (
    hasMoreDeviationsInLastFullViewFolder &&
    !isFetchingPausedInLastFullViewFolder
  ) {
    // we load next folder only when we run out deviations to load in the last one
    // or we paused load-more because the user clicked jump to comments
    return;
  }

  const folders = getNonEmptyFolders(state);
  const fullViewFolderIds = getFullViewFolderIds(state);
  if (fullViewFolderIds.length === folders.length) {
    return;
  }

  const topLevelFolders = folders.filter(folder => !folder.parentId);
  const lastFullViewFolderId = fullViewFolderIds[fullViewFolderIds.length - 1];
  return topLevelFolders[
    topLevelFolders.findIndex(
      folder => folder.folderId === lastFullViewFolderId
    ) + 1
  ]?.folderId;
}

export const getSubFolderFetchState = createSelector(
  state => state,
  (_, type) => type,
  (_, __, parentId) => parentId,
  (state: AppState, type, parentId) => {
    return state.gallectionSection.subFolderFetchState[`${type}-${parentId}`];
  }
);
