import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { showBadgeInfoModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { AppState } from '../../../../../types/store';
import { getProfileOwnerUser } from '../../../../selectors/users';
import { Props } from './SpotlightBadge';

type StateProps = Pick<Props, 'username'>;
type DispatchProps = Pick<Props, 'showModal'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => ({
  username: getProfileOwnerUser(state).username,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  showModal: showBadgeInfoModal,
};

export default connect(mapStateToProps, mapDispatchToProps);
