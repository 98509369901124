import React, { HTMLAttributes } from 'react';
import c from 'classnames';
import s from './LargeButtonsWrapper.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const LargeButtonsWrapper: React.FC<Props> = ({ className, ...rest }) => (
  <div className={c(s['root'], className)} {...rest} />
);
LargeButtonsWrapper.displayName = 'LargeButtonsWrapper';

export default LargeButtonsWrapper;
