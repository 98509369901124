import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import AdoptableSlider from './AdoptableSlider';
import adoptablesInit from './redux/adoptablesInit';

export const getAdoptableSliderWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent: AdoptableSlider,
  initActionsCreator: adoptablesInit,
});
