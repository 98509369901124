import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './SubscriptionsCoreOnlyBlock';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { createTipJarTier } from '@wix/da-shared-react/pkg/Tier/redux/creator/actions';
import { AppState } from '../../../../../types/store';

export type StateProps = Pick<Props, 'hasCore'>;
export type DispatchProps = Pick<Props, 'createTier' | 'createTipJar'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => ({
    hasCore: getCurrentUserHasCore(state),
  });
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> =
  dispatch => ({
    createTier: () =>
      dispatch(
        pushModal(ModalType.TIER_EDIT, {
          wrapInModal: false,
        })
      ),
    createTipJar: () => dispatch(createTipJarTier()),
  });

export default connect(mapStateToProps, mapDispatchToProps);
