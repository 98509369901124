import React from 'react';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { PapiDeviation } from '@wix/da-papi-types';
import PCPShopCard from '@wix/da-shared-react/pkg/ShopCard/PCPShopCard';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';
import { ShopItemType } from '../../../../../../../types/shop';
import UploadMore from '../../../_partials/UploadMore';

interface Props {
  showAddItemCard?: boolean;
  deviations: PapiDeviation[];
}

const PremiumContentSliderMobile: React.FC<Props> = ({
  showAddItemCard,
  deviations,
}) => {
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    undefined,
    {
      cookieDim: 'width',
    }
  );

  const DEFAULT_CARD_WIDTH = 320;
  const DEFAULT_CARD_HEIGHT = 292;

  const cardWidthMobile = measuredContainer?.width ?? DEFAULT_CARD_WIDTH;

  return (
    <div ref={ref}>
      <SwipeSlider startSlide={showAddItemCard ? 1 : 0}>
        {showAddItemCard && (
          <UploadMore
            itemType={ShopItemType.PREMIUM_CONTENT}
            height={DEFAULT_CARD_HEIGHT}
            biData={BiData<DefaultBiEvent>({
              evid: 2,
              click_info: 'create_premium_download',
              sectionname: 'premium_download',
            })}
          />
        )}
        {deviations.map(deviation => (
          <PCPShopCard
            key={deviation.deviationId}
            width={cardWidthMobile}
            deviation={deviation}
            withAuthorDetails={false}
            withDreamupLabel={false}
          />
        ))}
      </SwipeSlider>
    </div>
  );
};
PremiumContentSliderMobile.displayName = 'PremiumContentSliderMobile';

export default PremiumContentSliderMobile;
