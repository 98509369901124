import {
  getWideGroupListAdminsWidgetConfig,
  getWideGroupListMembersWidgetConfig,
} from '../../UserWidgets/GroupList/widgetConfig';
import { getMyBadgesWidgetConfig } from '../../UserWidgets/MyBadges/widgetConfig';
import { getStatsWidgetConfig } from '../../UserWidgets/Stats/widgetConfig';
import getCoverDeviationWidgetConfig from '../../UserWidgets/CoverDeviation/widgetConfig';
import { getGroupAboutWidgetWideConfig } from '../../GroupWidgets/GroupAbout/widgetWideConfig';
import { getWideGroupMembersWidgetConfig } from '../../GroupWidgets/GroupMembers/widgetConfig';
import { getWideGroupAdminsWidgetConfig } from '../../GroupWidgets/Admins/widgetConfig';
import {
  getWideWatchersWidgetConfig,
  getWideWatchingWidgetConfig,
} from '../../UserWidgets/WatchedDeviants/widgetConfig';
import { getWideAboutMeWidgetConfig } from '../../UserWidgets/AboutMe/widgetConfig';
import { getMyCommentsWidgetConfig } from '../../UserWidgets/MyComments/widgetConfig';

export const getAboutSectionWidgetConfigs = ({ t, dispatch }) => ({
  coverDeviation: getCoverDeviationWidgetConfig({ t, dispatch }),
  groupAbout: getGroupAboutWidgetWideConfig({ t, dispatch }),
  about: getWideAboutMeWidgetConfig({ t, dispatch }),
  userstats: getStatsWidgetConfig({ t, dispatch }),
  groupListAdmins: getWideGroupListAdminsWidgetConfig({ t, dispatch }),
  groupListMembers: getWideGroupListMembersWidgetConfig({ t, dispatch }),
  groupAdmins: getWideGroupAdminsWidgetConfig({ t, dispatch }),
  groupMembers: getWideGroupMembersWidgetConfig({ t, dispatch }),
  badgesActivity: getMyBadgesWidgetConfig({ t, dispatch }),
  watching: getWideWatchingWidgetConfig({ t, dispatch }),
  watchers: getWideWatchersWidgetConfig({ t, dispatch }),
  myComments: getMyCommentsWidgetConfig({ t, dispatch }),
});
