import React from 'react';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import {
  BiData,
  BiContextProvidedValue,
  UserNameClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import UserGrid from '../../_partials/UserGrid/UserGrid';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';

interface Props {
  moduleId: number;
}

const Supporters: React.FC<Props> = ({ moduleId }) => {
  const logEvent = useBiLogger();
  const top_supporters = useFlag('top_supporters');

  const { moduleData } = useModuleData<any>(moduleId);

  const users = top_supporters
    ? (moduleData.timePeriods?.alltime?.results || []).map(
        supporter => supporter.user
      )
    : moduleData?.results;

  return (
    <UserGrid
      users={users || []}
      onUserClick={user => {
        logEvent(
          BiData<UserNameClickBiEvent>({
            evid: 101,
            sectionname: BiContextProvidedValue,
            widgetname: BiContextProvidedValue,
            typeid: ResourceType.USER,
            itemid: user.userId,
          })
        );
      }}
    />
  );
};
Supporters.displayName = 'Supporters';

export default Supporters;
