import { SagaIterator } from 'redux-saga';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { all, takeEvery, select, call, put } from 'redux-saga/effects';
import {
  BiData,
  GallectionSearchSubmitBiEvent,
  PostFeedViewBiEvent,
  ProfileNavBarBiEvent,
  PureBiData,
  ScrollToLoadMoreBiEvent,
  ScrollToLoadNextFolderBiEvent,
} from '@wix/da-bi/pkg/events';
import {
  logPageView,
  biModalUnload,
} from '@wix/da-shared-react/pkg/biLogger/redux/actions';
import { logBiEvent } from '@wix/da-shared-react/pkg/biLogger/redux/saga';
import isSSR from '@wix/da-shared-react/pkg/utils/isSSR';
import { getPagingMode } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import { FETCH_SECTION_SUCCESS } from '../actions/sections';
import {
  getBiComponent,
  getPageViewBiData,
  getBiTypeAndItemId,
} from '../selectors/biLogger';
import {
  biFetchMoreFolderItems,
  biClickProfileNavBar,
  biLogPostsFeedItems,
} from '../actions/bi';
import { selectors, withOffset } from '@wix/da-shared-react/pkg/Stream';
import { exitDuperbrowseDone } from '@wix/da-shared-react/pkg/Duperbrowse/redux/actionCreators';
import {
  getFullViewFolderIds,
  getCurrentlyViewedFolderId,
} from '../selectors/gallectionSection';
import { getSectionStreamId } from '../selectors/sections';
import {
  GallectionResourceType,
  GallectionType,
} from '@wix/da-shared-react/pkg/types/gallection';
import { getProfileOwnerUser } from '../selectors/users';

export function* logSearchSubmit(searchQuery: string) {
  if (isSSR()) {
    return; // clientside only, as this event is also fired on SSR when search query is set from URL's query string
  }
  const event = BiData<GallectionSearchSubmitBiEvent>({
    evid: 299,
    search_text: searchQuery,
  });
  yield call(logBiEvent, event);
}

export function* pageViewHandler() {
  if (isSSR()) {
    return; // clientside only
  }
  const pageViewData: ReturnType<typeof getPageViewBiData> = yield select(
    getPageViewBiData
  );
  yield put(logPageView(pageViewData));

  const streamId = yield select(getSectionStreamId);
  const entityIds = yield select(selectors.getItems, streamId);
  const deviations = yield select(getDeviationsByIds, entityIds);
  yield put(biLogPostsFeedItems(deviations));
}

function* fetchSectionHandler(action) {
  if (isSSR()) {
    return; // clientside only
  }
  const pageViewData: ReturnType<typeof getPageViewBiData> = yield select(
    getPageViewBiData
  );
  yield put(logPageView(pageViewData));
}

function* infiniteScrollHandler(action) {
  const { streamId } = action.payload;
  const extraData: ReturnType<typeof getBiTypeAndItemId> = yield select(
    getBiTypeAndItemId
  );
  const biTotalItemsCount = yield select(selectors.getTotalItemsCount);
  const currentlyViewedFolderId = yield select(getCurrentlyViewedFolderId);
  const streamOffset = yield select(state =>
    withOffset.selectors.getNextOffset(state, streamId)
  );
  const streamParams = yield select(state =>
    selectors.getStreamParamsSafe(state, streamId)
  );
  const fullviewFolderIds = yield select(getFullViewFolderIds);

  if (streamOffset === 0 && fullviewFolderIds.length > 1) {
    const typeid =
      streamParams.gallectionType === GallectionType.COLLECTION
        ? GallectionResourceType.COLLECTION
        : GallectionResourceType.GALLERY;
    yield call(
      logBiEvent,
      PureBiData<ScrollToLoadNextFolderBiEvent>({
        evid: 96,
        typeid,
        itemid: currentlyViewedFolderId,
        next_itemid: streamParams.folderId,
        next_typeid: typeid,
      })
    );
  }

  yield call(
    logBiEvent,
    PureBiData<ScrollToLoadMoreBiEvent>({
      evid: 98,
      itemid: extraData.itemid,
      typeid: extraData.typeid,
      offset: biTotalItemsCount,
    })
  );
}

function* biClickProfileNavBarHandler(action) {
  const { linkName } = action.payload;
  const eventComponent = yield select(getBiComponent);
  const extraData: ReturnType<typeof getBiTypeAndItemId> = yield select(
    getBiTypeAndItemId
  );
  const owner = yield select(getProfileOwnerUser);
  yield call(
    logBiEvent,
    BiData<ProfileNavBarBiEvent>({
      evid: 200,
      view: owner.isGroup ? 'group' : 'profile',
      component: eventComponent,
      link_name: linkName,
      itemid: extraData.itemid,
      typeid: extraData.typeid,
    })
  );
}

export function* biLogPostsFeedItemsHandler(
  action: ReturnType<typeof biLogPostsFeedItems>
): SagaIterator {
  if (isSSR()) {
    return;
  }
  const {
    payload: { deviations },
  } = action;

  const streamId = yield select(getSectionStreamId);

  const pagingMode = yield select(getPagingMode);
  if (
    !streamId ||
    !deviations ||
    !deviations.length ||
    pagingMode !== 'scroll'
  ) {
    // POST_FEED_VIEW event should only fire for infinite scroll
    return;
  }
  const streamItems = yield select(selectors.getItems, streamId);
  const offset = streamItems.length - deviations.length;
  const metadata = JSON.stringify(
    deviations.map((item, index) => ({
      typeid: item.typeId,
      item: item.deviationId,
      rank: offset + index,
    }))
  );
  const { itemid, typeid }: ReturnType<typeof getBiTypeAndItemId> =
    yield select(getBiTypeAndItemId);

  yield call(
    logBiEvent,
    PureBiData<PostFeedViewBiEvent>({
      evid: 95,
      itemid,
      typeid,
      offset,
      metadata,
    })
  );
}

export default function* biLogger() {
  yield all([
    takeEvery(
      [appMounted, exitDuperbrowseDone, biModalUnload],
      pageViewHandler
    ),
    takeEvery(FETCH_SECTION_SUCCESS, fetchSectionHandler),
    takeEvery(biFetchMoreFolderItems, infiniteScrollHandler),
    takeEvery(biClickProfileNavBar, biClickProfileNavBarHandler),
    takeEvery(biLogPostsFeedItems, biLogPostsFeedItemsHandler),
  ]);
}
