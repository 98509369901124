import { handleActions, combineActions } from 'redux-actions';
import {
  addModule,
  addModuleSuccess,
  updateModuleOrderSuccess,
  updateSectionLayoutVersion,
  addModuleFail,
} from '../actions/layout';

const defaultState = {
  home: { zones: {} },
  about: { zones: {} },
};

// merge-update zone state
const updateZoneState = (appState, section, zone, zoneStateSlice) => {
  return {
    ...appState,
    [section]: {
      ...appState[section],
      zones: {
        ...appState[section].zones,
        [zone]: {
          ...appState[section].zones[zone],
          ...zoneStateSlice,
        },
      },
    },
  };
};

export default handleActions<any, any>(
  {
    [updateSectionLayoutVersion.toString()]: (state, action) => {
      const { section, version } = action.payload;
      return {
        ...state,
        [section]: {
          ...state[section],
          version,
        },
      };
    },
    [updateModuleOrderSuccess.toString()]: (state, action) => {
      const { section, zone, order: installedModulesIds } = action.payload;
      return updateZoneState(state, section, zone, { installedModulesIds });
    },
    [addModule.toString()]: (state, action: ReturnType<typeof addModule>) => {
      const { payload } = action;
      if (!payload) {
        return;
      }
      const { section, zone, position } = payload;
      return updateZoneState(state, section, zone, {
        isInstallingModule: true,
        currentlyInstalledModulePostion: position,
      });
    },
    [combineActions(addModuleSuccess, addModuleFail).toString()]: (
      state,
      action: ReturnType<typeof addModuleSuccess>
    ) => {
      const { payload } = action;
      if (!payload) {
        return;
      }
      const { section, zone } = payload;
      return updateZoneState(state, section, zone, {
        isInstallingModule: false,
        currentlyInstalledModulePostion: null,
      });
    },
  },
  defaultState
);
