import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import AdminsBox from './AdminsBox';
import AdminsBoxWide from './AdminsBoxWide';

export const getGroupAdminsWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: AdminsBox,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-5-donation-pool.svg',
    title: t('widgets.group_admins.addButtonHeader'),
    description: t('widgets.group_admins.description'),
  },
});

export const getWideGroupAdminsWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  ...getGroupAdminsWidgetConfig({ t, dispatch }),
  viewComponent: AdminsBoxWide,
});
