import React from 'react';
import { ModalType } from '../../../types/modals';

import GroupCloseModal from './GroupCloseModal';
import GroupChangeFounderModal from './GroupChangeFounderModal';

import InviteToGroupModal from './InviteToGroupModal';
import GivePointsPopup from './GivePointsPopup';
import CompletionPopup from '@wix/da-shared-react/pkg/CompletionPopup';

import i18next from 'i18next';

export const modalRenderer = (
  modalType: string,
  requestClose: () => void,
  params?: any
) => {
  switch (modalType) {
    case ModalType.GROUP_CLOSE:
      return <GroupCloseModal requestClose={requestClose} />;

    case ModalType.GROUP_CHANGE_FOUNDER:
      return <GroupChangeFounderModal requestClose={requestClose} />;

    case ModalType.INVITE_TO_GROUP:
      return (
        <InviteToGroupModal requestClose={requestClose} group={params.group} />
      );
    case ModalType.GIVE_POINTS:
      return <GivePointsPopup username={params.username} />;

    case ModalType.GIVE_POINTS_SUCCESS:
      const { username, pointsAmount } = params;

      const PointsInfo: React.FC<any> = ({ username, pointsAmount }) => {
        return (
          <strong>
            <span>{username}</span> <span>{pointsAmount}</span>{' '}
            {
              i18next.t('givePointsPopup.successMessage.points', {
                count: pointsAmount,
              }) as any
            }
          </strong>
        );
      };

      return (
        <CompletionPopup
          imageWidth={113}
          imageHeight={122}
          imageWidthMobile={90}
          imageHeightMobile={83}
          image="https://st.deviantart.net/eclipse/popups/give-popups/give_points_-_il3-3.svg"
          title={i18next.t('common.successHappy')}
          message={
            i18next.t('givePointsPopup.successMessage', {
              postProcess: ['reactPostprocessor', 'featureBrancher'],
              count: pointsAmount,
              br: <br />,
              userAndAmount: (
                <PointsInfo username={username} pointsAmount={pointsAmount} />
              ),
            }) as any
          }
          buttonLabel={i18next.t('givePointsPopup.button.label')}
        />
      );
    default:
      return;
  }
};

export default modalRenderer;
