import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EmptyState from '../../../EmptyState';
import DeviationSelector from '../FeaturedDeviationConfigPopover/DeviationPicker';
import {
  PapiRequestGruserModuleFeaturedDeviationSave,
  PapiRequestGruserModuleFeaturedPostSave,
} from '@wix/da-papi-types';
import { useModuleConfig } from '@wix/da-gruser-shared/pkg/hooks/useModuleConfig';
import { useModuleName } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import snakeCase from 'lodash/snakeCase';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';
import { getProfileOwner } from '../../../../selectors/users';

import s from './FeaturedDeviationEmpty.scss';

interface Props {
  moduleId: number;
}

const FeaturedDeviationEmpty: React.FC<Props> = ({ moduleId }) => {
  const { t } = useTranslation();
  const profileOwner = useSelector(getProfileOwner);
  const isOwnerGroup = isGroup(profileOwner.user);
  const moduleName = useModuleName(moduleId);
  const moduleNameSnakeCase = snakeCase(moduleName);
  let subTitle = t(`widgets.${moduleNameSnakeCase}.empty.subtitle.owner`);

  // This one particular instance needs a different subtitle for groups
  if (moduleNameSnakeCase === 'featured_post' && isOwnerGroup) {
    subTitle = t(`widgets.featured_post.group.empty.subtitle.owner`);
  }

  const { saveModuleConfig } = useModuleConfig(moduleId, moduleNameSnakeCase);

  const [showingDeviationSelector, setShowingDeviationSelector] =
    useState(false);

  return (
    <>
      <EmptyState
        className={s['root']}
        view="large"
        title={t(`widgets.${moduleNameSnakeCase}.empty.title.owner`)}
        subtitle={subTitle}
        withButton
        buttonLabel={t(`widgets.${moduleNameSnakeCase}.empty.CTALabel.owner`)}
        onButtonClick={() => setShowingDeviationSelector(true)}
      />
      {showingDeviationSelector && (
        <DeviationSelector
          type={moduleName === 'featuredDeviation' ? 'regular' : 'posts'}
          onClose={() => setShowingDeviationSelector(false)}
          onSelect={selected => {
            setShowingDeviationSelector(false);
            const deviation = selected[0];
            let params = {} as any;
            if (moduleName === 'featuredDeviation') {
              const featuredDeviationParams: Partial<PapiRequestGruserModuleFeaturedDeviationSave> =
                {
                  deviationid: deviation?.deviationId,
                };
              params = featuredDeviationParams;
            } else {
              const featuredPostParams: Partial<PapiRequestGruserModuleFeaturedPostSave> =
                {
                  itemid: deviation?.deviationId,
                  typeid: deviation?.typeId,
                };
              params = featuredPostParams;
            }
            saveModuleConfig(params);
          }}
        />
      )}
    </>
  );
};
FeaturedDeviationEmpty.displayName = 'FeaturedDeviationEmpty';

export default FeaturedDeviationEmpty;
