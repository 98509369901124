import React, { Suspense, lazy } from 'react';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const FeedConfigPopoverLazy = lazy(
  () => import(/* webpackChunkName: "owner" */ '../../FeedConfigPopover')
);

type Props = any;

const FeedConfigPopoverLazyLazy: React.FC<Props> = props => {
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      <FeedConfigPopoverLazy {...props} />
    </Suspense>
  );
};
FeedConfigPopoverLazyLazy.displayName = 'FeedConfigPopoverLazyLazy';

export default FeedConfigPopoverLazyLazy;
