import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { navigate } from '../../../helpers';
import { FILTER_QUERYSTRING_KEYS } from '../../constants';

import s from './EmptyAfterFiltering.scss';

const FILTER_AND_SEARCH_PARAMS = [...FILTER_QUERYSTRING_KEYS, 'q'];
const SVG_URL = Url.staticCDNLink(
  'eclipse/global/svg/il55-explore-deviant.svg'
);

export interface Props {
  isFiltering?: boolean;
  isSearching?: boolean;
  className?: string;
}

const EmptyAfterFiltering: React.FC<Props> = ({
  isFiltering,
  isSearching,
  className,
}) => {
  const { t } = useTranslation();
  const clearFilters = () =>
    navigate(
      removeParamsInQuerystring(FILTER_AND_SEARCH_PARAMS, window.location.href)
    );

  return (
    <Section className={cn(className, s['root'])}>
      <img className={s['illustration']} alt="" src={SVG_URL} />

      <Heading className={s['text']}>
        {isFiltering || isSearching ? (
          <>
            {t('pages.shop.shopFilters.emptyAfterFiltering.checkYourFilters')}{' '}
            <PlainButtonOrLink
              className={cn(s['clear-filters-link'], 'text-link-level-1-brand')}
              onClick={clearFilters}
            >
              {t('pages.shop.shopFilters.emptyAfterFiltering.clearFilters')}
            </PlainButtonOrLink>
          </>
        ) : (
          t('pages.shop.shopFilters.emptyAfterFiltering.noResults')
        )}
      </Heading>
    </Section>
  );
};

EmptyAfterFiltering.displayName = 'EmptyAfterFiltering';
export default EmptyAfterFiltering;

// ----
function removeParamsInQuerystring(keys: string[], url: string | URL): string {
  const updated = new URL(url);
  for (const key of keys) {
    updated.searchParams.delete(key);
  }
  return `${updated}`;
}
