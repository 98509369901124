import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { useNormalizedUserStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import { PapiGruserModuleWatching } from '@wix/da-papi-types';
import snakeCase from 'lodash/snakeCase';
import WidgetBoxWide from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import { Props as WidgetBoxProps } from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import { useModuleName } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import WatchedDeviants from '../WatchedDeviants';
import { getProfileOwnerStat } from '../../../../selectors/users';
import { useSelector } from 'react-redux';
import { WATCHER_IDS_STREAM, WATCHING_IDS_STREAM } from '../../../../streams';
import EmptyState from '../../../EmptyState';
import { ModuleType } from '../../../../../types/modules';
import getStatLine from '../../../../utils/getStatLine';

import s from './WatchedDeviantsBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const WatchedDeviantsBoxWide: React.FC<Props> = ({
  moduleId,
  widgetBoxProps,
}) => {
  const { t } = useTranslation();
  const moduleName = useModuleName(moduleId);
  const moduleNameSnakeCase = snakeCase(moduleName);

  const totalCount = useSelector<any, number>(state =>
    getProfileOwnerStat(state, moduleName)
  );
  const { moduleData } = useModuleData<PapiGruserModuleWatching>(moduleId);
  const modulePrivs = useModulePrivileges(moduleId);
  const isEmpty = !moduleData?.results.length;

  const streamInfo = useNormalizedUserStream(
    moduleNameSnakeCase === 'watching'
      ? WATCHING_IDS_STREAM
      : WATCHER_IDS_STREAM
  );

  const { items, hasMore, isFetching, fetchNext } = streamInfo;

  if (isEmpty && !modulePrivs?.edit) {
    return null;
  }

  return (
    <WidgetBoxWide
      id={
        moduleNameSnakeCase === 'watching'
          ? ModuleType.WATCHING
          : ModuleType.WATCHERS
      }
      header={t(`widgets.${moduleNameSnakeCase}.header`)}
      subHeader={getStatLine(t, moduleNameSnakeCase, totalCount)}
      moduleId={moduleId}
      count={totalCount}
      hasMore={hasMore}
      isFetching={isFetching}
      fetchMore={fetchNext}
      {...widgetBoxProps}
    >
      {!isEmpty ? (
        <WatchedDeviants moduleId={moduleId} users={items} />
      ) : (
        <EmptyState
          view="small"
          className={s['empty-state']}
          title={t(`widgets.${moduleNameSnakeCase}.empty.title.owner`)}
          subtitle={t(`widgets.${moduleNameSnakeCase}.empty.subtitle.owner`)}
          buttonLabel={t(`widgets.${moduleNameSnakeCase}.empty.CTALabel.owner`)}
          withButton={true}
          onButtonClick={() => {
            window.location.replace(Url.browseDeviantsYouWatchLink());
          }}
        />
      )}
    </WidgetBoxWide>
  );
};
WatchedDeviantsBoxWide.displayName = 'WatchedDeviantsBoxWide';

export default WatchedDeviantsBoxWide;
