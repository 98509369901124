import camelCase from 'lodash/camelCase';
import { all, select, put, takeEvery } from 'redux-saga/effects';
import { fetchModuleSuccess } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { getModuleDataFromPapiModule } from '@wix/da-gruser-shared/pkg/redux/helpers';
import { getAboutSectionWidgetConfigs } from '../components/Sections/AboutSection/aboutSectionWidgetConfigs';
import { getHomeWidgetConfigs } from '../components/Sections/HomeSection/homeWidgetConfigs';
import { getActiveSection } from '../selectors/sections';
import { getProfileGruser } from '../selectors/users';

export function* fetchModuleSuccessSaga(action) {
  const module = action.payload || {};
  const { id: moduleId, name: moduleName } = module;
  const activeSection = yield select(getActiveSection);
  let sectionWidgetConfigs;
  switch (activeSection) {
    case 'home':
      sectionWidgetConfigs = getHomeWidgetConfigs({
        t: () => null,
        dispatch: () => null,
      });
      break;
    case 'about':
      sectionWidgetConfigs = getAboutSectionWidgetConfigs({
        t: () => null,
        dispatch: () => null,
      });
      break;
    default:
      sectionWidgetConfigs = {};
  }
  const widgetConfig = sectionWidgetConfigs[camelCase(moduleName)];
  if (widgetConfig?.initActionsCreator) {
    const gruser = yield select(getProfileGruser);
    const initActions = widgetConfig.initActionsCreator(
      gruser,
      moduleId,
      getModuleDataFromPapiModule(module)
    );
    yield all(initActions.map(a => put(a)));
  }
}

export default function* rootModulesSaga() {
  yield all([takeEvery(fetchModuleSuccess, fetchModuleSuccessSaga)]);
}
