import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { useModuleVersion } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import { PapiModuleAbout } from '@wix/da-papi-types';
import { isEditorTextEmpty } from '@wix/da-editor-tiptap/pkg/components/utils/helpers';
import {
  ThemeOverride,
  ThemeSurface,
} from '@wix/da-react-context/pkg/ThemeContext';
import { AboutMeMode } from './types';
import MutualConnections from '../../UserWidgets/AboutMe/MutualConnections';
import { useSelector } from 'react-redux';
import {
  getProfileOwnerIsWatcher,
  getProfileOwnerUser,
} from '../../../selectors/users';
import ArtistInfoLine from '../../UserWidgets/AboutMe/ArtistInfoLine';
import UserInfoBullets from '../../UserWidgets/AboutMe/UserInfoBullets';
import { formatDateOfBirth } from '../../UserWidgets/AboutMe/utils';
import SocialMediaList from '../../UserWidgets/AboutMe/SocialMediaList';
import Badges from '../../UserWidgets/AboutMe/Badges';
import CollapsibleBio from '../../UserWidgets/AboutMe/CollapsibleBio';
import s from './AboutMe.scss';

interface Props {
  moduleId: number;
  mode?: AboutMeMode;
}

const AboutMe: React.FC<Props> = ({ moduleId, mode = AboutMeMode.DEFAULT }) => {
  const { t } = useTranslation();
  const moduleVersion = useModuleVersion(moduleId);
  const { moduleData } = useModuleData<PapiModuleAbout>(moduleId);
  const user = useSelector(getProfileOwnerUser);
  const isWatchingYou = useSelector(getProfileOwnerIsWatcher);
  const {
    isArtist,
    mutualWatchedUsers,
    mutualWatchedUsersCount,
    level,
    specialty,
    dobDay,
    dobMonth,
    dobYear,
    countrycode,
    deviantFor,
    gender,
    website,
    websiteLabel,
    socialLinks,
    badges,
    showBadges,
    textContent: bio,
    interests,
  } = moduleData;
  const modulePrivs = useModulePrivileges(moduleId);
  const isOwner = modulePrivs?.edit;
  const isCard = mode === AboutMeMode.CARD;

  const Gutter = ({ children }) => (
    <div className={c(isCard && s['gutter'])}>{children}</div>
  );

  const SectionLabel = ({ children }) => (
    <div className={s['section-label']}>{children}</div>
  );

  const SectionAndLabel = ({ label, children }) => (
    <div className={s['section']}>
      <SectionLabel>{label}</SectionLabel>
      {children}
    </div>
  );

  return (
    <div className={c(s['root'], isCard && 'ds-surface-secondary')}>
      <ThemeOverride themeSurface={ThemeSurface.SECONDARY}>
        {!isOwner && isCard && (
          <Gutter>
            <MutualConnections
              user={user}
              isWatchingYou={isWatchingYou}
              mutual={mutualWatchedUsers}
              mutualWatchedUsersCount={mutualWatchedUsersCount}
            />
          </Gutter>
        )}
        {isArtist && (
          <Gutter>
            <ArtistInfoLine
              level={level}
              specialty={specialty}
              className={s['info-line']}
            />
          </Gutter>
        )}
        <Gutter>
          <UserInfoBullets
            dateOfBirth={formatDateOfBirth(dobDay, dobMonth, dobYear, t)}
            countryCode={countrycode}
            deviantFor={deviantFor}
            gender={gender}
            website={website}
            websiteLabel={websiteLabel}
            isBirthweek={user.isBirthweek}
            username={user.username}
          />
        </Gutter>

        {socialLinks && socialLinks.length > 0 && (
          <Gutter>
            <SectionAndLabel label={t('widgets.aboutMe.followMe')}>
              <SocialMediaList socialLinks={socialLinks} user={user} />
            </SectionAndLabel>
          </Gutter>
        )}
        {showBadges && badges && (
          <Gutter>
            <SectionAndLabel label={t('widgets.aboutMe.myBadges')}>
              <Badges
                badges={badges}
                username={user.username}
                collapseMoreThan={6}
              />
            </SectionAndLabel>
          </Gutter>
        )}

        <Gutter>
          {bio && !isEditorTextEmpty(bio) && (
            <SectionLabel>{t('widgets.aboutMe.edit.myBio')}</SectionLabel>
          )}
          <CollapsibleBio
            bio={bio}
            interests={interests}
            mode={mode}
            moduleId={moduleId}
            moduleVersion={moduleVersion!}
          />
        </Gutter>
      </ThemeOverride>
    </div>
  );
};
AboutMe.displayName = 'AboutMe';

export default AboutMe;
