import React from 'react';
import { useTranslation } from 'react-i18next';
import { Url, USER_PROFILE_SECTION_PATHS } from '@wix/da-url';
import { PapiGruserModuleWatching } from '@wix/da-papi-types';
import snakeCase from 'lodash/snakeCase';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import { useModuleName } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import WatchedDeviants from '../WatchedDeviants';
import EmptyState from '../../../EmptyState';
import {
  getProfileOwner,
  getProfileOwnerStat,
} from '../../../../selectors/users';
import { useSelector } from 'react-redux';

import s from './WatchedDeviantsBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const WatchedDeviantsBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const profileOwner = useSelector(getProfileOwner);
  const moduleName = useModuleName(moduleId);
  const moduleNameSnakeCase = snakeCase(moduleName);

  const totalCount = useSelector<any, number>(state =>
    getProfileOwnerStat(state, moduleName)
  );
  const { moduleData } = useModuleData<PapiGruserModuleWatching>(moduleId);
  const modulePrivs = useModulePrivileges(moduleId);
  const isEmpty = !moduleData?.results.length;

  if (isEmpty && !modulePrivs?.edit) {
    return null;
  }

  return (
    <WidgetBox
      header={t(`widgets.${moduleNameSnakeCase}.header`)}
      moduleId={moduleId}
      count={totalCount}
      seeAllUrl={Url.userLink(
        profileOwner.user.username,
        `${USER_PROFILE_SECTION_PATHS.ABOUT}#${moduleName}`
      )}
      {...widgetBoxProps}
    >
      {!isEmpty ? (
        <WatchedDeviants moduleId={moduleId} users={moduleData.results} />
      ) : (
        <EmptyState
          view="small"
          className={s['empty-state']}
          title={t(`widgets.${moduleNameSnakeCase}.empty.title.owner`)}
          subtitle={t(`widgets.${moduleNameSnakeCase}.empty.subtitle.owner`)}
          buttonLabel={t(`widgets.${moduleNameSnakeCase}.empty.CTALabel.owner`)}
          withButton={true}
          onButtonClick={() => {
            window.location.replace(Url.browseDeviantsYouWatchLink());
          }}
        />
      )}
    </WidgetBox>
  );
};
WatchedDeviantsBox.displayName = 'WatchedDeviantsBox';

export default WatchedDeviantsBox;
