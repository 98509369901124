import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { ShopItemType } from '../../../../../../types/shop';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { CommissionStatusType } from '@wix/da-shared-react/pkg/types/commissions';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import {
  getProfileGruser,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { changeAllCommissionsStatus } from '../../../../../actions/commissions';
import { openCommissionEditModal } from '@wix/da-shared-react/pkg/redux/commissions/actionCreators';

import { Props } from './ShopCommissionsControls';
export type StateProps = Pick<
  Props,
  'isOwner' | 'isOpenForCommissions' | 'total'
>;
export type DispatchProps = Pick<
  Props,
  'openCommissionEditModal' | 'changeAllCommissionsStatus'
>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const gruser = getProfileGruser(state);
  const moduleData = getModuleDataByModuleName(
    state as any,
    gruser,
    'commissions'
  );
  const { isOpenForCommissions } = moduleData;
  const streamId = getShopItemsStreamId(ShopItemType.COMMISSIONS);
  return {
    streamId,
    items: getDeviationsByIds(state, streamSelectors.getItems(state, streamId)),
    total: streamSelectors.getTotal(state, streamId),
    isOwner: isCurrentUserProfileOwner(state),
    isOpenForCommissions,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  openCommissionEditModal: () =>
    dispatch(openCommissionEditModal({ deviationId: undefined })),
  changeAllCommissionsStatus: (status: CommissionStatusType) =>
    dispatch(changeAllCommissionsStatus({ status })),
});

export default connect(mapStateToProps, mapDispatchToProps);
