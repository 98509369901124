import { Action } from 'redux-actions';
import { PapiDeviation, PapiGruserModuleAdoptables } from '@wix/da-papi-types';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import {
  SHOP_ITEMS_PER_FETCH,
  SHOP_ITEMS_STREAM_GROUP_ID,
  getShopItemsStreamId,
} from '../../../../../../streams/shop';
import { ShopItemType } from '../../../../../../../types/shop';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiGruserModuleAdoptables
): Action<any>[] => {
  const { results } = moduleData;
  const deviations: PapiDeviation[] = results ?? [];
  const normalized = normalize(deviations, [deviationSchema]);

  return [
    withOffset.actions.initialize({
      streamId: getShopItemsStreamId(ShopItemType.PREMIUM_CONTENT),
      groupId: SHOP_ITEMS_STREAM_GROUP_ID,
      streamParams: {
        moduleId,
        shopItemType: ShopItemType.PREMIUM_CONTENT,
      },
      itemsPerFetch: SHOP_ITEMS_PER_FETCH,
      items: normalized.result,
      entities: normalized.entities,
      hasMore: moduleData.hasMore,
      total: moduleData.total,
    }),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
