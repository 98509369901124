import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { useSelector } from 'react-redux';
import { USER_PROFILE_SECTION_PATHS, Url } from '@wix/da-url';
import WidgetPaywall from '@wix/da-shared-react/pkg/widgets/lego/WidgetPaywall';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import ConditionalWrapper from '@wix/da-ds/pkg/ConditionalWrapper';
import useShowCoreUpsell from '@wix/da-shared-react/pkg/widgets/hooks/useShowCoreUpsell';
import { getProfileOwnerUser } from '../../../../selectors/users';
import BadgesSpotlight from '../BadgesSpotlight';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const BadgesSpotlightBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const showCoreUpsell = useShowCoreUpsell(moduleId);
  const { username } = useSelector(getProfileOwnerUser);

  return (
    <WidgetBox
      moduleId={moduleId}
      header={t('widgets.badgesSpotlight.title')}
      seeAllUrl={Url.userLink(
        username,
        `${USER_PROFILE_SECTION_PATHS.ABOUT}#badges_activity`
      )}
      {...widgetBoxProps}
    >
      <ConditionalWrapper
        condition={showCoreUpsell}
        wrap={child => (
          <WidgetPaywall
            title={t('widgets.badgesSpotlight.paywall.title')}
            subtitle={t('widgets.badgesSpotlight.paywall.subtitle')}
            buttonAlignment="horizontal"
            previewReferral={ReferralType.BADGES_SPOTLIGHT_PAYWALL_PREVIEW}
            lockedReferral={ReferralType.BADGES_SPOTLIGHT_PAYWALL_OVERLAY}
          >
            {child}
          </WidgetPaywall>
        )}
      >
        <BadgesSpotlight moduleId={moduleId} />
      </ConditionalWrapper>
    </WidgetBox>
  );
};
BadgesSpotlightBox.displayName = 'BadgesSpotlightBox';

export default BadgesSpotlightBox;
