import React from 'react';
import c from 'classnames';
import s from './TipDivider.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const TipDivider: React.FC<Props> = ({ className }) => (
  <div className={c(s['root'], className)} role="presentation" />
);
TipDivider.displayName = 'TipDivider';

export default TipDivider;
