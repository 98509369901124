import { call, all, takeEvery } from 'redux-saga/effects';
import i18next from 'i18next';
import { requestPuppy, isApiError } from '@wix/da-http-client';
import { putErrorNotification } from '@wix/da-shared-react/pkg/utils/saga';
import { setSectionPrivate } from '../actions/sections';

function* handleSetSectionPrivate(
  action: ReturnType<typeof setSectionPrivate>
) {
  const { isPrivate } = action.payload;
  const response = yield call(
    requestPuppy,
    {
      method: 'post',
      url: 'exclusives/set_private',
      data: {
        private_exclusives: isPrivate,
      },
    },
    undefined,
    'dauserprofile'
  );
  if (!response || isApiError(response)) {
    yield putErrorNotification(
      i18next.t('widgets.common.error.endpoint.fetchFailed')
    );
  } else {
    window.location.reload();
  }
}

export default function* rootSectionPrivateSaga() {
  yield all([takeEvery(setSectionPrivate, handleSetSectionPrivate)]);
}
