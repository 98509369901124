import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import FeaturedListItem from './FeaturedListItem';
import Button from '@wix/da-shared-react/pkg/Button';
import LoadableDuperbrowseContext from '../../../../../contexts/LoadableDuperbrowseContext';
import EmptyState from '../EmptyState';

import s from './FeaturedList.scss';

export interface Props {
  streamId: string;
  items: PapiDeviation[];
  fetchMore: () => void;
  hasMore: boolean;
  isFetching: boolean;
  isEmpty: boolean;
}

const FeaturedList: React.FC<Props> = ({
  items,
  streamId,
  isFetching,
  hasMore,
  fetchMore,
  isEmpty,
}) => {
  const { t } = useTranslation();
  if (isEmpty) {
    return (
      <EmptyState
        view="small"
        postType="featuredJournal"
        className={s['empty-state']}
      />
    );
  }

  return (
    <div className={s['root']}>
      <LoadableDuperbrowseContext
        streamId={streamId}
        parent={{ title: t('duperbrowse.backButton.profile.posts') }}
      >
        <div className={s['items']}>
          <OverlayContextProvider
            value={{
              showExtraActions: true,
              showThumbStatusIndicators: false,
            }}
          >
            {items.map((item, index) => {
              return (
                <FeaturedListItem
                  key={`${index}-${item.typeId}-${item.deviationId}-${item['isPinned']}`}
                  item={item}
                  className={s['item']}
                />
              );
            })}
          </OverlayContextProvider>

          {isFetching && <LoadingIndicator />}
          {hasMore && (
            <div className={s['button-wrapper']}>
              <Button view="b3" onClick={fetchMore} disabled={isFetching}>
                {t('common.loadMore')}
              </Button>
            </div>
          )}
        </div>
      </LoadableDuperbrowseContext>
    </div>
  );
};
FeaturedList.displayName = 'FeaturedList';

export default FeaturedList;
