export enum SocialLinks {
  BEHANCE = 1,
  DRIBBLE = 2,
  ETSY = 3,
  FACEBOOK = 4,
  FLICKR = 5,
  INSTAGRAM = 7,
  LINKEDIN = 8,
  PATREON = 9,
  PINTEREST = 10,
  TUMBLR = 11,
  TWITCH = 12,
  TWITTER = 13,
  VIMEO = 14,
  YOUTUBE = 15,
}

export const SocialLinkUrlRegex = {
  [SocialLinks.BEHANCE]:
    /^(https?:\/\/)?(www\.)?behance\.net\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.DRIBBLE]:
    /^(https?:\/\/)?(www\.)?dribbble\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.ETSY]:
    /^(https?:\/\/)?([\w0-9\-_]+\.)?etsy\.com(\/[\w+\-_=.,#%&$?!*'()]+)*\/?/i,
  [SocialLinks.FACEBOOK]:
    /^(https?:\/\/)?(www\.)?facebook\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.FLICKR]:
    /^(https?:\/\/)?(www\.)?flickr\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.INSTAGRAM]:
    /^(https?:\/\/)?(www\.)?instagram\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.LINKEDIN]:
    /^(https?:\/\/)?(www\.)?linkedin\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.PATREON]:
    /^(https?:\/\/)?(www\.)?patreon\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.PINTEREST]:
    /^(https?:\/\/)?(www\.)?([a-z]{2}\.)?pinterest\.(com|[a-z]{2})\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.TUMBLR]:
    /^(https?:\/\/)?((((www\.([\w+\-_=.,#%&$?!*'()]+\/?)+)|((?!(www\.))([\w+\-_=.,#%&$?!*'()]+\/?)+))tumblr\.com\/?)|((www\.)?tumblr\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+))/i,
  [SocialLinks.TWITCH]:
    /^(https?:\/\/)?(www\.)?twitch\.tv\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.TWITTER]:
    /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.VIMEO]:
    /^(https?:\/\/)?(www\.)?vimeo\.com\/([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
  [SocialLinks.YOUTUBE]:
    /^(https?:\/\/)?(www\.)?youtube\.com\/@?([\w+\-_=.,#%&$?!*'()]+\/?)+/i,
};

export function matchExact(r, str) {
  const match = str.match(r);
  return !!match && str === match[0];
}
