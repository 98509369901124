import React from 'react';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { PapiDeviation } from '@wix/da-papi-types';
import SellPromotionCard from '@wix/da-shared-react/pkg/ShopCard/SellPromotionCard';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';

interface Props {
  deviations: PapiDeviation[];
}

const ShopRecommendationsMobile: React.FC<Props> = ({ deviations }) => {
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    undefined,
    {
      cookieDim: 'width',
    }
  );

  const DEFAULT_CARD_WIDTH = 320;

  const cardWidthMobile = measuredContainer?.width ?? DEFAULT_CARD_WIDTH;

  return (
    <div ref={ref}>
      <SwipeSlider>
        {deviations.map((deviation, index) => (
          <SellPromotionCard
            key={index}
            deviation={deviation}
            width={cardWidthMobile}
          />
        ))}
      </SwipeSlider>
    </div>
  );
};
ShopRecommendationsMobile.displayName = 'ShopRecommendationsMobile';

export default ShopRecommendationsMobile;
