import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { PapiModuleTierContent } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { setTotalPages } from '../../../../../../actions/pageInfo';
import {
  TIER_CONTENT_DEVIATIONS_PER_FETCH,
  TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID,
  TIER_CONTENT_POSTS_PER_FETCH,
  getTierContentDeviationsStreamId,
} from '../../../../../../streams/tierContent';
import { setSectionStreamId } from '../../../../../../actions/sections';
import { setSelectedTierContentType } from '../../../../../../actions/subscriptionsSection';
import { getDeviationsCommentsInitActions } from '../../../../../../store/initActions/common';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleTierContent,
  requestParams: any
) => {
  const {
    tierDeviationid: tierDeviationId,
    deviations,
    section = requestParams.section,
    nextOffset,
    hasMore,
    totalPageCount,
  } = moduleData;
  const normalized = normalize(deviations, [deviationSchema]);
  const streamId = getTierContentDeviationsStreamId(
    tierDeviationId,
    section as any
  );

  return [
    withOffset.actions.initialize({
      streamId,
      groupId: TIER_CONTENT_DEVIATIONS_STREAM_GROUP_ID,
      itemsPerFetch:
        section === 'posts'
          ? TIER_CONTENT_POSTS_PER_FETCH
          : TIER_CONTENT_DEVIATIONS_PER_FETCH,
      streamParams: {
        tierDeviationId,
        section,
      },
      items: normalized.result,
      entities: normalized.entities,
      nextOffset: nextOffset!,
      hasMore,
    }),
    setSelectedTierContentType(section),
    setSectionStreamId(streamId),
    setTotalPages(totalPageCount || 1),
    ...getDeviationsCommentsInitActions(deviations),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
