import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import Donations from '../Donations';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const DonationsBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();

  return (
    <WidgetBox
      header={t('widgets.donations.header')}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      <Donations moduleId={moduleId} />
    </WidgetBox>
  );
};
DonationsBox.displayName = 'DonationsBox';

export default DonationsBox;
