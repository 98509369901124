import { call, put, select } from 'redux-saga/effects';
import {
  selectors as streamSelectors,
  withOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { FetchType } from '@wix/da-shared-react/pkg/Stream/base/types';
import { isApiError, requestPuppy } from '@wix/da-http-client';
import {
  PapiDaSharedGallectionFolders,
  PapiRequestDaSharedGallectionFolders,
} from '@wix/da-papi-types';
import { GallectionType } from '../../../types/gallection';
import { getProfileOwner } from '../../selectors/users';
import { GALLECTION_FOLDERS_PER_FETCH } from '../../streams';
import { normalizeGallectionList } from '../../utils/gallection';

export function* fetchMoreFolders({
  streamId,
  all,
}: ReturnType<typeof withOffset.actions.fetch>) {
  const [hasMore, gallectionType, username, offset]: [
    boolean,
    GallectionType,
    string,
    number
  ] = yield select(state => [
    streamSelectors.hasMore(state, streamId),
    streamSelectors.getStreamParam(state, streamId, 'gallectionType'),
    getProfileOwner(state).user.username,
    withOffset.selectors.getNextOffset(state, streamId),
  ]);

  if (!hasMore) {
    return;
  }

  const params: PapiRequestDaSharedGallectionFolders = {
    type: gallectionType,
    offset: all ? 0 : offset,
    username,
    limit: all ? 0 : GALLECTION_FOLDERS_PER_FETCH,
    with_subfolders: true,
  };

  const results: PapiDaSharedGallectionFolders | false = yield call(
    requestPuppy,
    {
      method: 'get',
      url: 'gallection/folders',
      params,
    }
  );

  if (!results || !results.results || isApiError(results)) {
    yield put(
      withOffset.actions.fetchFailure({
        streamId,
        fetchType: all ? FetchType.All : FetchType.Next,
        errorMsg: 'Failed to fetch',
      })
    );
    return;
  }

  const { result: items, entities } = normalizeGallectionList(
    results.results,
    gallectionType
  );

  yield put(
    withOffset.actions.fetchSuccessDeduped({
      streamId,
      items,
      hasMore: results.hasMore ?? false,
      nextOffset: results.nextOffset ?? undefined,
      entities,
    })
  );
}

export function* fetchPreviousFolders({
  payload: { streamId },
}: ReturnType<typeof withOffset.actions.fetchPrevious>) {
  const [hasPrevious, gallectionType, username, offset]: [
    boolean,
    GallectionType,
    string,
    number
  ] = yield select(state => [
    streamSelectors.getHasLess(state, streamId),
    streamSelectors.getStreamParam(state, streamId, 'gallectionType'),
    getProfileOwner(state).user.username,
    withOffset.selectors.getPrevOffset(state, streamId),
  ]);

  if (!hasPrevious) {
    return;
  }

  const params: PapiRequestDaSharedGallectionFolders = {
    type: gallectionType,
    offset,
    username,
    limit: GALLECTION_FOLDERS_PER_FETCH,
    with_subfolders: true,
  };

  const results: PapiDaSharedGallectionFolders | false = yield call(
    requestPuppy,
    {
      method: 'get',
      url: 'gallection/folders',
      params,
    }
  );

  if (!results || !results.results || isApiError(results)) {
    yield put(
      withOffset.actions.fetchFailure({
        streamId,
        fetchType: FetchType.Previous,
        errorMsg: 'Failed to fetch',
      })
    );
    return;
  }

  const { result: items, entities } = normalizeGallectionList(
    results.results,
    gallectionType
  );

  yield put(
    withOffset.actions.fetchPreviousSuccess({
      streamId,
      items,
      hasLess: results.hasLess ?? false,
      prevOffset: results.prevOffset ?? undefined,
      entities,
    })
  );
}
