import React, { useState, useCallback, useContext } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import {
  BiData,
  BuyCoreButtonBiEvent,
  CustomBoxPreviewBiEvent,
  BiContextProvidedValue,
} from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import CoreIcon from '@wix/da-ds/pkg/Icons/24x24/Core';
import EyeIcon from '@wix/da-ds/pkg/Icons/Eye';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import InfoBlock, { Props as InfoBlockProps } from '@wix/da-ds/pkg/InfoBlock';
import PreviewModeBar from '@wix/da-ds/pkg/PreviewModeBar';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import CoreExclusiveFeature from '@wix/da-shared-react/pkg/indicators/CoreExclusiveFeature/CoreExclusiveFeature';

import s from './WidgetPaywall.scss';

export interface Props extends InfoBlockProps {
  className?: string;
  previewReferral?: ReferralType;
  lockedReferral?: ReferralType;
  children: React.ReactNode;
}

enum WidgetPaywallView {
  LOCKED = 'locked',
  PREVIEW = 'preview',
}

export const WidgetPaywall: React.FC<Props> = ({
  className,
  children,
  previewReferral = ReferralType.WIDGET_PAYWALL_PREVIEW,
  lockedReferral = ReferralType.WIDGET_PAYWALL_OVERLAY,
  ...restProps
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const [activeView, setActiveView] = useState(WidgetPaywallView.LOCKED);
  const showPreview = useCallback(
    () => setActiveView(WidgetPaywallView.PREVIEW),
    []
  );
  const showLocked = useCallback(
    () => setActiveView(WidgetPaywallView.LOCKED),
    []
  );
  return (
    <ErrorBoundary debugComponent="WidgetPaywall">
      <div
        className={c(
          s['root'],
          activeView === WidgetPaywallView.PREVIEW && s['root-preview'],
          className
        )}
      >
        {activeView === WidgetPaywallView.LOCKED && (
          <div className={s['overlay']}>
            <InfoBlock
              className={s['locked']}
              backgroundType="none"
              title={t('paywall.widgetPreview.title')}
              subtitle={t('paywall.widgetPreview.subtitle')}
              buttonAlignment="vertical"
              illustration={<CoreExclusiveFeature withoutBuyCTA />}
              customButton={
                <BuyCoreBrandedButton
                  size="large"
                  biData={
                    {
                      referral_info: lockedReferral,
                    } as any
                  }
                  withSaleSparkles
                >
                  {t('paywall.widgetPreview.button.upgrade')}
                </BuyCoreBrandedButton>
              }
              customSecondaryButton={
                <GhostButton
                  size="medium"
                  variant="main"
                  icon={EyeIcon}
                  onClick={showPreview}
                  biData={BiData<CustomBoxPreviewBiEvent>({
                    evid: 268,
                    widgetname: BiContextProvidedValue,
                  })}
                >
                  {t('paywall.widgetPreview.button.preview')}
                </GhostButton>
              }
              {...restProps}
            />
          </div>
        )}
        {activeView === WidgetPaywallView.PREVIEW && (
          <div className={c(s['overlay'], s['preview-overlay'])}>
            <div className={s['preview']}>
              <PreviewModeBar
                onClose={showLocked}
                messageBeforeIcon={t(
                  isMobile
                    ? 'paywall.widgetPreview.snackbar.pushThisLive.mobile'
                    : 'paywall.widgetPreview.snackbar.pushThisLive'
                )}
                icon={<CoreIcon size={IconSize.SMALL} disableDefaultColors />}
                ctaHref={URLS.CORE_MEMBERSHIP}
                ctaBiData={BiData<BuyCoreButtonBiEvent>({
                  evid: 375,
                  referral_info: previewReferral,
                })}
                ctaLabel={t('paywall.widgetPreview.snackbar.upgradeToPublish')}
              />
            </div>
          </div>
        )}
        {children}
      </div>
    </ErrorBoundary>
  );
};
WidgetPaywall.displayName = 'WidgetPaywall';

export default WidgetPaywall;
