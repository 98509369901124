import { connect, MapStateToProps } from 'react-redux';
import { Props } from './FolderPaywall';
import { isCurrentUserProfileOwner } from '../../../../selectors/users';
import { AppState } from '../../../../../types/store';

type StateProps = Pick<Props, 'isOwner'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> =
  state => ({
    isOwner: isCurrentUserProfileOwner(state),
  });

export default connect(mapStateToProps);
