import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../types/store';
import {
  getProfileOwnerUser,
  getProfileOwnerMonetizationPromo,
} from '../../../selectors/users';
import { Props } from './MonetizationPromoBanner';

type StateProps = Pick<Props, 'monetizationPromo' | 'profileOwnerUser'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const monetizationPromo = getProfileOwnerMonetizationPromo(state);
  const profileOwnerUser = getProfileOwnerUser(state);
  return {
    monetizationPromo,
    profileOwnerUser,
  };
};

export default connect(mapStateToProps);
