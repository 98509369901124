import React from 'react';
import { PapiUser } from '@wix/da-papi-types';
import JoinGroupButton from '@wix/da-shared-react/pkg/JoinGroupButton';

export interface Props {
  profileOwnerUser: PapiUser;
}

const GroupMemberButton: React.FC<Props> = ({ profileOwnerUser }) => {
  return <JoinGroupButton type="solid" size="large" group={profileOwnerUser} />;
};
GroupMemberButton.displayName = 'GroupMemberButton';

export default GroupMemberButton;
