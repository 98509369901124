import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { getProfileOwnerUser } from '../../../selectors/users';
import {
  getCanManageRoles,
  getCanRespondToNotifications,
} from '../../../selectors/privs';
import { AppState } from '../../../../types/store';
import { Props } from './BackroomSection';

type StateProps = Pick<
  Props,
  'profileOwnerUser' | 'canManageRoles' | 'canRespondToGroupNotifications'
>;
type DispatchProps = Pick<Props, never>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  profileOwnerUser: getProfileOwnerUser(state),
  canManageRoles: getCanManageRoles(state),
  canRespondToGroupNotifications: getCanRespondToNotifications(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
