import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import { default as SharedFeedThumb } from '@wix/da-shared-react/pkg/DeviationViews/Feed';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import PinIcon from '@wix/da-ds/pkg/Icons/24x24/Pin';
import { getDeviationResourceType } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import { CommentSuggestionsType } from '@wix/da-shared-react/pkg/CommentBox/presets/CommentBoxWithSuggestions';
import { BiData, WatchMenuItemSelectionBiEvent } from '@wix/da-bi/pkg/events';
import s from './FeedThumb.scss';

export interface Props {
  className?: string;
  deviation: PapiDeviation;
  disablePinning?: boolean;

  // Privileges
  canPin: boolean;
  canEdit: boolean;

  // Actinos
  editItem: () => void;
  pinItem: () => void;
  unpinItem: () => void;

  // SharedFeedThumbProps
  withComments?: boolean;
  withWatchOrSubscribeButton?: boolean;
  withCommentSuggestions?: CommentSuggestionsType;
  extraActions?: NullableDropdownItem[];
}

const FeedThumb: React.FC<Props> = ({
  deviation,
  deviation: { isPinned, isPublished },
  className,
  canPin,
  canEdit,
  editItem,
  pinItem,
  unpinItem,
  withComments,
  withWatchOrSubscribeButton,
  withCommentSuggestions,
  extraActions = [],
}) => {
  const { t } = useTranslation();
  // TODO: consolidate with:
  // - Sections/PostsSection__profile_posts_feed/_partials/PostsFeed/PostsFeed.tsx
  // - Sections/PostsSection/Journals/JournalsList/JournalFeedThumb/JournalFeedThumb.tsx
  const pinAndEditActions: NullableDropdownItem[] = useMemo(
    () => [
      canPin &&
        isPublished && {
          label: isPinned ? t('widgets.feed.unpin') : t('widgets.feed.pin'),
          onClick: isPinned ? unpinItem : pinItem,
          biData: BiData<WatchMenuItemSelectionBiEvent>({
            evid: 424,
            typeid: getDeviationResourceType(deviation),
            itemid: deviation.deviationId,
            menu_item: isPinned ? 'unpin_to_top' : 'pin_to_top',
          }),
        },
      canEdit && {
        label: t('widgets.feed.edit'),
        onClick: editItem,
        biData: BiData<WatchMenuItemSelectionBiEvent>({
          evid: 424,
          typeid: getDeviationResourceType(deviation),
          itemid: deviation.deviationId,
          menu_item: 'edit',
        }),
      },
    ],
    [
      t,
      canPin,
      isPinned,
      isPublished,
      deviation,
      pinItem,
      unpinItem,
      canEdit,
      editItem,
    ]
  );

  return (
    <div className={classnames(s['root'], className)}>
      {isPinned && <PinIcon withPadding className={s['pinned-icon']} />}
      <SharedFeedThumb
        deviation={deviation}
        withComments={withComments}
        withWatchOrSubscribeButton={withWatchOrSubscribeButton}
        withCommentSuggestions={withCommentSuggestions}
        extraActions={[...pinAndEditActions, ...extraActions]}
      />
    </div>
  );
};
FeedThumb.displayName = 'FeedThumb';

export default FeedThumb;
