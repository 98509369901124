import React, { lazy, Suspense, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useThrottle } from '@wix/da-hooks/pkg/useThrottle';
import { useResizeObserver } from '@wix/da-hooks/pkg/useResizeObserver';
import ChartPanelSkeleton from '../../layout/ChartPanelSkeleton';
import { formatMonth } from '../../../../utils';
import { theme, margin } from '../../constants';
import {
  SubscriptionsChartPointType,
  SubscriptionsStatType,
} from '../../../../../../../../../../types/subscriptionsSection';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';

const MAX_VISIBLE_DATAPOINTS = 12;
const MAX_VISIBLE_DATAPOINTS_MOBILE = 6;
const RESIZE_THROTTLE_DELAY = 300;
const RESIZE_THROTTLE_OPTIONS = { trailing: true };
const WIDTH_FOR_ROTATED_TICKS = 360;

const ResponsiveLine = lazy(() =>
  import(/* webpackChunkName: "stats-light" */ '@nivo/line').then(m => ({
    default: m.ResponsiveLine,
  }))
);

interface Props {
  className?: string;
  chart: SubscriptionsChartPointType[];
  stats?: SubscriptionsStatType;
}

const SubscribersChartPanel: React.FC<Props> = ({
  className,
  chart,
  stats,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isSSR = typeof window === 'undefined';
  const visibleDataPoints = chart.slice(
    isMobile ? -MAX_VISIBLE_DATAPOINTS_MOBILE : -MAX_VISIBLE_DATAPOINTS
  );

  const rootRef = useRef<HTMLDivElement>(null);
  const [rootWidth, setRootWidth] = useState(0);
  const handleResize = useThrottle(
    () => {
      if (isMobile) {
        return;
      }
      const newWidth = rootRef.current!.clientWidth;
      if (newWidth !== rootWidth) {
        setRootWidth(newWidth);
      }
    },
    [rootWidth, isMobile],
    RESIZE_THROTTLE_DELAY,
    RESIZE_THROTTLE_OPTIONS
  );
  useResizeObserver(rootRef, handleResize);

  const newData = [
    {
      id: 'subscribers',
      data: visibleDataPoints.map(({ startDate, subscribers }) => ({
        x: formatMonth(startDate),
        y: subscribers,
      })),
    },
  ];

  return (
    <ChartPanelSkeleton
      className={className}
      rootRef={rootRef}
      totalTitle={t('widgets.subscriptionsSection.center.total.subscribers')}
      total={stats ? `${stats.activeSubscribers}` : ''}
      manageTitle={t('widgets.subscriptionsSection.center.button.subscribers')}
      manageHref={URLS.CUSTOMER_DASHBOARD}
    >
      {!isSSR && (
        <ErrorBoundary debugComponent="SubscribersChartPanel">
          <Suspense fallback={<LoadingIndicator view="llama" />}>
            <ResponsiveLine
              data={newData}
              margin={margin}
              axisBottom={{
                tickRotation:
                  !isMobile && rootWidth <= WIDTH_FOR_ROTATED_TICKS ? 45 : 0,
              }}
              axisLeft={{ tickValues: 4 }}
              enableGridX={false}
              gridYValues={4}
              xScale={{ type: 'point' }}
              yScale={{
                type: 'linear',
                min: 0,
              }}
              curve={`monotoneX`}
              colors={['#7579FF']}
              theme={theme}
              enableArea
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </ChartPanelSkeleton>
  );
};
SubscribersChartPanel.displayName = 'SubscribersChartPanel';

export default SubscribersChartPanel;
