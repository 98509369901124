import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import EmptyState from '../../../../EmptyState';
import { useVerification } from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { VerificationRequiredActions } from '@wix/da-shared-react/pkg/publicSession/types';
import noop from '@wix/da-shared-react/pkg/utils/noop';
import { PostsType } from '../../../../../../types/posts';
import { CTAModalsMap } from './constants';

import s from './EmptyState.scss';
import { AddNewContentClickBiEvent, BiData } from '@wix/da-bi/pkg/events';

export interface Props {
  postType: PostsType | 'featuredJournal' | 'featuredPosts';
  view?: 'large' | 'small' | 'wide';
  isOwner: boolean;
  profileOwner: PapiUser;
  className?: string;
  openSubmitPostPopup?: (modalProps?: any) => void;
}

function getVerificationPopupType(
  postType: Props['postType']
): VerificationRequiredActions {
  switch (postType) {
    case 'journals':
    case 'drafts':
      return 'submitjournal';
    case 'polls':
      return 'submitpoll';
    case 'statuses':
      return 'submitstatus';
    default:
      return 'submit';
  }
}

const PostsEmptyState: React.FC<Props> = ({
  view,
  isOwner,
  profileOwner,
  postType,
  className,
  openSubmitPostPopup = noop,
}) => {
  const { t } = useTranslation();
  const { isVerificationNeeded, pushVerificationPopup } = useVerification();
  const logBiEvent = useBiLogger();

  const modalType = CTAModalsMap[postType];

  const handleButtonClick = useCallback(() => {
    if (!modalType) {
      return null;
    }

    if (isVerificationNeeded) {
      pushVerificationPopup(getVerificationPopupType(postType));
    } else {
      openSubmitPostPopup({
        groupDestination: profileOwner.isGroup && profileOwner,
      });
      logBiEvent(
        BiData<AddNewContentClickBiEvent>({
          evid: 284,
          widgetname: `${postType}_emptystate`,
        })
      );
    }
  }, [
    isVerificationNeeded,
    logBiEvent,
    modalType,
    openSubmitPostPopup,
    postType,
    pushVerificationPopup,
    profileOwner,
  ]);

  return (
    <EmptyState
      view={view}
      className={classnames(
        s['root'],
        view === 'large' && s['large'],
        className
      )}
      title={
        isOwner
          ? t(`pages.posts.emptyState.${postType}.title.owner`)
          : t(`pages.posts.emptyState.${postType}.title.visitor`, {
              username: profileOwner.username,
            })
      }
      subtitle={
        isOwner
          ? t(`pages.posts.emptyState.${postType}.subtitle.owner`)
          : t(`pages.posts.emptyState.${postType}.subtitle.visitor`, {
              username: profileOwner.username,
            })
      }
      buttonLabel={
        modalType && t(`pages.posts.emptyState.${postType}.createCTA`)
      }
      onButtonClick={handleButtonClick}
      withButton={isOwner && modalType}
    />
  );
};
PostsEmptyState.displayName = 'PostsEmptyState';

export default PostsEmptyState;
