import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import ProfileCommentsBox from './ProfileCommentsBox';
import profileCommentsInit from './redux/profileCommentsInit';

export const getProfileCommentsWidgetConfig = ({
  t,
  dispatch,
  isGroup,
}): WidgetConfig => ({
  viewComponent: ProfileCommentsBox,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-9-profile-comments.svg',
    title: t(
      isGroup
        ? 'widgets.profileComments.group.addButtonHeader'
        : 'widgets.profileComments.addButtonHeader'
    ),
    description: t(
      isGroup
        ? 'widgets.profileComments.group.description'
        : 'widgets.profileComments.description'
    ),
  },
  initActionsCreator: profileCommentsInit,
});
