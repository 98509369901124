import { connect, MapDispatchToProps } from 'react-redux';
import { editDeviation } from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';
import { unfeaturePost } from '../../../../../../actions/feed';
import { Props } from './FeaturedListItem';

type StateProps = Pick<Props, never>;
type DispatchProps = Pick<Props, 'editItem' | 'unfeatureItem'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps = undefined;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { item }
) => ({
  editItem: () => dispatch(editDeviation({ deviation: item })),
  unfeatureItem: () => dispatch(unfeaturePost({ item })),
});

export default connect(mapStateToProps, mapDispatchToProps);
