import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Flex from '@wix/da-ds/pkg/Flex';
import StickyBar from '../../StickyBar';
import SearchField from '../../SearchField';

import s from './SearchResultsBar.scss';

export interface Props {
  searchQuery: string;
  isSearching: boolean;
  resultsTotal: number;
}

export const SearchResultsBar: React.FC<Props> = ({
  searchQuery,
  resultsTotal,
  isSearching,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const renderSearchingState = () => (
    <span className={s['primary-text']}>
      {t('pages.gallections.search.searchingFor', {
        postProcess: ['reactPostprocessor', 'featureBrancher'],
        query: <strong>{searchQuery}</strong>,
        all: <strong>{t('pages.gallections.search.searchingFor.all')}</strong>,
      })}
    </span>
  );

  const renderShowingResultsState = isStickyBarStuck => (
    <Flex fullWidth className={s['root']}>
      <Flex fullWidth alignItems="center">
        <span className={s['primary-text']}>
          {t('pages.gallections.search.showingSearchResultForIn', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            query: <strong>{searchQuery}</strong>,
            all: (
              <strong>{t('pages.gallections.search.searchingFor.all')}</strong>
            ),
          })}
        </span>
        {resultsTotal > 0 && (
          <span className={s['secondary-text']}>
            {t('pages.gallections.search.resultsCount', {
              count: resultsTotal,
            })}
          </span>
        )}
      </Flex>
      <Flex fullWidth justifyContent={isMobile ? 'center' : 'flex-end'}>
        {(isStickyBarStuck || isMobile) && (
          <SearchField className={s['search-field']} />
        )}
      </Flex>
    </Flex>
  );

  const renderBarContent = isStickyBarStuck =>
    isSearching
      ? renderSearchingState()
      : renderShowingResultsState(isStickyBarStuck);

  return <StickyBar>{renderBarContent}</StickyBar>;
};
SearchResultsBar.displayName = 'SearchResultsBar';

export default SearchResultsBar;
