import React from 'react';
import classnames from 'classnames';
import { PapiUser } from '@wix/da-papi-types';
import ElementQueries from '@wix/da-shared-react/pkg/ElementQueries';
import MiniProfileIdentity from '../../UserWidgets/_partials/MiniProfileIdentity';

import s from './UserGrid.scss';

export interface Props {
  users: PapiUser[];
  onUserClick?: (user: PapiUser) => void;
  className?: string;
  itemClassName?: string;
}

export const UserGrid: React.FC<Props> = ({
  users,
  onUserClick,
  className,
  itemClassName,
}) => {
  return (
    <ElementQueries
      widthBreakpoints={[640, 800]}
      className={classnames(s['grid'], className)}
    >
      {users.map(user => (
        <MiniProfileIdentity
          key={user.username}
          className={classnames(s['item'], itemClassName)}
          user={user}
          onClick={onUserClick}
          withTooltip
        />
      ))}
    </ElementQueries>
  );
};

export default UserGrid;
