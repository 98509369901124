import React from 'react';
import classnames from 'classnames';
import Sticky from '@wix/da-ds/pkg/Sticky';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';

import cssVars from '@wix/da-ds/src/styles/variables.scss';
import profilesCssVars from '../../../../styles/variables.scss';
import s from './WidgetHeaderMobile.scss';

export interface Props {
  header: string | React.ReactNode;
  count?: number | string;
  extras?: React.ReactNode | React.ReactNode[];
  extrasWhenSticky?: React.ReactNode | React.ReactNode[];
  isSticky?: boolean;
  className?: string;
  rootClassName?: string;
  isWide?: boolean;
}

const WidgetHeaderMobile: React.FC<Props> = ({
  className,
  isSticky,
  header,
  count,
  extras,
  extrasWhenSticky,
  rootClassName,
  isWide = false,
}) => {
  // T42244: The sticky headers get stuck a pixel too low sometimes
  const marginTop =
    parseInt(cssVars['site-header-height-mobile']) +
    parseInt(profilesCssVars['mobile-navbar-height']) -
    1;

  const shouldRenderAsHeading = !!header;
  const Tag = shouldRenderAsHeading ? Heading : 'div';
  const renderInner = (isStuck = false) => (
    <div
      className={classnames(
        s['root'],
        isStuck && s['is-stuck'],
        isWide && s['is-wide'],
        rootClassName
      )}
    >
      <Tag className={s['header']}>
        {header}
        {!!count && <span className={s['count']}>{shortenNumber(count)}</span>}
      </Tag>
      {extras}
      {isStuck && extrasWhenSticky}
    </div>
  );

  return (
    <div className={classnames(s['wrapper'], className)}>
      {isSticky ? (
        <Sticky className={'sticky'} marginTop={marginTop}>
          {isStuck => renderInner(isStuck)}
        </Sticky>
      ) : (
        renderInner()
      )}
    </div>
  );
};
WidgetHeaderMobile.displayName = 'WidgetHeaderMobile';

export default WidgetHeaderMobile;
