import { all, select, call, put } from 'redux-saga/effects';
import { PapiRequestDaSharedGallectionSearch } from '@wix/da-papi-types';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { requestPuppy } from '@wix/da-http-client';
import { getSearchQuery } from '../../selectors/gallectionSection';
import { getIsPagedMode, getProfileOwner } from '../../selectors/users';

import { GallectionSortOrder } from '../../../types/gallection';

function* fetchFromApi(params) {
  return yield call(requestPuppy, {
    method: 'get',
    url: `/gallection/search`,
    params,
  });
}

export default function* fetchMoreGallectionSearchResults(
  action,
  gallectionType
) {
  const { streamId } = action;

  const [offset, limit, searchQuery, profileOwner, sortOrder, isPaged] =
    yield all([
      select(state => withOffset.selectors.getNextOffset(state, streamId)),
      select(state => streamSelectors.getItemsPerFetch(state, streamId)),
      select(getSearchQuery),
      select(getProfileOwner),
      select(state =>
        streamSelectors.getStreamParam(state, streamId, 'sortOrder')
      ),
      select(getIsPagedMode),
    ]);

  const queryParams: PapiRequestDaSharedGallectionSearch = {
    username: profileOwner.user.username,
    type: gallectionType ?? 'gallery',
    order: sortOrder === GallectionSortOrder.NEWEST ? 'most-recent' : 'popular',
    q: searchQuery,
    init: isPaged || offset === 0, // TODO rename init to something else
    offset,
    limit,
  };

  const response = yield call(fetchFromApi, queryParams);

  if (response) {
    const { hasMore, nextOffset, estTotal, results: deviations } = response;
    const { result, entities } = normalize(deviations || [], [deviationSchema]);

    yield put(
      withOffset.actions.fetchSuccess({
        streamId,
        hasMore,
        nextOffset,
        items: result,
        entities,
        total: estTotal,
      })
    );
  }

  // TODO error catching
}
