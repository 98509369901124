import {
  getCustomInitActionsForWidgets,
  getPageInfoInitActions,
} from './common';
import { getHomeWidgetConfigs } from '../../components/Sections/HomeSection/homeWidgetConfigs';

export const getCustomInitActionsForHomeWidgets = (
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getHomeWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  ...getPageInfoInitActions(responseData, requestParams),
];
