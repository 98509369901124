import React from 'react';
import { useSelector } from 'react-redux';
import BaseSubNavBarDesktop, {
  Props as BaseProps,
} from '../../BaseSubNavBarDesktopInner';
import { getCanCustomizePage } from '../../../../../selectors/privs';
import LargeButtonsWrapper from '../../BaseSubNavBarDesktopInner/legos/LargeButtonsWrapper';
import RightSideButtonWrapper from '../../BaseSubNavBarDesktopInner/legos/RightSideButtonWrapper';
import ProfileSkinsButton from '../_partials/ProfileSkinsButton';
import GroupMemberButton from './_partials/GroupMemberButton';
import GroupMoreMenu from './_partials/GroupMoreMenu';
import SubmitButton from './_partials/SubmitButton';

export interface Props extends BaseProps {}

const GroupProfileSubNavDesktopInner: React.FC<Props> = ({ isSticky }) => {
  const canCustomize = useSelector(getCanCustomizePage);
  return (
    <BaseSubNavBarDesktop
      isSticky={isSticky}
      rightSideContent={
        <>
          {canCustomize && (
            <RightSideButtonWrapper>
              <ProfileSkinsButton iconOnly />
            </RightSideButtonWrapper>
          )}
          <RightSideButtonWrapper>
            <GroupMoreMenu />
          </RightSideButtonWrapper>
          <LargeButtonsWrapper>
            <RightSideButtonWrapper>
              <GroupMemberButton />
            </RightSideButtonWrapper>
            <RightSideButtonWrapper>
              <SubmitButton />
            </RightSideButtonWrapper>
          </LargeButtonsWrapper>
        </>
      }
    />
  );
};
GroupProfileSubNavDesktopInner.displayName = 'GroupProfileSubNavDesktopInner';

export default GroupProfileSubNavDesktopInner;
