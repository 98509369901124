import React from 'react';
import ArrowDown from '@wix/da-ds/pkg/Icons/24x24/ArrowDown';
import ArrowUp from '@wix/da-ds/pkg/Icons/24x24/ArrowUp';
import TextButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/TextButtonWithContextMenu';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { MessageLogModuleData } from '../../types';
import { useMessageLogParams } from '../hooks';
import { useTranslation } from 'react-i18next';
import {
  DropdownOption,
  NullableDropdownItem,
} from '@wix/da-ds/pkg/Dropdown/types';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { filterGroupMessageLogByNotificationType } from '../../../../../actions/backroomSection';

interface Props {
  moduleId: number;
}

function useNotificationTypeOptions(
  moduleId: number
): [NullableDropdownItem[], DropdownOption] {
  const { t } = useTranslation();
  const dispatchFilterAction = useAction(
    filterGroupMessageLogByNotificationType
  );
  const {
    moduleData: { instigatorModuleTypes },
  } = useModuleData<MessageLogModuleData>(moduleId);
  const { instigatorModuleType } = useMessageLogParams();

  const defaultNotificationTypeFilter: DropdownOption = {
    label: t(
      'widgets.group_messagelog.filterBar.notificationTypeDropdown.defaultOptionLabel.any'
    ),
    value: undefined,
    selected: instigatorModuleType === undefined,
    onClick: () => dispatchFilterAction(undefined),
  };

  const notificationTypeOptions: DropdownOption[] = [
    defaultNotificationTypeFilter,
    ...instigatorModuleTypes.map(notificationType => ({
      label: notificationType.label,
      value: notificationType.typeId,
      selected: notificationType.typeId === instigatorModuleType,
      onClick: () => dispatchFilterAction(notificationType.typeId),
    })),
  ];

  return [
    notificationTypeOptions,
    notificationTypeOptions.find(option => option.selected)!,
  ];
}

const NotificationTypeDropdown: React.FC<Props> = ({ moduleId }) => {
  const [notificationTypeOptions, selectedOption] =
    useNotificationTypeOptions(moduleId);

  return (
    <TextButtonWithContextMenu
      variant="main"
      size="medium"
      postfix={{
        opened: ArrowUp,
        closed: ArrowDown,
      }}
      items={notificationTypeOptions}
    >
      {selectedOption.label}
    </TextButtonWithContextMenu>
  );
};

NotificationTypeDropdown.displayName = 'NotificationTypeDropdown';

export default NotificationTypeDropdown;
