export interface GroupsBackroomState {
  folder?: GroupNotesFolderType;
  selectedNoteId?: number;
  search?: string;
  offset?: number;
}

export enum GroupNotesFolderType {
  ARCHIVED = 'archived',
  STARRED = 'starred',
  INBOX = 'inbox',
}
