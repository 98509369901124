import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../../types/store';
import {
  getEmptySections,
  hasSingularContentType,
  isEmpty,
} from '../../../../utils/emptyState';
import { isCurrentUserProfileOwner } from '../../../../selectors/users';
import { Props } from './ShopAll';

type StateProps = Pick<
  Props,
  'isEmpty' | 'isOwner' | 'hasSingularContentType' | 'emptySections'
>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    isEmpty: isEmpty(state),
    hasSingularContentType: hasSingularContentType(state),
    emptySections: getEmptySections(state),
    isOwner: isCurrentUserProfileOwner(state),
  };
};

export default connect(mapStateToProps);
