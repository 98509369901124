import { fetchPagePrivilegesSuccess } from '../actions/pagePrivileges';

import { handleActions, Action } from 'redux-actions';
import { AppState } from '../../types/store';
import { PagePrivileges } from '../../types/pagePrivileges';

export default handleActions<AppState['pagePrivileges'], any>(
  {
    [fetchPagePrivilegesSuccess.toString()]: (
      state: PagePrivileges,
      action: Action<PagePrivileges>
    ) => {
      return {
        ...state,
        ...action.payload,
      } as PagePrivileges;
    },
  },
  {}
);
