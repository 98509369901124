import React, { Suspense, lazy } from 'react';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const BadgesSpotlightConfigModal = lazy(
  () => import(/* webpackChunkName: "owner" */ '../BadgesSpotlightConfigModal')
);

type Props = any;

const BadgesSpotlightConfigModalLazy: React.FC<Props> = props => {
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      <BadgesSpotlightConfigModal {...props} />
    </Suspense>
  );
};
BadgesSpotlightConfigModalLazy.displayName = 'BadgesSpotlightConfigModalLazy';

export default BadgesSpotlightConfigModalLazy;
