import { URLS } from '@wix/da-url';

export type LlamaType =
  | 'llama'
  | 'super'
  | 'albino'
  | 'super_albino'
  | 'ninja'
  | 'fancy'
  | 'king'
  | 'spartan'
  | 'wizard'
  | 'golden'
  | 'runaway';

export interface LlamaInfo {
  threshold: LlamaType;
  imgSrc?: string;
}
export interface LlamaInfoWithPosition extends LlamaInfo {
  top: number;
  left: number;
}

export interface FormValues {
  backgroundIndex: number;
  llamas: LlamaInfoWithPosition[];
  unlockedLlamas: LlamaInfo[];
  hasMoreThresholdsToUnlock: boolean;
}

export interface FormState extends FormValues {
  isDragging?: boolean;
  discardedLlamas?: LlamaInfo[];
  dragObject?: LlamaInfo;
}

export const DefaultLlama = {
  threshold: 'llama',
  top: 0.8,
  left: 0.5,
  imgSrc: `${URLS.CDN}/badges/llama.gif`,
} as LlamaInfoWithPosition;

export const MysteryLlama = {
  threshold: '???' as LlamaType,
  top: 0,
  left: 0,
  imgSrc: `${URLS.CDN}/badges/llama_mystery.gif`,
} as LlamaInfoWithPosition;
