import { connect, MapDispatchToProps } from 'react-redux';
import { showMobileSharePopup } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { Props } from './FolderPaywallLayout';

export type StateProps = Pick<Props, never>;
export type DispatchProps = Pick<Props, 'onClick'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  onClick: showMobileSharePopup,
};

export default connect(null, mapDispatchToProps);
