import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { starNotes, unstarNotes } from '../../../../../actions/backroomSection';
import { Props } from './GroupNotesListItemActions';

type StateProps = Pick<Props, never>;
type DispatchProps = Pick<Props, 'starNotes' | 'unstarNotes'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  any
> = state => ({});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  starNotes,
  unstarNotes,
};

export default connect(mapStateToProps, mapDispatchToProps);
