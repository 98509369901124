import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Button from '@wix/da-shared-react/pkg/Button';

export interface Props {
  openModal: () => void;
  className?: string;
}

export const CommissionCreateButton: React.FC<Props & WithTranslation> = ({
  openModal,
  className,
  t,
}) => (
  <Button
    view="b16-undisciplined-designers"
    withPaddings={false}
    onClick={openModal}
    className={className}
  >
    {t('common.addNew')}
  </Button>
);
CommissionCreateButton.displayName = 'CommissionCreateButton';

export default withTranslation()(CommissionCreateButton);
