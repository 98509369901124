import { useCallback, useEffect } from 'react';
import { useEffectOnceOnMount } from '@wix/da-hooks/pkg/useEffectOnceOnMount';
import useModuleConfig from '@wix/da-gruser-shared/pkg/hooks/useModuleConfig';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { putEntities } from '@wix/da-shared-react/pkg/redux/entities/actions';
import {
  recruitmentModuleInitialized,
  recruitmentModuleSaved,
} from '../../../../actions/modules';

export function useGroupChangeFounder(moduleId: number) {
  const onInitialize = useAction(recruitmentModuleInitialized);
  const onSaved = useAction(recruitmentModuleSaved);
  const updateEntities = useAction(putEntities);

  const {
    isSaving,
    moduleConfig,
    fetchModuleConfig,
    saveResponse,
    saveModuleConfig,
    hasJustFinishedSaving,
    hasJustFinishedFetching,
    error,
  } = useModuleConfig(moduleId, '/recruitment/founder');

  const { config, options } = moduleConfig || {};

  const requestSave = useCallback(
    ({ user }) => {
      if (user) {
        saveModuleConfig({ founder_username: user.username });
      }
    },
    [saveModuleConfig]
  );

  // fetch config
  useEffectOnceOnMount(() => {
    fetchModuleConfig();
  });

  // after successful config fetch, fire onInitialize
  useEffect(() => {
    if (hasJustFinishedFetching) {
      onInitialize();
    }
  }, [onInitialize, hasJustFinishedFetching]);

  // after successful save, update entities, and fire onSaved
  useEffect(() => {
    if (!error && hasJustFinishedSaving) {
      if (saveResponse.thisGroup) {
        // update the group privs and meta in the store
        const { entities } = normalize(saveResponse.thisGroup, userSchema);
        updateEntities({ entities });
      }
      // announce that we finished changing the founder
      onSaved();
    }
  }, [onSaved, updateEntities, hasJustFinishedSaving, error, saveResponse]);

  return {
    config,
    options,
    isSaving,
    requestSave,
  };
}
export default useGroupChangeFounder;
