import { connect, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../../types/store';
import { getGallectionSectionType } from '../../../../selectors/gallectionSection';
import { Props } from './AddNewFolderCard';
import { getProfileOwnerUser } from '../../../../selectors/users';

export type StateProps = Pick<Props, 'gallectionType' | 'owner'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  gallectionType: getGallectionSectionType(state),
  owner: getProfileOwnerUser(state),
});

export default connect(mapStateToProps);
