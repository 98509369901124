import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './NavRoutes';
import { getPageRoutes } from '../../../../../../selectors/pageLayout';
import { AppState } from '../../../../../../../types/store';
import { biClickProfileNavBar } from '../../../../../../actions/bi';

export type StateProps = Pick<Props, 'routes'>;
export type DispatchProps = Pick<Props, 'biClickProfileNavBar'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  routes: getPageRoutes(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  biClickProfileNavBar,
};

export default connect(mapStateToProps, mapDispatchToProps);
