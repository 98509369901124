import { PapiUser } from '@wix/da-papi-types';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';
import { URLS, Url } from '@wix/da-url';

export const getUserPath = (username: string, path: string): string => {
  return `/${username}/${path}`;
};

export const getSubmitUrlForProfileOwner = (profileOwner: PapiUser): string => {
  return isGroup(profileOwner)
    ? Url.submissionLinkWithGroupId(profileOwner.userId)
    : Url.to(URLS.STUDIO);
};
