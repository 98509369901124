import { all, select, call, put } from 'redux-saga/effects';
import pick from 'lodash/pick';
import {
  withOffset,
  selectors as streamSelectors,
} from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { galleryFolderSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/folder';
import { isApiError } from '@wix/da-http-client';
import { ShopItemType } from '../../../types/shop';
import { PapiCommissionsRequestParams } from '../../../types/papi';
import { getRequestParams } from '../../selectors/pageInfo';
import { getProfileOwner } from '../../selectors/users';
import { PARAMS_PASSED_FROM_FRONT_TO_BACK } from '../../store/initActions/shop';
import { requestPuppyGruser } from '../gruserHelpers';

function* fetchFromApi(shopItemType, params) {
  return yield call(
    requestPuppyGruser,
    {
      method: 'get',
      url: `/module/${shopItemType}`,
      params,
    },
    undefined,
    'gruser'
  );
}

export default function* fetchMoreShopItems(action) {
  const { streamId } = action;
  const [requestParams, offset, limit, streamParams, profileOwner] = yield all([
    select(getRequestParams),
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state => streamSelectors.getItemsPerFetch(state, streamId)),
    select(state => streamSelectors.getStreamParamsSafe(state, streamId)),
    select(getProfileOwner),
  ]);

  const previousShopParams = pick(
    requestParams,
    PARAMS_PASSED_FROM_FRONT_TO_BACK
  );

  const newParams: PapiCommissionsRequestParams = {
    ...previousShopParams,
    username: profileOwner.user && profileOwner.user.username,
    moduleid: streamParams.moduleId,
    offset,
    limit,
  };

  const shopItemType: ShopItemType = streamParams.shopItemType;

  const response = yield call(fetchFromApi, shopItemType, newParams);

  if (response && !isApiError(response)) {
    const { hasMore, total, nextOffset, results } = response;

    const schema =
      streamParams.shopItemType === ShopItemType.PREMIUM_FOLDERS
        ? galleryFolderSchema
        : deviationSchema;
    const { result, entities } = normalize(results, [schema]);

    yield put(
      withOffset.actions.fetchSuccess({
        streamId,
        hasMore,
        nextOffset,
        items: result,
        total,
        entities,
      })
    );
  }
  // TODO error catching
}
