import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import CustomBox from './CustomBox';
import CustomConfigPopoverLazy from './CustomConfigPopover/CustomConfigPopoverLazy';

export const getCustomWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: CustomBox,
  configPopoverComponent: CustomConfigPopoverLazy,
  autoShowWidgetConfig: true,
  confirmRemove: () =>
    defaultRemoveConfirmation({ t, dispatch, willDestroyContent: true }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-4-custom.svg?2',
    title: t('widgets.custombox.addButtonHeader'),
    description: t('widgets.custombox.description'),
    isDecoratedWithStars: true,
  },
});
