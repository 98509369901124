import React, { useCallback, useEffect, useMemo, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Url, USER_PROFILE_BACKROOM_SECTIONS } from '@wix/da-url';
import { PapiUser, PapiGroupnote } from '@wix/da-papi-types';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useEmptyChatImage } from '@wix/da-shared-react/pkg/Chat/ChatWindow/hooks/useEmptyChatImage';

import EmptyState from '../../EmptyState';

import GroupNotesList from './_partials/GroupNotesList';
import GroupNotesThread from './_partials/GroupNotesThread';
import GroupNotesToolbar from './_partials/GroupNotesToolbar';
import GroupNotesSidebar from './_partials/GroupNotesSidebar';
import GroupNotesPagination from './_partials/GroupNotesPagination';

import { useGroupNotes } from './useGroupNotes';

import s from './GroupNotes.scss';

export interface Props {
  profileOwnerUser: PapiUser;

  moduleId: number;
  results: PapiGroupnote[];
  hasMore?: boolean;
}

export const GroupNotes: React.FC<Props> = ({
  moduleId,
  profileOwnerUser,
  results,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const illustrationSrc = useEmptyChatImage();
  const {
    isLoading,
    hasMore,
    hasLess,
    fetchNext,
    fetchPrevious,

    currentNoteId,
    setCurrentNoteId,

    selectedNoteIds,
    setSelectedNoteIds,
    selectNoteId,

    search,
    setSearch,

    folder,
    changeFolder,
  } = useGroupNotes(moduleId);

  const allNoteIds = useMemo(
    () => results.map(({ noteId }) => noteId),
    [results]
  );
  useEffect(() => {
    if (allNoteIds.length === 0) {
      setCurrentNoteId(undefined);
    }
  }, [allNoteIds, setCurrentNoteId]);

  const username = profileOwnerUser?.username;
  const handleItemClick = useCallback(
    noteId => {
      setCurrentNoteId(noteId);
      window.history.replaceState(
        null,
        '',
        Url.groupBackroomPath(
          username,
          USER_PROFILE_BACKROOM_SECTIONS.NOTES,
          noteId
        )
      );
    },
    [username, setCurrentNoteId]
  );

  return (
    <ErrorBoundary debugComponent="GroupNotes">
      <div className={s['root']}>
        <GroupNotesToolbar
          profileOwnerUser={profileOwnerUser}
          allNoteIds={allNoteIds}
          selectedNoteIds={selectedNoteIds}
          setSelectedNoteIds={setSelectedNoteIds}
          search={search}
          setSearch={setSearch}
        />
        <div className={isMobile ? s['column'] : s['row']}>
          {!isMobile && (
            <div className={s['sidebar']}>
              <GroupNotesSidebar
                onClick={changeFolder}
                selectedFolderName={folder}
                isLoading={isLoading}
              />
            </div>
          )}
          <div className={isMobile ? s['column'] : s['row']}>
            <div className={s['column']}>
              {results.length > 0 && (
                <>
                  <GroupNotesList
                    items={results}
                    currentNoteId={currentNoteId}
                    selectedNoteIds={selectedNoteIds}
                    onItemClick={handleItemClick}
                    onItemCheckboxChange={selectNoteId}
                  />
                  {!isLoading && (
                    <GroupNotesPagination
                      hasMore={hasMore}
                      hasLess={hasLess}
                      fetchNext={fetchNext}
                      fetchPrevious={fetchPrevious}
                    />
                  )}
                </>
              )}
              {results.length === 0 && (
                <EmptyState
                  view="small"
                  className={s['empty']}
                  illustration={
                    <img
                      alt="Empty Notes List"
                      src={illustrationSrc}
                      width="100"
                    />
                  }
                  title={t('widgets.group_notes.empty.list')}
                />
              )}
            </div>
            <div className={s['column']}>
              {currentNoteId && (
                <GroupNotesThread
                  noteId={currentNoteId}
                  className={s['thread']}
                />
              )}
              {!currentNoteId && (
                <EmptyState
                  view="small"
                  className={s['empty']}
                  illustration={
                    <img
                      alt="Empty Notes List"
                      src={illustrationSrc}
                      width="100"
                    />
                  }
                  title={t('widgets.group_notes.empty.thread')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default GroupNotes;
