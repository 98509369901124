import React from 'react';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { Nav } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import MiniProfileIdentity from '../../../UserWidgets/_partials/MiniProfileIdentity';
import NavRoutes from './_partials/NavRoutes';
import { getProfileOwnerUser } from '../../../../selectors/users';
import s from './BaseSubNavBarDesktopInner.scss';

export interface Props {
  rightSideContent?: React.ReactNode;
  isSticky?: boolean;
  smallerRightPadding?: boolean;
}

const BaseSubNavBarDesktopInner: React.FC<Props> = ({
  isSticky,
  rightSideContent,
  smallerRightPadding,
}) => {
  const { t } = useTranslation();
  const profileOwnerUser = useSelector(getProfileOwnerUser);

  return (
    <Nav
      className={c(
        s['navbar'],
        smallerRightPadding && s['smaller-right-padding'],
        isSticky ? s['sticky'] : s['non-sticky']
      )}
    >
      <VisuallyHidden>
        <Heading>{t('navigation.subnavigation.heading')}</Heading>
      </VisuallyHidden>
      <div className={s['navbar-user-info']}>
        <MiniProfileIdentity
          user={profileOwnerUser}
          className={s['user-info']}
          usernameClassName={s['user-name']}
          withTooltip={false}
          asCard={false}
        />
      </div>
      <NavRoutes
        className={c(s['nav-list'], isSticky && s['with-separator'])}
      />
      {rightSideContent}
    </Nav>
  );
};

export default BaseSubNavBarDesktopInner;
