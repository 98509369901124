import { createAction } from 'redux-actions';

export const reduxKey = 'GROUPS_BACKROOM';

export const filterGroupActionLogByRole = createAction(
  `${reduxKey}.FILTER_ACTION_LOG_BY_ROLE`,
  (roleId: number | undefined) => ({ roleId })
);

export const sortGroupActionLog = createAction(
  `${reduxKey}.SORT_ACTION_LOG`,
  (sortValue: 'newest' | 'oldest') => ({ sortValue })
);

export const filterGroupMessageLogByRole = createAction(
  `${reduxKey}.FILTER_MESSAGE_LOG_BY_ROLE`,
  (roleId: number | undefined) => ({ roleId })
);

export const filterGroupMessageLogByNotificationType = createAction(
  `${reduxKey}.FILTER_MESSAGE_LOG_BY_NOTIFICATION_TYPE`,
  (notificationType: number | undefined) => ({ notificationType })
);

export const filterGroupMessageLogByStatus = createAction(
  `${reduxKey}.FILTER_MESSAGE_LOG_BY_STATUS`,
  (status: number | undefined) => ({ status })
);

export const sortGroupMessageLog = createAction(
  `${reduxKey}.SORT_MESSAGE_LOG`,
  (sortBy: 'newest' | 'oldest') => ({ sortBy })
);

export const archiveNotes = createAction(
  `${reduxKey}.ARCHIVE_NOTES`,
  (noteIds: number[]) => ({ noteIds })
);

export const unarchiveNotes = createAction(
  `${reduxKey}.UNARCHIVE_NOTES`,
  (noteIds: number[]) => ({ noteIds })
);

export const starNotes = createAction(
  `${reduxKey}.STAR_NOTES`,
  (noteIds: number[]) => ({ noteIds })
);

export const unstarNotes = createAction(
  `${reduxKey}.UNSTAR_NOTES`,
  (noteIds: number[]) => ({ noteIds })
);

export const deleteNotes = createAction(
  `${reduxKey}.DELETE_NOTES`,
  (noteIds: number[]) => ({ noteIds })
);

export const setSelectedFolder = createAction(
  `${reduxKey}.SET_SELECTED_FOLDER`,
  (folder: string) => ({ folder })
);

export const setSelectedNoteId = createAction(
  `${reduxKey}.SET_SELECTED_NOTEID`,
  (noteId: number | undefined) => ({ noteId })
);

export const setNotesOffset = createAction(
  `${reduxKey}.SET_NOTES_OFFSET`,
  (offset: number | undefined) => ({ offset })
);

export const setNotesSearch = createAction(
  `${reduxKey}.SET_NOTES_SEARCH`,
  (search: string | undefined) => ({ search })
);
