import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PapiGallection } from '@wix/da-papi-types';
import { ALL_FOLDER_ID, SCRAPS_FOLDER_ID } from '@wix/da-url';
import Flex from '@wix/da-ds/pkg/Flex';
import CollapsibleBlock from '@wix/da-shared-react/pkg/CollapsibleBlock';
import Button from '@wix/da-shared-react/pkg/Button';
import SubfolderCard from '../../SubfolderCard';
import StickyFolderBar from '../../FolderBar/StickyFolderBar';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import { isCurrentUserProfileOwner } from '../../../../../selectors/users';
import ExcerptRenderer from '@wix/da-shared-react/pkg/TextRenderer/ExcerptRenderer';
import TextRenderer from '@wix/da-shared-react/pkg/TextRenderer';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import GallectionTiptapViewer from '@wix/da-editor-tiptap/pkg/components/Viewer/presets/Gallection';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import SubfolderCards from '../../SubfolderCards';

import s from './FolderDescription.scss';

export interface Props {
  folder: PapiGallection;
  className?: string;
}

const COLLAPSE_WHEN_THIS_TALL = 70;

const getDescriptionText = (folder: PapiGallection) => {
  if ([ALL_FOLDER_ID, SCRAPS_FOLDER_ID].includes(folder.folderId)) {
    // pseudo-folders
    return '';
  }

  if (folder.richDescription) {
    return folder.richDescription;
  }

  // Text without line breaks and html tags
  return (folder.description || '').replace(/<\/?[^>]+>/g, '');
};

export const FolderDescription: React.FC<Props> = ({ folder, className }) => {
  const isMobile = useContext(MobileContext);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const isSubfolderless = useFlag('subfolderless');

  const { isPremiumLocked } = normalizePremiumFolderData(
    folder.premiumFolderData
  );
  const { t } = useTranslation();

  if (!folder) {
    return null;
  }
  const description = getDescriptionText(folder);

  const subfolders = folder.subfolders ?? [];

  if (isMobile && !folder.hasSubfolders && !description) {
    return null;
  }

  const renderDescription = () => {
    if (!description) {
      return null;
    }

    return (
      <CollapsibleBlock
        maxHeight={COLLAPSE_WHEN_THIS_TALL}
        className={s['collapsible-block']}
        showFade
        fadeClassName={s['collapsible-fade']}
        renderToggleButton={({ collapsed, onClick }) => (
          <Button
            view="b3"
            className={s['collapsible-button']}
            onClick={onClick as any}
          >
            {t(
              collapsed
                ? 'pages.gallections.folderDescription.readMore'
                : 'common.showLess'
            )}
          </Button>
        )}
      >
        <div className={s['description']}>
          {folder.description && !folder?.richDescription && (
            <ExcerptRenderer
              htmlExcerptLegacy
              legacyTheme={'comments'}
              textContent={{
                excerpt: folder.description as string,
                html: {
                  type: 'writer',
                  markup: '',
                  features: '',
                },
              }}
            />
          )}
          {folder?.richDescription && (
            <TextRenderer
              tipTapPresetId="gallection"
              tipTapViewer={GallectionTiptapViewer}
              cookieType={MeasuredCookieType.GALLECTION_FOLDER}
              textContent={folder.richDescription}
            />
          )}
        </div>
      </CollapsibleBlock>
    );
  };

  const subfolderTransKeys = {
    gallery: 'pages.gallections.gallery.subfolders',
    collection: 'pages.gallections.collection.subfolders',
  };

  let renderSubfolders;

  if (isSubfolderless) {
    renderSubfolders = () => <SubfolderCards folder={folder} />;
  } else {
    renderSubfolders = () => {
      if (!subfolders.length) {
        return null;
      }
      // subfolder count should only be shown if there's description. Don't look at me.
      const showSubfolderCount = !!description;
      return (
        <div className={s['subfolders-section']}>
          <div className={s['subfolders-header']}>
            {t(subfolderTransKeys[folder.type])}{' '}
            {showSubfolderCount && (
              <span className={s['subfolders-count']}>{subfolders.length}</span>
            )}
          </div>
          <Flex wrap="wrap">
            {subfolders.map(
              subFolder =>
                (!!subFolder.size || isOwner) && (
                  <SubfolderCard
                    key={subFolder.folderId}
                    subfolder={subFolder}
                    className={s['subfolder']}
                  />
                )
            )}
          </Flex>
        </div>
      );
    };
  }
  return (
    <div className={classnames(s['root'], className)}>
      <Flex direction="column">
        {!isMobile && <StickyFolderBar currentFolder={folder} />}
        {!isPremiumLocked && renderDescription()}
        {renderSubfolders()}
      </Flex>
    </div>
  );
};
FolderDescription.displayName = 'FolderDescription';

export default FolderDescription;
