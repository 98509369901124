import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import { type ReturnType as UseFilterStateReturnType } from '../../hooks/useShopFilterState';
import { SubnavMap } from '../../types';
import ShopFiltersSubnavRadioGroup from './ShopFiltersSubnavRadioGroup';

import s from './ShopForm.scss';

export interface Props extends UseFilterStateReturnType {
  filterSubnavMap: SubnavMap;
  className?: string;
}

export const ShopForm: React.FC<React.PropsWithChildren<Props>> = ({
  filterSubnavMap,
  filterSubnavValues,
  updateFilters,
  updateFiltersAndReload,
  resetFilters,
  resetFiltersAndReload,
  className,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const reloadWithCurrentFilters = () => updateFiltersAndReload();

  return (
    <form
      className={cn(className, s['root'])}
      onSubmit={e => e.preventDefault()}
    >
      {filterSubnavMap.filter_adoptable_status && (
        <ShopFiltersSubnavRadioGroup
          className={s['radio-group']}
          buttonClassName={s['radio-group-option-label']}
          subnav={filterSubnavMap.filter_adoptable_status}
          legend={t('pages.shop.shopFilters.status.legend')}
          labelLocaleKeyPrefix="pages.shop.shopFilters.status.label."
          currentValue={filterSubnavValues.filter_adoptable_status}
          onChange={isMobile ? updateFilters : updateFiltersAndReload}
        />
      )}

      {filterSubnavMap.filter_price_range && (
        <ShopFiltersSubnavRadioGroup
          className={s['radio-group']}
          buttonClassName={s['radio-group-option-label']}
          subnav={filterSubnavMap.filter_price_range}
          legend={t('pages.shop.shopFilters.price.legend')}
          currentValue={filterSubnavValues.filter_price_range}
          onChange={isMobile ? updateFilters : updateFiltersAndReload}
        />
      )}

      {isMobile ? (
        <div className={s['mobile-form-buttons']}>
          <TextButton
            variant="main"
            size="large"
            type="button"
            onClick={resetFilters}
          >
            {t('pages.shop.shopFilters.resetForm.mobile')}
          </TextButton>
          <SolidButton
            variant="approval-green"
            size="large"
            type="button"
            onClick={reloadWithCurrentFilters}
          >
            {t('pages.shop.shopFilters.applyForm')}
          </SolidButton>
        </div>
      ) : (
        <TextButton
          className={s['desktop-reset-button']}
          variant="main"
          size="small"
          type="button"
          onClick={resetFiltersAndReload}
        >
          {t('pages.shop.shopFilters.resetForm')}
        </TextButton>
      )}
    </form>
  );
};

ShopForm.displayName = 'ShopForm';
export default ShopForm;
