import React from 'react';
import { useTranslation } from 'react-i18next';

import { PapiModuleDeviousness } from '@wix/da-papi-types';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';

import { default as Deviousness } from './Deviousness';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const DeviousnessBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const {
    moduleData: { awardType },
  } = useModuleData<PapiModuleDeviousness>(moduleId);
  return (
    <WidgetBox
      header={t(`widgets.deviousness.header.byType.var.${awardType}`)}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      <ErrorBoundary debugComponent="DeviousnessBox">
        <Deviousness moduleId={moduleId} />
      </ErrorBoundary>
    </WidgetBox>
  );
};
DeviousnessBox.displayName = 'DeviousnessBox';

export default DeviousnessBox;
