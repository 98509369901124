import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ModuleName } from '@wix/da-gruser-shared/pkg/types/modules';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import FilterIcon from '@wix/da-ds/pkg/Icons/Filter';
import RightArrowIcon from '@wix/da-ds/pkg/Icons/24x24/ChevronRight';
import LeftArrowIcon from '@wix/da-ds/pkg/Icons/24x24/ArrowLeft';
import Modal from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import { Subnavigation } from './types';
import useShopFilterState from './hooks/useShopFilterState';
import useRequestParams from './hooks/useRequestParams';
import useModuleDataByName from '@wix/da-gruser-shared/pkg/hooks/useModuleDataByName';
import IconButton from './_partials/IconButton';
import OrderDropdown from './_partials/OrderDropdown';
import EmptyAfterFiltering from './_partials/EmptyAfterFiltering';
import ShopSearch from './_partials/ShopSearch';
import ShopForm from './_partials/ShopForm';
import TopSupporters from '../../../TopSupporters';
import { ShopItemType } from '../../../../../../../types/shop';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import {
  MIN_SUPPORTERS_COUNT_TO_RENDER,
  TimePeriod,
} from '../../../TopSupporters/TopSupporters';

import s from './ShopFilters.scss';

export interface Props {
  moduleName: ModuleName;
  itemCount: number;
  areFiltersExpanded: boolean;
  setFiltersExpanded: (v: boolean | Function) => void;
  itemType: ShopItemType;
}

/** there are three control sections:
 *  - search: which appears as an input on desktop and a disclosure+input on mobile.
 *    Its state is used to build the 'q' parameter in the web url and papi's parameters
 *    The current value of 'q' on page load is sent in src/server/store/routeLoaders/base.ts
 *    (along with other request parameters) and picked up here with the useRequestParams hook.
 *  - order: this is a dropdown that, on both desktop and mobile, adds the order to the
 *    current url and immediately navigates the page
 *  - 'filters' are a series of subnavigations that map one or more options to sets of query
 *    params that are then sent to papi for filtering. On desktop, changing them immediately
 *    reloads the page with the new filters. On mobile, the user must click 'apply' (like a
 *    form) and then the filters will be added to the url and the page reloads. Because of
 *    that, we need to maintain the user's selections in useShopFilterState where any final
 *    url with filters is built and navigated to
 */
export const ShopFilters: React.FC<React.PropsWithChildren<Props>> = ({
  moduleName,
  itemCount,
  areFiltersExpanded,
  setFiltersExpanded,
  itemType,
  children,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const requestParams = useRequestParams();

  const toggleFilters = () => setFiltersExpanded(v => !v);
  const closeFilters = () => setFiltersExpanded(false);

  // convert the backends subnav array into a map keyed by subnav type
  const moduleData = useModuleDataByName(moduleName) ?? {};
  const { subnavs = [] as Subnavigation[], hasSearchBox } = moduleData;
  const { order: orderSubnav, ...filterSubnavMap } = useMemo(() => {
    return subnavs.reduce(
      (map, subnav) => Object.assign(map, { [subnav.type]: subnav }),
      {}
    );
  }, [subnavs]);

  const filterState = useShopFilterState(filterSubnavMap);
  const { activeFilterCount } = filterState;

  const hasLeftFilters = Object.keys(filterSubnavMap).length > 0;
  const hasTopFilters = hasSearchBox || orderSubnav;
  const hasFilters = hasLeftFilters || hasTopFilters;
  const isTopSupporters = useFlag('top_supporters');
  const supportersModuleData = useModuleDataByName('supporters') || false;

  const topSupporters = itemType === ShopItemType.ADOPTABLES && (
    <TopSupporters />
  );

  if (!hasFilters) {
    // When filters are not present we still want to show top supporters
    if (isTopSupporters && supportersModuleData) {
      const supporters =
        supportersModuleData.timePeriods?.[TimePeriod.RECENT]?.results || [];
      if (supporters.length >= MIN_SUPPORTERS_COUNT_TO_RENDER) {
        return (
          <div className={s['root']}>
            <div className={s['top-bar']}>{topSupporters}</div>
            <div className={s['content']}>{children}</div>
          </div>
        );
      }
    }
    return children;
  }

  const ArrowComponent = areFiltersExpanded ? LeftArrowIcon : RightArrowIcon;
  const expandToggle = (
    <IconButton
      className={s['expand-toggle']}
      onClick={toggleFilters}
      icon={<FilterIcon className={s['icon']} />}
      a11yText={t('pages.shop.shopFilters.openFiltersButton.a11y.description')}
    >
      {isMobile && activeFilterCount > 0 && (
        <span className={s['expand-toggle-filter-count']}>
          {`(${activeFilterCount})`}
        </span>
      )}
      <span className={s['expand-toggle-arrow']}>
        <ArrowComponent className={s['icon']} />
      </span>
    </IconButton>
  );

  const filterForm = (
    <ShopForm filterSubnavMap={filterSubnavMap} {...filterState} />
  );

  const existingSearchTerm = requestParams.q;
  const shopSearch = hasSearchBox && (
    <ShopSearch
      formClassName={s['search']}
      mobileIconClassName={s['icon']}
      autoFocus={isMobile}
      defaultValue={existingSearchTerm}
    />
  );

  const orderDropdown = orderSubnav && (
    <OrderDropdown
      className={s['order']}
      subnav={orderSubnav}
      localeSpecifier="pages.shop.shopFilters.order"
    />
  );

  const hasContent = itemCount >= 1;
  const content = hasContent ? (
    children
  ) : (
    <EmptyAfterFiltering
      isFiltering={activeFilterCount >= 1}
      isSearching={!!existingSearchTerm}
    />
  );

  if (isMobile) {
    return (
      <div className={s['root']}>
        {/* on mobile, filter form expands inside a modal... */}
        {hasLeftFilters && areFiltersExpanded && (
          <Modal
            className={s['mobile-filter-modal']}
            isOpen
            onRequestClose={closeFilters}
            showHeaderBorder
            heading={t('pages.shop.shopFilters.modalTitle')}
            contentClassName={s['mobile-filter-modal-content']}
          >
            {filterForm}
          </Modal>
        )}

        {(hasLeftFilters || hasTopFilters) && (
          <div className={s['top-bar']}>
            {topSupporters}
            <div className={s['top-controls']}>
              {/* ...but the button to open the modal goes in the top section */}
              {hasLeftFilters && expandToggle}
              {shopSearch}
              {orderDropdown}
            </div>
          </div>
        )}

        <div className={s['content']}>{content}</div>
      </div>
    );
  }

  // desktop
  return (
    <div
      className={cn(
        areFiltersExpanded && s['expanded'],
        hasLeftFilters && s['with-left-filters'],
        hasTopFilters && s['with-top-filters'],
        s['root']
      )}
      id="shop-filters"
    >
      {hasLeftFilters && (
        <div className={s['left-controls']}>
          {/* on desktop, filter form is in a sticky column on the left
              that expands or contracts in width instead */}
          <div className={s['sticky']}>
            {expandToggle}
            {areFiltersExpanded && filterForm}
          </div>
        </div>
      )}

      {hasTopFilters && (
        <div className={s['top-bar']}>
          {topSupporters}
          <div className={s['top-controls']}>
            {shopSearch}
            {orderDropdown}
          </div>
        </div>
      )}

      <div className={s['content']}>{content}</div>
    </div>
  );
};

ShopFilters.displayName = 'ShopFilters';
export default ShopFilters;
