import React, { useContext } from 'react';
import cn from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import EmptyState from './_partials/EmptyState';
import IncomeChartPanel from './_partials/ChartPanel/presets/IncomeChartPanel';
import SubscribersChartPanel from './_partials/ChartPanel/presets/SubscribersChartPanel';
import MobileContent from './_partials/MobileContent';

import s from './SubscriptionCenter.scss';
import { useSelector } from 'react-redux';
import {
  getSubscriptionsChartData,
  getSubscriptionsStats,
} from '../../../../../selectors/subscriptionsSection';

export interface Props {
  moduleId: number;
  className?: string;
}

const SubscriptionCenter: React.FC<Props> = ({ moduleId, className }) => {
  const isMobile = useContext(MobileContext);
  const stats = useSelector(getSubscriptionsStats);
  const chartData = useSelector(getSubscriptionsChartData);

  if (!stats || stats.length < 1 || !chartData || chartData.length < 1) {
    return <EmptyState />;
  }

  if (isMobile) {
    return (
      <MobileContent
        moduleId={moduleId}
        className={className}
        stats={stats}
        chartData={chartData}
      />
    );
  }

  return (
    <div className={cn(s['root'], className)}>
      <IncomeChartPanel
        className={s['chart-panel']}
        chart={chartData}
        stats={stats && stats[0]}
      />
      <SubscribersChartPanel
        className={s['chart-panel']}
        chart={chartData}
        stats={stats && stats[0]}
      />
    </div>
  );
};
SubscriptionCenter.displayName = 'SubscriptionCenter';

export default SubscriptionCenter;
