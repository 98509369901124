import { GroupCreationState } from '../../types/groupCreation';
import {
  initializePage,
  createGroup,
  groupCreationSuccess,
  groupCreationFailure,
} from '../actions/groupCreation';

const defaultState = {
  isLoading: false,
} as GroupCreationState;

export default function groupCreationReducer(
  state = defaultState,
  action
): GroupCreationState {
  const { type, payload } = action;
  switch (type) {
    case initializePage.toString():
      return {
        ...state,
        canCreate: payload.canCreate,
      };
    case createGroup.toString():
      return {
        ...state,
        isLoading: true,
      };
    case groupCreationSuccess.toString():
      return {
        ...state,
        groupCreated: payload,
        isLoading: false,
      };
    case groupCreationFailure.toString():
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
