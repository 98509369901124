import React, { useContext, useState } from 'react';
import { compose } from 'redux';
import { withTranslation, useTranslation } from 'react-i18next';
import useIsSellableAsPremiumGallery from '@wix/da-shared-react/pkg/utils/hooks/useIsSellableAsPremiumGallery';
import { ALL_FOLDER_ID } from '@wix/da-url';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import MediaManager, {
  getMediaSourceFromGallectionType,
} from '@wix/da-shared-react/pkg/MediaPopup/MediaManager';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { GallectionType } from '../../../../../types/gallection';
import BuyButton from '@wix/da-ds/pkg/Buttons/BuyButton';
import PremiumFolderDataIcon from '@wix/da-shared-react/pkg/GallectionFolderDropdown/_partials/PremiumFolderDataIcon';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import SortOrderSwitcher from '../SortOrderSwitcher';
import FolderBarFolderDropdown from './_partials/FolderBarFolderDropdown';
import { PapiGallection, PapiUser } from '@wix/da-papi-types';
import {
  withMobileContext,
  MobileContext,
} from '@wix/da-react-context/pkg/MobileContext';
import EditGallectionButton from '../EditGallectionButton';

import s from './FolderBar.scss';

export interface Props {
  currentFolder?: PapiGallection | null;
  profileOwner?: PapiUser | null;
  isOwner?: boolean;
  unsetSelectedFolderId: () => void;
  withBackButton?: boolean;
}

const enhance = compose(withMobileContext, withTranslation());

export const FolderBar: React.FC<Props> = ({
  currentFolder,
  profileOwner,
  isOwner,
  withBackButton,
  unsetSelectedFolderId,
}) => {
  const showSellAsPremiumGalleryButton = useIsSellableAsPremiumGallery(
    currentFolder || undefined
  );

  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();

  const [isMediaManagerShown, setShowMediaManager] = useState(false);
  const closeMediaManager = e => {
    setShowMediaManager(false);
    window.location.reload();
    e && e.preventDefault();
    e && e.stopPropagation();
  };

  if (!currentFolder || !profileOwner) {
    return null;
  }

  const { isPaidFolder, numSubscribers } = normalizePremiumFolderData(
    currentFolder?.premiumFolderData
  );

  const vespaSearchEngineIsReady = false;
  const showEditGallectionButton =
    !isMobile && currentFolder.folderId !== ALL_FOLDER_ID;

  return (
    <>
      <div className={s['folder-info']}>
        {withBackButton && (
          <PlainButtonOrLink
            className={s['back']}
            onClick={unsetSelectedFolderId}
          >
            {t!('pages.gallections.back')}
          </PlainButtonOrLink>
        )}
        <PremiumFolderDataIcon
          premiumFolderData={currentFolder?.premiumFolderData}
          size={IconSize.COMPACT}
          className={s['premium-folder-icon']}
        />
        <FolderBarFolderDropdown currentFolder={currentFolder} />
        {isPaidFolder && (
          <div className={s['comments-count']}>
            {t!('common.supporters_other')}
            <span className={s['count']}>{shortenNumber(numSubscribers)}</span>
          </div>
        )}
      </div>
      <div className={s['right-zone']}>
        <div>
          {showEditGallectionButton && (
            <EditGallectionButton gallection={currentFolder} />
          )}
          {vespaSearchEngineIsReady && (
            <SortOrderSwitcher className={s['sort-order-switch']} />
          )}
        </div>
        {showSellAsPremiumGalleryButton && (
          <BuyButton
            variant={'outline'}
            size={'medium'}
            rounded={true}
            onClick={() => setShowMediaManager(true)}
          >
            {t('folderCard.sell')}
          </BuyButton>
        )}
        {isMediaManagerShown && (
          <MediaManager
            initialMediaSource={getMediaSourceFromGallectionType(
              GallectionType.GALLERY
            )}
            gruser={profileOwner}
            gallectionFolder={currentFolder}
            onDone={closeMediaManager}
            onClose={closeMediaManager}
          />
        )}
      </div>
    </>
  );
};
FolderBar.displayName = 'FolderBar';

export default enhance(FolderBar) as React.ComponentType<Props>;
