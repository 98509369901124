import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import AboutMeBox from './AboutMeBox';
import AboutMeBoxWide from './AboutMeBox/AboutMeBoxWide';
import AboutMeConfigModalLazy from './AboutMeConfigModal/AboutMeConfigModalLazy';

export const getAboutMeWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: AboutMeBox,
  configPopoverComponent: AboutMeConfigModalLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-22-about-me.svg',
    title: t('widgets.aboutMe.header'),
    description: t('widgets.aboutMe.description'),
  },
});

export const getWideAboutMeWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  ...getAboutMeWidgetConfig({ t, dispatch }),
  viewComponent: AboutMeBoxWide,
});
