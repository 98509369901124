import { connect, MapStateToProps } from 'react-redux';
import { Props } from './AddItemCard';
import { AppState } from '../../../../../../types/store';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';

export type StateProps = Pick<Props, 'hasCoreMembership'>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  hasCoreMembership: getCurrentUserHasCore(state),
});

export default connect(mapStateToProps);
