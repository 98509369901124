import React from 'react';
import s from './UserInfoBulletList.scss';

interface Props {}
const UserInfoBulletList: React.FC<Props> = props => (
  <ul className={s['root']} {...props} />
);
UserInfoBulletList.displayName = 'UserInfoBulletList';

export default UserInfoBulletList;
