import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { PapiModuleCoverDeviation } from '@wix/da-papi-types';
import { setCustomSkin } from '../../../../actions/profileSkins';
import { profileSkinFromProps } from '../../../../../utils/profileSkins';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleCoverDeviation,
  requestParams: any
) => {
  const profileSkin = moduleData?.profileSkin;

  if (!profileSkin) {
    return [];
  }

  return [setCustomSkin(profileSkinFromProps(profileSkin as any))] as any;
};
