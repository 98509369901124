import React from 'react';
import { connect } from 'react-redux';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { Props as ConfirmationModalProps } from '@wix/da-shared-react/pkg/Popup/ConfirmationModal';
import { AppState } from '../../../../types/store';

export interface WithConfirmationModalProps {
  pushConfirmationModal: (props: ConfirmationModalProps) => void;
}

const withConfirmationModal = <BaseProps extends WithConfirmationModalProps>(
  BaseComponent: React.ComponentType<BaseProps>
) => {
  type HocProps = typeof dispatchProps;
  type CompProps = Omit<BaseProps, keyof WithConfirmationModalProps>;

  class ComponentWithConfirmationModal extends React.Component<HocProps> {
    render() {
      return <BaseComponent {...(this.props as BaseProps)} />;
    }
  }

  const dispatchProps = {
    pushConfirmationModal: modalActions.pushConfirmationModal,
  };

  return connect<{}, HocProps, CompProps, AppState>(
    null,
    dispatchProps
  )(ComponentWithConfirmationModal);
};

export default withConfirmationModal;
