import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import { ListItemRadioButton } from '@wix/da-ds/pkg/List/ListItem';

import s from './GroupCloseModal.scss';

export interface Props {
  requestClose: () => void;
  requestCancel: () => void;
  requestConfirm: (reason) => void;
}

const GroupCloseModal: React.FC<Props> = ({
  requestCancel,
  requestConfirm,
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState();
  const handleOnChange = (_, value) => {
    setReason(value);
  };
  return (
    <Modal
      isOpen
      showFooterBorder
      showHeaderBorder
      heading={t('groups.close.popup.title')}
      onRequestClose={requestCancel}
      layoutClassName={s['modal']}
      footer={
        <div className={s['footer']}>
          <TextButton
            key="cancel"
            variant="main"
            size="large"
            onClick={requestCancel}
          >
            {t('common.cancel')}
          </TextButton>
          <BrandedButton
            key="confirm"
            variant="brand-green"
            size="large"
            disabled={reason === undefined}
            onClick={() => requestConfirm(reason)}
          >
            {t('groups.close.popup.button.accept')}
          </BrandedButton>
        </div>
      }
    >
      <div className={s['root']}>
        <div className={s['text']}>{t('groups.close.popup.subtitle')}</div>
        <div className={s['options']}>
          {[1, 2, 3, 4, 0].map(index => (
            <ListItemRadioButton
              key={index}
              value={`${index}`}
              name="reason"
              label={t(`groups.close.popup.reason.var.${index}`)}
              onChange={handleOnChange}
              /* eslint-disable eqeqeq */
              checked={reason == index}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
GroupCloseModal.displayName = 'GroupCloseModal';

export default GroupCloseModal;
