import React from 'react';
import { compose } from 'redux';
import Button from '@wix/da-shared-react/pkg/Button';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  CommissionStatusType,
  CommissionStatusCodeMap,
} from '@wix/da-shared-react/pkg/types/commissions';
import {
  default as CommissionConfirmation,
  Props as CommissionConfirmationProps,
} from '@wix/da-shared-react/pkg/CommissionEditPopup/CommissionConfirmation';
import {
  withMobileContext,
  WithMobileContextProps,
} from '@wix/da-react-context/pkg/MobileContext';
import {
  WithVerificationProps,
  withVerification,
} from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import DisabledOverlay from '../../_partials/DisabledOverlay';
import DisabledEmptyState from '../../_partials/DisabledEmptyState';
import LockIcon from '@wix/da-ds/pkg/Icons/24x24/Lock';
import UnlockIcon from '@wix/da-ds/pkg/Icons/24x24/Unlock';
import CommissionCreateButton from '../../_partials/CommissionCreateButton';

import s from './ShopCommissionsControls.scss';

export interface Props {
  className?: string;
  isOpenForCommissions: boolean;
  openCommissionEditModal(): void;
  changeAllCommissionsStatus(status: CommissionStatusType): void;
  isOwner: boolean;
  total: number;
}

export interface State {
  activeCommissionsCount?: number;
  showChangeStatusButton: boolean;
  allCommissionsDisabled: boolean;
  confirmationPopupType?: CommissionConfirmationProps['confirmationType'];
}

const enhance = compose(withVerification, withMobileContext, withTranslation());
export class ShopCommissionsControls extends React.Component<
  Props & WithTranslation & WithMobileContextProps & WithVerificationProps
> {
  readonly state: State = {
    activeCommissionsCount: undefined,
    showChangeStatusButton: false,
    allCommissionsDisabled: false,
    confirmationPopupType: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    const activeCommissionsCount = props.items.filter(
      deviation =>
        deviation.commission.status ===
        CommissionStatusCodeMap[CommissionStatusType.ACTIVE]
    ).length;

    const showChangeStatusButton = props.total > 1;
    const allCommissionsDisabled = activeCommissionsCount === 0;

    if (
      showChangeStatusButton !== state.showChangeStatusButton ||
      allCommissionsDisabled !== state.allCommissionsDisabled
    ) {
      return {
        showChangeStatusButton,
        allCommissionsDisabled,
      };
    }
    return null;
  }

  render() {
    const {
      isOwner,
      isMobile,
      total,
      isOpenForCommissions,
      t,
    } = this.props;
    const {
      confirmationPopupType,
      showChangeStatusButton,
      allCommissionsDisabled,
    } = this.state;

    if (!total) {
      return <CommissionCreateButton />;
    }

    if (!isOpenForCommissions) {
      return this.renderDisabledView();
    }

    return (
      isOwner &&
      !isMobile && (
        <div className={s['owner-controls']}>
          {showChangeStatusButton && (
            <Button
              className={s['button']}
              view="b3"
              onClick={this.openChangeStatusConfirmation}
            >
              {allCommissionsDisabled ? (
                <UnlockIcon className={s['icon-unlock']} />
              ) : (
                <LockIcon className={s['icon-lock']} />
              )}
              {allCommissionsDisabled
                ? t('common.openAll')
                : t('common.closeAll')}
            </Button>
          )}
          {confirmationPopupType && (
            <CommissionConfirmation
              isOpen
              confirmationType={confirmationPopupType}
              onCancel={this.closeChangeStatusConfirmation}
              onConfirm={this.onChangeStatus}
            />
          )}
        </div>
      )
    );
  }

  renderDisabledView() {
    const { isOwner, isMobile } = this.props;
    return isOwner && !isMobile ? (
      <DisabledOverlay className={s['disabled-overlay']} />
    ) : (
      <DisabledEmptyState view="large" />
    );
  }

  openCreateModal = event => {
    const {
      openCommissionEditModal,
      isVerificationNeeded,
      pushVerificationPopup,
    } = this.props;
    event.preventDefault();
    if (isVerificationNeeded) {
      pushVerificationPopup('submitcommission');
      return;
    }

    openCommissionEditModal();
  };

  openChangeStatusConfirmation = event => {
    event.preventDefault();

    this.setState({
      confirmationPopupType: this.state.allCommissionsDisabled
        ? 'enableAll'
        : 'disableAll',
    });
  };

  closeChangeStatusConfirmation = () => {
    this.setState({
      confirmationPopupType: undefined,
    });
  };

  onChangeStatus = () => {
    const status = this.state.allCommissionsDisabled
      ? CommissionStatusType.ACTIVE
      : CommissionStatusType.DISABLED;

    this.props.changeAllCommissionsStatus(status);
    this.closeChangeStatusConfirmation();
  };
}

export default enhance(ShopCommissionsControls) as React.ComponentType<Props>;
