import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import GroupListBox from './GroupListBox';
import GroupListConfigPopoverLazy from './GroupListConfigPopover/GroupListConfigPopoverLazy';
import initMembers from './redux/initMembers';
import initAdmins from './redux/initAdmins';

export const getGroupListMembersWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent: GroupListBox,
  configPopoverComponent: GroupListConfigPopoverLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-17-groups.svg',
    title: t('widgets.group_list_members.addButtonHeader'),
    description: t('widgets.group_list_members.description'),
  },
  initActionsCreator: initMembers,
});

export const getGroupListAdminsWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent: GroupListBox,
  configPopoverComponent: GroupListConfigPopoverLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-0-group-admin.svg',
    title: t('widgets.group_list_admins.addButtonHeader'),
    description: t('widgets.group_list_admins.description'),
  },
  initActionsCreator: initAdmins,
});

export const getWideGroupListAdminsWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  ...getGroupListAdminsWidgetConfig({ t, dispatch }),
  isWide: true,
});

export const getWideGroupListMembersWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  ...getGroupListMembersWidgetConfig({ t, dispatch }),
  isWide: true,
});
