import { connect } from 'react-redux';
import { AppState } from '../../../types/store';
import {
  getProfileOwnerUser,
  getIsWatchingProfileOwner,
} from '../../selectors/users';

export default connect((state: AppState) => ({
  user: getProfileOwnerUser(state),
  initialData: { isWatching: getIsWatchingProfileOwner(state) },
}));
