import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import IconButton from '@wix/da-ds/pkg/Buttons/IconButton';
import { Url } from '@wix/da-url';
import MailIcon from '@wix/da-ds/pkg/Icons/24x24/Mail';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useRestrictToLoggedInAndVerified } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictToLoggedInAndVerified';

interface Props {
  className?: string;
  profileOwner: PapiUser;
}

export const NoteButton: React.FC<Props> = ({ className, profileOwner }) => {
  const { restrictToLoggedInAndVerified } = useRestrictToLoggedInAndVerified();
  const { t } = useTranslation();
  const handleClick = restrictToLoggedInAndVerified(
    ReferralType.NOTE,
    'notes',
    () => window.location.assign(Url.userNoteLink(profileOwner.username))
  );
  return (
    <IconButton
      size="medium"
      title={t(`navigation.subnavigation.message`)}
      className={className}
      tooltipText={t(`navigation.subnavigation.message`)}
      icon={MailIcon}
      onClick={handleClick}
    />
  );
};
NoteButton.displayName = 'NoteButton';

export default NoteButton;
