import React, { useState } from 'react';
import { GallectionType } from '@wix/da-shared-react/pkg/types/gallection';
import useIsSellableAsPremiumGallery from '@wix/da-shared-react/pkg/utils/hooks/useIsSellableAsPremiumGallery';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import EllipsisIcon from '@wix/da-ds/pkg/Icons/Ellipsis';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenu';
import MediaManager, {
  getMediaSourceFromGallectionType,
} from '@wix/da-shared-react/pkg/MediaPopup/MediaManager';
import FolderCard, {
  Props as FolderCardProps,
} from '@wix/da-shared-react/pkg/FolderCard';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';

export interface Props extends FolderCardProps {
  profileOwnerUser: PapiUser;
  isProfileOwner: boolean;
}

const ProfileFolderCard: React.FC<Props> = ({
  className,
  folder,
  profileOwnerUser,
  isProfileOwner,
  ...props
}) => {
  const { t } = useTranslation();
  const showSellAsPremiumGalleryButton = useIsSellableAsPremiumGallery(folder);
  const [isMediaManagerShown, setShowMediaManager] = useState(false);

  const closeMediaManager = e => {
    setShowMediaManager(false);
    window.location.reload();
    e && e.preventDefault();
    e && e.stopPropagation();
  };

  return (
    <>
      <FolderCard
        folder={folder}
        {...props}
        extras={
          showSellAsPremiumGalleryButton && (
            <IconButtonWithContextMenu
              items={[
                {
                  label: t('folderCard.sell'),
                  onClick: e => {
                    setShowMediaManager(true);
                    e && e.preventDefault();
                    e && e.stopPropagation();
                  },
                },
              ]}
              onClick={e => {
                e && e.preventDefault();
                e && e.stopPropagation();
              }}
              withButton
            >
              <EllipsisIcon size={IconSize.MEDIUM} />
            </IconButtonWithContextMenu>
          )
        }
      />
      {isMediaManagerShown && (
        <MediaManager
          initialMediaSource={getMediaSourceFromGallectionType(
            GallectionType.GALLERY
          )}
          gruser={profileOwnerUser}
          gallectionFolder={folder}
          onDone={closeMediaManager}
          onClose={closeMediaManager}
        />
      )}
    </>
  );
};
ProfileFolderCard.displayName = 'ProfileFolderCard';

export default ProfileFolderCard;
