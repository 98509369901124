import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../types/store';
import {
  setIsRepositioningProfileHero,
  setProfileHeroDeviation,
  saveProfileHero,
  deleteProfileHero,
} from '../../../../actions/profileHeroEditor';
import { getCoverDeviation } from '../../../../selectors/deviations';
import { getIsGroupProfile } from '../../../../selectors/users';
import { getIsRepositioningProfileHero } from '../../../../selectors/profileHeroEditor';
import { Props } from './ProfileHeroEditControls';

export type StateProps = Pick<
  Props,
  'coverDeviation' | 'isRepositioning' | 'isGroupProfile'
>;
export type DispatchProps = Pick<
  Props,
  | 'setIsRepositioningProfileHero'
  | 'setProfileHeroDeviation'
  | 'saveProfileHero'
  | 'deleteProfileHero'
>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state,
  ownProps
) => {
  const coverDeviation = getCoverDeviation(state);

  return {
    coverDeviation,
    isRepositioning: getIsRepositioningProfileHero(state),
    isGroupProfile: getIsGroupProfile(state),
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  setIsRepositioningProfileHero,
  setProfileHeroDeviation,
  saveProfileHero,
  deleteProfileHero,
};

export default connect(mapStateToProps, mapDispatchToProps);
