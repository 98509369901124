import { handleActions } from 'redux-actions';
import {
  updateModule,
  purgeAllModules,
  fetchModuleDataSuccess,
  fetchModuleEndpoint,
  fetchModuleEndpointSuccess,
  fetchModuleEndpointError,
  fetchModuleEndpointPurgeData,
  fetchModuleConfig,
  fetchModuleConfigSuccess,
  fetchModuleConfigError,
  saveModuleConfig,
  saveModuleConfigSuccess,
  saveModuleConfigError,
} from '../actions/modules';
import { removeModuleSuccess } from '../actions/layout';
import omit from 'lodash/omit';

const defaultState = {};

export default handleActions<any, any>(
  {
    [fetchModuleDataSuccess.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...omit(action.payload, ['entities']),
        moduleRequests: {
          ...(state[action.payload.id]?.moduleRequests || {}),
        },
      },
    }),
    [removeModuleSuccess.toString()]: (state, action) => {
      const { [action.payload.moduleId]: remove, ...rest } = state;
      return rest;
    },
    [updateModule.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleData: {
          ...state[action.payload.id].moduleData,
          ...action.payload.moduleData,
        },
        moduleMeta: {
          ...state[action.payload.id].moduleMeta,
          ...action.payload.moduleMeta,
        },
      },
    }),
    [fetchModuleConfig.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleMeta: {
          ...(state[action.payload.id]?.moduleMeta || {}),
          isFetchingConfig: true,
          errorDescription: undefined,
        },
      },
    }),
    [fetchModuleConfigSuccess.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleConfig: action.payload.config,
        moduleMeta: {
          ...(state[action.payload.id]?.moduleMeta || {}),
          isFetchingConfig: false,
        },
      },
    }),
    [fetchModuleConfigError.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleMeta: {
          ...(state[action.payload.id]?.moduleMeta || {}),
          isFetchingConfig: false,
          errorDescription: action.payload.errorDescription,
        },
      },
    }),
    [saveModuleConfig.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleMeta: {
          ...(state[action.payload.id]?.moduleMeta || {}),
          isSavingConfig: true,
          errorDescription: undefined,
        },
      },
    }),
    [saveModuleConfigSuccess.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleConfig: undefined,
        moduleMeta: {
          ...(state[action.payload.id]?.moduleMeta || {}),
          isSavingConfig: false,
        },
      },
    }),
    [saveModuleConfigError.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleConfig: undefined,
        moduleMeta: {
          ...(state[action.payload.id]?.moduleMeta || {}),
          isSavingConfig: false,
          errorDescription: action.payload.errorDescription,
        },
      },
    }),
    [fetchModuleEndpointPurgeData.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleRequests: {
          ...(state[action.payload.id]?.moduleRequests || {}),
          [action.payload.path]: undefined,
        },
      },
    }),
    [fetchModuleEndpoint.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleRequests: {
          ...(state[action.payload.id]?.moduleRequests || {}),
          [action.payload.path]: {
            path: action.payload.path,
            response: undefined,
            isFetching: true,
            errorDescription: undefined,
            ts: Date.now(),
          },
        },
      },
    }),
    [fetchModuleEndpointSuccess.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        version:
          action.payload?.response?.config?.version ||
          state[action.payload.id].version,
        moduleRequests: {
          ...(state[action.payload.id]?.moduleRequests || {}),
          [action.payload.path]: {
            ...(state[action.payload.id]?.moduleRequests?.[
              action.payload.path
            ] || {}),
            path: action.payload.path,
            response: action.payload.response,
            isFetching: false,
            errorDescription: undefined,
          },
        },
      },
    }),
    [fetchModuleEndpointError.toString()]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        moduleRequests: {
          ...(state[action.payload.id]?.moduleRequests || {}),
          [action.payload.path]: {
            ...(state[action.payload.id]?.moduleRequests?.[
              action.payload.path
            ] || {}),
            path: action.payload.path,
            response: action.payload.response,
            isFetching: false,
            errorDescription: action.payload.errorDescription,
          },
        },
      },
    }),
    [purgeAllModules.toString()]: () => ({}),
  },
  defaultState
);
