import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiGruserModuleBadgeActivity } from '@wix/da-papi-types';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import useGruser from '@wix/da-gruser-shared/pkg/hooks/useGruser';
import MyBadges from './MyBadges';
import { Props as WidgetBoxProps } from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import EmptyState from '../../EmptyState';
import WidgetBoxWide from '@wix/da-gruser-shared/pkg/components/WidgetBoxWide';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import getStatLine from '../../../utils/getStatLine';
import { URLS } from '@wix/da-url';
import { useSelector } from 'react-redux';
import {
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../selectors/users';
import { BADGE_ACTIVITY_STREAM } from '../../../streams';
import { ModuleType } from '../../../../types/modules';

import s from './MyBadges.scss';

export interface Props {
  moduleId: number;
  isHeaderStickyOnMobile: boolean;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const MyBadgesBoxWide: React.FC<Props> = ({
  moduleId,
  isHeaderStickyOnMobile,
  widgetBoxProps,
}) => {
  const { t } = useTranslation();
  const { gruser } = useGruser();
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const profileOwnerUser = useSelector(getProfileOwnerUser);
  const { moduleData } = useModuleData<PapiGruserModuleBadgeActivity>(moduleId);
  const { totalReceived = 0, totalSent = 0 } = moduleData;
  const { badges } = useSelector(state =>
    getModuleDataByModuleName(state as any, gruser, ModuleType.ABOUT)
  );

  const streamInfo = useStream<PapiGruserModuleBadgeActivity['results'][0]>(
    BADGE_ACTIVITY_STREAM
  );
  const { items, hasMore, isFetching, fetchNext } = streamInfo;

  return (
    <WidgetBoxWide
      moduleId={moduleId}
      id={ModuleType.BADGES_ACTIVITY}
      header={t('widgets.badges_activity.header')}
      count={totalReceived}
      subHeader={getStatLine(t, 'badges', totalReceived)}
      isHeaderStickyOnMobile={isHeaderStickyOnMobile}
      hasMore={hasMore}
      fetchMore={fetchNext}
      isFetching={isFetching}
      isEmpty={badges.length === 0}
      emptyView={
        isOwner ? (
          <EmptyState
            view="small"
            className={s['empty-state']}
            title={t('widgets.badges_activity.empty.title.owner')}
            subtitle={t('widgets.badges_activity.empty.subtitle.owner')}
            withButton
            buttonLabel={t('widgets.badges_activity.empty.CTALabel.owner')}
            onButtonClick={() => window.location.replace(URLS.ROOT)}
          />
        ) : (
          <EmptyState
            view="small"
            className={s['empty-state']}
            title={t('widgets.badges_activity.empty.title.visitor', {
              username: profileOwnerUser.username,
            })}
            subtitle={t('widgets.badges_activity.empty.subtitle.visitor', {
              username: profileOwnerUser.username,
            })}
          />
        )
      }
      {...widgetBoxProps}
    >
      <MyBadges
        badgeActivity={items}
        badges={badges}
        totalReceived={totalReceived}
        totalSent={totalSent}
        isOwner={isOwner}
        profileOwnerUser={profileOwnerUser}
      />
    </WidgetBoxWide>
  );
};

MyBadgesBoxWide.displayName = 'MyBadgesBoxWide';

export default MyBadgesBoxWide;
