import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  popModal,
  replaceModal,
} from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { getPointsCount } from '@wix/da-shared-react/pkg/publicSession/selectors';
import {
  makeInitGivePointsPopupXHRCall,
  makeGivePointsXHRCall,
} from '@wix/da-shared-react/pkg/simpleXHR/actionCreators';
import {
  getInitGivePointsPopupXHRCallResult,
  getIsLoadingForGivePointsXHR,
} from '@wix/da-shared-react/pkg/simpleXHR/selectors';
import { Props } from './GivePointsPopup';
import { ModalType as ModalTypeShared } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { ModalType } from '../../../../types/modals';

export type StateProps = Pick<Props, 'pointsBalance' | 'isLoading'>;
export type DispatchProps = Pick<Props, 'init' | 'submit' | 'close'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, any> = state => {
  return {
    // * we show loading state only on "points_modal/give" XHR
    // * "points_modal/" XHR fetches an accurate points balance
    // but we show modal right away with balance value from public session cache
    isLoading: getIsLoadingForGivePointsXHR(state),
    pointsBalance:
      getInitGivePointsPopupXHRCallResult(state)?.pointsBalance ??
      getPointsCount(state), // from session
  };
};
const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => ({
  init: () => {
    dispatch(makeInitGivePointsPopupXHRCall());
  },
  submit: ({ username, pointsAmount, message }) => {
    dispatch(
      makeGivePointsXHRCall({
        username,
        pointsAmount,
        message,
        onSuccess: () => {
          dispatch(
            replaceModal(ModalType.GIVE_POINTS_SUCCESS, {
              params: { username, pointsAmount },
              wrapInModal: false,
            })
          );
        },
        onError: result => {
          dispatch(
            replaceModal(ModalTypeShared.GIVE_ITEM_FAILURE, {
              params: {
                titleLocaleKey: 'givePointsPopup.failureMessage.title',
                errorDescription: result?.errorDescription,
              },
            })
          );
        },
      })
    );
  },
  close: () => dispatch(popModal()),
});

export default connect(mapStateToProps, mapDispatchToProps);
