import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { PapiBadge, PapiUser } from '@wix/da-papi-types';
import Badge from '@wix/da-ds/pkg/Badge';
import XTimesIcon from '@wix/da-ds/pkg/Icons/XTimes';
import shortenNumber from '@wix/da-ds/pkg/utils/shortenNumber';
import { showBadgeInfoModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { getBiDataForBadge } from '@wix/da-shared-react/pkg/utils/badges';

import s from './BadgeBox.scss';

export interface Props {
  item: PapiBadge;
  className?: string;
  profileOwnerUser: PapiUser;
}

const BadgeBox: React.FC<Props> = ({ profileOwnerUser, item, className }) => {
  const dispatch = useDispatch();

  const showInfo = useCallback(
    () => dispatch(showBadgeInfoModal(profileOwnerUser.username, item.id!)),
    [dispatch, profileOwnerUser, item]
  );

  const renderContent = () => {
    const count = item.stackCount || 1;
    const shortCount = shortenNumber(count, 0);
    const showRealCountInTitle = String(count) !== shortCount;
    return (
      <>
        <div className={s['badge-info']}>
          <Badge badge={item} />
          <XTimesIcon
            className={classnames(s['icon'], s['xtimes-icon'])}
            withPadding
            condensed
          />
          <span
            className={s['badge-count']}
            title={String(showRealCountInTitle && count)}
          >
            {shortCount}
          </span>
        </div>
        <div className={s['badge-title']}>
          <span className={s['badge-title-text']}>{item.baseTitle}</span>
        </div>
      </>
    );
  };

  return (
    <PlainButtonOrLink
      className={classnames(s['root'], className)}
      onClick={showInfo}
      biData={getBiDataForBadge(item)}
    >
      {renderContent()}
    </PlainButtonOrLink>
  );
};
BadgeBox.displayName = 'BadgeBox';
export default BadgeBox;
