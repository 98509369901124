import React from 'react';
import { PapiUser } from '@wix/da-papi-types';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Modal from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import HookFormDevtools from '@wix/da-shared-react/pkg/utils/hookform/Devtools';

import TextArea from '../../_partials/Form/TextArea';
import SelectField from '../../_partials/Form/SelectField';
import UserInput from './_partials/UserInputField';

import { useInviteToGroupForm } from './form';

import s from './InviteToGroupModal.scss';

export interface Props {
  group: PapiUser;
  moduleId: number;
  requestClose: () => void;
}

const InviteToGroupModal: React.FC<Props> = ({ moduleId, requestClose }) => {
  const { t } = useTranslation();

  const { isInitialized, requestSubmit, formContext } =
    useInviteToGroupForm(moduleId);
  const {
    watch,
    control,
    formState: { isValid },
  } = formContext;
  const { roles = [] } = watch('options') || {};

  if (!isInitialized) {
    return null;
  }

  return (
    <FormProvider {...formContext}>
      <Modal
        isOpen
        showFooterBorder
        showHeaderBorder
        layoutClassName={s['modal']}
        heading={t('groups.invite.popup.title')}
        onRequestClose={requestClose}
        footer={
          <div className={s['footer']}>
            <TextButton
              key="cancel"
              variant="main"
              size="large"
              onClick={requestClose}
            >
              {t('common.cancel')}
            </TextButton>
            <BrandedButton
              key="confirm"
              variant="brand-green"
              size="large"
              disabled={!isValid}
              onClick={requestSubmit}
            >
              {t('groups.invite.popup.button.accept')}
            </BrandedButton>
          </div>
        }
      >
        <div className={s['root']}>
          <UserInput
            name="user"
            label={t('groups.invite.popup.fields.username.label')}
            placeholder={t('groups.invite.popup.fields.username.placeholder')}
            isRequired
          />

          <SelectField
            name="roleid"
            withBlankOption={false}
            noBottomSpacing={false}
            label={t('groups.invite.popup.fields.role.label')}
            items={roles.map(({ roleId: value, rolelabel: label }) => ({
              value,
              label,
            }))}
          />

          <TextArea
            name="note"
            label={t('groups.invite.popup.fields.note.label')}
            placeholder={t('groups.invite.popup.fields.note.placeholder')}
            rows={5}
          />
          <HookFormDevtools control={control} />
        </div>
      </Modal>
    </FormProvider>
  );
};
InviteToGroupModal.displayName = 'InviteToGroupModal';

export default InviteToGroupModal;
