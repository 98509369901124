import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { BiData, TierAddNewBiEvent } from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import { useCurrentPromo } from '@wix/da-shared-react/pkg/utils/hooks/useCurrentPromo';
import TierCreatorReduxModule from '@wix/da-shared-react/pkg/Tier/redux/creator/ModuleLoadable';
import { useVerification } from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import BuyCoreBrandedButton from '@wix/da-shared-react/pkg/Button/core/dsPresets/BuyCoreBrandedButton';
import EmptyState from '../../../EmptyState';
import SubscriptionBanner from './SubscriptionBanner';

import s from './SubscriptionsCoreOnlyBlock.scss';

export interface Props {
  className?: string;
  hasCore: boolean;
  createTier: () => void;
  createTipJar: () => void;
}

const SubscriptionsCoreOnlyBlock: React.FC<Props> = ({
  className,
  hasCore,
  createTier,
  createTipJar,
}) => {
  const currentPromo = useCurrentPromo('self');
  const { t } = useTranslation();

  // users who are gifted core can see these buttons even before they're verified
  const { restrictToVerified } = useVerification();
  const restrictedCreateTier = restrictToVerified(
    'createsubscription',
    createTier
  );
  const restrictedCreateTipJar = restrictToVerified(
    'createsubscription',
    createTipJar
  );

  const tierBiData = BiData<TierAddNewBiEvent>({
    evid: 530,
    is_empty_state_view: 1,
    widgetname: undefined,
  });
  const tipjarBiData = {
    ...tierBiData,
    is_tip_jar: 1,
  };

  const coreStatus = hasCore ? 'hasCore' : 'noCore';
  return (
    <div className={s['root']}>
      <TierCreatorReduxModule />
      {!hasCore && !currentPromo && (
        <SubscriptionBanner
          className={s['promo-strip']}
          onCtaClick={restrictedCreateTipJar}
          ctaBiData={tipjarBiData}
        />
      )}
      <EmptyState
        className={c(s['empty-state-block'], className)}
        title={t(
          `page.subscriptions.coreOnlyBlock.title.var.${coreStatus}.updatedTiers`
        )}
        subtitle={
          <div className={s['subtitle-wrapper']}>
            <div className={s['subtitle']}>
              {t(
                `page.subscriptions.coreOnlyBlock.subtitle.var.${coreStatus}.updatedTiers`
              )}
            </div>
            {hasCore && (
              <TextButton
                size="medium"
                variant="main"
                href={t('widgets.tierContent.learnMore.url')}
              >
                {t('page.subscriptions.coreOnlyBlock.CTAbutton.learnMore')}
              </TextButton>
            )}
          </div>
        }
        illustration={<div className={s['illustration']} />}
        withIllustrationBelow
        customButton={
          hasCore ? (
            <SolidButton
              className={s['button']}
              variant="approval-green"
              size="medium"
              priority="primary"
              onClick={restrictedCreateTier}
              biData={tierBiData}
            >
              {t('page.subscriptions.coreOnlyBlock.CTAbutton.createTier')}
            </SolidButton>
          ) : (
            <BuyCoreBrandedButton
              className={s['button']}
              size="medium"
              biData={
                {
                  referral_info: ReferralType.SUBSCRIPTIONS,
                } as any
              }
              withSaleSparkles
            >
              {t('page.subscriptions.coreOnlyBlock.CTAbutton.buyCore')}
            </BuyCoreBrandedButton>
          )
        }
        customSecondaryButton={
          <GhostButton
            className={s['button']}
            variant="main"
            size="medium"
            {...(hasCore
              ? {
                  onClick: restrictedCreateTipJar,
                  biData: tipjarBiData,
                  children: t(
                    'page.subscriptions.coreOnlyBlock.CTAbutton.createTipJar'
                  ),
                }
              : {
                  onClick: restrictedCreateTier,
                  biData: tierBiData,
                  children: t(
                    'page.subscriptions.coreOnlyBlock.CTAbutton.startCreatingATier'
                  ),
                })}
          />
        }
      />
    </div>
  );
};
SubscriptionsCoreOnlyBlock.displayName = 'SubscriptionsCoreOnlyBlock';

export default SubscriptionsCoreOnlyBlock;
