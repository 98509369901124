export const STREAM_PARAM_TYPE_MODULEID = 'moduleid';
export const BADGE_ACTIVITY_STREAM = 'BADGE_ACTIVITY_STREAM';
export const BADGE_ACTIVITY_STREAM_ITEMS_PER_FETCH = 24;

export const MY_COMMENTS_STREAM = 'MY_COMMENTS_STREAM';
export const MY_COMMENTS_STREAM_ITEMS_PER_FETCH = 5;

export const WATCHER_IDS_STREAM = 'WATCHER_IDS_STREAM';
export const WATCHING_IDS_STREAM = 'WATCHING_IDS_STREAM';
export const SUPPORTERS_IDS_STREAM = 'SUPPORTERS_IDS_STREAM';
export const USERS_PER_FETCH = 24;

export const GROUP_MEMBERS_IDS_STREAM = 'GROUP_MEMBERS_IDS_STREAM';
export const GROUP_NEWEST_MEMBERS_IDS_STREAM =
  'GROUP_NEWEST_MEMBERS_IDS_STREAM';
export const GROUP_ACTIVE_MEMBERS_IDS_STREAM =
  'GROUP_ACTIVE_MEMBERS_IDS_STREAM';
export const MESSAGES_PER_FETCH = 24;
export const GROUP_MESSAGELOG_IDS_STREAM = 'GROUP_MESSAGELOG_IDS_STREAM';
export const GROUP_ACTIONLOG_IDS_STREAM = 'GROUP_ACTIONLOG_IDS_STREAM';

export const GROUPS_ADMIN_IDS_STREAM = 'GROUPS_ADMIN_IDS_STREAM';
export const GROUPS_MEMBER_IDS_STREAM = 'GROUPS_MEMBER_IDS_STREAM';
export const GROUPS_PER_FETCH = 24;

export const GALLERY_FOLDER_IDS_STREAM = 'GALLERY_FOLDER_IDS_STREAM';
export const COLLECTION_FOLDER_IDS_STREAM = 'COLLECTION_FOLDER_IDS_STREAM';
export const PRIVATE_COLLECTION_FOLDER_IDS_STREAM =
  'PRIVATE_COLLECTION_FOLDER_IDS_STREAM';
export const GALLECTION_FOLDERS_PER_FETCH = 50;

export const BADGES_STREAM = 'BADGES_STREAM';
export const BADGES_STREAM_ITEMS_PER_FETCH = 30;

export const TIERS_STREAM = 'TIERS_STREAM';
