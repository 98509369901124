import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { PapiGallection, PapiPremiumFolderData } from '@wix/da-papi-types';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import EyeDisc2 from '@wix/da-ds/pkg/Icons/24x24/EyeDisc2';
import PremiumGallery from '@wix/da-ds/pkg/Icons/24x24/PremiumGallery';
import Button from '@wix/da-shared-react/pkg/Button';
import WatchButton from '@wix/da-shared-react/pkg/Button/ProfileOwnerWatchButton';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import type { BiEvent } from '@wix/da-bi/pkg/events';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import { useRestrictToLoggedIn } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictToLoggedIn';
import { LoggedOutReturnType } from '@wix/da-shared-react/pkg/redux/loggedout';
import FolderPaywallLayout from './FolderPaywallLayout';

import s from './FolderPaywall.scss';

export interface Props {
  folder: PapiGallection;
  premiumFolderData: PapiPremiumFolderData;
  biData?: BiEvent;
  isOwner: boolean;
}

const FolderPaywall: React.FC<Props> = ({
  folder,
  biData,
  premiumFolderData,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { restrictToLoggedIn } = useRestrictToLoggedIn();

  const { formattedDollarPrice, isPaidFolder, isWatchToAccess, subproductId } =
    normalizePremiumFolderData(folder.premiumFolderData);
  const contentOwner = folder.owner;
  const checkoutLink = subproductId
    ? Url.checkoutLink(subproductId)
    : undefined;

  if (isPaidFolder) {
    return (
      <FolderPaywallLayout
        premiumFolderData={premiumFolderData}
        folder={folder}
        icon={<PremiumGallery size={IconSize.MEDIUM} />}
        button={
          <Button
            view="b18"
            fullWidth={isMobile}
            onClick={restrictToLoggedIn(
              ReferralType.SHOP_PREMIUM_GALLERIES,
              () => {
                if (checkoutLink) window.location.href = checkoutLink;
              },
              checkoutLink,
              LoggedOutReturnType.JOIN_ONLY
            )}
            biData={biData}
          >
            <span className={s['buttonLabel']}>
              {t('paywall.premium.folder.unlockFor')} {formattedDollarPrice}
            </span>
          </Button>
        }
      />
    );
  }

  if (isWatchToAccess) {
    return (
      <FolderPaywallLayout
        premiumFolderData={premiumFolderData}
        folder={folder}
        icon={<EyeDisc2 size={IconSize.MEDIUM} />}
        button={
          <WatchButton
            user={contentOwner!}
            onToggleWatchSuccess={() => {
              window.location.href = premiumFolderData.galleryUrl!;
            }}
          />
        }
      />
    );
  }

  return null;
};

FolderPaywall.displayName = 'FolderPaywall';

export default FolderPaywall;
