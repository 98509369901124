import { useCallback, useEffect, useState } from 'react';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import { useForm } from 'react-hook-form';
import useModuleConfig from '@wix/da-gruser-shared/pkg/hooks/useModuleConfig';
import { useEffectOnceOnMount } from '@wix/da-hooks/pkg/useEffectOnceOnMount';
import yupResolver from '@wix/da-shared-react/pkg/utils/yupResolver';
import { FormValues } from './types';
import { configAdapter, submitAdapter } from './adapters';
import { validationSchema } from './validation';

import {
  inviteModuleSaved,
  inviteModuleInitialized,
} from '../../../../actions/modules';

// Get module config from the module endpoint
// configAdapter: Transforms the config data into form data
// submitAdapter: Transforms the form data into request data
// validate: Validates form values, returns errors on failure
export function useInviteToGroupForm(moduleId: number) {
  const [renderVersion, setRenderVersion] = useState<number>();
  const {
    moduleConfig,
    resetConfig,
    fetchModuleConfig,
    error: configError,
    isSaving,
    hasJustFinishedFetching,
    hasJustFinishedSaving,
    saveModuleConfig,
  } = useModuleConfig(moduleId, 'recruitment/invite'); // use recruitment/invite/config and recruitment/invite/save

  const onConfigFetched = useAction(inviteModuleInitialized);
  const onConfigSaved = useAction(inviteModuleSaved);

  const { config } = moduleConfig;
  const isInitialized = !!config;

  const formContext = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver:
      validationSchema &&
      yupResolver(validationSchema, {
        abortEarly: false,
      }),
    defaultValues: configAdapter(moduleConfig) as any,
  });
  const { reset, register, handleSubmit, errors, setError } = formContext;

  useEffect(() => {
    // register non-form field "options"
    register('options');
  }, [register]);

  // Set server errors to form error
  const hasError = configError || Object.keys(errors).length > 0;
  useEffect(() => {
    if (configError) {
      setError('server', { type: 'server', message: configError });
    }
  }, [setError, configError]);

  // Fetch config on initial mount
  useEffectOnceOnMount(() => {
    fetchModuleConfig();
  });

  // Trigger optional success callbacks
  useEffect(() => {
    if (hasJustFinishedFetching) {
      onConfigFetched();
    }
  }, [hasJustFinishedFetching, onConfigFetched]);

  // Trigger optional success callbacks
  useEffect(() => {
    if (hasJustFinishedSaving && !hasError) {
      onConfigSaved();

      // this clears the config endpoint data from the store, forcing a refetch
      // otherwise this form could only be loaded once per page load
      resetConfig();
    }
  }, [hasJustFinishedSaving, hasError, resetConfig, onConfigSaved]);

  // If moduleConfig changed, reset the form with the new values
  useEffect(() => {
    if (moduleConfig?.config && moduleConfig.config.version !== renderVersion) {
      // Transform the module config and options through a configAdapter into
      reset(configAdapter(moduleConfig) as any);
      // Trigger re-render if the version changed
      setRenderVersion(moduleConfig.config.version);
    }
  }, [reset, moduleConfig, renderVersion]);

  // Start saving config
  const requestSubmit = useCallback(() => {
    if (isSaving) return;
    handleSubmit(data => {
      saveModuleConfig(submitAdapter(data as any));
    })();
  }, [isSaving, handleSubmit, saveModuleConfig]);

  return {
    formContext,
    requestSubmit,
    moduleConfig,
    isInitialized,
    errors,
  };
}

export default useInviteToGroupForm;
