import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import LlamaFarmBox from './LlamaFarmBox';
import LlamaFarmConfigModalLazy from './LlamaFarmConfigModal/LlamaFarmConfigModalLazy';

export const getLlamaFarmWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: LlamaFarmBox,
  configPopoverComponent: LlamaFarmConfigModalLazy,
  autoShowWidgetConfig: true,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-23-llama-farm.svg',
    title: t('widgets.llamafarm.addButtonHeader'),
    description: t('widgets.llamafarm.description'),
  },
});
