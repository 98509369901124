import React from 'react';
import c from 'classnames';
import s from './TipText.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

/** Text styled to how we want for the tip content. */
const TipText: React.FC<Props> = ({ children, className }) => {
  return <div className={c(s['root'], className)}>{children}</div>;
};
TipText.displayName = 'TipText';

export default TipText;
