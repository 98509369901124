import React, { useRef, HTMLAttributes } from 'react';
import { useCollapsingElements } from '../useCollapsingElements';
import { CollapsingElement, CollapsingElementId } from '../types';
import c from 'classnames';
import s from './CollapsingElementsContainer.scss';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  collapsingElements: CollapsingElement[];
  justifyContent?: 'flex-start' | 'flex-end';
  onCollapsedIdsChange?: (collapsedIds: Array<CollapsingElementId>) => void;
}

const CollapsingElementsContainer: React.FC<Props> = ({
  collapsingElements,
  justifyContent = 'flex-start',
  className,
  children,
  onCollapsedIdsChange,
  ...rest
}) => {
  const fullWidthContainerRef = useRef<HTMLDivElement | null>(null);
  const contentContainerRef = useRef<HTMLDivElement | null>(null);

  useCollapsingElements(collapsingElements, {
    fullWidthContainerRef,
    contentContainerRef,
    onCollapsedIdsChange,
  });

  return (
    <div
      ref={fullWidthContainerRef}
      className={c(
        s['full-width-container'],
        justifyContent && s[`justify-content-${justifyContent}`],
        className
      )}
      {...rest}
    >
      <div ref={contentContainerRef} className={s['inner-content-container']}>
        {children}
      </div>
    </div>
  );
};
CollapsingElementsContainer.displayName = 'CollapsingElementsContainer';

export default CollapsingElementsContainer;
