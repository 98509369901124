import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import BadgesSpotlightBox from './BadgesSpotlightBox';
import BadgesSpotlightConfigModalLazy from './BadgesSpotlightConfigModal/BadgesSpotlightConfigModalLazy';

export const getBadgesSpotlightConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: BadgesSpotlightBox,
  configPopoverComponent: BadgesSpotlightConfigModalLazy,
  autoShowWidgetConfig: true,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-2-badges.svg',
    title: t('widgets.badgesSpotlight.addButtonHeader'),
    description: t('widgets.badgesSpotlight.description'),
  },
});
