import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { PapiModuleGallectionFolders } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { GALLECTION_FOLDERS_PER_FETCH } from '../../../../../../streams';
import {
  getStreamIdForGallectionType,
  normalizeGallectionList,
} from '../../../../../../utils/gallection';
import { GallectionSourceType } from '../../../../../../../types/gallection';
import { ALL_FOLDER_ID, SCRAPS_FOLDER_ID } from '@wix/da-url';
import { EmptyCollectionsFolderException } from '../../../../../../utils/exceptions';
import { getDefaultFolderFromFolders } from '../../../helpers';
import {
  setFullViewFolderIds,
  setSelectedFolderId,
  setSelectedSubfolderId,
} from '../../../../../../actions/gallectionSection';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleGallectionFolders,
  requestParams: any
) => {
  const gallectionType =
    requestParams.gallectionType ??
    (moduleData?.results?.[0]?.type as GallectionSourceType);

  if (!gallectionType || !moduleData) {
    return [];
  }

  const { results: folders = [] } = moduleData;

  let initFolderId;
  let initSubfolderId;
  if (requestParams.all_folder) {
    initFolderId = ALL_FOLDER_ID;
  } else if (requestParams.scraps_folder) {
    initFolderId = SCRAPS_FOLDER_ID;
  } else if (requestParams.folder_id) {
    initFolderId = +requestParams.folder_id;
    const initFolder = folders
      .flatMap(folder => [folder, ...(folder.subfolders ?? [])])
      .find(folder => folder.folderId === initFolderId);

    if (!initFolder) {
      throw new EmptyCollectionsFolderException('');
    } else if (initFolder.parentId) {
      initFolderId = initFolder.parentId;
      initSubfolderId = initFolder.folderId;
    }
  } else {
    initFolderId = getDefaultFolderFromFolders(folders)?.folderId;
  }

  const { result, entities } = normalizeGallectionList(folders, gallectionType);

  return [
    setSelectedFolderId(initFolderId),
    setSelectedSubfolderId(initSubfolderId),
    setFullViewFolderIds([initFolderId]),
    withOffset.actions.initialize({
      streamId: getStreamIdForGallectionType(gallectionType),
      streamParams: {
        moduleId: module.id,
        gallectionType,
      },
      itemsPerFetch: GALLECTION_FOLDERS_PER_FETCH,
      items: result,
      entities,
      hasMore: moduleData.hasMore,
      nextOffset: moduleData.nextOffset ?? undefined,
      hasLess: moduleData.hasLess,
      prevOffset: moduleData.prevOffset,
    }),
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ] as any;
};
