import { all, put, select, takeEvery } from 'redux-saga/effects';
import history from '@wix/da-shared-react/pkg/redux/routing/history';
import { appMounted } from '@wix/da-shared-react/pkg/redux/app/actions';
import {
  requestCloseGroup,
  requestFounderLeaveGroup,
  inviteToGroup,
} from '../actions/groups';
import { getIsGroupProfile, getProfileOwnerUser } from '../selectors/users';

function* handleQueryParamTriggersSaga(action) {
  if (typeof window === 'undefined') {
    return;
  }
  const profileOwnerUser = yield select(getProfileOwnerUser);
  const isGroup = yield select(getIsGroupProfile);

  if (isGroup) {
    const params = new URLSearchParams(window.location.search);
    const flow = params.get('flow');
    switch (flow) {
      case 'founder-leave':
        yield put(requestFounderLeaveGroup(profileOwnerUser));
        break;

      case 'invite':
        yield put(inviteToGroup(profileOwnerUser));
        break;

      case 'close':
        yield put(requestCloseGroup(profileOwnerUser));
        break;

      default:
      // noop
    }
    if (flow) {
      // Remove the flow param from the URL
      params.delete('flow');
      history.replace({
        ...history.location,
        search: params.toString(),
      });
    }
  }
}

export default function* saga() {
  yield all([takeEvery(appMounted, handleQueryParamTriggersSaga)]);
}
