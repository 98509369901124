import {
  PostsType,
  PostsSortOrder,
  JournalFilterType,
} from '../../types/posts';

export const POSTS_DEVIATIONS_STREAM_GROUP_ID = 'posts';
export const FEATURED_POSTS_IDS_STREAM = 'FEATURED_POSTS_IDS_STREAM';

export const POSTS_PER_FETCH = 5;
export const FULLTEXT_JOURNALS_PER_FETCH = 5;
export const FEED_COMMENTS_MAX_DEPTH = 1;
export const FEED_COMMENTS_ITEMS_PER_FETCH = 20;

export const getPostsDeviationsStreamId = ({
  type,
  filter = JournalFilterType.ALL,
  sortOrder = PostsSortOrder.NEWEST,
}: {
  type: PostsType;
  filter?: JournalFilterType;
  sortOrder?: PostsSortOrder;
}): string =>
  [POSTS_DEVIATIONS_STREAM_GROUP_ID, filter, type, sortOrder, 'ids_stream']
    .filter(value => value)
    .join('_')
    .toUpperCase();
