import { createSelector } from 'reselect';
import { getStreamParams } from '@wix/da-shared-react/pkg/Stream/base/selectors';
import {
  MessageLogStreamParams,
  ActionLogStreamParams,
} from '../../types/groupsBackroom';
import {
  GROUP_MESSAGELOG_IDS_STREAM,
  GROUP_ACTIONLOG_IDS_STREAM,
} from '../streams';

const messageLogParamDefaults: MessageLogStreamParams = {
  sortBy: 'newest',
};

const actionLogParamDefaults: ActionLogStreamParams = {
  sortValue: 'newest',
};

export const getMessageLogParams = createSelector(
  state => getStreamParams(state, GROUP_MESSAGELOG_IDS_STREAM),
  (streamParams): MessageLogStreamParams => ({
    ...messageLogParamDefaults,
    ...(streamParams || {}),
  })
);

export const getActionLogParams = createSelector(
  state => getStreamParams(state, GROUP_ACTIONLOG_IDS_STREAM),
  (streamParams): ActionLogStreamParams => ({
    ...actionLogParamDefaults,
    ...(streamParams || {}),
  })
);
