import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../../../../selectors/users';
import { AppState } from '../../../../../../../types/store';
import { showSubmitModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { Props } from './EmptyState';
import { publishDraftTier } from '@wix/da-shared-react/pkg/Tier/redux/creator/actions';

type StateProps = Pick<Props, 'profileOwnerUser' | 'isOwner'>;
type DispatchProps = Pick<Props, 'showSubmitModal' | 'publishDraftTier'>;
type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  profileOwnerUser: getProfileOwnerUser(state),
  isOwner: isCurrentUserProfileOwner(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  showSubmitModal,
  publishDraftTier,
};

export default connect(mapStateToProps, mapDispatchToProps);
