import { put, takeEvery } from 'redux-saga/effects';
import { insertItems } from '@wix/da-shared-react/pkg/Stream/withOffset/actions';

import { createPollSuccess as createPollSuccessAction } from '@wix/da-shared-react/pkg/PollEditPopup/redux/actions';
import { PostsType } from '../../types/posts';
import { getPostsDeviationsStreamId } from '../streams/posts';

const pollsStreamId = getPostsDeviationsStreamId({
  type: PostsType.POLLS,
});

export function* createPollSuccessSaga(action) {
  const { payload } = action;
  yield put(
    insertItems({
      streamId: pollsStreamId,
      items: [payload.result],
      offset: 0,
    })
  );
}

export function* requestFailureSaga(action) {
  // eslint-disable-next-line no-console
  console.warn('\n\n!!!requestFailureSaga', action);
}

export default [takeEvery(createPollSuccessAction, createPollSuccessSaga)];
