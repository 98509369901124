import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import EmptyState from '../../../EmptyState';
import s from './SubscriptionsVisitorEmptyState.scss';

export interface Props {
  profileOwnerUser: PapiUser;
}

const SubscriptionsVisitorEmptyState: React.FC<Props> = ({
  profileOwnerUser,
}) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      view="small"
      className={s['root']}
      title={t(`widgets.tiers.empty.title.visitor`, {
        username: profileOwnerUser.username,
      })}
      subtitle={t(`widgets.tiers.empty.subtitle.visitor`, {
        username: profileOwnerUser.username,
      })}
      illustration={<div className={s['illustration']} />}
    />
  );
};
SubscriptionsVisitorEmptyState.displayName = 'SubscriptionsVisitorEmptyState';

export default SubscriptionsVisitorEmptyState;
