import React, { useState, useCallback } from 'react';
import { PapiUser } from '@wix/da-papi-types';
import { useTranslation } from 'react-i18next';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import Modal from '@wix/da-ds/pkg/modals/dsPresets/Modal';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import BrandedButton from '@wix/da-ds/pkg/Buttons/BrandedButton';
import SearchIcon from '@wix/da-ds/pkg/Icons/24x24/Search';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import FormElement from '@wix/da-ds/pkg/formControls/layouts/FormElement';
import InputLabelText from '@wix/da-ds/pkg/formControls/lego/InputLabelText';
import UserInput from '@wix/da-shared-react/pkg/Chat/UserInput';

import { useGroupChangeFounder } from './hooks/useGroupChangeFounder';
import s from './GroupChangeFounderModal.scss';

export interface Props {
  moduleId: number;
  requestClose: () => void;
}

const GroupChangeFounderModal: React.FC<Props> = ({
  moduleId,
  requestClose,
}) => {
  const { t } = useTranslation();
  const inputId = useUniqueId();
  const [user, setUser] = useState<PapiUser>();
  const { config, options, isSaving, requestSave } =
    useGroupChangeFounder(moduleId);

  const handleSubmit = useCallback(
    () => requestSave({ user }),
    [requestSave, user]
  );

  const candidates = options?.candidates;

  if (!config) {
    return null;
  }

  return (
    <Modal
      isOpen
      showFooterBorder
      showHeaderBorder
      heading={t('groups.leave.founder.popup.title')}
      onRequestClose={requestClose}
      footer={
        <div className={s['footer']}>
          <TextButton
            key="cancel"
            variant="main"
            size="large"
            onClick={requestClose}
          >
            {t('common.cancel')}
          </TextButton>
          <BrandedButton
            key="confirm"
            variant="brand-green"
            size="large"
            disabled={!user || isSaving}
            onClick={handleSubmit}
          >
            {t('common.confirm')}
          </BrandedButton>
        </div>
      }
    >
      <div className={s['root']}>
        <div className={s['body']}>{t('groups.leave.founder.popup.body')}</div>
        <FormElement
          inputId={inputId}
          label={
            <InputLabelText isRequired={true}>
              {t('groups.leave.founder.user.label')}
            </InputLabelText>
          }
        >
          <UserInput
            prefix={<SearchIcon size={IconSize.SMALL} />}
            users={user ? [user] : []}
            candidates={candidates}
            onAddUser={userToAdd => setUser(userToAdd)}
            onRemoveUser={_ => setUser(undefined)}
            onRemoveAll={() => setUser(undefined)}
          />
        </FormElement>
      </div>
    </Modal>
  );
};
GroupChangeFounderModal.displayName = 'GroupChangeFounderModal';

export default GroupChangeFounderModal;
