import { PapiUser } from '@wix/da-papi-types';
import { createAction } from 'redux-actions';

export interface InitializePagePayload {
  canCreate: boolean;
}
export const initializePage = createAction(
  'GROUP_CREATION.INITIALIZE_PAGE',
  (payload: InitializePagePayload) => payload
);

export interface CreateGroupPayload {
  group_name: string;
  group_description: string;
}
export const createGroup = createAction(
  'GROUP_CREATION.CREATE_GROUP',
  (payload: CreateGroupPayload) => payload
);

export const groupCreationSuccess = createAction(
  'GROUP_CREATION.GROUP_CREATION_SUCCESS',
  (payload: PapiUser) => payload
);

export interface FormError {
  field?: keyof CreateGroupPayload;
  errorMessage: string;
}
export const groupCreationFailure = createAction(
  'GROUP_CREATION.GROUP_CREATION_FAILURE',
  (payload: FormError[]) => payload
);
