import React from 'react';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import ReduxModuleLoader from '@wix/da-feedback-shared/pkg/redux/ModuleLoaderLazy';
import Loader from '@wix/da-ds/pkg/Loader';

import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';

import MessageLog from './MessageLog';
import FilterBar from './FilterBar';
import OrderDropdown from './FilterBar/OrderDropdown';

import s from './MessageLog.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const MessageLogBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  return (
    <WidgetBox
      moduleId={moduleId}
      header={<FilterBar moduleId={moduleId} />}
      headerExtras={<OrderDropdown />}
      {...widgetBoxProps}
    >
      <ErrorBoundary debugComponent="MessageLogBox">
        <React.Suspense
          fallback={
            <Loader state="eating_grass" size="big" className={s['loader']} />
          }
        >
          <ReduxModuleLoader />
          <MessageLog moduleId={moduleId} />
        </React.Suspense>
      </ErrorBoundary>
    </WidgetBox>
  );
};
MessageLogBox.displayName = 'MessageLogBox';

export default MessageLogBox;
