import React from 'react';
import classnames from 'classnames';
import { compose } from 'redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Url } from '@wix/da-url';
import { PapiUser } from '@wix/da-papi-types';
import {
  withVerification,
  WithVerificationProps,
} from '@wix/da-shared-react/pkg/VerificationPopup/withVerification';
import { BiEvent } from '@wix/da-bi/pkg/events';
import {
  withMobileContext,
  WithMobileContextProps,
} from '@wix/da-react-context/pkg/MobileContext';
import { GallectionType } from '../../../../../../types/gallection';
import { ShopItemType } from '../../../../../../types/shop';
import EmptyState from '../../../../EmptyState';
import camelCase from 'lodash/camelCase';

import s from './EmptyState.scss';

const enhance = compose(
  withMobileContext,
  withVerification,
  withRouter,
  withTranslation()
);

export interface Props {
  title?: string;
  subtitle?: string;
  view?: 'large' | 'wide';
  shopItemType?: ShopItemType;
  isOwner: boolean;
  user: PapiUser;
  buttonLabel?: string;
  onButtonClick?: (ev?: React.MouseEvent) => void;
  biData?: BiEvent;
  onOpenChat: (user: PapiUser) => void;
}

export class ShopEmptyState extends React.Component<
  Props &
    WithTranslation &
    RouteComponentProps<'history'> &
    WithVerificationProps &
    WithMobileContextProps
> {
  render() {
    const {
      shopItemType = 'all',
      view,
      title,
      subtitle,
      isOwner,
      isMobile,
      user: { username },
      buttonLabel,
      onButtonClick,
      biData,
      t,
    } = this.props;

    const shopItemTypeTranslationKey = camelCase(shopItemType);
    const translationMode = isOwner
      ? isMobile
        ? 'owner.mobile'
        : 'owner'
      : 'visitor';

    return (
      <EmptyState
        className={classnames(s['root'], view === 'large' && s['large'])}
        titleClassName={s['title']}
        subtitleClassName={s['subtitle']}
        view={view}
        title={
          title ||
          t!(
            `pages.shop.emptyState.${shopItemTypeTranslationKey}.title.${translationMode}`,
            {
              username,
            }
          )
        }
        subtitle={
          subtitle ||
          t!(
            `pages.shop.emptyState.${shopItemTypeTranslationKey}.subtitle.${translationMode}`
          )
        }
        buttonLabel={
          buttonLabel ||
          (isOwner
            ? t!('pages.shop.emptyState.goToGallery')
            : t!('pages.shop.emptyState.openChatWithArtist'))
        }
        onButtonClick={
          onButtonClick ||
          (isOwner ? this.navigateToGallery : this.handleOpenChat)
        }
        withButton
        biData={biData}
      />
    );
  }

  navigateToGallery = () => {
    const {
      history,
      user: { username },
    } = this.props;
    const galleryPath = Url.userGalleryPath({
      username,
      gallectionType: GallectionType.GALLERY,
    });
    history.push(galleryPath);
  };

  navigateToNotes = () => {
    const {
      user: { username },
      isVerificationNeeded,
      pushVerificationPopup,
    } = this.props;
    if (isVerificationNeeded) {
      pushVerificationPopup('notes');
      return;
    }

    const notesPath = Url.userNoteLink(username);
    window.location.assign(notesPath);
  };

  handleOpenChat = () => {
    const { user, onOpenChat } = this.props;
    onOpenChat(user);
  };
}

export default enhance(ShopEmptyState) as React.ComponentType<Props>;
