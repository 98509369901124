import React from 'react';
import { Url } from '@wix/da-url';
import { useTranslation, Trans } from 'react-i18next';
import { PapiUser, PapiDeviation } from '@wix/da-papi-types';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import PointsIcon from '@wix/da-ds/pkg/Icons/Points';
import Button from '@wix/da-shared-react/pkg/Button';
import WatchButton from '@wix/da-shared-react/pkg/Button/ProfileOwnerWatchButton';
import BasePaywall from '../BasePaywall';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';

import s from './DeviationPaywall.scss';

export interface Props {
  isLiterature?: boolean;
  premiumFolderData?: PapiDeviation['premiumFolderData'];
  contentOwner: PapiUser;
  folderId?: number;
  biData?: any;
  children: React.ReactNode;
}

const DeviationPaywall: React.FC<Props> = ({
  contentOwner,
  isLiterature,
  premiumFolderData,
  biData,
  children,
}) => {
  const { t } = useTranslation();
  const { type, hasAccess, pointsPrice, galleryUrl, subproductId } =
    premiumFolderData || {};

  const basePaywallProps = {
    isLiterature,
    hasAccess: !!hasAccess,
  };

  const { formattedDollarPrice } =
    normalizePremiumFolderData(premiumFolderData);

  return (
    <>
      {type === 'paid' && (
        <BasePaywall
          {...basePaywallProps}
          icon={<div className={s['illustration-pcp']} />}
          title={t('paywall.premium.deviation.title')}
          text={
            // prettier-ignore
            <Trans i18nKey="paywall.premium.deviation.text">
              To view, unlock its <a className={s['folder-link']} href={galleryUrl}>Premium Folder</a> - and
              support the artist!
            </Trans>
          }
          button={
            <Button
              view="b18"
              fullWidth={false}
              href={subproductId ? Url.checkoutLink(subproductId) : undefined}
              biData={biData}
            >
              <span className={s['buttonLabel']}>
                {t('paywall.premium.deviation.unlockFor')}{' '}
                {formattedDollarPrice}
                {pointsPrice !== undefined && pointsPrice < 80 && (
                  <>
                    {' / '}
                    <PointsIcon size={IconSize.TINY} disableDefaultColors />
                    {pointsPrice}
                  </>
                )}
              </span>
            </Button>
          }
        >
          {children}
        </BasePaywall>
      )}
      {type === 'watchers' && (
        <BasePaywall
          {...basePaywallProps}
          isLiterature={isLiterature}
          hasAccess={!!hasAccess}
          icon={<div className={s['illustration-watchers']} />}
          title={t('paywall.watchersOnly.deviation.title')}
          text={t('paywall.watchersOnly.deviation.text')}
          button={<WatchButton user={contentOwner!} />}
        >
          {children}
        </BasePaywall>
      )}
      {!type && children}
    </>
  );
};
DeviationPaywall.displayName = 'DeviationPaywall';

export default DeviationPaywall;
