import React from 'react';
import classnames from 'classnames';
import CommentThread from '@wix/da-shared-react/pkg/CommentThread';
import { CommentableTypes } from '@wix/da-shared-react/pkg/Comment/redux/types';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { CommentFeaturesContextProvider } from '@wix/da-shared-react/pkg/Comment/CommentFeaturesContext';

import useInitThread from './useInitThread';

import s from './GroupNotesThread.scss';

interface Props {
  className?: string;
  noteId: number;
}

export const GroupNotesThread: React.FC<Props> = ({ className, noteId }) => {
  const { isFetching } = useInitThread(noteId);
  if (isFetching) {
    return <LoadingIndicator />;
  }
  return (
    <div className={classnames(s['root'], className)}>
      <ErrorBoundary debugComponent="GroupNotesComments">
        <CommentFeaturesContextProvider
          value={{ showLikeButton: false, showAwardBadgeButton: false }}
        >
          <CommentThread
            className={s['thread']}
            commentableTypeId={CommentableTypes.COMMENT_THREADNOTE}
            commentableItemId={noteId}
            allowSelfInit={true}
            statsAndControls={'off'}
            postbox="none"
          />
        </CommentFeaturesContextProvider>
      </ErrorBoundary>
    </div>
  );
};
GroupNotesThread.displayName = 'GroupNotesThread';

export default GroupNotesThread;
