import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { PapiGroupnote } from '../../../../../../types/papi';
import Checkbox from '@wix/da-ds/pkg/Checkbox';
import ListItemContent from '@wix/da-ds/pkg/List/ListItem/legos/ListItemContent';
import LegacyTextRenderer from '@wix/da-shared-react/pkg/TextRenderer/LegacyTextRenderer';
import UserAvatar from '@wix/da-shared-react/pkg/User/Avatar';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import Timestamp from '@wix/da-shared-react/pkg/Timestamp';

import GroupNotesListItemActions from '../GroupNotesListItemActions';

import s from './GroupNotesListItem.scss';

interface Props {
  selected?: boolean;
  active?: boolean;
  item: PapiGroupnote;
  onChange?: (isChecked: boolean) => void;
  onClick?: () => void;
}

const GroupNotesListItem: React.FC<Props> = ({
  onChange,
  onClick,
  item,
  active,
  selected,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classnames(s['root'], active && s['active'])}
      onClick={e => e.target === e.currentTarget && onClick?.()}
    >
      <Checkbox
        onChange={onChange}
        className={s['checkbox']}
        isChecked={selected}
      />
      <div className={s['item-content']} onClick={onClick}>
        <ListItemContent
          label={<span className={s['pointer']}>{item.subject}</span>}
          bottomSlot={
            <span className={s['pointer']}>
              <LegacyTextRenderer
                className={s['excerpt']}
                html={item.body}
                theme="default"
              />
            </span>
          }
          subLabel={
            <div>
              {t('common.from')}{' '}
              <UserLink user={item.sender} className={s['username']} />{' '}
              <Timestamp timestamp={new Date(item.ts).toISOString()} />
            </div>
          }
          suffix={<GroupNotesListItemActions item={item} />}
          prefix={
            <UserAvatar
              className={s['avatar']}
              user={item.sender}
              size={32}
              withLink={false}
            />
          }
        />
      </div>
    </div>
  );
};
GroupNotesListItem.displayName = 'GroupNotesListItem';

export default GroupNotesListItem;
