import { handleActions } from 'redux-actions';
import { CustomBoxTemplatesState } from '../../types/customBoxTemplates';
import {
  clearCachedChosenTemplate,
  cacheChosenTemplate,
} from '../actions/customSectionTemplates';

export default handleActions<CustomBoxTemplatesState, any>(
  {
    [`${cacheChosenTemplate}`]: (
      state,
      action: ReturnType<typeof cacheChosenTemplate>
    ) => ({
      ...state,
      chosenTemplate: action.payload.template,
    }),
    [`${clearCachedChosenTemplate}`]: () => ({}),
  },
  {}
);
