import { connect, MapStateToProps } from 'react-redux';
import { Url } from '@wix/da-url';
import { AppState } from '../../../../types/store';
import {
  getProfileOwnerUser,
  getProfileOwnerStats,
  getProfileOwnerTagline,
  isCurrentUserProfileOwner,
} from '../../../selectors/users';
import { getCanChangeAvatar } from '../../../selectors/privs';
import { getModuleIdByModuleName } from '../../../selectors/modules';
import { Props } from './ProfileIdentity';
import { ModuleType } from '../../../../types/modules';

export type StateProps = Pick<
  Props,
  | 'profileOwnerUser'
  | 'tagline'
  | 'url'
  | 'stats'
  | 'aboutModuleId'
  | 'isOwner'
  | 'canChangeAvatar'
>;
export type OwnProps = Omit<Props, keyof StateProps>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const profileOwnerUser = getProfileOwnerUser(state);
  return {
    profileOwnerUser,
    tagline: getProfileOwnerTagline(state) || '',
    stats: getProfileOwnerStats(state),
    isOwner: isCurrentUserProfileOwner(state),
    url: Url.userLink(profileOwnerUser.username),
    aboutModuleId: getModuleIdByModuleName(state, ModuleType.ABOUT),
    canChangeAvatar: getCanChangeAvatar(state),
  };
};

export default connect(mapStateToProps);
