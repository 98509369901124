import React, { useContext } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import { PapiGallection, PapiPremiumFolderData } from '@wix/da-papi-types';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import DeviationDumbView from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import ShareIcon from '@wix/da-ds/pkg/Icons/24x24/Share';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { ScreenSizeContext } from '@wix/da-hooks/pkg/useMeasureElement/ScreenSizeContext';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import BiButton from '@wix/da-bi/pkg/Components/BiButton';
import PopoverWithTooltip from '@wix/da-shared-react/pkg/Popover/Preset/PopoverWithTooltip';
import { DesktopSharePopup } from '@wix/da-shared-react/pkg/SharePopup';
import FolderInfo from './FolderInfo';

import s from './FolderPaywallLayout.scss';

export interface Props {
  className?: string;
  icon: React.ReactNode;
  button: React.ReactNode;
  nonClickable?: boolean;
  folder: PapiGallection;
  premiumFolderData: PapiPremiumFolderData;
  onClick: (url: string, title: string) => void;
}

const FolderPaywallLayout: React.FC<Props> = ({
  icon,
  button,
  nonClickable = false,
  folder,
  premiumFolderData,
  onClick,
}) => {
  const { t } = useTranslation();
  const {
    formattedDollarPrice,
    numSubscribers,
    isPaidFolder,
    isWatchToAccess,
  } = normalizePremiumFolderData(folder.premiumFolderData);
  const { width: screenWidth } = useContext(ScreenSizeContext);
  const isMobile = useContext(MobileContext);
  const itemCount = folder.totalItemCount ?? folder.size;
  const galleryTitleToShare = t('paywall.gallery.titleToShare', {
    title: folder.name,
    author: folder.owner.username,
  });

  const mobileSectionMargin = parseInt(s['mobile-section-margin'], 10);
  const thumb = folder.thumb ?? folder?.additionalThumbs?.[0];

  return (
    <OverlayContextProvider
      value={{
        showPremiumFolderOverlay: false,
        showPremiumFolderIcon: false,
        showThumbStatusIndicators: false,
      }}
    >
      <div className={s['root']}>
        <div className={s['cover']}>
          {thumb && (
            <>
              <div className={s['icon']}>{icon}</div>
              <DeviationDumbView
                className={s['cover-image']}
                deviation={thumb}
                width={isMobile ? screenWidth - 2 * mobileSectionMargin : 319}
              />
            </>
          )}
        </div>
        <div className={s['info-wrap']}>
          {(isPaidFolder || isWatchToAccess) && (
            <div className={s['folder-type']}>
              {t(
                isPaidFolder
                  ? 'paywall.premium.folder.title'
                  : 'paywall.watchersOnly.folder.title'
              )}
            </div>
          )}
          <div className={s['title-wrap']}>
            <div className={s['title']}>
              <div className={s['folder-name']}>{folder.name}</div>
              {!isMobile && (
                <FolderInfo
                  className={s['sub']}
                  deviationCount={itemCount}
                  numSubscribers={numSubscribers}
                />
              )}
            </div>
            {isPaidFolder && (
              <div className={s['title-price']}>{formattedDollarPrice}</div>
            )}
          </div>
          {isMobile && (
            <FolderInfo
              className={s['sub']}
              deviationCount={itemCount}
              numSubscribers={numSubscribers}
            />
          )}
          {folder.description && (
            <div className={s['text']}>{folder.description}</div>
          )}
          {(isPaidFolder || isWatchToAccess) && (
            <div className={s['tiny-text']}>
              {t(
                isPaidFolder
                  ? 'paywall.gallery.purchaseInfo'
                  : 'paywall.gallery.watchInfo'
              )}
            </div>
          )}
          <div className={s['actions-wrapper']}>
            <span
              className={c(
                s['button'],
                nonClickable && s['non-clickable-button']
              )}
            >
              {button}
            </span>
            <div className={s['actions']}>
              <PopoverWithTooltip
                tooltip={t('actionbar.share')}
                renderPopover={() => (
                  <DesktopSharePopup
                    url={premiumFolderData.galleryUrl || ''}
                    title={galleryTitleToShare}
                  />
                )}
                placement={'bottom'}
                allowMobile={false}
                isDisabled={false}
                referenceClassName={s['popover-wrapper']}
                wrapperClassName={s['root-wrapper']}
              >
                <BiButton
                  onClick={() => {
                    if (!isMobile) {
                      return;
                    }
                    onClick(
                      premiumFolderData.galleryUrl || '',
                      galleryTitleToShare
                    );
                  }}
                  className={s['actions-button']}
                >
                  <ShareIcon
                    className={s['action-icon']}
                    size={IconSize.MEDIUM}
                  />
                </BiButton>
              </PopoverWithTooltip>
            </div>
          </div>
        </div>
      </div>
    </OverlayContextProvider>
  );
};
FolderPaywallLayout.displayName = 'BasePaywall';

export default FolderPaywallLayout;
