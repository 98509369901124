import { PapiDeviation } from '@wix/da-papi-types';
import { AppState } from '../../types/store';
import MediaService from '@wix/da-shared-react/pkg/utils/DeviationMediaService';
import { getModuleDataByModuleName as getModuleDataByModuleNameNewGrusers } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';

import { getModuleDataByModuleName } from './modules';
import { ModuleType } from '../../types/modules';
import { getProfileGruser } from './users';

export const getCoverDeviationLegacy = state => {
  const coverDeviation = (
    getModuleDataByModuleName(state, ModuleType.COVER_DEVIATION) || {}
  ).coverDeviation;
  return coverDeviation;
};

export const getCoverDeviation = (
  state: AppState
): PapiDeviation | undefined => {
  // the fallback is needed for when the user XHR-switches
  // from a ported section to a non-ported one and vica-versa
  return getCoverDeviationNewGrusers(state) || getCoverDeviationLegacy(state);
};

export const getCoverDeviationNewGrusers = (
  state: AppState
): PapiDeviation | undefined => {
  const coverDeviation = (
    getModuleDataByModuleNameNewGrusers(
      state as any,
      getProfileGruser(state),
      ModuleType.COVER_DEVIATION
    ) || {}
  ).coverDeviation;

  return coverDeviation || undefined;
};

export const getCoverDeviationOffsetY = (state: AppState): number => {
  const coverDeviationidOffsetY =
    (getModuleDataByModuleName(state, ModuleType.COVER_DEVIATION) || {})
      .coverDeviationidOffsetY || 0;
  return coverDeviationidOffsetY;
};

export const getDeviationFileSrc = (
  deviation: PapiDeviation,
  fileType: string
) => {
  const deviationFile = MediaService.getDeviationFile(deviation, fileType);
  return (deviationFile && deviationFile.src) || '';
};
