import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { AppState } from '../../types/store';
import { ModulePriv, ModuleType, Modules } from '../../types/modules';

const reduxKey = 'modules';
export const getModules = (state: any): Modules => {
  if (!state[reduxKey]) {
    return {} as any;
  }
  return state[reduxKey];
};

export const findModules = (modules: Modules, predicate: (m: any) => boolean) =>
  Object.keys(modules)
    .filter(key => predicate(modules[key]))
    .map(key => modules[key]);

export const getModule = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId] || {};

export const getModuleIds = createSelector(getModules, modules =>
  Object.keys(modules)
);

export const getModuleType = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId]?.type;
export const getModuleVersion = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId]?.version;
export const getModuleData = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId]?.moduleData || {};
export const getModulePrivileges = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId]?.privileges || {};
export const getModuleMeta = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId]?.moduleMeta || {};
export const getModuleRequests = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId]?.moduleRequests || {};
export const getModuleRequestsPath = (
  state: AppState,
  moduleId: number,
  path: string
) => getModuleRequests(state, moduleId)?.[path] || {};
// We will be deprecating getModuleConfig in favour of module requests
export const getModuleConfig = (state: AppState, moduleId: number) =>
  getModules(state)[moduleId]?.moduleConfig || {};

const getModuleTypeArgument = (state: AppState, moduleType: string) =>
  moduleType;

export const getAllModulesByModuleName = createCachedSelector(
  getModules,
  getModuleTypeArgument,
  (modules, moduleType) => findModules(modules, m => m.type === moduleType)
)(getModuleTypeArgument); // cache by moduleType

export const getModuleByModuleName = createCachedSelector(
  getAllModulesByModuleName,
  modules => modules.pop() || {}
)(getModuleTypeArgument);

export const getModuleDataByModuleName = createCachedSelector(
  getModuleByModuleName,
  module => module?.moduleData || {}
)(getModuleTypeArgument);

export const getModuleIdByModuleName = createCachedSelector(
  getModuleByModuleName,
  module => module?.id || undefined
)(getModuleTypeArgument);

export const getAllModuleTypeByModuleId = createSelector(getModules, modules =>
  Object.keys(modules).reduce((result, moduleId) => {
    result[moduleId] = modules[moduleId].type;
    return result;
  }, {} as any)
);

export const hasModuleTypeInstalled = createCachedSelector(
  getAllModulesByModuleName,
  (modules): boolean => !!modules.length
)(getModuleTypeArgument);

// cannot re-use selector from layout.ts due to cyclic dependency
export const getAllInstalledModuleIdsInSection = (
  state: AppState,
  sectionName: string
) => {
  const zones = state.layout?.[sectionName]?.zones || {};
  return Object.keys(zones).reduce(
    (acc, zoneName) => acc.concat(zones[zoneName]?.installedModulesIds || []),
    [] as number[]
  );
};

export const getSectionModules = createSelector(
  getModules,
  getAllInstalledModuleIdsInSection,
  (modules, allInstalledModuleIds) =>
    findModules(modules, m => allInstalledModuleIds.includes(m.id))
);

export const getSectionModuleDataByModuleType = createSelector(
  getSectionModules,
  getAllInstalledModuleIdsInSection,
  (state: AppState, sectionName: string, moduleType: string) => moduleType,
  (modules, allInstalledModuleIds, moduleType) => {
    const sectionModules = findModules(modules, m =>
      allInstalledModuleIds.includes(m.id)
    );
    const moduleRaw = sectionModules.find(m => m.type === moduleType);
    return moduleRaw?.moduleData || {};
  }
);

export const canPerformModuleTypeAction = (
  state: AppState,
  moduleType: ModuleType,
  priv: ModulePriv
) =>
  canPerformModuleAction(
    state,
    getModuleIdByModuleName(state, moduleType),
    priv
  );

export const canPerformModuleAction = (
  state: AppState,
  moduleId: number | undefined,
  priv: ModulePriv
) => !!moduleId && getModulePrivileges(state, moduleId)[priv] === true;

export const canEditModule = (state, type) =>
  canPerformModuleTypeAction(state, type, ModulePriv.EDIT);
