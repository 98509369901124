import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  getIsLoggedIn,
  getIsVerified,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import { Props } from './GallectionsSection';
import { AppState } from '../../../../types/store';
import { hasAnyDeviations } from '../../../selectors/folders';
import { isSectionLoading } from '../../../selectors/sections';
import { isCurrentUserProfileOwner } from '../../../selectors/users';
import {
  getSearchQuery,
  getGallectionSectionType,
} from '../../../selectors/gallectionSection';

export type StateProps = Pick<
  Props,
  | 'hasAnyDeviations'
  | 'isSectionLoading'
  | 'searchQuery'
  | 'gallectionType'
  | 'isOwner'
  | 'isLoggedIn'
  | 'isVerified'
>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const gallectionSectionType = getGallectionSectionType(state);

  return {
    isOwner: isCurrentUserProfileOwner(state),
    isLoggedIn: getIsLoggedIn(state),
    isVerified: getIsVerified(state),
    hasAnyDeviations: hasAnyDeviations(state),
    isSectionLoading: isSectionLoading(state),
    searchQuery: getSearchQuery(state),
    gallectionType: gallectionSectionType,
  };
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
