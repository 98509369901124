import React from 'react';
import c from 'classnames';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';

import s from './BasePaywall.scss';

export interface Props {
  className?: string;
  hasAccess: boolean;
  children: React.ReactNode;
  icon: React.ReactNode;
  text: string | React.ReactNode;
  button: React.ReactNode;
  title?: string;
  isLiterature?: boolean;
  isOwner: boolean;
}

const BasePaywall: React.FC<Props> = ({
  hasAccess,
  children,
  icon,
  title,
  text,
  button,
  isOwner,
  isLiterature,
}) => {
  if (hasAccess || isOwner) {
    // do not show the paywall for the owner and do not show it for those who have access
    return <>{children}</>;
  }
  return (
    <OverlayContextProvider
      value={{
        showPremiumFolderOverlay: false,
        showPremiumFolderIcon: false,
      }}
    >
      <div className={c(s['root'], isLiterature && s['literature'])}>
        <div className={s['content']}>{children}</div>
        <div className={s['overlay']}>
          <div className={s['overlay-bg']} />
          <div className={s['distancer']} />
          <div className={s['overlay-content']}>
            <div className={s['icon']}>{icon}</div>
            {title && <div className={s['title']}>{title}</div>}
            <div className={s['text']}>{text}</div>
            {button}
          </div>
        </div>
      </div>
    </OverlayContextProvider>
  );
};
BasePaywall.displayName = 'BasePaywall';

export default BasePaywall;
