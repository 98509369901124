import React from 'react';
import { useSelector } from 'react-redux';
import { isSectionLoading } from '../selectors/sections';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';

const withSectionFetchSpinner =
  <P extends object>(ChildComponent: React.ComponentType<P>) =>
  (props: P) => {
    const isLoading = useSelector(isSectionLoading);
    return isLoading ? <LoadingIndicator /> : <ChildComponent {...props} />;
  };

export default withSectionFetchSpinner;
