import React, { Suspense, lazy } from 'react';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const AboutMeConfigModal = lazy(
  () => import(/* webpackChunkName: "owner" */ '../../AboutMeConfigModal')
);

type Props = any;

const AboutMeConfigModalLazy: React.FC<Props> = props => {
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      <AboutMeConfigModal {...props} />
    </Suspense>
  );
};
AboutMeConfigModalLazy.displayName = 'AboutMeConfigModalLazy';

export default AboutMeConfigModalLazy;
