import React from 'react';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import {
  BiData,
  BiContextProvidedValue,
  UserNameClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { useNormalizedUserStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import GroupCard from '@wix/da-shared-react/pkg/GroupCard';
import Button from '@wix/da-shared-react/pkg/Button';
import { useSelector } from 'react-redux';
import c from 'classnames';
import { getProfileOwner } from '../../../selectors/users';
import { useTranslation } from 'react-i18next';
import ElementQueries from '@wix/da-shared-react/pkg/ElementQueries';
import { useModuleName } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import {
  GROUPS_ADMIN_IDS_STREAM,
  GROUPS_MEMBER_IDS_STREAM,
} from '../../../streams';
import s from './GroupList.scss';

interface Props {
  moduleId: number;
  isWide?: boolean;
}

const GroupList: React.FC<Props> = ({ moduleId, isWide }) => {
  const { t } = useTranslation();
  const profileOwner = useSelector(getProfileOwner);
  const moduleName = useModuleName(moduleId);
  const streamId =
    moduleName === 'groupListAdmins'
      ? GROUPS_ADMIN_IDS_STREAM
      : GROUPS_MEMBER_IDS_STREAM;

  const {
    items: groups,
    hasMore,
    isFetching,
    fetchNext,
  } = useNormalizedUserStream(streamId);

  const getBiData = group => {
    const { userId } = group;
    const biData = BiData<UserNameClickBiEvent>({
      evid: 101,
      typeid: ResourceType.GROUP,
      itemid: userId,
      sectionname: BiContextProvidedValue,
      creatorid: profileOwner?.user?.useridUuid,
    });
    return biData;
  };

  const renderGridElement = group => {
    return (
      <GroupCard
        key={group.username}
        group={group}
        className={s['group']}
        biData={getBiData(group)}
      />
    );
  };
  const groupItems = isWide ? groups : groups.slice(0, 12);

  const renderGrid = items => {
    const elements = items.map(renderGridElement);
    return (
      <ElementQueries
        widthBreakpoints={[640, 800]}
        className={c(s['grid'], !isWide && s['grid-flex'])}
      >
        {elements}
      </ElementQueries>
    );
  };

  return (
    <>
      {renderGrid(groupItems)}
      {isWide && hasMore && (
        <Button
          view="b8"
          className={s['load-more-button']}
          disabled={isFetching}
          onClick={fetchNext}
        >
          {t('common.loadMore')}
        </Button>
      )}
    </>
  );
};
GroupList.displayName = 'GroupList';

export default GroupList;
