import { Action } from 'redux-actions';
import { PapiModuleBadgesActivity } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';

import {
  BADGE_ACTIVITY_STREAM,
  BADGE_ACTIVITY_STREAM_ITEMS_PER_FETCH,
} from '../../../../streams';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleBadgesActivity
): Action<any>[] => {
  const { hasMore, nextOffset, results } = moduleData;
  return [
    {
      ...withOffset.actions.initialize({
        streamId: BADGE_ACTIVITY_STREAM,
        streamParams: {
          moduleId,
        },
        itemsPerFetch: BADGE_ACTIVITY_STREAM_ITEMS_PER_FETCH,
        items: results,
        nextOffset: nextOffset || undefined,
        hasMore,
      }),
      payload:
        null /* stream intialize actions are not compatible with Action<any> */,
    },
  ];
};
