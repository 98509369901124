import React from 'react';
import TextButton, { TextButtonProps } from '@wix/da-ds/pkg/Buttons/TextButton';
import ArrowRight from '@wix/da-ds/pkg/Icons/16x16/ArrowRight';

interface Props extends Omit<TextButtonProps, 'size' | 'variant'> {}

/**
 * A `<TextButton />` with a preset `size`, `variant`, and `postfix` prop.
 *
 * We use this at the bottom of statistic blocks.
 */
const StatisticButton: React.FC<Props> = props => {
  return (
    <TextButton size="small" variant="main" postfix={ArrowRight} {...props} />
  );
};
StatisticButton.displayName = 'StatisticButton';

export default StatisticButton;
