import React, { useCallback, useContext } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { URLS } from '@wix/da-url';
import MessageModal from '@wix/da-ds/pkg/modals/dsPresets/MessageModal';
import ModalLink from '@wix/da-ds/pkg/modals/dsPresets/ModalLink';
import { ConfirmButton } from '@wix/da-ds/pkg/modals/dsPresets/MessageModal/buttons';
import { FirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags';
import { useFirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/hooks';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import s from './MoneyDeviationPublishedModal.scss';

interface Props {
  type: 'tier' | 'commission';
  onClose: () => void;
}

const MoneyDeviationPublishedModal: React.FC<Props> = ({ type, onClose }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const { hasFlag, dropFlag } = useFirstTimeFlag(
    FirstTimeFlag.IsMsFirstTierNotCreated
  );

  const showFTUEVariant = !isMobile && hasFlag;

  const onCloseModal = useCallback(() => {
    onClose();
    showFTUEVariant && dropFlag();
  }, [onClose, showFTUEVariant, dropFlag]);

  return (
    <MessageModal
      // This modal is only mounted when we should be showing it, no need for extra handling
      isOpen
      variant={showFTUEVariant ? 'horizontal' : 'vertical'}
      className={classnames(showFTUEVariant && s['ftue-modal'])}
      title={t(`common.moneyDeviationPublishedPopup.var.${type}.title`)}
      message={
        showFTUEVariant ? (
          /* prettier-ignore */
          <Trans i18nKey={`common.moneyDeviationPublishedPopup.ftue.var.${type}.message`}>
            Now you can add tasty deviations and posts to attract subscribers.

            We’ve also added banners to your profile and deviations to let everyone
            know that you’re open for business. You can turn these promotions off in your
            <ModalLink href={URLS.SETTINGS_PROMOS}>Settings</ModalLink>.
          </Trans>
        ) : (
          t(`common.moneyDeviationPublishedPopup.var.${type}.message`)
        )
      }
      image={<div className={s['illustration']} />}
      footerButtons={[
        <ConfirmButton key="confirm" onClick={onCloseModal}>
          {t('common.moneyDeviationPublishedPopup.button.label')}
        </ConfirmButton>,
      ]}
      onRequestClose={onCloseModal}
    />
  );
};
MoneyDeviationPublishedModal.displayName = 'MoneyDeviationPublishedModal';

export default MoneyDeviationPublishedModal;
