import { ModuleType } from '../../types/modules';
import { AppState } from '../../types/store';
import {
  getDeviationById,
  getDeviationsByIds,
} from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import { getModuleDataByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { getProfileGruser } from './users';

export const getSelectedTierContentType = (state: AppState) =>
  state.subscriptionsSection.tierContentType;

export const getSelectedTierEntityId = (state: AppState) =>
  state.subscriptionsSection.selectedTierEntityId;

export const getSelectedTier = (state: AppState) => {
  const selectedTierEntityId = getSelectedTierEntityId(state);
  if (!selectedTierEntityId) {
    return undefined;
  }
  return getDeviationById(state, selectedTierEntityId);
};

export const hasTiers = state => {
  const tiers = getModuleDataByModuleName(
    state,
    getProfileGruser(state),
    'tiers'
  )?.tiers;
  return !!(tiers && tiers.length);
};

export const hasPublishedTiers = state => {
  const tiers = getModuleDataByModuleName(
    state,
    getProfileGruser(state),
    'tiers'
  )?.tiers;
  if (!tiers || !tiers?.length) {
    return false;
  }
  return (getDeviationsByIds(state, tiers) || []).some(
    tier => tier.isPublished
  );
};

export const getSelectedOrFirstTier = state => {
  let tierEntityId = getSelectedTierEntityId(state);
  if (!tierEntityId) {
    tierEntityId = getModuleDataByModuleName(
      state,
      getProfileGruser(state),
      ModuleType.TIERS
    )?.tiers[0];
  }
  return tierEntityId ? getDeviationById(state, tierEntityId) : undefined;
};

export const getSubscriptionsStats = (state: AppState) =>
  state.subscriptionsSection.stats;

export const getSubscriptionsChartData = (state: AppState) =>
  state.subscriptionsSection.chartData;
