import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import CommentThread, {
  selectors as commentThreadSelectors,
} from '@wix/da-shared-react/pkg/CommentThread';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';
import ThreadOrderSelect from '@wix/da-shared-react/pkg/CommentThread/_partials/ThreadOrderSelect';
import { CommentableTypes } from '@wix/da-shared-react/pkg/Comment/redux/types';
import useModuleMeta from '@wix/da-gruser-shared/pkg/hooks/useModuleMeta';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useGruser from '@wix/da-gruser-shared/pkg/hooks/useGruser';
import {
  ThemeOverride,
  ThemeSurface,
} from '@wix/da-react-context/pkg/ThemeContext';
import { getProfileOwner } from '../../../selectors/users';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import profileCommentsInit from './redux/profileCommentsInit';
import { PapiCommentThread } from '@wix/da-papi-types';

import s from './ProfileCommentsBox.scss';

export type Props = {
  moduleId: number;
  widgetBoxProps: Partial<WidgetBoxProps>;
};

const ProfileCommentsBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileOwner = useSelector(getProfileOwner);
  const isMobile = useContext(MobileContext);
  const { isLoggedIn } = useContext(SessionContext);
  const { gruser } = useGruser();
  const { moduleMeta } = useModuleMeta(moduleId);
  const { moduleData } = useModuleData<PapiCommentThread>(moduleId);
  const totalCommentCount = useSelector(state =>
    commentThreadSelectors.getTotal(
      state,
      CommentableTypes.COMMENT_USER,
      profileOwner?.user?.userId
    )
  );

  useEffect(() => {
    if (gruser && !moduleMeta?.isInitialized) {
      const initActions = profileCommentsInit(gruser, moduleId, moduleData);
      initActions.map(dispatch);
    }
  }, [gruser, moduleMeta, moduleData, moduleId, dispatch]);

  return (
    <WidgetBox
      header={
        isGroup(profileOwner.user)
          ? t('widgets.profileComments.group.header')
          : t('widgets.profileComments.header')
      }
      headerExtras={
        isLoggedIn && isMobile ? (
          <ThreadOrderSelect
            commentableTypeId={CommentableTypes.COMMENT_USER}
            commentableItemId={profileOwner.user.userId}
          />
        ) : undefined
      }
      count={totalCommentCount}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      <div className={s['root']}>
        <BiLoggerContextProvider
          value={{ widgetname: 'comments', sectionname: 'comments' }}
        >
          <ThemeOverride theme="match" themeSurface={ThemeSurface.SECONDARY}>
            <CommentThread
              commentableTypeId={CommentableTypes.COMMENT_USER}
              commentableItemId={profileOwner.user.userId}
              useCardLayout
              postbox="top"
              statsAndControls={'off'}
            />
          </ThemeOverride>
        </BiLoggerContextProvider>
      </div>
    </WidgetBox>
  );
};
ProfileCommentsBox.displayName = 'ProfileCommentsBox';

export default ProfileCommentsBox;
