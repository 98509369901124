import { createAction } from 'redux-actions';
import { GallectionSortOrder, GallectionType } from '../../types/gallection';

export const setSortOrder = createAction(
  'GALLECTION_SECTION/SET_SORT_ORDER',
  (payload: GallectionSortOrder) => payload
);

export const setGallectionSectionType = createAction(
  'GALLECTION_SECTION/SET_GALLECTION_SECTION_TYPE',
  (payload: GallectionType) => payload
);

export const setSelectedFolderId = createAction(
  'GALLECTION_SECTION/SET_SELECTED_FOLDERID',
  (payload: number) => payload
);

export const unsetSelectedFolderId = createAction(
  'GALLECTION_SECTION/UNSET_SELECTED_FOLDERID',
  () => ({})
);

export const setSelectedSubfolderId = createAction(
  'GALLECTION_SECTION/SET_SELECTED_SUBFOLDERID',
  (payload: number) => payload
);

export const unsetSelectedSubfolderId = createAction(
  'GALLECTION_SECTION/UNSET_SELECTED_SUBFOLDERID',
  () => ({})
);

export const setCurrentlyViewedFolderId = createAction(
  'GALLECTION_SECTION/SET_CURRENTLY_VIEWED_FOLDERID',
  (payload: number) => payload
);

export const unsetCurrentlyViewedFolderId = createAction(
  'GALLECTION_SECTION/UNSET_CURRENTLY_VIEWED_FOLDERID',
  () => ({})
);

export const setSearchInputValue = createAction(
  'GALLECTION_SECTION/SET_SEARCH_INPUT_VALUE',
  (payload: string) => payload
);

export const setSearchQuery = createAction(
  'GALLECTION_SECTION/SET_SEARCH_QUERY',
  (payload: string) => payload
);

export const clearSearch = createAction(
  'GALLECTION_SECTION/CLEAR_SEARCH',
  () => ({})
);

export const addFolderToFullViewList = createAction(
  'GALLECTION_SECTION/ADD_FOLDER_TO_FULLVIEW_VIEW',
  (payload: number) => payload
);

export const setFullViewFolderIds = createAction(
  'GALLECTION_SECTION/SET_FULLVIEW_FOLDERIDS',
  (payload: number[]) => payload
);

export const fetchAllFolders = createAction(
  'GALLECTION_SECTION/FETCH_ALL_FOLDERS',
  () => ({})
);

export const fetchSubFolders = createAction(
  'GALLECTION_SECTION/FETCH_SUB_FOLDERS',
  (type, parentId) => ({ type, parentId })
);

export const fetchSubFoldersStart = createAction(
  'GALLECTION_SECTION/FETCH_SUB_FOLDERS_START',
  (type, parentId) => ({ type, parentId })
);

export const fetchSubFoldersDone = createAction(
  'GALLECTION_SECTION/FETCH_SUB_FOLDERS_DONE',
  (type, parentId) => ({ type, parentId })
);

export const fetchSubFoldersError = createAction(
  'GALLECTION_SECTION/FETCH_SUB_FOLDERS_ERROR',
  (type, parentId) => ({ type, parentId })
);
