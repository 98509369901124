import { connect, MapDispatchToProps } from 'react-redux';
import { showBadgeInfoModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import { Props } from './Badges';

type DispatchProps = Pick<Props, 'showModal'>;
type OwnProps = Omit<Props, keyof DispatchProps>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  showModal: showBadgeInfoModal,
};

export default connect(null, mapDispatchToProps);
