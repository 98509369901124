import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Badge from '@wix/da-ds/pkg/Badge';
import { PapiBadge } from '@wix/da-papi-types';
import { showBadgeInfoModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import {
  BiData,
  BiContextProvidedValue,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import { useBiLogger } from '@wix/da-bi/pkg/hooks/useBiLogger';
import { getBiDataForBadge } from '@wix/da-shared-react/pkg/utils/badges';

import s from './Badges.scss';

export interface Props {
  badges: PapiBadge[];
  username: string;
  showModal: typeof showBadgeInfoModal;
  collapseMoreThan?: number;
}

const Badges: React.FC<Props> = ({
  badges,
  username,
  showModal,
  collapseMoreThan = 6,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const logBiEvent = useBiLogger();
  const indexLimit = collapseMoreThan - 1;
  const lengthLimit = collapseMoreThan;
  const handleBadgeClick = (badge: PapiBadge) => {
    showModal(username, badge.id!);
    logBiEvent(getBiDataForBadge(badge));
  };

  return (
    <div
      className={classnames(
        s['root'],
        (isExpanded || badges.length >= collapseMoreThan) &&
          s['a-lot-of-badges']
      )}
    >
      {badges.map((badge, index) => {
        if (isExpanded || index < indexLimit || badges.length <= lengthLimit) {
          return (
            <div
              key={badge.id}
              className={s['badge-wrapper']}
              onClick={() => handleBadgeClick(badge)}
            >
              <Badge badge={badge} />
            </div>
          );
        }
        return null;
      })}
      {badges.length > collapseMoreThan && (
        <PlainButtonOrLink
          key="more-badges"
          className={s['more-badges-icon']}
          onClick={() => setIsExpanded(!isExpanded)}
          biData={
            isExpanded
              ? undefined
              : BiData<DefaultBiEvent>({
                  evid: 2,
                  click_info: 'AboutMe.Badges.showMore',
                  sectionname: BiContextProvidedValue,
                  widgetname: BiContextProvidedValue,
                })
          }
          aria-label={t(
            isExpanded
              ? 'widgets.aboutMe.a11y.fewerBadges'
              : 'widgets.aboutMe.a11y.moreBadges'
          )}
        >
          {isExpanded ? '-' : `+${badges.length - lengthLimit}`}
        </PlainButtonOrLink>
      )}
    </div>
  );
};
Badges.displayName = 'Badges';

export default Badges;
