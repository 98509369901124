import {
  getPageInfoInitActions,
  getCustomInitActionsForWidgets,
} from './common';

import getAdoptabobblesWidgetConfigs from '../../components/Sections/AdoptabobblesSection/adoptabobblesWidgetConfigs';

export const getCustomInitActionsForAdoptabobblesWidgets = (
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getAdoptabobblesWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  ...getPageInfoInitActions(responseData, requestParams),
];
