import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pick from 'lodash/pick';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import CoreMonetizationAd from '@wix/da-shared-react/pkg/CoreMonetizationAd';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import Zone from '@wix/da-gruser-shared/pkg/components/Zone';
import { useRestrictToLoggedIn } from '@wix/da-shared-react/pkg/utils/hooks/useRestrictToLoggedIn';
import {
  getCurrentUserHasCore,
  getIsLoggedIn,
  getIsVerified,
} from '@wix/da-shared-react/pkg/publicSession/selectors';
import SubscriptionsCoreOnlyBlock from './SubscriptionsCoreOnlyBlock';
import SubscriptionsVisitorEmptyState from './SubscriptionsVisitorEmptyState/SubscriptionsVisitorEmptyState';
import MoneyDeviationPublishedModal from '../_partials/MoneyDeviationPublishedModal';
import { getSubscriptionWidgetConfigs } from './subscriptionWidgetConfigs';
import {
  hasPublishedTiers as getHasPublishedTiers,
  hasTiers as getHasTiers,
} from '../../../selectors/subscriptionsSection';
import {
  getProfileOwner,
  isCurrentUserProfileOwner,
} from '../../../selectors/users';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { getIsSectionLoading } from '../../../selectors/sections';

import s from './SubscriptionsSection.scss';

export interface Props {}

const SubscriptionsSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sendUserToJoinPage } = useRestrictToLoggedIn();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isVerified = useSelector(getIsVerified);
  const hasTiers = useSelector(getHasTiers);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const hasCore = useSelector(getCurrentUserHasCore);
  const profileOwner = useSelector(getProfileOwner);
  const hasPublishedTiers = useSelector(getHasPublishedTiers);
  const isSectionLoading = useSelector(getIsSectionLoading);
  const showExpiredCoreBanner = isOwner && hasTiers && !hasCore;

  const widgetConfigs = getSubscriptionWidgetConfigs({ t, dispatch });
  const topZoneWidgetConfigs = pick(widgetConfigs, [
    'subscriptionCenter',
    'subscriptionTips',
  ]);
  const mainZoneWidgetConfigs = pick(widgetConfigs, ['tiers', 'tierContent']);

  const [showSuccessModal, setShowSuccessModal] = useState(() => {
    if (typeof window === 'undefined') {
      return false;
    }
    return !!new URLSearchParams(window.location.search).get(
      'tier-publish-success'
    );
  });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (window.location.search.includes('new_tier')) {
      if (!isLoggedIn) {
        sendUserToJoinPage(ReferralType.SUBSCRIPTIONS_TIER_CREATION);
      }
      if (isVerified && isOwner) {
        dispatch(
          modalActions.pushModal(ModalType.TIER_EDIT, {
            wrapInModal: false,
          })
        );
      }
    }
    // run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dismissSuccessModal = useCallback(() => setShowSuccessModal(false), []);

  if (isSectionLoading) {
    return <LoadingIndicator />;
  }

  if (isOwner) {
    if (!hasTiers) {
      return <SubscriptionsCoreOnlyBlock className={s['core-only-block']} />;
    }
  } else {
    if (!hasPublishedTiers) {
      return (
        <SubscriptionsVisitorEmptyState profileOwnerUser={profileOwner?.user} />
      );
    }
  }

  return (
    <div className={cn(s['root'], showExpiredCoreBanner && s['with-banner'])}>
      {showSuccessModal && (
        <MoneyDeviationPublishedModal
          type="tier"
          onClose={dismissSuccessModal}
        />
      )}
      {showExpiredCoreBanner && (
        <CoreMonetizationAd
          text={t('page.subscriptions.coreMonetizationAd.text')}
          withCTA
        />
      )}
      <div className={s['zone-group']}>
        {isOwner && (
          <Zone
            className={cn(s['zone'], s['top-zone'])}
            name="midRight"
            widgetConfigs={topZoneWidgetConfigs}
          />
        )}
        <Zone
          className={s['zone']}
          name="midRight"
          widgetConfigs={mainZoneWidgetConfigs}
        />
      </div>
    </div>
  );
};
SubscriptionsSection.displayName = 'SubscriptionsSection';

export default SubscriptionsSection;
