import { createAction } from 'redux-actions';
import ReactCrop from 'react-image-crop';

export type AvatarUploadPayload = {
  file?: File;
  image?: HTMLImageElement;
  pixelCrop?: ReactCrop.Crop;
};

export const uploadAvatar = createAction(
  'AVATAR.PROCESS_AND_UPLOAD',
  (payload: AvatarUploadPayload) => payload
);

export type SetIsEditorOpenPayload = {
  isOpen: boolean;
};

export const setIsEditorOpen = createAction(
  'AVATAR.SET_IS_EDITOR_OPEN',
  (isOpen: boolean): SetIsEditorOpenPayload => ({ isOpen })
);

export type SetErrorMessagePayload = {
  errorMessage?: string;
};

export const setErrorMessage = createAction(
  'AVATAR.SET_ERROR_MESSAGE',
  (errorMessage: string | undefined): SetErrorMessagePayload => ({
    errorMessage,
  })
);
