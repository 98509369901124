import { ModuleConfig } from '../../../../../types/modules';
import { FormValues } from '../types';
import { fromPlainText } from '@wix/da-editor-tiptap/pkg/components/utils/helpers';

export const configAdapter = ({ config = {} }: ModuleConfig): FormValues => {
  const { title = '', tagline = '' } = config || {};
  let { description } = config || {};
  // Convert legacy writer format to draftjs
  if (description?.html?.type === 'writer') {
    description = fromPlainText(description?.excerpt || ' ');
  }
  return {
    title,
    tagline,
    description,
    editorApi: undefined,
  };
};

export const submitAdapter = (formValues: FormValues) => {
  const { title = '', tagline = '', editorApi } = formValues;
  let editorRaw: any = undefined;

  if (editorApi) {
    editorRaw = JSON.stringify(editorApi.getStateForSave());
  }
  return {
    title,
    tagline,
    editorRaw,
  };
};
