import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { Route, RouteComponentProps } from 'react-router';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import HiddenIcon from '@wix/da-ds/pkg/Icons/Hidden';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { useCollapsingRoutes } from '../../../useCollapsingRoutes';
import { Route as RouteDefinition } from '../../../../../../../types/route';
import c from 'classnames';
import s from './NavRoutes.scss';

export interface Props {
  className?: string;
  routes: RouteDefinition[];
  biClickProfileNavBar: (linkName: string) => void;
}

const NavRoutes: React.FC<Props & RouteComponentProps<any>> = ({
  className,
  routes,
  biClickProfileNavBar,
}) => {
  const { t } = useTranslation();
  const { shouldCollapse, routesToCollapse } = useCollapsingRoutes();

  return (
    <Route
      key="nav-user"
      path="/:username"
      sensitive={true}
      render={({ match }) => (
        <div className={c(
          s['nav-list'],
          shouldCollapse && s['oversized'],
          className
        )} id="nav">
          {routes.map(route => (
            <NavLink
              key={route.key}
              to={(route.defaultPath || route.path || '')
                .replace(/:username/, match.params.username)
                .replace(/\/:[a-z0-9_()\\+]+\?/gi, '')}
              exact={route.exact}
              className={c(
                s['nav-item'],
                s['nav-item-compactable'],
                routesToCollapse.includes(route.key) &&
                  s['nav-item-removable']
              )}
              activeClassName={s['active']}
              onClick={() => biClickProfileNavBar(route.key)}
            >
              {route.isPrivate && (
                <SimpleTooltip
                  text={t('navigation.route.onlyYou')}
                  className={s['nav-item-icon']}
                  usePortal
                >
                  <HiddenIcon
                    size={IconSize.TINY}
                    aria-label={t('navigation.route.a11y.private')}
                  />
                </SimpleTooltip>
              )}
              {t(`navigation.route.var.${route.key}`)}
              {route.isBeta && <span className={s['beta']}>beta</span>}
            </NavLink>
          ))}
        </div>
      )}
    />
  );
};
NavRoutes.displayName = 'NavRoutes';

export default withRouter(NavRoutes);
