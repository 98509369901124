import { connect, MapStateToProps } from 'react-redux';
import { Props } from './ProfileHeroV2';
import { AppState } from '../../../../types/store';
import { getCoverDeviation } from '../../../selectors/deviations';

export type StateProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof StateProps | 'coverDeviation'>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  const coverDeviation = getCoverDeviation(state);
  return {
    coverDeviation,
  };
};

export default connect(mapStateToProps);
