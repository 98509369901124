import React from 'react';
import Flex from '@wix/da-ds/pkg/Flex';
import { useTranslation } from 'react-i18next';
import Page from '@wix/da-shared-react/pkg/Pagination/_partials/Page';
import ArrowLeft from '@wix/da-ds/pkg/Icons/ArrowLeft';
import ArrowRight from '@wix/da-ds/pkg/Icons/ArrowRight';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';

import s from './GroupNotesPagination.scss';

interface Props {
  hasMore?: boolean;
  hasLess?: boolean;

  fetchNext?: () => void;
  fetchPrevious?: () => void;
}

const GroupNotesPagination: React.FC<Props> = ({
  hasMore,
  hasLess,
  fetchNext,
  fetchPrevious,
}) => {
  const { t } = useTranslation();

  return (
    <Flex className={s['pagination']} alignItems="center">
      {hasLess && (
        <Page page={-1} onChangePage={fetchPrevious} isCurrent={false}>
          <ArrowLeft
            className={s['arrow']}
            size={IconSize.MEDIUM}
            disableDefaultColors
          />
          {t('pagination.prev')}
        </Page>
      )}
      {hasMore && (
        <Page page={1} onChangePage={fetchNext} isCurrent={false}>
          {t('pagination.next')}
          <ArrowRight
            className={s['arrow']}
            size={IconSize.MEDIUM}
            disableDefaultColors
          />
        </Page>
      )}
    </Flex>
  );
};
GroupNotesPagination.displayName = 'GroupNotesPagination';

export default GroupNotesPagination;
