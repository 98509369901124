import { handleActions } from 'redux-actions';
import { ProfileHeroEditor } from '../../types/profileHeroEditor';
import {
  setIsRepositioningProfileHero,
  setProfileHeroDeviation,
  updateProfileHeroCropArea,
} from '../actions/profileHeroEditor';

export default handleActions<ProfileHeroEditor, any>(
  {
    [setIsRepositioningProfileHero.toString()]: (
      state: ProfileHeroEditor,
      action: ReturnType<typeof setIsRepositioningProfileHero>
    ): ProfileHeroEditor => ({
      ...state,
      isRepositioning: !!action.payload,
    }),
    [setProfileHeroDeviation.toString()]: (
      state: ProfileHeroEditor,
      action: ReturnType<typeof setProfileHeroDeviation>
    ): ProfileHeroEditor => ({
      ...state,
      deviation: action.payload,
    }),
    [updateProfileHeroCropArea.toString()]: (
      state: ProfileHeroEditor,
      action: ReturnType<typeof updateProfileHeroCropArea>
    ): ProfileHeroEditor => ({
      ...state,
      cropArea: action.payload,
    }),
  },
  {
    isRepositioning: false,
    deviation: null,
  }
);
