import { formatDate } from '@wix/da-ds/pkg/Time/functions';

export const formatBalance = (balance, currency?: true) =>
  Intl.NumberFormat('en-US', {
    signDisplay: 'exceptZero',
    style: currency && 'currency',
    currency: currency && 'USD',
    useGrouping: false,
  }).format(balance);

export const formatMonth = (date: string) =>
  formatDate(date, { month: 'short' });
