import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { getShopItemsStreamId } from '../streams/shop';
import { ShopItemType } from '../../types/shop';

export function hasSingularContentType(state) {
  return (
    Object.values(getEmptySections(state)).filter(empty => empty === false)
      .length === 1
  );
}

export function isEmpty(state) {
  return (
    Object.values(getEmptySections(state)).filter(empty => empty === true)
      .length === 4
  );
}

export function getEmptySections(state): Object {
  const hasNoCommissionItems = !streamSelectors.getTotal(
    state,
    getShopItemsStreamId(ShopItemType.COMMISSIONS)
  );

  const hasNoPremiumContentItems = !streamSelectors.getTotal(
    state,
    getShopItemsStreamId(ShopItemType.PREMIUM_CONTENT)
  );

  const hasNoPremiumFoldersItems = !streamSelectors.getTotal(
    state,
    getShopItemsStreamId(ShopItemType.PREMIUM_FOLDERS)
  );

  const hasNoAdoptableItems = !streamSelectors.getTotal(
    state,
    getShopItemsStreamId(ShopItemType.ADOPTABLES)
  );

  return {
    [ShopItemType.COMMISSIONS]: hasNoCommissionItems,
    [ShopItemType.PREMIUM_CONTENT]: hasNoPremiumContentItems,
    [ShopItemType.PREMIUM_FOLDERS]: hasNoPremiumFoldersItems,
    [ShopItemType.ADOPTABLES]: hasNoAdoptableItems,
  };
}
