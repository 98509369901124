import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { PapiGruserModuleWatchers } from '@wix/da-papi-types';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { WATCHER_IDS_STREAM, USERS_PER_FETCH } from '../../../../streams';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiGruserModuleWatchers,
  requestParams: any
) => {
  const { results, hasMore, nextOffset } = moduleData;
  const { result, entities } = normalize(results, [userSchema]);

  return [
    withOffset.actions.initialize({
      streamId: WATCHER_IDS_STREAM,
      streamParams: {
        moduleId,
      },
      itemsPerFetch: USERS_PER_FETCH,
      items: result,
      entities,
      nextOffset: nextOffset || undefined,
      hasMore,
    }),
  ] as any;
};
