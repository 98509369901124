import React, { lazy, Suspense } from 'react';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import retry from '@wix/da-shared-react/pkg/utils/webpack/chunkRetry';
import ModuleLoaderLazy from '@wix/da-shared-react/pkg/Discord/redux/ModuleLoaderLazy';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';

const DiscordTips = lazy(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "discord" */
        './index'
      )
  )
);

export interface Props {}

const DiscordTipsLoadable: React.FC<Props> = () => {
  // Suspense can't render in SSR
  const mounted = useIsMounted();
  if (!mounted) {
    return null;
  }
  return (
    <ErrorBoundary debugComponent="DiscordTipsLoadable">
      <Suspense fallback={null}>
        <ModuleLoaderLazy />
        <DiscordTips />
      </Suspense>
    </ErrorBoundary>
  );
};
DiscordTipsLoadable.displayName = 'DiscordTipsLoadable';

export default DiscordTipsLoadable;
