import React from 'react';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Zone from '@wix/da-gruser-shared/pkg/components/Zone';
import ZoneGroup from '@wix/da-gruser-shared/pkg/components/ZoneGroup';
import StreamPagination from '../_partials/StreamPagination';
import { getAdoptabobblesWidgetConfigs } from './adoptabobblesWidgetConfigs';

import s from './AdoptabobblesSection.scss';

export interface Props {
  isSectionLoading: boolean;
  isOwner: boolean;
  isWatching: boolean;
  isEmpty: boolean;
  streamId: string;
}

export interface RouteParams {
  username: string;
}

const AdoptabobblesSection: React.FC<Props & RouteParams> = ({
  isSectionLoading,
  streamId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const adoptabobblesWigetConfigs = getAdoptabobblesWidgetConfigs({
    t,
    dispatch,
  });
  if (isSectionLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={s['root']}>
      <ZoneGroup
        widgetConfigs={adoptabobblesWigetConfigs}
        className={s['zone-group']}
        disableTopAddWidget
      >
        <Zone
          name="midRight"
          widgetConfigs={adoptabobblesWigetConfigs}
          locked={true}
        />
      </ZoneGroup>
      <StreamPagination streamId={streamId} />
    </div>
  );
};
AdoptabobblesSection.displayName = 'AdoptabobblesSection';

export default AdoptabobblesSection;
