import React from 'react';
import { URLS } from '@wix/da-url';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';

export type Props = {
  className?: string;
};

export const WhatArePointsLink: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <PlainButtonOrLink
      href={URLS.ABOUT_POINTS}
      className={c('text-link-level-1-brand f-12-reg', className)}
    >
      {t('common.faq.whatArePoints')}
    </PlainButtonOrLink>
  );
};
WhatArePointsLink.displayName = 'WhatArePointsLink';

export default WhatArePointsLink;
