import { getSubscriptionCenterWidgetConfig } from './widgets/SubscriptionCenter/widgetConfig';
import { getSubscriptionTipsWidgetConfig } from './widgets/SubscriptionTips/widgetConfig';
import { getTierContentWidgetConfig } from './widgets/TierContent/widgetConfig';
import { getTiersWidgetConfig } from './widgets/Tiers/widgetConfig';
import getCoverDeviationWidgetConfig from '../../UserWidgets/CoverDeviation/widgetConfig';

export const getSubscriptionWidgetConfigs = ({ t, dispatch }) =>
  ({
    coverDeviation: getCoverDeviationWidgetConfig({ t, dispatch }),
    subscriptionCenter: getSubscriptionCenterWidgetConfig({ t, dispatch }),
    subscriptionTips: getSubscriptionTipsWidgetConfig({ t, dispatch }),
    tiers: getTiersWidgetConfig({ t, dispatch }),
    tierContent: getTierContentWidgetConfig({ t, dispatch }),
  } as any);
