import { all, select, call, put } from 'redux-saga/effects';
import {
  selectors as streamSelectors,
  withOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { FetchType } from '@wix/da-shared-react/pkg/Stream/base/types';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { deviationSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { getProfileOwner } from '../../selectors/users';
import { PapiPostsRequestParams } from '../../../types/papi';
import { PostsType } from '../../../types/posts';
import { biFetchMoreFolderItems, biLogPostsFeedItems } from '../../actions/bi';
import { setSectionStreamId } from '../../actions/sections';
import { initializeThread } from '@wix/da-shared-react/pkg/CommentThread/actionCreators';
import { getCommentableTypeForDeviationType } from '@wix/da-shared-react/pkg/DeviationPage/redux/helpers';
import {
  FEED_COMMENTS_ITEMS_PER_FETCH,
  FEED_COMMENTS_MAX_DEPTH,
} from '../../streams/posts';
import { isApiError } from '@wix/da-http-client';
import { requestPuppyGruser } from '../gruserHelpers';

function* fetchFromApi(postsType, params) {
  return yield call(
    requestPuppyGruser,
    {
      method: 'get',
      url: `/module/${postsType}`,
      params,
    },
    undefined,
    'gruser'
  );
}

export default function* fetchMorePosts(action) {
  const { streamId } = action;

  yield put(biFetchMoreFolderItems(streamId));
  const [offset, limit, streamParams, profileOwner = {}] = yield all([
    select(state => withOffset.selectors.getNextOffset(state, streamId)),
    select(state => streamSelectors.getItemsPerFetch(state, streamId)),
    select(state => streamSelectors.getStreamParamsSafe(state, streamId)),
    select(getProfileOwner),
  ]);

  const queryParams: PapiPostsRequestParams = {
    username: profileOwner.user && profileOwner.user.username,
    moduleid: streamParams.moduleId,
    order: streamParams.sortOrder,
    filter: streamParams.filter,
    offset,
    limit,
  };

  const postsType: PostsType = streamParams.postsType;

  if (postsType === PostsType.JOURNALS || postsType === PostsType.ALL) {
    queryParams.expand = 'deviation.fulltext';
  }

  const moduleName = streamParams.moduleType || postsType;
  if (moduleName === 'posts_feed') {
    queryParams.expand = 'deviation.fulltext,user.watch';
  }

  const response = yield call(fetchFromApi, moduleName, queryParams);
  if (!response || isApiError(response)) {
    yield put(
      withOffset.actions.fetchFailure({ streamId, fetchType: FetchType.Next })
    );
    // TODO error catching
    return;
  }

  const { hasMore, total, nextOffset, results } = response;
  const { result, entities } = normalize(results, [deviationSchema]);
  yield put(
    withOffset.actions.fetchSuccess({
      streamId,
      hasMore,
      nextOffset,
      items: result,
      total,
      entities,
    })
  );

  if (moduleName === 'posts_feed') {
    yield put(setSectionStreamId(streamId));
    yield put(biLogPostsFeedItems(results));
  }

  // Initialize comments
  const commentThreads = results.map(deviation =>
    put(
      initializeThread({
        thread: {
          commentableItemId: deviation.deviationId,
          commentableTypeId: getCommentableTypeForDeviationType(deviation),
          items: [],
          hasMore: !!deviation.stats?.comments,
          total: deviation.stats?.comments ?? 0,
          canPostComment: !!deviation.isCommentable,
        },
        maxDepth: FEED_COMMENTS_MAX_DEPTH,
        itemsPerFetch: FEED_COMMENTS_ITEMS_PER_FETCH,
      })
    )
  );
  yield all(commentThreads);
}
