import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PapiGallection } from '@wix/da-papi-types';
import { getSubFolderFetchState } from '../../../../selectors/gallectionSection';
import { fetchSubFolders } from '../../../../actions/gallectionSection';
import Flex from '@wix/da-ds/pkg/Flex';
import SubfolderCard from '../SubfolderCard';
import { isCurrentUserProfileOwner } from '../../../../selectors/users';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';

import s from './SubfolderCards.scss';

export interface Props {
  folder: PapiGallection;
}

export const SubfolderCards: React.FC<Props> = ({ folder }) => {
  const dispatch = useDispatch();
  const fetchState = useSelector(state =>
    getSubFolderFetchState(state, folder.type, folder.folderId)
  );
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchSubFolders(folder.type, folder.folderId));
  }, [dispatch, folder.folderId, folder.type]);

  const subfolderTransKeys = {
    gallery: 'pages.gallections.gallery.subfolders',
    collection: 'pages.gallections.collection.subfolders',
  };
  const header = (
    <div className={s['subfolders-header']}>
      {t(subfolderTransKeys[folder.type])}{' '}
      {folder.subfolders && (
        <span className={s['subfolders-count']}>
          {folder?.subfolders?.length ?? 0}
        </span>
      )}
    </div>
  );

  if (fetchState?.isError && !fetchState?.isFetching) {
    return (
      <div className={s['subfolders-section']}>
        {header}
        <Flex wrap="wrap">
          <TextButton
            variant="approval-green"
            size="medium"
            onClick={() => {
              dispatch(fetchSubFolders(folder.type, folder.folderId));
            }}
          >
            {t('pages.gallections.collection.subfolders.errorRetry')}
          </TextButton>
        </Flex>
      </div>
    );
  }

  if (folder.hasSubfolders && (folder.subfolders?.length ?? 0) === 0) {
    return (
      <div className={s['subfolders-section']}>
        {header}
        <Flex>
          <LoadingIndicator view="spinner"></LoadingIndicator>
        </Flex>
      </div>
    );
  }

  // We hide empties from non-owners
  const allEmpty = folder.subfolders?.every(sf => sf.size === 0) && !isOwner;

  if (!folder.subfolders || allEmpty) {
    return null;
  }

  return (
    <div className={s['subfolders-section']}>
      {header}
      <Flex wrap="wrap">
        {folder.subfolders.map(
          subFolder =>
            (!!subFolder.size || isOwner) && (
              <SubfolderCard
                key={subFolder.folderId}
                subfolder={subFolder}
                className={s['subfolder']}
              />
            )
        )}
      </Flex>
    </div>
  );
};
SubfolderCards.displayName = 'SubfolderCards';

export default SubfolderCards;
