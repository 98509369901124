import { AppState } from '../../types/store';
import { GroupNotesFolderType } from '../../types/backroomSection';

export const getState = (state: AppState) => state.backroomSection;

export const getSelectedFolder = (
  state: AppState
): GroupNotesFolderType | undefined => getState(state).folder;

export const getSelectedNoteId = (state: AppState): number | undefined =>
  getState(state).selectedNoteId;

export const getNotesOffset = (state: AppState): number | undefined =>
  getState(state).offset;

export const getNotesSearch = (state: AppState): string | undefined =>
  getState(state).search;
