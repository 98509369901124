import { getModuleByModuleName as getModuleByModuleNameNewGrusers } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getProfileGruser, getProfileOwnerUser } from '../selectors/users';
import { runModuleInitActions } from '@wix/da-gruser-shared/pkg/redux/sagas/modules';

import { SagaIterator } from 'redux-saga';
import {
  getProfileHeroCropArea,
  getProfileHeroDeviation,
} from '../selectors/profileHeroEditor';
import {
  saveProfileHero,
  deleteProfileHero,
  setIsRepositioningProfileHero,
} from '../actions/profileHeroEditor';
import { getSourceImageRatio } from '@wix/da-shared-react/pkg/DeviationViews/Dumbo/Image/_helpers';
import { fetchModuleEndpoint } from '@wix/da-gruser-shared/pkg/redux/actions/modules';
import { getPageName } from '@wix/da-gruser-shared/pkg/redux/selectors/page';

function* save(data) {
  const gruser = yield select(getProfileGruser);
  const pageName = yield select(state => getPageName(state, gruser));

  yield put(
    fetchModuleEndpoint(gruser!, {
      ...data,
      pagename: pageName,
      id: data.moduleid,
      path: 'cover_deviation/save',
      method: 'post',
    })
  );
  yield put(setIsRepositioningProfileHero(false));
}

export function* saveProfileHeroSaga(): SagaIterator {
  const gruser = yield select(getProfileGruser);
  const module = yield select(
    getModuleByModuleNameNewGrusers,
    gruser,
    'coverDeviation'
  );
  const moduleId = module.id;
  const moduleVersion = module.version;

  const version = moduleVersion;
  const { username } = yield select(getProfileOwnerUser);

  const deviation = yield select(getProfileHeroDeviation);
  const originalImageRatio = getSourceImageRatio(deviation);
  const cropArea = yield select(getProfileHeroCropArea);
  const cropAreaPapiParams = cropArea
    ? {
        image_width: cropArea.width,
        image_height: Math.round(cropArea.width / originalImageRatio),
        crop_x: cropArea.x,
        crop_y: cropArea.y,
        crop_width: cropArea.width,
        crop_height: cropArea.height,
      }
    : {};

  const response = yield call(save, {
    moduleid: moduleId,
    // Even though this expects a number and we're passing through the normalized
    // deviation id (which could be number | string), it should only ever receive
    // a deviation that production an id of type number
    deviationid: deviation.deviationId,
    ...cropAreaPapiParams,
    version,
    username,
  });

  if (response && response.moduleData) {
    yield call(runModuleInitActions, gruser, response.moduleData);
    yield put(setIsRepositioningProfileHero(false));
  }
}

export function* deleteProfileHeroSaga() {
  const gruser = yield select(getProfileGruser);
  const module = yield select(
    getModuleByModuleNameNewGrusers,
    gruser,
    'coverDeviation'
  );
  const moduleId = module.id;
  const moduleVersion = module.version;
  const { username } = yield select(getProfileOwnerUser);
  const response = yield call(save, {
    moduleid: moduleId,
    deviationid: 0,
    version: moduleVersion,
    username,
  });

  if (response && response.moduleData) {
    yield call(runModuleInitActions, gruser, response.moduleData);
  }
}

export default function* rootCoverDeviationSaga() {
  yield all([
    takeEvery(saveProfileHero, saveProfileHeroSaga),
    takeEvery(deleteProfileHero, deleteProfileHeroSaga),
  ]);
}
