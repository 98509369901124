import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import CommissionSlider from './CommissionSlider';
import commissionsInit from './redux/commissionsInit';

export const getCommissionSliderWidgetConfig = ({
  t,
  dispatch,
}): WidgetConfig => ({
  viewComponent: CommissionSlider,
  initActionsCreator: commissionsInit,
});
