import React from 'react';
import c from 'classnames';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './TipHeading.scss';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

/** A heading for our tips. This is wrapped in a `<Heading />` element from our a11y package. */
const TipHeading: React.FC<Props> = ({ children, className }) => (
  <Heading className={c(s['root'], className)}>{children}</Heading>
);
TipHeading.displayName = 'TipHeading';

export default TipHeading;
