import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDown from '@wix/da-ds/pkg/Icons/24x24/ArrowDown';
import ArrowUp from '@wix/da-ds/pkg/Icons/24x24/ArrowUp';
import { useAction } from '@wix/da-hooks/pkg/useAction';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import TextButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/TextButtonWithContextMenu';
import { DropdownOption } from '@wix/da-ds/pkg/Dropdown/types';
import { filterGroupMessageLogByStatus } from '../../../../../actions/backroomSection';
import { MessageLogModuleData } from '../../types';
import { useMessageLogParams } from '../hooks';

interface Props {
  moduleId: number;
}

function useStatusOptions(
  moduleId: number
): [DropdownOption[], DropdownOption] {
  const { t } = useTranslation();
  const dispatchFilterAction = useAction(filterGroupMessageLogByStatus);
  const {
    moduleData: { statuses },
  } = useModuleData<MessageLogModuleData>(moduleId);
  const { status: currentStatusId } = useMessageLogParams();

  const defaultStatusFilter: DropdownOption = {
    label: t(
      'widgets.group_messagelog.filterBar.statusDropdown.defaultOptionLabel.any'
    ),
    value: undefined,
    selected: currentStatusId === undefined,
    onClick: () => dispatchFilterAction(undefined),
  };

  const statusOptions = [
    defaultStatusFilter,
    ...statuses.map(status => ({
      label: status.label,
      value: status.statusId,
      selected: status.statusId === currentStatusId,
      onClick: () => dispatchFilterAction(status.statusId),
    })),
  ];

  return [statusOptions, statusOptions.find(option => option.selected)!];
}

const StatusDropdown: React.FC<Props> = ({ moduleId }) => {
  const [statusOptions, selectedOption] = useStatusOptions(moduleId);

  return (
    <TextButtonWithContextMenu
      variant="main"
      size="medium"
      postfix={{
        opened: ArrowUp,
        closed: ArrowDown,
      }}
      items={statusOptions}
    >
      {selectedOption.label}
    </TextButtonWithContextMenu>
  );
};

StatusDropdown.displayName = 'StatusDropdown';

export default StatusDropdown;
