import { createAction } from 'redux-actions';
export const FETCH_SECTION = 'FETCH_SECTION';
export const FETCH_SECTION_SUCCESS = 'FETCH_SECTION_SUCCESS';
export const FETCH_SECTION_PRIVATE_SUCCESS = 'FETCH_SECTION_PRIVATE_SUCCESS';
export const SET_SECTION_PRIVATE = 'SET_SECTION_PRIVATE';

export const fetchSection = createAction(FETCH_SECTION, (section: string) => ({
  section,
}));

export const fetchSectionSuccess = createAction(
  FETCH_SECTION_SUCCESS,
  (section: string) => ({
    section,
  })
);

export const fetchSectionPrivateSuccess = createAction(
  FETCH_SECTION_PRIVATE_SUCCESS,
  (isPrivate: boolean) => ({
    isPrivate,
  })
);

export const setSectionPrivate = createAction(
  SET_SECTION_PRIVATE,
  (isPrivate: boolean) => ({
    isPrivate,
  })
);

export const setSectionStreamId = createAction(
  'SECTION.SET_STREAMID',
  (streamId: string) => ({
    streamId,
  })
);
