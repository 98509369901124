import React from 'react';
import classnames from 'classnames';
import Sticky from '@wix/da-ds/pkg/Sticky';
import { Nav } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import { withTranslation, WithTranslation } from 'react-i18next';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';

import s from './SideNavBar.scss';

const BOTTOM_MARGIN = 60;

export interface SidebarRoute {
  hash: string;
  transKey: string;
  count?: string;
}

export interface SideNavBarProps {
  routes: SidebarRoute[];
  activeHash?: string;
  onActiveHashChange: (activeHash: string) => void;
  className?: string;
  biEvid: number;
  heading: React.ReactNode;
}

function workaroundChromeHashFragmentBug(clickEvent, idScrolledTo) {
  // Chrome hash fragment scrolling can overshoot in some cases, see https://phrac.deviantart.net/T38631
  // TODO: remove if/when this is fixed at some point after chrome 75
  // also note: this potentially surpresses the click event in chrome., so if you're adding
  // a bi click event to this nav menu, we need to remove this workaround

  // agent sniffing isn't great, but this is a chrome specific error
  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  if (!isChrome) {
    return;
  }
  const sectionScrolledTo = document.getElementById(idScrolledTo);
  if (!sectionScrolledTo) {
    return;
  }

  clickEvent.preventDefault();
  sectionScrolledTo.scrollIntoView();
}

class SideNavBar extends React.Component<SideNavBarProps & WithTranslation> {
  render() {
    const marginTop = 144;
    const {
      routes,
      className,
      activeHash,
      onActiveHashChange,
      biEvid,
      t,
      heading,
    } = this.props;

    return (
      <ErrorBoundary debugComponent="SideNavBar" hideOnFail>
        <div className={s['root']}>
          <Sticky
            zIndex={parseInt(s['z-index'])}
            marginTop={marginTop}
            className={s['sticky']}
            bottomStop={parseInt(s['footerHeight']) + BOTTOM_MARGIN}
          >
            {isStuck => (
              <Nav
                className={classnames(
                  s['nav-sidebar'],
                  isStuck && s['sticky'],
                  className
                )}
              >
                {heading}
                {routes.map(route => {
                  return (
                    <BiLink
                      biData={
                        {
                          evid: biEvid,
                          menu_item_name: route.hash,
                        } as any
                      }
                      key={route.hash}
                      href={`#${route.hash}`}
                      className={classnames(
                        s['nav-link-item'],
                        activeHash && activeHash === route.hash && s['active']
                      )}
                      onClick={e => {
                        workaroundChromeHashFragmentBug(e, route.hash);
                        onActiveHashChange(route.hash);
                      }}
                    >
                      {t(route.transKey)}
                      <span className={s['count']}>
                        {route.count && parseInt(route.count, 10) > 0
                          ? route.count
                          : ''}
                      </span>
                    </BiLink>
                  );
                })}
              </Nav>
            )}
          </Sticky>
        </div>
      </ErrorBoundary>
    );
  }
}

export default withTranslation()(SideNavBar);
