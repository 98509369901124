import React, { useMemo, useEffect, useContext, useState } from 'react';
import throttle from 'lodash/throttle';
import ddt from '@wix/da-ddt';
import {
  default as OnScrollBottom,
  DISTANCE_UNIT,
} from '@wix/da-shared-react/pkg/OnScrollBottom';
import { useStream } from '@wix/da-shared-react/pkg/utils/hooks/useStream';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import Pagination from '@wix/da-shared-react/pkg/Pagination';
import generatePaginationUrl from '../../../../utils/generatePaginationUrl';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import ArrowDown from '@wix/da-ds/pkg/Icons/16x16/ArrowDown';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './StreamPagination.scss';

const logger = ddt.createLogger('streampagination');

const DISTANCE_FROM_BOTTOM_TO_FETCH_MORE = 1000;
const FETCH_MORE_THROTTLE = 300;

export interface Props {
  streamId: string;
  currentPage: number;
  totalPages?: number;
  requestUrl: string;
  isInfiniteScroll: boolean;
  isDuperbrowseActive: boolean;
  error?: string;
  children?: React.ReactNode;
}

const StreamPagination: React.FC<Props> = ({
  streamId,
  children,
  isDuperbrowseActive,
  isInfiniteScroll: isInfiniteScrollProp,
  currentPage,
  totalPages,
  requestUrl,
  error,
}) => {
  // force paged mode if `page` is in the querystring
  const hasPageParam =
    typeof window !== 'undefined' &&
    new URLSearchParams(window.location.search).has('page');
  const isInfiniteScroll = hasPageParam ? false : isInfiniteScrollProp;

  // TODO: remove with profile_shop_pagination_2
  logger.log(
    'StreamPagination, props',
    JSON.stringify({
      streamId,
      hasPageParam,
      isInfiniteScroll,
      currentPage,
      totalPages,
      requestUrl,
      error,
    })
  );
  const { t } = useTranslation();
  const { isFetching, hasMore, fetchNext } = useStream(streamId);
  logger.log(
    'StreamPagination, useStream',
    JSON.stringify({ isFetching, hasMore })
  );

  const handleScrollToBottom = useMemo(
    () =>
      throttle(() => {
        logger.log(
          'handleScrollToBottom',
          JSON.stringify({
            isFetching,
            isDuperbrowseActive,
            hasMore,
            error,
          })
        );
        if (isFetching || isDuperbrowseActive || !hasMore || error) {
          return;
        }

        fetchNext();
      }, FETCH_MORE_THROTTLE),
    [fetchNext, hasMore, isFetching, isDuperbrowseActive, error]
  );

  const isMobile = useContext(MobileContext);
  const { isLoggedIn } = useContext(SessionContext);
  const shouldRequireInfiniteScrollStart =
    isInfiniteScroll && !isLoggedIn && isMobile;
  const [mobileInfScrollStarted, setMobileInfScrollStarted] = useState(false);

  useEffect(() => {
    return () => {
      handleScrollToBottom.cancel();
    };
  }, [handleScrollToBottom]);

  return (
    <>
      {children}
      {isFetching && <LoadingIndicator />}
      {hasMore && shouldRequireInfiniteScrollStart && (
        <div
          className={cn(
            s['start-infscroll-button'],
            mobileInfScrollStarted && s['hidden']
          )}
        >
          <SolidButton
            icon={ArrowDown}
            postfixIcon={ArrowDown}
            variant={'approval-green'}
            size={'large'}
            href={generatePaginationUrl((currentPage ?? 0) + 1, requestUrl)}
            onClick={e => {
              e.preventDefault();
              setMobileInfScrollStarted(true);
            }}
          >
            {t('common.startInfiniteScroll')}
          </SolidButton>
        </div>
      )}
      {!isInfiniteScroll && (
        <div className={s['paged']}>
          <Pagination
            currentPage={currentPage}
            totalPages={
              totalPages ? totalPages : hasMore ? currentPage + 1 : currentPage
            }
            showPageNumbers={!!totalPages}
            getPageUrl={page => generatePaginationUrl(page, requestUrl)}
          />
        </div>
      )}
      {(mobileInfScrollStarted ||
        (isInfiniteScroll && !shouldRequireInfiniteScrollStart)) && (
        <OnScrollBottom
          checkOnMount
          checkOnUpdate={hasMore}
          onScrolledToBottom={handleScrollToBottom}
          distanceToBottom={DISTANCE_FROM_BOTTOM_TO_FETCH_MORE}
          distanceUnit={DISTANCE_UNIT.PX}
        />
      )}
    </>
  );
};
StreamPagination.displayName = 'StreamPagination';

export default StreamPagination;
