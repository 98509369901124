import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '@wix/da-ds/pkg/formControls/dsPresets/TextInput';
import CloseIcon from '@wix/da-ds/pkg/Icons/16x16/Close';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import IconButton from '@wix/da-shared-react/pkg/Button/IconButton';

export interface Props {
  className?: string;
  searchInputValue: string;
  setSearchInputValue: (value: string) => void;
  setSearchQuery: (value: string) => void;
  clearSearch: () => void;
}

export const SearchField: React.FC<Props> = ({
  clearSearch,
  setSearchQuery,
  searchInputValue,
  className,
  setSearchInputValue,
}) => {
  const { t } = useTranslation();

  const closeSearch = () => {
    clearSearch();
  };

  const onInputEnter = () => {
    setSearchQuery(searchInputValue);
  };

  return (
    <div className={className}>
      <TextInput
        autoFocus
        id="gallery-search-input"
        label={t('pages.gallections.search.searchAll')}
        isLabelHidden={true}
        value={searchInputValue}
        placeholder={t('pages.gallections.search.whatAreYouLookingFor')}
        onChange={e => setSearchInputValue(e.target.value)}
        postfix={
          <IconButton aria-label={t('common.close')} onClick={closeSearch}>
            <CloseIcon size={IconSize.SMALLER} />
          </IconButton>
        }
        onKeyDown={e => {
          switch (e.key) {
            case 'Enter':
              onInputEnter();
              break;
            case 'Escape':
              closeSearch();
              break;
            default:
              break;
          }
        }}
        noBottomSpacing
      />
    </div>
  );
};
SearchField.displayName = 'SearchField';

export default SearchField;
