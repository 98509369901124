import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './SortOrderSwitcher';
import { AppState } from '../../../../../types/store';
import { getSortOrder } from '../../../../selectors/gallectionSection';
import { setSortOrder } from '../../../../actions/gallectionSection';

export type StateProps = Pick<Props, 'sorderOrder'>;
export type DispatchProps = Pick<Props, 'setSortOrder'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  sorderOrder: getSortOrder(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  setSortOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
