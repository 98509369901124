import { GridBreakpoint } from '@wix/da-shared-react/pkg/Grid';

export function getBreakpoints(elementHeight: number): GridBreakpoint[] {
  return [
    {
      maxWidth: 735,
      elementsPerRow: 1,
      elementHeight,
    },
    {
      maxWidth: 1024,
      elementsPerRow: 2,
      elementHeight,
    },
    {
      maxWidth: 1280,
      elementsPerRow: 3,
      elementHeight,
    },
    {
      maxWidth: 1536,
      elementsPerRow: 4,
      elementHeight,
    },
    {
      maxWidth: 1920,
      elementsPerRow: 5,
      elementHeight,
    },
    {
      maxWidth: 9999,
      elementsPerRow: 6,
      elementHeight,
    },
  ];
}
