import { createSelector } from 'reselect';
import { groupSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/group';
import { GROUPS_ROLE_ID_FOUNDER } from '@wix/da-shared-react/pkg/utils/constants';
import { getAllDataEntitiesForSchema } from '@wix/da-shared-react/pkg/redux/entities';
import { getProfileOwnerExtendedGroup } from './users';

export const getAllGroupsKeyedByIds = state =>
  getAllDataEntitiesForSchema(state, groupSchema);

export const getGroupsByIds = createSelector(
  [getAllGroupsKeyedByIds, (state, ids) => ids],
  (allGroupsKeyedByIds, ids) => ids.map(id => allGroupsKeyedByIds[id])
);

export const getGroupApps = state => getProfileOwnerExtendedGroup(state)?.apps;

export const getIsGroupMember = state =>
  getProfileOwnerExtendedGroup(state)?.isMember === true;

export const getIsInGroup = state =>
  getProfileOwnerExtendedGroup(state)?.isIngroup === true;

export const getIsGroupAdmin = state =>
  getProfileOwnerExtendedGroup(state)?.isAdmin === true;

export const getIsGroupFounder = state =>
  getProfileOwnerExtendedGroup(state)?.roletypeId === GROUPS_ROLE_ID_FOUNDER;

export const getCanLeave = state =>
  getProfileOwnerExtendedGroup(state)?.apps?.join?.canLeave;
