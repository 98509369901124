import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';

import { GROUP_NEWEST_MEMBERS_IDS_STREAM } from '../../../streams';
import UserStream from '../../_partials/UserStream';

interface Props {
  moduleId: number;
  widgetBoxProps?: WidgetBoxProps;
}

const NewestMembersBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  return (
    <WidgetBox
      header={t(`widgets.newest_members.header`)}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      <UserStream streamId={GROUP_NEWEST_MEMBERS_IDS_STREAM} />
    </WidgetBox>
  );
};
NewestMembersBox.displayName = 'NewestMembersBox';

export default NewestMembersBox;
