import React from 'react';
import { PapiBadge, PapiUser } from '@wix/da-papi-types';
import ElementQueries from '@wix/da-shared-react/pkg/ElementQueries';
import BadgeBox from './BadgeBox';
import classnames from 'classnames';

import s from './Badges.scss';

export interface Props {
  items: PapiBadge[];
  className?: string;
  widthBreakpoints?: number[];
  profileOwnerUser: PapiUser;
}

const Badges: React.FC<Props> = ({
  items,
  className,
  widthBreakpoints,
  profileOwnerUser,
}) => {
  return (
    <ElementQueries
      className={classnames(s['grid'], className)}
      widthBreakpoints={widthBreakpoints || [640, 800]}
    >
      {items && items.map((item, i) => (
        <BadgeBox
          key={i}
          item={item}
          className={s['grid-item']}
          profileOwnerUser={profileOwnerUser}
        />
      ))}
    </ElementQueries>
  );
};
Badges.displayName = 'Badges';

export default Badges;
