import React, { Suspense, lazy } from 'react';
import WidgetConfigPopover from '@wix/da-gruser-shared/pkg/components/WidgetBox/lego/WidgetConfigPopover';

const FeaturedDeviationConfigPopover = lazy(
  () =>
    import(
      /* webpackChunkName: "owner" */ '../../FeaturedDeviationConfigPopover'
    )
);

type Props = any;

const FeaturedDeviationConfigPopoverLazy: React.FC<Props> = props => {
  return (
    <Suspense fallback={<WidgetConfigPopover isLoading onClose={() => null} />}>
      <FeaturedDeviationConfigPopover {...props} />
    </Suspense>
  );
};
FeaturedDeviationConfigPopoverLazy.displayName =
  'FeaturedDeviationConfigPopoverLazy';

export default FeaturedDeviationConfigPopoverLazy;
