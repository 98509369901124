import { createAction } from 'redux-actions';
import { PapiCustomboxTemplate } from '@wix/da-papi-types';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';
import { reduxKey } from '../../types/customBoxTemplates';

export const fetchMoreCustomboxTemplates = createCallbackActionCreator(
  `${reduxKey}.FETCH_MORE_CUSTOMBOX_TEMPLATES`
);

export const saveCustomboxWithTemplate = createAction(
  `${reduxKey}.SAVE_CUSTOMBOX_WITH_TEMPLATE`,
  (moduleId: number, template: PapiCustomboxTemplate) => ({
    moduleId,
    template,
  })
);

export const cacheChosenTemplate = createAction(
  `${reduxKey}.CACHE_CHOSEN_TEMPLATE`,
  (template: PapiCustomboxTemplate) => ({ template })
);

export const clearCachedChosenTemplate = createAction(
  `${reduxKey}.CLEAR_CACHED_CHOSEN_TEMPLATE`,
  () => ({})
);
