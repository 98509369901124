import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { Action } from 'redux-actions';
import { ModuleType } from '../../../types/modules';
import { setCurrentPage } from '../../actions/pageInfo';
import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import { ModuleName } from '@wix/da-gruser-shared/pkg/types/modules';
import { initializeThread } from '@wix/da-shared-react/pkg/CommentThread/actionCreators';
import { getCommentableTypeForDeviationType } from '@wix/da-shared-react/pkg/DeviationPage/redux/helpers';
import { PapiDeviation } from '@wix/da-papi-types';
import {
  FEED_COMMENTS_ITEMS_PER_FETCH,
  FEED_COMMENTS_MAX_DEPTH,
} from '../../streams/posts';

// TODO: All functions should work with sanitized module data
export function getModuleData(modules: any[], name: string, zonename?: string) {
  if (!modules) {
    return;
  }
  if (zonename !== undefined) {
    return findModuleData(
      modules,
      mod => mod.name === name && mod.zonename === zonename
    );
  }
  return findModuleData(modules, mod => mod.name === name);
}

export const findModuleData = (
  modules: any[],
  predicate: (module) => boolean
) => {
  const found = modules.find(predicate);
  if (!found) {
    return;
  }
  // We changed from generic module_data on the backend to properly
  // spec'd module data structures. To do this we made module_data
  // contain unique key for each module type. We use the data_key
  // to lookup the data for the given module. If not present we skip
  // and assume backend returned the old structure.
  const { dataKey } = found.moduleData;
  if (dataKey) {
    return found.moduleData[camelCase(dataKey)];
  }
  return found.moduleData;
};

export function getRawSectionModules(responseData) {
  return get(responseData, 'gruser.page.modules', []);
}

export function getModuleByType(modules, type: ModuleType) {
  return modules.find(module => module.type === type);
}

export function getModulesByType(modules, type: ModuleType) {
  return modules.filter(module => module.type === type);
}

export function getPageInfoInitActions(responseData, requestParams) {
  const currentPage = requestParams.page ? parseInt(requestParams.page) : 1;
  return [setCurrentPage(currentPage)];
}

export function getDeviationsCommentsInitActions(
  deviations: PapiDeviation[] = []
) {
  return [
    ...(deviations || []).map(deviation =>
      initializeThread({
        thread: {
          commentableItemId: deviation.deviationId,
          commentableTypeId: getCommentableTypeForDeviationType(deviation),
          items: [],
          hasMore: !!deviation.stats?.comments,
          total: deviation.stats?.comments ?? 0,
          canPostComment: !!deviation.isCommentable,
        },
        maxDepth: FEED_COMMENTS_MAX_DEPTH,
        itemsPerFetch: FEED_COMMENTS_ITEMS_PER_FETCH,
      })
    ),
  ];
}

export function getProfileStats(responseData) {
  const { pageExtraData } = responseData;
  return {
    ...pageExtraData.stats,
  };
}

export function getProfileOwner(responseData) {
  const { pageExtraData, owner } = responseData;
  return {
    user: owner,
    isGroup: owner.isGroup,
    tagline: pageExtraData.gruserTagline,
    isWatching: pageExtraData.isWatching,
    isWatcher: pageExtraData.isWatcher,
    showMonetizationPromo: pageExtraData.showMonetizationPromo,
    stats: getProfileStats(responseData),
  };
}

type WidgetConfigs = Partial<Record<ModuleName, WidgetConfig>>;

export const getCustomInitActionsForWidgets = (
  responseData,
  requestParams,
  widgetConfigs: WidgetConfigs
) => {
  if (!responseData.gruser) {
    return [];
  }
  const { page: gruserPage, ...pureGruser } = responseData.gruser;

  const customInitActionsForModules: Action<any>[] = [];

  const modules = responseData.gruser?.page?.modules || [];
  modules.forEach(module => {
    const { id: moduleId, moduleData, name: moduleName } = module;
    const widgetConfig = widgetConfigs[camelCase(moduleName)];
    if (!widgetConfig?.initActionsCreator) {
      return;
    }
    const { dataKey } = moduleData;
    const actualModuleData = moduleData[camelCase(dataKey)];
    const customInitActions = widgetConfig.initActionsCreator(
      pureGruser,
      moduleId,
      actualModuleData,
      requestParams
    );
    customInitActionsForModules.push(...customInitActions);
  });

  return customInitActionsForModules;
};
