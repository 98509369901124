import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Torpedo from '@wix/da-shared-react/pkg/Torpedo/DeviationTorpedo';
import Grid from '@wix/da-shared-react/pkg/Grid/DeviationGrid';
import LoadableDuperbrowseContext from '../../../../contexts/LoadableDuperbrowseContext';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { PapiDeviation } from '@wix/da-papi-types';
import { GallectionType } from '../../../../../types/gallection';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';

export interface Props {
  streamId: string;
  gallectionType: GallectionType;
  deviations: PapiDeviation[];
}

const TORPEDO_DEFAULT_SIZE = {
  desktop: { width: 1024, height: 0 },
  mobile: { width: 320, height: 0 },
};
const TORPEDO_CONTAINER_SIZE = { width: 1024 };

export const GallectionDeviationsTorpedo: React.FC<Props> = ({
  streamId,
  deviations,
  gallectionType,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  return (
    <LoadableDuperbrowseContext
      streamId={streamId}
      parent={{
        title: t(
          gallectionType === GallectionType.GALLERY
            ? 'duperbrowse.backButton.profile.gallery'
            : 'duperbrowse.backButton.profile.favourites'
        ),
      }}
    >
      <BiLoggerContextProvider value={{ widgetname: 'folder_deviations' }}>
        {isMobile ? (
          <Grid
            items={deviations}
            cookieType={MeasuredCookieType.USER_PROFILE_GALLECTION_CONTENT}
            spacing={8}
            enableScrollOptim
            allowMobileCard
          />
        ) : (
          <Torpedo
            cookieType={MeasuredCookieType.USER_PROFILE_GALLECTION_CONTENT}
            cookieDim={'width'}
            defaultSize={TORPEDO_DEFAULT_SIZE}
            container={TORPEDO_CONTAINER_SIZE}
            items={deviations}
            enableScrollVisibilityOptimisation
          />
        )}
      </BiLoggerContextProvider>
    </LoadableDuperbrowseContext>
  );
};
GallectionDeviationsTorpedo.displayName = 'GallectionDeviationsTorpedo';

export default GallectionDeviationsTorpedo;
