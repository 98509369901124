import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { BiData, GiftSelectClickBiEvent } from '@wix/da-bi/pkg/events';
import { PapiBadge, PapiUser } from '@wix/da-papi-types';
import AddIcon from '@wix/da-ds/pkg/Icons/Add';
import BiDiv from '@wix/da-bi/pkg/Components/BiDiv';
import { getProfileOwnerUser } from '../../../../../../selectors/users';
import { Url, CHECKOUT_IDS } from '@wix/da-url';
import { pushBadgeModal } from '@wix/da-shared-react/pkg/Modals/redux/actionCreators';
import s from '../../BadgeBox/BadgeBox.scss';

export interface Props {
  item: PapiBadge;
  className?: string;
}

const BadgeGiftBox: React.FC<Props> = ({ className, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileOwnerUser: PapiUser = useSelector(getProfileOwnerUser);
  const title = t!('widgets.badgesbox.gift.give', { title: item.title });

  return (
    <div className={classnames(s['root'], s['gift'], className)}>
      <BiDiv
        className={s['flex']}
        onClick={() => {
          if (item.name === 'cake') {
            window.location.assign(
              `${Url.checkoutLink(CHECKOUT_IDS.CAKE)}&foruserid=${
                profileOwnerUser?.userId
              }`
            );
            return;
          }
          dispatch(
            pushBadgeModal(
              (profileOwnerUser && profileOwnerUser.username) || ''
            )
          );
        }}
        biData={BiData<GiftSelectClickBiEvent>({
          evid: 275,
          recipientid: profileOwnerUser && profileOwnerUser.useridUuid,
          menu_item_name: `give ${item.name} badge`,
        } as any)}
      >
        <div className={s['badge-info']}>
          <AddIcon className={s['icon']} condensed withPadding />
        </div>
        <div className={s['badge-title']}>
          <span className={s['badge-title-text']}>{title}</span>
        </div>
      </BiDiv>
    </div>
  );
};
BadgeGiftBox.displayName = 'BadgeGiftBox';
export default BadgeGiftBox;
