import { schema } from '@wix/da-shared-react/pkg/redux/normalizr';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { PapiNcMessageObjectSchema } from '@wix/da-feedback-shared/pkg/redux/normalizr/schemas';

export const GroupAdminsModuleDataResultSchema = new schema.Object({
  user: userSchema,
});
export const GroupAdminsModuleDataResultsSchema = [
  GroupAdminsModuleDataResultSchema,
];

export const GroupMessageLogResultsSchema = [PapiNcMessageObjectSchema];

export const GroupNoteSchema = new schema.Entity(
  'groupnote',
  {
    group: userSchema,
    sender: userSchema,
  },
  {
    idAttribute: 'noteId',
  }
);
export const GroupNotesSchema = [GroupNoteSchema];

export const GroupActionLogResultSchema = new schema.Entity(
  'group_actionlog',
  {
    originator: userSchema,
  },
  {
    idAttribute: 'eventId',
  }
);

export const GroupActionLogResultsSchema = [GroupActionLogResultSchema];
