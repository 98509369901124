import React from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@wix/da-ds/pkg/Icons/24x24/Edit';
import TextButton from '@wix/da-ds/pkg/Buttons/TextButton';
import {
  BiContextProvidedValue,
  BiData,
  EditWidgetClickBiEvent,
} from '@wix/da-bi/pkg/events';

export interface Props {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  iconRef?: React.RefCallback<any>;
}

const EditConfigButton: React.FC<Props> = ({
  className,
  onClick,
  disabled,
  iconRef,
}) => {
  const { t } = useTranslation();
  return (
    <TextButton
      variant="edit-purple"
      size="medium"
      icon={EditIcon}
      onClick={onClick}
      disabled={disabled}
      className={className}
      data-role="edit-control"
      biData={BiData<EditWidgetClickBiEvent>({
        evid: 278,
        widgetname: BiContextProvidedValue,
        widgetzone: BiContextProvidedValue,
      })}
    >
      {t!('common.edit')}
    </TextButton>
  );
};

EditConfigButton.displayName = 'EditConfigButton';

export default EditConfigButton;
