import React, { useState } from 'react';
import classnames from 'classnames';
import {
  gallectionTypeToResourceType,
  GallectionType,
} from '@wix/da-shared-react/pkg/types/gallection';
import AddItem from '@wix/da-ds/pkg/AddItem';
import MediaManager, {
  getMediaSourceFromGallectionType,
} from '@wix/da-shared-react/pkg/MediaPopup/MediaManager';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import { useTranslation } from 'react-i18next';
import { PapiUser } from '@wix/da-papi-types';
import s from './AddNewFolderCard.scss';

export type Props = {
  width: number;
  className?: string;
  gallectionType: GallectionType;
  owner: PapiUser;
};
export const AddNewFolderCard: React.FC<Props> = ({
  className,
  width,
  gallectionType,
  owner,
}) => {
  const { t } = useTranslation();
  const [isMediaManagerShown, setShowMediaManager] = useState(false);
  const closeMediaManagerAndReload = event => {
    setShowMediaManager(false);
    event && event.preventDefault();
    window.location.reload();
  };

  return (
    <>
      <div className={classnames(className, s['root'])} style={{ width }}>
        <AddItem
          className={s['card']}
          title={t(`pages.gallections.addNew.var.${gallectionType}`)}
          onClick={() => setShowMediaManager(true)}
          biData={BiData<DefaultBiEvent>({
            evid: 2,
            click_info: 'gallectionsSlider.newFolder',
            typeid: gallectionTypeToResourceType(gallectionType),
          })}
        />
      </div>
      {isMediaManagerShown && (
        <MediaManager
          initialMediaSource={getMediaSourceFromGallectionType(gallectionType)}
          withMediaSourceSelector={false}
          withNewGallectionPopupOpen
          gruser={owner}
          onDone={closeMediaManagerAndReload}
          onClose={closeMediaManagerAndReload}
        />
      )}
    </>
  );
};

AddNewFolderCard.displayName = 'AddNewFolderCard';
export default AddNewFolderCard;
