import React, { useMemo } from 'react';
import classnames from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import uniqueId from 'lodash/uniqueId';
import {
  PopperDropdownManager,
  PopperDropdownToggle,
} from '@wix/da-ds/pkg/Dropdown/PopperDropdownLayout';
import { useDropdownItems } from '@wix/da-ds/pkg/Dropdown/hooks/useDropdownItems';
import StandardDropdownMenu from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown/StandardDropdownMenu';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import ArrowDownIcon from '@wix/da-ds/pkg/Icons/24x24/ArrowDown';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { getDeviationEntityId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';

import s from './TiersDropdown.scss';

export interface Props {
  className?: string;
  tiers: PapiDeviation[];
  selectedTier?: PapiDeviation;
  setSelectedTierEntityId: (tierId: number) => void;
}

const TiersDropdown: React.FC<Props> = ({
  className,
  tiers,
  selectedTier,
  setSelectedTierEntityId,
}) => {
  const id = uniqueId('tiersdropdown');
  const items: NullableDropdownItem[] = useMemo(
    () =>
      tiers.map(tier => ({
        label: tier.title,
        value: getDeviationEntityId(tier),
      })),
    [tiers]
  );
  const { filteredItems } = useDropdownItems(items);

  return (
    <div className={classnames(s['root'], className)}>
      <PopperDropdownManager isInPopup disabled={tiers.length === 1}>
        <PopperDropdownToggle className={s['toggle']}>
          <span>{selectedTier?.title}</span>
          {tiers.length > 1 && (
            <ArrowDownIcon className={s['caret']} size={IconSize.MEDIUM} />
          )}
        </PopperDropdownToggle>
        <StandardDropdownMenu
          id={id}
          role={'menu'}
          placement="bottom-start"
          className={s['menu']}
          items={filteredItems}
          onSelect={setSelectedTierEntityId}
          menuScrollable
          allowFlip={false}
        />
      </PopperDropdownManager>
    </div>
  );
};
TiersDropdown.displayName = 'TiersDropdown';

export default TiersDropdown;
