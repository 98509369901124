import { handleActions } from 'redux-actions';
import {
  setCurrentPage,
  setTotalPages,
  setBiMetadata,
  setRequestParams,
} from '../actions/pageInfo';
import PageInfo from '../../types/pageInfo';

const defaultState = {
  currentPage: 1,
  totalPages: 1,
};

export default handleActions<any, any>(
  {
    [setCurrentPage.toString()]: (
      state: PageInfo,
      action: ReturnType<typeof setCurrentPage>
    ) => ({
      ...state,
      currentPage: action.payload,
    }),
    [setTotalPages.toString()]: (
      state: PageInfo,
      action: ReturnType<typeof setTotalPages>
    ) => ({
      ...state,
      totalPages: action.payload,
    }),
    [setBiMetadata.toString()]: (
      state: PageInfo,
      action: ReturnType<typeof setBiMetadata>
    ) => ({
      ...state,
      biMetadata: action.payload.biMetadata,
    }),
    [setRequestParams.toString()]: (
      state: PageInfo,
      action: ReturnType<typeof setRequestParams>
    ) => ({
      ...state,
      requestParams: action.payload,
    }),
  },
  defaultState
);
