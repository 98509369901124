import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import StandardThumb from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Standard';
import useDeviationPermissions from '../../../../../../../utils/hooks/useDeviationPermissions';

export interface Props {
  className: string;
  item: PapiDeviation;
  editItem: () => void;
  unfeatureItem: () => void;
}

const FeaturedListItem: React.FC<Props> = ({
  item,
  className,
  editItem,
  unfeatureItem,
}) => {
  const { t } = useTranslation();
  const { canFeature, canEdit } = useDeviationPermissions(item);
  const extraActions = [
    canFeature && {
      label: t('widgets.feed.unfeature'),
      onClick: unfeatureItem,
    },
    canEdit && {
      label: t('widgets.feed.edit'),
      onClick: editItem,
    },
  ].filter(Boolean);
  return (
    <StandardThumb
      width={300}
      height={280}
      deviation={item}
      className={className}
      extraActions={extraActions.length ? extraActions : undefined}
    />
  );
};
FeaturedListItem.displayName = 'FeaturedListItem';

export default FeaturedListItem;
