import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Zone from '@wix/da-gruser-shared/pkg/components/Zone';
import ZoneGroup from '@wix/da-gruser-shared/pkg/components/ZoneGroup';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import LazyContext from '@wix/da-shared-react/pkg/LazyLoadingImage/LazyContext';
import { getExhibitionistStripWidgetConfig } from '@wix/da-shared-react/pkg/widgets/Exhibitionist/widgetConfig';
import { getModuleByModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import useGruser from '@wix/da-gruser-shared/pkg/hooks/useGruser';
import { getIsGroup } from '@wix/da-shared-react/pkg/User/helpers';
import { getProfileOwnerUser } from '../../../selectors/users';
import { getHomeWidgetConfigs } from './homeWidgetConfigs';

import s from './HomeSection.scss';

export interface Props {}

const HomeSection: React.FC<Props> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gruser } = useGruser();
  const profileOwnerUser = useSelector(getProfileOwnerUser);
  const isMobile = useContext(MobileContext);

  const isGroup = getIsGroup(profileOwnerUser);

  const homeWidgetConfigs = getHomeWidgetConfigs({
    t,
    dispatch,
    isGroup,
  });
  const topBarWidgetConfigs = {
    ...homeWidgetConfigs,
    exhibitionist: getExhibitionistStripWidgetConfig({
      t,
      dispatch,
      isGroup,
    }),
  };

  const hasAboutMeInstalled = Boolean(
    useSelector<any, any>(state =>
      getModuleByModuleName(state, gruser, 'about')
    )
  );
  const hasMegaboxInstalled = Boolean(
    useSelector<any, any>(state =>
      getModuleByModuleName(state, gruser, 'megabox')
    )
  );

  const renderOneColumn = () => (
    <ZoneGroup
      disableTopAddWidget={true}
      widgetConfigs={homeWidgetConfigs}
      className={s['zone-group']}
    >
      <Zone name="topRight" widgetConfigs={homeWidgetConfigs} />
      <Zone name="megaBar" direction="row" widgetConfigs={homeWidgetConfigs} />
      <Zone name="topBar" widgetConfigs={homeWidgetConfigs} />
      {
        // We originally launched Eclipse with Exhibionist module being
        // pre-installed in the topLeft zone, but we've later disabled option to
        // install it there. If we do not include this zone all the accounts with
        // pre-installed exhibitionist modules will lose them.
      }
      <Zone name="topLeft" widgetConfigs={homeWidgetConfigs} />
      <Zone name="midRight" widgetConfigs={homeWidgetConfigs} />
      <Zone name="bottomLeft" widgetConfigs={homeWidgetConfigs} />
      <Zone name="bottomRight" widgetConfigs={homeWidgetConfigs} />
    </ZoneGroup>
  );

  const renderTwoColumns = () => (
    <>
      {/*
            If the MegaBox is installed, we show it in a separate
            ZoneGroup, so that it does not merge the AddWidgets with the Zone
            below it and to ensure it is shown on top. If it is not installed on
            the other hand, we want to collapse the Zone and merge the AddWidgets
            from the MEGA_BAR and the TOP_BAR zones into one, that's why we have
            them as the single ZoneGroup if Megabox is not installed.
          */}
      {!hasMegaboxInstalled && (
        <ZoneGroup
          className={s['zone-top']}
          widgetConfigs={topBarWidgetConfigs}
        >
          <Zone
            name="megaBar"
            direction="row"
            widgetConfigs={topBarWidgetConfigs}
          />
          <Zone name="topBar" widgetConfigs={topBarWidgetConfigs} />
        </ZoneGroup>
      )}
      {hasMegaboxInstalled && (
        <>
          <Zone
            name="megaBar"
            direction="row"
            widgetConfigs={topBarWidgetConfigs}
          />
          <ZoneGroup
            className={s['zone-top']}
            widgetConfigs={topBarWidgetConfigs}
          >
            <Zone name="topBar" widgetConfigs={topBarWidgetConfigs} />
          </ZoneGroup>
        </>
      )}

      <div className={s['flex-wrap']}>
        <ZoneGroup
          className={s['zone-column-left']}
          widgetConfigs={homeWidgetConfigs}
          disableTopAddWidget={hasAboutMeInstalled}
        >
          <Zone name="topLeft" widgetConfigs={homeWidgetConfigs} />
          <Zone name="bottomLeft" widgetConfigs={homeWidgetConfigs} />
        </ZoneGroup>
        <ZoneGroup
          className={s['zone-column-right']}
          widgetConfigs={homeWidgetConfigs}
        >
          <Zone name="topRight" widgetConfigs={homeWidgetConfigs} />
          <Zone name="midRight" widgetConfigs={homeWidgetConfigs} />
          <Zone name="bottomRight" widgetConfigs={homeWidgetConfigs} />
        </ZoneGroup>
      </div>
    </>
  );

  return (
    <div className={s['root']}>
      <BiLoggerContextProvider
        value={{ creatorid: profileOwnerUser.useridUuid }}
      >
        <LazyContext.Provider value={true}>
          {isMobile ? renderOneColumn() : renderTwoColumns()}
        </LazyContext.Provider>
      </BiLoggerContextProvider>
    </div>
  );
};
HomeSection.displayName = 'HomeSection';

export default HomeSection;
