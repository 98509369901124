import { SectionsState } from '../../types/sections';
import {
  fetchSection,
  fetchSectionSuccess,
  fetchSectionPrivateSuccess,
  setSectionStreamId,
} from '../actions/sections';

const defaultState = {
  active: 'home',
  streamId: '',
  isLoading: true,
  isPrivate: false,
} as SectionsState;

export default function sections(state = defaultState, action): SectionsState {
  const { type, payload } = action;
  switch (type) {
    case fetchSection.toString():
      return {
        ...state,
        active: payload.section,
        isLoading: true,
      };
    case fetchSectionSuccess.toString():
      return {
        ...state,
        active: payload.section || state.active,
        isLoading: false,
      };
    case fetchSectionPrivateSuccess.toString():
      return {
        ...state,
        isPrivate: payload.isPrivate || false,
      };
    case setSectionStreamId.toString():
      return {
        ...state,
        streamId: payload.streamId,
      };
    default:
      return state;
  }
}
