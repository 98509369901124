import React from 'react';
import { useTranslation } from 'react-i18next';
import { PapiModuleFeaturedDeviation } from '@wix/da-papi-types';
import snakeCase from 'lodash/snakeCase';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import useModulePrivileges from '@wix/da-gruser-shared/pkg/hooks/useModulePrivileges';
import PremiumFolderDataIcon from '@wix/da-shared-react/pkg/GallectionFolderDropdown/_partials/PremiumFolderDataIcon';
import { useModuleName } from '@wix/da-gruser-shared/pkg/hooks/useModule';
import FeaturedDeviation from '../FeaturedDeviation';
import EmptyView from '../FeaturedDeviationEmpty';

import s from './FeaturedDeviationBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const FeaturedDeviationBox: React.FC<Props> = ({
  moduleId,
  widgetBoxProps,
}) => {
  const { t } = useTranslation();
  const moduleName = useModuleName(moduleId);
  const moduleNameSnakeCase = snakeCase(moduleName);

  const { moduleData } = useModuleData<PapiModuleFeaturedDeviation>(moduleId);
  const modulePrivs = useModulePrivileges(moduleId);
  const { title, deviation } = moduleData;
  const isEmpty = !deviation;

  if (isEmpty && !modulePrivs?.edit) {
    return null;
  }

  return (
    <WidgetBox
      header={
        <>
          <PremiumFolderDataIcon
            premiumFolderData={deviation?.premiumFolderData}
            size={IconSize.COMPACT}
            className={s['premium-folder-icon']}
          />
          {title || t(`widgets.${moduleNameSnakeCase}.header`)}
        </>
      }
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      {!isEmpty ? (
        <FeaturedDeviation moduleId={moduleId} />
      ) : (
        <EmptyView moduleId={moduleId} />
      )}
    </WidgetBox>
  );
};
FeaturedDeviationBox.displayName = 'FeaturedDeviationBox';

export default FeaturedDeviationBox;
