import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { actions as modalActions } from '@wix/da-shared-react/pkg/Modals';
import { AppState } from '../../../../../../types/store';
import {
  getProfileOwner,
  getProfileOwnerUser,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import {
  fetchProfileOwnerGiveState,
  fetchProfileOwnerGiveStateSuccess,
} from '../../../../../actions/profileOwner';
import { Props } from './GiveLlamaButton';

type StateProps = Pick<
  Props,
  'canBeGivenLlama' | 'isOwner' | 'profileOwnerUser'
>;
type DispatchProps = Pick<
  Props,
  'initGiveState' | 'onLlamaBadgeSent' | 'pushBadgeModal'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  canBeGivenLlama: getProfileOwner(state).giveState?.canBeGivenLlama,
  isOwner: isCurrentUserProfileOwner(state),
  profileOwnerUser: getProfileOwnerUser(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  initGiveState: () => dispatch(fetchProfileOwnerGiveState()),
  onLlamaBadgeSent: () =>
    dispatch(fetchProfileOwnerGiveStateSuccess({ canBeGivenLlama: false })),
  pushBadgeModal: (
    username: string,
    onBadgeSent?: () => void,
    onClose?: () => void
  ) => dispatch(modalActions.pushBadgeModal(username, onBadgeSent, onClose)),
});

export default connect(mapStateToProps, mapDispatchToProps);
