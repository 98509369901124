import { Action } from 'redux-actions';
import { PapiModuleGroupMembers } from '@wix/da-papi-types';
import { Gruser } from '@wix/da-gruser-shared/pkg/types/papi';
import { withOffset } from '@wix/da-shared-react/pkg/Stream';
import { normalize } from '@wix/da-shared-react/pkg/redux/normalizr';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import { updateModule } from '@wix/da-gruser-shared/pkg/redux/actions/modules';

import {
  GROUP_MEMBERS_IDS_STREAM,
  GROUPS_PER_FETCH,
} from '../../../../streams';

export default (
  gruser: Gruser,
  moduleId: number,
  moduleData: PapiModuleGroupMembers
): Action<any>[] => {
  const { result, entities } = normalize(moduleData.results || [], [
    userSchema,
  ]);
  return [
    {
      ...withOffset.actions.initialize({
        streamId: GROUP_MEMBERS_IDS_STREAM,
        streamParams: {
          moduleId,
        },
        itemsPerFetch: GROUPS_PER_FETCH,
        items: result,
        entities,
        hasMore: moduleData.hasMore,
      }),
      payload:
        null /* stream intialize actions are not compatible with Action<any> */,
    },
    updateModule(gruser, {
      id: moduleId,
      moduleMeta: {
        isInitialized: true,
      },
    }),
  ];
};
