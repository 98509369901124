import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import { PapiGruserModuleCommissions } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import AddItemCard from '../../_partials/AddItemCard';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import DuperbrowseContext from '../../../../../contexts/DuperbrowseContext';
import CommissionSliderMobile from './CommissionSliderMobile';
import CommissionThumbWithMenu from '../../_partials/CommissionThumbWithMenu';
import { MeasuredSlider as Slider } from '@wix/da-shared-react/pkg/Slider';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { PANEL_HEIGHT } from '@wix/da-shared-react/pkg/DeviationViews/Thumb/Shop';
import EmptyState from '../../_partials/EmptyState';
import DisabledOverlay from '../../_partials/DisabledOverlay';
import DisabledEmptyState from '../../_partials/DisabledEmptyState';
import { selectors as streamSelectors } from '@wix/da-shared-react/pkg/Stream';
import { openCommissionEditModal } from '@wix/da-shared-react/pkg/redux/commissions/actionCreators';
import { USER_PROFILE_SHOP_SUB_PATHS, Url } from '@wix/da-url';
import {
  getProfileOwner,
  isCurrentUserProfileOwner,
} from '../../../../../selectors/users';
import { getShopItemsStreamId } from '../../../../../streams/shop';
import { ShopItemType } from '../../../../../../types/shop';
import s from './CommissionSlider.scss';

export type Props = {
  moduleId: number;
  widgetBoxProps: Partial<WidgetBoxProps>;
};

const CommissionSlider: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useContext(MobileContext);
  const profileOwner = useSelector(getProfileOwner);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const { moduleData } = useModuleData<PapiGruserModuleCommissions>(moduleId);
  const { isOpenForCommissions } = moduleData;
  const streamId = getShopItemsStreamId(ShopItemType.COMMISSIONS);
  const commissions = useSelector(state =>
    getDeviationsByIds(state, streamSelectors.getItems(state, streamId))
  );
  const isEmpty = !commissions.length;
  const isClosedForCommissions = !isOpenForCommissions;
  // is closed for commissions - visitor
  // is closed for commissions - mobile
  const showDisabledState =
    (!isOwner && isClosedForCommissions) ||
    (isMobile && isClosedForCommissions);

  const openCommissionEditModalFromEmptyState = () => {
    dispatch(openCommissionEditModal('commission_emptystate'));
  };

  const openCommissionEditModalFromHeaderButton = () => {
    dispatch(openCommissionEditModal('commission_headerbutton'));
  };

  // - disabled state for visitor
  // - disabled mobile state
  const renderDisabledEmptyView = () => <DisabledEmptyState view="wide" />;

  // - empty commissions
  const renderEmptyView = () => {
    return (
      <EmptyState
        view="wide"
        shopItemType={ShopItemType.COMMISSIONS}
        buttonLabel={
          isOwner ? t('pages.shop.emptyState.commissions.createCTA') : undefined
        }
        onButtonClick={
          isOwner ? openCommissionEditModalFromEmptyState : undefined
        }
        biData={BiData<DefaultBiEvent>({
          evid: 2,
          click_info: 'create_commission',
          sectionname: 'commission',
        })}
      />
    );
  };

  if (isEmpty && !isOwner) {
    return null;
  }

  const renderCommissionSlider = () => (
    <DuperbrowseContext
      streamId={streamId}
      deviations={commissions}
      parent={{ title: t('duperbrowse.backButton.profile.shop') }}
    >
      {isMobile ? (
        <CommissionSliderMobile
          deviations={commissions}
          showAddItemCard={isOwner}
          onCreate={openCommissionEditModalFromEmptyState}
        />
      ) : (
        <div className={s['content']}>
          {!isOpenForCommissions && <DisabledOverlay />}
          <Slider
            className={s['slider']}
            renderSliderItems={elementSize => (
              <>
                {isOwner && (
                  <AddItemCard
                    title={t('pages.shop.commissions.addItem.title')}
                    subtitle={t('pages.shop.commissions.addItem.subtitle')}
                    onClick={openCommissionEditModalFromHeaderButton}
                    view={'small'}
                    biData={BiData<DefaultBiEvent>({
                      evid: 2,
                      click_info: 'create_commission',
                      sectionname: 'commission',
                    })}
                  />
                )}
                {commissions.map(commission => (
                  <CommissionThumbWithMenu
                    key={commission.deviationId}
                    deviation={commission}
                    width={elementSize.width}
                    height={elementSize.height + PANEL_HEIGHT}
                    withIndicators={false}
                  />
                ))}
              </>
            )}
          />
        </div>
      )}
    </DuperbrowseContext>
  );

  return (
    <WidgetBox
      header={t('common.commissions_other')}
      moduleId={moduleId}
      seeAllUrl={Url.userShopPath(
        profileOwner.user.username,
        USER_PROFILE_SHOP_SUB_PATHS.COMMISSIONS
      )}
      {...widgetBoxProps}
    >
      {isEmpty
        ? showDisabledState
          ? renderDisabledEmptyView()
          : renderEmptyView()
        : renderCommissionSlider()}
    </WidgetBox>
  );
};

export default CommissionSlider;
