import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { AppState } from '../../../../../../types/store';
import { actions as ModalActions } from '@wix/da-shared-react/pkg/Modals';
import { CTAModalsMap } from './constants';

import {
  isCurrentUserProfileOwner,
  getProfileOwnerUser,
} from '../../../../../selectors/users';

import { Props } from './EmptyState';

type StateProps = Pick<Props, 'isOwner' | 'profileOwner'>;
type DispatchProps = Pick<Props, 'openSubmitPostPopup'>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
  profileOwner: getProfileOwnerUser(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  openSubmitPostPopup: (params = {}) => {
    const modalType = CTAModalsMap[ownProps.postType];
    if (modalType) {
      dispatch(
        ModalActions.pushModal(modalType, {
          wrapInModal: false,
          params,
        })
      );
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
