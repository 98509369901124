import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PapiGallection } from '@wix/da-papi-types';
import EditConfigButton from '../../../UserWidgets/_partials/WidgetEditControls/EditConfigButton';
import MediaManager, {
  getMediaSourceFromGallectionType,
} from '@wix/da-shared-react/pkg/MediaPopup/MediaManager';
import { pushModal } from '@wix/da-shared-react/pkg/Modals/redux/basicActions';
import { ModalType } from '@wix/da-shared-react/pkg/Modals/redux/types';
import { AppState } from '../../../../../types/store';
import {
  getIsGroupProfile,
  getProfileOwnerUser,
} from '../../../../selectors/users';
import { getGallectionSectionType } from '../../../../selectors/gallectionSection';
import {
  getCanPerformPageAction,
  isUserProfileOwner,
} from '../../../../selectors/privs';
import { PagePriv } from '../../../../../types/pagePrivileges';

interface Props {
  className?: string;
  gallection?: PapiGallection;
}

const EditGallectionButton: React.FC<Props> = ({ className, gallection }) => {
  const [isShowingMM, setIsShowingMM] = useState(false);
  const dispatch = useDispatch();
  const gallectionType = useSelector((state: AppState) =>
    getGallectionSectionType(state)
  );
  const profileOwnerUser = useSelector((state: AppState) =>
    getProfileOwnerUser(state)
  );
  const canContribute = useSelector((state: AppState) =>
    getCanPerformPageAction(state, PagePriv.CAN_CONTRIBUTE_DEVIATIONS)
  );
  const isGroup = useSelector(getIsGroupProfile);
  const isProfileOwner = useSelector(isUserProfileOwner);

  const closeAndReload = event => {
    setIsShowingMM(false);
    dispatch(pushModal(ModalType.LOADING_SCREEN, { wrapInModal: false }));
    event && event.preventDefault();

    // Pressing ESC key to close the MM cancels the reload.
    // Let's postpone it by 1 cycle
    setTimeout(() => {
      // This should be a simple reload, but that
      // doesn't set the referrer on Firefox
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }, 1);
  };

  if (isGroup && !canContribute) {
    return null;
  }

  if (!isGroup && !isProfileOwner) {
    return null;
  }

  return (
    <>
      <EditConfigButton
        className={className}
        onClick={() => setIsShowingMM(true)}
      />
      {isShowingMM && (
        <MediaManager
          initialMediaSource={getMediaSourceFromGallectionType(gallectionType)}
          gruser={profileOwnerUser}
          gallectionFolder={gallection}
          onClose={closeAndReload}
          onDone={closeAndReload}
        />
      )}
    </>
  );
};

export default EditGallectionButton;
