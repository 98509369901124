import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './GallectionSimpleView';
import { AppState } from '../../../../../types/store';
import { setCurrentlyViewedFolderId } from '../../../../actions/gallectionSection';
import {
  getCurrentlyViewedFolder,
  getShouldHideFolderSlider,
} from '../../../../selectors/gallectionSection';

export type StateProps = Pick<Props, 'currentFolder' | 'hideFolderSlider'>;
export type DispatchProps = Pick<Props, 'setCurrentlyViewedFolderId'>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => {
  return {
    currentFolder: getCurrentlyViewedFolder(state),
    hideFolderSlider: getShouldHideFolderSlider(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  setCurrentlyViewedFolderId,
};

export default connect(mapStateToProps, mapDispatchToProps);
