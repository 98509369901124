import React from 'react';
import get from 'lodash/get';
import { useUniqueId } from '@wix/da-hooks/pkg/useUniqueId';
import { useController, useFormContext } from 'react-hook-form';
import TextArea from '@wix/da-ds/pkg/formControls/dsPresets/TextArea';

function TextAreaField({ name, label, defaultValue = '', rows = 5, ...props }) {
  const id = useUniqueId();
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <TextArea
      id={id}
      label={label}
      rows={rows}
      {...props}
      {...inputProps}
      details={error?.message}
      validationResult={error ? 'error' : undefined}
      ref={ref}
    />
  );
}

export default TextAreaField;
