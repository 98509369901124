import { WidgetConfig } from '@wix/da-gruser-shared/pkg/types/widget';
import defaultRemoveConfirmation from '@wix/da-shared-react/pkg/widgets/defaultRemoveConfirmation';
import FeedBox from './FeedBox';
import FeedConfigPopoverLazyLazy from './FeedConfigPopover/FeedConfigPopoverLazy/FeedConfigPopoverLazy';
import initActionsCreator from './redux/initModule';

export const getFeedWidgetConfig = ({ t, dispatch }): WidgetConfig => ({
  viewComponent: FeedBox,
  configPopoverComponent: FeedConfigPopoverLazyLazy,
  confirmRemove: () => defaultRemoveConfirmation({ t, dispatch }),
  widgetCard: {
    minuatureImageSrc:
      'https://st.deviantart.net/eclipse/widgets/miniatures/2022/il8-8-posts.svg',
    title: t('widgets.feed.addButtonHeader'),
    description: t('widgets.feed.description'),
  },
  initActionsCreator,
});
