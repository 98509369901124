import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './Interests.scss';

export interface Props {
  interests: {
    id: number;
    value: string;
  }[];
  className?: string;
}

const Interests: React.FC<Props> = ({ interests, className }) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(s['root'], className)}>
      {interests.map(interest => (
        <div key={interest.id} className={s['interest-group']}>
          <div className={s['interest-label']}>
            {t!(`widgets.aboutMe.interests.${interest.id}`)}
          </div>
          <div className={s['interest-value']}>{interest.value}</div>
        </div>
      ))}
    </div>
  );
};
Interests.displayName = 'Interests';

export default Interests;
