import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiModuleShopRecommendations } from '@wix/da-papi-types';
import { MeasuredSlider as Slider } from '@wix/da-shared-react/pkg/Slider';
import SellPromotionCard from '@wix/da-shared-react/pkg/ShopCard/SellPromotionCard';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import ShopRecommendationsMobile from './ShopRecommendationsMobile';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';

import s from './ShopRecommendations.scss';

interface Props {
  moduleId: number;
}

const ShopRecommendations: React.FC<Props> = ({ moduleId }) => {
  const isMobile = useContext(MobileContext);
  const { moduleData } = useModuleData<PapiModuleShopRecommendations>(moduleId);
  const { deviations } = moduleData;
  const { t } = useTranslation();

  if (!deviations) {
    return null;
  }

  return (
    <div className={s['root']}>
      <div className={s['header']}>
        {t('widgets.shopRecommendations.header')}
      </div>
      <div className={s['subheader']}>
        {t('widgets.shopRecommendations.subheader')}
      </div>
      {isMobile ? (
        <ShopRecommendationsMobile deviations={deviations} />
      ) : (
        <Slider
          className={s['slider']}
          renderSliderItems={elementSize => (
            <>
              {deviations.map((deviation, index) => (
                <SellPromotionCard
                  key={index}
                  deviation={deviation}
                  width={elementSize.width}
                />
              ))}
            </>
          )}
        />
      )}
    </div>
  );
};
ShopRecommendations.displayName = 'ShopRecommendations';

export default ShopRecommendations;
