import React from 'react';
import { RenderedRouteProps } from '../../../../types/route';
import { renderRoutes } from 'react-router-config';

class RootPage extends React.Component<RenderedRouteProps> {
  render() {
    return renderRoutes(this.props.route.routes);
  }
}

export default RootPage;
