import React, { useState } from 'react';
import c from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Zone from '@wix/da-gruser-shared/pkg/components/Zone';
import ZoneGroup from '@wix/da-gruser-shared/pkg/components/ZoneGroup';
import { getModuleName } from '@wix/da-gruser-shared/pkg/redux/selectors/modules';
import { getLayout } from '@wix/da-gruser-shared/pkg/redux/selectors/layout';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import LazyContext from '@wix/da-shared-react/pkg/LazyLoadingImage/LazyContext';
import { getProfileGruser } from '../../../selectors/users';
import SideNavBar, { SidebarRoute } from '../../SideNavBar';
import WideWidgetLayout from '../../Layout/WideWidgetLayout';
import { ModuleType } from '../../../../types/modules';
import { getAboutSectionWidgetConfigs } from './aboutSectionWidgetConfigs';
import s from './AboutSection.scss';

export interface Props {}

const AboutSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeHash, setActiveHash] = useState<string>(ModuleType.ABOUT);
  const gruser = useSelector(getProfileGruser);
  const aboutSectionWidgetConfig = getAboutSectionWidgetConfigs({
    t,
    dispatch,
  });

  // once ported, add module here so corresponding route will be rendered
  const portedModules: ModuleType[] = [
    ModuleType.WATCHING,
    ModuleType.WATCHERS,
    ModuleType.GROUPS_ADMIN,
    ModuleType.GROUPS_MEMBER,
    ModuleType.MY_COMMENTS,
    ModuleType.ABOUT,
    ModuleType.BADGES_ACTIVITY,
    ModuleType.STATS,
    ModuleType.GROUP_ADMINS,
    ModuleType.GROUP_MEMBERS,
  ];

  const routes: SidebarRoute[] = useSelector(state => {
    const layout = getLayout(state as any, gruser);
    const zones = layout && layout.zones;
    if (!layout || !zones) {
      return [];
    }

    const installedModuleIds = Object.keys(zones).reduce(
      (acc, zone) => acc.concat(zones[zone].installedModulesIds),
      [] as number[]
    );

    return installedModuleIds
      .map(moduleId => {
        const moduleType = getModuleName(state as any, gruser, moduleId);
        if (!moduleType) {
          return undefined;
        }
        const translationKey = `pages.about.navmenu.${moduleType}`;
        return {
          hash: moduleType,
          transKey: translationKey,
        };
      })
      .filter(route => route) as SidebarRoute[];
  });

  const updateActiveHash = (newHash: string) => {
    const documentLocationHash =
      document && document.location && document.location.hash;

    if (!documentLocationHash && newHash === ModuleType.ABOUT) {
      return;
    }

    const hash = `#${newHash}`;
    if (documentLocationHash !== hash) {
      setActiveHash(newHash);
      typeof window !== undefined && window.history.replaceState({}, '', hash);
    }
  };

  const renderWideWidgets = () => (
    <WideWidgetLayout
      className={c(s['root'], s['hover-control'])}
      navigation={
        routes && (
          <SideNavBar
            routes={routes.filter(route =>
              portedModules.includes(route.hash as ModuleType)
            )}
            activeHash={activeHash}
            onActiveHashChange={updateActiveHash}
            className={s['hide-mobile']}
            biEvid={291}
            heading={
              <VisuallyHidden>
                <Heading>{t('pages.about.navmenu.a11y.heading')}</Heading>
              </VisuallyHidden>
            }
          />
        )
      }
    >
      <div className={s['flex-wrap']}>
        <ZoneGroup
          className={s['zone-column']}
          widgetConfigs={aboutSectionWidgetConfig}
        >
          <Zone
            className={s['zone']}
            name="topRight"
            widgetConfigs={aboutSectionWidgetConfig}
            locked={true}
          />
          <Zone
            className={s['zone']}
            name="midRight"
            widgetConfigs={aboutSectionWidgetConfig}
          />
          <Zone
            className={s['zone']}
            name="bottomRight"
            widgetConfigs={aboutSectionWidgetConfig}
          />
        </ZoneGroup>
      </div>
    </WideWidgetLayout>
  );

  return (
    <div className={s['root']}>
      <LazyContext.Provider value={true}>
        {renderWideWidgets()}
      </LazyContext.Provider>
    </div>
  );
};
AboutSection.displayName = 'AboutSection';

export default AboutSection;
