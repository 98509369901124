import React from 'react';
import { PapiDeviation } from '@wix/da-papi-types';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import PCPShopCard from '@wix/da-shared-react/pkg/ShopCard/PCPShopCard';
import { ShopItemType } from '../../../../../types/shop';
import { RenderCardProps } from '../_partials/ShopGridWithFilters/types';
import ShopGridWithFilters from '../_partials/ShopGridWithFilters';
import PremiumContentEmptyState from '../_partials/PremiumContentEmptyState';

const BILOGGER_CONTEXT = {
  sectionname: 'Premium Downloads',
  widgetname: 'premium_content',
};

const renderCard = (props: RenderCardProps): React.ReactNode => {
  const { subject, index, width, style } = props;
  return (
    <div style={{ ...style, height: 'auto' }}>
      <PCPShopCard
        key={index}
        width={width}
        withAuthorDetails={false}
        withIndicators={false}
        withDreamupLabel={false}
        deviation={subject as PapiDeviation}
      />
    </div>
  );
};

export interface Props {
  className?: string;
}

export const ShopPremiumContent: React.FC<Props> = ({ className }) => {
  return (
    <BiLoggerContextProvider value={BILOGGER_CONTEXT}>
      <ShopGridWithFilters
        className={className}
        itemType={ShopItemType.PREMIUM_CONTENT}
        moduleName="premiumContent"
        uploadMoreBiClickInfo="create_premium_download"
        invitationToUpload={PremiumContentEmptyState}
        renderCard={renderCard}
      />
    </BiLoggerContextProvider>
  );
};

ShopPremiumContent.displayName = 'ShopPremiumContent';
export default ShopPremiumContent;
