import { call } from 'redux-saga/effects';
import { userSchema } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/user';
import fetchModuleStream from './fetchModuleStream';

export default function* fetchMoreGroupsMember(action) {
  return yield call(
    fetchModuleStream,
    action,
    userSchema,
    undefined,
    'module/groups/members'
  );
}
