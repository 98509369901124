import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  PapiGallection,
  PapiDeviation,
  PapiPremiumFolderData,
} from '@wix/da-papi-types';
import { OverlayContextProvider } from '@wix/da-react-context/pkg/OverlayContext';
import LoadingIndicator from '@wix/da-ds/pkg/LoadingIndicator';
import OnScrollBottom, {
  DISTANCE_UNIT,
} from '@wix/da-shared-react/pkg/OnScrollBottom';
import { GallectionType } from '../../../../../../types/gallection';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import { gallectionTypeToResourceType } from '@wix/da-shared-react/pkg/types/gallection';
import { Url } from '@wix/da-url';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { normalizePremiumFolderData } from '@wix/da-shared-react/pkg/utils/normalizePremiumFolderData';
import GallectionDeviationsTorpedo from '../../GallectionDeviationsTorpedo';
import FolderPaywall from '../../FolderPaywall';
import FolderDescription from '../FolderDescription';
import FolderBreadcrumbs from '../FolderBreadcrumbs/';
import {
  BiContextProvidedValue,
  BiData,
  GalleryPurchaseBiEvent,
} from '@wix/da-bi/pkg/events';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import ArrowDown from '@wix/da-ds/pkg/Icons/16x16/ArrowDown';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import generatePaginationUrl from '../../../../../utils/generatePaginationUrl';

import s from './FolderInfiniteContent.scss';

export interface Props {
  streamId: string;
  folderId: number;
  subfolderId?: number;
  folder: PapiGallection;
  subfolder?: PapiGallection;
  gallectionType: GallectionType;
  deviations: PapiDeviation[];
  isInitialized: boolean;
  hasMore: boolean;
  isSelected: boolean;
  isFetching: boolean;
  initFetch: () => void;
  fetchMore: () => void;
  isOwner: boolean;
  className?: string;
  isMobile?: boolean;
  scrollIntoViewWhenLoaded?: boolean;
  justExitedDuperbrowse?: boolean;
  currentPage?: number;
  requestUrl?: string;
}

const FolderInfiniteContent: React.FC<Props> = ({
  isInitialized,
  initFetch,
  fetchMore,
  subfolder,
  deviations,
  isFetching,
  folder,
  className,
  gallectionType,
  hasMore,
  streamId,
  isOwner,
  requestUrl,
  currentPage,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (!deviations.length && !isInitialized && !isFetching) {
      initFetch && initFetch();
    }
  }, [deviations.length, initFetch, isFetching, isInitialized]);

  const { pointPrice, isPremiumLocked } = normalizePremiumFolderData(
    folder.premiumFolderData
  );
  const isCollection = gallectionType === GallectionType.COLLECTION;

  const onScrollBottom = useCallback(() => {
    if (isFetching) {
      return;
    }
    if (!hasMore) {
      return;
    }
    fetchMore && fetchMore();
  }, [fetchMore, hasMore, isFetching]);

  const isMobile = useContext(MobileContext);
  const { isLoggedIn } = useContext(SessionContext);
  const shouldRequireInfiniteScrollStart = !isLoggedIn && isMobile;
  const [mobileInfScrollStarted, setMobileInfScrollStarted] = useState(false);

  return (
    <OverlayContextProvider
      value={{
        showAuthorTooltip: isCollection,
        showThumbStatusIndicators: false,
      }}
    >
      <BiLoggerContextProvider
        value={{
          section_typeid: gallectionTypeToResourceType(gallectionType),
          sectionid: folder.folderId,
          authorid: folder.owner.useridUuid,
        }}
      >
        <ErrorBoundary debugComponent="FolderInfiniteContent">
          <div className={className}>
            {/* TITLE */}
            {!subfolder && (
              <FolderDescription
                folder={folder}
                className={s['folder-description']}
              />
            )}
            {subfolder && <FolderBreadcrumbs />}

            {/* PAYWALL */}
            {isPremiumLocked && !isOwner && (
              <FolderPaywall
                premiumFolderData={{
                  ...(folder.premiumFolderData as PapiPremiumFolderData),
                  galleryUrl: Url.userGalleryLink({
                    username: folder.owner.username,
                    gallectionType: 'gallery',
                    folderId: folder.folderId,
                    folderName: folder.name,
                  }),
                }}
                folder={folder}
                biData={BiData<GalleryPurchaseBiEvent>({
                  evid: 361,
                  typeid: gallectionTypeToResourceType(gallectionType) ?? 0,
                  itemid: folder.folderId,
                  galleryid: folder.folderId,
                  authorid: folder.owner.useridUuid,
                  points: pointPrice,
                  rank: BiContextProvidedValue,
                  reason_shown: BiContextProvidedValue,
                  section_typeid: BiContextProvidedValue,
                  sectionid: BiContextProvidedValue,
                  sectionname: BiContextProvidedValue,
                  sectionrank: BiContextProvidedValue,
                  sectiontype: BiContextProvidedValue,
                  sort_type: BiContextProvidedValue,
                  widgetname: BiContextProvidedValue,
                })}
              />
            )}

            {/* TORPEDO */}
            {!isPremiumLocked && (deviations.length > 0 || hasMore) && (
              <>
                <GallectionDeviationsTorpedo
                  streamId={streamId}
                  gallectionType={gallectionType}
                  deviations={deviations}
                />
                {isFetching && <LoadingIndicator />}
                {hasMore && shouldRequireInfiniteScrollStart && (
                  <div
                    className={cn(
                      s['start-infscroll-button'],
                      mobileInfScrollStarted && s['hidden']
                    )}
                  >
                    <SolidButton
                      icon={ArrowDown}
                      postfixIcon={ArrowDown}
                      variant={'approval-green'}
                      size={'large'}
                      href={generatePaginationUrl(
                        (currentPage ?? 0) + 1,
                        requestUrl
                      )}
                      onClick={e => {
                        e.preventDefault();
                        setMobileInfScrollStarted(true);
                      }}
                    >
                      {t('common.startInfiniteScroll')}
                    </SolidButton>
                  </div>
                )}
                {(mobileInfScrollStarted ||
                  !shouldRequireInfiniteScrollStart) && (
                  <OnScrollBottom
                    checkOnMount={mobileInfScrollStarted}
                    distanceToBottom={2}
                    distanceUnit={DISTANCE_UNIT.WINDOW_HEIGHT}
                    onScrolledToBottom={hasMore ? onScrollBottom : undefined}
                  />
                )}
              </>
            )}
          </div>
        </ErrorBoundary>
      </BiLoggerContextProvider>
    </OverlayContextProvider>
  );
};
FolderInfiniteContent.displayName = 'FolderInfiniteContent';

export default FolderInfiniteContent;
