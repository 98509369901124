import React, { useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { BiData, SortTypeSelectBiEvent } from '@wix/da-bi/pkg/events';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import StandardDropdown from '@wix/da-ds/pkg/Dropdown/Preset/StandardDropdown';
import MobileNativeDropdown from '@wix/da-ds/pkg/Dropdown/MobileNativeDropdown';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import { navigate } from '../../../helpers';
import { Subnavigation } from '../../types';

import s from './OrderDropdown.scss';

export interface Props {
  subnav?: Subnavigation;
  className?: string;
  menuClassName?: string;
  nativeTitleClassName?: string;
  localeSpecifier?: string;
  arrowDown?: (className: string) => React.ReactNode;
}

const OrderDropdown: React.FC<Props> = ({
  subnav,
  className,
  menuClassName,
  nativeTitleClassName,
  localeSpecifier = 'subnavigation',
  arrowDown,
}) => {
  // todo: this is a cheapshot copypasta, likely a lot of possible simplifying in here
  // todo: could start by removing implicit subnav (and poss. implicit 'order' context)
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const mobileLocale = isMobile ? '.mobile' : '';
  const baseLocale = `${localeSpecifier}${mobileLocale}`;

  const dropdownItems: NullableDropdownItem[] | undefined = useMemo(
    () =>
      subnav?.options?.map(option => {
        const label = t(`${baseLocale}.labels.${option.subnav}`, {
          defaultValue: option.subnav,
        });

        return {
          ...(isMobile
            ? { selected: option.subnav === subnav.currentValue }
            : { link: option.url }),
          label,
          value: option.subnav,
          biData: BiData<SortTypeSelectBiEvent>({
            evid: 122,
            menu_selection: option.subnav,
            dropdown_type: 'sort',
          }),
        };
      }),
    [subnav, isMobile, t, baseLocale]
  );

  const onMobileSelect = useCallback(
    selected => {
      const found = subnav?.options?.find(o => o.subnav === selected);
      if (found) {
        navigate(found.url);
      }
    },
    [subnav]
  );

  if (!dropdownItems?.length) {
    return null;
  }

  if (isMobile) {
    return (
      <label className={className}>
        <VisuallyHidden>{t(`${localeSpecifier}.a11y.label`)}</VisuallyHidden>
        <MobileNativeDropdown
          className={s['wrapper']}
          titleClassName={classnames(s['title'], nativeTitleClassName)}
          title={t(`${baseLocale}.labels.${subnav?.currentValue}`, {
            defaultValue: subnav?.currentValue,
          })}
          arrowClassName={s['arrow']}
          onSelect={onMobileSelect}
          items={dropdownItems}
        />
      </label>
    );
  }

  return (
    <div className={classnames(s['wrapper'], className)}>
      <StandardDropdown
        role="menu"
        selectedValue={subnav?.currentValue ?? undefined}
        items={dropdownItems}
        menuClassName={menuClassName}
        toggleClassName={s['desktop-toggle']}
        renderArrowDown={arrowDown}
        arrowSize={IconSize.TINY}
        placement="bottom-end"
      />
    </div>
  );
};
OrderDropdown.displayName = 'OrderDropdown';

export default OrderDropdown;
