import React from 'react';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import PlainButtonOrLink, {
  Props as PlainButtonOrLinkProps,
} from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';

interface Props extends PlainButtonOrLinkProps {
  icon: React.ReactNode;
  a11yText: string;
}

export const IconButton: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  a11yText,
  children,
  ...buttonProps
}) => {
  return (
    <PlainButtonOrLink {...buttonProps}>
      {icon}
      <VisuallyHidden>{a11yText}</VisuallyHidden>
      {children}
    </PlainButtonOrLink>
  );
};

export default IconButton;
