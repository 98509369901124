import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { getPagingMode } from '@wix/da-shared-react/pkg/publicSession/selectors';
import { useSelector } from 'react-redux';
import FolderPagedContent from './FolderPagedContent';
import FolderInfiniteContent from './FolderInfiniteContent';

export interface Props {
  folderId: number;
}
const FolderContent: React.FC<Props> = ({ folderId }) => {
  const pagingMode = useSelector(state => getPagingMode(state));
  const isMobile = useContext(MobileContext);
  let isPagedRequest;

  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    isPagedRequest = params.has('page');
  }

  if (!isMobile && (pagingMode === 'page' || isPagedRequest)) {
    return <FolderPagedContent folderId={folderId} />;
  }
  return <FolderInfiniteContent folderId={folderId} />;
};

export default FolderContent;
