// DS: COF1.1
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralType } from '@wix/da-bi/pkg/constants';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';
import ConditionalWrapper from '@wix/da-ds/pkg/ConditionalWrapper';
// TODO: import from da-shared-react
import WidgetPaywall from '../../../UserWidgets/_partials/paywalls/WidgetPaywall';
import useShowCoreUpsell from '@wix/da-shared-react/pkg/widgets/hooks/useShowCoreUpsell';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { isCurrentUserProfileOwner } from '../../../../selectors/users';
import EmptyState from '../../../EmptyState';
import { useSelector } from 'react-redux';
import LlamaFarm from '../LlamaFarm';

import s from './LlamaFarmBox.scss';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const LlamaFarmBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const showCoreUpsell = useShowCoreUpsell(moduleId);

  const { moduleData = {} } = useModuleData(moduleId);

  const { llamas = [] } = moduleData as any;
  const isEmpty = llamas.length === 0;

  return (
    <WidgetBox
      moduleId={moduleId}
      header={t('widgets.llamafarm.header')}
      {...widgetBoxProps}
    >
      <ConditionalWrapper
        condition={showCoreUpsell}
        wrap={child => (
          <WidgetPaywall
            previewReferral={ReferralType.LLAMAFARM_WIDGET_PAYWALL_PREVIEW}
            lockedReferral={ReferralType.LLAMAFARM_WIDGET_PAYWALL_OVERLAY}
          >
            {child}
          </WidgetPaywall>
        )}
      >
        {!isEmpty && <LlamaFarm moduleId={moduleId} />}
        {isEmpty && isOwner && (
          <EmptyState
            view="large"
            className={s['empty-state']}
            title={t('widgets.llamafarm.empty.title.owner')}
            subtitle={t('widgets.llamafarm.empty.subtitle.owner')}
          />
        )}
      </ConditionalWrapper>
    </WidgetBox>
  );
};
LlamaFarmBox.displayName = 'LlamaFarmBox';

export default LlamaFarmBox;
