import React from 'react';
import UserLink from '@wix/da-shared-react/pkg/User/Link';
import { getDeviationUrl } from '@wix/da-shared-react/pkg/DeviationViews/_helpers';
import {
  PapiComment,
  PapiForum,
  PapiGallection,
  PapiUser,
  PapiModuleMyComments,
  PapiDeviation,
} from '@wix/da-papi-types';
import { Trans, useTranslation } from 'react-i18next';
import { SubjectType } from '../types';
import { MyComment } from './MyComment';
import { Url } from '@wix/da-url';
import s from './MyComment.scss';

export interface Props {
  subject: PapiModuleMyComments['results'][0]['subject'];
  comment: PapiComment;
  isOwner: boolean;
  className?: string;
}

export const MyCommentFactory: React.FC<Props> = ({
  subject,
  isOwner,
  comment,
  className,
}) => {
  const { t } = useTranslation();

  let title;
  let thumb: PapiDeviation | undefined;

  const commentedBy = isOwner ? (
    <span className={s['commented-by-you']}>{t('common.you')}</span>
  ) : (
    // add span here so it matches the element count and i18n indeces of the 'you' above
    <span>
      <UserLink
        className={s['userlink']}
        showSymbol={false}
        user={comment.user}
        withTooltip={true}
      />
    </span>
  );

  const subjectType = Object.values(SubjectType).find(key =>
    subject.hasOwnProperty(key)
  );

  switch (subjectType) {
    case SubjectType.DEVIATION:
    case SubjectType.COMMISSION:
      const deviationSubject = subject as { deviation: PapiDeviation };
      thumb = deviationSubject.deviation;
      if (!thumb) {
        return null;
      }
      const author = thumb.author ? (
        <UserLink
          className={s['userlink']}
          user={thumb.author}
          showSymbol={false}
          withTooltip={true}
        />
      ) : null;
      title = thumb.status ? (
        <Trans i18nKey="widgets.myComments.status">
          {commentedBy}
          {' commented on '}
          <strong>
            <a href={getDeviationUrl(thumb)} title="status">
              {' a status '}
            </a>
          </strong>
          {author && (
            <>
              {' by '}
              {author}
            </>
          )}
        </Trans>
      ) : (
        <Trans i18nKey="widgets.myComments.deviation">
          {commentedBy}
          {' commented on '}
          <strong>
            <a href={getDeviationUrl(thumb)} title={thumb.title}>
              {thumb.title}
            </a>
          </strong>
          {author && (
            <>
              {' by '}
              {author}
            </>
          )}
        </Trans>
      );
      break;

    case SubjectType.FORUM:
      const forumSubject = subject as { forum: PapiForum };
      const forum = forumSubject.forum;
      if (!forum) {
        return null;
      }
      title = (
        <Trans i18nKey="widgets.myComments.forum">
          {commentedBy}
          {' commented on '}
          <strong>
            <a href={Url.forumLink(forum.forumPath, forum.threadId)}>
              {forum.subject}
            </a>
          </strong>
          {' by '}
          <UserLink
            className={s['userlink']}
            user={forum.author}
            showSymbol={false}
            withTooltip={true}
          />
        </Trans>
      );
      break;

    case SubjectType.COLLECTION:
      const collectionSubject = subject as { collection: PapiGallection };
      const collection = collectionSubject.collection;
      if (!collection) {
        return null;
      }
      title = (
        <Trans i18nKey="widgets.myComments.collection">
          {commentedBy}
          {' commented on '}
          <UserLink
            className={s['userlink']}
            user={collection.owner}
            showSymbol={false}
            withTooltip={true}
          />
          {"'s "}
          <strong>
            <a
              href={Url.userGalleryPath({
                username: collection.owner.username,
                gallectionType: collection.type as 'gallery' | 'collection',
                folderId: collection.folderId,
                folderName: collection.name,
              })}
            >
              collection
            </a>
          </strong>
        </Trans>
      );
      break;

    case SubjectType.PROFILE:
      const profileSubject = subject as { profile: PapiUser };
      const profile = profileSubject.profile;
      if (!profile) {
        return null;
      }
      title = (
        <Trans i18nKey="widgets.myComments.profile">
          {commentedBy}
          {' commented on '}
          <UserLink
            className={s['userlink']}
            showSymbol={false}
            user={profile}
            withTooltip={true}
          />
          {"'s profile"}
        </Trans>
      );
      break;

    default:
      return null;
  }

  return (
    <MyComment
      title={title}
      thumb={thumb}
      comment={comment}
      className={className}
    />
  );
};

MyCommentFactory.displayName = 'MyCommentFactory';

export default MyCommentFactory;
