import { getRawModuleDataFromResponse } from '../../../utils/modules';
import { getShopWidgetConfigs } from '../../components/Sections/ShopSection/shopWidgetConfigs';
import { FILTER_QUERYSTRING_KEYS } from '../../components/Sections/ShopSection/_partials/ShopGridWithFilters/ShopFilters/constants';
import {
  setTotalPages,
  setCurrentPage,
  setRequestParams,
} from '../../actions/pageInfo';
import {
  getCustomInitActionsForWidgets,
  getRawSectionModules,
  getModuleData,
} from './common';

/** these are the request parameters in the frontend that get passed to the backend papi call */
export const PARAMS_PASSED_FROM_FRONT_TO_BACK = [
  ...FILTER_QUERYSTRING_KEYS,
  'q',
  'order',
  'page',
  'deviationid',
];

export function getShopPageInfoInitActions(responseData, requestParams) {
  return [
    ...getShopCurrentPageInitActions(responseData, requestParams),
    ...getShopTotalPageInitActions(responseData, requestParams),
    setRequestParams(requestParams),
  ];
}

/** set the shop's currentPage from the request params */
function getShopCurrentPageInitActions(responseData, requestParams) {
  const currentPage = requestParams.page ? parseInt(requestParams.page, 10) : 1;
  return [setCurrentPage(currentPage)];
}

/** set the totalPages of the current stream of shop products (if any) */
function getShopTotalPageInitActions(responseData, requestParams) {
  // note that if we try this in the individual shop product module
  // inits in modules.ts, they will overwrite each other since the
  // `totalPages` are shared between them
  const { shop_section } = requestParams;
  if (!shop_section) {
    return [];
  }

  const modules = getRawSectionModules(responseData);
  const shopSectionModule = getModuleData(modules, shop_section);
  if (!shopSectionModule) {
    return [];
  }

  const moduleData = shopSectionModule.dataKey
    ? getRawModuleDataFromResponse(shopSectionModule)
    : shopSectionModule;
  const { totalPageCount } = moduleData;
  return totalPageCount === 0 || totalPageCount
    ? [setTotalPages(totalPageCount)]
    : [];
}

// new grusers
export const getCustomInitActionsForShopWidgets = (
  responseData,
  requestParams
) => [
  ...getCustomInitActionsForWidgets(
    responseData,
    requestParams,
    getShopWidgetConfigs({ t: () => null, dispatch: () => null })
  ),
  ...getShopPageInfoInitActions(responseData, requestParams),
];
