import React from 'react';
import classnames from 'classnames';
import { matchPath } from 'react-router';
import BetaBadge from '@wix/da-ds/pkg/BetaBadge';
import TextTooltipOnHover from '@wix/da-shared-react/pkg/Popover/Preset/TextTooltipOnHover';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import type { SubSubNavBarProps, SubSubNavBarItem } from '../types';
import BiLink from '@wix/da-bi/pkg/Components/BiLink';
import { BiData, ProfileSubNavBarBiEvent } from '@wix/da-bi/pkg/events';
import s from '../SubSubNavBar.scss';

export default class SubSubNavBarDesktop extends React.Component<SubSubNavBarProps> {
  render() {
    return (
      <ul className={classnames(s['list'], this.props.className)}>
        {this.props.items.map(this.renderItem)}
        {this.props.extras}
      </ul>
    );
  }

  renderItem = (item: SubSubNavBarItem, index: number) => {
    const { location } = this.props;
    const isActive = matchPath(location.pathname, {
      path: item.path,
      exact: item.exact,
    });

    return (
      <li
        key={index}
        className={classnames(s['list-item'], isActive && s['active'])}
      >
        {item.tooltipText ? (
          <TextTooltipOnHover
            className={s['list-item-content']}
            wrapperClassName={s['tooltip-wrapper']}
            contentClassName={s['tooltip-content']}
            tooltipText={item.tooltipText}
            placement="top"
          >
            {this.renderItemContent(item)}
          </TextTooltipOnHover>
        ) : (
          <div className={s['list-item-content']}>
            {this.renderItemContent(item)}
          </div>
        )}
      </li>
    );
  };

  renderItemContent = (item: SubSubNavBarItem) => {
    const Icon = item.labelIconClass;
    const path = Array.isArray(item.path) ? item.path[0] : item.path;
    return (
      <>
        {Icon && <Icon className={s['label-icon']} size={IconSize.SMALL} />}
        <BiLink
          href={path}
          biData={BiData<ProfileSubNavBarBiEvent>({
            evid: 290,
            menu_item: item.bi_item_label ?? '',
          })}
        >
          {item.label}
        </BiLink>
        <span className={s['count']}>{item.count}</span>
        {item.isBeta && (
          <BetaBadge
            badgeType="beta"
            size="small"
            variant="green"
            className={s['beta']}
          />
        )}
      </>
    );
  };
}
