import { useSelector } from 'react-redux';
import { getPageRoutes } from '../../../selectors/pageLayout';
import { isCurrentUserProfileOwner } from '../../../selectors/users';

const OVERSIZED_MAX_LENGTH = 7;
const OVERSIZED_ROUTES_TO_HIDE = ['about'];

export function useCollapsingRoutes() {
  const routes = useSelector(getPageRoutes);
  const isOwner = useSelector(isCurrentUserProfileOwner);
  const shouldCollapse =
    !isOwner &&
    routes.length > OVERSIZED_MAX_LENGTH;

  return {
    shouldCollapse,
    routesToCollapse: shouldCollapse ? OVERSIZED_ROUTES_TO_HIDE : [],
  };
}
