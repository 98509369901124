import { createAction } from 'redux-actions';
import { CommissionStatusType } from '@wix/da-shared-react/pkg/types/commissions';

export type ChangeCommissionsGlobalStatusPayload = {
  isOpen: boolean;
};

export type ChangeAllCommissionsStatusPayload = {
  status: CommissionStatusType;
};

export const changeAllCommissionsStatus = createAction(
  'COMMISSION.CHANGE_ALL_COMMISSIONS_STATUS',
  (payload: ChangeAllCommissionsStatusPayload) => payload
);

export const changeCommissionsGlobalStatus = createAction(
  'COMMISSION.CHANGE_GLOBAL_STATUS',
  (payload: ChangeCommissionsGlobalStatusPayload) => payload
);
