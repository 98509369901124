import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiDeviation } from '@wix/da-papi-types';
import { Url, SHOP_PATHS } from '@wix/da-url';
import {
  BiContextProvidedValue,
  BiData,
  DefaultBiEvent,
} from '@wix/da-bi/pkg/events';
import StandardGrid from '@wix/da-shared-react/pkg/Grid/StandardGrid';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import AdoptableShopCard from '@wix/da-shared-react/pkg/ShopCard/AdoptableShopCard';
import { useCurrentUser } from '@wix/da-shared-react/pkg/utils/hooks/useCurrentUser';
import BuyButton from '@wix/da-ds/pkg/Buttons/BuyButton';
import EmptyState from '../../EmptyState';
import LoadableDuperbrowseContext from '../../../contexts/LoadableDuperbrowseContext';
import { ADOPTABOBBLES_DEVIATIONS_STREAM_ID } from '../../../streams/adoptabobbles';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { GridBreakpoint } from '@wix/da-shared-react/pkg/Grid';

import s from './AdoptabobblesBoughtBox.scss';

const BREAKPOINTS: GridBreakpoint[] = [
  {
    maxWidth: 735,
    elementsPerRow: 1,
    elementHeight: 308,
  },
  {
    maxWidth: 1280,
    elementsPerRow: 3,
    elementHeight: 308,
  },
  {
    maxWidth: 1536,
    elementsPerRow: 4,
    elementHeight: 308,
  },
  {
    maxWidth: 1920,
    elementsPerRow: 5,
    elementHeight: 308,
  },
  {
    maxWidth: 9999,
    elementsPerRow: 6,
    elementHeight: 308,
  },
];

export interface Props {
  deviations?: PapiDeviation[];
}

const AdoptabobblesBought: React.FC<Props> = ({ deviations }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  const renderElement = useCallback(
    ({ width, index }) => {
      const extraProps = {};

      const adoptable = deviations![index]?.adoptable;
      const isOpenForSale = adoptable?.thumbSaleState === 'open';
      extraProps['hidePrice'] = !isOpenForSale;
      extraProps['hideSold'] = true;
      if (!isOpenForSale && adoptable?.owner?.userId === currentUser?.userId) {
        extraProps['detailsRightSlot'] = (
          <BuyButton isFakeButton size="medium" fillContainerWidth rounded>
            {t('common.sell')}
          </BuyButton>
        );
      }

      return (
        <div key={`adopt-${index}`} className={s['item']}>
          <AdoptableShopCard
            deviation={deviations![index]}
            width={width}
            withIndicators={false}
            {...extraProps}
          />
        </div>
      );
    },
    [deviations, currentUser, t]
  );

  return (
    <>
      <Heading className={s['widget-heading']}>
        {t('widgets.adoptabobbles_bought.heading')}
      </Heading>
      {(deviations || []).length > 0 ? (
        <LoadableDuperbrowseContext
          streamId={ADOPTABOBBLES_DEVIATIONS_STREAM_ID}
          parent={{
            title: t('duperbrowse.backButton.profile.exclusives'),
          }}
        >
          <StandardGrid
            elementCount={deviations?.length ?? 0}
            cookieType={MeasuredCookieType.USER_PROFILE_SHOP}
            breakpoints={BREAKPOINTS}
            preserveAspectRatio={false}
            enableScrollOptim
          >
            {renderElement}
          </StandardGrid>
        </LoadableDuperbrowseContext>
      ) : (
        <EmptyState
          view="large"
          title={t('widgets.adoptabobbles_bought.empty.title')}
          subtitle={t('widgets.adoptabobbles_bought.empty.subtitle')}
          withButton
          buttonVariant="approval-green"
          buttonLabel={t('widgets.adoptabobbles_bought.empty.cta')}
          onButtonClick={() => {
            window.location.href = Url.shopLink(SHOP_PATHS.EXCLUSIVES);
          }}
          biData={BiData<DefaultBiEvent>({
            evid: 2,
            sectionname: BiContextProvidedValue,
            widgetname: BiContextProvidedValue,
            click_info: 'shop_now',
          })}
        />
      )}
    </>
  );
};
AdoptabobblesBought.displayName = 'AdoptabobblesBought';

export default AdoptabobblesBought;
