import React, { useRef } from 'react';
import classnames from 'classnames';
import { PapiDeviation } from '@wix/da-papi-types';
import { useIsMounted } from '@wix/da-hooks/pkg/useIsMounted';
import Draggable from 'react-draggable';
import { useHeroImageForContainer } from '@wix/da-shared-react/pkg/utils/hooks/useHeroImageForContainer';

import s from './DraggableProfileHero.scss';

export interface Props {
  coverDeviation: PapiDeviation;
  className?: string;
  updateProfileHeroCropArea: (payload) => void;
}

export const DraggableProfileHero: React.FC<Props> = ({
  coverDeviation,
  className,
  updateProfileHeroCropArea,
}) => {
  const isMounted = useIsMounted();
  const rootDivRef = useRef<HTMLDivElement>(null);
  const draggableDivRef = useRef<HTMLDivElement>(null);

  const heroHeight = parseInt(s['hero-height']);
  const siteHeaderHeight = parseInt(s['site-header-height']);

  const { hero: image } = useHeroImageForContainer(coverDeviation, rootDivRef, {
    isViewportWidth: true,
    width: 1024,
    height: heroHeight,
    mobileWidth: 412,
    mobileHeight: heroHeight,
  });

  if (!coverDeviation) {
    return null;
  }

  let imageAspectRatio,
    scaledImageWidth,
    scaledImageHeight,
    hiddenPartOfImage,
    draggableContainerHeight;

  if (isMounted && image) {
    imageAspectRatio = (image.width || 0) / (image.height || 0);
    scaledImageWidth = rootDivRef.current!.getBoundingClientRect().width;
    scaledImageHeight = Math.round(scaledImageWidth / imageAspectRatio);
    hiddenPartOfImage = scaledImageHeight - heroHeight;
    draggableContainerHeight =
      heroHeight +
      hiddenPartOfImage *
        2 /* can be either below or above the visible hero area */;
  }

  return (
    <div ref={rootDivRef} className={classnames(s['root'], className)}>
      {isMounted && (
        <div
          className={s['draggable-container']}
          style={{
            position: 'absolute',
            top: -hiddenPartOfImage,
            height: draggableContainerHeight,
          }}
        >
          <Draggable
            bounds="parent"
            onStop={() => {
              const rootDivClientRect =
                rootDivRef.current!.getBoundingClientRect();
              const draggableDivClientRect =
                draggableDivRef.current!.getBoundingClientRect();
              updateProfileHeroCropArea({
                y: Math.abs(draggableDivClientRect.y - siteHeaderHeight),
                x: draggableDivClientRect.x,
                width: rootDivClientRect.width,
                height: rootDivClientRect.height,
              });
            }}
          >
            <div
              ref={draggableDivRef}
              className={s['draggable-image']}
              style={{
                width: scaledImageWidth,
                height: scaledImageHeight,
                top: hiddenPartOfImage - (scaledImageHeight - heroHeight) / 2,
                backgroundImage: image ? `url(${image.src})` : undefined,
              }}
            />
          </Draggable>
        </div>
      )}
    </div>
  );
};
DraggableProfileHero.displayName = 'DraggableProfileHero';

export default DraggableProfileHero;
