import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Props } from './UploadMore';
import { AppState } from '../../../../../../types/store';
import { getCurrentUserHasCore } from '@wix/da-shared-react/pkg/publicSession/selectors';

export type StateProps = Pick<Props, 'hasCoreMembership'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  hasCoreMembership: getCurrentUserHasCore(state),
});
const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
