import React from 'react';
import { BiData, DefaultBiEvent } from '@wix/da-bi/pkg/events';
import { useMeasureElementCookie } from '@wix/da-hooks/pkg/useMeasureElement/useMeasureElementCookie';
import { PapiDeviation } from '@wix/da-papi-types';
import CommissionShopCard from '@wix/da-shared-react/pkg/ShopCard/CommissionShopCard';
import SwipeSlider from '@wix/da-shared-react/pkg/SwipeSlider.v2';
import { ShopItemType } from '../../../../../../../types/shop';
import UploadMore from '../../../_partials/UploadMore';

interface Props {
  showAddItemCard?: boolean;
  deviations: PapiDeviation[];
  onCreate?: () => void;
}

const CommissionSliderMobile: React.FC<Props> = ({
  showAddItemCard,
  deviations,
  onCreate,
}) => {
  const { ref, dimensions: measuredContainer } = useMeasureElementCookie(
    undefined,
    {
      cookieDim: 'width',
    }
  );

  const DEFAULT_CARD_WIDTH = 320;
  const DEFAULT_CARD_HEIGHT = 322;

  const cardWidthMobile = measuredContainer?.width ?? DEFAULT_CARD_WIDTH;

  return (
    <div ref={ref}>
      <SwipeSlider startSlide={showAddItemCard ? 1 : 0}>
        {showAddItemCard && (
          <UploadMore
            itemType={ShopItemType.COMMISSIONS}
            height={DEFAULT_CARD_HEIGHT}
            biData={BiData<DefaultBiEvent>({
              evid: 2,
              click_info: 'create_commission',
              sectionname: 'commission',
            })}
            onCreate={onCreate}
          />
        )}
        {deviations.map(deviation => (
          <CommissionShopCard
            key={deviation.deviationId}
            width={cardWidthMobile}
            deviation={deviation}
            withAuthorDetails={false}
            withIndicators={false}
          />
        ))}
      </SwipeSlider>
    </div>
  );
};
CommissionSliderMobile.displayName = 'CommissionSliderMobile';

export default CommissionSliderMobile;
