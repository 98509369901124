import { createAction } from 'redux-actions';

export type MoveModuleActionPayload = {
  section: string;
  zone: string;
  moduleId: number;
  position: number;
};

export const moveModule = createAction(
  'LAYOUT.MOVE_MODULE',
  (payload: MoveModuleActionPayload) => payload
);

export type RemoveModuleActionPayload = {
  section: string;
  zone: string;
  moduleId: number;
};

export const removeModule = createAction(
  'LAYOUT.REMOVE_MODULE',
  (payload: RemoveModuleActionPayload) => payload
);
export const removeModuleSuccess = createAction(
  'LAYOUT.REMOVE_MODULE_SUCCESS',
  (payload: RemoveModuleActionPayload) => payload
);

export type UpdateModuleOrderActionPayload = {
  section: string;
  zone: string;
  order: number[];
  version?: number;
};

export const updateModuleOrderSuccess = createAction(
  'LAYOUT.UPDATE_MODULE_ORDER.SUCCESS',
  (payload: UpdateModuleOrderActionPayload) => payload
);

export type AddModuleActionPayload = {
  section: string;
  zone: string;
  moduleType: string;
  position: number;
};

export const addModule = createAction(
  'LAYOUT.ADD_MODULE',
  (payload: AddModuleActionPayload) => payload
);

export const addModuleSuccess = createAction(
  'LAYOUT.ADD_MODULE_SUCCESS',
  (payload: AddModuleActionPayload) => payload
);

export const addModuleFail = createAction(
  'LAYOUT.ADD_MODULE_FAIL',
  (payload: AddModuleActionPayload) => payload
);

export type SectionLayoutVersionPayload = {
  section: string;
  version: number;
};

export const updateSectionLayoutVersion = createAction(
  'LAYOUT.UPDATE_VERSION',
  (payload: SectionLayoutVersionPayload) => payload
);
