import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDown from '@wix/da-ds/pkg/Icons/ArrowDown';
import TextButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/TextButtonWithContextMenu';
import { NullableDropdownItem } from '@wix/da-ds/pkg/Dropdown/types';
import useModuleData from '@wix/da-gruser-shared/pkg/hooks/useModuleData';
import { ActionLogModuleData } from '../types';

export interface Props {
  moduleId: number;
  selectedRoleId?: number;
  onSelectRole: (roleId?: number) => void;
}

const ActionLogFilters: React.FC<Props> = ({
  moduleId,
  selectedRoleId,
  onSelectRole,
}) => {
  const { t } = useTranslation();

  const {
    moduleData: { instigatorRoles: roles },
  } = useModuleData<ActionLogModuleData>(moduleId);

  if (!roles?.length) {
    return null;
  }
  const roleFilterItems: NullableDropdownItem[] = [
    {
      value: '',
      label: t('widgets.group_actionlog.filters.role.empty'),
      selected: !selectedRoleId,
      onClick: () => onSelectRole(),
    },
  ].concat(
    roles.map(r => ({
      value: `${r.roleId}`,
      label: r.label,
      selected: selectedRoleId === r.roleId,
      onClick: () => onSelectRole(r.roleId),
    }))
  );

  const selectedRoleLabel =
    (selectedRoleId && roles.find(r => r.roleId === selectedRoleId)?.label) ??
    t('widgets.group_actionlog.filters.role.empty');

  return (
    <TextButtonWithContextMenu
      items={roleFilterItems}
      size="small"
      variant="main"
      postfix={ArrowDown}
    >
      {selectedRoleLabel}
    </TextButtonWithContextMenu>
  );
};
ActionLogFilters.displayName = 'ActionLogFilters';

export default ActionLogFilters;
