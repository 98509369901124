import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AppState } from '../../../../../../../types/store';
import { isCurrentUserProfileOwner } from '../../../../../../selectors/users';

import { Props } from './TierContentSubmit';

export type StateProps = Pick<Props, 'isOwner'>;
export type DispatchProps = Pick<Props, never>;
export type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<
  StateProps,
  OwnProps,
  AppState
> = state => ({
  isOwner: isCurrentUserProfileOwner(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export default connect(mapStateToProps, mapDispatchToProps);
