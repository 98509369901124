import React, { useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ModalFormDialog from '@wix/da-shared-react/pkg/Popup/ModalFormDialog';
import { useGroupAboutForm } from './hooks/useGroupAboutForm';
import TextInput from '../../../_partials/Form/TextInput';
import EditorField from './_partials/EditorField';
import { EditorContextProviderWrapper } from '@wix/da-editor-tiptap/pkg/components/Editor/Context';

import s from './EditPopup.scss';

export interface Props {
  moduleId: number;
  onClose: () => void;
}

const EditPopup: React.FC<Props> = ({ moduleId, onClose }) => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { isInitialized, requestSubmit, formContext } = useGroupAboutForm(
    moduleId,
    {
      onSubmitSuccess: onClose,
    }
  );
  const {
    setValue,
    formState: { isDirty, isValid },
  } = formContext;

  if (!isInitialized) {
    return null;
  }

  return (
    <EditorContextProviderWrapper>
      <FormProvider {...formContext}>
        <ModalFormDialog
          isOpen
          showHeaderBorder
          showFooterBorder
          title={t('widgets.group_about.edit.header')}
          submitBtnLabel={t('common.save')}
          onSubmit={requestSubmit}
          onCancel={onClose}
          onClose={onClose}
          disabledSubmit={!isValid || !isDirty}
          innerScrollRef={node => (scrollRef.current = node)}
        >
          <div className={s['content-wrapper']}>
            <div className={s['content']}>
              <EditorField
                name="description"
                label={t('widgets.group_about.edit.description.label')}
                subLabel={t('widgets.group_about.edit.description.subLabel')}
                placeholder={t(
                  'widgets.group_about.edit.description.placeholder'
                )}
                onReady={api => {
                  setValue('editorApi', api);
                }}
                scrollRef={scrollRef}
              />
              <TextInput
                name="tagline"
                label={t('widgets.group_about.edit.tagline.label')}
                placeholder={t('widgets.group_about.edit.tagline.placeholder')}
              />
            </div>
          </div>
        </ModalFormDialog>
      </FormProvider>
    </EditorContextProviderWrapper>
  );
};
EditPopup.displayName = 'GroupAboutEditPopup';

export default EditPopup;
