import { createAction } from 'redux-actions';
import { PapiDeviation } from '@wix/da-papi-types';

export const biFetchMoreFolderItems = createAction(
  'BI.FETCH_MORE_FOLDER_ITEMS',
  (streamId: string) => ({ streamId })
);

export const biClickProfileNavBar = createAction(
  'BI.PROFILE_NAV_BAR',
  (linkName: string) => ({ linkName })
);

export const biLogPostsFeedItems = createAction(
  'POSTS_FEED_FETCH_SUCCESS',
  (deviations: PapiDeviation[]) => ({ deviations })
);
