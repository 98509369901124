import { createAction } from 'redux-actions';
import { createCallbackActionCreator } from '@wix/da-shared-react/pkg/Stream/helpers';

export const moveBadgeInStream = createAction(
  'BADGES.MOVE_BADGE_IN_STREAM',
  (badgeId: number, position: number) => ({ badgeId, position })
);

export const moveBadgeCommit = createAction(
  'BADGES.MOVE_BADGE_COMMIT',
  (badgeId: number, initialPosition: number) => ({ badgeId, initialPosition })
);

export const fetchMoreBadges = createCallbackActionCreator('BADGES.FETCH_MORE');

export const refreshBadgeModules = createAction(
  'BADGES.REFRESH_MODULES',
  () => ({})
);
