import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './ArtistInfoLine.scss';

interface Props {
  level?: number;
  specialty?: number;
  className?: string;
}

const ArtistInfoLine: React.FC<Props> = ({ level, specialty, className }) => {
  const { t } = useTranslation();
  return (
    <div className={classnames(s['root'], className)}>
      {t('widgets.aboutMe.artist')}
      {!!level && (
        <>
          {'  //  '}
          {t(`widgets.aboutMe.levels.${level}`)}
        </>
      )}
      {!!specialty && (
        <>
          {'  //  '}
          {t(`widgets.aboutMe.specialties.${specialty}`)}
        </>
      )}
    </div>
  );
};
ArtistInfoLine.displayName = 'ArtistInfoLine';
export default ArtistInfoLine;
