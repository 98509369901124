import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  selectors as streamSelectors,
  withOffset,
} from '@wix/da-shared-react/pkg/Stream';
import { PapiDeviation } from '@wix/da-papi-types';
import { getDeviationEntityId } from '@wix/da-shared-react/pkg/redux/normalizr/schemas/deviation';
import { editDeviation } from '@wix/da-shared-react/pkg/redux/deviations/actionCreators';
import { getDeviationsByIds } from '@wix/da-shared-react/pkg/redux/deviations/selectors';
import {
  featurePost,
  unfeaturePost,
  pinPost,
  unpinPost,
  removePost,
} from '../../../../../actions/feed';
import { AppState } from '../../../../../../types/store';
import { isCurrentUserProfileOwner } from '../../../../../selectors/users';
import { getCanRemovePost } from '../../../../../selectors/privs';
import { FEATURED_POSTS_IDS_STREAM } from '../../../../../streams/posts';
import { Props } from './PostsFeed';

type StateProps = Pick<
  Props,
  'items' | 'isItemFeatured' | 'isOwner' | 'canRemovePost'
>;
type DispatchProps = Pick<
  Props,
  | 'pinItem'
  | 'unpinItem'
  | 'editItem'
  | 'featureItem'
  | 'unfeatureItem'
  | 'removeItem'
>;
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, AppState> = (
  state: AppState,
  { streamId }: OwnProps
) => {
  const featuredItemIds = streamSelectors.getItems(
    state,
    FEATURED_POSTS_IDS_STREAM
  );
  return {
    isOwner: isCurrentUserProfileOwner(state),
    canRemovePost: getCanRemovePost(state),
    items: getDeviationsByIds(state, streamSelectors.getItems(state, streamId)),
    isItemFeatured: (item: PapiDeviation) => {
      const itemId = getDeviationEntityId(item);
      return featuredItemIds.some(id => id === itemId);
    },
    streamId,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => ({
  fetchMore: () => dispatch(withOffset.actions.fetch(ownProps.streamId)),
  pinItem: item => dispatch(pinPost({ item, streamId: ownProps.streamId })),
  unpinItem: () => dispatch(unpinPost({ streamId: ownProps.streamId })),
  featureItem: item => dispatch(featurePost({ item })),
  unfeatureItem: item => dispatch(unfeaturePost({ item })),
  removeItem: item =>
    dispatch(removePost({ item, streamId: ownProps.streamId })),
  editItem: deviation => dispatch(editDeviation({ deviation })),
});

export default connect(mapStateToProps, mapDispatchToProps);
