import React from 'react';
import { useTranslation } from 'react-i18next';
import WidgetBox, {
  Props as WidgetBoxProps,
} from '@wix/da-gruser-shared/pkg/components/WidgetBox';

import GroupAbout from '../../GroupAbout';

interface Props {
  moduleId: number;
  widgetBoxProps?: Partial<WidgetBoxProps>;
}

const GroupAboutBox: React.FC<Props> = ({ moduleId, widgetBoxProps }) => {
  const { t } = useTranslation();

  return (
    <WidgetBox
      header={t('widgets.group_about.header')}
      moduleId={moduleId}
      {...widgetBoxProps}
    >
      <GroupAbout moduleId={moduleId} />
    </WidgetBox>
  );
};
GroupAboutBox.displayName = 'GroupAboutBox';

export default GroupAboutBox;
