import {
  fetchProfileOwnerSuccess,
  fetchProfileOwnerTaglineSuccess,
  fetchProfileOwnerStatsSuccess,
  fetchProfileOwnerGiveState,
  fetchProfileOwnerGiveStateSuccess,
  fetchProfileOwnerGiveStateFailure,
  FetchProfileOwnerSuccessPayload,
  FetchProfileOwnerTaglinePayload,
  FetchProfileOwnerStatsPayload,
  FetchProfileOwnerGiveStatePayload,
  fetchFallbackBadgeCheckSuccess,
} from '../actions/profileOwner';

import { handleActions, Action } from 'redux-actions';
import { AppState } from '../../types/store';
import { ProfileOwner } from '../../types/profileOwner';

export default handleActions<AppState['profileOwner'], any>(
  {
    [fetchProfileOwnerSuccess.toString()]: (
      state: ProfileOwner,
      action: Action<FetchProfileOwnerSuccessPayload>
    ) => {
      const payload = action.payload!;
      const {
        user,
        isWatching,
        isWatcher,
        isGroup,
        extendedGroup,
        showMonetizationPromo,
        tagline,
        stats,
      } = payload;
      return {
        ...state,
        user,
        showMonetizationPromo,
        isWatching,
        isWatcher,
        isGroup,
        extendedGroup,
        tagline,
        stats: {
          ...stats,
          founded: extendedGroup?.foundationTs,
          members: extendedGroup?.totalCount,
        },
      };
    },
    [fetchProfileOwnerTaglineSuccess.toString()]: (
      state: ProfileOwner,
      action: Action<FetchProfileOwnerTaglinePayload>
    ) => {
      const payload = action.payload!;
      return {
        ...state,
        tagline: payload.tagline,
      };
    },
    [fetchFallbackBadgeCheckSuccess.toString()]: (state: ProfileOwner) => {
      return {
        ...state,
        hasAprilFoolsBadge: true,
      };
    },
    [fetchProfileOwnerStatsSuccess.toString()]: (
      state: ProfileOwner,
      action: Action<FetchProfileOwnerStatsPayload>
    ) => {
      const payload = action.payload!;
      return {
        ...state,
        stats: {
          ...state.stats,
          ...payload,

          founded: state?.extendedGroup?.foundationTs,
          members: state?.extendedGroup?.totalCount,
        },
      };
    },
    [fetchProfileOwnerGiveState.toString()]: (state: ProfileOwner) => {
      return {
        ...state,
        giveState: {
          ...state.giveState,
          isLoading: true,
        },
      };
    },
    [fetchProfileOwnerGiveStateSuccess.toString()]: (
      state: ProfileOwner,
      action: Action<FetchProfileOwnerGiveStatePayload>
    ) => {
      const payload = action.payload!;
      const giveState = state.giveState!;
      const {
        canBeGivenLlama = giveState.canBeGivenLlama,
        canBeGivenCake = giveState.canBeGivenCake,
        canBeGivenCore = giveState.canBeGivenCore,
      } = payload;

      return {
        ...state,
        giveState: {
          ...giveState,
          canBeGivenLlama,
          canBeGivenCake,
          canBeGivenCore,
          isLoading: false,
        },
      };
    },
    [fetchProfileOwnerGiveStateFailure.toString()]: state => {
      return {
        ...state,
        giveState: {
          ...state.giveState,
          isLoading: false,
        },
      };
    },
  },
  {
    user: { id: 0 },
    isWatching: false,
    isWatcher: false,
    isGroup: false,
    extendedGroup: undefined,
    hasAprilFoolsBadge: false,
    giveState: {
      canBeGivenLlama: true,
      canBeGivenCake: true,
      canBeGivenCore: true,
      isLoading: false,
    },
    stats: {},
  }
);
