import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import { ZoneName } from '@wix/da-gruser-shared/pkg/types/zones';
import { BACKGROUNDS } from './constants';

export const getMeasuredCookieType = (zone: ZoneName): MeasuredCookieType => {
  switch (zone) {
    case 'midRight':
      return MeasuredCookieType.LLAMA_FARM_RIGHT;
    case 'midLeft':
    default:
      return MeasuredCookieType.LLAMA_FARM_LEFT;
  }
};

export const getBackgroundByIndex = (index: number): number =>
  BACKGROUNDS[(index + BACKGROUNDS.length) % BACKGROUNDS.length];
