import { ThemeOptions } from '@wix/da-react-context/pkg/constants';
import { PapiDeviation, PapiProfileskin } from '@wix/da-papi-types';
import { BackgroundImageDisplayMode } from '@wix/da-shared-react/pkg/Background/types';

export const profileSkinsStreamId = 'profile_skins';

const CACHE_BUSTER = 3;

/**
 * We accept URLs or css rules for an image through the profile skin admin tool. To improve the admin tool experience,
 * we allow raw URLs instead of ones wrapped in `url()`. So we need to wrap them manually here.
 *
 * This is hacky, I know.
 */
export function wrapPapiBackgroundUrl(url: string) {
  return url.startsWith('https') ? `url(${url}?${CACHE_BUSTER})` : url;
}

/**
 * Converts a backend skin type to what we're accustomed to working with on the frontend.
 */
export function papiSkinToFedSkin(papiSkin: PapiProfileskin): ProfileSkin {
  return {
    skinId: `${papiSkin.skinId}`,
    skinName: papiSkin.title,
    backgroundPreview: wrapPapiBackgroundUrl(papiSkin.previewImage),
    skin: {
      baseTheme: papiSkin.baseTheme as ThemeOptions,
      background: papiSkin.backgroundImage
        ? wrapPapiBackgroundUrl(papiSkin.backgroundImage)
        : '',
      staticBackground: papiSkin.staticBackgroundImage
        ? wrapPapiBackgroundUrl(papiSkin.staticBackgroundImage)
        : undefined,
    },
    // All skins are core only, no current plans to open this up to free users
    isCore: true,
  };
}

export interface ProfileSkinsSlice {
  activeSkin?: ProfileSkin;
  skinSelectorShown: boolean;
  isSkinSaving: boolean;

  /**
   * The user can only have one custom skin. This is the first profile skin returned by the initial list fetch.
   */
  customSkin?: ProfileSkin;
}

export interface ProfileSkin {
  skinId: string;
  skinName: string;
  backgroundPreview: string;
  featureProtected?: string;
  skin?: {
    baseTheme: ThemeOptions;
    background: string;
    staticBackground?: string;
    imageUrl?: string;
    image?: PapiDeviation;
    imageDisplayMode?: BackgroundImageDisplayMode;
  };
  isCore: boolean;
}

export const officialSkin: ProfileSkin = {
  skinId: 'official',
  skinName: 'Official',
  isCore: false,
  backgroundPreview: 'linear-gradient(225deg, #5d5d6c 0%, #22272b 100%)',
};

export const customSkin: ProfileSkin = {
  skinId: 'custom',
  skinName: 'Custom',
  isCore: true,
  backgroundPreview: `var(--g-bg-secondary)`,
};
